import { Resource } from '@ekuaibao/fetch';
var commonDimension = new Resource('/api/v1/basedata/commonDimensionItem');
var specification = new Resource('/api/form/v1/specificationVersions/originalId');
export var getCommonDimensionConfig = function (params) {
    return commonDimension.GET('', params);
};
export var getCommonDimension = function (params) {
    return commonDimension.POST('', null, params);
};
export var getSpecification = function (params) {
    return specification.GET("/$id", params);
};
