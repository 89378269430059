/**
 *  Created by gym on 2019-07-04 10:55.
 */
import React from 'react'
import { Form } from 'antd'
import styles from './FormItem.module.less'
import classnames from 'classnames'
import FormItemLabelWrap from './FormItemLabelWrap/FormItemLabelWrap'

export function FormItem(props) {
  let {
    label,
    external,
    isForbid = true,
    labelCol,
    wrapperCol,
    flowId,
    noColon,
    isDetail,
    field,
    detailId,
    labelAlign = 'right'
  } = props
  const cls = classnames(styles.form_item_4_wrapper, { [styles.form_item_label_left]: labelAlign === 'left' })
  if(field?.field === 'apportions' && external) {
    label = undefined
  }
  return (
    <div className={cls}>
      <Form.Item
        {...props}
        labelCol={labelCol ? labelCol : { span: 24 }}
        wrapperCol={wrapperCol ? wrapperCol : { span: 24 }}
        label={
          label ? (
            external || flowId ? (
              <span>
                <FormItemLabelWrap
                  isEdit={true}
                  external={external}
                  isForbid={isForbid}
                  noColon={noColon}
                  field={field}
                  flowId={flowId}
                  isDetail={isDetail}
                  detailId={detailId}
                >
                  <span>{label}</span>
                </FormItemLabelWrap>
              </span>
            ) : (
              <span>{label}</span>
            )
          ) : (
            ''
          )
        }
      >
        {props.children}
        {field?.customizeCalculateTip && <span className={classnames('custom-tip')}>{ field.customizeCalculateTip }</span>}
      </Form.Item>
    </div>
  )
}
