import { __awaiter, __generator, __read } from "tslib";
import React, { useState } from 'react';
import UploadButton from '../../../elements/ekbc-business/upload-button';
import { uploadMetaileFile } from '../bills.action';
import { showMessage } from '@ekuaibao/show-util';
var InvoiceUploadView = function (props) {
    var _a = __read(useState(false), 2), isUpload = _a[0], setIsUpload = _a[1];
    var children = props.children, invoiceId = props.invoiceId, onResult = props.onResult;
    var handleStart = function () {
        setIsUpload(true);
    };
    var handleFinish = function (fileList) {
        if (fileList === void 0) { fileList = []; }
        return __awaiter(void 0, void 0, void 0, function () {
            var _a, first;
            return __generator(this, function (_b) {
                setIsUpload(false);
                if (fileList === null || fileList === void 0 ? void 0 : fileList.length) {
                    _a = __read(fileList, 1), first = _a[0];
                    uploadMetaileFile({ invoiceId: invoiceId, fileName: first.key, fileUrl: first.url })
                        .then(function (_a) {
                        var value = _a.value;
                        if (onResult) {
                            onResult(value);
                            showMessage.success('上传成功');
                        }
                    })
                        .catch(function (err) {
                        showMessage.error(err.msg);
                    });
                }
                return [2];
            });
        });
    };
    return (React.createElement(UploadButton, { inputStyle: { width: '55px', cursor: 'pointer' }, accept: 'application/pdf,.pdf,.ofd', multiple: false, isLoading: isUpload, fileMaxSize: 5, children: children, showSuccessTip: false, onStart: handleStart, onFinish: handleFinish }));
};
export default InvoiceUploadView;
