/**
 *  Created by daiwenjuan on 2017/11/22 下午6:30.
 */
import { find, concat, forEach, filter } from 'lodash'
import { app as api } from '@ekuaibao/whispered'

export function fnCheckedList(params) {
  let { roles, staffs, departments } = params
  return [
    { type: 'department-member', multiple: true, checkedKeys: staffs || [] },
    { type: 'department', multiple: true, checkedKeys: departments || [] },
    { type: 'role', multiple: true, checkedKeys: roles || [] }
  ]
}

export function fnSetVisibleList(checkedList) {
  let depart = find(checkedList, line => line.type === 'department')
  let role = find(checkedList, line => line.type === 'role')
  let staff = find(checkedList, line => line.type === 'department-member')
  let staffList = staff.checkedKeys || []
  let departmentList = depart.checkedKeys || []
  let roleList = role.checkedKeys || []
  let visibility = {
    staffs: staffList.filter(v => v),
    departments: departmentList.filter(v => v),
    roles: roleList.filter(v => v)
  }
  let visibilityList = concat([], depart.checkedData)
  visibilityList = concat(visibilityList, role.checkedData)
  visibilityList = concat(visibilityList, staff.checkedData)
  let strInfo = fnSetVisibleStr(visibilityList)
  return { visibility, visibilityList, strInfo }
}

export function fnSetVisibleStr(visibilityList = []) {
  return visibilityList
    .map(line => line.name)
    .reverse()
    .join(',')
}

export async function fillCheckedList(checkedList) {
  const staffsActives = await api
    .dataLoader('@common.staffsActives')
    .load()
    .then(d => d.data)
  const departmentTree = await api
    .dataLoader('@common.department')
    .load()
    .then(d => d.data)
  const roleList = await api.dataLoader('@common.roleList').load()

  forEach(checkedList, obj => (obj.checkedData = getDataByObj(obj)))

  return checkedList

  function getDataByObj(obj) {
    let checkedData = []
    if (obj.type === 'department-member') {
      forEach(obj.checkedKeys, key => {
        if (key && key.length) {
          let a = filter(staffsActives, line => key === line.id)[0]
          a && checkedData.push(a)
        }
      })
    } else if (obj.type === 'role') {
      forEach(obj.checkedKeys, key => {
        let a = filter(roleList, line => key === line.id)[0]
        a && checkedData.push(a)
      })
    } else if (obj.type === 'department') {
      checkedData = getDeptByIds(departmentTree, obj.checkedKeys) || []
    }
    return checkedData
  }

  function getDeptByIds(deptData, ids = []) {
    let items = []
    let fn = item => {
      if (ids.indexOf(item.id) > -1) {
        items.push(item)
      }
      item.children = item.children || []
      if (item.children.length) {
        item.children.forEach(c => {
          fn(c)
        })
      }
    }
    deptData &&
      deptData.forEach(dept => {
        fn(dept)
      })
    return items
  }
}

// 获取KA-外币账户打开后需要展示的字段
export const getFormItemsArrHSBC = () => [
  {
    label: '国家/地区（Country/Area）',
    name: 'extensions.country',
    max: 140,
    type: 'select',
    payee: '收款人地址'
  },
  {
    label: '城市（City）',
    name: 'extensions.city',
    max: 140,
    type: 'select',
    payee: '收款人地址'
  },
  {
    label: '镇/区（Town/District）',
    name: 'extensions.town',
    max: 140,
    type: 'input',
    payee: '收款人地址'
  },
  {
    label: '街道（Street）',
    name: 'extensions.street',
    max: 140,
    type: 'input',
    payee: '收款人地址'
  },
  {
    label: '邮编（Zip code）',
    name: 'extensions.zipCode',
    max: 140,
    type: 'input',
    notChineseOREnglish: true
  },
  {
    label: '收款银行所在国家',
    name: 'extensions.bankCountry',
    max: 140,
    type: 'select',
    notChineseOREnglish: true
  },
  {
    label: 'Account Name',
    name: 'extensions.accountName',
    max: 140,
    type: 'input',
    onlyEn: true
  },
  {
    label: 'Bank Name',
    name: 'extensions.bankName',
    max: 140,
    type: 'input',
    onlyEn: true
  },
  {
    label: 'Country/Area',
    name: 'extensions.countryEn',
    onlyEn: true,
    max: 140,
    type: 'select',
    payee: 'Payee address'
  },
  {
    label: 'City',
    name: 'extensions.cityEn',
    onlyEn: true,
    max: 140,
    type: 'select',
    payee: 'Payee address'
  },
  {
    label: 'Town/District',
    name: 'extensions.townEn',
    onlyEn: true,
    max: 140,
    type: 'input',
    payee: 'Payee address'
  },
  {
    label: 'Street',
    name: 'extensions.streetEn',
    onlyEn: true,
    max: 140,
    type: 'input',
    payee: 'Payee address'
  },
  {
    label: 'BIC代码（BIC code）',
    name: 'extensions.bicCode',
    max: 140,
    type: 'input'
  }
]

// 后台不愿意返回，让前端写死的，我有罪
export const getFormItemsCityCode = () => [
  { key: '090', value: '所罗门群岛', eName: 'SB' },
  { key: '092', value: '英属维尔京群岛', eName: 'VG' },
  { key: '096', value: '文莱', eName: 'BN' },
  { key: '100', value: '保加利亚', eName: 'BG' },
  { key: '104', value: '缅甸', eName: 'MM' },
  { key: '108', value: '布隆迪', eName: 'BI' },
  { key: '112', value: '白俄罗斯', eName: 'BY' },
  { key: '116', value: '柬埔寨', eName: 'KH' },
  { key: '120', value: '喀麦隆', eName: 'CM' },
  { key: '124', value: '加拿大', eName: 'CA' },
  { key: '132', value: '佛得角', eName: 'CV' },
  { key: '136', value: '开曼群岛', eName: 'KY' },
  { key: '140', value: '中非', eName: 'CF' },
  { key: '144', value: '斯里兰卡', eName: 'LK' },
  { key: '148', value: '乍得', eName: 'TD' },
  { key: '152', value: '智利', eName: 'CL' },
  { key: '156', value: '中国', eName: 'CN' },
  { key: '158', value: '中国台湾', eName: 'TW' },
  { key: '162', value: '圣诞岛', eName: 'CX' },
  { key: '166', value: '科科斯（基林）群岛', eName: 'CC' },
  { key: '170', value: '哥伦比亚', eName: 'CO' },
  { key: '174', value: '科摩罗', eName: 'KM' },
  { key: '175', value: '马约特', eName: 'YT' },
  { key: '178', value: '刚果（布）', eName: 'CG' },
  { key: '180', value: '刚果（金）', eName: 'CD' },
  { key: '184', value: '库克群岛', eName: 'CK' },
  { key: '188', value: '哥斯达黎加', eName: 'CR' },
  { key: '191', value: '克罗地亚', eName: 'HR' },
  { key: '192', value: '古巴', eName: 'CU' },
  { key: '196', value: '塞浦路斯', eName: 'CY' },
  { key: '203', value: '捷克', eName: 'CZ' },
  { key: '204', value: '贝宁', eName: 'BJ' },
  { key: '208', value: '丹麦', eName: 'DK' },
  { key: '212', value: '多米尼克', eName: 'DM' },
  { key: '214', value: '多米尼加', eName: 'DO' },
  { key: '218', value: '厄瓜多尔', eName: 'EC' },
  { key: '222', value: '萨尔瓦多', eName: 'SV' },
  { key: '729', value: '苏丹', eName: 'SD' },
  { key: '728', value: '南苏丹', eName: 'SS' },
  { key: '226', value: '赤道几内亚', eName: 'GQ' },
  { key: '231', value: '埃塞俄比亚', eName: 'ET' },
  { key: '232', value: '厄立特里亚', eName: 'ER' },
  { key: '233', value: '爱沙尼亚', eName: 'EE' },
  { key: '234', value: '法罗群岛', eName: 'FO' },
  { key: '238', value: '福克兰群岛-马尔维纳斯群岛', eName: 'FK' },
  { key: '239', value: '南乔治亚岛和南桑德韦奇岛', eName: 'GS' },
  { key: '242', value: '斐济', eName: 'FJ' },
  { key: '246', value: '芬兰', eName: 'FI' },
  { key: '250', value: '法国', eName: 'FR' },
  { key: '254', value: '法属圭亚那', eName: 'GF' },
  { key: '258', value: '法属波利尼西亚', eName: 'PF' },
  { key: '260', value: '法属南部领地', eName: 'TF' },
  { key: '262', value: '吉布提', eName: 'DJ' },
  { key: '266', value: '加蓬', eName: 'GA' },
  { key: '268', value: '格鲁吉亚', eName: 'GE' },
  { key: '270', value: '冈比亚', eName: 'GM' },
  { key: '275', value: '巴勒斯坦', eName: 'PS' },
  { key: '276', value: '德国', eName: 'DE' },
  { key: '288', value: '加纳', eName: 'GH' },
  { key: '292', value: '直布罗陀', eName: 'GI' },
  { key: '296', value: '基里巴斯', eName: 'KI' },
  { key: '300', value: '希腊', eName: 'GR' },
  { key: '304', value: '格陵兰', eName: 'GL' },
  { key: '308', value: '格林纳达', eName: 'GD' },
  { key: '312', value: '瓜德罗普', eName: 'GP' },
  { key: '316', value: '关岛', eName: 'GU' },
  { key: '320', value: '危地马拉', eName: 'GT' },
  { key: '324', value: '几内亚', eName: 'GN' },
  { key: '328', value: '圭亚那', eName: 'GY' },
  { key: '634', value: '卡塔尔', eName: 'QA' },
  { key: '638', value: '留尼汪', eName: 'RE' },
  { key: '642', value: '罗马尼亚', eName: 'RO' },
  { key: '643', value: '俄罗斯联邦', eName: 'RU' },
  { key: '646', value: '卢旺达', eName: 'RW' },
  { key: '654', value: '圣赫勒拿', eName: 'SH' },
  { key: '659', value: '圣基茨和尼维斯', eName: 'KN' },
  { key: '660', value: '安圭拉', eName: 'AI' },
  { key: '662', value: '圣卢西亚', eName: 'LC' },
  { key: '666', value: '圣皮埃尔和密克隆', eName: 'PM' },
  { key: '670', value: '圣文森特和格林纳丁斯', eName: 'VC' },
  { key: '674', value: '圣马力诺', eName: 'SM' },
  { key: '678', value: '圣多美和普林西比', eName: 'ST' },
  { key: '682', value: '沙特阿拉伯', eName: 'SA' },
  { key: '686', value: '塞内加尔', eName: 'SN' },
  { key: '688', value: '塞尔维亚', eName: 'RS' },
  { key: '690', value: '塞舌尔', eName: 'SC' },
  { key: '694', value: '塞拉利昂', eName: 'SL' },
  { key: '702', value: '新加坡', eName: 'SG' },
  { key: '703', value: '斯洛伐克', eName: 'SK' },
  { key: '704', value: '越南', eName: 'VN' },
  { key: '705', value: '斯洛文尼亚', eName: 'SI' },
  { key: '706', value: '索马里', eName: 'SO' },
  { key: '710', value: '南非', eName: 'ZA' },
  { key: '716', value: '津巴布韦', eName: 'ZW' },
  { key: '724', value: '西班牙', eName: 'ES' },
  { key: '732', value: '西撒哈拉', eName: 'EH' },
  { key: '740', value: '苏里南', eName: 'SR' },
  { key: '744', value: '斯瓦尔巴岛和扬马延岛', eName: 'SJ' },
  { key: '748', value: '斯威士兰', eName: 'SZ' },
  { key: '752', value: '瑞典', eName: 'SE' },
  { key: '756', value: '瑞士', eName: 'CH' },
  { key: '760', value: '叙利亚', eName: 'SY' },
  { key: '762', value: '塔吉克斯坦', eName: 'TJ' },
  { key: '764', value: '泰国', eName: 'TH' },
  { key: '768', value: '多哥', eName: 'TG' },
  { key: '772', value: '托克劳', eName: 'TK' },
  { key: '776', value: '汤加', eName: 'TO' },
  { key: '780', value: '特立尼达和多巴哥', eName: 'TT' },
  { key: '784', value: '阿联酋', eName: 'AE' },
  { key: '788', value: '突尼斯', eName: 'TN' },
  { key: '792', value: '土耳其', eName: 'TR' },
  { key: '795', value: '土库曼斯坦', eName: 'TM' },
  { key: '796', value: '特克斯和凯科斯群岛', eName: 'TC' },
  { key: '798', value: '图瓦卢', eName: 'TV' },
  { key: '800', value: '乌干达', eName: 'UG' },
  { key: '804', value: '乌克兰', eName: 'UA' },
  { key: '807', value: '前南马其顿', eName: 'MK' },
  { key: '818', value: '埃及', eName: 'EG' },
  { key: '826', value: '英国', eName: 'GB' },
  { key: '833', value: '马恩岛', eName: 'IM' },
  { key: '834', value: '坦桑尼亚', eName: 'TZ' },
  { key: '840', value: '美国', eName: 'US' },
  { key: '850', value: '美属维尔京群岛', eName: 'VI' },
  { key: '854', value: '布基纳法索', eName: 'BF' },
  { key: '858', value: '乌拉圭', eName: 'UY' },
  { key: '860', value: '乌兹别克斯坦', eName: 'UZ' },
  { key: '862', value: '委内瑞拉', eName: 'VE' },
  { key: '876', value: '瓦利斯和富图纳', eName: 'WF' },
  { key: '882', value: '萨摩亚', eName: 'WS' },
  { key: '887', value: '也门', eName: 'YE' },
  { key: '894', value: '赞比亚', eName: 'ZM' },
  { key: '332', value: '海地', eName: 'HT' },
  { key: '334', value: '赫德岛和麦克唐纳岛', eName: 'HM' },
  { key: '336', value: '梵蒂冈', eName: 'VA' },
  { key: '340', value: '洪都拉斯', eName: 'HN' },
  { key: '344', value: '中国香港', eName: 'HK' },
  { key: '348', value: '匈牙利', eName: 'HU' },
  { key: '352', value: '冰岛', eName: 'IS' },
  { key: '356', value: '印度', eName: 'IN' },
  { key: '360', value: '印度尼西亚', eName: 'ID' },
  { key: '364', value: '伊朗', eName: 'IR' },
  { key: '368', value: '伊拉克', eName: 'IQ' },
  { key: '372', value: '爱尔兰', eName: 'IE' },
  { key: '376', value: '以色列', eName: 'IL' },
  { key: '380', value: '意大利', eName: 'IT' },
  { key: '384', value: '科特迪瓦', eName: 'CI' },
  { key: '388', value: '牙买加', eName: 'JM' },
  { key: '392', value: '日本', eName: 'JP' },
  { key: '398', value: '哈萨克斯坦', eName: 'KZ' },
  { key: '400', value: '约旦', eName: 'JO' },
  { key: '404', value: '肯尼亚', eName: 'KE' },
  { key: '408', value: '朝鲜', eName: 'KP' },
  { key: '410', value: '韩国', eName: 'KR' },
  { key: '414', value: '科威特', eName: 'KW' },
  { key: '417', value: '吉尔吉斯斯坦', eName: 'KG' },
  { key: '418', value: '老挝', eName: 'LA' },
  { key: '422', value: '黎巴嫩', eName: 'LB' },
  { key: '426', value: '莱索托', eName: 'LS' },
  { key: '428', value: '拉脱维亚', eName: 'LV' },
  { key: '430', value: '利比里亚', eName: 'LR' },
  { key: '434', value: '利比亚', eName: 'LY' },
  { key: '438', value: '列支敦士登', eName: 'LI' },
  { key: '440', value: '立陶宛', eName: 'LT' },
  { key: '442', value: '卢森堡', eName: 'LU' },
  { key: '446', value: '中国澳门', eName: 'MO' },
  { key: '450', value: '马达加斯加', eName: 'MG' },
  { key: '454', value: '马拉维', eName: 'MW' },
  { key: '458', value: '马来西亚', eName: 'MY' },
  { key: '462', value: '马尔代夫', eName: 'MV' },
  { key: '466', value: '马里', eName: 'ML' },
  { key: '470', value: '马耳他', eName: 'MT' },
  { key: '474', value: '马提尼克', eName: 'MQ' },
  { key: '478', value: '毛里塔尼亚', eName: 'MR' },
  { key: '480', value: '毛里求斯', eName: 'MU' },
  { key: '484', value: '墨西哥', eName: 'MX' },
  { key: '492', value: '摩纳哥', eName: 'MC' },
  { key: '496', value: '蒙古', eName: 'MN' },
  { key: '498', value: '摩尔多瓦', eName: 'MD' },
  { key: '499', value: '黑山', eName: 'ME' },
  { key: '500', value: '蒙特塞拉特', eName: 'MS' },
  { key: '504', value: '摩洛哥', eName: 'MA' },
  { key: '508', value: '莫桑比克', eName: 'MZ' },
  { key: '512', value: '阿曼', eName: 'OM' },
  { key: '516', value: '纳米比亚', eName: 'NA' },
  { key: '520', value: '瑙鲁', eName: 'NR' },
  { key: '524', value: '尼泊尔', eName: 'NP' },
  { key: '528', value: '荷兰', eName: 'NL' },
  { key: '533', value: '阿鲁巴', eName: 'AW' },
  { key: '540', value: '新喀里多尼亚', eName: 'NC' },
  { key: '548', value: '瓦努阿图', eName: 'VU' },
  { key: '554', value: '新西兰', eName: 'NZ' },
  { key: '558', value: '尼加拉瓜', eName: 'NI' },
  { key: '562', value: '尼日尔', eName: 'NE' },
  { key: '566', value: '尼日利亚', eName: 'NG' },
  { key: '570', value: '纽埃', eName: 'NU' },
  { key: '574', value: '诺福克岛', eName: 'NF' },
  { key: '578', value: '挪威', eName: 'NO' },
  { key: '580', value: '北马里亚纳', eName: 'MP' },
  { key: '581', value: '美国本土外小岛屿', eName: 'UM' },
  { key: '583', value: '密克罗尼西亚联邦', eName: 'FM' },
  { key: '584', value: '马绍尔群岛', eName: 'MH' },
  { key: '585', value: '帕劳', eName: 'PW' },
  { key: '586', value: '巴基斯坦', eName: 'PK' },
  { key: '591', value: '巴拿马', eName: 'PA' },
  { key: '598', value: '巴布亚新几内亚', eName: 'PG' },
  { key: '600', value: '巴拉圭', eName: 'PY' },
  { key: '604', value: '秘鲁', eName: 'PE' },
  { key: '608', value: '菲律宾', eName: 'PH' },
  { key: '612', value: '皮特凯恩', eName: 'PN' },
  { key: '616', value: '波兰', eName: 'PL' },
  { key: '620', value: '葡萄牙', eName: 'PT' },
  { key: '624', value: '几内亚比绍', eName: 'GW' },
  { key: '626', value: '东帝汶', eName: 'TL' },
  { key: '630', value: '波多黎各', eName: 'PR' },
  { key: '004', value: '阿富汗', eName: 'AF' },
  { key: '008', value: '阿尔巴尼亚', eName: 'AL' },
  { key: '010', value: '南极洲', eName: 'AQ' },
  { key: '012', value: '阿尔及利亚', eName: 'DZ' },
  { key: '016', value: '美属萨摩亚', eName: 'AS' },
  { key: '020', value: '安道尔', eName: 'AD' },
  { key: '024', value: '安哥拉', eName: 'AO' },
  { key: '028', value: '安提瓜和巴布达', eName: 'AG' },
  { key: '031', value: '阿塞拜疆', eName: 'AZ' },
  { key: '032', value: '阿根廷', eName: 'AR' },
  { key: '036', value: '澳大利亚', eName: 'AU' },
  { key: '040', value: '奥地利', eName: 'AT' },
  { key: '044', value: '巴哈马', eName: 'BS' },
  { key: '048', value: '巴林', eName: 'BH' },
  { key: '050', value: '孟加拉国', eName: 'BD' },
  { key: '051', value: '亚美尼亚', eName: 'AM' },
  { key: '052', value: '巴巴多斯', eName: 'BB' },
  { key: '056', value: '比利时', eName: 'BE' },
  { key: '060', value: '百慕大', eName: 'BM' },
  { key: '064', value: '不丹', eName: 'BT' },
  { key: '068', value: '玻利维亚', eName: 'BO' },
  { key: '070', value: '波黑', eName: 'BA' },
  { key: '072', value: '博茨瓦纳', eName: 'BW' },
  { key: '074', value: '布维岛', eName: 'BV' },
  { key: '076', value: '巴西', eName: 'BR' },
  { key: '084', value: '伯利兹', eName: 'BZ' },
  { key: '086', value: '英属印度洋领地', eName: 'IO' }
]
