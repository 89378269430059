import { __awaiter, __generator } from "tslib";
import { Fetch } from '@ekuaibao/fetch';
import { app as api } from '@ekuaibao/whispered';
import { session } from '@ekuaibao/session-info';
import { getCorpId } from '../lib/lib-util';
export default systemLimitLogout;
export function systemLimitLogout() {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var params, corporations, safeSetting, timeOut, lastTime, currentTime, timeType, webDomId, clusterLogout, limitTime, limitTimeId, selfTime;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = { sensitiveWords: true };
                    if (window.__PLANTFORM__ === 'MC')
                        params.corpId = getCorpId();
                    return [4, Fetch.GET('/api/v1/organization/corporations/info', params)];
                case 1:
                    corporations = _b.sent();
                    console.log("---------系统退出时间限制corporations", corporations);
                    safeSetting = (_a = corporations === null || corporations === void 0 ? void 0 : corporations.value) === null || _a === void 0 ? void 0 : _a.safeSetting;
                    timeOut = 60 * 1000;
                    lastTime = new Date().getTime();
                    currentTime = new Date().getTime();
                    timeType = false;
                    webDomId = document.getElementById('web');
                    clusterLogout = function () { return __awaiter(_this, void 0, void 0, function () {
                        var corpId, existGP, logoutUrl, paramsStr;
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    corpId = getCorpId();
                                    existGP = corpId === null || corpId === void 0 ? void 0 : corpId.includes('~GP');
                                    logoutUrl = (_a = window.PLATFORMINFO) === null || _a === void 0 ? void 0 : _a.logoutUrl;
                                    logoutUrl = logoutUrl && existGP ? logoutUrl : '';
                                    paramsStr = corpId ? "fromCluster=true&corpId=" + corpId : '';
                                    sessionStorage.setItem('isLogOutUrl', 'true');
                                    return [4, Fetch.DELETE('/api/account/v2/session')];
                                case 1:
                                    _b.sent();
                                    location.href = logoutUrl + "/web/app.html?" + paramsStr + "#/login";
                                    return [2];
                            }
                        });
                    }); };
                    limitTime = function () { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    webDomId && webDomId.addEventListener('mousemove', function () {
                                        lastTime = new Date().getTime();
                                    });
                                    timeType = window.location.href.indexOf('login') < 0;
                                    currentTime = new Date().getTime();
                                    console.log("---------系统退出时间限制lastTime+currentTime+timeType+timeOut", lastTime, currentTime, timeType, timeOut, (currentTime - lastTime));
                                    if (!(currentTime - lastTime > timeOut)) return [3, 6];
                                    if (!timeType) return [3, 5];
                                    if (!(((_a = window.PLATFORMINFO) === null || _a === void 0 ? void 0 : _a.clusterURL) && window.__PLANTFORM__ !== 'MC')) return [3, 1];
                                    clusterLogout();
                                    return [3, 4];
                                case 1:
                                    if (!window.IS_SMG) return [3, 3];
                                    sessionStorage.setItem('isLogOutUrl', 'true');
                                    return [4, Fetch.DELETE('/api/account/v2/session')];
                                case 2:
                                    _c.sent();
                                    location.href = (_b = window.PLATFORMINFO) === null || _b === void 0 ? void 0 : _b.logoutUrl;
                                    return [3, 4];
                                case 3:
                                    session.set('user', {});
                                    Fetch.DELETE('/api/account/v2/session');
                                    api.go('/login', true);
                                    _c.label = 4;
                                case 4:
                                    lastTime = new Date().getTime();
                                    return [3, 6];
                                case 5:
                                    lastTime = new Date().getTime();
                                    _c.label = 6;
                                case 6: return [2];
                            }
                        });
                    }); };
                    if (safeSetting && (safeSetting === null || safeSetting === void 0 ? void 0 : safeSetting.limitTimeId) !== 'neverOut') {
                        limitTimeId = safeSetting.limitTimeId, selfTime = safeSetting.selfTime;
                        switch (limitTimeId) {
                            case 'fiveMin':
                                timeOut = timeOut * 5;
                                break;
                            case 'twentyMin':
                                timeOut = timeOut * 20;
                                break;
                            case 'sixtyMin':
                                timeOut = timeOut * 60;
                                break;
                            case 'selfTime':
                                timeOut = timeOut * selfTime;
                                break;
                        }
                        window.setInterval(limitTime, 5000);
                    }
                    return [2];
            }
        });
    });
}
