/**
 *  Created by panwei on 2018/7/27 下午7:13.
 */
import React, { PureComponent } from 'react'
import styles from './InvoiceCard.module.less'
import { InvoiceHeader, InvoicePayerInfo } from './InvoiceHeaderInfo'
import { InvoiceMasterInfo } from './InvoiceMaster'
import { InvoiceDetailTip, InvoiceDetails, InvoiceTotal, InvoiceRemark, InvoiceAllDetail } from './InvoiceDetails'
import ThirdCardWrap from '../ThirdCardWrap/ThirdCardWrap'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { checkPayerInfo } from '../../lib/lib-util'
import { formatInvoiceData } from './FormatInvoiceData'
import { EnhanceConnect } from '@ekuaibao/store'
import { app as api } from '@ekuaibao/whispered'
import { thousandBitSeparator } from '../../components/utils/fnThousandBitSeparator'
import { _formatInvoiceDetail } from '../invoice-form/utils/config'
import { INVOICE_TYPE } from '@ekuaibao/lib/lib/enums'
import { get } from 'lodash'

@EnhanceConnect(state => ({
  payerInfoArr: state['@common'].payerInfo
}))
export default class InvoiceCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      invoiceAllDetails: undefined,
      ischeck: undefined,
      message: undefined,
      status: undefined
    }
  }

  componentWillMount() {
    let { bus } = this.props
    bus && bus.on('delete:invoiceForm:invoiceAllDetails', this.handleDeleteData)
    const masterId = getNodeValueByPath(this.props, 'invoice.master.id')
    this.handleGetInvoiceStateById(masterId)
  }

  componentWillReceiveProps(nextPorps) {
    const masterId = getNodeValueByPath(this.props, 'invoice.master.id')
    const nextMasterId = getNodeValueByPath(nextPorps, 'invoice.master.id')
    if (masterId !== nextMasterId) {
      this.handleGetInvoiceStateById(nextMasterId)
    }
  }

  componentWillUnmount() {
    let { bus } = this.props
    bus && bus.un('delete:invoiceForm:invoiceAllDetails', this.handleDeleteData)
  }

  handleDeleteData = () => {
    this.setState({ invoiceAllDetails: undefined })
  }

  handleGetInvoiceDetailsById = invoiceDetails => {
    let {
      master: { id }
    } = invoiceDetails
    let masterId = id
    this.setState({ isLoading: true })
    api.invokeService('@bills:get:invoice:Alldetails', masterId).then(res => {
      let invoiceAllDetails = _formatInvoiceDetail(invoiceDetails, res)
      this.setState({ isLoading: false, invoiceAllDetails: invoiceAllDetails })
    })
  }

  handleGetInvoiceStateById = masterId => {
    if (!masterId || !this.props.submitterId) return
    let submitId = this.props.submitterId.id
    this.setState({ isLoading: true })
    api
      .invokeService('@bills:get:invoice:state', masterId, submitId)
      .then(res => {
        let { ischeck, message, status } = res
        this.setState({ isLoading: false, ischeck, message, status })
      })
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false })
      })
  }

  handleDownloadPDF = () => {
    const { invoice } = this.props
    let { invoiceInfo } = formatInvoiceData({ data: invoice })
    api.invokeService('@common:download:invoce:pdf', invoiceInfo)
  }

  handleConfirm = () => {
    let { bus } = this.props
    bus.emit('invoice-select-delete')
  }

  fnGetAllAmount = invoiceInfo => {
    let invamt = 0,
      invtaxamt = 0
    invoiceInfo &&
      invoiceInfo.detailItems.forEach(v => {
        invamt += v.amount?.standard ? v.amount?.standard * 1 : v.amount * 1
        const tax = (v.tax && v.tax.standard) || v.tax || 0
        invtaxamt += tax * 1
      })

    invamt = thousandBitSeparator(invamt.toFixed(2))
    invtaxamt = thousandBitSeparator(invtaxamt.toFixed(2))
    return { invamt, invtaxamt }
  }

  renderInvoiceHeader = (invoice, invoiceOriginData) => {
    const { source, type } = invoice
    const { Delete } = this.props
    const title = INVOICE_TYPE()[type]
    //@i18n-ignore
    const aliPayPDF = get(invoiceOriginData, 'master.form.E_system_发票主体_attachmentType', '')
    //@i18n-ignore
    const ocrPDF = get(invoiceOriginData, 'master.form.E_system_发票主体_图片', '')
    const isFullDigital = ['FULL_DIGITAl_SPECIAL', 'FULL_DIGITAl_NORMAL'].includes(type) //全电票
    const hasPdf =
      source === 'UPLOAD' ||
      source === 'WECHAT_CARD' ||
      (source === 'ALIPAY_CARD' && aliPayPDF === 'PDF') ||
      (source === 'OCR' && ocrPDF.toLowerCase().endsWith('.pdf')) ||
      (source === 'AIFAPIAO' && ocrPDF.toLowerCase().endsWith('.pdf')) ||
      (isFullDigital && ocrPDF)
    const className = Delete ? 'down-pdf' : 'pdf'
    return (
      <InvoiceHeader
        title={title}
        hasPdf={hasPdf}
        isFullDigital={isFullDigital}
        Delete={Delete}
        className={className}
        handleDownloadPDF={this.handleDownloadPDF}
        handleConfirm={this.handleConfirm}
      />
    )
  }

  renderInvoiceBody = (invoiceInfo, isOld) => {
    const { invoice, checkout, permissions, isFrom, canSeeLink, showAllFeeType, payerInfoArr } = this.props
    let { invoiceAllDetails, isLoading, ischeck, message, status } = this.state
    const { buyer, taxIdNum, masterItems, detailItems, remark, type } = invoiceInfo
    const isFullDigital = ['FULL_DIGITAl_SPECIAL', 'FULL_DIGITAl_NORMAL'].includes(type)
    let masterInfoItems = masterItems
    if (isFullDigital) {
      const blackList = ['发票代码', '购买方地址电话', '购买方开户行及账号']
      masterInfoItems = masterInfoItems.filter(el => !blackList.includes(el?.label))
    }
    if (!invoiceInfo) {
      return null
    }
    if (ischeck === undefined) {
      ischeck = invoice.ischeck
    }
    if (message === undefined) {
      message = invoice.message
    }
    if (status === undefined) {
      status = invoice.status
    }
    let number = 0
    const { checkPayerName, checkPayerNumber } = checkPayerInfo(buyer.value, isOld)
    const { invamt, invtaxamt } = this.fnGetAllAmount(invoiceInfo)
    return (
      <div className={styles.invoice_card_body}>
        <InvoicePayerInfo
          isFrom={isFrom}
          ischeck={ischeck}
          message={message}
          status={status}
          buyer={buyer}
          taxIdNum={taxIdNum}
          checkPayerName={checkPayerName}
          checkPayerNumber={checkPayerNumber}
          payerInfoArr={payerInfoArr}
          invoice={invoice}
        />
        <InvoiceMasterInfo dataSource={masterInfoItems} />
        <InvoiceRemark remark={remark} />
        <InvoiceDetailTip invoiceAllDetails={invoiceAllDetails} checkout={checkout} />
        <InvoiceDetails
          number={number}
          dataSource={detailItems}
          checkout={checkout}
          permissions={permissions}
          canSeeLink={canSeeLink}
          showAllFeeType={showAllFeeType}
        />
        <InvoiceTotal invamt={invamt} invtaxamt={invtaxamt} number={number} />
        {!(invoiceAllDetails || checkout || isOld) && (
          <InvoiceAllDetail
            invoice={invoice}
            isLoading={isLoading}
            checkout={checkout}
            handleGetInvoiceDetailsById={this.handleGetInvoiceDetailsById}
          />
        )}
      </div>
    )
  }

  render() {
    const { invoice, hiddenHeader, className = '' } = this.props
    const { invoiceAllDetails } = this.state
    const invoiceData = invoiceAllDetails ? invoiceAllDetails : invoice
    if (!invoice) return null
    const { invoiceInfo, isOld } = formatInvoiceData({ data: invoiceData })

    return (
      <div className={styles.invoice_card_wrap}>
        <ThirdCardWrap
          className={className}
          header={this.renderInvoiceHeader(invoiceInfo, invoiceData)}
          body={this.renderInvoiceBody(invoiceInfo, isOld)}
          hiddenHeader={hiddenHeader}
        />
      </div>
    )
  }
}
