import { __assign, __extends, __rest } from "tslib";
import React, { PureComponent } from 'react';
import styles from './StandardDateSelectDrodown/DateSelectDrodown.module.less';
import { Menu, Dropdown } from 'antd';
import DateSelectDrodown from './StandardDateSelectDrodown/DateSelectDrodown';
import InputSelect from '../input-selector';
var StandardDateSelect = (function (_super) {
    __extends(StandardDateSelect, _super);
    function StandardDateSelect(props) {
        var _this = _super.call(this, props) || this;
        _this.result = [];
        _this.saveDateValue = function (result) {
            var _a = _this.props, getDateResult = _a.getDateResult, dataIndex = _a.dataIndex;
            var value = { result: result, dataIndex: dataIndex };
            getDateResult && getDateResult(value);
            _this.setState({
                visible: false
            });
        };
        _this.onVisibleChange = function (visible) {
            var _a = _this.props, getDateResult = _a.getDateResult, record = _a.record;
            if (!visible) {
                getDateResult && getDateResult((record && record.DATE_SAVE && record.DATE_SAVE.datas) || []);
            }
            _this.setState({ visible: visible });
        };
        _this.menu = function (_a) {
            var saveDateValue = _a.saveDateValue, data = _a.data;
            return (React.createElement(Menu, null,
                React.createElement(Menu.Item, null,
                    React.createElement(DateSelectDrodown, { saveDateValue: saveDateValue, data: data }))));
        };
        _this.state = {
            visible: true
        };
        return _this;
    }
    StandardDateSelect.prototype.render = function () {
        var _a = this.props, placeholder = _a.placeholder, onSelect = _a.onSelect, dataIndex = _a.dataIndex, record = _a.record, others = __rest(_a, ["placeholder", "onSelect", "dataIndex", "record"]);
        var visible = this.state.visible;
        return (React.createElement(React.Fragment, null,
            React.createElement(Dropdown, { overlay: this.menu({
                    saveDateValue: this.saveDateValue,
                    data: (record && record.DATE_SAVE && record.DATE_SAVE.datas) || []
                }), visible: visible, trigger: ['click'], placement: 'bottomCenter', onVisibleChange: this.onVisibleChange, overlayClassName: styles['expense-standard-dropdown'] },
                React.createElement("div", null,
                    React.createElement(InputSelect, __assign({ placeholder: placeholder, onSelect: function () { return onSelect && onSelect(); } }, others, { value: record[dataIndex] }))))));
    };
    return StandardDateSelect;
}(PureComponent));
export default StandardDateSelect;
