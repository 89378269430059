export const TITLE_CONFIG = {
  all: i18n.get('全部'),
  expense: i18n.get('报销单'),
  loan: window.IS_SMG ? i18n.get('预支单') : i18n.get('借款单'),
  requisition: i18n.get('申请单'),
  custom: i18n.get('基础单据'),
  receipt: i18n.get('收款单'),
  payment: i18n.get('付款单'),
  permit: i18n.get('授权单')
}

export const ICON_CONFIG = {
  expense: i18n.get('报销'),
  loan: window.IS_SMG ? i18n.get('预支') : i18n.get('借款'),
  requisition: i18n.get('申请')
}

export const SETTLEMENT = {
  PRECHARGE: i18n.get('冲抵预充值'),
  NOSETTLEMENT: i18n.get('无需支付'),
  AFTERSETTLEMENT: i18n.get('统一结算')
}

// export const PAYEE_INFO = {
//   ability: 'feeDetail',
//   active: true,
//   canAsDimension: false,
//   cnLabel: '收款信息',
//   cnPlaceholder: null,
//   dataType: { type: 'ref', entity: 'pay.PayeeInfo' },
//   defaultValue: { value: 'entity.default', type: 'predefine' },
//   dependence: null,
//   editable: true,
//   enLabel: '收款信息',
//   enPlaceholder: null,
//   field: 'feeDetailPayeeId',
//   // field: 'payeeId',
//   hide: false,
//   label: '收款信息',
//   name: 'feeDetailPayeeId',
//   // name: 'payeeId',
//   optional: false,
//   placeholder: '请选择收款信息',
//   showInDetails: false,
//   type: 'payeeInfo'
// }

export const afpAuthUrl = 'https://xbox.aifapiao.com/aifapiao-auth/index.html'
