import { __extends, __read } from "tslib";
import React, { PureComponent } from 'react';
import styles from './DateSelectDrodown.module.less';
import { Button, Form } from 'antd';
import { Dynamic } from '@ekuaibao/template';
import { elements } from './elements';
import { templates } from './utils/config';
import MessageCenter from '@ekuaibao/messagecenter';
import { numberToStringMap, stringToNumberMap } from '../utils';
import { get } from 'lodash';
var layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
function create(T) {
    return Form.create({
        onValuesChange: function (props, values) { }
    })(T);
}
var DateSelectDrodown = (function (_super) {
    __extends(DateSelectDrodown, _super);
    function DateSelectDrodown(props) {
        var _this = _super.call(this, props) || this;
        _this.bus = new MessageCenter();
        _this.initValue = function (valueList) {
            var formatValue = {};
            if (!valueList.length) {
                return;
            }
            formatValue['RANGE'] = { isChecked: false, values: { start: { month: '', day: '' }, end: { month: '', day: '' } } };
            valueList.forEach(function (item) {
                if (item.type === 'RANGE') {
                    var _a = __read(item.start.split('-'), 2), startM = _a[0], startD = _a[1];
                    var _b = __read(item.end.split('-'), 2), endM = _b[0], endD = _b[1];
                    formatValue['RANGE'] = {
                        values: { start: { month: startM, day: startD }, end: { month: endM, day: endD } },
                        type: 'RANGE',
                        isChecked: true
                    };
                }
                else if (item.type === 'WEEK') {
                    var weeks = item.value || [];
                    var weekList = weeks.map(function (oo) { return numberToStringMap[oo]; });
                    formatValue['WEEK'] = { selectedTags: weekList, isChecked: true, type: 'WEEK' };
                }
            });
            return formatValue;
        };
        _this.formatValues = function (result) {
            var valueList = [];
            if (result.RANGE) {
                var start = get(result, 'RANGE.values.start');
                var end = get(result, 'RANGE.values.end');
                var str = { type: 'RANGE', start: start.month + "-" + start.day, end: end.month + "-" + end.day };
                valueList.push(str);
            }
            if (result.WEEK) {
                var selectedTags = get(result, 'WEEK.selectedTags', []);
                var weeks = selectedTags.map(function (oo) { return stringToNumberMap[oo]; });
                var str1 = { type: 'WEEK', value: weeks };
                valueList.push(str1);
            }
            return valueList;
        };
        _this.handleValueSave = function () {
            var saveDateValue = _this.props.saveDateValue;
            _this.bus.getValueWithValidate().then(function (res) {
                Object.keys(res).forEach(function (line) {
                    if (!res[line].isChecked) {
                        delete res[line];
                    }
                });
                var result = _this.formatValues(res);
                saveDateValue && saveDateValue(result);
            });
        };
        var value = props.data && _this.initValue(props.data);
        _this.state = {
            value: value
        };
        return _this;
    }
    DateSelectDrodown.prototype.render = function () {
        var value = this.state.value;
        return (React.createElement("div", { id: "DateSelectModal", className: styles['date-select-wrapper'] },
            React.createElement("div", { className: "date-select-content" },
                React.createElement(Dynamic, { value: value, bus: this.bus, create: create, template: templates, elements: elements, layout: layout })),
            React.createElement("div", { className: "date-select-footer" },
                React.createElement(Button, { type: "primary", key: "ok", size: "large", onClick: this.handleValueSave }, i18n.get('保存')))));
    };
    return DateSelectDrodown;
}(PureComponent));
export default DateSelectDrodown;
