import loadable from '@loadable/component';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var InterconInput = loadableWithDescriptor(function () { return import('./interconnectal/InterconInput'); }, {
    type: 'interconnectal-text'
});
var InterconSelect = loadableWithDescriptor(function () { return import('./interconnectal/InterconSelect'); }, {
    type: 'interconnectal-select'
});
var LedgerStatisticFieldSelect = loadableWithDescriptor(function () { return import('./interconnectal/LedgerStatisticsFieldSelect'); }, {
    type: 'ledger-statistics-field-select'
});
var InterconSelectCascader = loadableWithDescriptor(function () { return import('./interconnectal/InterconSelectCascader'); }, {
    type: 'interconnectal-select-cascader'
});
var InterconRadioGroup = loadableWithDescriptor(function () { return import('./interconnectal/InterconRadioGroup'); }, {
    type: 'interconnectal-radio-group'
});
var InterconRadioGroupSelect = loadableWithDescriptor(function () { return import('./interconnectal/InterconRadioGroupSelect'); }, {
    type: 'interconnectal-radio-group-select'
});
var InterconSelectRelation = loadableWithDescriptor(function () { return import('./interconnectal/InterconSelectRelation'); }, {
    type: 'interconnectal-select-relation'
});
var Icon = loadableWithDescriptor(function () { return import('./interconnectal/Icon'); }, {
    type: 'icon'
});
var ImportMethod = loadableWithDescriptor(function () { return import('./interconnectal/ImportMethod'); }, {
    type: 'interconnectal-import-way'
});
var PlatFormAdmin = loadableWithDescriptor(function () { return import('./interconnectal/PlatFormAdmin'); }, {
    type: 'select:admin'
});
var SelectStaff = loadableWithDescriptor(function () { return import('./interconnectal/SelectStaff'); }, {
    type: 'select:staff'
});
var Entity = loadableWithDescriptor(function () { return import('./interconnectal/Entity'); }, {
    type: 'entities'
});
var EntityTree = loadableWithDescriptor(function () { return import('./interconnectal/EntityTree'); }, {
    type: 'entitiestree'
});
var InterconSeparator = loadableWithDescriptor(function () { return import('./interconnectal/InterconSeparator'); }, {
    type: 'interconnectal-separator'
});
var RefFeetypeInputTags = loadableWithDescriptor(function () { return import('./dynamic/RefFeetypeInputTags'); }, {
    type: 'ref:feetype:input:tags'
});
var InterconTreeSelectInterface = loadableWithDescriptor(function () { return import('./interconnectal/InterconTreeSelect'); }, {
    type: 'interconnectal:tree:select'
});
var CustomDimensionFilter = loadableWithDescriptor(function () { return import('./interconnectal/CustomDimensionFilter'); }, {
    type: 'custom-dimension-filter'
});
var BillStateSelector = loadableWithDescriptor(function () { return import('./interconnectal/BillStateSelector'); }, {
    type: 'limit:bill:state'
});
var InterconTagSelect = loadableWithDescriptor(function () { return import('./interconnectal/InterconTagSelect'); }, {
    type: 'interconnectal-tag-select'
});
var InterconLabel = loadableWithDescriptor(function () { return import('./interconnectal/InterconLable'); }, {
    type: 'interconnectal-label'
});
var RecordLogCheckBox = loadableWithDescriptor(function () { return import('./interconnectal/RecordLogCheckBox'); }, {
    type: 'reacord-log-checkbox'
});
var CheckBox = loadableWithDescriptor(function () { return import('./internal/CheckBox'); }, {
    type: 'checkbox'
});
var MappingRelation = loadableWithDescriptor(function () { return import('./interconnectal/MappingRelation'); }, {
    type: 'mapping:relation'
});
var Unknown = loadableWithDescriptor(function () { return import('./internal/Unknown'); }, { type: 'unknown' });
var interconnectalDataClear = loadableWithDescriptor(function () { return import('./interconnectal/interconnectalDataClear'); }, {
    type: 'interconnectal-dataclear'
});
var interInitDataCurrency = loadableWithDescriptor(function () { return import('./interconnectal/interInitDataCurrency'); }, {
    type: 'interconnectal-data-currency'
});
var TaxRuleField = loadableWithDescriptor(function () { return import('./interconnectal/TaxRuleField'); }, {
    type: 'interconnectal-tax-rule'
});
var interInitExcelDataSet = loadableWithDescriptor(function () { return import('./interconnectal/interconnectal-excel-dataset'); }, {
    type: 'interconnectal-excel-dataset'
});
export var interconnectal = [
    InterconInput,
    InterconSelect,
    InterconRadioGroup,
    InterconRadioGroupSelect,
    InterconSelectRelation,
    Icon,
    ImportMethod,
    PlatFormAdmin,
    Entity,
    InterconSeparator,
    RefFeetypeInputTags,
    InterconTreeSelectInterface,
    InterconSelectCascader,
    CustomDimensionFilter,
    BillStateSelector,
    InterconTagSelect,
    LedgerStatisticFieldSelect,
    EntityTree,
    InterconLabel,
    RecordLogCheckBox,
    CheckBox,
    SelectStaff,
    MappingRelation,
    interInitDataCurrency,
    TaxRuleField,
    interconnectalDataClear,
    interInitExcelDataSet,
    Unknown
];
