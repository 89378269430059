/***************************************************
 * Created by nanyuantingfeng on 2020/4/21 16:07. *
 ***************************************************/
import { cloneDeep, get } from 'lodash'
import { related } from '../../../elements/feeDetailViewList/Related'

export function formatNewTemplateValue(value = {}, template = [], isChangeTemplate) {
  const v = cloneDeep(value)
  Object.keys(v).forEach(key => {
    const field = template.find(item => item.field === key)
    const type = get(field, 'dataType.type', '')
    if (field && !field.editable && field.field !== 'invoiceForm' && type !== 'text' && type !== 'ref') {
      if (type === 'date' && get(field, 'defaultValue.value') === 'firstSubmit.date') {
        // 首次送审日期,数据不删除,还把数据保留
        // delete v[key]
      } else {
        delete v[key]
      }
    }
    if (field && field.type === 'splitCalculation') {
      delete v[key]
    }
    // 切换模板时如果是固定值且有值，把之前的值清除了
    if (
      isChangeTemplate &&
      v[key] &&
      !field?.editable &&
      field?.defaultValue?.type === 'constant' &&
      field?.defaultValue?.value
    ) {
      v[key] = field?.defaultValue?.value
    }
    if (key === 'expenseLink' || key === 'expenseLinks') {
      fnClearExpenseLink(template, v, key)
    }
    // 交易类型
    if (key && key === 'u_交易类型') {
      v[key] = v[key] ? v[key] : {}
      v[key].id = field?.defaultValue?.value?.id || ''
    }
  })
  return v
}

function fnClearExpenseLink(template, v, key) {
  const expenseLink = template.find(line => line.field === 'expenseLink' || line.field === 'expenseLinks') || {}
  const expenseLinkFlag = key !== expenseLink.field
  // expenseLinks 多选单选切换时
  const expenseLinksFlag =
    key === 'expenseLinks' && !get(expenseLink, 'allowMultipleRequests') && get(v, 'expenseLinks', []).length > 1
  if (expenseLinkFlag || expenseLinksFlag) {
    v.details &&
      v.details.forEach(line => {
        if (line.feeTypeForm.linkDetailEntities) {
          delete line.feeTypeForm.linkDetailEntities
        }
      })
    delete v[key]
    related.clearRelatedData()
  }
}
