/**************************************************
 * Created by kaili on 2017/7/24 下午5:15.
 **************************************************/
import styles from './FormWrapper.module.less'
import React, { Children } from 'react'
import classnames from 'classnames'
import { Form, Tooltip } from 'antd'
import { FormItem } from './FormItem'
import FormItemReadonly from './FormItem4Readonly'
import SVG_AUTOCALCULATE from '../../images/auto-calculate.svg'
import IMAGE_TIP from '../../images/custom-plan-tip.svg'
import fnGetFieldLabel from '../utils/fnGetFieldLabel'
import { fnHideFields, fnHideFieldsNote, fnFlowHideFields } from '../utils/fnHideFields'
import { isIE } from '../../lib/misc'
import { fnFlowShowFields } from '../utils/fnShowFields'

export const wrapper = (readonly, wrapLayout, labelStyle = {}) => (props, CC) => {
  let {
    field,
    layout,
    isNewStyle,
    external,
    isForbid,
    stackerManager,
    autoCalFields = {},
    multiplePayeesMode = false,
    payPlanMode,
    payeePayPlan,
    keel,
    isPopup,
    labelNoWrap,
    template = [],
    flowId,
    validateError = [],
    isDetail,
    detailId,
    canEditNote,
    offsetWidth,
    billSpecification,
    labelAlign,
    noPayInfo,
    isAlign = false,
    dataSource,
    isModify,
    originalValue
  } = props
  let {
    label,
    defaultValue,
    editable,
    isEdit,
    optional,
    configs,
    visible,
    hiddenLabel,
    labelView,
    className,
    hideInput,
    labelSize,
    name,
    hide,
    showLabel = true,
    noColon,
    dataLinkHiddenLabel = false,
    hideVisibility,
    attributeHide = false,
    isReview = false,
    whiteVisibility,
    hasAttrHide = false,
    dataType = {},
    open,
    ability,
    extra = '',
    blockUIConfig = {},
    groupSubtitle,
    groupTitle,
    currentNodeShowField // 审批流节点里面配置的当前节点下字段是否显示
  } = field
  label = fnGetFieldLabel(field)
  const billType = billSpecification?.type
  const supplierBills = ['reconciliation', 'settlement']
  const blackName = ['supplierSettlement', 'supplierReconciliation']
  let style = {}
  // visible === undefined 当做 true  处理
  if (visible === false) {
    style.display = 'none'
  }
  if (!showLabel) {
    labelStyle = { ...labelStyle, display: 'none' }
  }

  // 处理互联卡片隐藏操作（只读状态下展示的逻辑）
  // PEER_AUDIT_MAIN(同行人稽查) 会在后面的代码里面单独处理
  const isBlockUI =
    dataType?.entity?.startsWith('connect.BlockUI') && dataType?.entity !== 'connect.BlockUI.PEER_AUDIT_MAIN'

  if (!readonly && isBlockUI) {
    const entityStr = dataType.entity
    const count = entityStr.lastIndexOf('.')
    let id = entityStr.substring(count + 1, entityStr.length)
    if (entityStr.indexOf('connect.BlockUI.widgetCard') >= 0) {
      id = 'loanStatisticsWidgetCard'
    }
    //id目前有 APPROVAL_STATISTICS(审批统计) ENTERPRISE_PURCHASE_ORDER(合思企业购) ADJUSTMENTNODE(KA预算调整) LOAN_STATISTICS(借款统计)
    if (blockUIConfig?.[id] === 'hide') {
      style.display = 'none'
    }
  }

  layout = wrapLayout ? wrapLayout : layout
  if (label === '' && layout.wrapperCol && layout.labelCol) {
    layout.wrapperCol.offset = layout.labelCol.span
  }
  let Component = !!readonly ? FormItemReadonly : FormItem
  let clsNames = isNewStyle ? classnames('label-style') : ''

  if (labelSize === 'large') {
    clsNames = classnames('large-style')
  }
  if (labelSize === 'large-normal') {
    clsNames = classnames('large-normal')
  }
  const isLegalEntityMultiCurrency = name === 'legalEntityMultiCurrency'
  const { onFields = [], oldOnFields = [] } = autoCalFields
  const isFormula = defaultValue && defaultValue.type === 'formula'
  const isFormulaAttr = Array.isArray(configs) && !!~configs.findIndex(el => el.ability === 'caculate')
  const isFormulaCheck = Array.isArray(configs) && !!~configs.findIndex(el => el.ability === 'check')
  const isNewCostStandard = defaultValue && defaultValue.type === 'costStandard'
  const isOldCostStandard = oldOnFields?.includes(name) && !isFormula //旧差标
  const isVertical = layout === 'vertical'
  const isNeedMaxWidth = (isFormula && !editable) || isNewCostStandard || isOldCostStandard //有自动计算的图标时需要设置最大宽度
  const isSingleCol = template.length && template.filter(item => item.length === 1).length === template.length //是否是一列布局
  const labelV = () => {
    return labelView ? (
      labelView(label, clsNames, CC.props)
    ) : (
      <>
        <span
          style={labelStyle}
          className={classnames(clsNames, {
            'text-nowrap-ellipsis': !isEdit && !isVertical,
            'risk-warning-label-vertical': isNeedMaxWidth && isVertical && isSingleCol,
            'risk-warning-label-icon-horizontal': !isEdit && isNeedMaxWidth && !isVertical,
            'risk-warning-label-horizontal': !isEdit && !isVertical && !isNeedMaxWidth,
            'ie-risk-warning-label-horizontal':
              !isEdit && !isVertical && !isNeedMaxWidth && external && external.length && isIE()
          })}
        >
          {!!label && label.length > 4 ? (
            <Tooltip placement="topLeft" getPopupContainer={() => document.body} title={label}>{`${
              isAlign && label.length > 5 ? `${label.substring(0, 5)}...` : label
            }`}</Tooltip>
          ) : (
            `${label}`
          )}
        </span>
        {field?.optionalPayeeByZero && (
          <Tooltip title={i18n.get('支付金额不为0时收款信息必填')}>
            <img className="stand-16-icon ml-5" src={IMAGE_TIP} />
          </Tooltip>
        )}
        {isFormula && !editable && (
          <Tooltip title={i18n.get('系统自动计算，不可修改，无需再次审核')}>
            <img className="img-wrapper" src={SVG_AUTOCALCULATE} />
          </Tooltip>
        )}
        {(isNewCostStandard || isOldCostStandard) && (
          <Tooltip title={i18n.get('系统费标计算，不可修改，无需再次审核')}>
            <img className="img-wrapper" src={SVG_AUTOCALCULATE} />
          </Tooltip>
        )}
        {isFormulaAttr && (
          <Tooltip title={i18n.get('由系统条件配置判断选必填')}>
            <img className="img-wrapper ml-5" src={SVG_AUTOCALCULATE} />
          </Tooltip>
        )}
        {isFormulaCheck && (
          <Tooltip title={i18n.get('由系统条件配置校验规则')}>
            <img className="img-wrapper ml-5" src={SVG_AUTOCALCULATE} />
          </Tooltip>
        )}
        {isLegalEntityMultiCurrency && (
          <Tooltip title={i18n.get('法人实体多币种切换后，已有的费用明细会受影响，建议删除并重新添加明细')}>
            <img className="stand-16-icon ml-5" src={IMAGE_TIP} />
          </Tooltip>
        )}
      </>
    )
  }

  let mlabel = label && labelV()
  mlabel = !dataLinkHiddenLabel ? mlabel : ''
  const hasInput = !hideInput || !hideInput(CC.props)
  let hasStaffs = true
  const { departments, roles, staffs } = hideVisibility || { departments: [], roles: [], staffs: [] }
  if (!departments?.length && !roles?.length && !staffs?.length) {
    hasStaffs = false
  }
  let hasAuto = configs === null ? false : configs?.find(r => r?.property === 'hide') === undefined ? false : true
  let flowFieldShow = true
  if ((dataSource?.plan || originalValue?.plan) && isModify) {
    const plan = dataSource?.plan || originalValue?.plan
    const { currentNodeShowFieldMap } = fnFlowShowFields(plan)
    if (currentNodeShowFieldMap[field.name]) {
      currentNodeShowField = true
    }
  }
  if (currentNodeShowField) {
    flowFieldShow = false
  } else if (isReview) {
    //blockUI 字段类型 同行人稽查全局字段的处理逻辑
    let isShow = fnHideFields(isReview, whiteVisibility) && readonly
    if (!isShow) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
      flowFieldShow = false
    }
  } else if (
    !hide ||
    (hide && !hasStaffs && !hasAuto) ||
    (hide && fnHideFieldsNote(hideVisibility) && !hasAuto) ||
    (hide && fnHideFieldsNote(hideVisibility) && hasAuto) ||
    (hide && !fnHideFieldsNote(hideVisibility) && !hasAuto)
  ) {
    let isShow =
      (hide && fnHideFieldsNote(hideVisibility)) ||
      !hide ||
      validateError.includes(name) ||
      (external && external.length)
    if (!supplierBills.includes(billType) && blackName.includes(name)) {
      isShow = false
    }
    if (!isShow) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
      flowFieldShow = false
    }
  } else if ((hide && !hasStaffs && hasAuto) || (hide && !fnHideFieldsNote(hideVisibility) && hasAuto)) {
    let isShow = (hide && !attributeHide) || validateError.includes(name) || (external && external.length)
    if (!supplierBills.includes(billType) && blackName.includes(name)) {
      isShow = false
    }
    if (!isShow) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
      flowFieldShow = false
    }
  }
  //审批流是否设置了字段隐藏
  if (flowFieldShow) {
    const flowHiddenFields = fnFlowHideFields(dataSource?.plan)
    if (flowHiddenFields.includes(name)) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
    }
  }

  /**
   * 分摊必填
   * 禁止隐藏
   */
  if (open && ability === 'apportion') {
    style = {}
  }

  layout = typeof layout === 'string' ? { layout } : { ...layout }
  return (
    <>
      {groupTitle && <span className={styles['form-item-group-title']}>{groupTitle}</span>}
      {groupSubtitle && <span className={styles['form-item-group-sub-title']}>{groupSubtitle}</span>}
      <Component
        {...layout}
        labelAlign={labelAlign}
        offsetWidth={offsetWidth}
        labelNoWrap={labelNoWrap}
        noColon={noColon}
        label={!hiddenLabel ? mlabel : ''}
        className={classnames(
          `${className}`,
          { [styles['hidden-required']]: name === 'defaultValue' },
          { [styles['form-item-wrapper-editable']]: !readonly },
          styles['form-item-wrapper'],
          styles[className]
        )}
        flowId={flowId}
        required={!optional}
        style={style}
        extra={extra}
        external={external}
        isForbid={isForbid}
        isFeeType={!!(stackerManager || keel || isPopup)}
        multiplePayeesMode={multiplePayeesMode}
        payPlanMode={payPlanMode}
        payeePayPlan={payeePayPlan}
        help={hasInput ? undefined : ''}
        validateStatus={hasInput ? undefined : 'success'}
        field={field}
        isDetail={isDetail}
        detailId={detailId}
        canEditNote={canEditNote}
        noPayInfo={noPayInfo}
        isAlign={isAlign}
      >
        {!hasInput
          ? React.cloneElement(CC, {
              ...CC.props,
              hidden: true,
              style: { display: 'none' }
            })
          : Children.only(CC)}
      </Component>
    </>
  )
}

export const wrapperDataLinkEdit = (readonly, wrapLayout, labelStyle, showStatus = true, isTrip) => (props, CC) => {
  let {
    field,
    layout,
    isNewStyle,
    external,
    isForbid,
    multiplePayeesMode = false,
    stackerManager,
    keel,
    isDetail,
    detailId,
    validateError = [],
    billSpecification,
    dataSource
  } = props
  let {
    label,
    optional,
    visible,
    hiddenLabel,
    labelView,
    className,
    hideInput,
    labelSize,
    name,
    importMode,
    showLabel = true,
    dataLinkHiddenLabel = false,
    configs,
    hideVisibility,
    hide,
    attributeHide = false
  } = field
  const billType = billSpecification?.type
  const supplierBills = ['reconciliation', 'settlement']
  const blackName = ['supplierSettlement', 'supplierReconciliation']
  const isFormulaAttr = Array.isArray(configs) && !!~configs.findIndex(el => el.ability === 'caculate')
  let style = {}
  if (visible === false) {
    style.display = 'none'
  }
  layout = wrapLayout ? wrapLayout : layout
  if (label === '' && layout.wrapperCol && layout.labelCol) {
    layout.wrapperCol.offset = layout.labelCol.span
  }

  let Component = !!readonly ? FormItem : Form.Item

  if (isTrip) {
    Component = FormItem
  }

  let clsNames = isNewStyle ? classnames('label-style') : ''

  if (labelSize === 'large') {
    clsNames = classnames('large-style')
  }
  if (showLabel === false) {
    labelStyle = { ...labelStyle, display: 'none' }
  }
  const labelV = () => {
    return labelView ? (
      labelView(label, clsNames, CC.props)
    ) : (
      <>
        <span style={labelStyle} className={clsNames}>{`${label}`}</span>
        {isFormulaAttr && (
          <Tooltip title={i18n.get('由系统条件配置判断选必填')}>
            <img className="img-wrapper ml-5" src={SVG_AUTOCALCULATE} />
          </Tooltip>
        )}
      </>
    )
  }
  if (!showStatus && importMode === 'MULTIPLE') {
    showStatus = true
  }
  if (importMode === 'SINGLE') {
    if (!optional) {
      if (
        !showStatus &&
        (CC.props.value === undefined || (Array.isArray(CC.props.value) && CC.props.value.length === 0))
      ) {
        showStatus = true
      } else if (
        showStatus &&
        Array.isArray(CC.props.value) &&
        CC.props.value[0] &&
        CC.props.value[0].dataLinkTemplateId
      ) {
        showStatus = false
      }
    } else {
      showStatus = false
    }
  }

  label = fnGetFieldLabel(field)

  let mlabel = !!readonly ? label : labelV()
  mlabel = !dataLinkHiddenLabel ? mlabel : ''
  const hasInput = (!hideInput || !hideInput(CC.props)) && showStatus
  let hasStaffs = true
  const { departments, roles, staffs } = hideVisibility || { departments: [], roles: [], staffs: [] }
  if (!departments?.length && !roles?.length && !staffs?.length) {
    hasStaffs = false
  }
  let hasAuto = configs === null ? false : configs?.find(r => r?.property === 'hide') === undefined ? false : true
  let flowFieldShow = true
  if (
    !hide ||
    (hide && !hasStaffs && !hasAuto) ||
    (hide && fnHideFieldsNote(hideVisibility) && !hasAuto) ||
    (hide && fnHideFieldsNote(hideVisibility) && hasAuto) ||
    (hide && !fnHideFieldsNote(hideVisibility) && !hasAuto)
  ) {
    let isShow =
      (hide && fnHideFieldsNote(hideVisibility)) ||
      !hide ||
      validateError.includes(name) ||
      (external && external.length)
    if (!supplierBills.includes(billType) && blackName.includes(name)) {
      isShow = false
    }
    if (!isShow) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
      flowFieldShow = false
    }
  } else if ((hide && !hasStaffs && hasAuto) || (hide && !fnHideFieldsNote(hideVisibility) && hasAuto)) {
    let isShow = (hide && !attributeHide) || validateError.includes(name) || (external && external.length)
    if (!supplierBills.includes(billType) && blackName.includes(name)) {
      isShow = false
    }
    if (!isShow) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
      flowFieldShow = false
    }
  }

  //审批流是否设置了字段隐藏
  if (flowFieldShow) {
    const flowHiddenFields = fnFlowHideFields(dataSource?.plan)
    if (flowHiddenFields.includes(name)) {
      style = {
        height: '0px',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
      }
    }
  }

  return (
    <Component
      {...layout}
      label={!hiddenLabel ? mlabel : ''}
      className={classnames(
        { [styles['hidden-required']]: name === 'defaultValue' },
        styles['form-item-wrapper'],
        styles[className]
      )}
      required={!optional}
      style={style}
      external={external}
      isForbid={isForbid}
      isFeeType={!!(stackerManager || keel)}
      multiplePayeesMode={multiplePayeesMode}
      help={hasInput ? undefined : ''}
      validateStatus={hasInput ? undefined : 'success'}
      field={field}
      isDetail={isDetail}
      detailId={detailId}
    >
      {!hasInput
        ? React.cloneElement(CC, {
            ...CC.props,
            hidden: true,
            style: { display: 'none' }
          })
        : Children.only(CC)}
    </Component>
  )
}
