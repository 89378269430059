import { __extends } from "tslib";
import React from 'react';
import { Provider } from '@ekuaibao/datagrid/esm/State';
import { Language } from '@ekuaibao/datagrid/esm/i18n/Language';
var LocaleProvider = (function (_super) {
    __extends(LocaleProvider, _super);
    function LocaleProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.i18nText = {
            groupPanelEmptyText: i18n.get('拖拽表头字段至此处可进行分组查看'),
            columnChooserEmptyText: i18n.get('拖拽表头字段至此处可隐藏'),
            columnChooserTitle: i18n.get('编辑表头字段'),
            cancelRowChanges: i18n.get('取消'),
            saveRowChanges: i18n.get('保存'),
            addRow: i18n.get('新增'),
            editRow: i18n.get('编辑'),
            deleteRow: i18n.get('删除'),
            noData: i18n.get('暂无数据'),
            ascendingText: i18n.get('升序'),
            descendingText: i18n.get('降序'),
            clearText: i18n.get('清除排序'),
            submit: i18n.get('确定'),
            reset: i18n.get('重置'),
            columnChooserTip: i18n.get('点击编辑表头字段'),
            manualSizeForOnePage: function (args) { return i18n.get('manualSizeForOnePage', args); },
            autoSizeForOnePage: function (args) { return i18n.get('autoSizeForOnePage', args); },
            prevPage: i18n.get('上一页'),
            nextPage: i18n.get('下一页'),
            manualOptionJump: i18n.get('翻页，每页加载'),
            manualNumbers: i18n.get('条'),
            autoOptionJump: function (args) { return i18n.get('autoOptionJump', args); },
            cancel: i18n.get('取消'),
            pageJumpConfig: i18n.get('跳转到第'),
            pageJumpNumber: i18n.get('页'),
            selectAllText: i18n.get('选择全部'),
            selectedLabel: function (args) { return i18n.get('selectedLabel', args); },
            selectedField: i18n.get('已选择字段'),
            canSelectField: i18n.get('可选择字段'),
            selectAll: i18n.get('全部选择'),
            cancelAll: i18n.get('取消全部'),
            DragOrderAble: i18n.get('可拖拽排序'),
            selectInLeftBox: i18n.get('请从左侧框中点击选择字段'),
            transforEmpty: i18n.get('请选择要转移的字段')
        };
        return _this;
    }
    LocaleProvider.prototype.render = function () {
        return (React.createElement(Provider, null,
            React.createElement(Language, { texts: this.i18nText }),
            this.props.children));
    };
    return LocaleProvider;
}(React.PureComponent));
export { LocaleProvider };
