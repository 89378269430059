import { __awaiter, __generator } from "tslib";
import { Fetch } from '@ekuaibao/fetch';
import { getNOEKBSET } from '@ekuaibao/noekb';
import { app } from '@ekuaibao/whispered';
window.NOEKBSET = {};
var LOCAL_CONFIG_POWERCODE = '170036';
export default initializeTitle;
import { updateScopeVariable } from './updateScopeVariable';
export function initializeTitle() {
    return __awaiter(this, void 0, void 0, function () {
        var brandName, _a, language, _b, corpWordsReplaceItems, brand;
        return __generator(this, function (_c) {
            brandName = IS_HSFK ? 'hose' : 'ekuaibao';
            _a = app.sdk.staffSetting, language = _a.language, _b = _a.corpWordsReplaceItems, corpWordsReplaceItems = _b === void 0 ? [] : _b;
            brand = corpWordsReplaceItems.find(function (v) {
                return v.language === language && v.brandName === brandName;
            });
            document.title = i18n.get("\u6613\u5FEB\u62A5@@" + Fetch.ekbCorpId, null, function () { return (brand && brand.replaceKey) || i18n.get('易快报专业版'); });
            updateScopeVariable(app.sdk.staffSetting);
            if (IS_NOEKB) {
                window.NOEKBSET = getNOEKBSET(Fetch.ekbCorpId);
                document.title = i18n.get(window.NOEKBSET.title);
            }
            fetchLocalConfigPower().then(function (resp) {
                var value = (resp || {}).value;
                if (value === null || value === void 0 ? void 0 : value.state = 'using') {
                    changeFavicon();
                }
            });
            return [2];
        });
    });
}
var fetchLocalConfigPower = function () {
    return Fetch.GET("/api/charge/powers/detail/$" + LOCAL_CONFIG_POWERCODE);
};
export function changeFavicon(link) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, value, resp, _b, items, $faviconArr, $favicon;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!!link) return [3, 3];
                    return [4, Fetch.GET('/api/v1/organization/corporations/select')];
                case 1:
                    _a = (_c.sent()).value, value = _a === void 0 ? {} : _a;
                    if (!(value === null || value === void 0 ? void 0 : value.favoriteIconId)) return [3, 3];
                    return [4, Fetch.GET("/api/v1/attachment/attachments/[" + (value === null || value === void 0 ? void 0 : value.favoriteIconId) + "]")];
                case 2:
                    resp = _c.sent();
                    _b = resp.items, items = _b === void 0 ? [] : _b;
                    if (items && items[0]) {
                        link = items[0].url;
                    }
                    _c.label = 3;
                case 3:
                    if (!link)
                        return [2];
                    $faviconArr = document.querySelectorAll('link[rel~=icon]');
                    if ($faviconArr === null || $faviconArr === void 0 ? void 0 : $faviconArr.length) {
                        Array.prototype.forEach.call($faviconArr, function (faviconItem) {
                            faviconItem.href = link;
                        });
                    }
                    else {
                        $favicon = document.createElement('link');
                        $favicon.rel = 'icon';
                        $favicon.href = link;
                        document.head.appendChild($favicon);
                    }
                    return [2];
            }
        });
    });
}
