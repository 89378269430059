/**************************************************
 * Created by nanyuantingfeng on 15/09/2017 16:21.
 **************************************************/
import { parseMeta2ColumnOthers, parseMeta2ColumSpecials } from './columnOthers'
import parseFilterType from './parseFilterType'
import { get } from 'lodash'

export function prefix(name, prefixPath = '', exclude = []) {
  if (typeof prefixPath === 'string') {
    prefixPath = { '*': prefixPath }
  }

  if (!prefixPath[name] && !!~exclude.indexOf(name)) {
    return name
  }

  if (prefixPath[name] === '$') {
    return name
  }

  if (prefixPath[name]) {
    return `${prefixPath[name]}.${name}`
  }

  if (prefixPath['*']) {
    return `${prefixPath['*']}.${name}`
  }

  return name
}

const COLUMN_PROPERTY_MAPPING = {}

export function getColumnPropertyMapping() {
  return COLUMN_PROPERTY_MAPPING
}

function parseMeta2Column(property, prefixPath, mapping, specialColumn, dynamicChannelMap, Express, isDiDi, scenesType) {
  let { name, label, dataType, canAsDimension = false } = property
  const { type, entity } = dataType
  const exclude = mapping.exclude
  //待办列表中关联申请和补充申请无法搜索出数据问题    解决PRO-11984问题
  if (name === 'expenseLink' || name === 'linkRequisitionInfo') {
    name = `${name}.name`
  }
  const dataIndex = prefix(name, prefixPath, exclude)
  const others = parseMeta2ColumnOthers(name, property, dynamicChannelMap, Express, scenesType)
  COLUMN_PROPERTY_MAPPING[dataIndex] = property
  let specialList = ['submitterId', 'title', 'loanDate', 'repaymentDate', 'loanMoney', 'ownerId']
  let obj = {
    title: label,
    dataIndex,
    key: dataIndex,
    dataType: type,
    filterType: parseFilterType(property, type),
    sorter: true,
    label,
    canAsDimension,
    entity,
    value: dataIndex,
    className: 'fs-14',
    ...others
  }
  if (specialColumn && specialList.indexOf(name) >= 0) {
    obj.render = parseMeta2ColumSpecials(name, specialColumn)
  }
  if (property.name === 'staffName' && isDiDi) {
    obj.sorter = false
    delete obj.filterType
  }
  return obj
}

export function createColumns(
  baseDataProperties = [],
  prefixPath,
  mapping,
  specialColumn,
  dynamicChannelMap,
  Express,
  isDiDi,
  scenesType
) {
  const filterAbilityList = ['feeDetail', 'apportion', 'loanInfo']
  const filterDateTypeList = ['dynamic', 'list', 'profitAndLossSummary']
  const filterNameList = ['requisitionBalance']
  return baseDataProperties
    .filter(line => !~filterNameList.indexOf(line.name))
    .filter(line => !~filterDateTypeList.indexOf(line.dataType.type) || line.name === 'expenseLinks')
    .filter(line => !~filterAbilityList.indexOf(line.ability))
    .filter(
      line =>
        !~[
          'details',
          'attachments',
          'feeTypeId',
          // 'feeDate',
          'feeDatePeriod',
          'consumptionReasons',
          'orders',
          'apportions',
          'apportionPercent',
          'apportionMoney',
          'amount',
          'payerId',
          'tripId',
          'tripType',
          'tripDate',
          'tripDatePeriod',
          'tripCity',
          'tripFromCity',
          'tripToCity',
          'tripForm',
          'tripTypeId'
        ].indexOf(line.name)
    )
    .filter(line => get(line, 'dataType.type') !== 'dateRange')
    .map(line => parseMeta2Column(line, prefixPath, mapping, specialColumn, dynamicChannelMap, Express, isDiDi, scenesType))
}

export function createColumnsSwitcherDataIndexes(type = 'all', prefixPath, mapping) {
  const array = mapping[type]
  const exclude = mapping.exclude
  return array.map(line => prefix(line, prefixPath, exclude))
}
