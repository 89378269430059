import 'regenerator-runtime/runtime';
import '@ekuaibao/platform.is';
import '@ekuaibao/polyfill/esm/date-fix-use-moment-api';
import '@ekuaibao/polyfill/esm/moment-fix-use-date-api';
import '@ekuaibao/polyfill/esm/number-fix-toFixed-api';
import 'isarray-polyfill-for-mobx4-observablearray';
if (!window.location.origin) {
    var _a = window.location, protocol = _a.protocol, hostname = _a.hostname, port = _a.port;
    window.location.origin = protocol + '//' + hostname + (port ? ":" + port : '');
}
window.alertMessage = function (msg) {
    require('@ekuaibao/show-util').showMessage.error(msg);
};
import '@ekuaibao/lib/lib/promise-wrapper';
import '../track';
import Loading from './loading';
import { showMessage } from '@ekuaibao/show-util';
var showLoading = function (msg) {
    Loading.showLoading(msg);
};
var hideLoading = function () {
    Loading.hideLoading();
};
window.__showMessage_error = function (message) {
    showMessage.error(message);
};
window.showLoading = showLoading;
window.hideLoading = hideLoading;
export function parseProxy() {
    var rawParse = JSON.parse;
    JSON.parse = function () {
        var str = arguments[0];
        var reviver = typeof arguments[1] == 'function'
            ? arguments[1]
            : function (_k, v) {
                return v;
            };
        try {
            return rawParse(str, reviver);
        }
        catch (err) {
            throw new SyntaxError("JSON\u89E3\u6790\u5931\u8D25\uFF1A" + JSON.stringify(str) + ", " + err.stack);
        }
    };
}
parseProxy();
