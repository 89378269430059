import { __assign } from "tslib";
export function getAvailableTree(tree, ids) {
    var result = [];
    tree.forEach(function (element) {
        if ((ids.indexOf(element.id) >= 0) || element.active) {
            result.push(element);
        }
        else {
            if (element.children && element.children.length > 0) {
                var ab = getAvailableTree(element.children, ids);
                var obj = __assign(__assign({}, element), { children: ab });
                if (ab && ab.length > 0) {
                    result.push(obj);
                }
            }
        }
    });
    return result;
}
