/***************************************************
 * Created by nanyuantingfeng on 2020/4/21 15:51. *
 ***************************************************/
import { get } from 'lodash'

function findInGlobalFields(component, globalFields = [], isDataLink) {
  let { field, label, cnLabel, enLabel } = component
  let def = globalFields.find(line => line.name === field)

  if (isDataLink && !def.dataType) {
    def.dataType = { type: def.type, entity: def.entity, elemType: def.elemType }
  }

  let { dataType = {}, name } = def || {}
  let { type, entity, elemType } = dataType

  let newType = component.type || type

  /********************************
   * ref 这个类型并不足以描述控件的行为
   */
  if (component.type === 'select') {
    if (dataType.type === 'ref') {
      newType = `ref:${entity}`
    } else if (dataType.type === 'list') {
      const { type, entity } = elemType
      newType = `list:${type}:${entity}`
      // 自定义档案多选需要区分 搜索和下拉 即 list:${type}:${entity}:${component.type}
      if (entity.startsWith('basedata.Dimension')) {
        newType = `list:${type}:${entity}:${component.type}`
      }
    }
  }

  if (name === 'expenseLink') {
    //关联申请
    newType = 'expenseLink'
  }
  if (name === 'linkRequisitionInfo') {
    //补充申请
    newType = 'linkRequisitionInfo'
  }

  if (!cnLabel) {
    cnLabel = label
  }
  if (!enLabel) {
    enLabel = label
  }
    // 处理一下 档案关系多选且是搜索的时候
  if (
    newType === 'select_search' &&
    dataType?.type === 'list' &&
    elemType.entity.startsWith('basedata.Dimension')
  ) {
    newType = `list:${elemType.type}:${elemType.entity}:${newType}`
  }

  /********************************
   * 优先级: field < component < type(ref)
   */
  return { ...def, ...component, type: newType, cnLabel, enLabel }
}

function fnFieldCalculateFormulaValue(component, comMap) {
  const { defaultValue, label } = component
  if (defaultValue.type === 'presetFormula') return component

  let formula = label + '='
  const formulaArray = get(defaultValue, 'value[0].formula',[]) 
  formulaArray.forEach(element => {
    if (element.type === 'field') {
      formula += `${comMap[element.value].label}`
    } else {
      formula += `${element.value}`
    }
  })
  component.defaultValue.formulaValue = formula
}

export function parseAsMeta(specification, globalFields, isDataLink = false) {
  let oo = []
  let components = get(specification, 'components') || []
  const comMap = {}
  components.forEach(element => {
    comMap[element.field || '@'] = element
  })
  let i = -1
  while (++i < components.length) {
    let component = components[i]
    if (component.defaultValue && component.defaultValue.type === 'formula') {
      fnFieldCalculateFormulaValue(component, comMap)
    }

    oo.push(findInGlobalFields(component, globalFields, isDataLink))
  }

  return oo
}
