/**************************************************
 * Created by nanyuantingfeng on 11/08/2017 17:41.
 **************************************************/
import moment from 'moment'
import fnPredefine4Date, { formatWithTime } from './fnPredefine4Date'

export default (field = {}) => {
  const { defaultValue, withTime, dateTimeType } = field
  let start = 0,
    end = 0
  if (defaultValue.type === 'predefine' && defaultValue.value !== 'submit.requisition') {
    //从申请单取值
    start = end = fnPredefine4Date(field)
    if (field.name === 'tripDatePeriod') {
      //住宿日期+1day
      end = formatWithTime(withTime, moment().add(1, 'days'), dateTimeType)
    }
    return { start, end }
  }
}
