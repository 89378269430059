import { __assign } from "tslib";
import { app as api } from '@ekuaibao/whispered';
import { moneyStrToStandardCurrencyMoney } from '@ekuaibao/money-math';
import { get } from 'lodash';
var isWx = window.__PLANTFORM__ === 'WEIXIN';
export function getMoney(money) {
    return money ? (typeof money === 'object' ? (money.standard ? money.standard : '0.00') : money) : '0.00';
}
export function isMoney(obj) {
    return obj && typeof obj === 'object' && (obj.standard || obj.standard === 0);
}
export function fnDefineIsFormula(field, autoCalFields, fieldMeta) {
    if (autoCalFields === void 0) { autoCalFields = {}; }
    var _a = autoCalFields.onFields, onFields = _a === void 0 ? [] : _a, _b = autoCalFields.oldOnFields, oldOnFields = _b === void 0 ? [] : _b;
    var isFormulaAttr = Array.isArray(fieldMeta === null || fieldMeta === void 0 ? void 0 : fieldMeta.configs) && !!~(fieldMeta === null || fieldMeta === void 0 ? void 0 : fieldMeta.configs.findIndex(function (el) { return el.ability === 'caculate'; }));
    var isFormula = (fieldMeta === null || fieldMeta === void 0 ? void 0 : fieldMeta.defaultValue) && (fieldMeta === null || fieldMeta === void 0 ? void 0 : fieldMeta.defaultValue.type) === 'formula';
    var isOldCostStandard = (oldOnFields === null || oldOnFields === void 0 ? void 0 : oldOnFields.includes(field)) && !isFormula;
    var type = get(fieldMeta, 'dataType.elemType.type') || get(fieldMeta, 'dataType.type');
    if (type === 'money') {
        if (isFormulaAttr && (fieldMeta === null || fieldMeta === void 0 ? void 0 : fieldMeta.editable) && !isFormula && !isOldCostStandard) {
            return false;
        }
        else {
            return (onFields &&
                !!~onFields.indexOf(field) &&
                !(fieldMeta && fieldMeta.editable && fieldMeta.defaultValue && fieldMeta.defaultValue.type === 'formula'));
        }
    }
    return (onFields &&
        !!~onFields.indexOf(field) &&
        !(fieldMeta && fieldMeta.editable && fieldMeta.defaultValue && fieldMeta.defaultValue.type === 'formula'));
}
export function standardValueMoney(value, currency) {
    var standardCurrency = currency ? currency : api.getState()['@common'].standardCurrency;
    var strCode = standardCurrency.strCode, numCode = standardCurrency.numCode, symbol = standardCurrency.symbol, unit = standardCurrency.unit, scale = standardCurrency.scale;
    return {
        standard: value,
        standardStrCode: strCode,
        standardNumCode: numCode,
        standardSymbol: symbol,
        standardUnit: unit,
        standardScale: scale
    };
}
export function getAcountKey(value) {
    var kv = {
        taxRate: 'taxAmount',
        noTaxAmount: 'noTaxAmount',
        taxTotal: 'taxTotal'
    };
    return kv[value];
}
export function moneyStr2StandardMoneyValue(money) {
    if (money === void 0) { money = '0.00'; }
    if (!!~['number', 'string'].indexOf(typeof money)) {
        var standCurrency = api.getState('@common.standardCurrency');
        return moneyStrToStandardCurrencyMoney(money, standCurrency);
    }
    return money;
}
export function isIE() {
    return !!(window.ActiveXObject || 'ActiveXObject' in window || navigator.userAgent.indexOf('MSIE') > -1);
}
export var getDataSource = function (key) {
    var dataSource = isWx ? session.get(key) : localStorage.getItem(key);
    return dataSource ? JSON.parse(dataSource) : {};
};
export function calculateColCountByOffsetWidth(_a) {
    var offsetWidth = _a.offsetWidth, layoutBtnGroupMap = _a.layoutBtnGroupMap, _b = _a.size, size = _b === void 0 ? { isFirstLocal: false, isFirstResize: false } : _b, layoutSizeMap = _a.layoutSizeMap;
    var webShow = getDataSource('170043_webShow');
    if (webShow) {
        if (webShow.forbidStaffModify) {
            return webShow.formNumber;
        }
        else {
            return layoutBtnGroupMap['form-col-count'] || webShow.formNumber || 2;
        }
    }
    var colCount;
    var minSmallFilter = offsetWidth < layoutSizeMap.small.min;
    var maxSmallFilter = offsetWidth >= layoutSizeMap.small.min && offsetWidth < layoutSizeMap.small.max;
    var middleFilter = (offsetWidth >= layoutSizeMap.middle.min && offsetWidth <= layoutSizeMap.middle.max) ||
        offsetWidth >= layoutSizeMap.middle.max;
    if (minSmallFilter) {
        colCount = 1;
    }
    else if (maxSmallFilter) {
        colCount = layoutSizeMap.small.colCount;
    }
    else if (middleFilter) {
        colCount = layoutSizeMap.middle.colCount;
    }
    else {
        colCount = layoutSizeMap.small.colCount;
    }
    var localColCount = Number(layoutBtnGroupMap['form-col-count'] || colCount);
    if (size.isFirstLocal) {
        return offsetWidth >= layoutSizeMap.middle.min || localColCount < layoutSizeMap.small.colCount
            ? localColCount
            : colCount;
    }
    else if (size.isFirstResize) {
        return colCount;
    }
    else {
        return localColCount;
    }
}
export function standardMoneyByForeign(foreign, foreignNumCode, changeRate) {
    var _a, _b, _c;
    var dimentionCurrency = (_a = api.getState()['@bills']) === null || _a === void 0 ? void 0 : _a.dimentionCurrencyInfo;
    var allCurrencyRates = (_b = api.getState()['@common']) === null || _b === void 0 ? void 0 : _b.allCurrencyRates;
    var standardCurrency = (dimentionCurrency === null || dimentionCurrency === void 0 ? void 0 : dimentionCurrency.currency) ? dimentionCurrency === null || dimentionCurrency === void 0 ? void 0 : dimentionCurrency.currency : (_c = api.getState()['@common']) === null || _c === void 0 ? void 0 : _c.standardCurrency;
    var rates = (dimentionCurrency === null || dimentionCurrency === void 0 ? void 0 : dimentionCurrency.rates) ? dimentionCurrency === null || dimentionCurrency === void 0 ? void 0 : dimentionCurrency.rates : allCurrencyRates;
    var rate = rates === null || rates === void 0 ? void 0 : rates.find(function (rate) { return rate.numCode === foreignNumCode; });
    if (rate) {
        var __rate = changeRate || rate.rate;
        var standard = new Big(foreign).times(__rate).toFixed(standardCurrency === null || standardCurrency === void 0 ? void 0 : standardCurrency.scale);
        return {
            standard: standard,
            standardStrCode: standardCurrency === null || standardCurrency === void 0 ? void 0 : standardCurrency.strCode,
            standardNumCode: standardCurrency === null || standardCurrency === void 0 ? void 0 : standardCurrency.numCode,
            standardSymbol: standardCurrency === null || standardCurrency === void 0 ? void 0 : standardCurrency.symbol,
            standardUnit: standardCurrency === null || standardCurrency === void 0 ? void 0 : standardCurrency.unit,
            standardScale: standardCurrency === null || standardCurrency === void 0 ? void 0 : standardCurrency.scale,
            rate: __rate,
            sysRate: rate === null || rate === void 0 ? void 0 : rate.rate,
            foreign: Number(foreign).toFixed(rate.scale),
            foreignNumCode: rate === null || rate === void 0 ? void 0 : rate.numCode,
            foreignScale: rate === null || rate === void 0 ? void 0 : rate.scale,
            foreignStrCode: rate === null || rate === void 0 ? void 0 : rate.strCode,
            foreignSymbol: rate === null || rate === void 0 ? void 0 : rate.symbol,
            foreignUnit: rate === null || rate === void 0 ? void 0 : rate.unit
        };
    }
    return standardValueMoney(foreign);
}
export function isZero(value) {
    if (!value)
        return false;
    if (typeof value === 'string') {
        return value === '0';
    }
    if (typeof value === 'number') {
        return value === 0;
    }
    return false;
}
export function standardCurrencyToForeign(standardMoneyStr, money) {
    var foreignScale = money.foreignScale, rate = money.rate, standardScale = money.standardScale, foreignNumCode = money.foreignNumCode, budgetNumCode = money.budgetNumCode, budgetRate = money.budgetRate, budgetScale = money.budgetScale;
    var standardMoneyBig = new Big(standardMoneyStr);
    var moneyObj = __assign(__assign({}, money), { standard: standardMoneyBig.toFixed(Number(standardScale)) });
    if (foreignNumCode) {
        moneyObj.foreign = standardMoneyBig.div(rate).toFixed(Number(foreignScale));
    }
    if (budgetNumCode && budgetRate) {
        moneyObj.budget = standardMoneyBig.div(budgetRate).toFixed(Number(budgetScale));
    }
    return moneyObj;
}
