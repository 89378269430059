/**
 *  Created by daiwenjuan on 2018/5/17 下午5:16.
 */
import React, { PureComponent } from 'react'
import styles from './index.module.less'
import SVG_TIPS from './images/tips.svg'

export default class EKBTips extends PureComponent {
  render() {
    let { tips = [] } = this.props
    return (
      <div className={styles['ekbtips-wrapper']}>
        <div className="tips-content">
          <div className="left">
            <img src={SVG_TIPS} />
          </div>
          <div className="right">
            {tips.map((line, key) => {
              return <div key={key}>{line}</div>
            })}
          </div>
        </div>
      </div>
    )
  }
}
