/************************************************
 * Created By nanyuantingfeng On 7/1/16 16:16.
 ************************************************/
import { Form } from 'antd'
import React from 'react'

const EnhanceFormCreate = config => Component =>
  class extends React.Component {
    constructor(props) {
      super(props)
      this.Component = Form.create()(Component)
      this.handleOverrideGetResult = this.handleOverrideGetResult.bind(this)
      this.handleOverrideGetDiff = this.handleOverrideGetDiff.bind(this)
      this.handleOverridePropsFn = this.handleOverridePropsFn.bind(this)
      this.handleOverridePropsFnByMap = this.handleOverridePropsFnByMap.bind(this)
    }

    handleOverrideGetResult(fn) {
      this.getResult = fn
    }

    handleOverrideGetDiff(fn) {
      this.getDiff = fn
    }

    handleOverridePropsFn(key, fn) {
      this[key] = fn
    }

    handleOverridePropsFnByMap(map) {
      for (let key in map) {
        this[key] = map[key]
      }
    }

    render() {
      return React.createElement(
        this.Component,
        {
          ...config,
          ...this.props,
          overrideGetResult: this.handleOverrideGetResult,
          overrideGetDiff: this.handleOverrideGetDiff,
          overridePropsFn: this.handleOverridePropsFn,
          overridePropsFnByMap: this.handleOverridePropsFnByMap
        },
        null
      )
    }
  }

export default EnhanceFormCreate
