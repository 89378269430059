import { __assign } from "tslib";
import React from 'react';
import ReadOnlyCell from './ReadOnlyCell';
import TableHeader from './TableHeader';
import { fixedColumnCount } from '../helper/tableHelper';
import EKBIcon from '../../../elements/ekbIcon';
import EditDimensionCell from './EditDimensionCell';
import PayPlanStatusCell from './PayPlanStatusCell';
import PayPlanReceipt from './PayPlanReceipt';
import PayPlanReceiptActions from './PayPlanReceiptActions';
import { T } from '@ekuaibao/i18n';
export function formatCoumnForDisplay(props) {
    var editable = props.editable, payPlanStore = props.payPlanStore, onDelete = props.onDelete, onCreate = props.onCreate, flowId = props.flowId, isModify = props.isModify, dataSource = props.dataSource, billSpecification = props.billSpecification;
    var columns = props.columns;
    if (!editable) {
        columns = columns.map(function (line) {
            return __assign(__assign({}, line), { render: function (value, record, idx) { return (React.createElement(ReadOnlyCell, { value: value, dataIndex: line.dataIndex, riskWarn: record.riskWarn, id: record.apportionId, type: line.type, isModify: isModify, idx: idx, model: line.model })); } });
        });
    }
    var formatColumns = columns.map(function (col) {
        var title = (React.createElement(TableHeader, { column: col, editable: editable, getTableStore: function () { return payPlanStore; } }));
        if (!col.editable) {
            return __assign(__assign({}, col), { title: title, render: function (value, record, idx) { return (React.createElement(ReadOnlyCell, __assign({ value: value, dataIndex: col.dataIndex, riskWarn: record.riskWarn, id: record.apportionId, type: col.type, isModify: isModify, idx: idx, model: col.model }, col))); } });
        }
        if (col.editable && col.type === 'paymentStatus') {
            return __assign(__assign({}, col), { title: title, render: function (value, record, idx) {
                    return (React.createElement(PayPlanStatusCell, { status: value }));
                } });
        }
        if (col.editable && col.type === 'receiptIds') {
            return __assign(__assign({}, col), { title: title, render: function (value, record, idx) {
                    return (React.createElement(PayPlanReceipt, { receiptArr: value, record: record, dataSource: dataSource }));
                } });
        }
        if (col.editable && col.type === 'receiptActions') {
            return __assign(__assign({}, col), { title: title, render: function (value, record, idx) {
                    return (React.createElement(PayPlanReceiptActions, { receiptArr: value, idx: idx, record: record, dataSource: dataSource }));
                } });
        }
        if (col.editable && (col.type === 'payeeInfo' || col.type === 'legalEntity')) {
            return __assign(__assign({}, col), { title: title, render: function (value, record, idx) { return (React.createElement(EditDimensionCell, { currentSpecification: dataSource.currentSpecification, entity: col.entity, payPlanStore: payPlanStore, dataIndex: col.dataIndex, type: col.type, editable: editable, isModify: isModify, flowId: flowId, value: value, record: record, title: col.title, idx: idx, model: col.model, billSpecification: billSpecification })); } });
        }
        return __assign(__assign({}, col), { title: title, onCell: function (record) { return ({
                record: record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                type: col.type,
                entity: col.entity,
                isModify: isModify,
                payPlanStore: payPlanStore,
                model: col.model
            }); } });
    });
    var config = {};
    if (formatColumns.length > fixedColumnCount) {
        config.fixed = 'right';
        config.width = 40;
    }
    if (editable) {
        if (formatColumns.findIndex(function (item) { return item.dataIndex === 'actions'; }) > -1) {
            var target = formatColumns.find(function (item) { return item.dataIndex === 'actions'; });
            target.render = function (text, record, idx) {
                if (payPlanStore.objs.length > 1 && payPlanStore.objs.length - 1 === idx && idx != 5) {
                    return isModify ? React.createElement(React.Fragment, null,
                        React.createElement("a", { className: 'delete_icon', onClick: function () { return onCreate && onCreate(record); } }, React.createElement(T, { name: "\u65B0\u5EFA" })),
                        React.createElement("span", { className: "separate" }, "|"),
                        React.createElement("a", { className: 'delete_icon', onClick: function () { return onDelete && onDelete(record); } }, React.createElement(T, { name: "\u5220\u9664" }))) : null;
                }
                else if (payPlanStore.objs.length - 1 === idx && idx === 5) {
                    return React.createElement("a", { className: 'delete_icon', onClick: function () { return onDelete && onDelete(record); } }, React.createElement(T, { name: "\u5220\u9664" }));
                }
                else if (payPlanStore.objs.length - 1 === idx && idx === 0) {
                    return React.createElement("a", { className: 'delete_icon', onClick: function () { return onCreate && onCreate(record); } }, React.createElement(T, { name: "\u65B0\u5EFA" }));
                }
                else {
                    return null;
                }
            };
        }
        else {
            if (isModify) {
                formatColumns.push(__assign(__assign({ title: '', key: 'action' }, config), { render: function (text, record) { return (React.createElement(EKBIcon, { className: 'delete_icon', name: '#EDico-delete', onClick: function () { return onDelete && onDelete(record); } })); } }));
            }
        }
    }
    return formatColumns;
}
