
export const channelMap = () => {
  return {
    NBBANK: i18n.get("宁波银行"),
    OFFLINE: i18n.get("线下支付"),
    CREDITEASE: i18n.get("在线支付"),
    ERP: i18n.get("ERP支付"),
    WALLET: i18n.get("企业钱包"),
    SOUCHE: i18n.get("搜车支付"),
    SOUCHEV2: i18n.get("搜车支付(测试)"),
    FINGARD: i18n.get("保融支付"),
    CHANPAY: i18n.get("银企联支付"),
    CHANPAYV2: i18n.get("银企联支付"),
    GALAXYCMB: i18n.get("星河互动线上支付"),
    BODACGB: i18n.get("博大广发线上支付"),
    HZBANK: i18n.get("杭州银行"),
    ALIPAY: i18n.get("支付宝(办公)"),
    ANTALIPAY: i18n.get("企业付-支付宝"),
    NSTC: i18n.get("九恒星线上支付")
  }
}

export function filterChannels(channels = []) {
  const arr = []
  if (Array.isArray(channels)) {
    channels.forEach(item => {
      if (item.active) {
        const data = { label: item.name, value: item.channel, icon: item.icon }
        arr.push(data)
      }
    })
  }

  return arr
}
