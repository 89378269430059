import { app as api } from '@ekuaibao/whispered'
export function reorganizeRiskWarningData(msg) {
    const res = msg.reduce((result, current, index) => {
        Array.isArray(current.riskWarning) && current.riskWarning.map(v => {
            if (result[v + current.pathValueId]) {
                result[v + current.pathValueId].messages.push(`${current.invoiceMsg}`)
            } else {
                result[v + current.pathValueId] = {
                    controlName: v + "：",
                    type: 'invoice',
                    controlField: 'invoiceForm',
                    path: current.path, // 费用明细
                    pathValueId: current.pathValueId, // 费用明细id
                    messages: [`${current.invoiceMsg}`],
                    isReview: false
                }
            }
        })
        if(current.reviewFlag){
            const basedata = api.getState()['@common']
            const KA_REVIEW_RISK = basedata?.powers?.KA_REVIEW_RISK
            const ristText = KA_REVIEW_RISK && basedata?.warningText ? basedata?.warningText : i18n.get("未收到发票的纸质发票")
            window.IS_SZJL && current.riskWarning?.push(ristText)
            if (result['isReview' + current.pathValueId]) {
                result['isReview' + current.pathValueId].messages.push(`${current.invoiceMsg}`)
            } else {
                result['isReview' + current.pathValueId] = {
                    controlName: ristText + '：',
                    type: 'invoice',
                    controlField: 'invoiceForm',
                    path: current.path, // 费用明细
                    pathValueId: current.pathValueId, // 费用明细id
                    messages: [`${current.invoiceMsg}`],
                    isReview: true  // 复核风险 
                }
            }
        }
        return result
    }, {})
    // 未收到发票的纸质发票排序到最后
    const arr = Object.values(res).sort((a,b) => {
        return Number(a.isReview) - Number(b.isReview)
    })
    return arr
}