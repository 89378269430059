import { __extends } from "tslib";
import React, { PureComponent } from 'react';
import { renderPopoverContent } from '../ExceedStandardRiskForField';
import styles from './NoteView.module.less';
import { app as api } from '@ekuaibao/whispered';
import EKBIcon from '../../../elements/ekbIcon';
import { get } from 'lodash';
import NoteContentLine from './NoteContentLine';
import classNames from 'classnames';
import { isIE } from '../../../lib/misc';
var NoteView = (function (_super) {
    __extends(NoteView, _super);
    function NoteView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleAddNote = function () {
            var _a = _this.props, flowId = _a.flowId, detailId = _a.detailId, isDetail = _a.isDetail, field = _a.field;
            api.open('@bills:NoteEditModal', { page: 'add', flowId: flowId, detailId: detailId, isDetail: isDetail, field: field });
        };
        _this.handleChangePageToDelete = function (noteId) {
            var flowId = _this.props.flowId;
            api.open('@bills:NoteEditModal', { page: 'delete', flowId: flowId, noteId: noteId });
        };
        _this.renderNoteByType = function (noteType) {
            var _a = _this.props, noteArr = _a.noteArr, detailReadable = _a.detailReadable, canEditNote = _a.canEditNote, authorRemovable = _a.authorRemovable, showBillNotesInHistory = _a.showBillNotesInHistory;
            var singleNoteArr = noteArr.filter(function (el) { return el.type === noteType; });
            if (!singleNoteArr.length)
                return null;
            var canDeleteNote = !showBillNotesInHistory && canEditNote && (authorRemovable || noteType === 'NORMAL');
            return (React.createElement("div", { className: 'note-item' },
                React.createElement("div", { className: 'note-type' }, noteType === 'NORMAL' ? i18n.get('普通批注') : i18n.get('信用批注')),
                singleNoteArr.map(function (note, idx) {
                    var content = note.content, authorName = note.authorName, rule = note.rule, authorId = note.authorId;
                    var name = i18n.get("{__k0}\uFF1A", { __k0: authorName });
                    var staffId = api.getState('@common.userinfo.staff.id');
                    if (canDeleteNote)
                        canDeleteNote = authorId === staffId;
                    var text;
                    if (noteType === 'NORMAL') {
                        text = React.createElement(NoteContentLine, { name: name, text: content });
                    }
                    else {
                        var label = get(rule, 'label', '');
                        text = React.createElement(NoteContentLine, { name: name, text: label });
                        if (detailReadable) {
                            var score = get(rule, 'score', '');
                            var labelText = score >= 0 ? i18n.get("{__k0}\uFF08+{__k1}\uFF09", { __k0: label, __k1: score }) : i18n.get("{__k0}\uFF08{__k1}\uFF09", { __k0: label, __k1: score });
                            text = React.createElement(NoteContentLine, { name: name, text: labelText });
                        }
                    }
                    return (React.createElement("div", { className: classNames("note-content", { "jc-sb": !isIE(), "option-line": !isIE(), 'pos-r': isIE() }), key: idx },
                        text,
                        canDeleteNote && (React.createElement("div", { className: classNames('note-delete-btn-wrap', { 'ie-note-delete-btn-wrap': isIE() }), onClick: function () { return _this.handleChangePageToDelete(note.id); } },
                            React.createElement(EKBIcon, { className: 'note-delete-btn', name: '#EDico-delete' })))));
                })));
        };
        _this.renderRiskContent = function () {
            var external = _this.props.external;
            if (!external)
                return null;
            return (React.createElement("div", { className: 'note-item' }, renderPopoverContent(external)));
        };
        _this.renderContent = function () {
            return (React.createElement("div", { className: classNames('note-content-wrap', { 'note-content-wrap-layout': !isIE() }) },
                _this.renderRiskContent(),
                _this.renderNoteByType('NORMAL'),
                _this.renderNoteByType('CREDIT')));
        };
        _this.renderBtn = function () {
            var _a = _this.props, canEditNote = _a.canEditNote, showBillNotesInHistory = _a.showBillNotesInHistory;
            if (!canEditNote || showBillNotesInHistory)
                return null;
            return (React.createElement("div", { className: "note-btn-wrap" },
                React.createElement("div", { className: 'note-add-btn', onClick: _this.handleAddNote }, i18n.get('添加批注'))));
        };
        return _this;
    }
    NoteView.prototype.render = function () {
        return (React.createElement("div", { className: styles['noteView-wrap'] },
            this.renderContent(),
            this.renderBtn()));
    };
    return NoteView;
}(PureComponent));
export default NoteView;
