import {Fetch} from '@ekuaibao/fetch'
import MessageCenter from '@ekuaibao/messagecenter'
class SourceManager extends MessageCenter {
  constructor() {
    super()
    this.map = {}
  }

  get(name) {
    return this.map[name]
  }

  getMap() {
    return this.map
  }

  add(name, url, params) {
    let s = this.map[name]
    if (s) this.delete(name)
    this.map[name] = Fetch.SSE(url, params)
    return this.map[name]
  }

  /**
   *
   * @param name
   * @returns {undefined}
   */
  delete(name) {
    let s = this.map[name]
    if (s && s.readyState !== 2) {
      s.close()
    }

    delete this.map[name]
    return this.map[name]
  }
}

const sourceMap = new SourceManager()

export default sourceMap
