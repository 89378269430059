/**
 *  Created by daiwenjuan on 17/6/22 下午5:05.
 */
import { clone } from 'lodash'
import { app as api } from '@ekuaibao/whispered'
import { moneyStr2StandardMoneyValue } from './misc'
import { invoiceOptions } from './InvoiceUtil'
import moment from 'moment'

export function invoiceConvertConsume(feeType, invoicedata, specification, autoCalFields, submitter) {
  let { components } = specification
  //let {value = {}} = invoicedata//原数据结构写法，自造数据结构没有value层,所以下边令value
  let value = invoicedata || {}
  let { data, id } = value
  const { onFields } = autoCalFields

  let feeTypeField = {}
  if (components.find(v => v.field === 'amount')) {
    const invsumamt = Number(data.invsumamt) || data.invsumamt
    feeTypeField['amount'] = moneyStr2StandardMoneyValue(invsumamt)
  }
  let c = components.find(v => v.field === 'feeDate')
  let cp = components.find(v => v.field === 'feeDatePeriod')

  if (c) {
    feeTypeField['feeDate'] = setValue(data.invdate, c)
  }

  if (cp) {
    feeTypeField['feeDatePeriod'] = setValueRange(data.invdate, c)
  }

  if (components.find(v => v.field === 'invoice')) {
    feeTypeField['invoice'] = '1'
  }

  if (components.find(v => v.field === 'invoiceType')) {
    feeTypeField.invoiceType = setInvoiceType(data.invtype)
  }

  if (components.find(v => v.field === 'noTaxAmount')) {
    const noTaxAmount = Number(data.invamt) || data.invamt
    feeTypeField.noTaxAmount = moneyStr2StandardMoneyValue(noTaxAmount)
  }

  if (components.find(v => v.field === 'taxAmount')) {
    const taxAmount = Number(data.invtaxamt) || data.invtaxamt
    feeTypeField.taxAmount = moneyStr2StandardMoneyValue(taxAmount)
  }

  if (components.find(v => v.field === 'taxRate')) {
    //税率
    if (data && data.itemdata) {
      //const itemdata = JSON.parse(data.itemdata)//原始代码
      const itemdata = typeof data.itemdata === 'string' ? JSON.parse(data.itemdata) : data.itemdata
      if (itemdata && itemdata.length === 1) {
        feeTypeField.taxRate = itemdata[0].taxRate
      }
    }
  }

  let feeTypeForm = {
    ...feeTypeField,
    orders: [value.id],
    ordersData: [value]
  }

  const formData = {
    feeTypeId: feeType,
    feeTypeForm,
    specificationId: specification
  }

  return Promise.resolve(formData)
}

// TODO: 这里实现的比较重复，可以找代文娟和寇云一起商量重构

export function CSCConvertConsume(feeType, importData, specification, records, autoCalFields, submitter) {
  const { components } = specification
  const { data, id } = importData
  const { onFields } = autoCalFields
  let feeTypeField = {}
  const record = records.find(r => r.id === importData.transactRecordId)
  if (components.some(v => v.field === 'amount')) {
    feeTypeField['amount'] = moneyStr2StandardMoneyValue(record.useBalance)
  }

  if (components.some(v => v.field === 'feeDate')) {
    try {
      feeTypeField['feeDate'] = +new Date(`${data.tranDate} ${data.tranTime}`)
    } catch (e) {
      console.error(e.message)
      feeTypeField['feeDate'] = Date.now()
    }
  }

  if (components.some(v => v.field === 'feeDatePeriod')) {
    feeTypeField['feeDatePeriod'] = {
      start: Date.now(),
      end: Date.now()
    }
  }

  if (components.some(v => v.field === 'invoice')) {
    feeTypeField['invoice'] = '1'
  }

  let invoiceType = components.find(v => v.field === 'invoiceForm')
  if (invoiceType) {
    if (!invoiceType.editable) {
      feeTypeField.invoiceForm = { type: 'noWrite' }
    } else {
      let arr = invoiceOptions(invoiceType.invoiceType) || []
      let type = arr.length && arr[0].type
      if (type) {
        feeTypeField.invoiceForm = type === 'unify' ? { type: type, invoiceCorporationId: 'defaults' } : { type }
      }
    }
  }

  const feeTypeForm = {
    ...feeTypeField,
    orders: [id],
    ordersData: [importData]
  }

  const formData = {
    feeTypeId: feeType,
    feeTypeForm,
    specificationId: specification
  }
  return Promise.resolve(formData)
}

export function fnFormatThirdData2Details(feeType, specification, orderDatas, value = [], mappingRelation, others) {
  let components = specification.components.filter(v => v.field)
  let detailsPromise = orderDatas.map((order, idx) => {
    return _formatDetail(specification, components, order, mappingRelation, value, feeType, idx, others)
  })
  return Promise.all(detailsPromise)
}

function _formatDetail(specification, components, order, mappingRelation, value, feeType, idx, others) {
  let { form } = order
  let feeTypeForm = {}
  components.forEach(v => {
    if (v.defaultValue && v.defaultValue.type === 'constant' && v.field !== 'apportions') {
      feeTypeForm[v.field] = v.defaultValue.value
    }

    if (v.field === 'settlement' && v.defaultValue && v.defaultValue.type === 'constant') {
      const { settlementItems } = others
      const id = v.defaultValue.value.id
      feeTypeForm[v.field] = settlementItems.find(line => line.id === id)
    }

    if (v.type === 'date') {
      feeTypeForm[v.field] = moment().valueOf()
    }

    if (v.type === 'dateRange') {
      let start = moment().valueOf()
      let end = moment().valueOf()
      feeTypeForm[v.field] = { start, end }
    }

    let mapping = mappingRelation.filter(o => o.to === v.field)
    mapping.forEach(m => {
      if (form[m.from]) {
        feeTypeForm[m.to] = form[m.from]
        v.editable = m.editable
      }
    })

    if (v.type === 'money' && feeTypeForm[v.field]) {
      feeTypeForm[v.field] = moneyStr2StandardMoneyValue(feeTypeForm[v.field])
    }

    if (v.type === 'invoice' && v.editable) {
      const invoiceOptions = getInvoiceOptions(v.invoiceType)
      feeTypeForm[v.field] = invoiceOptions.length ? { ...invoiceOptions[0] } : undefined
    } else if (v.type === 'invoice' && !v.editable) {
      feeTypeForm[v.field] = { type: 'noWrite' }
    }
  })

  feeTypeForm.orders = [order.id]
  feeTypeForm.thirdPartyOrders = [order]

  let detail = {
    feeTypeId: feeType,
    feeTypeForm,
    specificationId: specification,
    idx: value.length + idx
  }

  return api
    .invoke('generate:DetailByAutoCalculate', { detailFormValue: detail, components })
    .then(data => {
      // 如果发现 specificationId 的 open 发生了变化是因为 generate:DetailByAutoCalculate 修改的
      detail.feeTypeForm = data
      return detail
    })
    .catch(_ => {
      return detail
    })
}

export function setValueRange(value) {
  value = value && value.toString()

  let time = moment(value, 'YYYY-MM-DD HH:mm:ss').format('x') * 1

  let start = time || Date.now()
  let end = time || Date.now()
  return {
    start: start,
    end: end
  }
}

export function setValue(value) {
  value = value && value.toString()
  let time = moment(value, 'YYYY-MM-DD HH:mm:ss').format('x') * 1
  return time || Date.now()
}

export function setInvoiceType(type) {
  switch (type) {
    case 'DIGITAL_NORMAL':
    case 'PAPER_ROLL':
    case 'PAPER_FEE':
    case 'PAPER_NORMAL':
      return 'VATOrdinaryInvoice'
    case 'DIGITAL_SPECIAL':
      return 'VATSpecialInvoic'
    case 'PAPER_SPECIAL':
      return 'VATSpecialInvoice'
    case 'PAPER_CAR':
      return 'MotorInvoice'
    default:
      return ''
  }
}

export function formatDIDIDetail({ specification, item, details, ordersData, authScope }) {
  if (item.id === 'DIDI') {
    const { components } = specification
    const invoiceTypeField = components.find(c => c.type === 'invoice')
    if (invoiceTypeField) {
      //有发票形式
      const { invoiceType } = invoiceTypeField
      let ids = invoiceType.unify.limit ? invoiceType.unify.invoiceCorporation : undefined //统一开票是否有限制开票方
      const getUnifyCorporationList = api.invokeService('@invoice-manage:import:getUnifyCorporationList')
      return api.dispatch(getUnifyCorporationList()).then(data => {
        //查询开票方
        const didiCorp = data.items.find(item => item.channel === 'DIDI')
        if (data.items.length) {
          details = formatDetailsForDIDIValue(
            didiCorp || data.items[0],
            details,
            invoiceTypeField,
            ordersData,
            authScope,
            data.items
          )
        }
        return details
      })
    }
  }
  return Promise.resolve(details)
}
//新授权范围下的导入订单默认值逻辑
function formatDetailsForDIDIValue(didiCorp, details, invoiceTypeField, ordersData, authScope, dataItems) {
  return details.map((d, i) => {
    let { feeTypeForm } = d
    let notTaxi = ordersData[i] && ordersData[i].form && ordersData[i].form.useCarType != 'TAXI' //订单是否是非出租车
    const { invoiceType } = invoiceTypeField
    let hasC = !!invoiceType.unify.choose //是否有统一开票
    let islimit = invoiceType.unify.limit //统一开票 ---是否限制了开票方
    let hasDIDI = didiCorp.channel == 'DIDI' //是否有滴滴统一开票
    let editable = invoiceTypeField.editable
    //非编辑状态下，1|2|3的时候 认为是有统一开票的.
    if (authScope && !editable) {
      hasC = true
    }
    //非出租车&&1|2|3
    if (notTaxi && authScope) {
      //有滴滴统一开票或者不可编辑
      if (
        hasC &&
        hasDIDI &&
        (!editable || !islimit || invoiceType.unify.invoiceCorporation.indexOf(didiCorp.id) >= 0)
      ) {
        feeTypeForm[invoiceTypeField.field] = {
          type: 'unify',
          invoiceCorporationId: didiCorp.id,
          invoiceCorporation: didiCorp
        }
      } else if (hasC && (!hasDIDI || (islimit && invoiceType.unify.invoiceCorporation[0] != didiCorp.id))) {
        feeTypeForm[invoiceTypeField.field] = {
          type: 'unify'
        }
      } else {
        if (!editable) {
          feeTypeForm[invoiceTypeField.field] = { type: 'noWrite' }
        } else {
          let a = getInvoiceOptions(invoiceTypeField.invoiceType)
          if (a[0].type == 'unify') {
            let limitId = islimit ? invoiceType.unify.invoiceCorporation[0] : dataItems[0].id
            let limitItem = dataItems.find(item => item.id == limitId)
            feeTypeForm[invoiceTypeField.field] = {
              type: 'unify',
              invoiceCorporationId: limitItem.id,
              invoiceCorporation: limitItem
            }
          } else {
            feeTypeForm[invoiceTypeField.field] = a[0]
          }
        }
      }
    } else {
      let a = getInvoiceOptions(invoiceTypeField.invoiceType)
      //出租车可编辑
      if (editable) {
        if (a[0].type == 'unify') {
          let limitId = islimit ? invoiceType.unify.invoiceCorporation[0] : dataItems[0].id
          let limitItem = dataItems.find(item => item.id == limitId)
          feeTypeForm[invoiceTypeField.field] = {
            type: 'unify',
            invoiceCorporationId: limitItem.id,
            invoiceCorporation: limitItem
          }
        } else {
          feeTypeForm[invoiceTypeField.field] = a[0]
        }
      } else {
        feeTypeForm[invoiceTypeField.field] = { type: 'noWrite' }
      }
    }
    feeTypeForm.ordersData = [ordersData[i]]
    return d
  })
}

export function getInvoiceOptions(invoiceType) {
  let arr = []
  if (!invoiceType) return arr
  let {
    exist,
    noExist,
    wait,
    unify: { choose }
  } = invoiceType
  if (exist) {
    arr.push({ type: 'exist' })
  }
  if (wait) {
    arr.push({ type: 'wait' })
  }
  if (choose) {
    arr.push({ type: 'unify', invoiceCorporationId: 'defaults' })
  }
  if (noExist) {
    arr.push({ type: 'noExist' })
  }
  return arr
}
