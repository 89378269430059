import './Breadcrumb.less'
import { cloneDeep } from 'lodash'
import React, { PureComponent } from 'react'
import { Breadcrumb, Dropdown, Menu } from 'antd'

import MENU_IMAGE from './small.svg'
const noop = () => {}
export default class EKBBreadcrumb extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.items,
      changeTitle: false
    }
  }

  componentWillMount() {
    const { bus } = this.props
    bus && bus.on('last:title:change', this.lastTitleChange)
  }

  componentWillUnmount() {
    const { bus } = this.props
    bus && bus.un('last:title:change', this.lastTitleChange)
  }

  lastTitleChange = value => {
    const { items } = this.props
    items[items.length - 1].title = value
    this.setState({
      data: items,
      changeTitle: true
    })
  }

  fnGetFinalArray = () => {
    let { items } = this.props
    let length = items.length || 0
    let itemsCopy = cloneDeep(items)
    let innerList
    itemsCopy.forEach(item => {
      typeof item.title === 'string' && item.title.length > 12 && (item.title = item.title.substr(0, 12) + '...')
    })
    length > 3 && (innerList = [itemsCopy[0], { title: '...' }].concat(itemsCopy.slice(itemsCopy.length - 2)))
    const finalArr = length > 3 ? innerList : itemsCopy
    return { finalArr, length }
  }

  renderMenu(items) {
    return (
      <Menu>
        {items.map((line, index) => {
          if (line.className === 'cannotClick') return null
          return (
            <Menu.Item key={index}>
              <a target="_blank" rel="noopener" onClick={index === items.length - 1 ? noop : line.onClick}>
                {line.title}
              </a>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  renderDropdown(items) {
    let menu = this.renderMenu(items)
    return (
      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link fix" href="javascript:void(0)">
          <img src={MENU_IMAGE} alt="" />
        </a>
      </Dropdown>
    )
  }

  render() {
    let { separator = '/', items } = this.props
    const { finalArr, length } = this.fnGetFinalArray()
    return (
      <div className="navigation-bar-breadcrumb-wrapper">
        <div className="navigation-bar-breadcrumb-content">
          {length > 3 && this.renderDropdown(items)}
          {length > 3 && <span className="line" />}
          <Breadcrumb separator={separator}>
            {finalArr.map((line, index) => (
              <Breadcrumb.Item
                key={line.key}
                href={line.href}
                className={line.className}
                style={index === finalArr.length - 1 || line.title === '...' ? { cursor: 'text' } : {}}
                onClick={index === finalArr.length - 1 ? noop : line.onClick}
              >
                {!!~line.title.indexOf(i18n.get('（版本')) && typeof line.title === 'string'
                  ? line.title.split(i18n.get('（版本'))[0]
                  : line.title}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      </div>
    )
  }
}
