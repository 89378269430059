/**************************************************
 * Created by nanyuantingfeng on 2018/4/10 12:03.
 **************************************************/
import styles from './RefDataVisibility.module.less'
import React, { PureComponent } from 'react'
import { EnhanceField } from '@ekuaibao/template'
import { wrapper } from '../layout/FormWrapper'
import { app as api } from '@ekuaibao/whispered'
import { EnhanceConnect } from '@ekuaibao/store'
import { Select, Checkbox, Popover } from 'antd'

const { Option } = Select

@EnhanceConnect(state => ({
  roleList: state['@common'].roleList
}))
@EnhanceField({
  descriptor: {
    type: 'data.visibility'
  },

  validator: () => (rule, value, callback) => {
    if (rule.level > 0) {
      return callback()
    }

    if (!value) {
      return callback()
    }

    if (!value.roles || !Array.isArray(value.roles) || !value.roles.length) {
      return callback(i18n.get('请选择部门角色或项目角色'))
    }

    callback()
  },

  initialValue(props) {
    return props.value
  },

  wrapper: wrapper()
})
export default class RefDataVisibility extends PureComponent {
  static defaultProps = {
    roleList: []
  }

  constructor(props, ...args) {
    super(props, ...args)

    this.state = { checked: !!props.value }
  }

  preGetValue = () => {
    return !this.state.checked ? false : this.props.value
  }

  componentWillMount() {
    api.dataLoader('@common.roleList').load()
  }

  buildSelectOptions = rolesList => {
    return rolesList.map(line => (
      <Option key={line.id} value={line.id}>
        {line.name}
      </Option>
    ))
  }

  handleSelectChange = roles => {
    const { onChange, value } = this.props
    onChange({ ...value, roles })
  }

  handleCheckBoxChange = e => {
    const checked = e.target.checked
    this.setState({ checked }, () => {
      const { onChange } = this.props
      onChange(e.target.checked ? { addNew: false, roles: [] } : false)
    })
  }

  handleAddNewCheckBoxChange = e => {
    const addNew = e.target.checked
    const { onChange, value } = this.props
    onChange({ ...value, addNew })
  }

  render() {
    const { field, value, roleList } = this.props
    const { checked } = this.state
    let { roles = [], addNew = false } = value || {}
    const rolesList = getRolesList(roleList)
    roles = intersectionValue(roleList, roles)

    return (
      <div className={styles.refProjectDepartmentRoleListWrapper} id="384123721391837823987">
        <div className={styles.header}>
          <Checkbox onChange={this.handleCheckBoxChange} checked={checked}>
            {field.label}
          </Checkbox>

          <Popover
            placement="right"
            content={
              <div style={{ maxWidth: 270 }}>
                {i18n.get(
                  '所选角色内的员工可以查看所有企业报表，且仅能查看员工在该角色内对应的部门或项目的单据数据。 例如：张三是A部门的部门主管，那么张三可以查看所有企业报表，且能够看到A部门的单据数据。'
                )}
              </div>
            }
          >
            <div className={styles.help}>?</div>
          </Popover>
        </div>

        <Select
          showSearch
          className={styles.select}
          disabled={!checked}
          value={roles}
          mode="multiple"
          style={{ minWidth: 400 }}
          placeholder={field.placeholder}
          getPopupContainer={() => document.getElementById('384123721391837823987')}
          optionFilterProp="children"
          onChange={this.handleSelectChange}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {this.buildSelectOptions(rolesList)}
        </Select>

        <div className={`${styles.header} ${styles.select}`}>
          <Checkbox disabled={!checked} onChange={this.handleAddNewCheckBoxChange} checked={addNew}>
            {i18n.get('允许上述已选角色内的员工新建报表')}
          </Checkbox>

          <Popover
            placement="right"
            content={<div style={{ maxWidth: 270 }}>{i18n.get("允许以上角色内的员工新建报表（新建报表仅允许本人查看）。")}</div>}
          >
            <div className={styles.help}>?</div>
          </Popover>
        </div>
      </div>
    )
  }
}

function getRolesList(roleList) {
  // @i18n-ignore
  return roleList.filter(line => line.scope.name === '部门角色' || line.scope.name === '项目角色')
}

function intersection(a, b) {
  return a.filter(v => b.indexOf(v) > -1)
}

function intersectionValue(list, array) {
  return intersection(list.map(line => line.id), array)
}
