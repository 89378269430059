import { __awaiter, __generator } from "tslib";
import { app } from '@ekuaibao/whispered';
import { Fetch } from '@ekuaibao/fetch';
import { getAliPayCardAuthState, getAliPayCardUrl, getAifapiaoCardAuthState } from '../../../bills.action';
export function checkAliPayCardAuth() {
    return __awaiter(this, void 0, Promise, function () {
        var isAuth, url, ekbCorpId, accessToken, search;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, getAliPayCardAuthState()];
                case 1:
                    isAuth = (_a.sent()).value;
                    if (isAuth) {
                        return [2, true];
                    }
                    return [4, getAliPayCardUrl()];
                case 2:
                    url = (_a.sent()).value;
                    ekbCorpId = Fetch.ekbCorpId, accessToken = Fetch.accessToken;
                    search = "?ekbCorpId=" + ekbCorpId + "&accessToken=" + accessToken;
                    app.emit('@vendor:open:link', url + encodeURIComponent(search));
                    return [2, false];
            }
        });
    });
}
export function checkAifapiaoCardAuth() {
    return __awaiter(this, void 0, Promise, function () {
        var isAuth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, getAifapiaoCardAuthState()];
                case 1:
                    isAuth = (_a.sent()).value;
                    if (isAuth) {
                        return [2, true];
                    }
                    return [4, app.open('@bills:ImportAiFaPiaoH5')];
                case 2:
                    _a.sent();
                    return [2, false];
            }
        });
    });
}
