import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { DetailValueSource } from '@ekuaibao/ekuaibao_types';
import { app as api } from '@ekuaibao/whispered';
import { getV } from '@ekuaibao/lib/lib/help';
import { array2object, isNumber, isObject, isString } from '@ekuaibao/helpers';
import { MoneyMath } from '@ekuaibao/money-math';
import { IDENTIFY_INVOICE_TYPE as INVOICE_TYPE_ENUM } from './enums';
import { getAcountKey, standardValueMoney } from './misc';
import { getDisableStateByInvoiceID, getMarkStateByInvoiceID } from '../plugins/bills/bills.action';
import { showMessage } from '@ekuaibao/show-util';
var InvoiceMappingValue = (function () {
    function InvoiceMappingValue() {
    }
    InvoiceMappingValue.prototype.invoice2Detail = function (param) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var KA_DISABLE_INVOICE, feeType, invoices, _c, specification, isBatch, attachmentList, _d, components, arr_1, disableInfoResult, markInfoResult, rule2InvoiceValue, feeTypeForm, formData;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!!this.invoiceRuleMap) return [3, 2];
                        return [4, this.lazy()];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        KA_DISABLE_INVOICE = api.getState()['@common'].powers.KA_DISABLE_INVOICE;
                        feeType = param.feeType, invoices = param.invoices, _c = param.specification, specification = _c === void 0 ? {} : _c, isBatch = param.isBatch, attachmentList = param.attachmentList;
                        _d = specification.components, components = _d === void 0 ? [] : _d;
                        if (!KA_DISABLE_INVOICE) return [3, 5];
                        arr_1 = [];
                        invoices.map(function (i) {
                            var _a;
                            if (!('disableInfo' in i) && ((_a = i.master) === null || _a === void 0 ? void 0 : _a.id)) {
                                arr_1.push(i.master.id);
                            }
                        });
                        if (!arr_1.length) return [3, 5];
                        return [4, api.dispatch(getDisableStateByInvoiceID(arr_1))];
                    case 3:
                        disableInfoResult = _e.sent();
                        return [4, getMarkStateByInvoiceID(arr_1)];
                    case 4:
                        markInfoResult = _e.sent();
                        (_a = disableInfoResult === null || disableInfoResult === void 0 ? void 0 : disableInfoResult.items) === null || _a === void 0 ? void 0 : _a.forEach(function (disable) {
                            invoices.forEach(function (i) {
                                var _a;
                                if (((_a = i.master) === null || _a === void 0 ? void 0 : _a.id) === (disable === null || disable === void 0 ? void 0 : disable.invoiceId)) {
                                    i.disableInfo = { disable: true };
                                }
                            });
                        });
                        (_b = markInfoResult === null || markInfoResult === void 0 ? void 0 : markInfoResult.items) === null || _b === void 0 ? void 0 : _b.forEach(function (mark) {
                            invoices.forEach(function (i) {
                                var _a;
                                if (((_a = i.master) === null || _a === void 0 ? void 0 : _a.id) === (mark === null || mark === void 0 ? void 0 : mark.invoiceId)) {
                                    i.markInfo = { mark: true };
                                }
                            });
                        });
                        _e.label = 5;
                    case 5:
                        rule2InvoiceValue = this.processInvoice(invoices, components);
                        feeTypeForm = this.processFieldMappingValue(components, rule2InvoiceValue, invoices);
                        feeTypeForm = this.processSummaryFields(components, feeTypeForm, rule2InvoiceValue);
                        return [4, this.processFormCityField(components, feeTypeForm)];
                    case 6:
                        feeTypeForm = _e.sent();
                        return [4, this.processEnumField(components, feeTypeForm)];
                    case 7:
                        feeTypeForm = _e.sent();
                        formData = {
                            feeTypeId: feeType,
                            feeTypeForm: feeTypeForm,
                            specificationId: specification,
                            attachmentList: attachmentList
                        };
                        if (!isBatch) return [3, 9];
                        return [4, api
                                .invoke('generate:DetailByAutoCalculate', { detailFormValue: formData, components: components }, true)
                                .catch(function () {
                                return formData;
                            })];
                    case 8: return [2, _e.sent()];
                    case 9: return [2, formData];
                }
            });
        });
    };
    InvoiceMappingValue.prototype.clearInvoicesForm = function (components) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.lazy()];
                    case 1:
                        _a.sent();
                        return [2, this.processNoInvoices(components)];
                }
            });
        });
    };
    InvoiceMappingValue.prototype.invoice2FeeTypeForm = function (invoices, components) {
        if (invoices === void 0) { invoices = []; }
        return __awaiter(this, void 0, void 0, function () {
            var rule2InvoiceValue, feeTypeForm;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.invoiceRuleMap) return [3, 2];
                        return [4, this.lazy()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!invoices.length) {
                            return [2, this.processNoInvoices(components)];
                        }
                        rule2InvoiceValue = this.processInvoice(invoices, components);
                        feeTypeForm = this.processFieldMappingValue(components, rule2InvoiceValue, invoices);
                        return [4, this.processFormCityField(components, feeTypeForm)];
                    case 3:
                        feeTypeForm = _a.sent();
                        return [4, this.processEnumField(components, feeTypeForm)];
                    case 4:
                        feeTypeForm = _a.sent();
                        return [2, this.processSummaryFields(components, feeTypeForm, rule2InvoiceValue)];
                }
            });
        });
    };
    InvoiceMappingValue.prototype.lazy = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, items;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, api.invokeService('@invoice-manage:get:invoice:mapping:rule')];
                    case 1:
                        _a = (_b.sent()).items, items = _a === void 0 ? [] : _a;
                        this.invoiceRules = items;
                        this.invoiceRuleMap = array2object(items, 'entityId');
                        return [2];
                }
            });
        });
    };
    InvoiceMappingValue.prototype.processInvoice = function (invoices, components) {
        var _this = this;
        var invoiceMapList = {};
        var invoiceEntityKeys = [];
        var baseDataPropertiesMap = api.getState('@common.globalFields.baseDataPropertiesMap') || {};
        invoices.forEach(function (invoice) {
            var list = invoiceMapList[invoice.master.entityId];
            if (!list) {
                list = [];
                invoiceMapList[invoice.master.entityId] = list;
                invoiceEntityKeys.push(invoice.master.entityId);
            }
            list.push(invoice);
        });
        var rule2InvoiceValue = {};
        invoiceEntityKeys.forEach(function (entityId) {
            var invoiceList = invoiceMapList[entityId];
            var invoiceRule = _this.invoiceRuleMap[entityId];
            invoiceRule.rules = _this.mergeRules(invoiceRule === null || invoiceRule === void 0 ? void 0 : invoiceRule.rules, _this.addDefaultRules(entityId));
            invoiceList.forEach(function (invoice, curIndex) {
                var detailResult;
                if (invoice.details && invoice.details.length) {
                    detailResult = _this.processDetail(invoice);
                }
                rule2InvoiceValue = _this.conver2DetailByRule(invoice, detailResult, invoiceRule, rule2InvoiceValue, baseDataPropertiesMap, curIndex, invoiceList.length, components);
            });
        });
        return rule2InvoiceValue;
    };
    InvoiceMappingValue.prototype.conver2DetailByRule = function (invoice, detailResult, ruleItem, rule2InvoiceValue, globalFieldMap, curIndex, invoiceListLength, components) {
        var _this = this;
        return ruleItem.rules.reduce(function (result, rule) {
            var globalField = globalFieldMap[rule.detailField];
            var type = getV(globalField, 'dataType.type');
            if (rule.detailField === 'sumAmountAndTax') {
                type = 'money';
            }
            var entity = getV(globalField, 'dataType.entity');
            var prev = result[rule.detailField];
            var current = invoice.master.form[rule.mappingValue];
            if (rule.mappingValue === 'E_税额' && invoice.taxAmount) {
                current = invoice.taxAmount;
            }
            if (rule.mappingValue === 'E_税率' && invoice.taxRate !== undefined) {
                current = invoice.taxRate;
            }
            if (rule.mappingType === DetailValueSource.FIXED) {
                current = rule.mappingValue;
            }
            if (type === 'money') {
                if (rule.detailField === 'amount' && getV(invoice, 'approveAmount') && getV(invoice, 'comment')) {
                    result[rule.detailField] = _this.processMoney(getV(invoice, 'approveAmount'), prev, rule.mappingValue, false);
                    return result;
                }
                result[rule.detailField] = _this.processMoney(current, prev, rule.mappingValue, detailResult);
                return result;
            }
            if (type === 'number') {
                result[rule.detailField] = _this.processNumber(current, prev, rule.mappingValue, detailResult, invoice.taxRate);
                return result;
            }
            if (entity === 'basedata.city' && current && rule.mappingType === DetailValueSource.INVOICEINFO) {
                result[rule.detailField] = { isFixed: false, value: current };
                return result;
            }
            if (entity === 'organization.Staff' && current && rule.mappingType === DetailValueSource.INVOICEINFO) {
                var tempResult = Object.assign({}, __assign({}, result));
                var loginStaff = api.getState('@common.userinfo.staff');
                var activesStaffs = api.getState('@common.staffsVisibility');
                var showTipMessage_1 = false;
                components.map(function (component) {
                    if (component.field == rule.detailField)
                        showTipMessage_1 = true;
                });
                var filterStaff = (activesStaffs === null || activesStaffs === void 0 ? void 0 : activesStaffs.filter(function (staff) {
                    return staff.name === current;
                })) || [];
                if ((filterStaff === null || filterStaff === void 0 ? void 0 : filterStaff.length) > 1) {
                    if ((loginStaff === null || loginStaff === void 0 ? void 0 : loginStaff.name) == filterStaff[0].name) {
                        tempResult[rule.detailField] = loginStaff;
                    }
                    else {
                        tempResult[rule.detailField] = '';
                        if (curIndex + 1 == invoiceListLength && showTipMessage_1)
                            showMessage.error(i18n.get('在该企业找到多位同名乘车人员，请手动选择'));
                    }
                }
                else if ((filterStaff === null || filterStaff === void 0 ? void 0 : filterStaff.length) == 1) {
                    tempResult[rule.detailField] = filterStaff[0];
                }
                else {
                    tempResult[rule.detailField] = '';
                    if (curIndex + 1 == invoiceListLength && showTipMessage_1)
                        showMessage.error(i18n.get('在该企业未找到对应乘车人员'));
                }
                return tempResult;
            }
            if (rule.detailField === 'invoiceType' && current && rule.mappingType === DetailValueSource.INVOICEINFO) {
                result[rule.detailField] = { isFixed: false, value: current };
                return result;
            }
            var ENUM_VALUE_TO_TEXT = api.getState('@common').powers.ENUM_VALUE_TO_TEXT;
            if (ENUM_VALUE_TO_TEXT &&
                type === 'text' &&
                rule.mappingValue === 'E_system_发票主体_发票类别' &&
                current &&
                rule.mappingType === DetailValueSource.INVOICEINFO) {
                result[rule.detailField] = INVOICE_TYPE_ENUM()[current];
                return result;
            }
            result[rule.detailField] = current;
            return result;
        }, rule2InvoiceValue);
    };
    InvoiceMappingValue.prototype.processDetail = function (invoice) {
        var deduction = invoice.master.form['E_是否抵扣'];
        var masterEntityId = invoice.master.entityId;
        return invoice.details.reduce(function (result, current) {
            var _a;
            var taxAmountKey = "E_" + current.entityId + "_\u7A0E\u989D";
            var noTaxAmountKey = "E_" + current.entityId + "_\u91D1\u989D";
            var taxRateKey = "E_" + current.entityId + "_\u7A0E\u7387";
            var taxAmount = result[taxAmountKey] || 0;
            var noTaxAmount = result[noTaxAmountKey] || 0;
            var sumTaxAmount = new MoneyMath(taxAmount).add(current.form[taxAmountKey] || 0).value;
            var sumNoTaxAmount = new MoneyMath(noTaxAmount).add(current.form[noTaxAmountKey] || 0).value;
            var sumAmountAndTax = new MoneyMath(sumTaxAmount).add(sumNoTaxAmount).value;
            var currentTaxRate = current.form[taxRateKey];
            var taxRate = result.hasOwnProperty(taxRateKey) ? result[taxRateKey] : currentTaxRate;
            if (taxRate !== undefined && taxRate !== currentTaxRate) {
                taxRate = undefined;
            }
            result = (_a = {},
                _a[taxAmountKey] = sumTaxAmount,
                _a[noTaxAmountKey] = sumNoTaxAmount,
                _a[taxRateKey] = taxRate,
                _a["E_\u7A0E\u7387"] = taxRate,
                _a["E_" + masterEntityId + "_\u7A0E\u989D"] = sumTaxAmount,
                _a["E_\u4E0D\u8BA1\u7A0E\u91D1\u989D"] = sumNoTaxAmount,
                _a["E_" + masterEntityId + "_\u4EF7\u7A0E\u5408\u8BA1"] = sumAmountAndTax,
                _a.sumAmountAndTax = sumAmountAndTax,
                _a);
            if (deduction) {
                result["E_\u7A0E\u989D"] = invoice.taxAmount || sumTaxAmount;
            }
            return result;
        }, {});
    };
    InvoiceMappingValue.prototype.processMoney = function (current, prev, sourceField, detailResult) {
        if (prev === void 0) { prev = standardValueMoney(0); }
        if (detailResult) {
            current = detailResult[sourceField] ? detailResult[sourceField] : current;
        }
        var money = new MoneyMath(prev).add(current).value;
        if (money.foreignStrCode && money.foreign) {
            money.foreign = Number(money.foreign).toFixed(Number(money.foreignScale));
        }
        return money;
    };
    InvoiceMappingValue.prototype.processNumber = function (current, prev, sourceField, detailResult, taxRate) {
        if (sourceField === void 0) { sourceField = ''; }
        if (taxRate === void 0) { taxRate = ''; }
        if (sourceField.indexOf('税率') >= 0) {
            current = detailResult ? detailResult[sourceField] : current;
            var result = prev !== undefined && current !== prev ? '' : current;
            if (isString(result) && (result === null || result === void 0 ? void 0 : result.length) && taxRate) {
                result = taxRate;
            }
            return result;
        }
        return String(Number(current) + Number(prev || 0));
    };
    InvoiceMappingValue.prototype.processFieldMappingValue = function (components, rule2InvoiceValue, invoices) {
        return components.reduce(function (result, current) {
            var value = rule2InvoiceValue[current.field];
            if (value !== undefined) {
                if (current.field === 'invoiceType' && isObject(value) && !value.isFixed) {
                    result[current.field] = invoiceTypeMapping[value.value];
                    return result;
                }
                if (current.field === 'taxRate') {
                    var rate = isNumber(value) ? String(value) : value;
                    value = rate === null || rate === void 0 ? void 0 : rate.replace('%', '');
                    value = isNaN(Number(value)) ? '0' : value;
                }
                result[current.field] = value;
                return result;
            }
            if (current.field === 'invoice') {
                result[current.field] = '1';
                return result;
            }
            if (current.field === 'invoiceForm') {
                result[current.field] = {
                    invoices: invoices,
                    type: 'exist'
                };
            }
            return result;
        }, {});
    };
    InvoiceMappingValue.prototype.processSummaryFields = function (component, feeTypeForm, rule2InvoiceValue) {
        var summaryFields = component.filter(function (line) { return getV(line, 'defaultValue.type') === 'invoiceSum'; });
        if (summaryFields.length) {
            summaryFields.forEach(function (_a) {
                var defaultValue = _a.defaultValue, field = _a.field;
                var v = getV(defaultValue, 'value');
                var value = getAcountKey(v);
                if (v === 'taxTotal') {
                    feeTypeForm[field] = new MoneyMath(rule2InvoiceValue.sumAmountAndTax).value;
                }
                else {
                    feeTypeForm[field] = rule2InvoiceValue[value];
                }
            });
        }
        return feeTypeForm;
    };
    InvoiceMappingValue.prototype.processNoInvoices = function (component) {
        var fields = this.invoiceRules.reduce(function (result, current) {
            return current.rules.reduce(function (ruleResult, ruleCurrent) {
                ruleResult.push(ruleCurrent.detailField);
                return ruleResult;
            }, result);
        }, []);
        return component.reduce(function (feeTypeForm, c) {
            if (!!~fields.indexOf(c.field)) {
                feeTypeForm[c.field] = undefined;
            }
            return feeTypeForm;
        }, {});
    };
    InvoiceMappingValue.prototype.processFormCityField = function (components, feeTypeForm) {
        return __awaiter(this, void 0, void 0, function () {
            var valueFields, cityKeys, encodeCityKeys, _a, cityValue_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        valueFields = components.reduce(function (result, c) {
                            if (getV(c, 'type') === 'city') {
                                var city = feeTypeForm[c.field];
                                if (city && city.isFixed !== undefined && !city.isFixed) {
                                    var fields = result[city.value] || [];
                                    fields.push(c.field);
                                    result[city.value] = fields;
                                }
                            }
                            return result;
                        }, {});
                        cityKeys = Object.keys(valueFields);
                        encodeCityKeys = cityKeys.map(function (oo) { return encodeURIComponent(oo); });
                        if (!cityKeys.length) return [3, 2];
                        return [4, api.invokeService('@invoice-manage:get:invoice:city', {
                                cities: encodeCityKeys
                            })];
                    case 1:
                        _a = (_b.sent()).value, cityValue_1 = _a === void 0 ? {} : _a;
                        return [2, cityKeys.reduce(function (form, key) {
                                var value = cityValue_1[key];
                                var fields = valueFields[key];
                                fields.reduce(function (fieldForm, field) {
                                    fieldForm[field] = value ? JSON.stringify([value]) : undefined;
                                    return fieldForm;
                                }, feeTypeForm);
                                return form;
                            }, feeTypeForm)];
                    case 2: return [2, Promise.resolve(feeTypeForm)];
                }
            });
        });
    };
    InvoiceMappingValue.prototype.processEnumField = function (components, feeTypeForm) {
        return __awaiter(this, void 0, void 0, function () {
            var baseDataPropertiesMap, result, enumCodes, promises, data, items_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        baseDataPropertiesMap = api.getState('@common.globalFields.baseDataPropertiesMap') || {};
                        result = components.reduce(function (result, c) {
                            var globalField = baseDataPropertiesMap[c.field];
                            var entity = getV(globalField, 'dataType.entity', '') || getV(globalField, 'dataType.elemType.entity', '');
                            var value = feeTypeForm[c.field];
                            if (entity.startsWith('basedata.Enum') && isString(value)) {
                                var code = entity.split('.').pop();
                                var fields = (result[code] || { fields: [] }).fields;
                                fields.push(c.field);
                                result[code] = { fields: fields, value: value };
                            }
                            return result;
                        }, {});
                        enumCodes = Object.keys(result);
                        if (!enumCodes.length) return [3, 2];
                        promises = enumCodes.map(function (code) { return api.invokeService('@common:get:enumitems', code); });
                        return [4, Promise.all(promises)];
                    case 1:
                        data = _a.sent();
                        items_1 = data.reduce(function (result, item) {
                            result = result.concat(item.items);
                            return result;
                        }, []);
                        if (items_1.length) {
                            return [2, enumCodes.reduce(function (form, code) {
                                    var _a = result[code], fields = _a.fields, name = _a.value;
                                    var value = items_1.find(function (item) { return item.id === name || item.code === name || item.name === name; });
                                    fields.reduce(function (fieldForm, field) {
                                        fieldForm[field] = value;
                                        return fieldForm;
                                    }, feeTypeForm);
                                    return form;
                                }, feeTypeForm)];
                        }
                        _a.label = 2;
                    case 2: return [2, feeTypeForm];
                }
            });
        });
    };
    InvoiceMappingValue.prototype.addDefaultRules = function (entityId) {
        var baseRules = [
            { detailField: 'taxAmount', mappingType: 'invoiceInfo', mappingValue: 'E_税额' },
            { detailField: 'noTaxAmount', mappingType: 'invoiceInfo', mappingValue: 'E_不计税金额' }
        ];
        var defaultRulesMap = {
            system_发票主体: __spread(baseRules, [
                { detailField: 'taxRate', mappingType: 'invoiceInfo', mappingValue: 'E_税率' },
                { detailField: 'sumAmountAndTax', mappingType: 'invoiceInfo', mappingValue: "E_" + entityId + "_\u4EF7\u7A0E\u5408\u8BA1" }
            ]),
            system_出租车票: __spread(baseRules, [
                { detailField: 'sumAmountAndTax', mappingType: 'invoiceInfo', mappingValue: 'E_system_出租车票_金额' }
            ]),
            system_火车票: __spread(baseRules, [
                { detailField: 'sumAmountAndTax', mappingType: 'invoiceInfo', mappingValue: 'E_system_火车票_金额' }
            ]),
            system_客运汽车发票: __spread(baseRules, [
                { detailField: 'sumAmountAndTax', mappingType: 'invoiceInfo', mappingValue: 'E_system_客运汽车发票_金额' }
            ]),
            system_航空运输电子客票行程单: __spread(baseRules, [
                {
                    detailField: 'sumAmountAndTax',
                    mappingType: 'invoiceInfo',
                    mappingValue: 'E_system_航空运输电子客票行程单_金额'
                }
            ]),
            system_过路费发票: __spread(baseRules, [
                { detailField: 'sumAmountAndTax', mappingType: 'invoiceInfo', mappingValue: 'E_system_过路费发票_金额' }
            ]),
            system_定额发票: __spread(baseRules),
            system_其他: __spread(baseRules)
        };
        return defaultRulesMap[entityId] || [];
    };
    InvoiceMappingValue.prototype.mergeRules = function (rules, toMergeRules) {
        var map = array2object(rules, 'detailField');
        return toMergeRules.reduce(function (array, rule) {
            if (!map[rule.detailField]) {
                array.push(rule);
            }
            return array;
        }, rules);
    };
    return InvoiceMappingValue;
}());
export default InvoiceMappingValue;
var invoiceTypeMapping = {
    DIGITAL_NORMAL: 'VATElectronicInvoice',
    DIGITAL_SPECIAL: 'VATSpecialInvoice',
    PAPER_NORMAL: 'VATOrdinaryInvoice',
    PAPER_SPECIAL: 'VATSpecialInvoice',
    PAPER_CAR: 'MotorInvoice',
    PAPER_ROLL: 'VATVolumeTicket',
    PAPER_FEE: 'TollInvoice',
    BLOCK_CHAIN: 'BlockchainElectronicInvoice',
    FULL_DIGITAl_NORMAL: 'FullDigitalNormal',
    FULL_DIGITAl_SPECIAL: 'FullDigitalSpecial'
};
