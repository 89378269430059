import { __decorate, __extends } from "tslib";
import React from 'react';
import styles from './PayPlanReceiptActions.module.less';
import { downloadReceipt, printReceipt, previewReceipt } from '../helper/fetchUtil';
import { getV } from '@ekuaibao/lib/lib/help';
import { app as api } from '@ekuaibao/whispered';
import { EnhanceConnect } from '@ekuaibao/store';
var PayPlanReceiptActions = (function (_super) {
    __extends(PayPlanReceiptActions, _super);
    function PayPlanReceiptActions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openModal = function () {
            var _a = _this.props, record = _a.record, BankReceiptPdfModel = _a.BankReceiptPdfModel;
            api.open('@bills:ReceiptFileModal', { payPlanId: record.key, ids: record.receiptIds, BankReceiptPdfModel: BankReceiptPdfModel });
        };
        _this.handlePreview = function () {
            var record = _this.props.record;
            printReceipt(record.receiptIds);
        };
        _this.handleDowload = function () {
            var record = _this.props.record;
            downloadReceipt(record.receiptIds);
        };
        _this.handlePrint = function () {
            var record = _this.props.record;
            printReceipt(record.receiptIds);
        };
        _this.handlePreviewNTSC = function () {
            var ids = getV(_this.props, 'record.receiptIds', []);
            if (ids.length > 0) {
                previewReceipt(ids[0], _this.props.BankReceiptPdfModel);
            }
        };
        _this.renderPreview = function () {
            var _a = _this.props, dataSource = _a.dataSource, receiptArr = _a.receiptArr, KA_REEXCHANGE_PROCESSING = _a.KA_REEXCHANGE_PROCESSING;
            if (!(receiptArr.length > 0)) {
                return (React.createElement("span", null));
            }
            var paymentChannel = getV(dataSource, 'form.paymentChannel');
            if (KA_REEXCHANGE_PROCESSING) {
                return React.createElement("span", { onClick: _this.openModal }, i18n.get('查看'));
            }
            else {
                if (paymentChannel === 'NSTC') {
                    return React.createElement("span", { onClick: _this.handlePreviewNTSC }, i18n.get('预览'));
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", { onClick: _this.handlePreview }, i18n.get('查看')),
                    React.createElement("span", { onClick: _this.handleDowload }, i18n.get('下载')),
                    React.createElement("span", { onClick: _this.handlePrint }, i18n.get('打印'))));
            }
        };
        return _this;
    }
    PayPlanReceiptActions.prototype.render = function () {
        var receiptArr = this.props.receiptArr;
        if (!receiptArr) {
            return null;
        }
        return (React.createElement("div", { className: styles['PayPlanReceiptActions-wrapper'] }, this.renderPreview()));
    };
    PayPlanReceiptActions = __decorate([
        EnhanceConnect(function (state) { return ({
            BankReceiptPdfModel: state['@common'].powers.BankReceiptPdfModel,
            KA_REEXCHANGE_PROCESSING: state['@common'].powers.KA_REEXCHANGE_PROCESSING
        }); })
    ], PayPlanReceiptActions);
    return PayPlanReceiptActions;
}(React.Component));
export default PayPlanReceiptActions;
