import { allPermissions } from '../configure/config'
import React from 'react'
import { get } from 'lodash'
import WarningPopup from '../warningPopup/WarningPopup'

export function permissionSort(permissionCodes) {
  return allPermissions.filter(e => !!~permissionCodes.indexOf(e))
}

export function setInputDisabled(source, type, userInfo) {
  let disabled = false
  switch (source) {
    case 'contacts':
      disabled = !!userInfo[type]
      break
    case 'userInfo':
      disabled = true
      break
  }
  return disabled
}

export function generateComponent(type, checkConsistent) {
  const str = type === 'cellphone' ? i18n.get('手机号') : i18n.get('邮箱')
  const content = i18n.get(`该账号非员工登录所使用的{__k0}`, { __k0: str })
  const style = { marginLeft: 0 }
  let Component = <></>
  switch (type) {
    case 'cellphone':
      const isPhoneSame = get(checkConsistent, 'isPhoneSame')
      if (!isPhoneSame) {
        Component = <WarningPopup content={content} style={style} />
      }
      break
    case 'email':
      const isEmailSame = get(checkConsistent, 'isEmailSame')
      if (!isEmailSame) {
        Component = <WarningPopup content={content} style={style} />
      }
      break
    default:
      Component = <></>
  }

  return Component
}

const defaultFields = isAllowExternalStaff => {
  const baseFields = [
    {
      name: 'name',
      label: i18n.get('姓名'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'text'
      },
      cnLabel: i18n.get('姓名'),
      enLabel: '',
      editable: true,
      field: 'name',
      type: 'text',
      optional: true,
      placeholder: '请输入姓名',
      isDisplayOptional: false
    },
    {
      name: 'code',
      label: i18n.get('工号'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'text'
      },
      cnLabel: i18n.get('工号'),
      enLabel: '',
      editable: true,
      field: 'code',
      type: 'text',
      optional: true,
      placeholder: '请输入工号',
      isDisplayOptional: false
    },
    {
      name: 'cellphone',
      label: i18n.get('手机号'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'text'
      },
      cnLabel: i18n.get('手机号'),
      enLabel: '',
      editable: false,
      field: 'cellphone',
      type: 'number',
      optional: true,
      placeholder: '请输入手机号',
      isDisplayOptional: false
    },
    {
      name: 'email',
      label: i18n.get('邮箱'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'text'
      },
      cnLabel: i18n.get('邮箱'),
      enLabel: '',
      editable: true,
      field: 'email',
      type: 'text',
      optional: true,
      placeholder: '请输入邮箱',
      isDisplayOptional: false
    },
    {
      name: 'note',
      label: i18n.get('备注'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'text'
      },
      cnLabel: i18n.get('备注'),
      enLabel: '',
      editable: true,
      field: 'note',
      type: 'text',
      optional: true,
      placeholder: '请输入备注',
      isDisplayOptional: false
    }
  ]
  if (!isAllowExternalStaff) {
    baseFields.push({
      name: 'departments',
      label: i18n.get('部门'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'ref:organization.Department'
      },
      cnLabel: i18n.get('部门'),
      enLabel: '',
      editable: true,
      field: 'departments',
      type: 'ref:organization.Department',
      optional: true,
      placeholder: '请选择部门',
      multiple: true,
      isDisplayOptional: false,
      selectRange: 'all',
      format: values => {
        return values && values.map(vv => vv.name).join(',')
      }
    })
    baseFields.push({
      name: 'cost_center',
      label: i18n.get('成本中心'),
      active: true,
      canAsDimension: false,
      ability: '',
      dataType: {
        type: 'text'
      },
      cnLabel: i18n.get('成本中心'),
      enLabel: '',
      editable: false,
      field: 'cost_center',
      type: 'text',
      optional: true,
      placeholder: '-',
      isDisplayOptional: false,
      selectRange: 'all'
    })
  }
  return baseFields
}

export function convertStaffFieldsToTemplate({
  fields = [],
  needDefaultFields = false,
  isAllowExternalStaff = false,
  _isAdmin = false,
  disableKeys
}) {
  let cps = fields
    .filter(filed => !!filed.active)
    .map(field => {
      field.editable = _isAdmin
        ? field.editable !== undefined
          ? field.editable
          : field.name.indexOf('.') > -1
          ? false
          : true
        : false
      if (field.editable && !!disableKeys?.length) {
        field.editable = !disableKeys?.includes(field.name)
      }
      field.optional = field.optional !== undefined ? field.optional : true
      field.field = field.name
      field.type = field?.dataType?.type
      field.selectRange = 'all'
      if (field.type === 'ref') {
        field.placeholder = field.placeholder || `请选择${field.label}`
      }
      field.placeholder = field.placeholder || `请输入${field.label}`
      if (field?.dataType?.entity?.startsWith('basedata.city')) {
        field.type = 'city'
      }
      if (field?.dataType?.entity?.startsWith('basedata.Dimension')) {
        field.type = `ref:basedata.Dimension`
      }
      if (field?.dataType?.entity?.startsWith('basedata.Enum')) {
        field.type = `ref:basedata.Enum`
      }
      if (field?.dataType?.entity?.startsWith('basedata.Settlement')) {
        field.type = `ref:basedata.Settlement`
      }
      if (field.type === 'date') {
        field.isClear = true
        field.optionalDefaultValue = true
      }
      if (field.name.indexOf('.') > -1) {
        field.placeholder = i18n.get('无')
        field.defaultPlaceholder = true
      }
      return field
    })
  if (needDefaultFields) {
    cps = defaultFields(isAllowExternalStaff).concat(cps)
  }
  return cps
}

export function fnHandleDefalutValue(values = {}) {
  const defaultValues = [
    'name',
    'code',
    'email',
    'note',
    'cellphone',
    'defaultDepartment',
    'defaultDepartmentName',
    'departments',
    'departmentsName'
  ]
  if (values.departments) {
    values.departments = values.departments.map(d => d.id)
  }
  const result = {}
  defaultValues.forEach(key => {
    result[key] = values[key]
    delete values[key]
  })

  return { ...result, staffCustomForm: values }
}
