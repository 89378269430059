/**
 * Created by zhaoyue on 2017/7/18.
 */

import React, { PureComponent } from 'react'
import loadImage from 'image-promise'
import SVG_SRC from './images/icon.svg'
import SVG_ERROR_SRC from './images/icon-error.svg'

export default class Thumbnail extends PureComponent {
  static defaultProps = {
    className: '',
    src: SVG_SRC,
    errSrc: SVG_ERROR_SRC,
    alt: '',
    width: '',
    height: ''
  }

  constructor(props) {
    super(props)
    this.state = {
      src: props.src
    }
  }

  componentWillMount() {
    const { src } = this.props
    this.loadImg(src)
    this.setState({ src })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ src: nextProps.src })
    }
  }

  loadImg(src) {
    loadImage(src)
      .then(() => {
        this.setState({ src })
      })
      .catch(() => {
        this.setState({ src: SVG_ERROR_SRC })
      })
  }

  render() {
    const { src } = this.state
    const { className, alt, width, height } = this.props
    return <img className={className} src={src} alt={alt} width={width || '20'} height={height || '20'} />
  }
}
