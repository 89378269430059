export var dimensionDeptTags = [
    {
        id: 'in',
        label: i18n.get('的值是')
    },
    {
        id: 'include',
        label: i18n.get('的值属于')
    }
];
export var switchTags = [
    {
        id: '=',
        label: i18n.get('等于')
    }
];
export var stateTags = [
    {
        id: 'false',
        name: i18n.get('停用'),
        label: i18n.get('停用')
    },
    {
        id: 'true',
        name: i18n.get('启用'),
        label: i18n.get('启用')
    }
];
export var planTags = [
    {
        value: '_balance',
        name: i18n.get('余额'),
        label: i18n.get('余额')
    },
    {
        value: '_percentage',
        name: i18n.get('百分比'),
        label: i18n.get('百分比')
    }
];
export var moneyTags = [
    {
        id: '>',
        label: '>'
    },
    {
        id: '<',
        label: '<'
    },
    {
        id: '>=',
        label: '≥'
    },
    {
        id: '<=',
        label: '≤'
    }
];
export var moneyNumber = [
    {
        id: '>',
        label: '>'
    },
    {
        id: '<',
        label: '<'
    },
    {
        id: '>=',
        label: '≥'
    },
    {
        id: '<=',
        label: '≤'
    }, {
        id: '=',
        label: '='
    }, {
        id: '!=',
        label: '≠'
    }
];
export var TextTags = [
    {
        id: 'yes',
        label: i18n.get('是')
    },
    {
        id: 'no',
        label: i18n.get('不是')
    },
    {
        id: 'include',
        label: i18n.get('包含')
    },
    {
        id: 'exclude',
        label: i18n.get('不包含')
    }
];
export var dimensionDeptTags2 = [
    {
        id: 'in',
        label: i18n.get('是')
    },
    {
        id: 'notIn',
        label: i18n.get('不是')
    }
];
export var staffTags = [
    {
        id: 'in',
        label: i18n.get('是')
    },
    {
        id: 'notIn',
        label: i18n.get('不是')
    }
];
export var switchTags2 = [
    {
        id: '=',
        label: i18n.get('等于')
    }
];
export var TextTags2 = [
    {
        id: '=',
        label: i18n.get('是')
    },
    {
        id: '!=',
        label: i18n.get('不是')
    },
    {
        id: 'contains',
        label: i18n.get('包含')
    },
    {
        id: 'notContains',
        label: i18n.get('不包含')
    }
];
export var dateTags = [
    {
        id: '=',
        label: i18n.get('等于')
    },
    {
        id: '!=',
        label: i18n.get('不等于')
    },
    {
        id: '>=',
        label: i18n.get('早于')
    },
    {
        id: '<=',
        label: i18n.get('晚于')
    },
    {
        id: 'in',
        label: i18n.get('属于')
    },
    {
        id: 'not in',
        label: i18n.get('不属于')
    }
];
export var typesMap1 = {
    department: dimensionDeptTags,
    number: moneyNumber,
    PLAN: moneyTags,
    LEDGER: moneyTags,
    text: TextTags,
    switcher: switchTags,
    active: switchTags,
    money: moneyNumber,
    staff: staffTags,
    date: dateTags
};
export var typesMap2 = {
    department: dimensionDeptTags2,
    number: moneyNumber,
    PLAN: moneyTags,
    LEDGER: moneyTags,
    text: TextTags2,
    switcher: switchTags2,
    active: switchTags2,
    money: moneyNumber,
    staff: staffTags
};
export var billStates = [
    {
        active: true,
        id: 'draft',
        value: 'draft',
        label: i18n.get('待提交')
    },
    {
        active: true,
        id: 'pending,approving',
        value: 'pending,approving',
        label: i18n.get('审核中')
    },
    {
        active: true,
        id: 'waitingPay',
        value: 'waitingPay',
        label: i18n.get('待支付')
    },
    {
        active: true,
        id: 'paying',
        value: 'paying',
        label: i18n.get('支付中')
    },
    {
        active: true,
        id: 'archived',
        value: 'archived',
        label: i18n.get('已完成')
    },
    {
        active: true,
        id: 'sending',
        value: 'sending',
        label: i18n.get('待寄送')
    },
    {
        active: true,
        id: 'receiving',
        value: 'receiving',
        label: i18n.get('待收单')
    },
    {
        active: true,
        id: ' receivingExcep',
        value: 'receivingExcep',
        label: i18n.get('收单异常')
    },
    {
        active: true,
        id: 'rejected',
        value: 'rejected',
        label: i18n.get('被驳回')
    }
];
export var switchSelector = [
    {
        id: 'true',
        name: i18n.get('开'),
        label: i18n.get('开')
    },
    {
        id: 'false',
        name: i18n.get('关'),
        label: i18n.get('关')
    }
];
