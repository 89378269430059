import { __assign } from "tslib";
import moment from 'moment';
import { thousandBitSeparator } from '../../../components/utils/fnThousandBitSeparator';
import { getStrLastWord } from '@ekuaibao/lib/lib/entityUtil/entityTableUtil';
import { formatLinkText, fixCurrencyList } from '../../DataLinkTable/tableUtil';
import { getNewMoney } from '../../../elements/puppet/Currency';
export { getStrLastWord };
export function formatEntityList(entity, entityList, symbol, isFormatNone) {
    if (symbol === void 0) { symbol = '/'; }
    if (isFormatNone === void 0) { isFormatNone = true; }
    if (!entity || !entityList) {
        return [];
    }
    var cEntityList = [];
    entity.fields.forEach(function (v) {
        var formatStr = entityList.form[v.name];
        var item = {};
        item.label = v.label;
        item.name = v.name;
        switch (v.type) {
            case 'money':
                item.content = formatStr ? formatStr.standardSymbol + thousandBitSeparator(formatStr.standard) : '';
                break;
            case 'date':
                item.content = formatStr
                    ? v.withTime
                        ? moment(formatStr).format("YYYY" + symbol + "MM" + symbol + "DD HH:mm")
                        : moment(formatStr).format("YYYY" + symbol + "MM" + symbol + "DD")
                    : '-';
                break;
            case 'dateRange':
                item.content = formatStr ? fnGetDateRangeFormate(v.withTime, formatStr.start, formatStr.end) : '-';
                break;
            case 'switcher':
                item.content = formatStr ? i18n.get('是') : i18n.get('否');
                break;
            case 'duration':
                var m = Math.ceil((formatStr % 3600000) / 60000);
                var h = Math.floor(formatStr / 3600000);
                if (h > 0) {
                    item.content = i18n.get("{__k0}\u65F6{__k1}\u5206", { __k0: h, __k1: m });
                }
                else {
                    item.content = i18n.get("{__k0}\u5206", { __k0: m });
                }
                break;
            case 'autoNumber':
                item.content = v.rule;
                break;
            default:
                item.content = formatStr ? formatStr : isFormatNone ? '-' : '';
        }
        cEntityList.push(item);
    });
    return cEntityList;
}
export function fnGetDateRangeFormate(withTime, start, end) {
    return withTime
        ? moment(Number(start)).format('YYYY/MM/DD HH:mm') + '~' + moment(Number(end)).format('YYYY/MM/DD HH:mm')
        : moment(Number(start)).format('YYYY/MM/DD') + '~' + moment(Number(end)).format('YYYY/MM/DD');
}
export function formatEntityItem(entity, entityList) {
    if (!entityList || !entity) {
        return [];
    }
    var cEntityItem = [];
    entity.fields.forEach(function (v) {
        var item = {};
        if (v.name.endsWith('_name') || v.name.endsWith('_code')) {
            item.label = v.label;
            item.content = entityList.form[v.name];
            cEntityItem.push(item);
        }
    });
    return cEntityItem;
}
export function getValue(titleList, data) {
    return (titleList || [])
        .map(function (v) {
        if (data[v.source] && (v === null || v === void 0 ? void 0 : v.entity) === "basedata.Enum.currency") {
            var formatStr = data[v.source][v.name] || '';
            return fixCurrencyList(formatStr);
        }
        if (data[v.source] && data[v.source][v.name]) {
            var formatStr = data[v.source][v.name];
            if (v.type === 'text') {
                var textValue = formatLinkText(formatStr, v.name).textValue;
                formatStr = textValue;
            }
            if (v.type === 'date') {
                return v.withTime ? moment(formatStr).format('YYYY/MM/DD HH:mm') : moment(formatStr).format("YYYY/MM/DD");
            }
            if (v.source === 'planned') {
                var moneyName = formatPlaned(v, data, 'planned').moneyName;
                var value = formatStr && formatStr[moneyName];
                return v.type === 'number' ? value + v.unit : formatMoney(value);
            }
            if (formatStr instanceof Object && v.source !== 'planned') {
                if (v.type === 'money') {
                    return formatMoney(formatStr);
                }
                return formatStr.name;
            }
            if (v.type === 'ref' && v.entity === 'basedata.city' && formatStr && typeof formatStr === 'string') {
                return JSON.parse(formatStr)
                    .map(function (i) { return i.label; })
                    .join(',');
            }
            return formatStr ? (v.unit ? formatStr + v.unit : formatStr) : '-';
        }
        else {
            return '-';
        }
    })
        .join(' | ');
}
function formatMoney(value) {
    if (!value) {
        return '';
    }
    return getNewMoney(value);
}
export function getOverviewValue(type, overview, data) {
    if (type === 'PLANNED') {
        if (!data ||
            !data[overview.source] ||
            !data[overview.source][overview.plannedId]) {
            return void 0;
        }
        var planData = data[overview.source][overview.plannedId];
        return __assign(__assign({}, planData), overview);
    }
    else {
        return getFieldValue(overview.fields, data);
    }
}
function getFieldValue(overviewList, data) {
    var cc = [];
    overviewList.map(function (item) {
        var source = item.source;
        if (source === 'planned') {
            var Keyname = data[source][item.name];
            if (!Keyname)
                return;
            var _a = formatPlaned(item, data, source), moneyName = _a.moneyName, classname = _a.classname;
            var value = Keyname && item.unit ? Keyname[moneyName] + item.unit : Keyname[moneyName];
            cc.push({
                value: value,
                label: item.label,
                type: item.type,
                classname: classname,
                executeName: Keyname && Keyname.executeName,
                controlType: Keyname && Keyname.controlType
            });
        }
        else {
            var value = data[source][item.name];
            if (value !== undefined) {
                if (item.type === 'switcher') {
                    value = value ? i18n.get('启用中') : i18n.get('已停用');
                }
                else if (item.type === 'ref') {
                    value = data[source][item.name] && data[source][item.name].name;
                }
                if (item.name === 'useCount') {
                    value = data[item.source][item.name] + '/' + data[item.source].totalCount;
                }
                if (item.unit) {
                    value = value + item.unit;
                }
            }
            else {
                value = '-';
            }
            data[source] && cc.push({ value: value, label: item.label, type: item.type });
        }
    });
    return cc;
}
function formatPlaned(item, data, source) {
    var moneyName = '';
    var classname = '';
    if (item.name.endsWith('_balance')) {
        moneyName = 'BALANCE';
        if (data[source] &&
            data[source][item.name] &&
            data[source][item.name][moneyName] &&
            data[source][item.name][moneyName].standard < 0) {
            classname = 'overview-tips-error';
        }
    }
    else if (item.name.endsWith('_percentage')) {
        moneyName = 'PERCENTAGE';
        if (data[source] && data[source][item.name] && data[source][item.name][moneyName] > 100) {
            classname = 'overview-tips-error';
        }
    }
    return { moneyName: moneyName, classname: classname };
}
