var DataSourceType;
(function (DataSourceType) {
    DataSourceType["CONSTANT"] = "CONSTANT";
    DataSourceType["DATALINK_FIELD"] = "DATALINK_FIELD";
    DataSourceType["BILL_FIELD"] = "BILL_FIELD";
    DataSourceType["DYNAMIC"] = "DYNAMIC";
    DataSourceType["RELATIVE_TODAY"] = "RELATIVE_TODAY";
    DataSourceType["BILL_DETAIL_FIELD"] = "BILL_DETAIL_FIELD";
})(DataSourceType || (DataSourceType = {}));
var TupleInvoiceType = function () { return [
    {
        label: i18n.get('电子发票文件'),
        value: 'UPLOAD'
    },
    {
        label: i18n.get('手工录入'),
        value: 'QUERY'
    },
    {
        label: i18n.get('扫一扫录入'),
        value: 'SCAN'
    },
    {
        label: i18n.get('智能拍票'),
        value: 'OCR'
    },
    {
        label: i18n.get('微信卡包导入'),
        value: 'WECHAT_CARD'
    },
    {
        label: i18n.get('支付宝卡包导入'),
        value: 'ALIPAY_CARD'
    },
    {
        label: i18n.get('爱发票导入'),
        value: 'AIFAPIAO'
    }
]; };
var isNull;
(function (isNull) {
    isNull["null"] = "null";
    isNull["notNull"] = "not null";
})(isNull || (isNull = {}));
export { DataSourceType, TupleInvoiceType, isNull };
