import React from 'react';
import styles from './NoteContentLine.module.less';
import classNames from 'classnames';
import { isIE } from '../../../lib/misc';
export default function NoteContentLine(props) {
    var _a;
    var name = props.name, text = props.text;
    return (React.createElement("div", { className: classNames(styles['note-content-text-wrap'], (_a = { 'dis-f': !isIE() }, _a[styles['ie-note-content-text-wrap']] = isIE(), _a)) },
        React.createElement("span", { className: 'note-content-text-authorName' }, name),
        React.createElement("span", { className: classNames('note-content-text-content') }, text)));
}
