/**
 * common 通用模块
 */
export default [
  {
    id: '@commonLib',
    ref: '/'
  },
  {
    id: '@modals',
    point: '@@layers',
    prefix: '@modals',
    onload: () => import('./modals')
  }
]
