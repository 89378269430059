/**
 * @author Yun.kou <koopking@gmail.com>
 * @date 2017-12-29
 * @param {object} scene -场景
 * @description 用于把场景的过滤条件整合，拼装给DataGrid的Fetch,
 *              1. traversalTree用于递归遍历树(部门/档案)，
 *                 核心思路：遍历树转换成一维，去重。
 *                 兼容是否包含叶子节点逻辑
 *              2. getFormatMapping 规则映射:根据列选值类型，映射分类。
 *                 类别映射转换规则。
 *                 ⚠️ 注意：prefix 为偏移参数，对应不同请求类型。
 * @description 😂  😂  😂  这段代码有毒,时隔半年再次看根本看不懂,
 *              函数式编程写的时候爽，维护的时候正要人命(╯-_-)╯~╩╩。
 */

import { cloneDeep } from 'lodash'
import { app as api } from '@ekuaibao/whispered'

// formatMapping, buildFunctionMap 根据场景增加进行枚举扩展

const conditionMap = {
  or: '||',
  and: '&&'
}

const getFormatMapping = (type, includeDep = false) => {
  const prefix = type === 'backlog' ? 'flowId.' : ''
  const prefixType = type === 'backlog' ? 'type' : 'formType'
  let Map = includeDep
    ? {
        filterKey: '',
        filterQuery: 'inValue'
      }
    : {
        filterKey: '.id',
        filterQuery: 'in'
      }
  return {
    [`${prefix}form.loanDepartment`]: Map,
    [`${prefix}form.expenseDepartment`]: Map,
    [i18n.get(`{__k0}form.项目`, { __k0: prefix })]: {
      filterKey: '.id',
      filterQuery: 'in'
    },
    [prefixType]: {
      filterKey: '',
      filterQuery: 'inValue'
    }
  }
}

const getFormatSpecificationId = (key, value) => {
  let str = ''
  value.forEach(v => {
    str += `||${key}.contains("${v}")`
  })
  return '(' + str.slice(2) + ')'
}

const buildFunctionMap = {
  in: value => `in(${value.map(v => `"${v.id}"`)})`,
  inValue: value => `in(${value.map(v => `"${v}"`)})`,
  contains: value => `contains("${value}")`
}

function traversalDimensionTree(arr, dimensionItem) {
  return traversalTree(arr, dimensionItem)
}

function traversalDepartmentTree(arr) {
  const departmentTree = api.getState()['@common'].department.data
  return traversalTree(arr, departmentTree)
}

/**
 * @description 遍历部门/自定义档案树，并去重
 * @param arr
 * @param tree
 */
function traversalTree(arr, tree) {
  let departs = []
  const ids = arr.map(d => d.id)

  // 拍平部门树
  function fnGetDepArr(tree) {
    tree.forEach(tree => {
      departs.push(tree)
      if (tree.children.length) {
        fnGetDepArr(tree.children)
      }
    })
  }

  fnGetDepArr(tree)

  let departIds = ids

  function mapDepId(idArr) {
    if (!idArr.length) {
      return
    }

    let DepChildren = idArr
      .reduce((prev, curr) => {
        let currDep = departs.find(d => d.id === curr) || []
        return prev.concat(currDep.children)
      }, [])
      .filter(d => !!d)

    if (!DepChildren.length) {
      return
    }

    DepChildren = DepChildren.map(d => d.id).filter(d => !idArr.includes(d))

    departIds = departIds.concat(DepChildren)
    mapDepId(DepChildren)
  }

  mapDepId(departIds)
  return departIds
}

export default function(scene, type = 'backlog', dimensionItems) {
  let { condition, filters } = scene
  let queryString = ''
  if (!condition || !filters) {
    return queryString
  }

  let newFilters = cloneDeep(filters)

  newFilters = newFilters.map(f => {
    // 如果是「包含」、「不包含」算法类型，直接返回本身
    if (['contains', 'notContains'].includes(f.algorithms)) {
      return f
    }
    //遍历部门
    if (f.includeDep) {
      f.value = traversalDepartmentTree(f.value)
    }

    //遍历自定义档案
    if (f.entity && f.entity.includes('basedata.Dimension.')) {
      f.value = traversalDimensionTree(f.value, dimensionItems[f.key])
    }

    return f
  })
  //过滤值为空的filters
  newFilters.filter(f => f.value.length || f.filterName).forEach((f, index) => {
    const { key, value, includeDep, algorithms, filterName } = f
    const formatMapping = getFormatMapping(type, includeDep, algorithms)
    const thisConditionStr = `${index ? conditionMap[condition] : ''}`
    let thisQueryString = ''
    if (algorithms === 'between') {//日期类字段
      value.length === 2 ? thisQueryString = `${key}>= ${value[0]} && ${key}<= ${value[1]}` : ''
    } else {
      const isContainsAlgorithms = ['contains', 'notContains'].includes(algorithms)
      if (key.includes('specificationId') || key.includes('submitterId')) {
        thisQueryString = getFormatSpecificationId(key, value)
      } else {
        let { filterKey, filterQuery } =
          f.entity && f.entity.includes('basedata.Dimension.')
            ? {
              filterKey: '.id',
              filterQuery: 'inValue'
            }
            : formatMapping[key]
        if (isContainsAlgorithms) {
          // 「包含」、「不包含」算法类型
          filterKey = '.name'
          filterQuery = 'contains'
        }
        const filterValue = isContainsAlgorithms ? filterName : value
        thisQueryString = `${key}${filterKey}.${buildFunctionMap[filterQuery](filterValue)}`
      }
    }

    const inversePrefix = ['notIn', 'notContains'].includes(algorithms) ? '!' : ''
    queryString += thisConditionStr + inversePrefix + thisQueryString
  })

  return queryString
}
