export var InvoiceEntityType = {
    增值税发票: 'system_发票主体',
    火车票: 'system_火车票',
    客运汽车发票: 'system_客运汽车发票',
    航空运输电子客票行程单: 'system_航空运输电子客票行程单',
    出租车票: 'system_出租车票',
    过路费发票: 'system_过路费发票',
    定额发票: 'system_定额发票',
    其他发票: 'system_其他',
    机打发票: 'system_机打发票',
    医疗发票: 'system_医疗发票',
    消费小票: 'system_消费小票'
};
