window.APPLICATION_VERSION = APPLICATION_VERSION;
window.HUAWEI_LOGIN = HUAWEI_LOGIN;
window.IS_STANDALONE = IS_STANDALONE;
window.IS_NOEKB = IS_NOEKB;
window.IS_HSFK = IS_HSFK;
window.IS_CMBC = IS_CMBC;
window.IS_SMG = IS_SMG;
window.IS_SZJL = IS_SZJL;
window.IS_OPG = IS_OPG;
window.IS_ICBC = IS_ICBC;
window.SYNC_TEMPLATE_ORIGIN = SYNC_TEMPLATE_ORIGIN;
window.NODE_ENV = process.env.NODE_ENV;
