/**************************************************
 * Created by nanyuantingfeng on 11/07/2017 12:08.
 **************************************************/
import { Fetch, Resource } from '@ekuaibao/fetch'
import { fnFormatAttachment } from '@ekuaibao/lib/lib/lib-util'
import ParseQuery2OrderSelect from '@ekuaibao/lib/lib/ParseQuery2OrderSelect'
import parseQuery2Select from '@ekuaibao/lib/lib/parseQuery2Select'
import { app, app as api } from '@ekuaibao/whispered'
import { QuerySelect } from 'ekbc-query-builder'
import { get, forEach } from 'lodash'
import { fetchAttachment, fetchAttachmentForApplies, fetchInvoice } from '../../lib/attachment-fetch'
import {
  joinParams,
  joinParamsLite,
  joinParamsOnlyDetail,
  selectDetails,
  selectEntity,
  selectEntityLite,
  selectForm
} from '../../lib/entity.join'
import filtersFixer from '../../lib/filtersFixer'
import key from './key'
import { sortFeeTypeForm } from './util/billUtils'
import { parseSubmitParamAsFormValue, convertArray } from './util/parse'
import { reorganizeRiskWarningData } from './util/riskWarningUtil'
import { showMessage } from '@ekuaibao/show-util'

const qs = require('qs')
const specifications = new Resource('/api/form/v1/specificationVersions')
const flows = new Resource('/api/flow/v1/flows')
const flowId = new Resource('/api/v1/flow/plans/back/approver')
const calculateField = new Resource('/api/form/v2')
const plans = new Resource('/api/v1/flow/plans')
const loanInfo = new Resource('/api/v1/loan/loanInfo')
const loanInfo2 = new Resource('/api/v2/loan/loanInfo')
const repay = new Resource('/api/v1/loan/repayment')
const budgetList = new Resource('/api/v1/budget/report/flow')
const budgetDetail = new Resource('/api/v1/budget/report/flow')
const city = new Resource('/api/v1/basedata/city')
const search = new Resource('/api/flow/v1/flows/my')
const billFlows = new Resource('/api/flow/v1/flows/flowCode')
const expenseLinkList = new Resource('/api/form/v3/requisition/info/details/byIds')
const requisition = new Resource('/api/form/v2/requisition')
const rule = new Resource('/api/rpc/v1/rule')
const voucherData = new Resource('/api/preview/v2/data')
const printCount = new Resource('/api/v1/print/remind/count')
const billVersions = new Resource('/api/flow/v2/flowVersioneds')
const tpp = new Resource('/api/tpp/v2')
const carbonCopy = new Resource('/api/flow/v2/carbonCopy')
const backlogs = new Resource('/api/flow/v2/backlogs')
const fieldMapping = new Resource('/api/v1/mapping/fieldMapping')
const entity = new Resource('/api/v2/datalink/entity')
const datalinkFlow = new Resource('/api/v1/datalinkflow')
const trips = new Resource('/api/trip/v2/type/search')
const invoice = new Resource('/api/v1/invoice/validation')
const dimensionItems = new Resource('/api/v1/basedata/dimensionItems')
const VPhotoDetail = new Resource('/api/v2/vphoto/flowid')
const VPhotoVerify = new Resource('/api/v2/vphoto')
const corporationList = new Resource('/api/v2/invoice/unify/corporation/list')
const invoiceV2Action = new Resource('/api/v2/invoice')
const v2flows = new Resource('/api/flow/v2/flows')
const riskWarning = new Resource('/api/flow/v2/riskwarning/coalition/info')
const singleInvoiceRiskWarning = new Resource('/api/flow/v3/riskwarning/invoice/riskWarning') // get为获取风险，post为记录风险
const isEbot = new Resource('/api/flow/v2/flows/isEbot')
const datalink = new Resource('/api/v1/datalink')
const dataLinkV2 = new Resource('/api/v2/datalink')
const recordLink = new Resource('/api/v1/record/recordLink/search')
const dataLinkEdit = new Resource('/api/v2/datalink/entityEdit/entityForm')
const dataLinkEditItems = new Resource('/api/v2/datalink/entityEdit/getEntityFormDetail')
const supplementIdentity = new Resource('/api/v2/pay/payeeInfo/check')
const staff = new Resource('/api/v1/organization/staffs')
const apportionAction = new Resource('/api/form/v2/apportion')
const planpay = new Resource('/api/pay/v2/plan')
const feeTypesFetch = new Resource('/api/v1/form/feeTypes/list')
const feeTypes = new Resource('/api/v1/form/feeTypes')
const shareListApi = new Resource('/api/form/v2/apportion')
const recommendAction = new Resource('/api/v2/recommend')
const requisitionInfo = new Resource('/api/form/v3/requisition/info')
const departmentInfo = new Resource('/api/v1/organization/departments/tree/flat')
const departmentSearchInfo = new Resource('/api/v1/organization/departments/search/assist')

const alipayCardPackAction = new Resource('/api/v1/alipayCardPack')
const credit = new Resource('/api/credit/v2')
const updateWithNotes = new Resource('/api/withNotes/v2/batch')
const updateQuickExpense = new Resource('/api/quickexpense/v1/batch')
const checkYeego = new Resource('/api/flow/v2/check/yeeGoOrder')
const bankList = new Resource('/api/v2/pay/accounts/manual/repayment/account/list')
const flowConfigResource = new Resource('/api/v1/flow/planConfigs')
const dependenceAction = new Resource('/api/v1/record/recordLink')
import fetchMutilValue from '../../lib/mutil-staff-fetch'
import { postFlowAction } from '@ekuaibao/lib/lib/PostEventToIFrame'
const aifapiaoAction = new Resource('/api/v1/aifapiao')
const baseCity = new Resource('/api/v2/basedata/city')
const message = new Resource('/api/message/v1/messageCenterConfig')
const budgetInfo = new Resource('/api/v1/budget/info')
const requisitionCheckAuto = new Resource('/api/form/v2/requisition')
const loanChart = new Resource('/api/v2/loan/loanInfo/loadInfoSum')
const autoGenerationFeeDetail = new Resource('/api/flow/v1/autoGenerationFeeDetail')
const nullifyRule = new Resource('/api/flow/v1/nullifyRule')

const autoAssociate = new Resource('/api/v1/requisition/autoAssociate/detail')

const repaymentRecord = new Resource('/api/v1/loan/repaymentRecord')
const previewWPS = new Resource('/api/v2/preview')
const configRule = new Resource('/api/v2/loan/config')
const taxAmoutChangeLog = new Resource('/api/v1/invoice/invoiceDataChange')
const originalOrderNo = new Resource('/api/tpp/v2/travelManagement/order/all')
const setting = new Resource('/api/mall/v1/officialCard')
const payment = new Resource('/api/flow/v2/referables')

const associationGroupData = new Resource('/api/v1/association/group/list')
const layoutConfig = new Resource('/api/v1/flow/layoutConfig')
const widgetConfig = new Resource('/api/v1/flow/smallComponentConfig')
const invoiceReason = new Resource('/api/v1/invoiceRiskExplain')
const extensionCenterConfigRes = new Resource('/api/v1/flow/extensionCenterConfig')
const invoiceDisable = new Resource('/api/v2/invoiceDisable')
const invoiceMark = new Resource('/api/v2/invoiceMark')
const meta = new Resource('/api/v1/flow')
const payee = new Resource('/api/pay/v2/accounts')
const delegate = new Resource('/api/v1/organization/delegate')
const subsidyAction = new Resource('/api/v1/requisition/subsidy')
const unAuthorizedApprove = new Resource('/api/flow/v2/check/approverRepeatedByUnauthorized')

const getNewOrderMicro = new Resource('/api/tpp/v2/travelManagement/orderMicro')

const orderMicroUpgradeService = new Resource('/api/tpp/v2/travelManagement/getTravelManagementConfig')
const flowLink = new Resource('/api/flow/v1/flowLink')
const privateCar = new Resource('/api/v2/privateCar')

const currencyAction = new Resource('/api/v2/currency')
const accountList = new Resource('/api/v2/pay/accounts')
const attachmentAction = new Resource('/api/v1/attachment/attachments')
const detailFLowRelation = new Resource('/api/v1/detailFLowRelation')
const quickExpenseConfig = new Resource('/api/v1/quickExpenseConfig')

const hiddenLoading = true
export const pageSize = 100
const IMG_REG_PRE = /^(.*)\.(jpg|bmp|gif|ico|pcx|jpeg|png|raw|tga)$/i
import { afpAuthUrl } from './util/config'

// 订单微服务fileds
export function getOrderFields(params = {}) {
  return getNewOrderMicro.GET('/field/list', params)
}

// 订单微服务list
export function getOrderList(params, query) {
  return getNewOrderMicro.POST('/getOrders', params, query)
}

// 订单微服务详情
export function getOrderDetails(params) {
  return getNewOrderMicro.GET('/getOrderDetail', params)
}

// 订单微服务业务线
export function getOrderMicroTypes() {
  return getNewOrderMicro.GET('/orderType')
}

// 是否升级订单微服务
export function upgradeOrderMicro(params) {
  return orderMicroUpgradeService.GET('', params)
}

// 单据表格审批，储存预览url
export function setPrintPreviewUrl(url) {
  return {
    type: key.SET_PRINT_PREVIEW_URL,
    url
  }
}
//票据连号风险需要填写原因
export function getInvoiceReason(param) {
  return {
    type: key.GET_INVOICE_REASON,
    payload: invoiceReason.GET('/$id', param)
  }
}
export function saveInvoiceReason(params) {
  return invoiceReason.POST('/save', params)
}
export function saveInvoiceReasonBatch(params) {
  return invoiceReason.POST('/saveBatch', params)
}

// 获取发票连号风险的理由和相关 id
export function getInvoiceIdAndReason(params) {
  return invoiceReason.GET('/ticket/$id', params)
}

// 申请事项/行程规划list
export function getTripPlanningList(param) {
  return requisition.GET('/$id/travels', param)
}

// 申请事项是否有行程
export function checkTripPlanningHasTravel(param) {
  return requisition.GET('/$id/existTravels', param)
}
// 申请事项行程同步到第三方平台
export function syncTravel(param) {
  return requisition.GET('/$id/pushExtTrips', param)
}
// 订单详情退改签列表
export function getOriginalOrderNo(params) {
  return originalOrderNo.GET('/$dataLinkId', params)
}
// 获取部门信息
export function getDepartmentInfo(params = {}) {
  const { parentId = '', start = 0, count = 50 } = params
  let id = Fetch.corpId
  return departmentInfo.POST(
    `/$corpId`,
    {
      limit: {
        start,
        count
      },
      filterBy: '(active == true)'
    },
    { corpId: id, accessToken: Fetch.accessToken, parentId }
  )
}

//我的单据search接口
export function searchMyBillList(param = {}) {
  const start = get(param, 'start', 0)
  const stateFilter = get(param, 'stateFilter', '')
  let query = new QuerySelect()
    .filterBy(param.formType)
    .filterBy(param.filters)
    .filterBy(stateFilter)
    .limit(start, pageSize)
    .desc('updateTime')
  query.filterBy(filterSystemBillStr())
  let pathP = { type: 'home5' }
  if (!window.isNewHome) {
    pathP = {}
    query = query.select(
      `
         id,
         state,
         formType,
         logs,
         ownerId(name),
         form(
             submitDate,
             title,
             submitterId(id,name,avatar),
             expenseMoney,
             loanMoney,
             payMoney,
             requisitionMoney)
         `
    )
  }
  return search.POST('', query.value(), pathP)
}

/**
 * 获取打印提醒的单据的个数
 */
export function getMyPrintRemindCount() {
  return {
    type: key.SEARCH_MY_PRINTREMIND_COUNT,
    payload: printCount.GET('')
  }
}

/**
 * 获取已支付未确认的单据
 */
export function getMyPaidBill() {
  const query = new QuerySelect()
    .filterBy('state=="paid"')
    .filterBy(filterSystemBillStr())
    .limit(0, 500)
    .value()
  return {
    type: key.SEARCH_MY_BILL_PAID_LIST,
    payload: search.POST('', query)
  }
}

//获取审批流程详情
export function getFlowInfoById(param, done) {
  let { id, ...others } = param
  let query = new QuerySelect()
    .filterBy(`id=="${id}"`)
    .select(`${selectEntity()}`)
    .value()
  let params = {
    id,
    ...others,
    ...joinParams()
  }
  return {
    type: key.GET_FLOW_INFO_BY_ID,
    // payload: flows
    // .POST('/flowId/$id', query, params, null, { hiddenLoading })
    // .then(fetchAttachment)
    // .then(fetchInvoice)
    // .then(fetchMutilValue),
    payload: Promise.all([
      flows
        .POST('/flowId/$id', query, params, null, { hiddenLoading })
        .then(fetchAttachment)
        .then(fetchInvoice)
        .then(fetchMutilValue),
      singleInvoiceRiskWarning.GET('/[flowId]', { flowId: id }, null, { hiddenLoading }).catch(() => {
        return Promise.resolve({
          items: []
        })
      })
    ]).then(result => {
      const curWarningMsg = result[1]?.items?.find(v => v.flowId == id)?.invoiceMsg || []
      const res = { ...result[0], singleInvoiceRiskWarning: curWarningMsg }
      return res
    }),
    done
  }
}

// 单子少量必要数据  lite
export function getFlowInfoByIdLite(param, done) {
  let { id, ...others } = param
  let query = new QuerySelect()
    .filterBy(`id=="${id}"`)
    .select(`${selectEntityLite()}`)
    .value()
  let params = {
    id,
    ...others,
    ...joinParamsLite()
  }
  return flows.POST('/flowId/$id', query, params, null, { hiddenLoading })
}

export function getBillInfoByCode(param, done) {
  let { code } = param
  let query = new QuerySelect()
    .filterBy(`form.code=="${code}"`)
    .select(`${selectEntity()}`)
    .value()
  let params = {
    code,
    ...joinParams()
  }
  return {
    type: key.GET_BILL_INFO_BY_CODE,
    payload: billFlows
      .POST('/$code', query, params, null, { hiddenLoading })
      .then(fetchAttachment)
      .then(fetchInvoice)
      .then(fetchMutilValue),
    done
  }
}

//获取申请单详情（包括共享过来的单据）
export function getFlowInfoByIdNew(param, done) {
  let { id, ...others } = param
  let query = new QuerySelect()
    .filterBy(`id=="${id}"`)
    .select(`${selectEntity()}`)
    .value()
  let params = {
    id,
    ...others,
    ...joinParams()
  }
  let url = '/$id/searchflow'
  let request = requisition
    .POST(url, query, params)
    .then(fetchAttachment)
    .then(fetchInvoice)
    .then(fetchMutilValue)
  return {
    type: key.GET_FLOW_INFO_BY_ID,
    payload: request,
    done
  }
}

//获取可用模板
let specificationJoin = () => ({
  join: `components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping`,
  join$1: `components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform`
})

export function getSpecificationsByIds(ids) {
  return {
    type: key.GET_SPECIFICATIONS,
    payload: specifications.GET('/[ids]', { ids })
  }
}

//old actions
//获取流程
export function calPlanInstance(data, alterFlag = false) {
  let { id, ...other } = data

  let params = {
    flowId: id,
    ...other
  }
  if (alterFlag) {
    params.form.alterFlag = alterFlag
  }
  let join = {
    join: 'nodes.approverId,approverId,/v1/organization/staffs',
    join$1: 'nodes.counterSigners.signerId,signerId,/v1/organization/staffs',
    join$2: 'nodes.counterSignersCandidate.signerId,signerId,/v1/organization/staffs'
  }
  return {
    type: key.CAL_PLAN_INSTANCE,
    payload: plans.POST('/computed', params, { ...join }, null, { hiddenLoading })
  }
}

function emitOperate() {}

//保存流程
export function saveFlow(params, type = { actionType: 'save' }) {
  //消费明细按照日期排序
  const details = get(params, 'form.details')
  if (details && details.length > 1) {
    params.form.details = sortFeeTypeForm(params.form.details)
  }

  let { id } = params
  let request
  let { actionType, hiddenMsg } = type
  if (actionType === 'save') {
    request = new Promise((resolve, reject) => {
      try {
        let fn = id
          ? flows.POST('/$id', params, null, null, { hiddenLoading, hiddenMsg })
          : flows.POST('', params, null, null, { hiddenLoading, hiddenMsg })
        resolve(fn)
      } catch (e) {
        reject(e)
      }
    })
  } else {
    const paramData = { ...params }
    request = api
      .invoke('@vendor:dd:message:code')
      .then(pushMessageCode => {
        return id
          ? flows.POST('/$id', paramData, { pushMessageCode }, null, { hiddenLoading, hiddenMsg })
          : flows.POST('', paramData, { pushMessageCode }, null, { hiddenLoading, hiddenMsg })
      })
      .then(res => {
        postFlowAction({ ...params })
        return res
      })
      .catch(e => Promise.reject(e))
  }

  return {
    type: key.SAVE_FLOW,
    billType: params.formType,
    payload: request,
    mode: { name: params.name, hiddenMsg }
  }
}

//删除流程
export function delFlow(id, done) {
  const params = { id, name: 'freeflow.delete' }
  return {
    type: key.DEL_FLOW,
    payload: flows.POST('/$id', params).then(res => {
      postFlowAction({ id, name: 'freeflow.delete' })
      return res
    }),
    done
  }
}
//作废流程
export function nullifyFlow(id, done) {
  const params = { id, name: 'freeflow.nullify', params: { nullifyType: 'nullifyRule' } }
  return {
    type: key.NULLIFY_FLOW,
    payload: flows.POST('/$id', params).then(res => {
      postFlowAction({ id, name: 'freeflow.nullify' })
      return res
    }),
    done
  }
}
//检查模板是否可显示作废按钮
export function checkNulllifyRule(params) {
  return {
    type: key.GET_NULLIFY_RULE_BYSPECID,
    payload: nullifyRule.GET('/$specId/effect/$state', params)
  }
}
//检查单据提交人是否可作废
export function checkNulllifyFlowId(flowId) {
  return {
    type: key.GET_NULLIFY_RULE_BY_FLOWID,
    payload: nullifyRule.GET('/$flowId/check', flowId)
  }
}
export function retractFlow(id, done) {
  const params = { id, name: 'freeflow.retract' }
  return {
    type: key.RETRACT_FLOW,
    payload: flows
      .POST('/$id', params, null, () => void 0)
      .then(res => {
        postFlowAction({ id, name: 'freeflow.retract' })
        return res
      })
  }
}

export function commentFlow(param) {
  const params = { ...param, name: 'freeflow.comment' }
  let request = api
    .invoke('@vendor:dd:message:code')
    .then(messageCode => {
      let body = { messageCode }
      if (param.privilegeId) {
        body.privilegeId = param.privilegeId
        delete param.privilegeId
      }
      return flows.POST('/comment/$id', params, body).then(res => {
        postFlowAction({ ...param, name: 'freeflow.comment' })
        return res
      })
    })
    .catch(e => Promise.reject(e))
  return {
    type: key.COMMENT_FLOW,
    payload: request
  }
}

export function transferFlow(param) {
  const params = { ...param, name: 'freeflow.addnode' }
  let request = api
    .invoke('@vendor:dd:message:code')
    .then(messageCode => {
      return backlogs.POST('/$id', params, { messageCode }).then(res => {
        postFlowAction({ ...param, name: 'freeflow.addnode' })
        return res
      })
    })
    .catch(e => Promise.reject(e))
  return {
    type: key.TRANSFER_FLOW,
    payload: request
  }
}

//退单获取审批人
export function getWithdrawFlowId(param) {
  return {
    type: key.GET_WITHDRAW_FLOWID,
    payload: flowId.GET('/$id', param)
  }
}

export function withdrawSave(data) {
  let params = { ...data, name: 'freeflow.back' }

  return {
    type: key.WITHDRAW_SAVE,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return flows
          .POST('/$flowId', { ...params, messageCode }, {}, () => {})
          .then(res => {
            postFlowAction({ ...data, name: 'freeflow.back' })
            return res
          })
      })
      .catch(e => Promise.reject(e))
  }
}

export function confirmTotalFlows(ids, done) {
  let data = { flowIds: convertArray(ids), action: { name: 'freeflow.archive' } }
  return {
    type: key.CONFIRM_FLOWS,
    payload: flows.POST('/do/action/batch', data).then(res => {
      postFlowAction({ ids, name: 'freeflow.archive' })
      return res
    }),
    done
  }
}

//核销借款相关流程
export function getLoanPackageList(data, done) {
  let type = key['GET_LOAN_PACKAGE_LIST']

  let { state = 'REPAID', start = 0, count = 999, filters = '' } = data || {}
  state = state || 'REPAID'
  if (state === 'PAID') {
    type = key['GET_LOAN_PACKAGE_LIST_PAID']
  }
  let param = new QuerySelect()
    .limit(start, count)
    // .filterBy(`state.in("${state}")${state === 'PAID' ? '&&transfer==false' : ''}`)
    .filterBy(`state.in("${state}")`)
    .filterBy(filters)
    .value()
  if (!data?.orderBy) {
    param.orderBy = new QuerySelect().orderBy('loanDate', 'ASC').value().orderBy
  } else {
    param.orderBy = new QuerySelect().orderBy(data.orderBy[0].value, data.orderBy[0].order).value().orderBy
  }

  return {
    type: type,
    payload: loanInfo.POST('/mine/byState', param),
    done
  }
}

export function searchLoanByOwnerIdAndState(params, ownerId, flowId) {
  const { filters, start = 0, count = 9999 } = params
  let queryString = new QuerySelect().filterBy(filters).value()
  return loanInfo.POST('/byOwnerIdAndState/$ownerId/search', { ownerId, ...queryString }, { flowId, start, count })
}

export function searchLoanInfoList(params, ownerId) {
  const { filters, start = 0, count = 9999 } = params
  let queryString = new QuerySelect()
    .limit(start, count)
    .filterBy(`ownerId.contains("${ownerId}")`)
    .filterBy(filters)
    .value()
  return loanInfo.POST('/search', queryString)
}

// 有借款能力的单据增加借款详情tab
export function getLoanPackageByFlowId(param, done) {
  let flowId = param.id
  let departments = 'departments,departmentArr,/v1/organization/departments?select=id,name'
  let params = {
    flowId: param.id,
    join: `repaymentRecords.casherId,staff,/v1/organization/staffs?join=${departments}`,
    join$1: `repaymentRecords.accountId,accountCompany,/pay/v1/accounts`,
    join$2: `repaymentRecords.ownerId,ownerId,/v1/organization/staffs`,
    join$3: `repaymentRecords.shiftStaffId,shiftStaffId,/v1/organization/staffs`,
    join$4: `repaymentRecords.attachments.fileId,fileId,/v1/attachment/attachments`,
    join$5: `flowSpecificationId,flowSpecificationId,/form/v1/specificationVersions`
  }
  return {
    type: key.GET_LOAN_PACKAGE_BY_FLOWID,
    payload: loanInfo.GET('/byFlowId/$flowId', params),
    done
  }
}

export function checkLoanPackageExist(loanIds, submitterId, done) {
  const ids = loanIds.join(',')
  return {
    type: key.CHECK_LOAN_PACKAGE_EXIST,
    payload: loanInfo.GET(`/checkLoanInfo/[ids]?submitterId=${submitterId}`, { ids }),
    done
  }
}

export function getloanpackageDetailInfo(param, done) {
  let departments = 'departments,departmentArr,/v1/organization/departments?select=id,name'
  let params = {
    ...param,
    join: `repaymentRecords.casherId,staff,/v1/organization/staffs?join=${departments}`,
    join$1: `repaymentRecords.accountId,accountCompany,/pay/v1/accounts`,
    join$2: `repaymentRecords.ownerId,ownerId,/v1/organization/staffs`,
    join$3: `repaymentRecords.shiftStaffId,shiftStaffId,/v1/organization/staffs`,
    join$4: `repaymentRecords.attachments.fileId,fileId,/v1/attachment/attachments`,
    join$5: `flowSpecificationId,flowSpecificationId,/form/v1/specificationVersions`,
    join$6: `repaymentRecords.staffId,staffId,/v1/organization/staffs`,
    join$7: 'repaymentRecords.casherId,staffs,/v1/organization/staffs',
    join$8: 'repaymentRecords.shareStaffIds,shareStaffIds,/v1/organization/staffs',
    join$9: 'repaymentRecords.cancelShareStaffIds,cancelShareStaffIds,/v1/organization/staffs',
    join$10: 'repaymentRecords.payerOwnerId,payerOwnerId,/v1/organization/staffs',
    join$11: `ownerId,ownerId,/v1/organization/staffs`,
    join$12: `sharedOwnerIds,sharedOwnerIds,/v1/organization/staffs`,
    join$13: 'notConfirmOwnerIds,notConfirmOwnerIds,/v1/organization/staffs'
  }
  return {
    type: key.GET_LOAN_PACKAGE_DETAIL_BY_ID,
    payload: loanInfo.GET('/$id', params),
    done
  }
}

//获取借款单据审批中logs
export function getloanpackageDetailLogs(flowId) {
  return {
    type: key.GET_LOAN_LOGS,
    payload: loanInfo.GET('/approve/byFlowId/$flowId', {
      flowId,
      join: 'repaymentRecords.ownerId,ownerId,/v1/organization/staffs'
    })
  }
}

export function getLoanBalance(done) {
  return {
    type: key.GET_LOAN_BALANCE,
    payload: loanInfo.GET('/mine/balance'),
    done
  }
}

// 还款
export function applyRepayment(data, EBUSSCARD, done) {
  return {
    type: key.APPLY_REPAYMENT,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => repay.POST(`${EBUSSCARD ? '/applyEBussCard' : '/apply'}`, data, { messageCode }))
      .catch(e => Promise.reject(e)),
    done
  }
}

// 还款状态
export function getRepayInfo(data, done) {
  let params = {
    ...data,
    join: `casherId,casher,/v1/organization/staffs?select=name,id`
  }
  return {
    type: key.GET_REPAYMENTINFO,
    payload: repay.GET('/mine/byLoanInfoId/$loanInfoId', params),
    done
  }
}

// 根据还款申请状态查询个人还款申请
export function getRepayApplyByStatus(data) {
  return {
    type: key.GET_REPAY_APPLY_BY_STATUS,
    payload: repay.GET('/mine/byState', data)
  }
}

// 保存显示还款状态
export function hideRepayInfo(data, done) {
  return {
    type: key.HIDE_REPAYMENTINFO,
    payload: repay.PUT('/rejectRead/$loanInfoId', data),
    done
  }
}

export function getBudgetList(id, budgetOccupancyDisplay) {
  return {
    type: key.GET_BUDGET_LIST,
    payload: budgetList.GET('/$id', { id, budgetOccupancyDisplay })
  }
}

export function getBudgetDetail(data) {
  const { id, budgetId, nodeId, periodTime, start = 0, count = 10, name = '' } = data
  let params = {
    id,
    budgetId,
    nodeId,
    periodTime,
    start,
    count
  }
  return {
    type: key.GET_BUDGET_DETAIL,
    payload: budgetDetail.POST('/$id/info/$budgetId/node/$nodeId/time/$periodTime', { name }, params)
  }
}

export function getFeeTypeTemplateById(id, done) {
  let data = {
    id: id,
    ...specificationJoin()
  }
  return {
    type: key.GET_FEETYPE_TEMPLATE_BY_ID,
    payload: specifications.GET('/$id', data),
    done
  }
}

export function getCityByKeyword(keyword, done) {
  keyword = JSON.stringify(keyword).replace(/%/g, '\\\\%')
  let query = new QuerySelect()
    .filterBy(
      `name.contains(${keyword})||enName.contains(${keyword})||fullName.contains(${keyword})||nameSpell.contains(${keyword})`
    )
    .limit(0, 20)
  query = query.value()
  return {
    type: key.GET_CITY_BY_KEY_WORD,
    payload: city.POST('/search', query),
    done
  }
}

export function getFinishedBillList(param = {}, scene, dimensionItems, done) {
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  const sceneFiltersQuery = scene ? filtersFixer(scene, 'flow', dimensionItems) : ''
  let query = parseQuery2Select(param, undefined, 'flow', lang)
  query.select(`${selectEntity()}`).filterBy(sceneFiltersQuery)

  const queryString = query.value()

  return {
    type: key.SEARCH_MY_FINISHED_BILL_LIST,
    payload: search.POST('', queryString),
    done
  }
}

export function getExpenseLink(data, done) {
  let params = {
    ...data,
    join: `id,specificationVersion,/form/v1/specificationVersions`
  }
  return {
    type: key.GET_EXPENSE_LINK,
    payload: requisition.GET('/expenseLink/$id', params),
    done
  }
}

export const applyEventJoin = {
  join: `specificationId,specificationId,/form/v1/specificationVersions`,
  join$1: `changeLogs.operatorId,operatorId,/v1/organization/staffs?select=id,name,avatar,code,email,cellphone,note`,
  join$2: `changeLogs.fromStaff,fromStaff,/v1/organization/staffs?select=id,name,code,email,cellphone,note`,
  join$3: `changeLogs.toStaffs,toStaffs,/v1/organization/staffs?select=id,name,code,email,cellphone,note`,
  join$4: `ownerId,ownerId,/v1/organization/staffs?select=id,name,code,email,cellphone,note`
}

const DetailListJoin = {
  // join: `form.specificationId,specificationId,/form/v1/specificationVersions`,
  // join$1: `form.details.specificationId,specificationId,/form/v1/specificationVersions`,
  // join$2: `form.submitterId,submitterId,/v1/organization/staffs`,
  join$3: `form.details.feeTypeId,feeTypeId,/v1/form/feeTypes`,
  join$4: `form.details.feeTypeForm.attachments.fileId,fileId,/v1/attachment/attachments`
  // 临时解决 PRO-24518
  // join$5: `form.details.feeTypeForm.expenseDepartment,expenseDepartment,/v1/organization/departments`
}

export function getFlowInfo(id, done) {
  let query = new QuerySelect()
    .filterBy(`id=="${id}"`)
    .select(
      `form(${selectForm()},
                ${selectDetails()}
                ,...)
           ,...`
    )
    .value()
  let params = {
    id,
    ...joinParamsOnlyDetail()
  }
  let request = flows
    .POST('/flowId/$id', query, params)
    .then(resp => fetchAttachment(resp))
    .then(resp => fetchMutilValue(resp))
    .then(resp => resp.value)
  return {
    type: key.GET_APPLY_BILL_DETAIL,
    payload: request,
    done
  }
}

//获取申请事项中的事项明细
export function getDetailList(id) {
  const query = new QuerySelect()
    .select(`form(${selectDetails()},submitterId(...),specificationId(...),...),...`)
    .value()
  let params = {
    id,
    ...DetailListJoin
  }
  const request = requisition.POST('/$id/details', query, params).then(resp => fetchAttachmentForApplies(resp.items))
  return {
    type: key.GET_APPLY_EVENT_DETAIL_LIST,
    payload: request
  }
}

export function getApplyEvent(state, done, searchText) {
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage === 'en-US'
  // const stateStr = state === 'PROCESS' ? `state=="PROCESS"` : `state!="PROCESS"`
  let query = new QuerySelect()
  // .filterBy(stateStr)
  query = query.select(`flowId(form(submitterId(id,name,code,email,cellphone,note),...),...),...`)
  if (searchText) {
    if (lang) {
      query = query.filterBy(
        `(lower(title).contains(lower("${searchText}"))||lower(code).contains(lower("${searchText}")))`
      )
    } else {
      query = query.filterBy(`(title.contains("${searchText}")||code.contains("${searchText}"))`)
    }
  }
  query = query.value()
  return {
    type: key.GET_APPLY_EVENT,
    payload: requisition.POST('/my', query, applyEventJoin),
    state: state,
    done
  }
}

export function getApplyEventWithOrder(state, done, searchText, sortFieldName = 'submitDate', sortType = 'DESC') {
  const stateStr = state === 'PROCESS' ? `state=="PROCESS"` : `state!="PROCESS"`
  let query = new QuerySelect().filterBy(stateStr)
  query = query
    .select(`flowId(form(submitterId(id,name,code,email,cellphone,note),...),...),...`)
    .orderBy(sortFieldName, sortType)
  if (searchText) {
    query = query.filterBy(`(title.contains("${searchText}")||code.contains("${searchText}"))`)
  }
  query = query.value()
  return {
    type: key.GET_APPLY_EVENT,
    payload: requisition.POST('/myOrder', query, applyEventJoin),
    state: state,
    done
  }
}

export function getApplySource(requisitionDataSource) {
  return {
    type: key.GET_REQUISITION_DATA_SOURCE,
    payload: { requisitionDataSource }
  }
}

//获取提交人委托切换补充申请
export function getDetailSubmitterIdList({ state = 'PROCESS', id = '' }) {
  const stateStr = state === 'PROCESS' ? `state=="PROCESS"` : `state!="PROCESS"`
  let query = new QuerySelect().filterBy(stateStr)
  query = query.value()
  return {
    type: key.GET_SUBMITTERLIST,
    payload: requisition.POST('/my', query, { ...applyEventJoin, submitterId: id })
  }
}

export function getApplyEventById(id) {
  let params = {
    id: id,
    ...applyEventJoin
  }
  return {
    type: key.GET_APPLY_EVENT,
    payload: requisition.GET('/$id', params)
  }
}

export function getApplyByExpense(data = {}, done) {
  let { expenseLink, ...others } = data
  let params = {
    ...others,
    ...applyEventJoin
  }
  return {
    type: key.GET_APPLY_BY_EXPENSE,
    payload: requisition.GET('/byExpense/$id', params),
    expenseLink,
    done
  }
}

export function getApplyByExpenseWithOrder(data = {}, done) {
  let { expenseLink, ...others } = data
  let params = {
    ...others,
    ...applyEventJoin
  }
  const query = data.query || new QuerySelect().orderBy('submitDate', 'DESC').value()
  return {
    type: key.GET_APPLY_BY_EXPENSE,
    payload: requisition.POST('/byExpenseRelated/$id', query, params),
    expenseLink,
    done
  }
}

/**
 * 关闭申请 重新打开
 * @param {*} data
 * @param {*} done
 * @returns
 */
export function changeStateApplyEvent(data, done) {
  let { action, id, type, privilegeId } = data
  let path = action === 'close' ? `/$id/${action}/$type` : `/$id/${action}`
  return {
    type: key.CHANGE_APPLY_EVENT,
    payload: requisition.PUT(path, { id, type }, { privilegeId }),
    done
  }
}

// 获取个人公务卡交易记录
export function getOfficialCard(valuation, principalId) {
  const queryStringObj = principalId ? { principalId } : {}
  return {
    type: key.GET_OFFICIAL_CARD,
    payload: Fetch.GET('/api/mall/v1/officialCard/$BOC', queryStringObj),
    valuation
  }
}

export function getAutoCalculationResult(data) {
  return {
    type: key.GET_AUTO_CALCULATIO_RESULT,
    payload: rule.POST('/calculate', data)
  }
}

// 私车公用档案关系字段查询接口
export function getCalculateRecordLink(data) {
  return {
    type: key.GET_CALCULATE_RECORD_LINK,
    payload: privateCar.POST('/calculateRecordLink', data)
  }
}

//穿透查询,根据借款人ID和当前单据ID查询该借款人所有借款包列表
export function getLoanPackageListByID(params, done) {
  return {
    type: key.GET_LOAN_PACKAGE_LIST_BY_ID,
    payload: loanInfo.GET('/byOwnerIdAndLoanInfoId/$ownerId', params, null, { hiddenLoading }),
    done
  }
}

//单据催办
export function billRemind(flowId, taskId) {
  return {
    type: key.BILL_REMIND,
    payload: api.invoke('@vendor:dd:message:code').then(messageCode => {
      return flows.PUT('/remind/$id', { id: flowId }, { messageCode, taskId: taskId })
    })
  }
}

//获取凭证预览数据
export function getVoucherData(id, done) {
  return {
    type: key.GET_VOUCHER_DATA,
    payload: voucherData.POST({ flowId: id }),
    done
  }
}

//获取历史版本数据
export function getBillHistoryVersionList(flowId, privilegeId) {
  const query = new QuerySelect()
    .desc('updateTime')
    .select(
      `
         id,
         createTime,
         modifyUserId
         `
    )
    .value()
  const params = {
    id: flowId,
    privilegeId,
    join: 'modifyUserId,modifyUserId,/v1/organization/staffs?select=id,name'
  }

  return billVersions.POST('/flowId/$id', query, params)
}

//获取历史版本详情
export function getBillHistoryVersionDetail(id, privilegeId) {
  let query = new QuerySelect().select(`${selectEntity()}`).value()
  let params = {
    id,
    ...joinParams(),
    privilegeId
  }
  return billVersions
    .POST('/version/$id', query, params)
    .then(fetchAttachment)
    .then(fetchInvoice)
    .then(fetchMutilValue)
}

//获取个人可导入订单接口
export function getThirdPartyOrderData(params, qs) {
  let path = '/orders/search' //qs.orderType.indexOf('hose_mall') > -1 ? '/hosemall/order/search' :
  let query = ParseQuery2OrderSelect(params).value()
  return {
    type: key.GET_THIRD_PARTY_ORDER_DATA,
    payload: tpp.POST(path, query, qs)
  }
}

//点击刷新获取最新的订单--->后台接口名称待定
export function refreshGetNewData(params = {}) {
  return {
    type: key.REFRESH_GET_NEW_DATA,
    payload: tpp.POST('/orders/remoteOrder/refresh', params)
  }
}

// 获取申请单回退行程是否可撤回
// export function getTravelBackInfo(id) {
//   return {
//     type: key.GET_TRAVEL_BACK_INFO,
//     payload: tpp.GET('/travelManagement/travelBackInfo/$id', { id })
//   }
// }
export function getTravelBackInfo(requisitionId, submitterId) {
  return {
    type: key.GET_TRAVEL_BACK_INFO,
    payload: dataLinkV2.POST('/searchOrderIdsByRequistion', undefined, { requisitionId, submitterId })
  }
}

// 校验行程规划的往返城市是否具有住宿|用车|餐饮权限
export function checkReturnCity(params) {
  return {
    type: key.CHECK_RETURN_CITY,
    payload: tpp.POST('/travelManagement/city/checkReturnCity', params)
  }
}

// 获取行程模板列表
export function getTripsTemplate() {
  const data = {
    join:
      'specificationId,specificationId,/form/v1/specificationVersions?join=components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping&join=components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments'
  }
  const query = new QuerySelect()
    .filterBy('active==true')
    .orderBy('createTime', 'ASC')
    .value()

  return {
    type: key.GET_TRIPS_TEMPLATE,
    payload: trips.POST('', query, data)
  }
}

//获取实体模板
export function getDataLinkEditTemplate(params) {
  let joinReferenceData = `components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform`
  return {
    type: key.GET_DATA_LINK_EDIT,
    payload: dataLinkEdit.GET('/$id/$type', { ...params, join: joinReferenceData })
  }
}

//根据业务对象写入字段返回字段内容对象
export function getDataLinkEditItems(params) {
  return {
    type: key.GET_DATA_LINK_EDIT_ITEMS,
    payload: dataLinkEditItems.GET('/$flowId/$type', params)
  }
}

//获取抄送列表单据详情
export function getCarbonCopyFlowInfoById(param, done) {
  let { id, ...others } = param
  let query = new QuerySelect()
    .filterBy(`id=="${id}"`)
    .select(`${selectEntity()}`)
    .value()
  let params = {
    id,
    ...others,
    ...joinParams()
  }
  return {
    type: key.GET_CARBON_COPY_FLOW_INFO_BY_ID,
    payload: carbonCopy
      .POST('/byId', query, params)
      .then(fetchAttachment)
      .then(fetchInvoice)
      .then(fetchMutilValue),
    done
  }
}

export function getInputInvoiceData(data) {
  return {
    type: key.GET_INPUT_INVOICE_DATA,
    payload: invoiceV2Action.POST('/validation/query', data, null, null, { customHandle500Error: true })
  }
}

export function getInputInvoiceCode(code) {
  let data = { fpdm: code }
  return {
    type: key.GET_INPUT_INVOICE_CAPTCHA,
    payload: invoice.GET('/captcha/input', data)
  }
}

export function getDimensionItemsById(ids) {
  return {
    type: key.GET_DIMENSION_BY_ID,
    payload: dimensionItems.GET('/[ids]', { ids })
  }
}

//获取数据互联赋值规则

export function getDataLinkConfigRule(param) {
  return {
    type: key.GET_DATALINK_CONFIGRULE,
    payload: fieldMapping.GET('/$id', param)
  }
}

export function getDataLinkEntity(id) {
  return {
    type: key.GET_DATALINK_ENTITY,
    payload: entity.GET('/$id', { id })
  }
}

export function transferApply(id, userId, userRemark, privilegeId) {
  return {
    type: key.TRANSFER_APPLY,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return requisition.PUT('/$id/shift/$userId', { id, userId, userRemark }, { messageCode, privilegeId })
      })
      .catch(e => Promise.reject(e))
  }
}

export function shareApply(id, userIds, privilegeId) {
  return {
    type: key.SHARE_APPLY,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        if (userIds.length === 0) {
          return requisition.PUT('/$id/share/[,]', { id }, { messageCode, privilegeId })
        }
        return requisition.PUT('/$id/share/[userIds]', { id, userIds }, { messageCode, privilegeId })
      })
      .catch(e => Promise.reject(e))
  }
}

export function getDatalinkFlow(param = {}, scene, dimensionItems) {
  const sceneFiltersQuery = scene ? filtersFixer(scene, 'backlog', dimensionItems) : ''
  const query = parseQuery2Select(param, undefined, 'backlog')
  query.select(`${selectEntity('flowId')}`).filterBy(sceneFiltersQuery)

  const queryString = query.value()
  const { dataLinkId } = param
  return {
    type: key.GET_DATALINK_CONFIGRULE,
    payload: datalinkFlow.POST('/search', queryString, { dataLinkId })
  }
}

export function updateDataLinkInstance(dataLinkObj) {
  return {
    type: key.UPDATE_DATALINK_INSTANCE,
    dataLinkObj
  }
}

export function deleteDetailDataLinkInstance(param) {
  return {
    type: key.UPDATE_DETAIL_DATALINK_INSTANCE,
    ...param
  }
}

export function getVphotoDetail(flowId) {
  return VPhotoDetail.GET('/$flowId', { flowId })
}

export function verifyVPhotoOrders(value) {
  return VPhotoVerify.GET('/$value', { value })
}

export function getInvoiceCorporation(ids) {
  return {
    type: key.GET_INVOICE_CORPORATION,
    payload: ids ? corporationList.GET('/[ids]', { ids }) : corporationList.GET('')
  }
}

export function saveInvoiceWait(params) {
  return {
    type: key.SAVE_INVOICE_WAIT,
    payload: invoiceV2Action.PUT('/wait/$flowId', params)
  }
}

export function deleteInvoiceInfo(params) {
  return {
    type: key.DELETE_INVOICE_WAIT,
    payload: invoiceV2Action.DELETE('/wait/$flowId', params)
  }
}

export function getInvoiceDetailsById(masterId) {
  return {
    type: key.GET_INVOICE_DETAILALL_BYID,
    payload: invoiceV2Action.GET('/validation/$masterId', { masterId })
  }
}

export function getInvoiceStateById(masterId, staffId) {
  return {
    type: key.GET_INVOICE_DETAILALL_BYID,
    payload: invoiceV2Action.GET('/validation/visibility', { masterId, staffId })
  }
}

export function getFlowByCode(params) {
  const { code, hiddenMsg } = params
  return {
    type: key.GET_FLOW_INFO_BY_CODE,
    payload: v2flows.POST('/code/$code', { code: encodeURIComponent(code) }, null, null, { hiddenMsg })
  }
}

export function generateConsumeDetails(checkedInvoiceItemsMap) {
  return {
    type: key.GENERATE_CONSUME_DETAILS,
    payload: checkedInvoiceItemsMap
  }
}

export function isShowEbotInfo(params) {
  const { flowId, form } = params
  return {
    type: key.ISSHOW_EBOT,
    payload: isEbot.POST('/shift/$flowId', form, { flowId })
  }
}

export function getOCRList(params) {
  return {
    type: key.GET_OCR_LIST,
    payload: invoiceV2Action.POST('/ocr/multiple_items', params)
  }
}

// TODO
export function saveTempRepayInfo() {}

export function getCurrentFlowState(flowId) {
  return {
    type: key.GET_CURRENT_FLOW_STATE,
    payload: v2flows.GET('/current/state/$flowId', { flowId }, null, { hiddenLoading })
  }
}

export function getBackLogByFlowId(flowId) {
  return {
    type: key.GET_CURRENT_BACKLOG_BY_ID,
    payload: backlogs.GET('/current/$flowId', { flowId }, null, { hiddenLoading })
  }
}

export function getFlowRiskWarning({ id, level, types, privilegeId = '', reasonModify = false }) {
  const params = {
    flowId: id,
    privilegeId
    // types,
    // level
  }
  if (types) {
    params.types = types
  }
  if (level) {
    params.level = level
  }
  return {
    type: key.GET_FLOW_RISK_WARNING,
    // payload: riskWarning.GET('/$flowId', params, null, { hiddenLoading }),
    payload: Promise.all([
      riskWarning.GET('/$flowId', params, null, { hiddenLoading }),
      singleInvoiceRiskWarning
        .GET('/[flowId]', level ? { flowId: id, ...params } : { flowId: id }, null, { hiddenLoading })
        .catch(() => {
          return Promise.resolve({
            items: []
          })
        })
    ]).then(result => {
      //金龙借款未核销提示需前端重新编辑
      if (window.IS_SZJL) {
        const loanRiskWarning =
          result[0]?.value?.riskWarning?.filter(v => {
            return v.type === 'loan'
          }) || []
        loanRiskWarning.map(loan => {
          loan?.messages?.map((text, index) => {
            // @i18n-ignore
            if (text === '有可核销借款但未核销' || text === 'Have a write-off of the loan but have not written off') {
              loan.messages[index] = i18n.get('有可核销的借款或预付款尚未核销')
            }
          })
        })
      }
      const currentFlow = result[1]?.items?.find(v => v.flowId == id) || {}
      const curWarningMsg = currentFlow?.invoiceMsg || []
      // 重组新的发票msg数据,取自一个新的实时查询接口  ---- start
      const riskWarningData = reorganizeRiskWarningData(curWarningMsg) || []
      if (currentFlow.versionFlag > 1) {
        let otherRiskWarning = []
        // 票据连号需要填写原因，输出判断条件
        if (reasonModify) {
          result[0]?.value?.riskWarning?.forEach(v => {
            if (
              (v.type === 'invoice' && (v?.controlName === '票据连号：' || v?.controlName === '存在连号票据：')) ||
              (v.type !== 'invoice' && v?.controlName === '')
            ) {
              otherRiskWarning.push(v)
            }
          })
        } else {
          otherRiskWarning = result[0]?.value?.riskWarning?.filter(v => {
            return v.type !== 'invoice'
          })
        }
        const newRiskWarning = [...otherRiskWarning, ...riskWarningData]
        result[0].value.riskWarning = newRiskWarning
      }
      // 重组新的发票msg数据,取自一个新的实时查询接口  ---- end
      // 兼容历史单据，票据连号原因写进当前messages展示
      const noRiskWarningReason = currentFlow?.invoiceMsg?.every(item => !item.riskWarningReason?.length)
      if (currentFlow && noRiskWarningReason && currentFlow?.invoiceRiskExplainContent?.length) {
        result[0]?.value?.riskWarning?.forEach(v => {
          if (v?.controlName === '票据连号：' || v?.controlName === '存在连号票据：') {
            let item = i18n.get('连号原因：') + currentFlow?.invoiceRiskExplainContent
            v.messages.push(item)
          }
        })
      }
      const res = {
        ...result[0],
        singleInvoiceRiskWarning: curWarningMsg,
        invoiceRiskExplainContent: currentFlow.invoiceRiskExplainContent
      }
      return res
    }) // 单张发票风险提示
  }
}

export function saveOCRcardInfo(params) {
  const { entityId, invoiceId, value } = params
  let param = { entityId, invoiceId }
  return {
    type: key.SAVE_OCR_CARD_INFO,
    payload: invoiceV2Action.PUT('/ocr/$invoiceId/$entityId', value, param)
  }
}

export function getInvoiceImgById(ids) {
  const params = ids && Array.isArray(ids) ? { ids } : { ids: [ids] }
  return {
    type: key.GET_INVOICE_IMG_BYID,
    payload: invoiceV2Action.POST('/images', params)
  }
}

export function getInvoiceInfoByIds(ids) {
  return {
    type: key.GET_INVOICE_INFO_BY_IDS,
    payload: invoiceV2Action.GET('/validation/[ids]', { ids })
  }
}

export function getDataLinkList(params) {
  const { type } = params
  const query = new QuerySelect().filterBy(i18n.get(`name != "行程"`)).value()
  return {
    type: key.GET_DATALINK,
    type2: type,
    payload: datalink.POST('/searchDataLinkEntityByCategory/$type', query, { type })
  }
}

export function getCYRAndFZRList(data) {
  const { start, count, entityId, filterBy, type } = data
  let query = new QuerySelect().select(`entityId(...),ownerId(...),...`)
  if (count) {
    query.limit(start, count)
  }
  if (filterBy) {
    query.filterBy(filterBy)
  }
  query = query.value()
  const join = {
    join: `platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments`
  }
  return {
    type: key.GET_CYR_FZR_LIST,
    payload: datalink.POST('/searchDataLinkByCategory/$type', query, {
      type,
      entityId,
      ...join
    })
  }
}

export function getLedgerAndPlanByEntityId(params) {
  return {
    type: key.GET_LEDGER_AND_PLAN_BY_ID,
    payload: datalink.GET('/$entityId/other/info', params)
  }
}

function parseParams(params, key) {
  const { ledgerConfigId, dataLinkId, statisticsSource, start, count, sort = true, childrenId } = params
  let joinParam = {}
  let query = new QuerySelect()
    .filterBy('active==true')
    .limit(start, count)
    .orderBy(key || 'updateTime', sort)
  if (statisticsSource === 'MASTER') {
    query = query
      .select(`flowId(form(submitterId(id,name,code,email,cellphone,note),...),...),...`)
      .filterBy('(detailId=="null"||detailId=="")')
  } else {
    query = query.filterBy('detailId!="null"')
    joinParam.join = `feeTypeId,feeTypeId,/v1/form/feeTypes`
  }
  return [query.value(), { childrenId, ledgerConfigId, dataLinkId }, joinParam]
}
export async function getLedgerRelationList(params) {
  const [query, args, joinParam] = parseParams(params, 'createTime')
  try {
    const result = await datalink.POST('/byLedgerConfigId/$ledgerConfigId/byDataLinkId/$dataLinkId', query, {
      ...args,
      ...joinParam
    })
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export function getLedgerRelationTable(params) {
  const [query, args] = params
  try {
    return datalink.POST('/byLedgerConfigId/$ledgerConfigId/byDataLinkId/$dataLinkId', query, {
      ...args
    })
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getLedgerRelationListAmount(params) {
  const [query, args] = parseParams(params)
  try {
    const result = await datalink.POST('/byLedgerConfigId/$ledgerConfigId/byDataLinkId/$dataLinkId/total', query, args)
    return result.items
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getLedgerRelationEntityList(params) {
  const { ledgerConfigId, dataLinkId, start, count, type = 'LIST', dataLinkQuery } = params
  const query = dataLinkQuery ? dataLinkQuery : new QuerySelect().limit(start, count).value()
  const relationQuery = new QuerySelect().filterBy('active==true').filterBy('refDataLinkId!="null"')
  try {
    const result = await dataLinkV2.POST(
      '/byLedgerConfigId/$ledgerConfigId/byDataLinkId/$dataLinkId/bySourceDataLink',
      { relationQuery: relationQuery.value(), dataLinkQuery: query, type },
      {
        ledgerConfigId,
        dataLinkId
      }
    )
    return result.items
  } catch (e) {
    return e
  }
}

export function getInvoiceIsDeductible(params) {
  return {
    type: key.GET_INVOICE_ISDEDUCTIBLE,
    payload: invoiceV2Action.PUT('/isDeductible/$id', params)
  }
}

export function saveMultiplePayeed(multiplePayeesMode) {
  return {
    type: key.SAVE_MULTIPLE_PAYEED,
    payload: multiplePayeesMode
  }
}

export function getDatalinkTemplateById(params) {
  return {
    type: key.GET_DATALLINK_TEMPLATE_BYID,
    payload: dataLinkV2.GET(`/detail/$entityId/$type`, {
      ...params,
      join: 'data.dataLink.entityId,entity,/v2/datalink/entity'
    })
  }
}

export function getDatalinkTemplateByIds(params) {
  return {
    type: key.GET_DATALLINK_TEMPLATE_BYIDS,
    payload: dataLinkV2.POST(
      `/detail/$type`,
      { type: 'CARD', ...params },
      { join: 'data.dataLink.entityId,entity,/v2/datalink/entity' }
    )
  }
}

function getStaffWithPageSize(data = {}) {
  let items = []
  let pages = 1
  let pageSize = 2999
  let params = {
    start: 0,
    count: pageSize,
    ...data
  }
  let getStaffs = () => {
    return recordLink.POST('', params).then(resp => {
      items = items.concat(resp.items)
      if (resp.count !== items.length) {
        params.start = pages * pageSize
        pages++
        return getStaffs()
      }
      return { items }
    })
  }
  return getStaffs()
}

export function getRecordLink(params) {
  // if (!params.dependenceId) {
  //   return {
  //     type: key.GET_RECORDLINK,
  //     payload: Promise.resolve({ matchDefaultValue: false, leafItems: undefined, items: [] })
  //   }
  // }
  return {
    type: key.GET_RECORDLINK,
    payload: params.entity === 'organization.Staff' ? getStaffWithPageSize(params) : recordLink.POST('', params)
  }
}

export function transferLoanPackage(param) {
  let departments = 'departments,departmentArr,/v1/organization/departments?select=id,name'
  const { privilegeId, ...others } = param
  let params = {
    ...others,
    join: `repaymentRecords.casherId,staff,/v1/organization/staffs?join=${departments}`,
    join$1: `repaymentRecords.accountId,accountCompany,/pay/v1/accounts`,
    join$2: `repaymentRecords.ownerId,ownerId,/v1/organization/staffs`,
    join$3: `repaymentRecords.fromStaffId,fromStaffId,/v1/organization/staffs`
  }
  return {
    type: key.PUT_TRANSFER_LOAN_PACKAGE,
    payload: loanInfo2.PUT(`/$id/shift/$staffId`, { ...params }, { privilegeId })
  }
}

export function getShowBudgetTab(params) {
  return {
    type: key.GET_SHOW_BUDGET_TAB,
    payload: budgetList.GET('/$id/status', params)
  }
}

export function getCalculateField(specificationId, submitterId, billSpecificationId, others = {}) {
  // presetTemplateId有值，则是快速报销的自动计算，使用presetTemplateId，非快速报销使用billSpecificationId
  const { legalEntityId, presetTemplateId } = others || {}
  return {
    type: key.GET_FEETYPE_CALCULATE_FIELDS,
    payload: calculateField.GET('/calculateFields/staffId/$submitterId/specId/$specificationId', {
      specificationId,
      submitterId,
      billSpecificationId: presetTemplateId || billSpecificationId,
      legalEntityId
    })
  }
}

export function checkSupplementIdentity(params) {
  return supplementIdentity.POST('', params)
}

export function getFeetypeInvoiceImportMode(params) {
  return feeTypes.POST('/importMode/visibility', params)
}

export function getAssignmentRuleById(entityIds) {
  return {
    type: key.GET_ASSIGNMENT_RULE_BY_ID,
    payload: fieldMapping.GET(`/getListByEntityIds/$[entityIds]`, { entityIds: entityIds })
  }
}

export function getStaffById(id) {
  const params = {
    id,
    join: 'defaultDepartment,defaultDepartment,/v1/organization/departments'
  }
  return {
    type: key.GET_STAFF_BY_ID,
    payload: staff.GET(`/$id`, params)
  }
}

export function exportApportionExcel(params) {
  return {
    type: key.EXPORT_APPORTION,
    payload: apportionAction.POST('/excel/export', params)
  }
}

export function exportPlanPayExcel(params) {
  return {
    type: key.EXPORT_PLANPAY,
    payload: planpay.POST('/export')
  }
}

export function getFeeTypeListByFormData(param) {
  return {
    type: key.GET_FEETYPE_LIST_BY_FORMDATA,
    payload: feeTypesFetch.POST(param)
  }
}

export function getShareListApi(params) {
  return {
    type: key.GET_SHARE_LIST_API,
    payload: shareListApi.POST('/getApportionByApi', params)
  }
}

export function getFeeDetailEditable(params) {
  return new Promise(async resolve => {
    try {
      const result = await v2flows.GET('/modify/$flowId/check/feeType/button/disabled', params)
      resolve(result)
    } catch (e) {
      resolve({ value: false })
    }
  })
}

/**
 * @params {bill/details/trip:errorName[]}
 */
export function setValidateError(param) {
  return {
    type: key.VALIDATE_ERROR,
    payload: param
  }
}

export function getFeeTypeRecommed(param) {
  return recommendAction.POST('/feeType', param).catch(err => {
    return { item: [] }
  })
}

export function saveFeeTypeVisibleList(param) {
  return {
    type: key.SAVE_FEETYPE_VISIBLE_LIST,
    feeTypeVisibleObjForModify: param
  }
}

export function modifyFeeDetailTaxInfo(params) {
  return {
    type: key.MODIFY_FEEDETAIL_TAXINFO,
    payload: v2flows.POST('/invoice/tax', params)
  }
}

export function getFieldsGroupData(params) {
  return {
    type: key.GET_FIELDS_GROUP_DATA,
    payload: v2flows.GET('/dimension', params)
  }
}

export function saveFieldsGroupData(params) {
  return {
    type: key.SAVE_FIELDS_GROUP_DATA,
    payload: v2flows.POST('/dimension', params)
  }
}

export function getRelatedDetailList(params) {
  const { ids } = params
  if (window.IS_SZJL) {
    //临时给金龙发版，后端金龙逻辑与这个有冲突
    const join = {
      join:
        'dataList.feeTypeId,feeTypeId,/v1/form/feeTypes?join=expenseSpecificationId,expenseSpecificationId,/form/v1/specificationVersions',
      join$1: `specificationId,specificationId,/form/v1/specificationVersions`,
      join$2: `ownerId,ownerId,/v1/organization/staffs`
    }
    return requisitionInfo.POST('/details', { ids }, { ...join })
  }
  const join = {
    join:
      'dataList.feeTypeId,feeTypeId,/v1/form/feeTypes?join=expenseSpecificationId,expenseSpecificationId,/form/v1/specificationVersions',
    join$1: `specificationId,specificationId,/form/v1/specificationVersions`,
    join$2: `ownerId,ownerId,/v1/organization/staffs`,
    join$3: `dataList.expenseDepartment,expenseDepartment,/v1/organization/departments?select=id,name,code`,
    join$4: 'dataList.invoiceForm.invoiceCorporationId,invoiceCorporation,/v2/invoice/unify/corporation/list',
    join$5: 'dataList.invoiceForm.attachments.fileId,fileId,/v1/attachment/attachments',
    join$6: 'dataList.invoiceForm.invoices.invoiceId,master,/v2/invoice',
    join$7: 'dataList.invoiceForm.invoices.itemIds,details,/v2/invoice/item'
  }
  return requisitionInfo.POST('/details', { ids }, { ...join })
}

export function getMetaByIds(ids) {
  return meta.POST('/meta', ids)
}

//获取单据可用的管理规则
export function getActiveCreditRules(params) {
  return {
    type: key.GET_ACTIVE_CREDIT_RULES,
    payload: credit.GET('/rules/byFlowId/$flowId', params)
  }
}

//获取单据上的批注
export function getBillNotes(params) {
  return {
    type: key.GET_BILL_NOTES,
    payload: credit.GET('/notes/byFlowId/$flowId', params)
  }
}

//在单据上添加批注
export function addBillNote(params) {
  return {
    type: key.POST_ADD_NOTE,
    payload: credit.POST('/notes', params),
    flowId: params.dataId
  }
}

//在单据历史版本中查询批注快照
export function searchNoteFormHistory(params) {
  return {
    type: key.SEARCH_NOTE_FORM_HISTORY,
    payload: credit.GET('/notes/byFlowVersionedId/$id', params)
  }
}

//更新用户浏览状态，用来判断是否正在浏览单据历史
export function changeStatusOfShowBillNotesInHistory(showBillNotesInHistory) {
  return {
    type: key.CHANGE_STATUS_OF_SHOWBILLNOTESINHISTORY,
    showBillNotesInHistory
  }
}

//获取个人信用积分
export function getMyCreditPoint(submitterId) {
  return {
    type: key.GET_MY_CREDIT_POINT,
    submitterId,
    payload: credit.GET('/stafflog/point/$submitterId', { submitterId })
  }
}

export function getPreviewWPS(params) {
  return previewWPS.GET('/', params).catch(err => showMessage.error(i18n.get(err.msg || err.errorMessage)))
}

let lock = true
export async function handleFilePreview(params) {
  const { value, line, pdfShowCurrentWindow = true } = params

  if (line?.key?.startsWith('DP:')) {
    const extNode = JSON.parse(line.key.split('DP:').pop())
    await api.invokeService('@common:ding:pan:space:authorize:download', { fileIds: [extNode.fileId] })
    const dingCorpId = get(api.getState('@common').userinfo, 'staff.corporationId.sourceId')
    const data = { ...extNode, corpId: dingCorpId }
    return api.invoke('@vender:dingtalk:dp:preview', data)
  }

  const fileList = fnFormatAttachment(value)
  let KA_PREVIEW_SHOW_MODAL = await api.getState('@common.powers').KA_PREVIEW_PDF_SHOW_MODAL
  if (pdfShowCurrentWindow && KA_PREVIEW_SHOW_MODAL) {
    let currentIdx = 0
    const items = fileList
      .filter(item => item)
      .map((item, index) => {
        if ((line.id || line.fileId) === item.fileId) {
          currentIdx = index
        }
        return {
          key: item.key,
          fileId: item.fileId,
          title: (item.fileName || '').split('.')[0],
          source: 'UPLOAD',
          hasPDF: true,
          image: {
            url: item.url,
            fileName: item.key
          },
          imageSrc: item.url
        }
      })
    await api.open('@bills:IframePreviewerModal', {
      modalTitle: '附件预览',
      items,
      currentIdx
    })
    return false
  }
  const imageList = fileList.filter(v => IMG_REG_PRE.test(v.fileName))
  if (!IMG_REG_PRE.test(line.fileName)) {
    //本地化项目未配置wps服务时,走原有预览逻辑
    if (window.PLATFORMINFO?.oldPreviewService) {
      api.emit('@vendor:preview', line.url, line.fileName)
      return
    }
    if (lock) {
      lock = false
      const params = { corpId: Fetch.ekbCorpId, key: line.key }
      const result = await getPreviewWPS(params)
      lock = true
      if (result?.value?.url) {
        api.sdk.openLink(result.value?.url)
      } else {
        return showMessage.info(i18n.get('附件预览出错'))
      }
    } else {
      return showMessage.info(i18n.get('数据加载中,请稍候...'))
    }
  } else {
    api.emit('@vendor:preview:images', imageList, line)
  }
}

export function handleFileDownload(line) {
  const url = get(line, 'url', '')
  const fileName = get(line, 'fileName', '')
  const callBack = needDownloadFileName => {
    if (needDownloadFileName) {
      api.emit('@vendor:download', url, fileName, true)
    } else {
      api.emit('@vendor:download', url, fileName)
    }
  }
  if (window.__PLANTFORM__ === 'DING_TALK') {
    const needDownloadFileName = api.getState()['@bills']?.needDownloadFileName
    if (needDownloadFileName !== undefined) {
      callBack(needDownloadFileName)
      return
    } else {
      api
        .invokeService('@bills:get:if:download:fileName')
        .then(res => {
          callBack(res?.value)
        })
        .catch(err => {
          callBack(false)
        })
    }
    return
  }
  api.emit('@vendor:download', url, fileName)
}

export function getAliPayInvoiceList(param) {
  return {
    type: key.GET_ALIPAY_INVOICE_LIST,
    payload: alipayCardPackAction.GET('/invoice/list', { ...param })
  }
}

export function getAliPayInvoiceDetail(param) {
  const { invoiceData, taxNo } = param
  return {
    type: key.GET_ALIPAY_INVOICE_DETAIL,
    payload: alipayCardPackAction.POST('/invoice/import', invoiceData, { taxNo })
  }
}

export function getAliPayCardAuthState() {
  return alipayCardPackAction.GET('/authorized')
}

export function getAifapiaoCardAuthState() {
  return aifapiaoAction.GET('/getAuthByStaff')
}
export function saveAifapiaoAuthorize(params) {
  return {
    type: key.SAVE_AIFAPIAO_AUTHORIZE,
    payload: aifapiaoAction.POST('/saveUserAuthorize', params)
  }
}
export function getAliPayCardUrl() {
  return alipayCardPackAction.GET('/authorizeUrl')
}

export function feeDetailMoveToRecordExpends(params, qs) {
  return updateWithNotes.PUT('/update/withNotes', params, qs)
}

export function feeDetailMoveToQuickExpends(params) {
  return updateQuickExpense.PUT('/update/detail', params)
}

// 转交、回退、变更
export function flowDoAction(params) {
  return flows.POST('/do/action/batch', params)
}

export function checkApplyClosed(id) {
  return requisitionInfo.GET('/checkClosed/$id', { id })
}

const LinkListJoin = {
  join: `details.form.details.feeTypeId,feeTypeId,/v1/form/feeTypes`,
  join$1: `details.form.details.feeTypeForm.attachments.fileId,fileId,/v1/attachment/attachments`
}

// 关联多申请事项时获取申请事项明细
export function getExpenseLinkList(ids) {
  const query = new QuerySelect().select(`${selectEntity()}`).value()
  return expenseLinkList.POST('', { query, ids }, LinkListJoin)
}

export function getFlowByIds(ids) {
  const params = {
    ids,
    join: `form.specificationId,specificationId,/form/v1/specificationVersions`,
    join$1: `form.submitterId,submitterId,/v1/organization/staffs`
  }
  return flows.GET('/[ids]', params)
}

export function isOpenTemplateModal(data) {
  return {
    type: key.IS_OPEN_TEMPLATE_MODAL,
    payload: data
  }
}

export function getRetryCheckerInvoice(invoiceId) {
  return {
    type: key.GET_RETRY_CHECKER_INVOICE,
    payload: invoiceV2Action.GET('/checker/retry/$invoiceId', { invoiceId })
  }
}

export function checkYegoOrder(params) {
  return checkYeego.POST('', params)
}

export async function getChildrenPrimary(params) {
  try {
    const result = await datalink.GET('/childrenPrimary/$dataLinkId', params)
    return result.items
  } catch (e) {
    return Promise.reject(e)
  }
}

export function getBankList(params) {
  return bankList.GET('', params)
}

export function delayLoanInfo(data) {
  const { privilegeId, ...others } = data
  return loanInfo2.PUT('/$loanInfoId/delay', others, { privilegeId })
}
// 修改还款包金额
export function updateRepaymentMoney(data) {
  const { loanInfoId, revise, privilegeId } = data
  return loanInfo2.PUT('/$loanInfoId/revise', { loanInfoId, ...revise }, { privilegeId })
}

export function shareLoan(params) {
  const { loanInfoId, staffList, userRemark } = params
  return loanInfo.POST(`/share/$loanInfoId/[${staffList}]`, { loanInfoId, userRemark })
}

export function getLoanListByBill(params) {
  !params.orderBy && (params.orderBy = new QuerySelect().orderBy('loanDate', 'ASC').value().orderBy)
  return loanInfo.POST('/byWriteOffLoans', { ...params })
}

export function saveWrittenOffSummary(writtenOffSummaryForMutiCurrency) {
  return {
    type: key.SAVE_WRITTENOFF_SUMMARY,
    writtenOffSummaryForMutiCurrency
  }
}

export function clearWrittenOffSummary() {
  return { type: key.CLEAR_WRITTENOFF_SUMMARY }
}

export function activateFlow(param) {
  return {
    type: key.ACTIVATE_FLOW,
    payload: flows.POST('/activate/$id', param)
  }
}

export function checkBillBudgetOccupy(params) {
  return budgetList.POST('/$id/checkFreeze', params)
}

export function updateEditApproveAmount(params) {
  return flows.POST('/editApproveAmount', params)
}

export function updateDimentionCurrency(dimentionCurrencyInfo) {
  return {
    type: key.UPDATE_DIMENTION_CURRECY,
    dimentionCurrencyInfo
  }
}

export function getFlowPlanConfigByConfigId(param) {
  return flowConfigResource.GET('/$id', param)
}

// 用户订单拉取接口
export function GetPullTravelOrderByStaff(params) {
  return {
    type: key.GET_PULLTRAVELORDERBYSTAFF,
    payload: tpp.PUT('/travelManagement/order/pullTravelOrderByStaff', params)
  }
}

export function getDependenceDatalinkList(params) {
  return dependenceAction.POST('/searchForDataLink', params)
}

// 发票风险记录接口
export function recordInvoiceRiskWarning(id, params) {
  return {
    type: key.GET_FLOW_RISK_WARNING,
    payload: singleInvoiceRiskWarning.POST('/[flowId]', { flowId: id, ...params }, null, { hiddenLoading })
  }
}
// 发票风险记录接口（只请求）
export function recordInvoiceRiskWarningFetchOnly(id, params) {
  return singleInvoiceRiskWarning.POST('/[flowId]', { flowId: id, ...params }, null, { hiddenLoading })
}

//禁用发票
export function submitDisableInvoice(params) {
  return {
    type: key.POST_DISABLE_INVOICE,
    payload: invoiceDisable.POST('/disable', { ...params })
  }
}
//查询发票禁用状态
export function getDisableStateByInvoiceID(params) {
  return {
    type: key.GET_DISABLE_STATE_BY_INVOICEID,
    payload: invoiceDisable.POST('/byInvoiceIds', params)
  }
}

//禁用发票
export function submitMarkInvoice(params) {
  return {
    type: key.POST_MARK_INVOICE,
    payload: invoiceMark.POST('/disable', { ...params })
  }
}

//查询发票标记状态
export function getMarkStateByInvoiceID(params) {
  return invoiceMark.POST('/byInvoiceIds', params)
}

export function getInvoiceComparedData(params) {
  return invoiceV2Action.POST('/checker/getComparedData', params)
}

export async function getAiFaPiaoSign() {
  const userInfo = (await api.getState('@common.userinfo').staff) || {}
  const data = JSON.stringify({ ekbUserId: userInfo?.id })
  const params = { params: data }
  return fetch(`/api/v1/aifapiao/getSign?corpId=${Fetch.ekbCorpId}`, {
    body: qs.stringify(params),
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    credentials: 'include'
  }).then(response => response.json())
}

export async function getAifapiaoPublicUrl() {
  // const publicUrl = 'http://localhost:8866/#/'
  const callBackUrl = window.location.origin
  const sign = (await getAiFaPiaoSign()) || ''
  const userInfo = (await api.getState('@common.userinfo').staff) || {}
  const ekbUserId = userInfo?.id
  const corporationId = userInfo?.corporation?.id
  return `${afpAuthUrl}#/pages/index/index?sign=${sign?.value ??
    ''}&ekbUserId=${ekbUserId}&corporationId=${corporationId}&callBackUrl=${callBackUrl}`
}

export function getOperation(param) {
  return message.POST('/getOperation', param)
}

export function getPrice(param) {
  return message.POST('/getPrice', param)
}

export function importInvoiceFromAiFaPiao(params) {
  return {
    type: key.HANDLE_INVOICE_IMPORT,
    payload: aifapiaoAction.POST('/invoice/import', params)
  }
}

export function filterSystemBillStr() {
  const userInfo = app.getState('@common.userinfo')
  const corpId = userInfo?.staff?.corporationId
  return `INSTR(form.specificationId,"${corpId}:system:对账单")==0`
}

/**
 *
 * @param params : { fileName: "", fileUrl: "", invoiceId: "" }
 */
export function uploadMetaileFile(params) {
  return invoiceV2Action.POST('/ocr/uploadOriginFile', params)
}

/**
 * @description 是否可以重新打开
 * @param {*} id
 * @returns
 */
export function checkOpenAgain(id) {
  return requisitionInfo.GET('/checkOpenAgain/$id', { id })
}

export function getCityCodeByKey(ids) {
  return baseCity.POST('/getCurrentAndParent/[ids]', { ids })
}
// 获取预算分解维度
export function getBudgetDimension() {
  return budgetInfo.GET('/custom/dimension')
}

export function updateInvocieTax(params) {
  return invoiceV2Action.POST('/priceAndTaxSeparatedConfig/getDeduction', params)
}

export function getLoanChart() {
  return loanChart.GET('')
}
export function getAutoAssociateDetail(param) {
  return autoAssociate.GET('/$specificationId/$requisitionId', param)
}

export function postAutoAssociateDetailcr(param) {
  return autoAssociate.POST('cr/$specificationId/$requisitionId', param)
}

/**
 * @description
 */
export function withdraw(id, name, rejectTo, nextId) {
  return {
    type: key.FLOW_WITHDRAW,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return backlogs.POST('/withdraw/$id', { id, name, rejectTo, nextId }, { messageCode })
      })
      .catch(e => Promise.reject(e))
  }
}

/*
 * @description  确认/拒绝 接收借款单
 * @param {*} params
 * @returns
 */
export function shiftConfirmLoan(params) {
  // privilegeId 敏感id 咱也不知道是啥 必传 可为空
  const { id, mark, privilegeId = '' } = params
  return loanInfo2.PUT('/shiftConfirm/$id', { id }, { mark, privilegeId })
}

/**
 * 共享借款的确认/拒绝
 * @param param : {id: 借款包ID， type: CONFIRM_SHARE/REJECT_SHARE}
 * @returns {Promise<any>}
 */
export async function shareLoanConfirm(params) {
  const result = await loanInfo.POST('/shareConfirm/$id/$type', params)
  if (!!result?.errorCode) {
    return Promise.reject({ message: result.errorMessage || result.errorDetails })
  }
  return result
}

/**
 * 判断借款是否被取消共享
 * @param params : {id: 借款id}
 */
export function shareIsCancel(params) {
  return loanInfo.POST('/isCancel/$id', params)
}

/**
 * @description 是否有权限还款与共享
 * @returns
 */
export function hasPermissionLoan() {
  return loanInfo2.GET('/hasPermissionLoan')
}
/**
 * @description 重新校验
 * @param {*} invoiceId
 * @returns
 */
export function getCheckInvoiceAgain(params) {
  return invoiceV2Action.POST('/ocr/fixInvoice', params)
}
/**
 * @description 校验发票种类
 * @param {*} invoiceId
 * @returns
 */
export function getInvoiceInputType(params) {
  return invoiceV2Action.POST('/validation/getInvoiceInputType', params)
}

/**
 * 修复借款包
 * @param {*} ids
 * @returns
 */
export function fixRepaymentRecord(ids) {
  return repaymentRecord.POST('/fixRepaymentRecord', ids)
}

// 获取配置列表
export function getLoanConfigRule() {
  return {
    type: key.LOAN_RULE_CONFIG_LIST,
    payload: configRule.GET('')
  }
}

// 获取还款修改金额配置
export function getLoanConfigParam() {
  return configRule.GET('/isRevise')
}

export function setTaxAmoutChangeLog(data) {
  return taxAmoutChangeLog.POST('/addLog', {
    ...data,
    changeType: 'INSERT',
    dataType: 'InvoiceTax'
  })
}

/**
 * @description 共享申请事项
 * @param {*} id
 * @param {*} userIds
 * @param {*} privilegeId
 * @returns
 */
export function sharedApply(id, userIds, privilegeId, userRemark) {
  return {
    type: key.SHARE_APPLY,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then(messageCode => {
        return requisition.POST(
          '/$id/shareRequisition',
          { staffIds: userIds, userRemark },
          { id, messageCode, privilegeId }
        )
      })
      .catch(e => Promise.reject(e))
  }
}

export function getAssociationGroupData() {
  return {
    type: key.GET_ASSOCIATION_GROUP_DATA,
    payload: associationGroupData.GET('')
  }
}

//自动生成费用明细
export function getAutoGenerationFeeDetail(data) {
  return {
    type: key.AUTO_GENERATION_FEE_DETAIL,
    payload: autoGenerationFeeDetail.POST('', { ...data })
  }
}

/**
 * 自动生成费用明细
 */
export function getAutoGenerationFeeDetailRules(data) {
  return {
    type: key.AUTO_GENERATION_FEE_DETAIL_RULES,
    payload: autoGenerationFeeDetail.GET('/get/$specId', data)
  }
}

// 获取公务卡设置信息
export function getOfficialCardSetting(params) {
  return setting.GET('/config')
}

// 获取启动的结算方式
export function getPayments() {
  return payment.GET('/$name', { name: 'basedata.Settlement', withVisibility: true })
}
// 获取启动的结算方式
export function getLayoutConfigList() {
  const query = new QuerySelect()
  query.filterBy('type=="REVIEW"')
  query.limit(0, 1000)
  return layoutConfig.POST('/search', query.value())
}

// 获取单据审阅态设置展示配置
export function getLayoutConfig(specificationId) {
  return layoutConfig.GET('/my/$type/$specificationId', {
    type: 'REVIEW',
    specificationId
  })
}

//保存布局配置信息
export function setFlowLayoutConfig(data) {
  return layoutConfig
    .PUT('/setFlowLayoutConfig', data)
    .catch(err => showMessage.error(i18n.get(err.msg || err.errorMessage)))
}

//获取布局配置信息详情
export function getLayoutConfigById(id) {
  return layoutConfig.GET('/$id', {
    id
  })
}

const LayoutConfigCache = {}
export function getLayoutConfigWithType(params) {
  // 带缓存的请求接口，30s
  const fetcher = () => {
    const { type, specificationId } = params
    const key = `${type}-${specificationId}`
    if (LayoutConfigCache[key]) return LayoutConfigCache[key]
    const promise = layoutConfig.GET('/my/$type/$specificationId', params)
    LayoutConfigCache[key] = promise

    setTimeout(() => {
      LayoutConfigCache[key] = undefined
    }, 30000)
    return promise
  }
  return {
    type: key.GET_LAYOUT_CONFIG,
    payload: fetcher()
  }
}

/**
 * @description 判断是否有申请补助的入口
 * @param {申请事项id} requisitionId
 * @returns
 */
export function checkSurplus(requisitionId) {
  return requisitionInfo.POST('/checkSurplus', {}, { requisitionId })
}

/**
 * @description 获取结余补助行程
 * @param {申请事项id} requisitionId
 * @returns
 */
export function getSurplusConfirmList(requisitionId) {
  return requisitionInfo.POST('/surplusConfirm', {}, { requisitionId })
}

/**
 * @description 获取定额补助行程
 * @param {*} requisitionIds
 * @returns
 */
export function getTravelConfirmList(requisitionIds) {
  return requisitionInfo.POST('/travelConfirm', requisitionIds)
}

/**
 * @description 计算结余补助接口
 * @param {*} params
 * @returns
 */
export function getCalculateSurplusAmount(params) {
  return requisitionInfo.POST('/calculateSurplusAmount/$id', params)
}

/**
 * @description 计算定额补助接口
 * @param {*} params
 * @returns
 */
export function getCalculateTravelAmount(params) {
  return requisitionInfo.POST(`/calculateTravelAmount/[ids]`, params)
}

/**
 * @description 确认生成单据结余
 * @param {*} params
 * @returns
 */
export function getSurplusResult(params) {
  return {
    type: key.GET_SURPLUS_RESULT,
    payload: requisitionInfo.POST('/surplus/$requisitionId', params)
  }
}

/**
 * @description E商卡余额
 * @param {*} loanInfoId
 * @returns
 */
export function getOwnerRemain(loanInfoId) {
  return {
    type: key.GET_OWNER_REMAIN,
    payload: loanInfo.GET('/getOwnerRemain', { loanInfoId })
  }
}

/**
 * @description 确认生成单据定额
 * @param {*} params
 * @returns
 */
export function getTravelResult(params) {
  return {
    type: key.GET_TRAVEL_RESULT,
    payload: requisitionInfo.POST('/travel', params)
  }
}

export function searchExtensionCenterConfig(params) {
  return {
    type: key.SEARCH_EXTENSION_CENTER_CONFIG,
    payload: extensionCenterConfigRes.POST(`/search`, params)
  }
}
// 设置小组件配置
export function setWidgetConfig(params) {
  return widgetConfig
    .POST('/', params)
    .catch(err => showMessage.error(i18n.get(err.msg || err.errorMessage || '接口异常')))
}
// 获取小组件配置
export function getWidgetConfig() {
  return widgetConfig.GET('/', {}).catch(err => showMessage.error(i18n.get(err.msg || err.errorMessage || '接口异常')))
}

/**
 * @description 费用变更
 * @param {*} flowid
 * @returns
 */
export function getFeeTypeChange(flowid) {
  return {
    type: key.GET_FEE_TYPE_CHANGE,
    payload: flows.GET('/checkDetail/$flowid', { flowid })
  }
}

export function getDefaultPayee(params) {
  return {
    type: key.GET_DEFAULT_PAYEE,
    payload: payee.GET('/default', { ...params })
  }
}

export function getDelegateConfig(id) {
  return {
    type: key.GET_DELEGATE_CONFIG,
    payload: delegate.GET(`/getDelegateCustomConfig/delegateId/$id`, { id })
  }
}

export function setSubmitterData(submitterData) {
  return {
    type: key.SET_SUBMITTER_DATA,
    submitterData
  }
}
/**
 * @description 获取补助配置详情
 * @returns
 */
export function getSubsidyConfig() {
  return subsidyAction.GET('/list')
}

function fnCreateNodesInstance(nodes) {
  let random = (min, max) => Math.floor(Math.random() * (max - min)) + min
  forEach(nodes, o => {
    o.id = `FLOW:${random(0, 2147483647)}:${random(0, 2147483647)}`
  })
  return nodes
}

/**
 * @description 审批流预览
 * @param {*} dataSource
 * @returns
 */
export function flowPreview(dataSource) {
  const baseDataProperties = api?.getState('@common')?.globalFields?.data
  const originForm = dataSource?.form
  const flowPlanConfigId = dataSource?.flowPlanConfigId
  const specificationId = dataSource?.form?.specificationId
  let components = specificationId?.components
  const form = {}
  components = components?.filter(item => item?.field)
  components?.forEach(it => {
    form[it?.field] = originForm?.[it?.field]
  })
  let value = parseSubmitParamAsFormValue(
    form,
    specificationId,
    { ...dataSource, currentSpecification: specificationId },
    baseDataProperties
  )
  delete value?.id
  value.flowPlanConfigId = flowPlanConfigId
  api.invokeService('@bills:calPlanInstance', value, {}).then(action => {
    let resp = action
    let nodes = fnCreateNodesInstance(resp?.value?.nodes)
    if (action?.error) {
      // 判断没有节点直接不显示弹窗
      return
    }
    const sensitiveLogs = dataSource?.logs?.filter(log => !!log?.attributes?.sensitiveContent?.length)
    const sensitiveContent = sensitiveLogs?.length
      ? sensitiveLogs[sensitiveLogs?.length - 1]?.attributes?.sensitiveContent
      : ''
    return api.open('@bills:FlowConfigModal', {
      data: {
        isSensitive: get(resp, 'value.submitNode.isSensitive'),
        isRequired: get(resp, 'value.submitNode.isRequired'),
        sensitiveContent,
        flowNodes: nodes,
        formType: value?.formType,
        urgent: resp?.value?.submitNode?.urgent ? resp?.value?.submitNode?.urgent : {}
      },
      dataSource
    })
  })
}

/**
 * 校验“单据当前审批人与前序节点重复时无权审批”是否重复
 * @param id
 * @returns {Promise<any>}
 */
export function getApproverRepeatConfig(id) {
  return unAuthorizedApprove.GET('/$id', { id })
}

export function getFlowLinkList(params = {}) {
  const { query, formData = {}, calculateFlag = 'MASTER' } = params
  const param = {
    formData,
    calculateFlag,
    query
  }
  return flowLink.POST(query ? '/choose' : '/calculate', param, {})
}

// 获取明细引用的公务卡数据
export function getOfficialCardData(params) {
  return setting.POST('/searchByOrderIds', params)
}

/**
 * @description 获取当前企业是否不进行自动计算企业白名单
 * @returns
 */
export function getCalculateCorpIdWhiteList() {
  return {
    type: key.GET_CAlCULATE_CORP_ID_WHITELIST,
    payload: flows.GET('/check/optimizeCorpId')
  }
}

/**
 * @description 查询当前单据提交后所处的阶段
 * @param {*} id
 * @returns
 */
export function getFlowStage(id) {
  return flows.GET('/flowStage/$id', { id })
}

export function getLinkRequisitionCoprs() {
  return {
    type: key.GET_LINK_REQUISITION_COPRS,
    payload: requisitionInfo.GET('/linkRequisitionCoprs')
  }
}

export function getCurrencyRatesById(id) {
  return {
    type: key.GET_CURRENCY_RATES_BYID,
    payload: currencyAction.GET('/active/$id', { id })
  }
}

export function getCurrencyConfig() {
  return {
    type: key.GET_CURRENCY_CONFIG,
    payload: currencyAction.GET('/config')
  }
}

export function getActiveAccountList(data) {
  return {
    type: key.GET_ACTIVE_ACCOUNT_LIST,
    payload: accountList.POST('/actives/filter', { ...data })
  }
}

export function getIfDownloadNeedFileName() {
  return {
    type: key.GET_IF_DOWNLOAD_NEED_FILENAME,
    payload: attachmentAction.GET('/needFileName')
  }
}

// 获取业务对象单据模板依赖配置字段
export function getPermissionFieldComponents(id) {
  return specifications.GET('/fieldsDependentReferences', { id } )
}

// 根据单据id获取明细来源
export function getDetailFLowRelation(id) {
  return detailFLowRelation.GET('/$id', { id })
}

// 保存单据明细来源
export function setDetailFLowRelation(data) {
  return detailFLowRelation.POST('', null, data)
}

// 根据企业id获取预置单据模版id
export function getPresetTemplate(data) {
  return quickExpenseConfig.GET('/getPresetTemplate')
}
