import { cloneDeep, sortBy, groupBy, concat, get, isObject } from 'lodash'
import { Fetch } from '@ekuaibao/fetch'
import { app as api } from '@ekuaibao/whispered'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { getV } from '@ekuaibao/lib/lib/help'
import { showModal, showMessage } from '@ekuaibao/show-util'
import Big from 'big.js'
import { T } from '@ekuaibao/i18n'
import { Resource } from '@ekuaibao/fetch'
import { MoneyMath } from '@ekuaibao/money-math'
import moment from 'moment'
import {IDENTIFY_INVOICE_TYPE} from '../../../lib/enums'
import { INVOICE_TYPE } from '@ekuaibao/lib/lib/enums'
import {InvoiceEntityType} from "./types";
const feeTypesVisibility = new Resource('/api/flow/v2/flows/feeType/visibility')
import { standardCurrencyToForeign, standardValueMoney } from '../../../lib/misc'
import { getSpecification } from './billFetchUtil'

export function getFeeTypeVisibleList(param) {
  return feeTypesVisibility.GET('', param)
}

//删除单据上的批注
export function deleteNoteById(params) {
  return Fetch.DELETE(`/api/credit/v2/notes/$${params.noteId}`, null, { body: { reason: params.reason } }).then(() => {
    setTimeout(() => api.invokeService('@bills:get:bill:notes', { flowId: params.flowId }))
  })
}

//获取申请事项中的预算
export function getRequitistionEvent(id) {
  return Fetch.GET(`/api/form/v2/requisition/$${id}/budgetoccupieds`)
}

//获取当前props所使用的SpecificationList
export function getSpecificationsByType(props, type) {
  return props[`${type === 'permit' ? 'requisition' : type}SpecificationList`] || []
}

//获取默认Specification
export function getDefSpecificationDS(dataSource, specificationGroupsList) {
  if (!specificationGroupsList) return
  if (dataSource.form && dataSource.form.specificationId) {
    let specification = dataSource.form.specificationId
    let filterList = specificationGroupsList.filter(
      o => o.specifications.filter(i => i.id === specification.id).length > 0
    )
    if (filterList.length === 0) {
      let current = cloneDeep(specification)
      // current.name += i18n.get('(旧)')
      current.isOldVersion = true
      return current
    } else {
      return specification
    }
  }
  return null
}

//endregion
export function calculateFlowNodes(nodes = []) {
  let curNodes = cloneDeep(nodes)
  curNodes.forEach(o => {
    if (o.type === 'countersign') {
      const crossCorpApprove = getV(o, 'config.crossCorpApprove')
      if (crossCorpApprove) {
        o.counterSigners.forEach(k => {
          k.signerId = k.id
          delete k.id
        })
      } else {
        o.counterSigners.forEach(k => {
          k.signerId = k.signerId.id
        })
      }
      o.counterSignersCandidate.forEach(k => {
        k.signerId = k.signerId.id
      })
    }
  })
  return curNodes
}

//消费明细按照时间排序。规则是按照消费日期升序，如果没有消费日期，则取起止日期的开始日期。如果前两者均没有，则按照添加顺序排在列表底部
export function sortFeeTypeForm(FeeTypeArr) {
  if (FeeTypeArr && FeeTypeArr.length > 1) {
    return (FeeTypeArr = sortBy(FeeTypeArr, [
      el => {
        return el.feeTypeForm.feeDate || (el.feeTypeForm.feeDatePeriod && el.feeTypeForm.feeDatePeriod.start)
      }
    ]))
  }
  return FeeTypeArr
}

//弹出绑定窗口
export function showModalForFollowWeChat() {
  const KA_LOCAL_CONFIG = api.getState()['@common'].powers.KA_LOCAL_CONFIG
  if (KA_LOCAL_CONFIG) {
    return Fetch.GET('/api/v1/organization/corporations/select').then(resp => {
      const { value: { enableWechatBind = true } = {} } = resp
      if (!enableWechatBind) {
        return null
      }
      return doShowModalForFollowWeChat()
    })
  } else {
    return doShowModalForFollowWeChat()
  }
}

function doShowModalForFollowWeChat() {
  const disablePlatform = ['HUAWEI', 'FEISHU']
  //判断是否应该弹出绑定窗口
  return Fetch.GET('/api/weixin/v1/qrcode', { tagId: 104 }).then(res => {
    if (res.isBinding) return null
    const userInfo = api.getState('@common.userinfo.data')
    let isHaveLocalStorage = localStorage.getItem(
      'isBindWeChat:' + userInfo.staff.id + ' - ' + userInfo.staff.corporationId
    )
    if (
      !isHaveLocalStorage &&
      !disablePlatform.includes(window.__PLANTFORM__) &&
      Fetch.ekbCorpId !== 'DZgaDKv4Yk8400' && 
      res?.qrCode
    ) {
      // 判断该用户是否首次登陆 并且不为华为和飞书
      api.open('@bills:FollowWeChatModal', { qrCode: 'data:image/jpg;base64,' + res.qrCode })
    }
  })
}

import { getDetailCalculateMoney } from './getDetailCalculateMoney'

export { getDetailCalculateMoney }

import { formatNewTemplateValue } from './formatNewTemplateValue'
import { related } from '../../../elements/feeDetailViewList/Related'
import { uuid, isArray } from '@ekuaibao/helpers'
export { formatNewTemplateValue }

import { fnCheckPrintAvailable } from './fnCheckPrintAvailable'
import { getDetailFLowRelation } from '../bills.action'
export { fnCheckPrintAvailable }

export function setRemunerationBatchtoValue(dataSource, v, remunerationBatchField) {
  const remunerationBatch = get(dataSource, `form.${remunerationBatchField}`)
  if (remunerationBatch) {
    v[remunerationBatchField] = remunerationBatch
  }
}

export function renderInvoiceRiskTips(data) {
  let tips = []
  data.form.details &&
    data.form.details.find((item, index) => {
      if (item.feeTypeForm.invoiceForm && item.feeTypeForm.invoiceForm.type === 'wait') {
        return tips.push(
          <div
            key={index}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
          >
            <T name="有「待开发票」的消费" />
          </div>
        )
      }
    })

  return tips
}

export function getFlowRequirdNode(flows = []) {
  const budgetNodes = []
  let requiredNodes = []
  flows.forEach((item, index) => {
    const { conditionalType, type } = item
    //找出第预算超额需要审批的节点
    if (conditionalType === 'BUDGET') {
      budgetNodes.push({ budgetNode: item, budgetNodeIndex: index })
    }

    //找出必填节点
    let otherCheck = index === 0
    if (!requiredNodes.length) {
      otherCheck = true
    }
    if (type === 'branch') {
      otherCheck = false
    }
    if (checkPropty(item, otherCheck)) {
      requiredNodes.push({ requireNode: item, requireNodeIndex: index })
    }
  })

  const budgetNodeInfo = budgetNodes[0]
  const { budgetNode, budgetNodeIndex } = budgetNodeInfo || {}

  const requiredNodeInfo = requiredNodes[0]
  const { requireNode, requireNodeIndex = 0 } = requiredNodeInfo || {}

  requiredNodes = requiredNodes.map(item => item.requireNode)

  //有预算超额节点时：如果必填节点在预算超额前面或者在预算节点的下一个节点就不再找必填节点
  if (
    !!budgetNode && //存在预算超额节点
    requiredNodeInfo && //有必填节点
    requireNodeIndex >= budgetNodeIndex &&
    requireNodeIndex - budgetNodeIndex !== 1
  ) {
    //必填节点在预算超额后边并且不是预算节点的下一个节点
    //当地一个节点不是预算超额节点时，将下一个节点设置为必填节点
    for (let i = budgetNodeIndex + 1; i < flows.length; i++) {
      const node = flows[i]
      const { conditionalType } = node
      if (conditionalType !== 'BUDGET' && node.type !== 'branch' && checkPropty(node, true)) {
        requiredNodes.push(node)
        break
      }
    }
  }
  if (budgetNodes.length > 0) {
    const nodes = getFirstNoBudgetNodes(flows)
    const nodeList = nodes.filter(oo => oo.type !== 'branch')
    if (nodeList.length > 0) {
      requiredNodes = requiredNodes.concat(nodes)
    }
  }
  return requiredNodes
    .filter(item => !!item)
    .filter(item => item.type !== 'ebot')
    .map(item => item.configNodeId)
}

// 处理 第一个非预算节点以及以上节点必须是必填的
function getFirstNoBudgetNodes(flows) {
  const nodes = []
  let firstBudget = 0
  for (let i = 0; i < flows.length; i++) {
    const { conditionalType, type } = flows[i]
    if (conditionalType !== 'BUDGET' && type !== 'countersign' && type !== 'branch') {
      firstBudget = i
      break
    }
  }

  for (let i = 0; i <= firstBudget; i++) {
    const { skippedType, type } = flows[i]
    if (skippedType === 'NO_SKIPPED' && type !== 'countersign' && type !== 'branch') {
      nodes.push(flows[i])
    }
  }
  return nodes
}

function checkPropty(node, otherCheck = false) {
  const { config = {}, skippedType, type, isAllStaffs, staffIds } = node
  const isEbot = type === 'ebot'
  const hasApprover = isAllStaffs || (staffIds && staffIds.length > 0)
  const isPass = skippedType === 'NO_ABILITY' || skippedType === 'PAY_AMOUNT_IS_0' || !hasApprover || isEbot

  const isAbility = skippedType === 'NO_ABILITY'
  const isCountersign = type === 'countersign'

  let isSubmitterChoice = false,
    isManualChoose = true,
    isAuto = false
  if (!!config) {
    //由“提交人”指定： 手动选择时 必填 ；自动选择时 有可选审批人时必选 
    isManualChoose = !config?.staffSelectionChoiceMode || ['ALL','RANGE'].includes(config?.staffSelectionChoiceMode)
    isSubmitterChoice = config?.isSubmitterChoice ?? false
    isAuto = config?.isAuto ?? isAuto
  }

  return (
    (otherCheck && ((isCountersign && !isAuto) || (!isCountersign && !isPass))) || 
    (!isAbility && isSubmitterChoice && (isManualChoose || hasApprover))
  )
}

export function confirmCopy(dataSource) {
  return new Promise(resolve => {
    const formType = get(dataSource, 'form.specificationId.type', '')
    if (formType === 'expense' || formType === 'requisition') {
      showModal.confirm({
        className: 'confirmCopyModal-wrapper',
        title: i18n.get('提示'),
        content:
          formType === 'expense'
            ? i18n.get(
                '复制生成的报销单，将不会携带原消费记录中的卡片及拆分组件的明细信息，如：发票、滴滴、易快报自营等第三方卡片信息。如有疑问请找小易咨询'
              )
            : i18n.get('复制生成的申请单，将不会携带拆分组件的明细信息。如有疑问请找小易咨询复制生成的申请单'),
        okText: i18n.get('确认'),
        cancelText: i18n.get('取消'),
        onOk: () => {
          related.clearRelatedData()
          resolve()
        }
      })
    } else {
      resolve()
    }
  })
}

export async function formatCopyBillData(datasource) {
  const form = get(datasource, 'form')
  const data = await formatFormForCopy(form)
  return { state: 'new', formType: datasource.formType, form: data, isCopy: true }
}

//复制单据时的过滤方法
export async function formatFormForCopy(value) {
  const delKeys = [
    'expenseLink',
    'expenseLinks',
    'linkRequisitionInfo',
    'writtenOffMoney',
    'expenseDate',
    'submitDate',
    'requisitionDate',
    'loanDate',
    'repaymentDate'
  ]
  const { specificationId: specification } = value
  value = deleteKeys(value, specification, delKeys)
  // value = await checkSelectValue(value)
  let allCurrencyRate = []
  if (value?.legalEntityMultiCurrency && value?.legalEntityMultiCurrency?.form?.baseCurrencyId) {
    const { baseCurrencyId } = value?.legalEntityMultiCurrency?.form
    const { items: rates = [] } = await api.invokeService('@currency-manage:get:currency:rates:by:Id', baseCurrencyId)
    allCurrencyRate = rates
  } else {
    allCurrencyRate = await api.dataLoader('@common.allCurrencyRates').load()
  }
  const components = get(specification, 'components', [])
  const detailC = components.find(item => ['details', 'trips'].includes(item.field))
  const hide = get(detailC, 'hide')
  const tempDataLinkIds = []
  if (hide) delete value.details
  // 更新支付计划的id
  if (value.payPlan && value.payPlan.length) {
    value.payPlan = value.payPlan.map(el => {
      el.dataLinkId = uuid(14)
      tempDataLinkIds.push(el.dataLinkId)
      return el
    })
  }
  if (!!detailC && !hide) {
    let details = get(value, `${detailC.field}`, [])
    const isSyncFeetypeId = tempDataLinkIds.length === details.length
    //费用明细字段处理
    details = details.map((item, idx) => {
      let detailForm = null
      const spe = get(item, 'specificationId')
      if (detailC.field === 'details') {
        item.feeTypeForm.detailId = isSyncFeetypeId ? tempDataLinkIds[idx] : uuid(14)
        // 复制时删除分摊id
        const apportions = getV(item, 'feeTypeForm.apportions', [])
        if (apportions.length) {
          apportions.forEach(el => {
            el.apportionForm['apportionId'] = 'ID_' + uuid(11)
          })
        }
        detailForm = get(item, 'feeTypeForm')
        item.feeTypeForm = deleteKeys(detailForm, spe)
        item = formatDetailValue(item)
      } else {
        delete item.tripForm.tripId
        detailForm = get(item, 'tripForm')
        item.tripForm = deleteKeys(detailForm, spe)
      }
      return formatFormAmount(item, allCurrencyRate, 'feeTypeForm')
    })
    value[detailC.field] = details
  }

  return formatFormAmount(value, allCurrencyRate)
}

/**
 * 对数据中的金额字段格式化，过滤出所有金额字段
 * @param data 需要处理的数据
 * @param allCurrency: 系统中所有已配置的外币币种
 * @param valuePath: 对应的数据路径，默认传进来的data就是要处理的数据
 * @returns {*}
 */
function formatFormAmount(data, allCurrency, valuePath = '') {
  const components = getV(data, 'specificationId.components', [])
  if (!components.length) return data
  const moneyComponents = components.filter(item => {
    const type = getV(item, 'type', '')
    return type === 'money'
  })
  if (!moneyComponents.length) return data
  let value = !!valuePath ? data[valuePath] : data
  moneyComponents.forEach(item => {
    const field = getV(item, 'field', '')
    value[field] = formatAmount(value[field], allCurrency)
  })
  !!valuePath ? (data[valuePath] = value) : (data = value)
  return data
}

/**
 * 格式化金额：复制过来的金额中的税率和系统配置的外币的税率不一样的时候，按照系统配置的税率重新计算此金额中对应的本位币金额
 * @param amount 需要格式化的币种
 * @param allCurrency 系统中所有已配置的外币币种
 * @returns {*}
 */
function formatAmount(amount, allCurrency) {
  const foreignStrCode = getV(amount, 'foreignStrCode', '')
  const rate = getV(amount, 'rate', '')
  if (!foreignStrCode || !rate) return amount
  if (!allCurrency || !allCurrency.length) return amount
  const currency = allCurrency.find(item => item.strCode === foreignStrCode)
  if (!currency) return amount
  const currencyRate = getV(currency, 'rate', '')
  if (rate === currencyRate) return amount
  const foreign = getV(amount, 'foreign', 0)
  const standardScale = getV(amount, 'standardScale', 2)
  const result = { ...amount }
  result.rate = currencyRate
  result.sysRate = currencyRate
  result.standard = new Big(foreign).times(currencyRate).toFixed(Number(standardScale))
  return result
}
// 复制单据功能检查 检查自定义档案数据数据，如果是非叶子节点到话，就删除该字段
// 因遵守尽量不更改用户自己所选择操作，所以此方法注释，在保存单据的时候提示用户
async function checkSelectValue(value) {
  const components = getV(value, 'specificationId.components', [])
  const globalFields =  api.getState()['@common'].globalFields.data || []
  const DimensionData =  globalFields.filter(type =>{
    const entity = getV(type, 'dataType.entity') || getV(type, 'dataType.elemType.entity')
    if(entity?.startsWith('basedata.Dimension')) {
      return type
    }
  })

  if (!components.length) return value
  const selectAndLeafComponents = components.filter(item=>{
    if(DimensionData.filter(d=>d.name == item.field).length > 0){
      return item
    }
  })
  const needCheckValueKeys = []
  selectAndLeafComponents.forEach(item => {
    const { field } = item
    if (Object.keys(value).includes(field)) {
      needCheckValueKeys.push(field)
    }
  })
  
  if (!!needCheckValueKeys.length) {
    const params = []
    needCheckValueKeys.map(key => {
      const dimension = value[key]
      const id = getV(dimension, 'id') || dimension
      params.push({
        key,
        id
      })
    })
    const result = await api.invokeService(
      '@custom-dimension:check:Dimensions:isLeaf',
      params.map(item => item.id)
    )
    const notLeaf = result.filter(item => !item.isLeaf)
    if (notLeaf.length) {
      const ids = notLeaf.map(item => item.id)
      const needDelKey = params.filter(item => {
        if(item.id instanceof Array){
          return item.id.filter(v=>ids.includes(v)).length
        }else{
          return ids.includes(item.id)
        }
      }).map(item => item.key)

      needDelKey.forEach(delkey => {
        delete value[delkey]
      })
    }
  }
  return value
}

function formatDetailValue(item) {
  if (item.feeTypeForm.feeDate) {
    item.feeTypeForm.feeDate = new Date().getTime()
  }

  const feetypeForm = item.feeTypeForm
  const invoiceType = get(feetypeForm, 'invoiceForm.type')

  const { specificationId } = item
  const { components } = specificationId
  const dateFields = components.filter(el => el.type === 'date')
  dateFields.forEach(field => {
    const { defaultValue, withTime } = field
    if (defaultValue && defaultValue.value === 'submit.date') {
      item.feeTypeForm[field.field] = withTime
        ? moment(moment().format('YYYY/MM/DD HH:mm')).valueOf()
        : moment(moment().format('YYYY/MM/DD')).valueOf()
    }
  })

  //以下信息，不在复制单据时带入草稿
  if (invoiceType === 'exist') {
    //通过导入发票生成的明细
    const invoiceFormField = components.find(el => el.field === 'invoiceForm')
    const { editable, defaultValue } = invoiceFormField
    if (!editable) {
      item.feeTypeForm.invoiceForm = { type: defaultValue.value }
    } else {
      item.feeTypeForm.invoiceForm = { type: invoiceType }
    }

    const taxs = components.filter(line => get(line, 'defaultValue.type') === 'invoiceSum').map(line => line.field)
    taxs.forEach(key => {
      delete item.feeTypeForm[key]
    })
  }
  if (
    item.feeTypeForm.thirdPartyOrders || //通过导入中航易购生成的明细
    item.feeTypeForm.ordersData //通过导入易快报自营业务生成的明细（企业消费，公务卡等）
  ) {
    delete item.feeTypeForm.thirdPartyOrders
    delete item.feeTypeForm.ordersData
    delete item.feeTypeForm.orders
  }
  return item
}

function deleteKeys(value, specification, keys = []) {
  const { components } = specification
  components.forEach(component => {
    if (isNeedDel(component)) {
      keys.push(component.field)
    }
  })
  keys.push('code')
  keys.push('linkDetailEntities')
  keys.map(delkey => {
    delete value[delkey]
  })
  return value
}

function isNeedDel(component) {
  const { type, defaultValue, editable } = component
  const value = get(defaultValue, 'value', '')
  const isOfficialCardSettlement = defaultValue?.type === 'officialCardSettlement'
  return type === 'dataLink' || type === 'dataLinkEdits' || (value === 'submit.requisition' && !editable) || isOfficialCardSettlement
}

export function canChangeTemplateFn(dataSource) {
  return getNodeValueByPath(dataSource, 'plan.submitNode.nextId', 'SUBMIT') === 'SUBMIT'
}

//提交单据时 对补充单据的外理逻辑
export function submitBillLogic({ formValue, CHANGJIEPay, bus, isAdmin, multiplePayeesMode, needCertificateNo = [] }) {
  const { payeeId = {}, submitterId = {}, details = [], payPlanMode, payPlan = [] } = formValue
  const staffId = get(payeeId, 'staffId', {}) || {} //staffId 可能为null
  const canEdit = isAdmin || staffId.id === submitterId.id
  // 如果是多收款人模式
  if (CHANGJIEPay && multiplePayeesMode) {
    const payeeList = api.getState('@common.payees') || []
    let payeeIdMap = {}
    let waitForComplement = details.filter(
      item =>
        item.feeTypeForm.feeDetailPayeeId &&
        (!item.feeTypeForm.feeDetailPayeeId.certificateType || !item.feeTypeForm.feeDetailPayeeId.certificateNo) &&
        item.feeTypeForm.feeDetailPayeeId.type === 'PERSONAL' &&
        needCertificateNo.includes(item.feeTypeForm.feeDetailPayeeId.id)
    )
    if (payPlanMode) {
      waitForComplement = payPlan.filter(item => {
        // @i18n-ignore
        const payee =
          payeeList.length &&
          payeeList.find(payee => payee.id === item.dataLinkForm[i18n.get('E_system_支付计划_收款信息')])
        // @i18n-ignore
        return (
          item.dataLinkForm['E_system_支付计划_收款信息'] &&
          payee &&
          (!payee.certificateType || !payee.certificateNo) &&
          payee.type === 'PERSONAL' &&
          needCertificateNo.includes(item.dataLinkForm['E_system_支付计划_收款信息'])
        )
      })
    }
    if (waitForComplement.length > 0) {
      if (payPlanMode) {
        waitForComplement.forEach(item => {
          // @i18n-ignore
          const payee =
            payeeList.length && payeeList.find(payee => payee.id === item.dataLinkForm['E_system_支付计划_收款信息'])
          // @i18n-ignore
          return (payeeIdMap[item.dataLinkForm['E_system_支付计划_收款信息']] = payee)
        })
      } else {
        waitForComplement.forEach(
          item => (payeeIdMap[item.feeTypeForm.feeDetailPayeeId.id] = item.feeTypeForm.feeDetailPayeeId)
        )
      }
      const canEdit = isAdmin
      return api
        .open('@bills:CheckPayeeModal', {
          payeeIdList: Object.values(payeeIdMap),
          payeeIdMap,
          canEdit,
          multiplePayeesMode: multiplePayeesMode,
          billsBus: bus,
          formValue
        })
        .then(data => {
          if (data === 'cancel') {
            return data
          }
          if (canEdit) {
            let feeDetailPayeeId
            const { details, payPlanMode } = formValue
            if (!payPlanMode) {
              details.length > 0 &&
                details.forEach(item => {
                  feeDetailPayeeId = data[item.feeTypeForm.feeDetailPayeeId.id]
                  if (feeDetailPayeeId) {
                    item.feeTypeForm.feeDetailPayeeId = feeDetailPayeeId
                  }
                })
            }
            if (bus) {
              bus.setFieldsValue({ details: [...details] })
            }
            return formValue
          } else {
            return Promise.reject()
          }
        })
    }
  } else if (
    payeeId &&
    CHANGJIEPay &&
    (!payeeId.certificateType || !payeeId.certificateNo) &&
    payeeId.type === 'PERSONAL'
  ) {
    // 单收款人模式
    return bus.invoke('get:pay:money').then(payMoney => {
      return api.open('@bills:CheckPayeeModal', { payeeId, canEdit }).then(data => {
        if (data === 'cancel') {
          return data
        }
        if (canEdit) {
          formValue.payeeId = data
          if (bus) {
            bus.setFieldsValue({ payeeId: data })
          }
          return formValue
        } else {
          return Promise.reject()
        }
      })
      return formValue
    })
  }
  return formValue
}

//这4种状态下 提交单据时需要给multiplePayeesMode赋值
const billsUnsavedState = {
  new: true,
  modify: true,
  draft: true,
  rejected: true
}

//审批中修改单据和复制单据时设置值
export function setFormMultiplePayeesMode(formValue, dataSource = {}) {
  const { payeeId = {} } = formValue
  if (!formValue.multiplePayeesMode && billsUnsavedState[dataSource.state] && payeeId?.multiplePayeesMode) {
    formValue.multiplePayeesMode = payeeId.multiplePayeesMode
    formValue.payPlanMode = payeeId.payPlanMode
    formValue.payeePayPlan = payeeId.payeePayPlan
  }
}

function hasApprotion(value) {
  const newValue = cloneDeep(value)
  const details = getV(newValue, 'details', [])
  let hasApportion = false
  details.forEach(item => {
    const apportions = getV(item, 'feeTypeForm.apportions', [])
    if (!!apportions.length) {
      hasApportion = true
      delete item.feeTypeForm.apportions
    }
  })
  return { hasApportion, newValue }
}

export async function checkValue(params) {
  const { oldTemplate, newTemplate, bus, dataSource, remunerationBatchField } = params
  const { data, value } = await parseValueApprotion({ newTemplate, bus, dataSource, remunerationBatchField })
  const nV = await checkTempKeepCurrencyEqual({ oldTemplate, newTemplate, value })
  return { data, value: nV }
}

export async function checkTempKeepCurrencyEqual({ oldTemplate, newTemplate, value }) {
  return new Promise(async (resolve, reject) => {
    const OTMustAllFeeTypeCurrencyEqual = getMustAllFeeTypeCurrencyEqual(oldTemplate)
    const NTMustAllFeeTypeCurrencyEqual = getMustAllFeeTypeCurrencyEqual(newTemplate)
    if (OTMustAllFeeTypeCurrencyEqual === NTMustAllFeeTypeCurrencyEqual) return resolve(value)
    const details = getV(value, 'details', [])
    if (!details.length || isCurrencyEquel(details)) return resolve(value)
    if (NTMustAllFeeTypeCurrencyEqual) {
      showModal.confirm({
        title: i18n.get('即将移除所有费用明细'),
        content: i18n.get(
          '新模板要求所有费用币种一致。当前单据存在不同币种的费用。切换到新的单据模板将会导致所有费用被移除。'
        ),
        onOk: () => {
          delete value.details
          resolve(value)
        },
        onCancel: () => {
          reject()
        },
        okText: i18n.get('移除'),
        cancelText: i18n.get('取消')
      })
    } else {
      resolve(value)
    }
  })
}

function isCurrencyEquel(details) {
  let result = true
  let beforeCode = ''
  for (let i = 0; i < details.length; i++) {
    const detail = details[i]
    const foreignNumCode = getV(detail, 'feeTypeForm.amount.foreignNumCode', '')
    if (i !== 0) {
      result = foreignNumCode === beforeCode
    }
    if (!result) {
      break
    }
    beforeCode = foreignNumCode
  }
  return result
}

function getMustAllFeeTypeCurrencyEqual(template) {
  const components = getV(template, 'components', [])
  const detailComponent = components.find(c => c.field === 'details')
  return getV(detailComponent, 'mustAllFeeTypeCurrencyEqual', false)
}

export function parseValueApprotion(params) {
  const { newTemplate, bus, dataSource, remunerationBatchField } = params
  return new Promise(async (resolve, reject) => {
    if (!newTemplate || !bus) reject()
    const value = await bus.getValue()
    const remunerationBatch = get(dataSource, `form.${remunerationBatchField}`)
    if (remunerationBatch) {
      value[remunerationBatchField] = remunerationBatch
    }
    const config = getV(newTemplate, 'configs', [])
    const chargeAgainst = config.find(item => item.ability === 'chargeAgainst')
    const isChargeAgainst = getV(chargeAgainst, 'isChargeAgainst', false)
    if (isChargeAgainst) {
      const { hasApportion, newValue } = hasApprotion(value)
      if (hasApportion) {
        showModal.confirm({
          content: i18n.get('您即将切换到一个冲销单据模板，冲销模板不支持分摊，将清空分摊信息'),
          onOk: () => {
            resolve({ data: newTemplate, value: newValue })
          },
          onCancel: () => {
            reject()
          },
          okText: i18n.get('确定'),
          cancelText: i18n.get('取消')
        })
      } else {
        resolve({ data: newTemplate, value })
      }
    } else {
      resolve({ data: newTemplate, value })
    }
  })
}

export function getValidateErrorByShow(components, errKeys = []) {
  return components.filter(v => !v.hide && errKeys.includes(v.field))
}

export function showSensitiveContent(dataSource, userId) {
  const owner = get(dataSource, 'ownerId')
  const submitter = get(dataSource, 'form.submitterId')

  const ownerId = typeof owner === 'object' ? owner.id : owner
  if (ownerId === userId) return true

  const submitterId = typeof submitter === 'object' ? submitter.id : submitter
  if (submitterId === userId) return true

  const nodes = get(dataSource, 'plan.nodes') || []
  let show = false
  for (let node of nodes) {
    const { counterSigners, approverId } = node
    if (approverId && approverId.id === userId) {
      show = true
      break
    }
    if (counterSigners && counterSigners.length > 0) {
      const signer = counterSigners.find(item => item.signerId.id === userId)
      if (signer) {
        show = true
        break
      }
    }
  }
  return show
}

export function getSensitiveContent(value) {
  return `${i18n.get('提交人添加了补充说明：')}${value}`
}

export const STATE_LIST = ['paid', 'archived', 'rejected'] //这些状态下不显示敏感信息

// 校验借款包是否存在，是否可以被单据关联
export function checkLoanPackage(writtenOff, submitterId) {
  let loanIds = writtenOff.map(item => {
    // 兼容报销单关联多条申请单
    return typeof item.loanInfoId === 'object' ? item.loanInfoId.id : item.loanInfoId
  })
  return new Promise((resolve, reject) => {
    api.invokeService('@bills:check:loan:package:exist', loanIds, submitterId).then(res => {
      const { items } = res
      if (items && items.length) {
        let str = ''
        Object.keys(items[0]).forEach(a => {
          str += items[0][a] + i18n.get('；')
        })
        str = str.substring(0, str.length - 1)
        showMessage.error(str)
        reject(str)
      } else {
        resolve()
      }
    })
  })
}

export const errorStyle = {
  marginLeft: 2,
  width: 16,
  color: '#FF7C7C',
  height: 16
}

export function fnLinkDetailEntitiesValue(value) {
  const cValue = cloneDeep(value)
  const arrLinkDetails = get(cValue, 'linkDetailEntities', []) || []
  if (arrLinkDetails.length && arrLinkDetails[0].linkDetailEntityId) {
    // const linkDetailEntities = groupBy(arrLinkDetails, 'linkDetailEntityId.linkId')
    const linkDetailEntities = {}
    arrLinkDetails.forEach(item => {
      const key = item.linkDetailEntityId.linkRequisitionInfo || item.linkDetailEntityId.linkId
      let list = linkDetailEntities[key]
      if (!list?.length) {
        list = []
        linkDetailEntities[key] = list
      }
      list.push(item)
    })
    const list = []
    Object.keys(linkDetailEntities).forEach(key => {
      const dataList = linkDetailEntities[key].map(item => {
        const { amount, linkDetailEntityId, _tempConsumId } = item
        const { unwrittenOffAmount, id } = linkDetailEntityId
        const money = related.relatedMap[id]
        const modifyMoney = new MoneyMath(unwrittenOffAmount).add(amount).value
        const tempConsumId = _tempConsumId ? _tempConsumId : cValue.detailId
        linkDetailEntityId.unwrittenOffAmount = new MoneyMath(linkDetailEntityId.unwrittenOffAmount).add(money).value
        return { ...linkDetailEntityId, modifyMoney, modifyValue: amount, _tempConsumId: tempConsumId }
      })
      list.push({ flowId: key, dataList: dataList })
    })
    cValue.linkDetailEntities = list
  }
  return cValue
}

export async function checkTemplateField(oldTemplate, newTemplate, legalEntityCurrencyPower = false, bus) {
  if (legalEntityCurrencyPower) {
    const oldCurrecyField = oldTemplate?.components?.find(f => f.field === 'legalEntityMultiCurrency')
    const newCurrecyField = newTemplate?.components?.find(f => f.field === 'legalEntityMultiCurrency')
    if (oldCurrecyField && !newCurrecyField) {
      api.invokeService('@bills:update:dimention:currency', null)
      const currency = api.getState()['@common'].standardCurrency 
      const rates = await api.dataLoader('@common.allCurrencyRates').load()
      bus.emit('dimention:currency:change', { rates, currency })
    }
  }
}

export function getFilterList() {
  const formTypes = [
    { label: i18n.get('全部模板'), type: 'all' },
    { label: i18n.get('报销单'), type: 'expense' },
    { label: i18n.get('借款单'), type: 'loan' },
    { label: i18n.get('申请单'), type: 'requisition' }
  ]
  if (api.getState()['@common'].powers?.BasicDocument) {
    //基础单据charge
    Array.prototype.push.apply(formTypes, [
      { label: i18n.get('付款单'), type: 'payment' },
      { label: i18n.get('通用审批单'), type: 'custom' }
    ])
  }
  if (api.getState()['@common'].powers?.RECEIPT_DOCUMENT) {
    //基础单据charge
    Array.prototype.push.apply(formTypes, [{ label: i18n.get('收款单'), type: 'receipt' }])
  }
  return [
    {
      type: 'state',
      label: i18n.get('提交状态'),
      children: [
        { label: i18n.get('全部状态'), type: 'all' },
        { label: i18n.get('草稿'), type: 'draft' },
        { label: i18n.get('审批中'), type: 'approving' },
        { label: i18n.get('已驳回'), type: 'rejected' },
        { label: i18n.get('待收单'), type: 'receiving' },
        { label: i18n.get('待寄送'), type: 'sending' },
        { label: i18n.get('待支付'), type: 'paying' }
        // { label: i18n.get('已完成(待确认)'), type: 'paid' },
        // { label: i18n.get('已完成(已确认)'), type: 'archived' }
      ]
    },
    {
      type: 'formType',
      label: i18n.get('模板类型'),
      children: formTypes
    }
  ]
}

export const formatDate = (value, format) => {
  if (!value) {
    return value
  }
  return moment(value).format(format)
}

// 费用类型的差异
export const getDiffsBetweenFeeTypes = (curDetails, prevDetails) => {
  const curFeeDetailMap = curDetails.reduce(
    (result, item) =>
      Object.assign(result, item?.feeTypeForm?.detailId ? { [item?.feeTypeForm?.detailId]: item } : undefined),
    {}
  )
  const prevFeeDetailMap = prevDetails.reduce(
    (result, item) =>
      Object.assign(result, item?.feeTypeForm?.detailId ? { [item?.feeTypeForm?.detailId]: item } : undefined),
    {}
  )
  const curRes = curDetails.map((item, index) => {
    const id = item?.feeTypeForm?.detailId
    const prev = prevFeeDetailMap[id]
    // 如果以前不存在，则是新增的
    if (!prev) {
      return {
        type: 'add',
        name: item?.feeTypeId?.fullname,
        time: item?.feeTypeId?.createTime,
        amount: item?.feeTypeForm?.amount,
        subText: [formatDate(item?.feeTypeForm?.feeDate, 'YYYY年MM月DD日')],
        text: []
      }
    } else {
      // 假如价格相同，则视为没有变更
      if (prev?.feeTypeForm?.amount.standard == item?.feeTypeForm?.amount.standard) {
        return null
      }
      return {
        type: 'change',
        name: item?.feeTypeId?.fullname,
        time: item?.feeTypeId?.createTime,
        amount: item?.feeTypeForm?.amount,
        subText: [formatDate(item?.feeTypeForm?.feeDate, 'YYYY年MM月DD日')],
        text: [],
        before: {
          name: prev?.feeTypeId?.fullname,
          time: prev?.feeTypeId?.createTime,
          amount: prev?.feeTypeForm?.amount,
          subText: [formatDate(prev?.feeTypeForm?.feeDate, 'YYYY年MM月DD日')],
          text: []
        }
      }
    }
  })

  const prevRes = prevDetails.map((item) => {
    const id = item?.feeTypeForm?.detailId
    const cur = curFeeDetailMap[id]
    // 如果现在不存在，则是删除的
    if (!cur) {
      return {
        type: 'deled',
        name: item?.feeTypeId?.fullname,
        time: item?.feeTypeId?.createTime,
        amount: item?.feeTypeForm?.amount,
        subText: [formatDate(item?.feeTypeForm?.feeDate, 'YYYY年MM月DD日')],
        text: []
      }
    } else {
      return null
    }
  })
  return [...curRes, ...prevRes].filter(Boolean)
}


const formatInvoice = (item) => {
  const fileKey = item?.key
  if (fileKey) {
    return {
      name: item.fileName,
      time: null,
      thumbUrl: item.fileId?.thumbUrl || item.fileId?.url,
      url: item.fileId?.url || item.fileId?.thumbUrl
    }
  }
  const result = formatInvoiceInfoData(item)
  return {
    ...result,
    amountLabel: '税价合计：',
    subText: [
      result.date,
      result.code && '发票号码：' + result.code,
      '发票类型：' + result.typeName
    ]
  }
}

/**
 * 转换发票数据
 * @param item
 * @returns {import('./types').InvoiceSimpleData}
 */
export const formatInvoiceInfoData = (item) => {
  const entityId = item?.master?.entityId
  const value = item?.master
  const mapItem = {
    [InvoiceEntityType.增值税发票]: {
      name: 'E_system_发票主体_销售方名称',
      date: 'E_system_发票主体_发票日期',
      code: 'E_system_发票主体_发票号码',
      type: 'E_system_发票主体_发票类别',
      amount: 'E_system_发票主体_价税合计'
    },
    [InvoiceEntityType.出租车票]: {
      name: `出租车发票(${value?.form?.E_system_出租车票_发票所在地})`,
      date: 'E_system_出租车票_上车时间',
      code: 'E_system_出租车票_发票号码',
      type: '出租车票',
      amount: 'E_system_出租车票_金额'
    },
    [InvoiceEntityType.过路费发票]: {
      name: `过路费发票(${value?.form?.E_system_过路费发票_入口} - ${value?.form?.E_system_过路费发票_出口})`,
      date: 'E_system_过路费发票_时间',
      code: 'E_system_过路费发票_发票号码',
      type: '过路费发票',
      amount: 'E_system_过路费发票_金额'
    },
    [InvoiceEntityType.定额发票]: {
      name: '定额发票',
      code: 'E_system_定额发票_号码',
      type: '定额发票',
      amount: 'E_system_定额发票_金额'
    },
    [InvoiceEntityType.火车票]: {
      name: `${value?.form?.E_system_火车票_上车车站}--${value?.form?.E_system_火车票_下车车站}`,
      code: 'E_system_火车票_号码', // 没有发票代码
      type: '火车票',
      date: 'E_system_火车票_乘车时间',
      amount: 'E_system_火车票_金额'
    },
    [InvoiceEntityType.航空运输电子客票行程单]: {
      name: `${value?.form?.E_system_航空运输电子客票行程单_出发站}--${value?.form?.E_system_航空运输电子客票行程单_到达站}`,
      code: 'E_system_航空运输电子客票行程单_电子客票号码', // 没有发票代码
      type: '机票行程单',
      date: 'E_system_航空运输电子客票行程单_乘机时间',
      amount: 'E_system_航空运输电子客票行程单_金额'
    },
    [InvoiceEntityType.客运汽车发票]: {
      name: `客运汽车发票（${value?.form?.E_system_客运汽车发票_出发车站}--${value?.form?.E_system_客运汽车发票_达到车站}）`,
      code: 'E_system_客运汽车发票_发票号码',
      type: '客运汽车票',
      date: 'E_system_客运汽车发票_时间',
      amount: 'E_system_客运汽车发票_金额'
    },
    [InvoiceEntityType.其他发票]: {
      name: '其他票据',
      date: 'E_system_其他_日期',
      type: '其他票据',
      amount: 'E_system_其他_金额'
    },
    [InvoiceEntityType.医疗发票]: {
      name: '医疗发票',
      date: 'E_system_医疗发票_开票日期',
      type: '医疗发票',
      code: 'E_system_医疗发票_票据号码',
      amount: 'E_system_医疗发票_金额合计'
    },
    [InvoiceEntityType.消费小票]: {
      name: value?.form?.E_system_消费小票_店名 || '无法识别店名',
      type: '消费小票',
      date: 'E_system_消费小票_时间',
      amount: 'E_system_消费小票_金额'
    },
    [InvoiceEntityType.机打发票]: {
      name: 'E_system_机打发票_销售方名称',
      code: 'E_system_机打发票_发票号码',
      type: '机打发票',
      date: 'E_system_机打发票_时间',
      amount: 'E_system_机打发票_金额'
    }
  }
  const oo = mapItem[entityId]
  const invoiceType =  value?.form?.[oo.type] || oo.type
  let invoiceTypeText = INVOICE_TYPE()?.[invoiceType] ?? IDENTIFY_INVOICE_TYPE?.()?.[invoiceType] ?? invoiceType

  if (invoiceTypeText === 'E_system_发票主体_发票类别') {
    invoiceTypeText = i18n.get('增值税发票')
  }

  return {
    name: value?.form?.[oo.name] || oo.name,
    time: value?.createTime,
    entityId,
    type: invoiceType,
    typeName: invoiceTypeText,
    code: value?.form?.[oo.code],
    amount: value?.form?.[oo.amount],
    date: oo.date ? formatDate(value?.form?.[oo.date], 'YYYY年MM月DD日') : undefined
  }
}

export const getDiffsBetweenInvoices = (curDetails, prevDetails) => {
  let curInvoices = []
  let preInvoices = []
  curDetails.map(item => {
    const invoices = getV(item, 'feeTypeForm.invoiceForm.invoices', [])
    const attachments = getV(item, 'feeTypeForm.invoiceForm.attachments', [])
    curInvoices = curInvoices.concat(invoices).concat(attachments)
  })
  prevDetails.map(item => {
    const invoices = getV(item, 'feeTypeForm.invoiceForm.invoices', [])
    const attachments = getV(item, 'feeTypeForm.invoiceForm.attachments', [])
    preInvoices = preInvoices.concat(invoices).concat(attachments)
  })
  console.log(curInvoices, preInvoices)
  const curRes = curInvoices.map((item, index) => {
    const fileKey = item?.key // 发票照片
    // 发票id + 更新时间 作为唯一
    const id = item?.master?.id
    const updateTime = item?.master?.updateTime
    const entityId = item?.master?.entityId
    const prev = preInvoices.find(item => {
      if (fileKey) {
        return item?.key == fileKey
      } else {
        return item?.master?.id == id && item?.master?.updateTime == updateTime
      }
    })
    // 如果以前不存在，则是新增的
    if (!prev) {
      return {
        ...formatInvoice(item),
        type: 'add',
      }
    } else {
      // 假如价格相同，则视为没有变更
      if (prev?.master?.form?.['E_' + entityId + '_发票号码'] == item?.master?.form?.['E_' + entityId + '_发票号码']) {
        return null
      }
      return {
        ...formatInvoice(item),
        before: formatInvoice(prev),
        type: 'change',
      }
    }
  })
  const prevRes = preInvoices.map((item, index) => {
    const fileKey = item?.key // 发票照片
    // 发票id + 更新时间 作为唯一
    const id = item?.master?.id
    const updateTime = item?.master?.updateTime
    const entityId = item?.master?.entityId
    const cur = curInvoices.find(item => {
      if (fileKey) {
        return item?.key == fileKey
      } else {
        return item?.master?.id == id && item?.master?.updateTime == updateTime
      }
    })
    // 如果现在不存在，则是删除的
    if (!cur) {
      return {
        ...formatInvoice(item),
        type: 'deled',
      }
    } else {
      return null
    }
  })
  return [...curRes, ...prevRes].filter(Boolean)
}

export const getDiffsBetweenVersions = (type, curVersion, prevVersion) => {
  console.log(curVersion, prevVersion)
  const curDetails = curVersion.value.form.details.reverse()
  const prevDetails = prevVersion.value.form.details
  switch (type) {
    case 'documentType':
      return getDiffsBetweenFeeTypes(curDetails, prevDetails)
    case 'invoice':
      return getDiffsBetweenInvoices(curDetails, prevDetails)
  }
}

export function keepTwoDecimalFull(num, p) {
  let result = parseFloat(num)
  if (isNaN(result)) {
    return num
  }

  let multiplicator = Math.pow(10, p)

  result = Math.round(num * multiplicator) / multiplicator;

  let s_x = result.toString()

  let pos_decimal = s_x.indexOf('.')

  if (pos_decimal < 0) {
    pos_decimal = s_x.length
    s_x += '.'
  }
  while (s_x.length <= pos_decimal + p) {
    s_x += '0'
  }
  return s_x
}

export async function getObjById(feeTypeForm, components) {
  const types = ['select', 'dataLink']
  const list = []
  const map = {}
  components?.length &&
    components?.forEach(item => {
      const type = item.type
      const value = feeTypeForm[item.field]
      if (types.includes(type) && value) {
        isArray(value)
          ? value.forEach(line => {
              list.push({ ref: item.field, id: isObject(line) ? line?.id : line })
            })
          : list.push({ ref: item.field, id: isObject(value) ? value?.id : value })
      }
    })
  if (!list.length) return feeTypeForm
  let result = await api.invokeService('@bills:get:metaby:ids', { value: list })
  result &&
    result?.items?.forEach(line => {
      const u = list?.find(item => item?.id === line.id)
      map[u.ref] = line
    })
  return map
}

export function splitTemplateToGroups(template = []) {
  if (!template.length) return { groupTemplate: template }
  const groupsCount = template.filter(item => item.type === 'group').length
  const groupsFirstPosition = template.findIndex(item => item.type === 'group')
  // 无分组组件或者有分组组件且分组组件在第一位且只有一个分组组件
  if (groupsCount === 0 || (groupsFirstPosition === 0 && groupsCount === 1)) {
    return {groupTemplate: template, isGroup: false}
  }
  // 有多个分组情况
  const groupTemplate = []
  let groupPosition = 0
  template.forEach((item, index) => {
    if (item.type === 'group' && index !== 0) {
      groupTemplate.push(template.slice(groupPosition, index))
      groupPosition = index
    }
  })
  // 取最后的分组
  groupTemplate.push(template.slice(groupPosition, template.length))
  return { groupTemplate, isGroup: true }
}

// 获取分摊预设规则
export const fnGetQualificationForm = (specification, feeTypeInfo, feetypeTemplate, isBatch = false) => {
  const apportionConfig = getV(specification, 'value.configs', []).find(el => el?.ability === 'apportion')
  const dataLinkFilter = getV(apportionConfig, 'apportionConfig.dataLinkFilter', [])

  const qualificationForm = []
  if (isBatch && dataLinkFilter.some(v => v?.right?.type === 'BILL_DETAIL_FIELD')) {
    let errorMsg = i18n.get('该分摊方式不支持批量分摊，请更换或在费用明细中添加分摊。')
    toast.error(errorMsg)
  } else {
    dataLinkFilter.forEach(el => {
      const type = el?.right?.type
      const value = el?.right?.value
      if (type === 'BILL_DETAIL_FIELD' && value && feetypeTemplate?.find(v => (v.name || v.field) === value)) {
        let val = ''
        if (feeTypeInfo?.[value]?.toString()) {
          let feeInfoValueType = Object.prototype.toString.call(feeTypeInfo?.[value])
          if (feeInfoValueType.includes('Array')) {
            let valueType = Object.prototype.toString.call(feeTypeInfo[value][0])
            if (valueType.includes('Object')) {
              val = feeTypeInfo[value].map(el => el?.id || '')
            } else {
              val = feeTypeInfo[value]
            }
          } else if (feeInfoValueType.includes('Object')) {
            if (feeTypeInfo[value]?.id) {
              val = feeTypeInfo[value]?.id || ''
            }
            if (feeTypeInfo[value]?.standard) {
              val = feeTypeInfo[value]?.standard || ''
            }
          } else {
            val = feeTypeInfo[value]
          }
        }
        qualificationForm.push({
          type: el?.right?.type,
          field: Array.isArray(value) ? value?.[0] || '' : value,
          value: val
        })
      }
    })
  }
  return qualificationForm
}

// 根据分摊预设规则进行分摊
export const fnApportionDetails = (details, billSpecification) => {
  // 分摊预设规则需要提交人id
  const userinfo = api.getState()['@common'].userinfo
  const currentStaffId = getV(userinfo, 'staff.id')
  details.forEach(async detail => {
    const { feeTypeForm, specificationId, feeTypeId } = detail
    // 获取分摊模板
    const detailComponents = getV(detail, 'feeTypeId.expenseSpecification.components', [])
    const apportion = detailComponents.find(el => el?.type === 'apportions')
    const apportionId = getV(apportion, 'specificationIds[0]', '')
    if (!apportionId) return
    const apport = await getSpecification({ id: apportionId })

    // 获取分摊预设规则
    const qualificationForm = fnGetQualificationForm(apport, feeTypeForm, specificationId?.components)
    //
    const params = {
      submitterId: currentStaffId,
      specificationId: billSpecification.id,
      feeTypeId: feeTypeId?.id,
      apportionName: specificationId?.name,
      apportionId: apport?.value?.id,
      qualificationForm
    }

    const res = await api.invokeService('@bills:get:getShareListApi', params)
    let { apportions } = res.value;
    apportions = fnPercentageApportion(apportions, feeTypeForm?.amount);

    detail.feeTypeForm.apportions = apportions.map(el => {
      return {
        apportionForm: el,
        specificationId: apport.value
      };
    });
  })
  return details
}

// 根据分摊比例分摊
export const fnPercentageApportion = (data, amount) => {
  if (!data) return null
  const feeAmountBig = new Big(amount.standard)
  const FIXED_VALUE = amount.standardScale
  const length = data?.length
  let totalAmountNoLast = 0
  let smallThanZero
  let result = data.map((line, index) => {
    let percentBig = new Big(line.apportionPercent).div(new Big(100))
    let apportionMoneyStr = percentBig.times(feeAmountBig).toFixed(FIXED_VALUE) //本位币
    if (index === length - 1) {
      apportionMoneyStr = new Big(feeAmountBig).minus(totalAmountNoLast).toFixed(Number(FIXED_VALUE))
      if (apportionMoneyStr < 0) {
        smallThanZero = true
      }
    }
    totalAmountNoLast = new Big(totalAmountNoLast).plus(apportionMoneyStr).toFixed(Number(FIXED_VALUE))
    line.apportionMoney = standardCurrencyToForeign(
      apportionMoneyStr,
      line.apportionMoney || standardValueMoney('0.00')
    )
    return line
  })

  if (smallThanZero) {
    let totalAmountNoLast = 0
    result = data.map((line, index) => {
      let percentBig = new Big(line.apportionPercent).div(new Big(100))
      let apportionMoneyStr = percentBig
        .times(feeAmountBig)
        .round(FIXED_VALUE, 0)
        .toFixed(FIXED_VALUE)
      if (index === length - 1) {
        apportionMoneyStr = new Big(feeAmountBig).minus(totalAmountNoLast).toFixed(Number(FIXED_VALUE))
      }
      totalAmountNoLast = new Big(totalAmountNoLast).plus(apportionMoneyStr).toFixed(Number(FIXED_VALUE))
      line.apportionMoney = standardCurrencyToForeign(
        apportionMoneyStr,
        line.apportionMoney || standardValueMoney('0.00')
      )
      return line
    })
  }
  return result
}

function percentageApportion({ data = [], feeAmountBig }) {

}

/**
 * 判断单据是否使用了快速报销
 * @param dataSource
 * @returns {Promise<boolean>}
 */
export const checkQuickExpends = async dataSource => {
  // 快速报销直接发起单据过来的dataSource有插入的isQuickExpends，没有dataSource?.id
  let isQuickExpends = get(dataSource, 'requisitionInfo.isQuickExpends', false)
  // 草稿，驳回，提交过的单据有dataSource?.id，没有插入的isQuickExpends
  if (dataSource?.id) {
    // 查询单据明细是否是快速报销明细
    const res = await getDetailFLowRelation(dataSource?.id)
    isQuickExpends = res?.value === 'QUICK_EXPENSE'
  }
  return isQuickExpends
}

/**
 * 根据发票使用规则和单据风险判断提交送审时是否需要填写风险原因
 * @param id 单据id
 * @returns {Promise<T_RiskWarningContentItem[]>}
 */
export const getNeedSubmitRiskReasonList = async id => {
  const result = await Promise.all([
    api.invokeService('@invoice-manage:get:invoice:rule:list'),
    api.invokeService('@bills:get:flow:risk:warning', { id, reasonModify: true })
  ])
  const [invoiceRuleList, riskWarning] = result
  const needSubmitRiskReasonRuleList = invoiceRuleList?.items?.filter(
    item => item.control === 'ALLOW_SUBMIT_SHOW_RISK_EXPLAIN'
  )
  const needSubmitRiskReasonRuleMap = {}
  needSubmitRiskReasonRuleList.forEach(item => {
    needSubmitRiskReasonRuleMap[item.id] = item
  })
  const invoiceNormIdMap = {}
  const needSubmitRiskReasonList = riskWarning?.singleInvoiceRiskWarning?.reduce((result, curRiskWarning) => {
    const { invoiceId, invoiceMsg, relatedFlows, riskWarningReason = [] } = curRiskWarning
    const needSubmitRiskReasonListFilter = riskWarningReason.filter(
      reasonItem => !!needSubmitRiskReasonRuleMap[reasonItem.invoiceNormId]
    )
    needSubmitRiskReasonListFilter.forEach(item => {
      const { invoiceNormId, invoiceNormDesc, invoiceRiskExplainId, invoiceRiskExplainContent } = item
      if (invoiceNormIdMap[invoiceNormId] !== undefined) {
        result[invoiceNormIdMap[invoiceNormId]]?.riskWarningContent.push({
          invoiceNum: invoiceId,
          invoiceMsg,
          invoiceRiskExplainId,
          invoiceRiskExplainContent,
          relatedFlows: relatedFlows ? relatedFlows[invoiceNormDesc] : []
        })
      } else {
        invoiceNormIdMap[invoiceNormId] = result.length
        result.push({
          invoiceNormId,
          invoiceNormDesc,
          riskWarningContent: [
            {
              invoiceNum: invoiceId,
              invoiceMsg,
              invoiceRiskExplainId,
              invoiceRiskExplainContent,
              relatedFlows: relatedFlows ? relatedFlows[invoiceNormDesc] : []
            }
          ]
        })
      }
    })
    return result
  }, [])
  return needSubmitRiskReasonList
}

/**
 * 为 BillVersionDiffModal 展示发票风险组装数据
 * @params 通过 @bills:get:flow:risk:warning 获取的原始的风险数据
 * @returns 组装好的备份数据
 */
export const getRiskReasonDataForVersionDiffModal = riskData => {
  if (!riskData || !riskData.value) return riskData
  const riskDataClone = cloneDeep(riskData)
  const { singleInvoiceRiskWarning = [], value } = riskDataClone
  const riskReasonArr = []
  singleInvoiceRiskWarning?.forEach(riskWarningItem => {
    const { invoiceMsg, riskWarningReason } = riskWarningItem
    if (riskWarningReason?.length) {
      riskWarningReason.forEach(reasonItem => {
        const { invoiceNormDesc, invoiceRiskExplainContent } = reasonItem
        if (invoiceRiskExplainContent) {
          riskReasonArr.push({
            invoiceMsg,
            invoiceNormDesc,
            invoiceRiskExplainContent
          })
        }
      })
    }
  })
  value?.riskWarning?.forEach(riskWarningItem => {
    const { controlName, messages = [] } = riskWarningItem
    riskReasonArr
      .filter(item => `${item.invoiceNormDesc}：` === controlName)
      .forEach(item => {
        let index = messages.findIndex(message => item.invoiceMsg === message)
        if (index !== -1) {
          messages.splice(index + 1, 0, i18n.get('原因：{_k0}', { _k0: item.invoiceRiskExplainContent }))
        }
      })
  })
  return riskDataClone
}
