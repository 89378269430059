import { __awaiter, __generator } from "tslib";
import { Fetch } from '@ekuaibao/fetch';
import { Store } from '@ekuaibao/i18n';
import brandData from '../i18n/brand';
export function initialize() {
    return __awaiter(this, void 0, void 0, function () {
        var store, scope;
        return __generator(this, function (_a) {
            store = Store.getInstance();
            store.useDefaultLocale(determineLocale());
            store.useLocaleDataLoader(function (language) {
                return Fetch.GET("locales/" + language + ".json", null, { isText: true });
            });
            scope = IS_HSFK
                ? 'hose'
                : IS_ICBC
                    ? 'icbc'
                    : 'ekuaibao';
            store.addScopeVariables(brandData);
            store.changeScope(scope);
            return [2, store.initialize()];
        });
    });
}
export function determineLocale() {
    var backLanguage = Fetch.staffSetting && Fetch.staffSetting.language;
    if (Fetch.ekbCorpId === 'wJEazW8N980000')
        backLanguage = 'en-US';
    if (backLanguage) {
        Fetch.lang = backLanguage;
        Fetch.defaultLanguage = backLanguage;
        return backLanguage;
    }
    else {
        backLanguage = window.navigator && window.navigator.language;
        backLanguage = /en/.test(backLanguage) ? 'en-US' : 'zh-CN';
        Fetch.lang = backLanguage;
        Fetch.defaultLanguage = backLanguage;
        return backLanguage;
    }
}
