import { filterFromUrl } from '../lib/lib-util';
import { localCacheGet, session } from '@ekuaibao/session-info';
import { app } from '@ekuaibao/whispered';
import { Fetch } from '@ekuaibao/fetch';
export function initRoute() {
    var noPathCache = filterFromUrl('noPathCache');
    var locationHash = location.hash.slice(1);
    var blackList = ['/login', '/auth-check', '/selectEnterprise5', '/mc-error'];
    var shouldNotRedirect = !!~blackList.indexOf(locationHash);
    if (shouldNotRedirect || noPathCache) {
        return;
    }
    if (!Fetch.ekbCorpId && (window.__PLANTFORM__ === 'APP' || window.__PLANTFORM__ === 'THIRDPARTY')) {
        initFetchEkbCorpId();
    }
    var beforePagePathKey = 'beforePagePath';
    var beforePage = decodeURIComponent(localCacheGet(beforePagePathKey, true));
    var cannotInit = !beforePage || !beforePage.startsWith('/');
    if (cannotInit) {
        window.__PLANTFORM__ === 'MC' ? (beforePage = '/mc-tenant-list') : (beforePage = '/new-homepage');
    }
    app.useHistory({
        initialEntries: [beforePage],
        search: location.search.slice(1)
    });
}
function initFetchEkbCorpId() {
    var ekbCorpId = filterFromUrl('ekbCorpId');
    if (!ekbCorpId) {
        var userInfo = session.get('user');
        if (userInfo) {
            ekbCorpId = userInfo.corpId;
        }
    }
    if (ekbCorpId) {
        Fetch.ekbCorpId = ekbCorpId;
    }
}
