import './EditableCell.less';
import React from 'react';
import Money from '../../../elements/puppet/Money';
import { formatDisplayValue } from '../helper/formatTableData';
import classnames from 'classnames';
export default function ApportionReadOnlyCell(props) {
    var value = props.value, dataIndex = props.dataIndex, type = props.type, model = props.model;
    if (!value && value != 0) {
        return null;
    }
    if (dataIndex === 'money') {
        var isShowForeign = value && value.foreign;
        return React.createElement(Money, { value: value, valueSize: 14, isShowForeign: !!isShowForeign });
    }
    return (React.createElement("span", { className: classnames({ 'editable-td_cell-fs': model === 'credit' }) }, formatDisplayValue(value, type, dataIndex, model)));
}
