import { __awaiter, __generator } from "tslib";
import { Fetch } from '@ekuaibao/fetch';
import { get } from 'lodash';
var TokenData = (function () {
    function TokenData() {
        var _this = this;
        this.cleanToken = function () {
            _this._data = null;
        };
        this.tokenIsExpired = function (fileServiceType) {
            var isAliOss = fileServiceType === 'alioss';
            if (!isAliOss) {
                return false;
            }
            var expirationTime = get(_this._data, 'expirationTime', 0);
            var nowTime = new Date();
            return nowTime.getTime() > expirationTime;
        };
    }
    TokenData.prototype.data = function (fileServiceType) {
        return __awaiter(this, void 0, void 0, function () {
            var token, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(!this._data || this.tokenIsExpired(fileServiceType))) return [3, 2];
                        return [4, Fetch.GET('/api/v1/attachment/attachments/token').then(function (_a) {
                                var value = _a.value;
                                return value;
                            })];
                    case 1:
                        token = _a.sent();
                        this._data = token;
                        _a.label = 2;
                    case 2: return [2, this._data];
                    case 3:
                        e_1 = _a.sent();
                        return [2, Promise.reject(e_1)];
                    case 4: return [2];
                }
            });
        });
    };
    TokenData.getInstance = function () {
        if (!TokenData.instance) {
            TokenData.instance = new TokenData();
        }
        return TokenData.instance;
    };
    return TokenData;
}());
export default TokenData;
