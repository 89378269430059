import { __assign, __read, __spread } from "tslib";
import React from 'react';
import { Popover, Tooltip } from 'antd';
import moment from 'moment';
import { getStaffShowByConfig } from '../../../../elements/utilFn';
export function getFormat(withTime) {
    var types = ['YYYY/MM/DD', 'HH:mm:ss'];
    return withTime ? types.join(' ') : types[0];
}
export function getColumns(type) {
    var columnsWithLogs = [
        {
            title: i18n.get('操作时间'),
            label: i18n.get('操作时间'),
            width: 180,
            dataType: 'text',
            dataIndex: 'createTime',
            render: function (data) { return moment(data).format(getFormat(true)); }
        },
        {
            title: i18n.get('操作人'),
            label: i18n.get('操作人'),
            width: 160,
            dataType: 'text',
            dataIndex: 'operator.name',
            render: function (data, record) {
                var name = getStaffShowByConfig(record === null || record === void 0 ? void 0 : record.operator);
                return (React.createElement(Popover, { content: name }, name));
            }
        },
        {
            title: i18n.get('渠道'),
            label: i18n.get('渠道'),
            width: 100,
            dataType: 'text',
            dataIndex: 'logSource',
            render: function (data) {
                switch (data) {
                    case 'WRITE':
                        return React.createElement(React.Fragment, null, i18n.get('录入'));
                    case 'FLOW':
                        return React.createElement(React.Fragment, null, i18n.get('单据写入'));
                    case 'MANUAL':
                        return React.createElement(React.Fragment, null, i18n.get('手动'));
                    case 'CALCULATION':
                        return React.createElement(React.Fragment, null, i18n.get('自动计算'));
                    case 'RECEIPTDATA':
                        return React.createElement(React.Fragment, null, i18n.get('内部归集'));
                    case 'EBOT':
                    case 'EXCEL':
                    case 'API':
                    default:
                        return React.createElement(React.Fragment, null, data);
                }
            }
        },
        {
            title: i18n.get('变更方式'),
            label: i18n.get('变更方式'),
            width: 100,
            dataType: 'text',
            dataIndex: 'logType',
            render: function (data) {
                if (data === i18n.get('INSERT')) {
                    return React.createElement("div", { className: 'history_logType_add' }, i18n.get('新增'));
                }
                else {
                    return React.createElement("div", { className: 'history_logType_edit' }, i18n.get('修改 '));
                }
            }
        },
        {
            title: i18n.get('变更内容'),
            label: i18n.get('变更内容'),
            width: 160,
            dataType: 'text',
            dataIndex: 'logs',
            render: function (data, record) {
                if (record.logType === i18n.get('INSERT')) {
                    return '-';
                }
                return data.map(function (item, arr, index) {
                    var fieldName = item.fieldName, _a = item.field, field = _a === void 0 ? {} : _a;
                    var fieldItem = getCurrentField(fieldName, __assign({}, field));
                    return React.createElement("div", { className: "history_box history_box_content", key: index },
                        React.createElement("span", null, !!fieldItem ? fieldItem.label : item.fieldLabel),
                        React.createElement("br", null));
                });
            }
        },
        {
            title: i18n.get('变更前'),
            label: i18n.get('变更前'),
            dataType: 'text',
            dataIndex: 'logs',
            width: 300,
            render: function (data, record) {
                if (record.logType === i18n.get('INSERT')) {
                    return '-';
                }
                return data.map(function (item) {
                    var fieldName = item.fieldName, _a = item.field, field = _a === void 0 ? {} : _a;
                    var fieldItem = getCurrentField(fieldName, __assign({}, field));
                    if (!fieldItem) {
                        return getFieldComponent('text', item.fieldLabel);
                    }
                    var content = (!!item.beforeContent || item.beforeContent === false) ? getFieldComponent(fieldItem, item.beforeContent) : getFieldComponent('nomatch', item.beforeContent);
                    return content;
                });
            }
        },
        {
            title: i18n.get('变更后'),
            label: i18n.get('变更后'),
            dataType: 'text',
            dataIndex: 'logs',
            width: 300,
            render: function (data, record, index) {
                if (record.logType === i18n.get('INSERT')) {
                    return '-';
                }
                return data.map(function (item) {
                    var fieldName = item.fieldName, _a = item.field, field = _a === void 0 ? {} : _a;
                    var fieldItem = getCurrentField(fieldName, field);
                    if (!fieldItem) {
                        return getFieldComponent('text', item.fieldLabel, true);
                    }
                    var content = (!!item.afterContent || item.afterContent === false) ? getFieldComponent(fieldItem, item.afterContent, true) : getFieldComponent('nomatch', item.afterContent, true);
                    return content;
                });
            }
        }
    ];
    if (type === 'PRIVATE_CAR') {
        return columnsWithLogs.filter(function (v) { return v.dataIndex !== 'logSource'; });
    }
    return columnsWithLogs;
}
export function getCurrentField(name, field) {
    if (name === 'ownerId') {
        return { type: 'ownerId', label: i18n.get('负责人') };
    }
    if (name === 'visibility') {
        return { type: 'visibility', label: i18n.get('参与人') };
    }
    if (name === 'active') {
        return { type: 'active', label: i18n.get('停启用状态') };
    }
    var _a = field.type, type = _a === void 0 ? '' : _a;
    if (type === 'ref') {
        var entity = field.entity;
        field.type = !!entity ? getRefType(entity) : field.type;
    }
    if (type === 'list') {
        var elemType = field.elemType;
        field.type = !!elemType && elemType.type !== 'location' ? getListType(elemType) : field.type;
    }
    return field;
}
export function getRefType(entity) {
    if (entity === void 0) { entity = ''; }
    if (entity.startsWith('pay.PayeeInfo')) {
        return 'ref:pay.PayeeInfo';
    }
    else if (entity.startsWith('datalink.DataLinkEntity')) {
        return 'ref:datalink.DataLinkEntity';
    }
    else if (entity.startsWith('basedata.Dimension')) {
        return 'ref:basedata.Dimension';
    }
    else if (entity.startsWith('basedata.city')) {
        return 'ref:basedata.city';
    }
    else if (entity.startsWith('organization.Staff')) {
        return 'ref:organization.Staff';
    }
    else {
        return entity;
    }
}
export function getListType(elemType) {
    var entity = elemType.entity, type = elemType.type;
    if (entity === 'organization.Staff' && type === 'ref') {
        return 'list:organization.Staff';
    }
    else if (type === 'attachment') {
        return 'list:attachment';
    }
    else if (entity.startsWith('datalink.DataLinkEntity') && type === 'ref') {
        return 'list:datalink.DataLinkEntity';
    }
    else {
        return 'list';
    }
}
export function getFieldComponent(field, detail, isAfter) {
    if (isAfter === void 0) { isAfter = false; }
    var type = field.type, _a = field.withTime, withTime = _a === void 0 ? false : _a, unit = field.unit;
    var formatWithTime = getFormat(withTime);
    var html;
    if (detail === '-') {
        return React.createElement("div", { className: 'history_box' }, '-');
    }
    switch (type) {
        case 'text':
        case 'autoNumber':
        case 'list:attachment':
            html = !!detail ? detail : '-';
            break;
        case 'number':
            html = !!detail ? "" + detail + unit : '-';
            break;
        case 'date':
            html = !!detail ? moment(detail).format(formatWithTime) : '-';
            break;
        case 'money':
            var standard = detail.standard, standardSymbol = detail.standardSymbol;
            html = !!detail ? "" + standardSymbol + standard : '-';
            break;
        case 'list':
            var str_1 = '';
            !!detail && detail.forEach(function (ele, index, arr) {
                var showName = ele.name || ele.address || '-';
                if (index === arr.length - 1) {
                    str_1 += "" + showName;
                }
                else {
                    str_1 += showName + ",";
                }
            });
            str_1 = React.createElement(Tooltip, { placement: "topLeft", title: str_1 }, str_1);
            if (!detail || !detail.length) {
                str_1 = '-';
            }
            html = str_1;
            break;
        case 'location':
            var loc = detail.name || detail.address;
            html = !!loc ? React.createElement(Tooltip, { placement: "topLeft", title: loc }, loc) : '-';
            break;
        case 'switcher':
        case 'active':
            html = !!detail ? i18n.get('开启') : i18n.get('关闭');
            break;
        case 'ownerId':
        case 'ref:organization.Staff':
        case 'organization.Department':
            var _b = detail.name, name = _b === void 0 ? '-' : _b;
            html = !!name ? name : '-';
            break;
        case 'visibility':
            var _c = detail.staffs, staffs = _c === void 0 ? [] : _c, _d = detail.roles, roles = _d === void 0 ? [] : _d, _e = detail.departments, departments = _e === void 0 ? [] : _e, fullVisible = detail.fullVisible;
            var arr = __spread(staffs, roles, departments);
            html = fullVisible ? i18n.get('全部人员') : !!arr.length ? getMemberItem(arr) : '-';
            break;
        case 'ref:pay.PayeeInfo':
            html = fixPayeeInfo(detail);
            break;
        case 'ref:basedata.Dimension':
        case 'ref:datalink.DataLinkEntity':
            html = getNameAndCode(detail);
            break;
        case 'list:organization.Staff':
            html = !!detail.length ? getPopoverMember(detail) : '-';
            break;
        case 'ref:basedata.city':
            html = !!detail.length ? getMapItem(detail) : '-';
            break;
        case 'list:datalink.DataLinkEntity':
            html = !!detail.length ? getMutiEntity(detail) : '-';
            break;
        case 'dateRange':
            var start = detail.start, end = detail.end;
            if (!start || !end) {
                html = '-';
            }
            html = "" + moment(start).format(formatWithTime) + i18n.get('～') + moment(end).format(formatWithTime);
            break;
        case 'nomatch':
        default:
            html = '-';
            break;
    }
    var cls = isAfter ? "history_box history_box_after" : "history_box";
    return React.createElement("div", { className: cls }, html);
}
function getPopoverMember(data) {
    return (React.createElement(Popover, { content: getMemberItem.call(this, data) }, getMemberItem(data)));
}
export function getMapItem(data) {
    return data && data.map(function (item, index, arr) {
        return React.createElement(React.Fragment, null,
            getNameAndCode(item),
            index !== arr.length - 1 ? ',' : '');
    });
}
export function getNameAndCode(data) {
    var name = data.name, code = data.code;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null, name),
        !!code && React.createElement("span", { className: 'color-gray' },
            "(",
            code,
            ")")));
}
function getMutiEntityContent(data) {
    return data && data.map(function (item) {
        return React.createElement("div", null, getNameAndCode(item));
    });
}
function getMutiEntity(data) {
    return (React.createElement(Popover, { content: getMutiEntityContent.call(this, data) }, data && data.map(function (item, index, arr) {
        return index === arr.length - 1 ? item.name : item.name + ",";
    })));
}
export function getMemberItem(staffs) {
    return staffs && staffs.map(function (item, index) {
        var _a = item.name, name = _a === void 0 ? '' : _a;
        return React.createElement(React.Fragment, null,
            name,
            " ",
            index !== staffs.length - 1 ? ',' : '');
    });
}
export function fixPayeeInfo(payeeInfo) {
    return (React.createElement(Popover, { content: renderPayDetail.call(this, payeeInfo) },
        React.createElement("div", { className: "account" },
            React.createElement("span", null, payeeInfo.name),
            React.createElement("span", { className: "color-gray" },
                i18n.get("（"),
                i18n.get(payeeInfo.type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户')),
                i18n.get("）")))));
}
export function renderPayDetail(doc) {
    return (React.createElement("div", { className: "ekb-account-info-popover" },
        React.createElement("div", { className: "header" },
            i18n.get(doc.type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户')),
            " | ",
            doc.name),
        React.createElement("div", { className: "body" },
            React.createElement("div", { className: "line1" },
                React.createElement("img", { src: doc.icon }),
                !!doc.bank ? doc.bank : doc.unionBank),
            React.createElement("div", { className: "line2 text-nowrap-ellipsis" }, doc.accountNo),
            React.createElement("div", { className: "line3" }, doc.branch))));
}
