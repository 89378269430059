/**
 *  Created by daiwenjuan on 2018/4/12 下午12:14.
 */

import { get, find, intersection } from 'lodash'
import { app as api } from '@ekuaibao/whispered'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
export function lastSelectValue(field, lastChoice) {
  let { defaultValue = {} } = field
  let type = get(field, 'defaultValue.type', '')
  if (type === 'predefine' && defaultValue.value === 'lastselect' && lastChoice) {
    let obj = lastChoice.find(line => line.fieldName === field.name)
    if (obj) {
      return obj.lastChoiceValue
    }
  }
  return null
}
export function constantValue(field) {
  let { defaultValue = {} } = field
  let type = get(field, 'defaultValue.type', '')
  if (type === 'constant' && defaultValue.value) {
    return get(defaultValue, 'value.id')
  }
  return null
}

export function refPropertyById(props) {
  let { field, lastChoice } = props
  let constVal = constantValue(field)
  if (constVal) {
    return handleGetDataById(field, constVal)
  }
  let lastVal = lastSelectValue(field, lastChoice)
  if (lastVal) {
    return handleGetDataById(field, lastVal)
  }
}
export function handleGetDataById(field, id) {
  const entity = get(field, 'dataType.entity') || get(field, 'dataType.elemType.entity')
  if (entity.startsWith('basedata.Dimension')) {
    return handleGetDimensionById(id)
  }
  if (entity.startsWith('basedata.Settlement')) {
    return api.invokeService('@common:get:staff:dimension', { name: 'basedata.Settlement' }).then(data => {
      let result = data.items.find(item => item.id === id)
      return result
    })
  }
  if (entity === 'organization.Staff') {
    return field.dataType.type === 'list'
      ? api.invokeService('@common:get:staff:by:ids', { ids: id.split(',') }).then(data => {
        return data.items
      })
      : api.invokeService('@bills:get:StaffById', id)
  }
  if (entity.startsWith('basedata.Enum.')) {
    const code = entity.match(/basedata.Enum.(\S*)/)[1]
    return api.invokeService('@common:get:enumitems', code).then(data => {
      return find(data.items, { code: id })
    })
  }

  return Promise.resolve()
}

function handleGetDimensionById(id) {
  return api.invokeService('@bills:get:dimension', id).then(result => {
    if (result && result.items.length > 0) {
      let me = api.getState()['@common'].userinfo.staff
      let item = result.items[0]
      if (!item.visibility.fullVisible && !checkDimensionVisible(me, item.visibility)) {
        return undefined
      }
      return item
    }
  })
}
export function checkDimensionVisible(user, visibility) {
  const { departments, roles, staffs } = visibility

  if (staffs?.includes(user.id)) {
    return true
  }

  //部门可见性无法判断,需要优化这个逻辑
  // let deptIds = user.departments && user.departments.map(v => v.id)
  if (departments && departments.length) {
    return true
  }
  let roleIds = user.roles && user.roles.values.map(v => v.roleDefId)
  if (roleIds && intersection(roleIds, roles).length) {
    return true
  }
  return false
}

export function canSelectParent(field = {}) {
  const { selectRange } = field
  return 'leaf' !== selectRange
}

export function checkDefaultDeptValue(dept, field, submitterId = {}) {
  if (!dept) return dept
  const { id } = dept
  //没有部门数据时返回空值
  const deptMap = api.getState('@common.department').mapData
  if (!deptMap || !Object.keys(deptMap).length) return {}
  let value = deptMap[id]
  //确认是否只可选择末级部门
  const children = getNodeValueByPath(value, 'children', [])
  if (children.length && !canSelectParent(field)) return {}
  //如果在配置中限制了只可选择用户的所在部门, 需要确认部门是否在默认部门中
  return checkBelongDepartment(field, value, submitterId)
}

//检查模版中是否配置了选项，让提交人只能选择其所在部门
export function checkBelongDepartment(field = {}, value, submitterId ={}) {
  if (field && field.onlyBelongDepartment) {
    const submitterDepartments = get(submitterId, 'departments', [])
    if (value && submitterDepartments.length) {
      const submitterDepartmentsArr = submitterDepartments.map(dep => {
        if(typeof dep === 'string') return dep
        return dep.id
      })
      if (!submitterDepartmentsArr.includes(value.id)) return {}
    }
  }
  return value
}

/**
 * @param {Array | Object} tree
 */
export function formatTreeToArray(tree) {
  if (!tree) return []

  let queue = Array.isArray(tree) ? [...tree] : [tree]
  let data = []

  while (queue.length !== 0) {
    let item = queue.shift()

    data.push({
      id: item?.id,
      parentId: item?.parentId,
      name: item?.name
    })

    let children = item?.children

    if (children) {
      for (let i = 0; i < children.length; i++) {
        const element = children[i]
        queue.push(element)
      }
    }
  }
  return data
}

/**
 * 档案添加完整路径
 * @param {array} list
 * @param {string} fullPathName
 */
export function addFullPath(list = [], fullPathName = '') {
  list.forEach(el => {
    el.fullPath = fullPathName ? `${fullPathName}/${el.name}` : el.name
    if (el.children) {
      el.children = addFullPath(el.children, el.fullPath)
    }
    return el
  })
  return list
}
