import styles from './InvoiceItem.module.less'
import React, { PureComponent, useState } from 'react'
import moment from 'moment'
import { Button, Checkbox, Tooltip, Popconfirm, Popover } from 'antd'
import InvoiceCard from '../../elements/invoice-card'
import { app as api } from '@ekuaibao/whispered'
import { formatInvoiceData } from './FormatInvoiceData'
import EKBIcon from '../../elements/ekbIcon'
import Money from '../puppet/Money'
import SVG_INVOICE_TEXT from '../../images/icon-file-text.svg'
import SVG_INVOICE_TOKEN from '../../images/qkl.svg'
import SVG_INVOICE_PDF from '../../images/icon-file-pdf.svg'
import SVG_WARNING from '../../images/warning.svg'
import { groupBy, get, cloneDeep } from 'lodash'
import { INVOICE_TYPE, IMG_REG } from '@ekuaibao/lib/lib/enums'
import CopyToClipboard from 'react-copy-to-clipboard'
import { showModal, showMessage } from '@ekuaibao/show-util'
import InvoiceTaxInfo from '../CarouselInvoiceReviewer/utils/InvoiceTaxInfo'
import { isIE } from '../../lib/misc'
import { TupleInvoiceType } from '../ConditionalEditComponent/Type'
import { T } from '@ekuaibao/i18n'
import { invoiceMetaile } from '@ekuaibao/lib/lib/invoice/invoiceHelper'
import InvoiceUploadView from '../../plugins/bills/views/InvoiceUploadView'
import classNames from 'classnames'
import { submitDisableInvoice, submitMarkInvoice } from '../../plugins/bills/bills.action'
import { isHongShanTestingEnterprise } from '@ekuaibao/lib/lib/help'
import { Fetch } from '@ekuaibao/fetch'
const IMAGE_MAP = new Map()

const InvoiceMarkDisableType = {
  MARK: "mark",
  DISABLE: "disable"
}

class AddInvoice extends PureComponent {
  constructor(props) {
    super(props)
    let isShowInvoice = props.invoiceNum && props.invoiceNum === 1
    this.state = {
      isShowInvoice
    }
  }

  handleLookClick = e => {
    e && e.stopPropagation && e.stopPropagation()
    e && e.preventDefault && e.preventDefault()
    this.setState({
      isShowInvoice: !this.state.isShowInvoice
    })
  }

  HandleDownloadPDF = (props, e) => {
    e && e.stopPropagation && e.stopPropagation()
    e && e.preventDefault && e.preventDefault()
    let { invoiceInfo } = formatInvoiceData({ data: props })
    const file = IMAGE_MAP.get(get(invoiceInfo, 'id'))
    api.invokeService('@common:download:invoce:pdf', invoiceInfo, file)
  }

  handleUploadMetaileResponse = invoice => {
    const { index, onUploadInvoiceMetaile } = this.props
    onUploadInvoiceMetaile(index, invoice)
  }

  render() {
    const { isShowInvoice } = this.state
    const {
      bus,
      item,
      submitterId,
      isEdit,
      isInvoiceManagePermissions,
      isReadOnlyPage,
      onChange,
      checkout,
      permissions,
      onOpenEditModal,
      canSeeLink = false,
      isHover,
      billState,
      isInHistory,
      index,
      onRetryCheckerInvoiceClick,
      isEditAuthenticity = true,
      modifyApproveMoney,
      showPriceAndRate = true,
      checkInvoiceAgain,
      showCheckAginButton
    } = this.props
    const { invoiceInfo } = formatInvoiceData({ data: item, dateFormat: i18n.get('YYYY年MM月DD日') })
    // @ts-ignore
    const { E_税额, E_是否抵扣 } = item.master.form
    const source = invoiceInfo && invoiceInfo.source
    //@i18n-ignore
    const aliPayPDF = get(item, 'master.form.E_system_发票主体_attachmentType', '')
    //@i18n-ignore
    const ocrPDF = get(item, 'master.form.E_system_发票主体_图片', '')
    const { invamt, masterItems, type } = invoiceInfo
    const isFullDigital = ['FULL_DIGITAl_SPECIAL', 'FULL_DIGITAl_NORMAL'].includes(type) //全电票
    const hasPdf =
      source === 'UPLOAD' ||
      source === 'WECHAT_CARD' ||
      (source === 'ALIPAY_CARD' && aliPayPDF === 'PDF') ||
      (source === 'OCR' && ocrPDF.toLowerCase().endsWith('.pdf')) ||
      (source === 'AIFAPIAO' && ocrPDF.toLowerCase().endsWith('.pdf')) ||
      (isFullDigital && ocrPDF)
    const seller = masterItems.find(line => line.type === 'seller')
    const title = seller ? seller.value : masterItems[0].value
    const invoiceName = INVOICE_TYPE()[type]
    const time = masterItems.filter(i => i.type === 'date')[0].value
    let clsC = 'invoice-item-yellowType'
    const isInvoice = get(item, 'master.entityId', '') === 'system_发票主体'
    // @i18n-ignore
    const isCheckerInvoice = isEditAuthenticity ? get(item, 'master.form.E_system_发票主体_验真') : true
    const { shoMetaileAction } = invoiceMetaile(item?.master)
    // 复用一下isEditAuthenticity，在指定的页面不可以上传原件
    const isShowMetaileAction = isEditAuthenticity ? shoMetaileAction : false
    const cls = getClassNameOfInvoiceCardWrap(item)
    const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(this.props)
    const {showMark, showDisable} = invoiceDisableMarkStatus

    return (
      <div className={styles[cls]}>
        <div className="invoice-item-yellow">
          {isEdit && <EditDelete data={this.props} isEditCard={false} />}
          <InvoiceMid
            title={title}
            allMoney={invamt}
            info={time}
            {...this.props}
            isEditCard={false}
            showPriceAndRate={showPriceAndRate}
            typeText={invoiceName || i18n.get('增值税发票')}
            typeTextCls={clsC}
          />
          {(isReadOnlyPage || !isEdit) && (
            <InvoiceOtherInfo
              item={item}
              modifyApproveMoney={modifyApproveMoney}
              billState={billState}
              isInHistory={isInHistory}
              isHover={isHover}
              isInvoiceManagePermissions={isInvoiceManagePermissions}
              allMoney={invamt}
              taxAmount={E_税额}
              isDeduction={E_是否抵扣}
              onChange={onChange}
              onOpenEditModal={e => onOpenEditModal(item)}
              source={invoiceInfo.type}
              showPriceAndRate={showPriceAndRate}
            />
          )}
          {!isShowInvoice && <div className="invoice_line" />}
          {isShowInvoice && (
            <InvoiceCard
              className={'invoice_distance'}
              invoice={item}
              bus={bus}
              submitterId={submitterId}
              hiddenHeader={true}
              checkout={checkout}
              permissions={permissions}
              canSeeLink={canSeeLink}
            />
          )}
          <div className="invoice-item-wrap">
            <div className="look-and-open">
              <div className="invoice-item-open" onClick={this.handleLookClick}>
                {isShowInvoice ? i18n.get('收起明细') : i18n.get('展开明细')}
              </div>
              {hasPdf && (
                <span className="invoice-item-look" onClick={this.HandleDownloadPDF.bind(this, item)}>
                  {isFullDigital ? i18n.get('查看文件') : i18n.get('查看PDF')}
                </span>
              )}
            </div>
            <div className="right-action">
              {isShowMetaileAction ? (
                <InvoiceUploadView
                  invoiceId={item?.invoiceId || item?.master?.id}
                  children={<div className="checker-invoice">{i18n.get('上传原文件')}</div>}
                  onResult={this.handleUploadMetaileResponse}
                />
              ) : null}
              <InvoiceToDisableOrMark
                {...this.props}
                showDisable={showDisable}
                showMark={showMark} />
              {isInvoice && !isCheckerInvoice && (
                <div
                  className={classNames('checker-invoice', { 'ml-8': shoMetaileAction })}
                  onClick={() => onRetryCheckerInvoiceClick(index, item)}
                >
                  {i18n.get('点击验真')}
                </div>
              )}
              {showCheckAginButton && !isFullDigital && (
                <Button onClick={() => checkInvoiceAgain(index, item)} className="checkAgain" type="primary">{i18n.get('重新查验')}</Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function MedicalInvoice(props) {
  const { item, isEdit, isReadOnlyPage, onChange } = props
  const { master } = item
  const {
    E_system_医疗发票_开票日期,
    E_system_医疗发票_金额合计,
    E_system_发票主体_来源,
    E_system_医疗发票_发票种类
  } = master.form
  const title = E_system_医疗发票_发票种类
  const info = moment(E_system_医疗发票_开票日期).format(i18n.get('YYYY年MM月DD日'))
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const { showMark, showDisable } = invoiceDisableMarkStatus

  return (
    <div className={styles[cls]}>
      <div className={'invoice-item-yellow'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          item={item}
          allMoney={E_system_医疗发票_金额合计}
          title={title}
          info={info}
          {...props}
          typeText={i18n.get('医疗发票')}
          typeTextCls={'invoice-item-machineType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo item={item} {...props} allMoney={E_system_医疗发票_金额合计} />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapItem = {
  system_发票主体: AddInvoice,
  system_医疗发票: MedicalInvoice,
  system_出租车票: TaxiInvoice,
  system_过路费发票: TollInvoice,
  system_定额发票: QuotaInvoice,
  system_火车票: TrainInvoice,
  system_航空运输电子客票行程单: PlaneInvoice,
  system_客运汽车发票: BusInvoice,
  system_其他: OtherInvoice,
  system_消费小票: ShoppingInvoice,
  system_机打发票: MachineInvoice,
  invoicePhoto: InvoicePhotoView
}

const invoiceTaxInfo = new InvoiceTaxInfo()
export default function(props) {
  const {
    dataSource,
    isEdit,
    bus,
    onDeleteItem,
    onOpenEditInvoice,
    invoiceImgList,
    handleImgPreview,
    isReadOnlyPage,
    onOpenEditModal,
    isInvoiceManagePermissions,
    isHover,
    onOpenEditReviewAmount,
    isInHistory = false
  } = props
  let imgList = groupBy(invoiceImgList, 'id', [])
  return dataSource.map((item, index) => {
    const { attachment, master, type } = item
    const entityId = master && master.entityId
    const id = master && master.id
    let entity = entityId || type
    const Component = mapItem[entity]
    const imgInfo = attachment ? attachment : imgList[id] ? imgList[id][0] : undefined
    return (
      <Component
        {...props}
        isEdit={isEdit}
        bus={bus}
        item={item}
        key={id}
        isInHistory={isInHistory}
        index={index}
        imgInfo={imgInfo}
        onDeleteItem={onDeleteItem}
        handleImgPreview={handleImgPreview}
        onOpenEditInvoice={onOpenEditInvoice}
        isReadOnlyPage={isReadOnlyPage}
        isHover={isHover}
        isInvoiceManagePermissions={isInvoiceManagePermissions}
        onOpenEditModal={() => onOpenEditModal(item)}
        onOpenEditReviewAmount={() => onOpenEditReviewAmount(item)}
      />
    )
  })
}

function handleDeleteItem(data) {
  const { item, onDeleteItem, isRecordExpends, isQuickExpends } = data
  if (isHongShanTestingEnterprise(Fetch.ekbCorpId)
      && !isRecordExpends
      && !isQuickExpends
  ) {
    showMessage.info(i18n.get('删除后，若需要重新添加该发票，请先将此单据存为草稿或直接提交后进行'))
  }
  onDeleteItem && onDeleteItem(item)
}

function EditDelete(props) {
  const { data, isEditCard = true } = props
  const { onOpenEditInvoice, item, index, imgInfo, dataSource } = data
  return (
    <div className="edit-delete">
      {isEditCard && (
        <EKBIcon
          name="#EDico-edit"
          onClick={handleOpenEditInvoice.bind(this, { dataSource, item, index, imgInfo, onOpenEditInvoice })}
          className="icon-edit"
        />
      )}
      <EKBIcon name="#EDico-delete"
               onClick={handleDeleteItem.bind(this, data)}
               className="icon-delete" />
    </div>
  )
}

function handleOpenEditInvoice(args) {
  const { dataSource, item, index, imgInfo, onOpenEditInvoice } = args
  onOpenEditInvoice && onOpenEditInvoice(dataSource, item, index, imgInfo)
}

// 获取当前发票的提示内容
function getContent(warningMsg) {
  return (
    <ul>
      {warningMsg.map((v, i) => {
        return <li key={i}>{v}</li>
      })}
    </ul>
  )
}

function BaseInfoView(props) {
  const {
    title,
    info,
    dataSource,
    imgInfo,
    onOpenEditInvoice,
    item,
    index,
    isEditCard = true,
    isEdit,
    external,
    isForbid,
    isModify,
    riskData,
    isReadOnlyPage
  } = props
  const [className, url] =
    isEdit && !isModify && !isReadOnlyPage ? ['error', '#EDico-plaint-circle'] : ['warning', '#EDico-plaint-circle']
  const warningMsg = riskData?.singleInvoiceRiskWarning?.find(v => v.invoiceId === item?.invoiceId)?.riskWarning

  return (
    <div className="invoice-item-intr">
      <div className="invoice-item-title-container">
        <div
          className="invoice-item-title"
          onClick={
            isEdit
              ? isEditCard
                ? handleOpenEditInvoice.bind(this, { dataSource, item, index, imgInfo, onOpenEditInvoice })
                : null
              : null
          }
        >
          {title}
          {/* 每张发票存在问题的话，单独标示出来 */}
          {warningMsg?.length > 0 && (
            <Popover content={getContent(warningMsg)} title="风险详情" trigger="hover">
              <svg className={`icon ${className}`} aria-hidden="true">
                <use xlinkHref={url} />
              </svg>
            </Popover>
          )}
        </div>
      </div>

      <div className="invoice-item-date">{info}</div>
    </div>
  )
}

function handleImgClick(props, img) {
  const { handleImgPreview, imgInfo } = props
  const temImgInfo = imgInfo || img
  let imageList = {
    fileId: temImgInfo.id,
    key: temImgInfo.fileName || '',
    ...temImgInfo
  }
  handleImgPreview && handleImgPreview(imageList)
}

//type表示发票操作类别，disable表示禁用发票 mark标记发票
function handleDisableClick(props, type) {
  const { isModify, item, onDeleteItem, bus, index, onInvoiceStatusChange } = props
  const invoiceId = get(props, 'item.invoiceId')
  api.open('@bills:InvoiceDisableModal',
    { invoiceId, disableInfo: item?.disableInfo, markInfo: item?.markInfo, type }).then(reason => {
      if (isModify) {
        const newItem = cloneDeep(item)
        newItem.disableInfo.disable = true
        const needDelete = get(item, 'disableInfo.control') === 'FORBID_SUBMIT_DELETE'
        if (needDelete && onDeleteItem) {
          onDeleteItem(item)
        } else {
          onInvoiceStatusChange && onInvoiceStatusChange(index, newItem)
        }
        bus.emit('invoice:disable:click', { invoiceId, reason })
      } else {
        const param = { [invoiceId]: reason.disableReason }
        let invoiceOperation = type === 'mark'? submitMarkInvoice(param):submitDisableInvoice(param)
        api.dispatch(invoiceOperation).then(data => {
          if(data.id === 'success'){
            showMessage.success(i18n.get('操作成功'))
            onInvoiceStatusChange && onInvoiceStatusChange()
          }
        }).catch(e => {
          showModal.info({
            title: i18n.get('警告'),
            content: e.errorMessage,
            okText: i18n.get('确定')
          })
          return
        })
      }
    })
}
function fnGetInvoiceIconByType(src, isToken = false) {
  const cls = isToken ? `invoice-pic-con invoice-pic-token` : 'invoice-pic-con'
  return (
    <div className={cls}>
      <img className="invoice-pic" src={src} />
    </div>
  )
}

function fnRenderIcon(source, props) {
  const { item, imgInfo } = props
  let type = get(item, 'master.form.E_system_发票主体_发票类别') // @i18n-ignore
  switch (source) {
    case 'QUERY':
      return fnGetInvoiceIconByType(SVG_INVOICE_TEXT)
    case 'UPLOAD':
    case 'ALIPAY_CARD':
    case 'SCAN':
    case 'AIFAPIAO':
      return fnGetInvoiceIconByType(SVG_INVOICE_PDF)
    case 'OCR':
      return renderOcrImg(imgInfo, props)
    case 'WECHAT_CARD':
      return renderWxImg(type)
  }
}

function renderOcrImg(imgInfo, props) {
  if (imgInfo && imgInfo.thumbUrl) {
    return <img className="invoice-item-pic" src={imgInfo.thumbUrl} onClick={() => handleImgClick(props)} />
  }
  const id = get(props, 'item.master.id')
  const defaultImg = IMAGE_MAP.get(id)
  const [img, setImg] = useState(defaultImg)
  if (img) {
    return img.thumbUrl ? (
      <img className="invoice-item-pic" src={img.thumbUrl} onClick={() => handleImgClick(props, img)} />
    ) : (
      <img
        className="invoice-item-pic"
        style={{ backgroundColor: 'rgba(211, 123, 69, 0.5)' }}
        src={SVG_INVOICE_PDF}
        onClick={() => handleImgClick(props, img)}
      />
    )
  }
  if (!defaultImg) {
    api.invokeService('@bills:get:invoice:image:by:ids', [id]).then(rep => {
      const [resImg] = rep.items
      IMAGE_MAP.set(id, resImg)
      setImg(resImg)
    })
    IMAGE_MAP.set(id, {})
  }
  return img && img.thumbUrl ? (
    <img className="invoice-item-pic" src={img.thumbUrl} onClick={() => handleImgClick(props, img)} />
  ) : (
    <img className="invoice-item-pic" style={{ backgroundColor: 'rgba(211, 123, 69, 0.5)' }} src={SVG_INVOICE_PDF} />
  )
}

function renderWxImg(type) {
  const cls = type === 'BLOCK_CHAIN' ? `invoice-pic-con invoice-pic-token` : 'invoice-pic-con'
  const imageSrc = type === 'BLOCK_CHAIN' ? SVG_INVOICE_TOKEN : SVG_INVOICE_PDF
  return (
    <div className={cls}>
      <img className="invoice-pic" src={imageSrc} />
    </div>
  )
}

export function getTaxAmount(detail) {
  const { entityId, form } = detail
  const tags = form[`E_${entityId}_价税合计`]
  // @i18n-ignore
  return new Big(getMoney(tags)).toFixed(2)
}
export function getMoney(money) {
  return money ? (typeof money === 'object' ? (money.standard ? money.standard : '0.00') : money) : '0.00'
}

function InvoiceMid(props) {
  const {
    item,
    title,
    info,
    allMoney,
    isEdit,
    typeText,
    typeTextCls = 'invoice-item-yellowType',
    isReadOnlyPage
  } = props
  let source = get(item, i18n.get('master.form.E_system_发票主体_来源'))
  const isInvoice = get(item, 'master.entityId', '') === 'system_发票主体'
  // @i18n-ignore
  const isCheckerInvoice = get(item, 'master.form.E_system_发票主体_验真')
  const allMoneyDetail =
    item.details && item.details.length ? invoiceTaxInfo.getAllMoney(item.details) : getTaxAmount(item.master)
  const realTagClass = isCheckerInvoice ? 'real-card' : 'no-real-card'
  const { metaileText, metaileColor, metaileBgColor } = invoiceMetaile(item?.master)
  const { disable } = item.disableInfo || {}
  const sourceLabel = TupleInvoiceType().find(el => el.value === source)?.label
  // 标记发票
  const { mark } = item.markInfo || {}

  return (
    <div className="invoice-item-mid">
      <div className="invoice-item-mid-content">
        <div className="option-line">
          {fnRenderIcon(source, props)}
          <BaseInfoView title={title} info={info} {...props} />
        </div>
        <div style={{ display: 'flex', flexShrink: 0, flexDirection: 'column' }}>
          <div className="dis-f">
            {disable && <div className='disable-item'>{i18n.get('已禁用')}</div>}
            {mark && <div className='disable-item'>{i18n.get('已标记')}</div>}
            {isInvoice && (
              <div className={realTagClass}>
                <T name={isCheckerInvoice ? '已验真' : '未验真'} />
              </div>
            )}
            {sourceLabel && <div className='source-item'>{sourceLabel}</div>}
            <div className={typeTextCls}>{typeText}</div>
          </div>
          <div className={'tag-wrapper-row'}>
            {metaileText?.length ? (
              <div style={{ color: metaileColor, backgroundColor: metaileBgColor }} className="metaile-tag">
                <T name={metaileText} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {isEdit && !isReadOnlyPage && (
        <div className="invoice-item-total">
          {item.master.entityId === 'system_发票主体' ? ( // @i18n-ignore
            <>
              <div className="invoice-item-merge">{i18n.get('价税合计')}</div>
              <Money className="invoice-item-money" value={allMoneyDetail} withoutStyle={isIE()} />
            </>
          ) : (
            <>
              <div className="invoice-item-merge">{i18n.get('总金额')}</div>
              <Money className="invoice-item-money" value={allMoney} withoutStyle={isIE()} />
            </>
          )}
        </div>
      )}
      {!(isReadOnlyPage || !isEdit) && renderInvoiceEdit(props, true)}
      {!(isReadOnlyPage || !isEdit) && renderInvoiceReview(props, true)}
    </div>
  )
}

function TaxiInvoice(props) {
  const { item, isEdit, isReadOnlyPage, onOpenEditModal, isInHistory } = props
  const { master } = item
  const {
    E_system_出租车票_发票所在地,
    E_system_出租车票_上车时间,
    E_system_出租车票_下车时间,
    E_system_出租车票_里程,
    E_system_出租车票_金额
  } = master.form
  const taxi_date = `${moment(E_system_出租车票_上车时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))}-${moment(
    E_system_出租车票_下车时间
  ).format('HH:mm')}`
  const title = E_system_出租车票_发票所在地
    ? i18n.get(`出租车发票 ({__k0})`, { __k0: E_system_出租车票_发票所在地 })
    : ''
  let km = E_system_出租车票_里程 ? `${E_system_出租车票_里程}km` : ''
  const money = E_system_出租车票_金额 ? E_system_出租车票_金额 : ''
  const info = `${taxi_date} ${km}`
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

  return (
    <div className={styles[cls]}>
      <div className={'invoice-item-yellow'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid title={title} allMoney={money} info={info} {...props} typeText={i18n.get('出租车票')} />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            {...props}
            onOpenEditModal={() => onOpenEditModal(item)}
            isInHistory={isInHistory}
            item={item}
            allMoney={money}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function TollInvoice(props) {
  const { item, isEdit, isReadOnlyPage, onOpenEditModal, isInHistory } = props
  const { master } = item
  const {
    E_system_过路费发票_时间,
    E_system_过路费发票_入口,
    E_system_过路费发票_出口,
    E_system_过路费发票_金额
  } = master.form
  const _start = E_system_过路费发票_入口 ? E_system_过路费发票_入口 : ''
  const _end = E_system_过路费发票_出口 ? E_system_过路费发票_出口 : ''
  const title = `${_start} -- ${_end}`
  const info = moment(E_system_过路费发票_时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

    return (
      <div className={styles[cls]}>
      <div className={'invoice-item-yellow'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          title={title}
          allMoney={E_system_过路费发票_金额}
          info={info}
          {...props}
          typeText={i18n.get('过路费票')}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            {...props}
            item={item}
            onOpenEditModal={() => onOpenEditModal(item)}
            allMoney={E_system_过路费发票_金额}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function QuotaInvoice(props) {
  const { item, isEdit, isReadOnlyPage, onOpenEditModal } = props
  const { master } = item
  const { E_system_定额发票_金额 } = master.form
  const title = i18n.get('定额发票')
  const info = ''
  const money = E_system_定额发票_金额 ? E_system_定额发票_金额 : ''
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

    return (
      <div className={styles[cls]}>
      <div className={'invoice-item-yellow'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid title={title} allMoney={money} info={info} {...props} typeText={i18n.get('定额发票')} />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo {...props} onOpenEditModal={() => onOpenEditModal(item)} item={item} allMoney={money} />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function TrainInvoice(props) {
  const { item, isEdit, isInvoiceManagePermissions, isReadOnlyPage, onChange, onOpenEditModal } = props
  const { master } = item
  const {
    E_system_火车票_乘车时间,
    E_system_火车票_车次,
    E_system_火车票_乘车人姓名,
    E_system_火车票_上车车站,
    E_system_火车票_下车车站,
    E_system_火车票_金额,
    E_system_火车票_座位类型,
    E_税额,
    E_是否抵扣
  } = master.form
  const train_date = moment(E_system_火车票_乘车时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))
  const _start = E_system_火车票_上车车站 ? E_system_火车票_上车车站 : ''
  const _end = E_system_火车票_下车车站 ? E_system_火车票_下车车站 : ''
  const title = `${_start} -- ${_end}`
  const info = isEdit
    ? `${train_date} ${E_system_火车票_车次 !== undefined ? E_system_火车票_车次 : ''} ${E_system_火车票_乘车人姓名}`
    : train_date
  const otherData = [
    { label: i18n.get('车次'), value: E_system_火车票_车次 !== undefined ? E_system_火车票_车次 : '' },
    { label: i18n.get('乘车人'), value: E_system_火车票_乘车人姓名 },
    { label: i18n.get('火车席别'), value: E_system_火车票_座位类型 !== undefined ? E_system_火车票_座位类型 : '' }
  ]
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

  return (
    <div className={styles[cls]}>
      <div className={'invoice-item-blue'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          item={item}
          allMoney={E_system_火车票_金额}
          title={title}
          info={info}
          {...props}
          typeText={i18n.get('火车票')}
          typeTextCls={'invoice-item-blueType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            item={item}
            {...props}
            allMoney={E_system_火车票_金额}
            otherData={otherData}
            taxAmount={E_税额}
            onOpenEditModal={e => onOpenEditModal(item)}
            onChange={onChange}
            isDeduction={E_是否抵扣}
            isInvoiceManagePermissions={isInvoiceManagePermissions}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function MachineInvoice(props) {
  const {
    item,
    isEdit,
    isInvoiceManagePermissions,
    isReadOnlyPage,
    onChange,
    onOpenEditModal,
    isEditAuthenticity = true
  } = props
  const { master } = item
  const {
    E_system_机打发票_时间,
    E_system_机打发票_金额,
    E_税额,
    E_是否抵扣,
    E_system_机打发票_销售方名称
  } = master.form
  const title = E_system_机打发票_销售方名称

  const info = moment(E_system_机打发票_时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))
  const { shoMetaileAction } = invoiceMetaile(item?.master)
  // 复用一下isEditAuthenticity，在指定的页面不可以上传原件
  const isShowMetaileAction = isEditAuthenticity ? shoMetaileAction : false
  const handleUploadMetaileResponse = invoice => {
    const { index, onUploadInvoiceMetaile } = props
    onUploadInvoiceMetaile(index, invoice)
  }
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

  return (
    <div className={styles[cls]}>
      <div className={'invoice-item-machine'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          item={item}
          allMoney={E_system_机打发票_金额}
          title={title}
          info={info}
          {...props}
          typeText={i18n.get('机打发票')}
          typeTextCls={'invoice-item-machineType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            item={item}
            {...props}
            allMoney={E_system_机打发票_金额}
            taxAmount={E_税额}
            onOpenEditModal={e => onOpenEditModal(item)}
            onChange={onChange}
            isDeduction={E_是否抵扣}
            isInvoiceManagePermissions={isInvoiceManagePermissions}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
            {isShowMetaileAction ? (
              <InvoiceUploadView
                invoiceId={item?.invoiceId || item?.master?.id}
                children={<div className="checker-invoice">{i18n.get('上传原文件')}</div>}
                onResult={handleUploadMetaileResponse}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

function PlaneInvoice(props) {
  const { item, isEdit, isInvoiceManagePermissions, isReadOnlyPage, onChange, onOpenEditModal } = props
  const { master } = item
  const {
    E_system_航空运输电子客票行程单_乘机时间,
    E_system_航空运输电子客票行程单_航班号,
    E_system_航空运输电子客票行程单_乘机人姓名,
    E_system_航空运输电子客票行程单_座位等级,
    E_system_航空运输电子客票行程单_出发站,
    E_system_航空运输电子客票行程单_到达站,
    E_system_航空运输电子客票行程单_金额,
    E_system_航空运输电子客票行程单_电子客票号码,
    E_system_航空运输电子客票行程单_填开日期,
    E_税额,
    E_是否抵扣
  } = master.form
  const plane_date = moment(E_system_航空运输电子客票行程单_乘机时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))
  const _start = E_system_航空运输电子客票行程单_出发站 ? E_system_航空运输电子客票行程单_出发站 : ''
  const _end = E_system_航空运输电子客票行程单_到达站 ? E_system_航空运输电子客票行程单_到达站 : ''
  const title = `${_start} -- ${_end}`
  const _code = E_system_航空运输电子客票行程单_航班号 ? E_system_航空运输电子客票行程单_航班号 : ''
  const _name = E_system_航空运输电子客票行程单_乘机人姓名 ? E_system_航空运输电子客票行程单_乘机人姓名 : ''
  const _grade = E_system_航空运输电子客票行程单_座位等级 ? E_system_航空运输电子客票行程单_座位等级 : ''
  const _id = E_system_航空运输电子客票行程单_电子客票号码 ? E_system_航空运输电子客票行程单_电子客票号码 : ''
  const _fillDate = moment(E_system_航空运输电子客票行程单_填开日期).format(i18n.get('YYYY年MM月DD日'))
  const info = isEdit ? `${plane_date} ${_code} ${_name} ${_grade}` : `${plane_date}`
  const otherData = [
    { label: i18n.get('航班号'), value: _code },
    { label: i18n.get('席别'), value: _grade },
    { label: i18n.get('乘机人'), value: _name, isCopy: true },
    { label: i18n.get('电子客票号'), value: _id, isCopy: true },
    { label: i18n.get('填开日期'), value: _fillDate }
  ]
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

    return (
      <div className={styles[cls]}>
      <div className={'invoice-item-blue'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          item={item}
          allMoney={E_system_航空运输电子客票行程单_金额}
          title={title}
          info={info}
          {...props}
          typeText={i18n.get('机票行程单')}
          typeTextCls={'invoice-item-blueType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            item={item}
            {...props}
            onOpenEditModal={e => onOpenEditModal(item)}
            allMoney={E_system_航空运输电子客票行程单_金额}
            otherData={otherData}
            taxAmount={E_税额}
            isDeduction={E_是否抵扣}
            onChange={onChange}
            isInvoiceManagePermissions={isInvoiceManagePermissions}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function BusInvoice(props) {
  const { item, isEdit, isInvoiceManagePermissions, isReadOnlyPage, onChange, onOpenEditModal } = props
  const { master } = item
  const {
    E_system_客运汽车发票_时间,
    E_system_客运汽车发票_出发车站,
    E_system_客运汽车发票_达到车站,
    E_system_客运汽车发票_金额,
    E_system_客运汽车发票_姓名,
    E_税额,
    E_是否抵扣
  } = master.form
  const _start = E_system_客运汽车发票_出发车站 ? E_system_客运汽车发票_出发车站 : ''
  const _end = E_system_客运汽车发票_达到车站 ? E_system_客运汽车发票_达到车站 : ''
  const title = `${_start} -- ${_end}`
  const bus_name = E_system_客运汽车发票_姓名 ? E_system_客运汽车发票_姓名 : ''
  const info = isEdit
    ? `${moment(E_system_客运汽车发票_时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))} ${bus_name}`
    : `${moment(E_system_客运汽车发票_时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))}`
  const otherData = [{ label: i18n.get('乘车人'), value: bus_name }]
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

    return (
      <div className={styles[cls]}>
      <div className={'invoice-item-blue'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          item={item}
          allMoney={E_system_客运汽车发票_金额}
          title={title}
          info={info}
          {...props}
          typeText={i18n.get('客运汽车票')}
          typeTextCls={'invoice-item-blueType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            item={item}
            {...props}
            onOpenEditModal={e => onOpenEditModal(item)}
            allMoney={E_system_客运汽车发票_金额}
            otherData={otherData}
            taxAmount={E_税额}
            isDeduction={E_是否抵扣}
            onChange={onChange}
            isInvoiceManagePermissions={isInvoiceManagePermissions}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function ShoppingInvoice(props) {
  const { item, isEdit, isInvoiceManagePermissions, isReadOnlyPage, onChange, onOpenEditModal } = props
  const { master } = item
  const { E_system_消费小票_店名, E_system_消费小票_时间, E_system_消费小票_金额, E_是否抵扣 } = master.form
  const title = E_system_消费小票_店名 || i18n.get('无法识别店名')
  const info = `${moment(E_system_消费小票_时间).format(i18n.get('YYYY年MM月DD日 HH:mm'))}`
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

  return (
    <div className={styles[cls]}>
      <div className={'invoice-item-blue'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          item={item}
          allMoney={E_system_消费小票_金额}
          title={title}
          info={info}
          {...props}
          typeText={i18n.get('消费小票')}
          typeTextCls={'invoice-item-blueType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            item={item}
            {...props}
            onOpenEditModal={e => onOpenEditModal(item)}
            allMoney={E_system_消费小票_金额}
            isDeduction={E_是否抵扣}
            onChange={onChange}
            isInvoiceManagePermissions={isInvoiceManagePermissions}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function OtherInvoice(props) {
  const { item, isEdit, isReadOnlyPage, onOpenEditModal } = props
  const { master } = item
  const { E_system_其他_日期, E_system_其他_金额 } = master.form
  const title = i18n.get('其他票据')
  const info = moment(E_system_其他_日期).format(i18n.get('YYYY年MM月DD日'))
  const cls = getClassNameOfInvoiceCardWrap(item)
  const invoiceDisableMarkStatus = getInvoiceDisableMarkStatus(props)
  const {showMark, showDisable} = invoiceDisableMarkStatus

  return (
    <div className={styles[cls]}>
      <div className={'invoice-item-gray'}>
        {isEdit && <EditDelete data={props} />}
        <InvoiceMid
          title={title}
          allMoney={E_system_其他_金额}
          info={info}
          {...props}
          typeText={i18n.get('其他票据')}
          typeTextCls={'invoice-item-grayType'}
        />
        {(isReadOnlyPage || !isEdit) && (
          <InvoiceOtherInfo
            {...props}
            onOpenEditModal={() => onOpenEditModal(item)}
            item={item}
            allMoney={E_system_其他_金额}
          />
        )}
        <div className="invoice-item-wrap" style={{ justifyContent: 'flex-end' }}>
          <div className="right-action">
            <InvoiceToDisableOrMark
              {...props}
              showDisable={showDisable}
              showMark={showMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

function handlePhotoOperate(item, fn) {
  fn && fn(item)
}

function InvoicePhotoView(props) {
  const { isEdit, item, onFileDownload, onFilePreview, onRemoveAttachment, isDownload = true } = props
  return (
    <div className={styles['invoice-item-wrapper']}>
      <div className={'invoice-item-gray'}>
        {isEdit && (
          <Popconfirm
            title={i18n.get('确定删除发票照片？')}
            okText={i18n.get('确认')}
            cancelText={i18n.get('取消')}
            onConfirm={handlePhotoOperate.bind(this, item, onRemoveAttachment)}
          >
            <div className="edit-delete">
              <EKBIcon name="#EDico-delete" className="icon-delete" />
            </div>
          </Popconfirm>
        )}
        <div className="invoice-item-mid">
          <div className="invoice-item-mid-content">
            <div className="invoice-item-intr option-line" onClick={() => handlePhotoOperate(item, onFilePreview)}>
              <div className="invoice-photo-content" onClick={() => handlePhotoOperate(item, onFilePreview)}>
                <img className="invoice-photo-img" src={item.url} />
              </div>
              <div className="invoice-item-title">{item.fileName}</div>
              <div className="invoice-photo-operate">
                <div className="item" onClick={() => handlePhotoOperate(item, onFilePreview)}>
                  {i18n.get('预览')}
                </div>
                {isDownload && !IMG_REG.test(item.fileName) && (
                  <div className="item" onClick={() => handlePhotoOperate(item, onFileDownload)}>
                    {i18n.get('下载')}
                  </div>
                )}
              </div>
            </div>
            <div className={'invoice-item-grayType'}>{i18n.get('发票照片')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function handleCopy() {
  showMessage.success(i18n.get('复制成功！'))
}

function InvoiceOtherInfo(props) {
  const { item, allMoney, otherData = [] } = props
  const allMoneyDetail =
    item.details && item.details.length ? invoiceTaxInfo.getAllMoney(item.details) : getTaxAmount(item.master)
  return (
    <div className="invoice-item-other-info">
      {otherData.map((item, index) => {
        const isCopy = item.isCopy
        const cls = isCopy ? 'invoice-otherInfo-item-bg' : 'invoice-otherInfo-item'
        return (
          <Tooltip title={i18n.get('点击复制')} overlayStyle={isCopy ? {} : { display: 'none' }} key={index}>
            <div className={cls} key={index}>
              <CopyToClipboard text={item.value} onCopy={isCopy ? handleCopy : null}>
                <div className="invoice-info-value">
                  {item.label}
                  <span>{item.value}</span>
                </div>
              </CopyToClipboard>
            </div>
          </Tooltip>
        )
      })}
      <div className="invoice-otherInfo-money">
        {item.master.entityId === 'system_发票主体' ? ( // @i18n-ignore
          <>
            <div>{i18n.get('价税合计')}</div>
            <Money className="invoice-item-money" value={allMoneyDetail} withoutStyle={isIE()} />
          </>
        ) : (
          <>
            <div>{i18n.get('总金额')}</div>
            <Money className="invoice-item-money" value={allMoney} withoutStyle={isIE()} />
          </>
        )}
      </div>
      {renderInvoiceEdit(props, false)}
      {renderInvoiceReview(props, false)}
    </div>
  )
}

function renderInvoiceReview(props, isEdit = true) {
  const cls = isEdit ? 'invoice-edit-item' : 'invoice-approve-item'
  const linecls = isEdit ? 'invoice_line' : 'invoice_line_approve'
  const {
    item,
    allMoney,
    onOpenEditReviewAmount,
    isHover = false,
    billState,
    isModify,
    modifyApproveMoney = false
  } = props
  if (!modifyApproveMoney) {
    return <></>
  }
  let { approveAmount, comment = '' } = item
  approveAmount =
    approveAmount == void 0
      ? item.master.entityId === 'system_发票主体'
        ? item.details && item.details.length
          ? invoiceTaxInfo.getAllMoney(item.details)
          : getTaxAmount(item.master)
        : allMoney
      : approveAmount
  const hasComment = comment ? !!comment.length : false
  const isSubmitEditable =
    (billState === 'draft' || billState === 'new' || billState === 'rejected') && modifyApproveMoney.isSubmitEditable
  const showEdit = isEdit && !isHover && (isModify || isSubmitEditable)
  const content = <p>{comment}</p>
  return (
    <div className="invoice-edit-wrapper">
      <div className={linecls} style={{ marginBottom: '12px' }} />
      <div className={'invoice-edit-item-tickets'}>
        <div className={'invoice-edit-item-data'} style={{ width: showEdit ? '92%' : '100%' }}>
          <div className={cls}>
            <div className={'invoice-edit-txt'}>
              {i18n.get('核发金额')}
              {hasComment ? <img className="ml-4" src={SVG_WARNING} /> : null}
            </div>
            <Money className="text" value={approveAmount} withoutStyle={isIE()} />
          </div>
          <div className={cls}>
            <div>{i18n.get('核发批注')}</div>
            {/* <div className="text">{comment}</div> */}
            <Popover content={content} title="" placement="topRight">
              <div className="invoice-show-txt text">{comment}</div>
            </Popover>
          </div>
        </div>
        {showEdit && (
          <div className={`${cls} invoice-edit`} onClick={onOpenEditReviewAmount}>
            {i18n.get('编辑')}
          </div>
        )}
      </div>
    </div>
  )
}

function renderInvoiceEdit(props, isEdit = true) {
  const { item, showPriceAndRate = true } = props
  const {
    master: { entityId },
    taxRate
  } = item
  if (!showPriceAndRate) {
    return null
  }
  return (
    <div className="invoice-edit-wrapper">
      {entityId !== i18n.get('system_发票主体') &&
        invoiceTaxInfo.fnCanEditType(entityId) &&
        renderTickets(props, isEdit)}
      {entityId === i18n.get('system_发票主体') && renderInvoice(props, isEdit)}
    </div>
  )
}

function renderTickets(props, isEdit) {
  const {
    item,
    onOpenEditModal,
    isHover = false,
    isInvoiceManagePermissions = false,
    billState,
    isInHistory = false
  } = props
  const cls = isEdit ? 'invoice-edit-item' : 'invoice-approve-item'
  const linecls = isEdit ? 'invoice_line' : 'invoice_line_approve'
  const taxAmount = invoiceTaxInfo.getTaxAmount(item)
  const taxRate = invoiceTaxInfo.getTaxRate(item)
  return (
    <>
      <div className={linecls} style={{ marginBottom: '12px' }} />
      <div className={'invoice-edit-item-tickets'}>
        <div className={'invoice-edit-item-data'}>
          <div className={cls}>
            <div>{i18n.get('税率')}</div>
            <div className="text">
              {taxRate}
              {'%'}
            </div>
          </div>
          <div className={cls}>
            <div>{i18n.get('可抵扣税额')}</div>
            <Money className="text" value={taxAmount} withoutStyle={isIE()} />
          </div>
        </div>
        {!isHover && isInvoiceManagePermissions && invoiceTaxInfo.fnCanState(billState) && !isInHistory && (
          <div className={`${cls} invoice-edit`} onClick={onOpenEditModal}>
            {i18n.get('编辑')}
          </div>
        )}
      </div>
    </>
  )
}

function renderInvoice(props, isEdit) {
  const {
    item,
    onOpenEditModal,
    isHover = false,
    isInvoiceManagePermissions = false,
    billState,
    isInHistory = false
  } = props
  const cls = isEdit ? 'invoice-edit-item' : 'invoice-approve-item'
  const total = invoiceTaxInfo.getTaxAmount(item)
  const taxRate = invoiceTaxInfo.getTaxRate(item)
  return (
    <>
      <div className={'invoice-edit-item-data'}>
        {!!taxRate && (
          <div className={cls}>
            <div>{i18n.get('税率')}</div>
            <div className="text">
              {taxRate}
              {'%'}
            </div>
          </div>
        )}
        <div className={cls}>
          <div>{i18n.get('可抵扣税额')}</div>
          <div className="invoice-edit-item-right">
            <Money className="text" value={total} withoutStyle={isIE()} />
            {!isHover && isInvoiceManagePermissions && invoiceTaxInfo.fnCanState(billState) && !isInHistory && (
              <div onClick={onOpenEditModal} className="ml-8 invoice-edit">
                {i18n.get('编辑')}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {!isHover && isInvoiceManagePermissions && invoiceTaxInfo.fnCanState(billState) && !isInHistory && (
        <div className={`${cls} invoice-edit`} onClick={onOpenEditModal}>
          <div>{i18n.get('编辑')}</div>
        </div>
      )} */}
    </>
  )
}

function InvoiceToDisableOrMark(props) {
  const {showDisable=false, showMark=false} = props

  return <>
    {showDisable && <span className='invoice-disable-btn'
                          onClick={() => handleDisableClick(props, InvoiceMarkDisableType.DISABLE)}>{i18n.get('点击禁用')}</span>}
    {showMark && <span className='invoice-disable-btn'
                       onClick={() => handleDisableClick(props, InvoiceMarkDisableType.MARK)}>{i18n.get('点击标记')}</span>}
  </>
}

function getInvoiceDisableMarkStatus(props){
  const { item, isHover, isReadOnlyPage } = props  //isEdit和isModify在补充发票查看单据详情时是true,故用isReadOnlyPage判断是编辑态
  const { disable, canDisable, currentFlowState } = item?.disableInfo || {}

  //编辑态可以禁用发票
  const showDisable = ['APPROVING'].includes(currentFlowState) &&
    !isReadOnlyPage && canDisable && !disable && !isHover
  //非编辑态可以标记发票
  const { mark, canMark } = item.markInfo || {}
  const showMark = ['APPROVING'].includes(item?.markInfo?.currentFlowState) &&
    isReadOnlyPage && !mark && canMark
  return {
    showMark,
    showDisable
  }
}

function getClassNameOfInvoiceCardWrap(item){
  const { disable } = item?.disableInfo || {}
  const { mark } = item?.markInfo || {}
  const grey = disable || mark
  return  grey? 'invoice-item-wrapper-disable' : 'invoice-item-wrapper'
}

export { mapItem }
