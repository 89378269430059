import React from 'react';
export var PayPlanReceipt = function (props) {
    var receiptArr = props.receiptArr, record = props.record, dataSource = props.dataSource;
    var hasReceipt = false;
    if (receiptArr !== undefined) {
        if (receiptArr.length > 0) {
            hasReceipt = true;
        }
        else {
            hasReceipt = false;
        }
    }
    return (React.createElement("div", null, ((dataSource === null || dataSource === void 0 ? void 0 : dataSource.state) === 'paid' || (dataSource === null || dataSource === void 0 ? void 0 : dataSource.state) === 'archived' || hasReceipt || record.paymentStatus === '支付成功')
        ? React.createElement("span", null, i18n.get('支付记录')) : React.createElement("span", null, i18n.get('支付计划'))));
};
export default PayPlanReceipt;
