import React from 'react';
import Loading from '@ekuaibao/loading';
import { asyncComponentConfig } from '@ekuaibao/async-component';
import { get } from 'lodash';
import './FetchConfig';
asyncComponentConfig({
    LoadingComponent: function () { return (React.createElement(Loading, { style: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }, className: "layout-content-right center", color: "var(--brand-base)" })); },
    ErrorComponent: function (error) {
        var name = get(error, 'error.name');
        var imgUrl, desc;
        if (name === 'TypeError') {
            imgUrl = require('../images/error-type.png');
            desc = i18n.get('未知问题，请联系系统管理员');
        }
        else {
            imgUrl = require('../images/error-system.png');
            desc = (React.createElement("div", null,
                i18n.get('当前应用版本过低，请'),
                React.createElement("div", { style: { display: 'inline', cursor: 'pointer', textDecoration: 'underline', color: 'var(--brand-base)' }, onClick: function () {
                        window.location.reload();
                    } }, i18n.get('刷新')),
                i18n.get('重试-1')));
        }
        console.error(error);
        return (React.createElement("div", { style: { width: '100%' } },
            React.createElement("div", { className: "center", style: { marginTop: 200, marginBottom: 24 } },
                React.createElement("img", { width: "300px", height: "300px", src: imgUrl })),
            React.createElement("div", { style: { width: '100%', height: '100%', textAlign: 'center', color: 'rgba(29,43,61,0.75)', fontSize: 14 } }, desc)));
    }
});
import './updateScopeVariable';
