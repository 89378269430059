/**
 *  Cr`1eated by daiwenjuan on 17/6/14 下午4:56.
 */
import { app as api } from '@ekuaibao/whispered'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { dealPrintData } from '@ekuaibao/lib/lib/lib-util'

export function dateFormat(date, time) {
  if (time === 'start') {
    return date.format('YYYY-MM-DD 00:00:00')
  }

  return date.format('YYYY-MM-DD 23:59:59')
}

export function stateFormat(state) {
  let states = {
    draft: i18n.get('草稿'),
    approving: i18n.get('审批中'),
    receiving: i18n.get('待收单'),
    sending: i18n.get('待寄送'),
    rejected: i18n.get('已驳回'),
    paying: i18n.get('待支付'),
    paid: i18n.get('已完成(待确认)'),
    archived: i18n.get('已完成(已确认)')
  }
  return states[state]
}

export function getSorter(conditions) {
  let sorter = this.state.sorter
  if (conditions.sorter && conditions.sorter.order && conditions.sorter.field) {
    let field = conditions.sorter.field.replace(/flow\./, '')
    let order = conditions.sorter.order.replace(/end/, '')
    if (field === 'form.specificationId.name') {
      field = 'form.specificationId.nameSpell'
    }

    if (field === 'form.submitterId.name') {
      field = 'form.submitterId.nameSpell'
    }

    if (field === 'form.expenseDepartment.name') {
      field = 'form.expenseDepartment.nameSpell'
    }

    if (field === 'form.projectProperty.name') {
      field = `\`PROPERTY:${this.props.projectProperty.projectId}\`.name`
    }

    sorter = { order, field }
    this.setState({ sorter })
  }

  return sorter
}

export function setPagination(conditions) {
  const pager = this.state.pagination
  pager.pageCurrent = conditions.pageCurrent
  pager.pageSize = conditions.pageSize || pager.pageSize
  this.setState({ pagination: pager })
  let offset = (conditions.pageCurrent - 1) * conditions.pageSize
  let size = conditions.pageSize
  return { offset, size }
}

export function clearFilterCondition() {
  this.setState({
    specListSelected: [],
    deptListSelected: [],
    expStateSelected: [],
    projectSelected: []
  })
}

function _isFilterChange(filter = []) {
  let isFilterChange = false
  for (let key in filter) {
    if (filter[key].length > 0) {
      isFilterChange = true
      break
    }
  }

  return isFilterChange
}

export function setFilter(conditions, customFilter, isDateChange, flag, dateType) {
  let newFilter = []
  let { filters } = this.state
  let isFilterChange = conditions && _isFilterChange(conditions.filters)
  if (isFilterChange) {
    //表体筛选
    newFilter = setEnumFilters.call(this, conditions.filters)
    customFilter = ''
    this.setState(
      {
        inSearch: false,
        searchText: ''
      },
      () => {
        this.refs['searchInput'] && this.refs['searchInput'].handleClear()
      }
    )
  }

  if (isDateChange) {
    //日期筛选 ＋ 表体筛选
    filters.find(v => v.column === 'specification') && newFilter.push(filters.find(v => v.column === 'specification'))
    if (filters.find(v => v.column === 'state')) {
      newFilter.push({
        column: 'state',
        value:
          dateType === 'payDate'
            ? 'state.in("archived","paid")'
            : 'state.in("archived","paid","paying","approving","sending","receiving")'
      })
    }

    filters.find(v => v.column === 'department') && newFilter.push(filters.find(v => v.column === 'department'))
    filters.find(v => v.column === 'projectProperty') &&
      newFilter.push(filters.find(v => v.column === 'projectProperty'))
  }

  if (!isFilterChange && !isDateChange) {
    //清空表体筛选
    clearFilterCondition.call(this)
  }

  if (customFilter) {
    // 模糊查询
    newFilter = setCustomerFilter.call(this, customFilter)
  }

  if (!newFilter.filter(v => v.column === 'state').length) {
    newFilter.push({
      column: 'state',
      value:
        dateType === 'payDate'
          ? 'state.in("archived","paid")'
          : 'state.in("archived","paid","paying","approving","sending","receiving")'
    })
  }

  if (
    flag !== 'budget' &&
    !newFilter.filter(v => v.column === 'expenseDate').length &&
    !newFilter.filter(v => v.column === 'customFilter').length
  ) {
    if (dateType === 'payDate') {
      newFilter.push({
        column: 'payDate',
        value: `form.payDate>${moment(this.state.startDate).valueOf()}&&form.payDate<${moment(
          this.state.endDate
        ).valueOf()}`
      })
    } else {
      newFilter.push({
        column: 'expenseDate',
        value: `form.expenseDate>${moment(this.state.startDate).valueOf()}&&form.expenseDate<${moment(
          this.state.endDate
        ).valueOf()}`
      })
    }
  }

  this.setState({ filters: newFilter })
  return newFilter
}

export function setEnumFilters(enumFilters = []) {
  let newFilter = []
  let specValue = enumFilters['form.specificationId.name'] || []
  let deptValue = enumFilters['form.expenseDepartment.name'] || []
  let expStateValue = cloneDeep(enumFilters['state']) || []
  let projectValue = enumFilters[i18n.get('form.项目.name')] || []

  // expStateValue.indexOf('paid') > -1 && expStateValue.unshift('archived')

  specValue = specValue.map(v => JSON.stringify(v))
  deptValue = deptValue.map(v => JSON.stringify(v))
  expStateValue = expStateValue.map(v => JSON.stringify(v))
  projectValue = projectValue.map(v => JSON.stringify(v))

  this.setState({
    specListSelected: enumFilters['form.specificationId.name'],
    deptListSelected: enumFilters['form.expenseDepartment.name'],
    expStateSelected: enumFilters['state'],
    projectSelected: enumFilters[i18n.get('form.项目.name')]
  })

  if (specValue.length) {
    newFilter.push({
      column: 'specification',
      value: `form.specificationId.name.in(${specValue})`
    })
  }

  if (deptValue.length) {
    newFilter.push({
      column: 'department',
      value: `form.expenseDepartment.in(${deptValue})`
    })
  }

  if (expStateValue.length) {
    newFilter.push({
      column: 'state',
      value: `state.in(${expStateValue})`
    })
  }

  if (projectValue.length) {
    let newProjectValue = []
    let filterNull = false
    projectValue.forEach(value => {
      if (value === '"no-project-value"') {
        filterNull = true
      } else {
        newProjectValue.push(value)
      }
    })

    let filterValue = (newProjectValue.length && i18n.get(`form.项目.id.in({__k0})`, { __k0: newProjectValue })) || ''

    if (filterNull) {
      if (filterValue.length) {
        filterValue += '||'
      }

      filterValue = i18n.get(`({__k0}form.项目.id.isNull())`, { __k0: filterValue })
    }

    newFilter.push({
      column: i18n.get('项目'),
      value: filterValue
    })
  }

  return newFilter
}

function setCustomerFilter(customFilter) {
  customFilter = JSON.stringify(customFilter)
  customFilter = customFilter.replace(/%/g, '\\\\%')
  let str =
    '(form.title.contains(' +
    customFilter +
    ')||form.code.contains(' +
    customFilter +
    ')||form.submitterId.name.contains(' +
    customFilter +
    '))'
  let customFilterObj = { column: 'customFilter', value: str }
  let newFilter = []
  newFilter.push(customFilterObj)
  return newFilter
}

export function handlePrint(selectedRowKeys) {
  let cache = this.__cache
  let data = dealPrintData(selectedRowKeys, cache)
  const { doPrint } = api.require('@audit/service-print')

  doPrint(data)
}
