import loadable from '@loadable/component';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var IndexBudget = loadableWithDescriptor(function () { return import('./budget/BudgetConfig'); }, {
    type: 'budgetConfig'
});
export var budgetConfig = [IndexBudget];
