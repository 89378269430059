var _a, _b, _c, _d, _e, _f, _g;
import loadable from '@loadable/component';
export default [
    {
        resource: '@elements',
        value: (_a = {},
            _a['ekbIcon'] = loadable(function () { return import('./elements/ekbIcon'); }),
            _a['puppet/personnelList/NewPersonnelList'] = loadable(function () {
                return import('./elements/puppet/personnelList/NewPersonnelList');
            }),
            _a['logo/logo-for-login'] = loadable(function () { return import('./elements/logo/logo-for-login'); }),
            _a['feeType-tree-select'] = loadable(function () { return import('./elements/feeType-tree-select'); }),
            _a['feetype-tree/feetype-tree-search-modal'] = loadable(function () {
                return import('./elements/feetype-tree/feetype-tree-search-modal');
            }),
            _a['modal/sync-staffs-modal'] = loadable(function () { return import('./elements/modal/sync-staffs-modal'); }),
            _a['export-excel/export-excel-modal'] = loadable(function () { return import('./elements/export-excel/export-excel-modal'); }),
            _a['async-export-modal'] = loadable(function () { return import('./elements/async-export-modal'); }),
            _a['city/SelectCityModal'] = loadable(function () { return import('./elements/city/SelectCityModal'); }),
            _a['transfer/TransferModal'] = loadable(function () { return import('./elements/transfer/TransferModal'); }),
            _a['del-confirm-modal'] = loadable(function () { return import('./elements/del-confirm-modal'); }),
            _a['staff-selector/staff-selector-modal'] = loadable(function () { return import('./elements/staff-selector/staff-selector-modal'); }),
            _a['staff-selector-v2/staff-selector-modal'] = loadable(function () { return import('./elements/staff-selector-v2/staff-selector-modal'); }),
            _a['select-member-modal'] = loadable(function () { return import('./elements/select-member-modal'); }),
            _a['staff-selector-field'] = loadable(function () { return import('./elements/custom-fields/StaffSelectorField'); }),
            _a['select-tree-modal'] = loadable(function () { return import('./elements/select-tree-modal'); }),
            _a['CarouselInvoiceReviewer/CarouselInvoiceReviewer'] = loadable(function () {
                return import('./elements/CarouselInvoiceReviewer/CarouselInvoiceReviewer');
            }),
            _a['budget-detail/budget-detail'] = function () { return import('./elements/budget-detail/budget-detail'); },
            _a['payee-account/account-change-log'] = loadable(function () { return import('./elements/payee-account/account-change-log'); }),
            _a['print-modal'] = loadable(function () { return import('./elements/print-modal'); }),
            _a['dept-select-modal'] = loadable(function () { return import('./elements/dept-select-modal'); }),
            _a['phone-verification/verification-phone'] = loadable(function () {
                return import('./elements/phone-verification/verification-phone');
            }),
            _a['flow-allow-modal'] = loadable(function () { return import('./elements/flow-allow-modal'); }),
            _a['payPlan/table/EditRow'] = loadable(function () { return import('./elements/payPlan/table/EditRow'); }),
            _a['payPlan/table/EditCell'] = loadable(function () { return import('./elements/payPlan/table/EditCell'); }),
            _a['puppet/Money'] = loadable(function () { return import('./elements/puppet/Money'); }),
            _a['puppet/details/DetailItemExpandFields'] = loadable(function () { return import('./elements/puppet/details/DetailItemExpandFields'); }),
            _a['puppet/Guide/OnceItemTip'] = loadable(function () { return import('./elements/puppet/Guide/OnceItemTip'); }),
            _a['puppet/Guide/OncePopoverTip'] = loadable(function () { return import('./elements/puppet/Guide/OncePopoverTip'); }),
            _a['batch-components/BatchDimensionSelectComponent'] = loadable(function () {
                return import('./elements/batch-components/BatchDimensionSelectComponent');
            }),
            _a['tag-selector'] = loadable(function () { return import('./elements/tag-selector'); }),
            _a['puppet/view-item/SelectItem'] = loadable(function () { return import('./elements/puppet/view-item/SelectItem'); }),
            _a['SummaryComponent'] = loadable(function () { return import('./elements/SummaryComponent'); }),
            _a['data-grid/DataGridWrapper'] = loadable(function () { return import('./elements/data-grid/DataGridWrapper'); }),
            _a['data-grid-v2/LoaderWithLegacyData'] = loadable(function () { return import('./elements/data-grid-v2/LoaderWithLegacyData'); }),
            _a['ETabs'] = loadable(function () { return import('./elements/ETabs'); }),
            _a['attachment-component/AttachmentComponent'] = loadable(function () {
                return import('./elements/attachment-component/AttachmentComponent');
            }),
            _a['MoneyView/MoneyView'] = loadable(function () { return import('./elements/MoneyView/MoneyView'); }),
            _a['signature/SignaturePanel'] = loadable(function () { return import('./elements/signature/SignaturePanel'); }),
            _a['signature/ApproveSignature'] = loadable(function () { return import('./elements/signature/ApproveSignature'); }),
            _a['Animation/Animation'] = loadable(function () { return import('./elements/Animation/Animation'); }),
            _a['payment-form-field/select-payment-method'] = loadable(function () {
                return import('./elements/payment-form-field/select-payment-method');
            }),
            _a['expense-export'] = loadable(function () { return import('./elements/expense-export'); }),
            _a['search-input'] = loadable(function () { return import('./elements/search-input'); }),
            _a['puppet/attachment'] = loadable(function () { return import('./elements/puppet/attachment'); }),
            _a['puppet/details/Details'] = loadable(function () { return import('./elements/puppet/details/Details'); }),
            _a['payPlan/PayPlayTableWrapper'] = loadable(function () { return import('./elements/payPlan/PayPlayTableWrapper'); }),
            _a['payee-account/account-list-item'] = loadable(function () { return import('./elements/payee-account/account-list-item'); }),
            _a['payee-account/AccountNoInput'] = loadable(function () { return import('./elements/payee-account/AccountNoInput'); }),
            _a['data-grid-v2/LineClamp'] = loadable(function () { return import('./elements/data-grid-v2/LineClamp'); }),
            _a['data-grid-v2/SimpleTableWithLoader'] = loadable(function () { return import('./elements/data-grid-v2/SimpleTableWithLoader'); }),
            _a['icon'] = loadable(function () { return import('./elements/icon'); }),
            _a['EkbHighLighter'] = loadable(function () { return import('./elements/EkbHighLighter'); }),
            _a['logo/logo'] = loadable(function () { return import('./elements/logo/logo'); }),
            _a['puppet/KeelSingleViewHeader'] = loadable(function () { return import('./elements/puppet/KeelSingleViewHeader'); }),
            _a['puppet/KeelViewBody'] = loadable(function () { return import('./elements/puppet/KeelViewBody'); }),
            _a['puppet/KeelBasic'] = require('./elements/puppet/KeelBasic').default,
            _a['InvoiceCard/OpenBillInfoCard'] = loadable(function () { return import('./elements/InvoiceCard/OpenBillInfoCard'); }),
            _a['ImmersiveToolBar'] = loadable(function () { return import('./elements/ImmersiveToolBar'); }),
            _a['DataLinkTable/DataLinkTableWrapper'] = loadable(function () { return import('./elements/DataLinkTable/DataLinkTable'); }),
            _a['invoice-form/RefInvoice'] = loadable(function () { return import('./elements/invoice-form/RefInvoice'); }),
            _a['InvoiceCard/InvoiceItem'] = loadable(function () { return import('./elements/InvoiceCard/InvoiceItem'); }),
            _a['feetype-icon'] = loadable(function () { return import('./elements/feetype-icon'); }),
            _a['data-grid/EKBDataGridWrapper'] = loadable(function () { return import('./elements/data-grid/EKBDataGridWrapper'); }),
            _a['data-grid-v2/LightingMode'] = loadable(function () { return import('./elements/data-grid-v2/LightingMode'); }),
            _a['input-selector'] = loadable(function () { return import('./elements/input-selector'); }),
            _a['transfer-tree'] = loadable(function () { return import('./elements/transfer-tree'); }),
            _a['puppet/tree-menu/tree-menu'] = loadable(function () { return import('./elements/puppet/tree-menu/tree-menu'); }),
            _a['ImmersiveToolBar/index'] = loadable(function () { return import('./elements/ImmersiveToolBar/index'); }),
            _a['SearchBarForTitle/SearchBarForTitle'] = loadable(function () { return import('./elements/SearchBarForTitle/SearchBarForTitle'); }),
            _a['SearchBarForTitle/NoResultViewForSearch'] = loadable(function () {
                return import('./elements/SearchBarForTitle/NoResultViewForSearch');
            }),
            _a['member-edit/member-edit-view'] = loadable(function () { return import('./elements/member-edit/member-edit-view'); }),
            _a['puppet/TreeSelectSingle'] = loadable(function () { return import('./elements/puppet/TreeSelectSingle'); }),
            _a['currency/currency-dropdown'] = loadable(function () { return import('./elements/currency/currency-dropdown'); }),
            _a['input-tags'] = loadable(function () { return import('./elements/input-tags'); }),
            _a['feetype-tree-menu'] = loadable(function () { return import('./elements/feetype-tree-menu'); }),
            _a['select/ComplexSelect'] = loadable(function () { return import('./elements/select/ComplexSelect'); }),
            _a['select/ComplexSelectMultiple'] = loadable(function () { return import('./elements/select/ComplexSelectMultiple'); }),
            _a['puppet/Ref'] = loadable(function () { return import('./elements/puppet/Ref'); }),
            _a['ekbc-basic/breadcrumb'] = loadable(function () { return import('./elements/ekbc-basic/breadcrumb'); }),
            _a['payment-form-field/select-bank-field'] = loadable(function () {
                return import('./elements/payment-form-field/select-bank-field');
            }),
            _a['payment-form-field/select-area-field'] = loadable(function () {
                return import('./elements/payment-form-field/select-area-field');
            }),
            _a['payment-form-field/select-branch-field'] = loadable(function () {
                return import('./elements/payment-form-field/select-branch-field');
            }),
            _a['puppet/RefEnum'] = loadable(function () { return import('./elements/puppet/RefEnum'); }),
            _a['ekbc-basic/active-wrapper/active-wrapper'] = loadable(function () {
                return import('./elements/ekbc-basic/active-wrapper/active-wrapper');
            }),
            _a['FilterPopover'] = loadable(function () { return import('./elements/FilterPopover'); }),
            _a['edit-table-elements/StandardDateSelectDrodown/DateSelectDrodown'] = loadable(function () {
                return import('./elements/edit-table-elements/StandardDateSelectDrodown/DateSelectDrodown');
            }),
            _a['city/CityComponent'] = loadable(function () { return import('./elements/city/CityComponent'); }),
            _a['select-member-role-department'] = loadable(function () { return import('./elements/select-member-role-department'); }),
            _a['MarketADBanner/MarketADBanner'] = loadable(function () { return import('./elements/MarketADBanner/MarketADBanner'); }),
            _a['tag-selector-edit'] = loadable(function () { return import('./elements/tag-selector-edit'); }),
            _a['immersiveBtn/ImmersiveBtn'] = loadable(function () { return import('./elements/immersiveBtn/ImmersiveBtn'); }),
            _a['dataGrid/DataGrid'] = loadable(function () { return import('./elements/dataGrid/DataGrid'); }),
            _a['ScreeningDropDown/ScreeningDropDown'] = loadable(function () { return import('./elements/ScreeningDropDown/ScreeningDropDown'); }),
            _a['print-view'] = loadable(function () { return import('./elements/print-view'); }),
            _a['namePopover/PopoverWrapper'] = loadable(function () { return import('./elements/namePopover/PopoverWrapper'); }),
            _a['EKBPagination'] = loadable(function () { return import('./elements/EKBPagination'); }),
            _a['circle-chart'] = loadable(function () { return import('./elements/circle-chart'); }),
            _a['puppet/data-interconnection-config/DataConfig'] = loadable(function () {
                return import('./elements/puppet/data-interconnection-config/DataConfig');
            }),
            _a['transfer/SortableTransferTable'] = loadable(function () { return import('./elements/transfer/SortableTransferTable'); }),
            _a['transfer/SortableTransfer'] = loadable(function () { return import('./elements/transfer/SortableTransfer'); }),
            _a['search-dropdown'] = loadable(function () { return import('./elements/search-dropdown'); }),
            _a['puppet/entity-detail/entity-detail-fieldview'] = loadable(function () {
                return import('./elements/puppet/entity-detail/entity-detail-fieldview');
            }),
            _a['member-edit/DeptEditView'] = loadable(function () { return import('./elements/member-edit/DeptEditView'); }),
            _a['radio-group/EKBRadioGroup'] = loadable(function () { return import('./elements/radio-group/EKBRadioGroup'); }),
            _a['warningPopup/WarningPopup'] = loadable(function () { return import('./elements/warningPopup/WarningPopup'); }),
            _a['ConditionalEditComponent/ConditionalEditWrapper'] = loadable(function () {
                return import('./elements/ConditionalEditComponent/ConditionalEditWrapper');
            }),
            _a['ekbc-business/bills/expense-flow-config'] = loadable(function () {
                return import('./elements/ekbc-business/bills/expense-flow-config');
            }),
            _a['invoice-card'] = loadable(function () { return import('./elements/invoice-card'); }),
            _a['StaffsTagSelect'] = loadable(function () { return import('./elements/StaffsTagSelect'); }),
            _a['Skeleton'] = loadable(function () { return import('./elements/Skeleton/Skeleton'); }),
            _a['PayerInfoList'] = loadable(function () { return import('./elements/payerInfo'); }),
            _a['ColorPicker'] = loadable(function () { return import('./elements/ColorPicker'); }),
            _a['puppet/FakeInput'] = loadable(function () { return import('./elements/puppet/FakeInput'); }),
            _a['CurrencyMoney'] = loadable(function () { return import('./elements/currency/currency-money'); }),
            _a['FilterDateRange'] = loadable(function () { return import('./elements/filter-date-range/FilterDateRange'); }),
            _a['travel/card'] = loadable(function () { return import('./elements/new-travel-plan-item'); }),
            _a['StaffCredential'] = loadable(function () { return import('./components/dynamic/StaffCredential'); }),
            _a['PersonnelList'] = loadable(function () { return import('./elements//puppet/personnelList/PersonnelList'); }),
            _a['Brow'] = loadable(function () { return import('./elements/brow/Brow'); }),
            _a['BrowModal'] = loadable(function () { return import('./elements/brow/BrowModal'); }),
            _a['list-menu'] = loadable(function () { return import('./elements/ListMenu/list-menu'); }),
            _a['table-wrapper'] = loadable(function () { return import('./elements/data-grid-v2/TableWrapper'); }),
            _a)
    },
    {
        resource: '@components',
        value: (_b = {},
            _b['layout/ExceedStandardRiskForField'] = loadable(function () { return import('./components/layout/ExceedStandardRiskForField'); }),
            _b['dynamic/CityPickerComponent'] = loadable(function () { return import('./components/dynamic/CityPickerComponent'); }),
            _b['mc-authority/button'] = loadable(function () { return import('./components/mc_authority_button'); }),
            _b['mc-authority/enum'] = require('./components/mc_authority_button/enum').default,
            _b)
    },
    {
        resource: '@ekb-components',
        value: (_c = {},
            _c['business/breadcrumb'] = loadable(function () { return import('./ekb-components/business/breadcrumb'); }),
            _c['business/breadcrumb/index'] = loadable(function () { return import('./ekb-components/business/breadcrumb/index'); }),
            _c['business/breadcrumb/Title'] = loadable(function () { return import('./ekb-components/business/breadcrumb/Title'); }),
            _c['business/breadcrumb/Breadcrumb'] = loadable(function () { return import('./ekb-components/business/breadcrumb/Breadcrumb'); }),
            _c['base/puppet/EKBSelect'] = loadable(function () { return import('./ekb-components/base/puppet/EKBSelect'); }),
            _c['base/form/form-item'] = loadable(function () { return import('./ekb-components/base/form/form-item'); }),
            _c['base/puppet/EKBTreeSelect'] = loadable(function () { return import('./ekb-components/base/puppet/EKBTreeSelect'); }),
            _c['business/ekbsteps'] = loadable(function () { return import('./ekb-components/business/ekbsteps'); }),
            _c['business/role-selected/tags'] = loadable(function () { return import('./ekb-components/business/role-selected/tags'); }),
            _c['base/puppet/EKBCheckBox'] = loadable(function () { return import('./ekb-components/base/puppet/EKBCheckBox'); }),
            _c['base/puppet/EKBInput'] = loadable(function () { return import('./ekb-components/base/puppet/EKBInput'); }),
            _c['business/card/data-interconnection'] = loadable(function () {
                return import('./ekb-components/business/card/data-interconnection');
            }),
            _c['base/form/form-text'] = loadable(function () { return import('./ekb-components/base/form/form-text'); }),
            _c)
    },
    {
        resource: '@elements',
        value: (_d = {},
            _d['enhance/enhance-form-create'] = require('./elements/enhance/enhance-form-create').default,
            _d['feetype-icons'] = require('./elements/feetype-icons').default,
            _d['payPlan/table/table.store'] = require('./elements/payPlan/table/table.store'),
            _d['payPlan/types'] = require('./elements/payPlan/types'),
            _d['payPlan/helper/formatTableData'] = require('./elements/payPlan/helper/formatTableData'),
            _d['payPlan/table/ColumnFormat'] = require('./elements/payPlan/table/ColumnFormat'),
            _d['payPlan/helper/tableHelper'] = require('./elements/payPlan/helper/tableHelper'),
            _d['data-grid-v2/withLoader'] = require('./elements/data-grid-v2/withLoader').default,
            _d['data-grid-v2/LocaleProvider'] = require('./elements/data-grid-v2/LocaleProvider'),
            _d['ekbc-basic/layout/Box'] = require('./elements/ekbc-basic/layout/Box'),
            _d['data-grid/columnsUtil'] = require('./elements/data-grid/columnsUtil'),
            _d['data-grid-v2/DataGrid.module.less'] = require('./elements/data-grid-v2/DataGrid.module.less'),
            _d['puppet/MoneyNzh'] = require('./elements/puppet/MoneyNzh').default,
            _d['InvoiceCard/FormatInvoiceData'] = require('./elements/InvoiceCard/FormatInvoiceData'),
            _d['data-grid/columnOthers'] = require('./elements/data-grid/columnOthers'),
            _d['data-grid/fetchFixer'] = require('./elements/data-grid/fetchFixer'),
            _d['DataLinkTable/tableUtil'] = require('./elements/DataLinkTable/tableUtil'),
            _d['payPlan/helper/fnInitalValue'] = require('./elements/payPlan/helper/fnInitalValue'),
            _d['payPlan/table/credit.store'] = require('./elements/payPlan/table/credit.store'),
            _d['edit-table-elements/StandardDateSelectDrodown/utils/types'] = require('./elements/edit-table-elements/StandardDateSelectDrodown/utils/types'),
            _d['edit-table-elements/TableSelectItemView'] = require('./elements/edit-table-elements/TableSelectItemView'),
            _d['edit-table-elements/fnTreeFilterAvailable'] = require('./elements/edit-table-elements/fnTreeFilterAvailable'),
            _d['payee-account/utils'] = require('./elements/payee-account/utils'),
            _d['configure/config'] = require('./elements/configure/config'),
            _d['feeDetailViewList/Related'] = require('./elements/feeDetailViewList/Related'),
            _d['transfer/images/empty.svg'] = require('./elements/transfer/images/empty.svg'),
            _d['payee-account/account-list-consts'] = require('./elements/payee-account/account-list-consts'),
            _d['member-edit/util'] = require('./elements/member-edit/util'),
            _d['ConditionalEditComponent/Utils'] = require('./elements/ConditionalEditComponent/Utils'),
            _d['puppet/details/FormatDateUtils'] = require('./elements/puppet/details/FormatDateUtils'),
            _d['edit-table-elements/CurrencySetting'] = require('./elements/edit-table-elements/CurrencySetting'),
            _d['UniversalComponent'] = require('./elements/universal/StandardVersionComponent'),
            _d['StandardVersionComponent'] = require('./elements/universal/StandardVersionComponent'),
            _d['Types/ContextUtils'] = require('./components/dynamic/types'),
            _d['staffs/staffShowFn'] = require('./elements/staffs/staffShowFn').default,
            _d)
    },
    {
        resource: '@components',
        value: (_e = {},
            _e['reports'] = require('./components/reports').default,
            _e['index.editable'] = require('./components/index.editable'),
            _e['index.entitydetail'] = require('./components/index.entitydetail'),
            _e['index.interconnectal'] = require('./components/index.interconnectal'),
            _e['index.internal'] = require('./components/index.internal'),
            _e['index.readonly'] = require('./components/index.readonly'),
            _e['index.trips.readonly'] = require('./components/index.trips.readonly'),
            _e['layout/FormWrapper'] = require('./components/layout/FormWrapper'),
            _e['consts'] = require('./components/consts'),
            _e['utils/fnCurrencyObj'] = require('./components/utils/fnCurrencyObj'),
            _e['utils/fnPredefine4Date'] = require('./components/utils/fnPredefine4Date'),
            _e['utils/fnThousandBitSeparator'] = require('./components/utils/fnThousandBitSeparator'),
            _e['utils/fnFilterPaymentChannel'] = require('./components/utils/fnFilterPaymentChannel'),
            _e['validator/validator'] = require('./components/validator/validator'),
            _e['interconnectal/InterconInput'] = require('./components/interconnectal/InterconInput'),
            _e['utils/fnCheckPayerInfo'] = require('./components/utils/fnCheckPayerInfo'),
            _e['utils/fnFormartDatalinkData'] = require('./components/utils/fnFormartDatalinkData'),
            _e['utils/getInitialValue'] = require('./components/utils/getInitialValue').default,
            _e['utils/fnEntityDataParse'] = require('./components/utils/fnEntityDataParse'),
            _e['utils/utilFunctionsParams'] = require('./components/utils/utilFunctionsParams'),
            _e['utils/questionnaireConfig'] = require('./components/utils/questionnaireConfig'),
            _e['interconnectal/checkboxLabel'] = require('./components/interconnectal/checkboxLabel'),
            _e['interconnectal/CustomDimensionFilter.isValidCustomDimensions'] = require('./components/interconnectal/CustomDimensionFilter.isValidCustomDimensions'),
            _e['index.budget'] = require('./components/index.budget'),
            _e['index.supplier'] = require('./components/index.supplier'),
            _e)
    },
    {
        resource: '@images',
        value: (_f = {},
            _f['add.svg'] = require('./images/add.svg'),
            _f['delete.svg'] = require('./images/delete.svg'),
            _f['brand'] = require('./images/brand'),
            _f['changjie-logo.png'] = require('./images/changjie-logo.png'),
            _f['other-logo.svg'] = require('./images/other-logo.svg'),
            _f['auth-check-user.svg'] = require('./images/auth-check-user.svg'),
            _f['auth-check-user-lock.svg'] = require('./images/auth-check-user-lock.svg'),
            _f['auth-check-invalid.svg'] = require('./images/auth-check-invalid.svg'),
            _f['auth-manage-buy.svg'] = require('./images/auth-manage-buy.svg'),
            _f['refresh.svg'] = require('./images/refresh.svg'),
            _f['budget-tip.svg'] = require('./images/budget-tip.svg'),
            _f['budget_success.svg'] = require('./images/budget_success.svg'),
            _f['budget_draft_or_edit.svg'] = require('./images/budget_draft_or_edit.svg'),
            _f['home-arrow-right.svg'] = require('./images/home-arrow-right.svg'),
            _f['custom-plan-tip.svg'] = require('./images/custom-plan-tip.svg'),
            _f['external.svg'] = require('./images/external.svg'),
            _f['avatar.svg'] = require('./images/avatar.svg'),
            _f['no-select-exp.png'] = require('./images/no-select-exp.png'),
            _f['home-loan.png'] = require('./images/home-loan.png'),
            _f['home-requsition.png'] = require('./images/home-requsition.png'),
            _f['template-expense.png'] = require('./images/template-expense.png'),
            _f['template-loan.png'] = require('./images/template-loan.png'),
            _f['template-travel-requsition.png'] = require('./images/template-travel-requsition.png'),
            _f['template-travel.png'] = require('./images/template-travel.png'),
            _f['mine-corporation-info-placeHolderImg.svg'] = require('./images/mine-corporation-info-placeHolderImg.svg'),
            _f['empty-item.svg'] = require('./images/empty-item.svg'),
            _f['feetype_root.png'] = require('./images/feetype_root.png'),
            _f['add-condition.svg'] = require('./images/add-condition.svg'),
            _f['custom-flow-close.svg'] = require('./images/custom-flow-close.svg'),
            _f['custom-flow-del.svg'] = require('./images/custom-flow-del.svg'),
            _f['taxi.svg'] = require('./images/taxi.svg'),
            _f['eleme.svg'] = require('./images/eleme.svg'),
            _f['icon-move-up.svg'] = require('./images/icon-move-up.svg'),
            _f['icon-move-up-disabled.svg'] = require('./images/icon-move-up-disabled.svg'),
            _f['icon-move-down.svg'] = require('./images/icon-move-down.svg'),
            _f['icon-move-down-disabled.svg'] = require('./images/icon-move-down-disabled.svg'),
            _f['empty-detail.svg'] = require('./images/empty-detail.svg'),
            _f['bell.svg'] = require('./images/bell.svg'),
            _f['no-user-avator-square.svg'] = require('./images/no-user-avator-square.svg'),
            _f['icon-edit.svg'] = require('./images/icon-edit.svg'),
            _f['icon-delete.svg'] = require('./images/icon-delete.svg'),
            _f['newpageguide.svg'] = require('./images/newpageguide.svg'),
            _f['icon-asc.svg'] = require('./images/icon-asc.svg'),
            _f['icon-dsc.svg'] = require('./images/icon-dsc.svg'),
            _f['search-empty.svg'] = require('./images/search-empty.svg'),
            _f['roleAvatar.svg'] = require('./images/roleAvatar.svg'),
            _f['empty.svg'] = require('./images/empty.svg'),
            _f['no-user-avator.svg'] = require('./images/no-user-avator.svg'),
            _f['platform-default-icon.png'] = require('./images/platform-default-icon.png'),
            _f['role.svg'] = require('./images/role.svg'),
            _f['department.svg'] = require('./images/department.svg'),
            _f['tongyong.svg'] = require('./images/tongyong.svg'),
            _f['clyh.svg'] = require('./images/clyh.svg'),
            _f['xc-travel.png'] = require('./images/xc-travel.png'),
            _f['record-left.png'] = require('./images/record-left.png'),
            _f['record-right.png'] = require('./images/record-right.png'),
            _f['invoice-left.png'] = require('./images/invoice-left.png'),
            _f['invoice-right.png'] = require('./images/invoice-right.png'),
            _f['no_travel.svg'] = require('./images/no_travel.svg'),
            _f['home_record.svg'] = require('./images/home_record.svg'),
            _f['home_invoice.svg'] = require('./images/home_invoice.svg'),
            _f['order-top.svg'] = require('./images/order-top.svg'),
            _f)
    },
    {
        resource: '@hosting',
        value: (_g = {},
            _g['initializeTitle'] = require('./hosting/initializeTitle'),
            _g)
    }
];
