/**
 * created by zhoahuabing on 2018/5/17
 * */
import React, { PureComponent } from 'react'
import './Title.less'
import { isString, isFunction } from '@ekuaibao/helpers'

export default class Title extends PureComponent {
  state = { chargeTitle: undefined }

  componentWillReceiveProps(nextPorps) {
    if (nextPorps.changeTitleEffect !== this.props.changeTitleEffect) {
      this.setState({ chargeTitle: undefined })
    }
  }

  handleChangeTitle = chargeTitle => {
    this.setState({ chargeTitle })
  }

  renderTitle = () => {
    let { changeColor, TitleContainer, title, changeTitleEffect, tooltip, titleType = 'single', ...others } = this.props
    let classNameInner =
      'navigation-bar-title-content ' +
      (changeColor ? 'change-color ' : '') +
      (window.isNewHome ? ' navigation-bar-title-' + titleType : '')
    const { chargeTitle } = this.state
    let finalTitle = changeTitleEffect ? chargeTitle || title : title
    finalTitle = isString(finalTitle) ? i18n.get(finalTitle) : finalTitle
    return TitleContainer ? (
      <TitleContainer {...others} changeTitle={this.handleChangeTitle}>
        <div className={classNameInner}>{finalTitle}</div>
        {tooltip && tooltip()}
      </TitleContainer>
    ) : (
      <>
        <div className={classNameInner}>{finalTitle}</div>
        {tooltip && tooltip()}
      </>
    )
  }

  render() {
    let { statusText, otherView, RightView, bus, className = '', immersiveable, nodeLength, ...others } = this.props
    const { otherViewReplaceTitle, create } = others
    let classNameWrapper = 'navigation-bar-title-wrapper ' + className
    let renderTitleVisible = true
    let otherViewVisible = !!otherView
    if (otherViewReplaceTitle) {
      renderTitleVisible = nodeLength > 1
      otherViewVisible = nodeLength <= 1
    }
    if (otherViewReplaceTitle === false) {
      otherViewVisible = false
    }
    return (
      <div className={classNameWrapper}>
        {renderTitleVisible && this.renderTitle()}
        {otherViewVisible && (isFunction(otherView) ? otherView(create) : otherView)}
        {statusText && <div className="status-tag">{statusText}</div>}
        {RightView && <RightView {...others} changeTitle={this.handleChangeTitle} TitleBus={bus} />}
      </div>
    )
  }
}
