import { NetworkAdapter } from 'mobx-cobweb';
import { Fetch } from '@ekuaibao/fetch';
import { app } from '@ekuaibao/whispered';
export default function () {
    var corpId = encodeURIComponent(Fetch.ekbCorpId);
    var params = { corpId: corpId };
    var corpIds = [
        'zuY3K7aYWy3Gvf',
        'KME3xpxaWsgDCg',
        'rFA3IKwRGo0ku0',
        'mEJ3up0Mmw09n0',
        'Adh3iwvzAx000g',
        'wyR3gQCLL7048E',
        'QjI3ASXENYfb6g',
        'ID_3j9_YZ30k4k',
    ];
    if (Fetch.accessToken && !corpIds.includes(corpId)) {
        params['accessToken'] = Fetch.accessToken;
    }
    app.collection.setNetworkAdapter(new NetworkAdapter('/', {
        params: params
    }));
}
