import { groupBy, reduce } from 'lodash';
import { MoneyMath } from '@ekuaibao/money-math';
var Related = (function () {
    function Related() {
        this._relatedMap = {};
        this._expenseLink = [];
        this._expenseSpec = {};
        this._relatedItemMap = {};
    }
    Related.getInstance = function () {
        if (this._instance == null) {
            this._instance = new Related();
        }
        return this._instance;
    };
    Related.prototype.reduceMoney = function (arr) {
        if (arr === void 0) { arr = []; }
        return reduce(arr, function (sum, n) {
            return new MoneyMath(sum).add(n.consumeAmount).value;
        }, 0);
    };
    Object.defineProperty(Related.prototype, "relatedMap", {
        get: function () {
            var _temprelatedMap = {};
            var relates = Object.values(this._relatedMap);
            var cRelates = [];
            relates.forEach(function (relate) { return (cRelates = cRelates.concat(relate)); });
            var _relates = groupBy(cRelates, 'relateId');
            for (var key in _relates) {
                if (_relates[key]) {
                    _temprelatedMap[key] = this.reduceMoney(_relates[key]);
                }
            }
            return _temprelatedMap;
        },
        enumerable: true,
        configurable: true
    });
    Related.prototype.deleteRelateItem = function (consumId, id) {
        var arr = this._relatedMap[consumId];
        this._relatedMap[consumId] = arr ? arr.filter(function (line) { return line.relateId !== id; }) : arr;
    };
    Related.prototype.getUseMoneyByConsumeId = function (consumId) {
        var relateds = this._relatedMap[consumId];
        var moneys = relateds ? relateds.map(function (line) { return line.consumeAmount; }) : [];
        return reduce(moneys, function (sum, n) {
            return new MoneyMath(sum).add(n).value;
        }, 0);
    };
    Related.prototype.getDetialsMoneyByConsumeId = function (consumId) {
        return this._relatedMap[consumId];
    };
    Related.prototype.updateRelatedMap = function (consumId, id, value) {
        var relateds = this._relatedMap[consumId];
        this._relatedMap[consumId] = relateds
            ? relateds.map(function (line) {
                if (line.relateId === id) {
                    line.consumeAmount = value;
                }
                return line;
            })
            : relateds;
    };
    Related.prototype.setRelatedMap = function (data) {
        var id = data.id, value = data.value;
        if (value && id) {
            this._relatedMap[id] = value;
        }
    };
    Related.prototype.removeByConsumeId = function (consumeId) {
        if (this._relatedMap[consumeId]) {
            delete this._relatedMap[consumeId];
        }
    };
    Related.prototype.setExpenseLink = function (expenseLink) {
        this._expenseLink = expenseLink || [];
    };
    Related.prototype.setExpenseSpecification = function (specification) {
        this._expenseSpec = specification;
    };
    Object.defineProperty(Related.prototype, "expenseLink", {
        get: function () {
            return this._expenseLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Related.prototype, "expenseLinkIds", {
        get: function () {
            return this._expenseLink.map(function (line) { return (line === null || line === void 0 ? void 0 : line.id) || line; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Related.prototype, "specificationConfig", {
        get: function () {
            var _a = this._expenseSpec, notOverIsChecked = _a.notOverIsChecked, rule = _a.rule;
            var ids = this.expenseLinkIds;
            var allowAdd = rule === 'DETAIL_RULE' && !!ids.length;
            return { allowAdd: allowAdd, notOverIsChecked: notOverIsChecked };
        },
        enumerable: true,
        configurable: true
    });
    Related.prototype.setTempConsumId = function (tempConsumeId) {
        this._tempConsumId = tempConsumeId;
    };
    Object.defineProperty(Related.prototype, "tempConsumId", {
        get: function () {
            return this._tempConsumId;
        },
        enumerable: true,
        configurable: true
    });
    Related.prototype.setRelatedItemMap = function (data) {
        var id = data.id, value = data.value;
        this._relatedItemMap[id] = value;
    };
    Object.defineProperty(Related.prototype, "relatedItemMap", {
        get: function () {
            return this._relatedItemMap;
        },
        enumerable: true,
        configurable: true
    });
    Related.prototype.clearRelatedData = function () {
        this._relatedMap = {};
        this._expenseLink = [];
        this._tempConsumId = '';
        this._expenseSpec = {};
        this._relatedItemMap = {};
    };
    Related._instance = null;
    return Related;
}());
export default Related;
var related = Related.getInstance();
export { related };
