import { app as api } from '@ekuaibao/whispered';
export function getColumnType(type) {
    return type.toUpperCase();
}
var openDimension = function (params) {
    var data = params.data ? { selectedNode: params.data } : undefined;
    var name = 'basedata.Dimension.法人实体';
    return api.invokeService('@common:get:staff:dimension', { name: name }).then(function (res) {
        return api.open('@layout:SelectTreeModal', {
            dataset: res.items,
            dataIndex: '法人实体',
            title: '法人实体',
            data: data,
            searchType: 'select',
            selectRange: 'all',
            entityId: name
        });
    });
};
export function openPayeeInfo(params) {
    return api.open('@bills:SelectPayeeModal', params);
}
export function getTablePageSize(editable) {
    return editable ? 10 : 100;
}
export var funcMap = {
    PAYEEINFO: openPayeeInfo,
    LEGALENTITY: openDimension,
};
export var fixedColumnCount = 4;
