import React, { PureComponent } from 'react'

import styles from './index.module.less'
import { Switch } from 'antd'
import classnames from 'classnames'
import svgadd from '../../../../images/icon-add.svg'
import svgj from '../../../../images/icon-j.svg'
import svgtitle from '../../../../images/icon-title.svg'

export default class DataTreeCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      show: true
    }
  }
  renderAction(item) {
    let { actionName, onClick } = this.props
    return (
      <div className="edit-btn" onClick={() => onClick && onClick(item)}>
        {actionName}
      </div>
    )
  }
  renderChild() {
    let { childName = '', onClickChild, showType = '' } = this.props
    if (showType !== '') {
      return null
    }
    return (
      <div className="edit-btn" onClick={() => onClickChild && onClickChild()}>
        {childName}
      </div>
    )
  }

  renderSwitch(item) {
  // todo zhb 开关
    let { onChange, disabledEdit } = this.props
    if (disabledEdit) {
      return null
    }
    return (
      <Switch
        checkedChildren={i18n.get('开')}
        unCheckedChildren={i18n.get('关')}
        defaultChecked={item.active}
        onChange={e => onChange && onChange(item, e)}
      />
    )
  }

  renderEditable(item, bool) {
    return (
      <div style={{ display: 'flex' }}>
        {bool && this.renderChild(item)}
        {this.renderAction(item)}
        {this.renderSwitch(item)}
      </div>
    )
  }

  renderDisable(item) {
    return this.renderSwitch(item)
  }

  renderShowAction(item) {
    return this.renderAction(item)
  }

  renderRight(item, bool) {
    let { isShowSwitch = true } = this.props
    let { active } = item
    if (isShowSwitch) {
      if (active) {
        return this.renderEditable(item, bool)
      }
      return this.renderDisable(item)
    }
    return this.renderShowAction(item)
  }
  toggle = () => {
    let {
      item: { children }
    } = this.props
    if (children.length == 0) {
      return
    }
    this.setState({
      show: !this.state.show
    })
  }
  renderTree = (childs = []) => {
    if (childs.length == 0) {
      return <p className="pno">{i18n.get('暂无数据')}</p>
    }
    return childs.map((it, index) => {
      let { id, name, active } = it
      it.child = true
      it.parent = { id: this.props.id, name: this.props.name }
      return (
        <div className={styles['data-tree-box']} key={id + index}>
          <img src={svgj} />
          <div className="card-content">
            <div className="left">
              <div className="title">{i18n.get(name)}</div>
              <div className="id">{`ID:${id}`}</div>
            </div>
            <div className="right">{this.renderRight(it)}</div>
          </div>
        </div>
      )
    })
  }
  render() {
    let {
      name,
      id,
      isChild,
      last,
      item: { children, allowAddSubType },
      item
    } = this.props
    //let classNames = classnames(styles.cardWrapper, { [styles.disable]: !isOpen && !isShowImag }, className)
    //let nameClass = classnames('name', { disableName: !isOpen && !isShowImag })
    let ticon = classnames('t-icon', { 't-icon-no': last })
    let { show } = this.state
    let src = show ? svgtitle : svgadd
    src = children.length > 0 ? src : svgj

    return (
      <div className={styles['data-tree-card']}>
        <span className={ticon}>
          <img src={src} onClick={this.toggle} />
        </span>
        <div className="card-content">
          <div className="left">
            <div className="title">{i18n.get(name)}</div>
            <div className="id">{`ID:${id}`}</div>
          </div>
          <div className="right">{this.renderRight(item, allowAddSubType)}</div>
        </div>
        {show && children.length > 0 && <div className="treelist">{isChild && this.renderTree(children)}</div>}
      </div>
    )
  }
}
