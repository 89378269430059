/***************************************************
 * Created by nanyuantingfeng on 2020/4/23 12:26. *
 ***************************************************/
import { app as api } from '@ekuaibao/whispered'

export function emitResetFieldsExternals(external) {
  if (!external) return
  const mesKey = `external:${external[0].path}:edit`
  api.emit(mesKey, external[0])
}
