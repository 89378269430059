import { __assign, __decorate, __extends, __read, __spread } from "tslib";
import React, { PureComponent } from 'react';
import { EnhanceField } from '@ekuaibao/template';
import styles from './WeekSelectItem.module.less';
import { Checkbox, Tag } from 'antd';
import { wrapper } from '../../../../components/layout/FormWrapper';
var CheckableTag = Tag.CheckableTag;
import { cloneDeep } from 'lodash';
var WeekSelectItem = (function (_super) {
    __extends(WeekSelectItem, _super);
    function WeekSelectItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleOnChecked = function (e) {
            var _a = _this.props, onChange = _a.onChange, name = _a.field.name, value = _a.value, bus = _a.bus;
            bus.setValidateLevel(1);
            var isChecked = e.target.checked;
            onChange && onChange(__assign(__assign({}, value), { isChecked: isChecked, type: name }));
        };
        return _this;
    }
    WeekSelectItem.prototype.handleChange = function (tag, checked) {
        var _a = this.props, value = _a.value, onChange = _a.onChange;
        var tags = cloneDeep(value.selectedTags) || [];
        var nextSelectedTags = checked ? __spread(tags, [tag]) : tags.filter(function (t) { return t !== tag; });
        this.setState({ selectedTags: nextSelectedTags });
        value.selectedTags = nextSelectedTags;
        onChange && onChange(__assign({}, value));
    };
    WeekSelectItem.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.field, showLabel = _b.showLabel, tagsList = _b.tagsList, _c = _a.value, isChecked = _c.isChecked, disabled = _c.disabled, _d = _c.selectedTags, selectedTags = _d === void 0 ? [] : _d;
        return (React.createElement("div", { className: styles['weekSelect-wrapper'] },
            React.createElement("div", { className: "weekSelect-title" },
                React.createElement(Checkbox, { onChange: this.handleOnChecked, defaultChecked: isChecked, disabled: disabled },
                    React.createElement("span", null, showLabel)),
                React.createElement("div", { className: "weekSelect-content" }, isChecked &&
                    tagsList &&
                    tagsList.map(function (tag) { return (React.createElement(CheckableTag, { key: tag, checked: selectedTags.indexOf(tag) > -1, onChange: function (checked) { return _this.handleChange(tag, checked); } }, tag)); })))));
    };
    WeekSelectItem = __decorate([
        EnhanceField({
            descriptor: {
                type: 'dimension-week-select'
            },
            validator: function (field) { return function (rule, value, callback) {
                if (rule.level === 1) {
                    return callback();
                }
                return callback();
            }; },
            initialValue: function (props) {
                var _a = props.field, disabled = _a.disabled, name = _a.name, isChecked = _a.isChecked;
                return { type: name, disabled: disabled, isChecked: isChecked };
            },
            wrapper: wrapper()
        })
    ], WeekSelectItem);
    return WeekSelectItem;
}(PureComponent));
export default WeekSelectItem;
