/**************************************************
 * Created by nanyuantingfeng on 21/09/2017 17:02.
 **************************************************/

import { isCity } from '@ekuaibao/lib/lib/propertySetIs'

export default function(property, defaultType) {
  if (isCity(property)) return false
  if (property?.dataType?.entity?.startsWith('basedata.Enum')) return false
  return defaultType
}
