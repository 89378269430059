import React from 'react'

export function permissionName(key) {
  let config = {
    SYS_ADMIN: i18n.get('系统管理'),
    REPORT_VIEW: i18n.get('费用报表管理'),
    BUDGET_MANAGE: i18n.get('预算管理'),
    LOAN_MANAGE: i18n.get('借款管理'),
    EXPENSE_MANAGE: i18n.get('报销单管理'),
    REQUISITION_MANAGE: i18n.get('申请管理'),
    CUSTOM_REPORT_VIEW: i18n.get('高级报表'),
    SETTLEMENT_MANAGE: i18n.get('结算方式管理'),
    TRIP_MANAGE: i18n.get('差旅管理'),
    CORP_WALLET_MANAGE: i18n.get('企业钱包管理'),
    VIRTUAL_CARD_MANAGE: i18n.get('E商卡管理'),
    INVOICE_MANAGE: i18n.get('票据管理'),
    SUPPLEMENT_INVOICE: i18n.get('补充发票'),
    SMART_REPORT: i18n.get('智能报表管理'),
    AUDIT_ADMIN: i18n.get('审计日志'),
    BANK_ACCOUNT_MANAGE: i18n.get('账户管理'),
    RECEIPT_ACCOUNT: i18n.get('账户管理-收款账户'),
    PAYMENT_ACCOUNT: i18n.get('账户管理-付款账户'),
    PAYMENT_WORKBENCH: i18n.get('支付数据管理'),
    // PAYMENT_MANAGE: i18n.get('支付管理'),
    CREDIT_MANAGE: i18n.get('信用管理'),
    MALL_MANAGE: i18n.get('商城管理'),
    INVOICE_REVIEW: i18n.get('发票复核管理'),
    INVOICE_DISCOUNT: i18n.get('进项抵扣管理'),
    CONSUMER_MATTERS: i18n.get('自动报销管理'),
    RECEIPT_MANAGE: i18n.get('收款单管理'),
    CHECKING_BILL_MANAGE: i18n.get('对账结算管理'),
    RESEARCHERS_ACTIVATE: i18n.get('人员激活管理'),
    CORP_PAYMENT_MANAGE: i18n.get('对公付款管理'),
    CONTACTS_MANAGE: i18n.get('通讯录管理'),
    KA_TOBACCO_CHECKING_MANAGER: i18n.get('部门或科室对账'),
    EXTEND_DIMENSION:i18n.get('扩展档案管理'),
    CORP_AGENCY_EXPENSE: i18n.get('企业代报销'),
    PAYMENT_MANAGEMENT: i18n.get('企业支付管理'),
    FLOW_ADMIN: i18n.get('审批流管理'),
    ROLE_ADMIN: i18n.get('角色管理'),
    INVOICE_REVIEW_QUICK_EXPENSE: i18n.get('发票复核管理（费用明细）')
  }

  return config[key]
}

export function permissionDescribe(key) {
  let config = {
    SYS_ADMIN: <div>{i18n.get('permissionDescribe')}</div>,
    REPORT_VIEW: <div>{i18n.get('REPORT_VIEW')}</div>,
    BUDGET_MANAGE: <div>{i18n.get('BUDGET_MANAGE')}</div>,
    LOAN_MANAGE: <div>{i18n.get('LOAN_MANAGE')}</div>,
    EXPENSE_MANAGE: <div>{i18n.get('EXPENSE_MANAGE')}</div>,
    REQUISITION_MANAGE: <div>{i18n.get('REQUISITION_MANAGE')}</div>,
    CUSTOM_REPORT_VIEW: <div>{i18n.get('CUSTOM_REPORT_VIEW')}</div>,
    SETTLEMENT_MANAGE: <div>{i18n.get('可维护企业的结算方式。')}</div>,
    TRIP_MANAGE: <div>{i18n.get('可以管理企业差旅钱包，查看差旅交易流水 ，进行差旅相关配置。')}</div>,
    CORP_WALLET_MANAGE: <div>{i18n.get('管理并查看企业账户的信息及交易数据，操作充值')}</div>,
    VIRTUAL_CARD_MANAGE: <div>{i18n.get('管理并查看企业成员的E商卡信息及数据，配置消费标准')}</div>,
    INVOICE_MANAGE: <div>{i18n.get('INVOICE_MANAGE')}</div>,
    SUPPLEMENT_INVOICE: <div>{i18n.get('SUPPLEMENT_INVOICE')}</div>,
    SMART_REPORT: <div>{i18n.get('可管理智能报表后台')}</div>,
    AUDIT_ADMIN: <div>{i18n.get('可查看企业审计日志，与系统管理权限互斥。')}</div>,
    BANK_ACCOUNT_MANAGE: <div>{i18n.get('可管理企业付款账户、收款账户。')}</div>,
    RECEIPT_ACCOUNT: <div>{i18n.get('可管理企业收款账户。')}</div>,
    PAYMENT_ACCOUNT: <div>{i18n.get('可管理企业付款账户。')}</div>,
    // PAYMENT_MANAGE: <div>{i18n.get('可查看和管理支付相关数据。')}</div>,
    PAYMENT_WORKBENCH: <div>{i18n.get('可查看支付相关数据。')}</div>,
    CREDIT_MANAGE: <div>{i18n.get('设置和发布信用模型，管理加减分规则，管理信用数据。')}</div>,
    MALL_MANAGE: <div>{i18n.get('可拥有查看合思商城后台的权限。')}</div>,
    INVOICE_REVIEW: <div>{i18n.get('具有发票复核的操作与查看风险的权限')}</div>,
    INVOICE_DISCOUNT: <div>{i18n.get('具有进项抵扣管理的操作权限')}</div>,
    CONSUMER_MATTERS: <div>{i18n.get('可配置消费事项、费用生成、自动提报的相关规则')}</div>,
    RECEIPT_MANAGE: <div>{i18n.get('可查看和管理企业中所有收款单，并将其导出为Excel。')}</div>,
    CHECKING_BILL_MANAGE: <div>{i18n.get('可拥有对账结算中心的操作权限')}</div>,
    RESEARCHERS_ACTIVATE: <div>{i18n.get('可以激活所在部门的全部员工，系统管理员默认有激活所有员工的权限')}</div>,
    CORP_PAYMENT_MANAGE: <div>{i18n.get('对公付款管理')}</div>,
    KA_TOBACCO_CHECKING_MANAGER: <div>{i18n.get('部门或科室对账')}</div>,
    CONTACTS_MANAGE: <div>{i18n.get('可用于管理企业员工信息')}</div>,
    EXTEND_DIMENSION: <div>{i18n.get('可管理企业内的扩展档案')}</div>,
    CORP_AGENCY_EXPENSE: <div>{i18n.get('企业代报销')}</div>, 
    PAYMENT_MANAGEMENT: <div>{i18n.get('可操作支付复核及配置复核规则')}</div>,
    CORP_AGENCY_EXPENSE: <div>{i18n.get('企业代报销')}</div>,
    FLOW_ADMIN: <div>{i18n.get('可管理企业审批流，对审批流程进行维护')}</div>,
    ROLE_ADMIN: <div>{i18n.get('可管理企业角色，维护角色对应人员')}</div>,
    INVOICE_REVIEW_QUICK_EXPENSE: <div>{i18n.get('发票复核管理（费用明细）')}</div>
  }
  /*
   系统管理员权限：用于维护企业信息及数据，可为企业成员配置权限和进行角色组管理，对消费类型、模板、审批流、项目进行自定义。
   费用报表查看权限：可查看企业费用报表。
   借款管理权限：可查看管理企业借款情况，对成员还款进行确认。
   报销单管理权限：可查看企业中所有报销单，并将其导出为Excel。
   申请单管理权限：查看企业中的所有申请单以及它们的报销情况。
   高级报表：可查看并管理高级报表。
   发票管理：可查看并管理所有导入的发票及项目明细，可集中维护「统一开票」、「待开发票」的单据。
   补充发票：可在「待办」列表中，快速查找「待开发票」的单据；可在「首页」、「待办」各列表中进行「补充发票」操作。
   */
  return config[key]
}

export const allPermissions = [
  'SYS_ADMIN',
  'REPORT_VIEW',
  'EXPENSE_MANAGE',
  'LOAN_MANAGE',
  'BUDGET_MANAGE',
  'REQUISITION_MANAGE',
  'CUSTOM_REPORT_VIEW',
  'SETTLEMENT_MANAGE',
  'TRIP_MANAGE',
  'CORP_WALLET_MANAGE',
  'VIRTUAL_CARD_MANAGE',
  'INVOICE_MANAGE',
  'SUPPLEMENT_INVOICE',
  'SMART_REPORT',
  'AUDIT_ADMIN',
  'BANK_ACCOUNT_MANAGE',
  'RECEIPT_ACCOUNT',
  'PAYMENT_ACCOUNT',
  'PAYMENT_WORKBENCH',
  'newMall',
  // 'PAYMENT_MANAGE',
  'CREDIT_MANAGE',
  'RESEARCHERS_ACTIVATE',
  'INVOICE_REVIEW_QUICK_EXPENSE'
]
