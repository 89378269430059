var _a;
import loadable from '@loadable/component';
export default {
    resource: '@lib',
    value: (_a = {},
        _a['attachment-fetch'] = require('./lib/attachment-fetch'),
        _a['entity.join'] = require('./lib/entity.join'),
        _a['expenseMangerUtils'] = require('./lib/expenseMangerUtils'),
        _a['export-excel-service'] = require('./lib/export-excel-service'),
        _a['lib-util'] = require('./lib/lib-util'),
        _a['misc'] = require('./lib/misc'),
        _a['filtersFixer'] = require('./lib/filtersFixer').default,
        _a['mutil-staff-fetch'] = require('./lib/mutil-staff-fetch').default,
        _a['dragSort'] = loadable(function () { return import('./lib/dragSort'); }),
        _a['sourceMap'] = require('./lib/souceMap').default,
        _a['fee-util'] = require('./lib/fee-util'),
        _a['token-data'] = require('./lib/token-data').default,
        _a['datalink-table-column-parse'] = require('./elements/DataLinkTable/tableUtil'),
        _a['columns-util'] = require('./elements/data-grid/columnsUtil'),
        _a['entity-select-join'] = require('./lib/entity.join'),
        _a['requisition-relate'] = require('./elements/feeDetailViewList/Related'),
        _a)
};
