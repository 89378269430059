/**************************************************
 * Created by panwei on 2017/5/24 下午9:17.
 **************************************************/
import { calcDetailsAmount, total, getTaxAmount } from '@ekuaibao/lib/lib/lib-util'
import { checkPayerInfo } from './lib-util'
import { cloneDeep, forEach, concat, get, uniqWith, each, map, indexOf, isEqual } from 'lodash'
import { getFeeTypeFormFormInvoice } from './InvoiceUtil'
import moment from 'moment'
import { app as api } from '@ekuaibao/whispered'
import { getV } from '@ekuaibao/lib/lib/help'
import { related } from '../elements/feeDetailViewList/Related'
import InvoiceMappingValue from './InvoiceMappingValue'
import { isHongShanTestingEnterprise } from '@ekuaibao/lib/lib/help'
import { Fetch } from '@ekuaibao/fetch'

let _TEM_LIST = []

export function getVisibleFeeTypeList(list, filterIds) {
  _TEM_LIST = cloneDeep(list)
  let data = cloneDeep(list)
  if (!filterIds || !filterIds.length) {
    return data
  }

  let filterItems = getFilterList(data, filterIds)
  let fullItems = []
  forEach(filterItems, item => {
    let fullPath = getFullPath(data, item)
    if (!fullPath.length) {
      //选择的是父路径
      fullItems.push(item)
    } else {
      fullPath.push(item)
      fullItems = concat(fullItems, fullPath)
    }

    fullItems = concat(fullItems, getAllChildren(item))
  })
  fullItems = uniqWith(fullItems, isEqual)
  return listToTree(fullItems, { idKey: 'id', parentKey: 'parentId', childrenKey: 'children' })
}

export function getFeeTypeById(list, feeTypeId) {
  let feeType = undefined
  let fn = (list, feeTypeId) => {
    each(list, item => {
      if (item.id === feeTypeId) {
        feeType = item
        return
      }

      fn(item.children, feeTypeId)
    })
  }

  if (list && list.length > 0) fn(list, feeTypeId)
  return feeType
}

//TODO:之前没有处理没有可用消费类型的情况，引用处需要处理
export function getDefaultFeeType(list) {
  let defaultFeeType = undefined
  if (list && list.length > 0) {
    let firstObj = list && list[0]
    let fn = item => {
      if (item.children && item.children.length) {
        fn(item.children[0])
      } else {
        defaultFeeType = item
      }
    }

    fn(firstObj)
    return defaultFeeType
  } else {
    return defaultFeeType
  }
}

export function getFeeTypePath(list, feeType) {
  _TEM_LIST = list
  let parents = getFullPath(list, feeType, [])
  parents.unshift(feeType)
  let data = parents.reverse()
  return map(data, item => item.name).join('/')
}

export function fnGetVisibleIds(list) {
  let result = []
  let fn = (list, result = []) => {
    list.forEach(item => {
      result.push(item.id)
      if (item.children && item.children.length) fn(item.children, result)
    })
  }

  fn(list, result)
  return result
}

function getAllChildren(parent) {
  let children = []
  let fn = item => {
    forEach(item.children, c => {
      children.push(c)
      fn(c)
    })
  }

  fn(parent)
  return children
}

function getFilterList(list, filterIds) {
  let tempList = []
  each(list, item => {
    let fn = data => {
      each(data, line => {
        if (!!~indexOf(filterIds, line.id)) {
          tempList.push(line)
        }

        fn(line.children)
      })
    }

    if (!!~indexOf(filterIds, item.id)) {
      tempList.push(item)
    }

    fn(item.children)
  })
  return tempList
}

function getFullPath(list, selectedItem, result = []) {
  list.forEach(el => {
    let node = undefined
    if (el.id === selectedItem.parentId) {
      node = el
      result.push(el)
    }

    if (node) {
      getFullPath(_TEM_LIST, node, result)
    } else {
      getFullPath(el.children || [], selectedItem, result)
    }
  })
  return result
}

function listToTree(data, options) {
  options = options || {}
  let ID_KEY = options.idKey || 'id'
  let PARENT_KEY = options.parentKey || 'parent'
  let CHILDREN_KEY = options.childrenKey || 'children'

  let tree = [],
    childrenOf = {}
  let item, id, parentId

  for (let i = 0, length = data.length; i < length; i++) {
    item = data[i]
    id = item[ID_KEY]
    parentId = item[PARENT_KEY] || 0
    childrenOf[id] = childrenOf[id] || []
    item[CHILDREN_KEY] = childrenOf[id]
    if (parentId !== 0) {
      childrenOf[parentId] = childrenOf[parentId] || []
      childrenOf[parentId].push(item)
    } else {
      tree.push(item)
    }
  }

  return tree
}

export function fnCheckPayerInfo(value = []) {
  value.forEach(v => {
    if (!v.feeTypeForm) return
    let { orders = [] } = v.feeTypeForm
    if (orders.length) {
      let invoice = orders.find(o => o && o.platform === 'fp')
      if (invoice) {
        let { showError } = checkPayerInfo(invoice.data)
        v.errorMsg = v.errorMsg || {}
        if (showError) {
          v.errorMsg['payerInfo'] = i18n.get('发票信息与企业开票信息不一致；')
        } else {
          v.errorMsg['payerInfo'] = ''
        }
      }
    }
  })
}

export function formatNewTemplateValue(value = {}, template = [], isKeepInvoiceValue) {
  const v = cloneDeep(value)
  const isHongShanTest = isHongShanTestingEnterprise(Fetch.ekbCorpId)
  Object.keys(v).forEach(key => {
    const field = template.find(item => item.field === key)
    const type = get(field, 'dataType.type', '')
    if (isHongShanTest && get(field, 'defaultValue.type') === 'constant') {
      if (v[field.field]?.id === get(field, 'defaultValue.value.id')) {
        return
      }
    }
    if (field && !field.editable && field.field !== 'invoiceForm' && type !== 'text') {
      delete v[key]
    }

    if (key === 'expenseLink' || key === 'expenseLinks') {
      fnClearExpenseLink(template, v, key)
    }
  })
  delete v?.travelPlanning
  return v
}

function fnClearExpenseLink(template, v, key) {
  const expenseLink = template.find(line => line.field === 'expenseLink' || line.field === 'expenseLinks') || {}
  const expenseLinkFlag = key !== expenseLink.field
  // expenseLinks 多选单选切换时
  const expenseLinksFlag =
    key === 'expenseLinks' && !get(expenseLink, 'allowMultipleRequests') && get(v, 'expenseLinks', []).length > 1
  if (expenseLinkFlag || expenseLinksFlag) {
    v.details &&
      v.details.forEach(line => {
        if (line.feeTypeForm.linkDetailEntities) {
          delete line.feeTypeForm.linkDetailEntities
        }
      })
    delete v[key]
    related.clearRelatedData()
  }
}

/**
 * 切换模版的时候清理掉自动计算的值
 * @param templates
 * @param values
 * @param autoCalFields
 * @returns {*}
 */
export async function initDefaultValue(templates, values, autoCalFields) {
  if (!templates || !values) return values
  const tmpMap = {}
  let isKeepInvoiceValue = false
  templates.forEach(element => {
    tmpMap[element.field || '@'] = element
    if (element.field && element.field === 'invoiceForm') {
      isKeepInvoiceValue = !element.editable || element.invoiceType.exist // 已有发票和无需填写时要保留发票
    }
  })
  let v = formatNewTemplateValue(values, templates, isKeepInvoiceValue)
  v = await formatFeetypeValues(v, templates)
  formatForConstant(v, templates)
  return v
}

// 固定值在费用类型切换时，清除当前值，分摊数据不清空
function formatForConstant(values, templates) {
  const isHongShanTest = isHongShanTestingEnterprise(Fetch.ekbCorpId)
  templates.forEach(line => {
    const isAmountField =
      get(values, 'invoiceForm.invoices', []).length &&
      line?.dataType?.type === 'money' &&
      line?.defaultValue?.type === 'none' &&
      line?.editable === false
    const blackList = ['invoiceForm', 'apportions', 'invoice']
    if (isAmountField) {
      blackList.push(line.field)
    }
    if (
      get(line, 'editable') === false &&
      !blackList.includes(line.field) &&
      get(line, 'defaultValue.type') !== 'invoiceSum'
    ) {
      if (isHongShanTest && get(line, 'defaultValue.type') === 'constant') {
        if (values[line.field]?.id === get(line, 'defaultValue.value.id')) {
          return
        }
      }
      values[line.field] = undefined
    }
  })
}

async function formatFeetypeValues(value = {}, template = []) {
  const invoiceCorporation = get(value, 'invoiceForm.invoiceCorporation')
  const invoices = get(value, 'invoiceForm.invoices', []) || []
  const attachments = get(value, 'invoiceForm.attachments', []) || []
  if (!invoices.length && !attachments.length && !invoiceCorporation) {
    delete value.invoiceForm
    return value
  }
  // 已经包含的发票需要计算出金额
  if (value.invoiceForm && invoices.length) {
    const invoiceMappingValue = new InvoiceMappingValue()
    let detailValue = await invoiceMappingValue.invoice2FeeTypeForm(invoices, template)
    // let detailValue = invoiceForDetails(invoices, template) 这种赋值方法没有按照配置赋值规则来赋值
    if (detailValue.invoiceForm) {
      detailValue.invoiceForm.invoices = value.invoiceForm.invoices
      attachments.length && (detailValue.invoiceForm.attachments = attachments)
    }
    // 切换费用类型时，手动修改的字段值保持不变，不被自动计算值覆盖
    value = { ...detailValue, ...value }
  }

  const field = template.find(f => f.field === 'invoiceForm')
  if (!field) return value
  const canImportPhoto = get(field, 'importMode.photo', true)
  if (!canImportPhoto && attachments.length) {
    value.invoiceForm.attachments = undefined
  }
  if (
    !field.editable &&
    field.defaultValue.value === 'noWrite' &&
    value.invoiceForm &&
    value.invoiceForm.type !== 'unify'
  ) {
    // 无需填写
    return value
  }
  if (!field.editable || !field.invoiceType.exist) {
    delete value.invoiceForm
    return value
  }
  return value
}

export function invoiceForDetails(invoices, template) {
  let detailValus = []
  let detailValue = {}
  invoices.forEach(line => {
    const detailValue = getFeeTypeFormFormInvoice(template, line)
    detailValus.push(detailValue)
  })
  if (detailValus.length) {
    const haveTaxAmount = template.find(
      line =>
        line.field === 'taxAmount' && (line.defaultValue.type === 'none' || line.defaultValue.type === 'invoiceSum')
    )
    const haveNoTaxAmount = template.find(
      line =>
        line.field === 'noTaxAmount' && (line.defaultValue.type === 'none' || line.defaultValue.type === 'invoiceSum')
    )
    const haveTaxRate = template.find(line => line.field === 'taxRate')
    const haveTaxTotal = template.find(line => line.type === 'money' && line.defaultValue.value === 'taxTotal')
    detailValue = detailValus[0]
    detailValue.amount = calcDetailsAmount(detailValus)
    haveNoTaxAmount ? (detailValue.noTaxAmount = total(detailValus.map(line => line.noTaxAmount))) : ''
    haveTaxAmount ? (detailValue.taxAmount = total(detailValus.map(line => line.taxAmount))) : ''
    haveTaxRate ? (detailValue.taxRate = getTaxAmount(detailValus.map(line => line.taxRate))) : ''
    haveTaxTotal ? (detailValue[haveTaxTotal.field] = total(detailValus.map(line => line[haveTaxTotal.field]))) : ''
  }
  // formatInvoiceForTax(template, detailValue)
  return detailValue
}

export function buildFeeDateString(feeDate) {
  if (!feeDate) {
    return feeDate
  }
  if (typeof feeDate === 'object') {
    let { start, end } = feeDate
    start = moment(start).format('YYYY/MM/DD')
    end = moment(end).format('YYYY/MM/DD')
    return `${start} ~ ${end}`
  }
  return moment(feeDate).format('YYYY/MM/DD')
}

export function lineSelectCurrencyDisable(field, details, index) {
  let result = isSelectCurrencyDisable(field, details)
  if (result && details.length === 1 && index === 0) {
    result = false
  }
  return result
}

export function isSelectCurrencyDisable(field, details) {
  if (!details || !details.length) {
    return false
  }
  const keepCurrencyEqual = getV(field, 'mustAllFeeTypeCurrencyEqual', false)
  const currency = api.getState('@common').powers.Currency
  return keepCurrencyEqual && currency
}

export function getForeignNumCode(details = []) {
  const detail = details[0]
  const foreignNumCode = getV(detail, 'feeTypeForm.amount.foreignNumCode', '')
  return foreignNumCode
}

export function getInvoiceIdsByFeeTypeForm(data = {}) {
  const invoices = get(data, 'feeTypeForm.invoiceForm.invoices', [])
  return (
    invoices &&
    invoices.map(line => {
      const detailIds = line.details ? line.details.map(item => item.id) : []
      const id = get(line, 'master.id', '')
      return { masterId: id, detailIds }
    })
  )
}
