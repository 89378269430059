/**
 *  Created by gym on 2018/5/15 下午2:40.
 */

import * as React from 'react'
import styles from './form-text.module.less'

export default class FormText extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { label, text, tips, imgUrl, style, className } = this.props
    return (
      <div className={`${styles['text-wrapper']} ${className}`} style={style}>
        <div className="text-label">{label}:</div>
        <div className="container-wrapper">
          {!!imgUrl && <img src={imgUrl} />}
          <div className="text-container">{i18n.get(text)}</div>
        </div>
        {tips && <div className="text-tip">{tips}</div>}
      </div>
    )
  }
}
