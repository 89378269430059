import { isObject } from '@ekuaibao/helpers'
import { app as api } from '@ekuaibao/whispered'

export function isPrintShow(selectedRowsData) {
  if (!selectedRowsData || !Object.keys(selectedRowsData).length) {
    return true
  }
  const staffId = api.getState('@common')?.userinfo?.staff?.id
  let selectData = []
  Object.keys(selectedRowsData).forEach(key => {
    selectData.push(selectedRowsData[key])
  })
  let canPrint = true
  for (let index = 0; index < selectData.length; index++) {
    const i = selectData[index]
    const flowId = i.hasOwnProperty('flowId') ? i.flowId : i
    const onlyOwnerPrint = flowId?.form?.onlyOwnerPrint
    const ownerAndApproverPrintNodeFlag = flowId?.form?.ownerAndApproverPrintNodeFlag
    const ownerId = isObject(flowId.ownerId) ? flowId.ownerId.id : flowId.ownerId
    //说明开启了限制审批人和制单人限制节点打印的配置
    if (ownerAndApproverPrintNodeFlag) {
      const state = flowId?.state
      const actions = flowId?.actions
      const myAction = actions?.[staffId]
      //可以打印的场景：1、审批结束后 2、myAcion包含'freeflow.print'
      canPrint = ['archived', 'paid'].includes(state) || myAction?.includes('freeflow.print')
    } else {
      //可以打印的场景：1、制单人永远可以打印 2、未开启允许制单人打印的配置
      canPrint = ownerId === staffId || !onlyOwnerPrint
    }
    //考虑批量情况下，批量情况下只要有一条可以打印，就跳出循环
    if (canPrint) {
      break;
    }
  }
  return canPrint
}
