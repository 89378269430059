import { LocaleProvider } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import en_US from 'antd/es/locale-provider/en_US';
export var LocaleProviderPatch = observer(function (props) {
    var currentLocale = i18n.currentLocale;
    var localeValue = en_US;
    if ('en-US' === currentLocale) {
        moment.locale('en');
        localeValue = en_US;
    }
    if ('zh-CN' === currentLocale) {
        moment.locale('zh-cn');
        localeValue = zh_CN;
    }
    return React.createElement(LocaleProvider, { locale: localeValue }, props.children);
});
