import { __decorate, __metadata } from "tslib";
import { observable, action, runInAction, toJS } from 'mobx';
import { formatCreditData } from '../helper/formatTableData';
import { uuid } from '@ekuaibao/helpers';
var TableStore = (function () {
    function TableStore() {
        this.objs = [];
        this.columns = [];
        this.displayColumns = [];
        this.selectObjs = [];
        this.maxLevelPoint = 0;
        this.minLevelPoint = 0;
    }
    TableStore.prototype.initObjs = function (values) {
        this.objs = formatCreditData(values);
        var ascData = this.sortData(this.objs, 'asc');
        var descData = this.sortData(this.objs, 'desc');
        this.minLevelPoint = ascData && ascData.lowest;
        this.maxLevelPoint = descData && descData.highest;
    };
    TableStore.prototype.sortData = function (datas, order) {
        if (order === void 0) { order = 'asc'; }
        if (order === 'asc') {
            datas = datas.length && datas.sort(function (a, b) {
                return b.code.charCodeAt(0) - a.code.charCodeAt(0);
            });
        }
        else {
            datas = datas.length && datas.sort(function (a, b) {
                return a.code.charCodeAt(0) - b.code.charCodeAt(0);
            });
        }
        return datas.length && datas[0];
    };
    TableStore.prototype.setMaxLevelPoint = function (value) {
        var data = this.sortData(this.objs, 'desc');
        data.highest = value;
        this.maxLevelPoint = value;
    };
    TableStore.prototype.setMinLevelPoint = function (value) {
        var data = this.sortData(this.objs, 'asc');
        data.lowest = value;
        this.minLevelPoint = value;
    };
    TableStore.prototype.getConvertObjs = function () {
        var objs = toJS(this.objs);
        return objs.map(function (item) {
            delete item.key;
            return item;
        });
    };
    TableStore.prototype.updateObjs = function (objs) {
        var _this = this;
        runInAction(function () {
            objs = objs.map(function (line) {
                return { key: uuid(14), code: '', lowest: 0, highest: 0, description: '' };
            });
            _this.objs = _this.objs.concat(objs);
        });
    };
    TableStore.prototype.deleteObjs = function (objs) {
        var ids = objs.map(function (line) { return line.key; });
        this.objs = this.objs.filter(function (line) { return !~ids.indexOf(line.key); });
    };
    TableStore.prototype.updateColumns = function (columns) {
        this.columns = columns;
    };
    TableStore.prototype.updateDisplayColumns = function (columns) {
        this.displayColumns = columns;
    };
    TableStore.prototype.clearObjs = function () {
        this.objs = [];
        this.selectObjs = [];
    };
    TableStore.prototype.addOneObj = function () {
        var datas = this.objs;
        if (datas.length < 6) {
            datas = toJS(datas);
            datas.length && datas.sort(function (a, b) {
                return b.code.charCodeAt(0) - a.code.charCodeAt(0);
            });
            var targetCode = datas.length && datas[0].code;
            var targetLowest = datas.length && datas[0].lowest;
            var value = {
                code: String.fromCharCode(targetCode.charCodeAt(0) + 1),
                lowest: 0,
                highest: targetLowest || 0,
                description: '',
                key: uuid(14)
            };
            this.objs.push(value);
        }
    };
    TableStore.prototype.updateOneObjValue = function (id, fieldName, value) {
        var _this = this;
        runInAction(function () {
            var targetObj = _this.objs.find((function (obj) { return obj.key === id; }));
            targetObj[fieldName] = value;
            if (fieldName === 'lowest') {
                var targetIdx = _this.objs.findIndex((function (obj) { return obj.key === id; }));
                if (targetIdx + 1 <= _this.objs.length - 1) {
                    var nextLevel = _this.objs[targetIdx + 1];
                    nextLevel.highest = value || 0;
                }
            }
        });
    };
    TableStore.prototype.updateObjsValue = function (fieldName, value) {
        var _this = this;
        this.selectObjs.forEach(function (line) {
            _this.updateOneObjValue(line.key, fieldName, value);
        });
    };
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "objs", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "columns", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "displayColumns", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "selectObjs", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], TableStore.prototype, "maxLevelPoint", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], TableStore.prototype, "minLevelPoint", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "initObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "setMaxLevelPoint", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "setMinLevelPoint", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "getConvertObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "deleteObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateColumns", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateDisplayColumns", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "clearObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "addOneObj", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String, Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateOneObjValue", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateObjsValue", null);
    return TableStore;
}());
export default TableStore;
