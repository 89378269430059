import { __awaiter, __generator } from "tslib";
require('../polyfill');
window.addEventListener('DOMContentLoaded', function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, require('./bootstrap').bootstrap()];
            case 1:
                _a.sent();
                return [2];
        }
    });
}); });
