/**
 *  Created by daiwenjuan on 2018/3/16 下午2:14.
 */
import { showModal } from '@ekuaibao/show-util'
import { app as api } from '@ekuaibao/whispered'
export function fnCheckPayerInfo(userInfo, payerInfo, isImport, modalInfo /*: { title: string, content: string }*/) {
  let { title, content } = !!modalInfo ? modalInfo : isImport ? getImportShowInfo() : getCheckedShowInfo()
  let { permissions } = userInfo
  let isAdmin = permissions && !!~permissions.indexOf('SYS_ADMIN')
  if (!payerInfo || !payerInfo.length) {
    isAdmin
      ? showModal.confirm({
          title: title,
          content: content,
          onOk: () => {
            api.invokeService('@layout:active:menu')
            api.go('/corporation-info')
          },
          okText: i18n.get('去设置'),
          cancelText: i18n.get('取消')
        })
      : showModal.error({ title: title, content: i18n.get('请联系管理员进行设置开票信息') })
    return true
  }
  return false
}
function getImportShowInfo() {
  return {
    title: i18n.get('无法导入'),
    content: i18n.get('请先设置一个开票信息')
  }
}
function getCheckedShowInfo() {
  return {
    title: i18n.get('无法验证'),
    content: i18n.get('无法使用验证发票功能，请前往设置开票信息')
  }
}
