/**************************************************
 * Created by nanyuantingfeng on 28/03/2018 11:05.
 **************************************************/
import styles from './RefStaffMemberDepartmentTags.module.less'
import React, { PureComponent } from 'react'
import { EnhanceField } from '@ekuaibao/template'
import { TextArea } from '../../elements/puppet/FakeInput'
import { wrapper } from '../layout/FormWrapper'
import { required } from '../validator/validator'
import { Tag, Checkbox } from 'antd'
import { app as api } from '@ekuaibao/whispered'
import { fillCheckedList } from '../../elements/payee-account/utils'
import { cloneDeep } from 'lodash'

@EnhanceField({
  descriptor: {
    type: 'staff.member.department.tags'
  },
  validator: (field, props) => (rule, value, callback) => {
    if (rule.level > 0) {
      return callback()
    }
    callback(required(field, value))
  },
  initialValue(props) {
    return (
      props.value || [
        { type: 'department-member', multiple: true, checkedKeys: [] },
        { type: 'department', multiple: true, checkedKeys: [] },
        { type: 'role', multiple: true, checkedKeys: [] }
      ]
    )
  },

  wrapper: wrapper()
})
export default class RefStaffMemberDepartmentTags extends PureComponent {
  state = { checked: false }

  constructor(props, ...args) {
    super(props, ...args)

    props.bus.__MULTIPLE_COUNT_MAP_ = props.bus.__MULTIPLE_COUNT_MAP_ || {}
  }

  componentWillMount() {
    const { bus, value, onChange } = this.props
    bus.on('@multiple:staff.member.department.tags', this.handleMultipleChange)

    //将默认值渲染到界面上  补全checkedData
    isNeedFillValue(value) && fillValue(value).then(data => onChange(data))
  }

  componentWillUnmount() {
    const { bus, field } = this.props
    bus.un('@multiple:staff.member.department.tags', this.handleMultipleChange)

    delete bus.__MULTIPLE_COUNT_MAP_[field.name]
  }

  handleMultipleChange = data => {
    const { onChange } = this.props
    if (this.state.checked) {
      this.setState({ checked: false }, () => {
        onChange(data)
      })
    }
  }

  handleCheckBoxChange = e => {
    const { bus, field } = this.props
    const checked = e.target.checked

    this.setState({ checked }, () => {
      bus.__MULTIPLE_COUNT_MAP_[field.name] = checked

      const keys = Object.keys(bus.__MULTIPLE_COUNT_MAP_).filter(line => !!bus.__MULTIPLE_COUNT_MAP_[line])

      bus.emit('@multiple:staff.member.department.tags:count:changed', keys)
    })
  }

  handleClick = () => {
    const { value, bus, onChange } = this.props
    api.open('@layout:SelectStaffsModal', { checkedList: value.checkedList ? value.checkedList : value }).then(data => {
      // 此处开启就将渲染所有的checked Line
      // if (this.state.checked) {
      //   bus.emit('@multiple:staff.member.department.tags', data)
      // } else {
      onChange(data.checkedList ? data.checkedList : data)
      // }
    })
  }

  handleCloseOne = (e, line) => {
    e && e.stopPropagation && e.stopPropagation()
    e && e.preventDefault && e.preventDefault()

    const { value } = this.props
    const { onChange } = this.props
    onChange(closeOne(value, line))
  }

  buildTags = () => {
    const { value } = this.props
    const members = (value[0] || value.checkedList).checkedData || []
    const departments = (value[1] || value.checkedList).checkedData || []
    const roles = (value[2] || value.checkedList).checkedData || []
    return members
      .concat(departments)
      .concat(roles)
      .map(line => (
        <Tag key={`${line.id}-${line.name}`} closable onClose={e => this.handleCloseOne(e, line)}>
          {line.name}
        </Tag>
      ))
  }

  render() {
    const { field } = this.props
    const { editable } = field

    return (
      <div className={styles.refStaffMemberDepartmentTagsItemWrapper}>
        <div className={styles.checkbox}>
          <Checkbox onChange={this.handleCheckBoxChange} checked={this.state.checked} className={styles.label}>
            {field.label}
          </Checkbox>
        </div>

        <TextArea disabled={!editable} onClick={this.handleClick}>
          {this.buildTags()}
        </TextArea>
      </div>
    )
  }
}

function isNeedFillValue(value) {
  const members = value[0].checkedData
  const departments = value[1].checkedData
  const roles = value[2].checkedData
  return !members || !departments || !roles
}

async function fillValue(value) {
  return isNeedFillValue(value) ? await fillCheckedList(value) : value
}

function closeOne(value, line) {
  let { id } = line
  value = cloneDeep(value)
  value[0] = { ...value[0], ...closeOneN(value[0], id) }
  value[1] = { ...value[1], ...closeOneN(value[1], id) }
  value[2] = { ...value[2], ...closeOneN(value[2], id) }
  return value
}

function closeOneN({ checkedData, checkedKeys }, id) {
  checkedData = checkedData.filter(line => line.id !== id)
  checkedKeys = checkedKeys.filter(line => line !== id)
  return { checkedData, checkedKeys }
}
