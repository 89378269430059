/**
 * Created by zhaoyue on 2017/7/13.
 */
import styles from './Money.module.less'
import React from 'react'
import { app as api } from '@ekuaibao/whispered'
import Big from 'big.js'
import { Fetch } from '@ekuaibao/fetch'
import { get } from 'lodash'

export default function Money(props) {
  let {
    className,
    currencySize,
    value,
    valueSize,
    color,
    style,
    fontWeight,
    isNegative,
    isShort,
    prefixLabel,
    showSymbol,
    showStrCode,
    symbolSize,
    withoutStyle,
    isShowForeign,
    showForeignNum,
    currencySymbol,
    currencyStrCode,
    onlyForeign
  } = props
  const standardCurrency = api.getState('@common.standardCurrency')
  const { symbol = '', scale = 2, strCode } = standardCurrency
  const m = getMoneyValue(value, isShowForeign)
  const mAbs = Math.abs(isNaN(Number(m)) ? 0 : m)
  let moneyValue = new Big(mAbs).toFixed(Number(getScale(value, isShowForeign, scale)))
  let unit = ''
  if (isShort) {
    const shortObject = shortening(moneyValue, value && value.standardScale)
    moneyValue = shortObject.money
    unit = shortObject.unit
  }
  if (isShort && !unit) {
    moneyValue = thousandBitSeparator(moneyValue)
  } else if (!isShort) {
    moneyValue = thousandBitSeparator(moneyValue)
  }
  const sy = currencySymbol || getSymbol(value, isShowForeign) || symbol
  const sc = currencyStrCode || getStrCode(value, isShowForeign) || strCode
  isNegative = isNegative || m < 0
  color = isNegative ? 'rgb(245, 34, 45)' : color

  let standardStr = ''
  if (isShowForeign) {
    const stanadardScale = getScale(value, false, scale)
    const standardValue = new Big(getMoneyValue(value, false)).toFixed(Number(stanadardScale))
    const standardMoney = thousandBitSeparator(standardValue)
    standardStr = `${getSymbol(value, false)}${standardMoney}`
  }

  return (
    <div
      className={`${withoutStyle ? '' : styles['money-wrap']} ${className}`}
      style={withoutStyle ? void 0 : { color, ...style }}
    >
      <div className={withoutStyle ? '' : styles['valid-money']}>
        <div style={withoutStyle ? void 0 : { fontSize: valueSize, fontWeight: fontWeight }} className="value">
          {prefixLabel && <span className="currency mr-4">{prefixLabel}</span>}
          {isNegative || showSymbol || showStrCode ? (
            <span style={withoutStyle ? void 0 : { fontSize: currencySize }} className="currency mr-4">
              {isNegative && '-'}
              {showSymbol ? sy : ''}
              {showStrCode ? sc : ''}
            </span>
          ) : null}
          {moneyValue}
          {unit && <span style={withoutStyle ? void 0 : { fontSize: symbolSize, fontWeight: fontWeight }}>{unit}</span>}
        </div>
      </div>
      {isShowForeign && showForeignNum &&  !onlyForeign && <div className="standard_money">{standardStr}</div>}
    </div>
  )
}

export function shortening(money, scale = 2) {
  let unit = ''
  let num = Number(money)
  const w = 10000
  const billion = 100000000
  const pow = Math.pow(10, scale)
  if (num >= billion) {
    if (Fetch.defaultLanguage === 'en-US') {
      num = (Math.floor((num * pow) / w) / pow) * 10
      unit = 'k'
    } else {
      num = Math.floor((num * pow) / w) / pow
      unit = i18n.get('万')
    }
  }
  return { money: thousandBitSeparator(Number(num).toFixed(scale) + ''), unit }
}

function thousandBitSeparator(num) {
  if (num && num.toString().indexOf(',') > -1) {
    return num
  }
  const re = /(-?\d+)(\d{3})/g
  while (re.test(num)) {
    num = num.replace(re, '$1,$2')
  }
  return num
}

function getMoneyValue(value, isShowForeign) {
  if (isShowForeign) {
    return typeof value === 'object' ? (value && value.foreign ? value.foreign : 0) : value || 0
  }
  return typeof value === 'object' ? (value && value.standard ? value.standard : 0) : value || 0
}

function getSymbol(value, isShowForeign) {
  if (isShowForeign) {
    return value && value.foreignSymbol
  }
  return value && value.standardSymbol
}

function getStrCode(value, isShowForeign) {
  if (isShowForeign) {
    return value && value.foreignStrCode
  }
  return value && value.standardStrCode
}

function getScale(value, isShowForeign, defaultScale = 2) {
  let key = 'standardScale'
  if (isShowForeign) {
    key = 'foreignScale'
  }
  const scale = get(value, key)
  return !isNaN(Number(scale)) && scale !== null ? scale : defaultScale
}

Money.defaultProps = {
  className: '', //外层赋予的class
  isNegative: false, //是否负数
  currency: 'CNY', //货币种类
  value: '0.00', //金额
  isShort: false,
  showSymbol: true,
  showStrCode: false,
  symbolSize: 12,
  isShowForeign: false,
  showForeignNum: true,
  onlyForeign:false
}

// {
//   "standard":"202.2",
//   "standardStrCode":"CNY",
//   "standardNumCode":"156",
//   "standardSymbol":"¥",
//   "standardUnit":"元",
//   "foreignStrCode":"JPY",
//   "foreignNumCode":"392",
//   "foreignSymbol":"J¥",
//   "foreignUnit":"圆",
//   "sysRate":"6.6",
//   "foreign":"30.63636363636363636364",
//   "standardScale":2,
//   "rate":"6.6",
//   "foreignScale":0
// }
