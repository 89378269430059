import loadable from '@loadable/component';
import { ENUM_TYPES } from './consts';
import { includes } from 'lodash';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var Attachment = loadableWithDescriptor(function () { return import('./dynamic/Attachment.readonly'); }, {
    type: 'attachments'
});
var Money = loadableWithDescriptor(function () { return import('./dynamic/Money.readonly'); }, {
    type: 'money'
});
var PayeeInfo = loadableWithDescriptor(function () { return import('./dynamic/PayeeInfo.readonly'); }, {
    type: 'payeeInfo'
});
var Ref = loadableWithDescriptor(function () { return import('./dynamic/Ref.readonly'); }, {
    test: function (_a) {
        var type = _a.type;
        return type.startsWith('ref') && type !== 'ref:organization.Staff' && !includes(ENUM_TYPES, type) && type !== 'ref:basedata.Enum.currency';
    }
});
var RefStaff = loadableWithDescriptor(function () { return import('./dynamic/RefStaff.readonly'); }, {
    type: 'ref:organization.Staff'
});
var Date = loadableWithDescriptor(function () { return import('./dynamic/Date.readonly'); }, {
    type: 'date'
});
var Number = loadableWithDescriptor(function () { return import('./dynamic/Number.readonly'); }, {
    type: 'number'
});
var DateRange = loadableWithDescriptor(function () { return import('./dynamic/DateRange.readonly'); }, {
    type: 'dateRange'
});
var Switcher = loadableWithDescriptor(function () { return import('./dynamic/Switcher.readonly'); }, {
    type: 'switcher'
});
var Label = loadableWithDescriptor(function () { return import('./dynamic/Label.readonly'); }, {
    test: function (_a) {
        var type = _a.type;
        return !!~['specification', 'text', 'textarea', 'list'].indexOf(type);
    }
});
var Separator = loadableWithDescriptor(function () { return import('./dynamic/Separator.readonly'); }, {
    type: 'separator'
});
var Annotation = loadableWithDescriptor(function () { return import('./dynamic/Annotation'); }, {
    type: 'annotation'
});
var City = loadableWithDescriptor(function () { return import('./dynamic/City.readonly'); }, {
    type: 'city'
});
var RefEnum = loadableWithDescriptor(function () { return import('./dynamic/RefEnum.readonly'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return includes(ENUM_TYPES, type);
    }
});
var SelectSearch = loadableWithDescriptor(function () { return import('./dynamic/SelectSearch.readonly'); }, {
    type: 'select_search'
});
var DataLink = loadableWithDescriptor(function () { return import('./dynamic/DataLink.readonly'); }, {
    type: 'dataLink'
});
var DataLinkEdits = loadableWithDescriptor(function () { return import('./dynamic/dataLinkEdit/DataLinkEdit.readonly'); }, {
    test: function (field) {
        var type = field.type, referenceData = field.referenceData;
        return type === 'dataLinkEdits' && referenceData.type !== 'TRIP';
    }
});
var DataLinkList = loadableWithDescriptor(function () { return import('./dynamic/DataLinkList.readonly'); }, {
    type: 'dataLinks'
});
var MutilStaff = loadableWithDescriptor(function () { return import('./dynamic/MutilStaff.readonly'); }, {
    type: 'list:ref:organization.Staff'
});
var Unknown = loadableWithDescriptor(function () { return import('./internal/Unknown'); }, { type: 'unknown' });
var Currency = loadableWithDescriptor(function () { return import('./dynamic/Currency.readonly'); }, {
    type: 'ref:basedata.Enum.currency'
});
var DataLinkStaff = loadableWithDescriptor(function () { return import('./dynamic/DataLinkStaff'); }, {
    type: 'list:ref:dataLink.Staff'
});
export var readonlyTrips = [
    Currency,
    Attachment,
    Date,
    Ref,
    RefStaff,
    Money,
    PayeeInfo,
    Label,
    Separator,
    Annotation,
    Number,
    DateRange,
    City,
    RefEnum,
    Switcher,
    SelectSearch,
    DataLink,
    DataLinkEdits,
    DataLinkList,
    MutilStaff,
    Unknown,
    DataLinkStaff
];
