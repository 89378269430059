/**
 * Created by panwei on 2017/11/16.
 */
import { app as api } from '@ekuaibao/whispered'
import { Fetch } from '@ekuaibao/fetch'
import parseQuery2Select, {
  parseQuery2RequisitionOrLinkRequisitionInfo,
  parseQuery2SelectNoPage
} from '@ekuaibao/lib/lib/parseQuery2Select'
import filtersFixer from './filtersFixer'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { getV } from '@ekuaibao/lib/lib/help'
import { isEmptyObject } from '@ekuaibao/helpers'
import { showMessage } from '@ekuaibao/show-util'

const ignoreFields = ['action']

export async function exportExcel(param, bus, post = false) {
  const config = await api.open('@layout:ExportExcelModal', { fromPage: param.fromPage, param })
  param = { ...param, config }
  const { exportAll } = config
  if (!exportAll) {
    param.config.fields = await getSelectFields(bus)
  }

  const privilegeId = getV(param, 'data.privilegeId')
  if (privilegeId) {
    param = { ...param, privilegeId }
    delete param.data.privilegeId
  }

  return chooseExportType(param, post)
}

async function getSelectFields(bus) {
  if (!bus) {
    return []
  }
  const fields = await bus.invoke('get:column:checked:value')
  const newFields = fields.map(item => {
    if ('form.linkRequisitionInfo.name' === item) {
      return 'form.linkRequisitionInfo'
    } else if ('form.expenseLink.name' === item) {
      return 'form.expenseLink'
    } else if ('flowId.form.linkRequisitionInfo.name' === item) {
      return 'flowId.form.linkRequisitionInfo'
    } else if ('flowId.form.expenseLink.name' === item) {
      return 'flowId.form.expenseLink'
    } else {
      return item
    }
  })
  return newFields.filter(f => !~ignoreFields.indexOf(f))
}

function chooseExportType(param, post = false) {
  const { exportType, needAsyncExport, onlyAsyncExport } = param

  if (exportType === 'export_all') {
    if (onlyAsyncExport) return justAsyncExport(param)
    needAsyncExport ? asyncExportAll(param, post) : chooseExportAllFunctionType(param)
  } else {
    chooseExportSelectedFunctionType(param)
  }
}

function chooseExportSelectedFunctionType(param) {
  let { funcType, data, config, privilegeId } = param
  switch (funcType) {
    case 'apply-manage':
      exportApplySelectBills(data, config)
      break
    case 'budget-bill':
      const fromNodeId = api.getState('@report-budget-chart').rootNodeId
      exportSelectedBudgetBills(data, config, fromNodeId)
      break
    case 'loan-manger-pending-pay':
      exportLoanManagerPendingPay(param)
      break
    case 'wallet-history':
      exprotSelectedWalletHistorys(data, config)
      break
    case 'carbonCopy':
      exportSelectedBills(param)
      break
    case 'budget-statement-bills':
      exportBudgetdStatementBills(param)
      break
    default:
      exportSelectedBills(param)
      break
  }
}

function chooseExportAllFunctionType(param) {
  const dimensionItems = api.getState('@common.dimensionItems')
  let { funcType, data, config, privilegeId = '' } = param
  switch (funcType) {
    case 'apply-manage':
      exportApplyAllBills(data, config, dimensionItems)
      break
    case 'budget-bill':
      const fromNodeId = api.getState('@report-budget-chart').rootNodeId
      exportAllBudgetBills(data, config, fromNodeId)
      break
    case 'loan-manage':
      exportLoanManagerAll(data, config, dimensionItems)
      break
    case 'loan-package-manage':
      exportLoanPackageManagerAll(data, config, privilegeId)
      break
    case 'my-all-archived-bill':
      exportMyAllArchivedBills(param, 'flow', dimensionItems)
      break
    case 'backlog':
      exportAllBills(param, 'backlog', dimensionItems)
      break
    case 'wallet-history':
      exprotWalletHistorys(data, config)
      break
    case 'carbonCopy':
      exportAllBills(param, 'carbonCopy', dimensionItems)
      break
    case 'budget-statement-bills':
      exportBudgetdStatementAllBills(param)
      break
    default:
      exportAllBills(param, undefined, dimensionItems)
      break
  }
}

export function exportBillsByPaymentBatchId(param) {
  let { data, type } = param
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let exportUrl = `${Fetch.fixOrigin(location.origin)}/api/flow/v2/export${
    type === 'print' ? '/pdf' : ''
  }/byPaymentBatchId/$${data}?corpId=${ekbCorpId}`
  let exportCheckUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/flow/v2/export/pdf/check/byPaymentBatchId/$${data}?corpId=${ekbCorpId}`
  if (type === 'print') {
    Fetch.GET(exportCheckUrl)
      .then(res => {
        api.emit('@vendor:download', exportUrl)
      })
      .catch(err => {
        showMessage.error(err.errorMessage)
      })
  } else {
    api.emit('@vendor:download', exportUrl)
  }
}

export function exportSelectedBills(param) {
  let { data, config, privilegeId = '', showAllFeeType, needAsyncExport = false, funcType, unNeedState, others } = param
  if (data.keys && !data.length) {
    data = data.keys
  }
  if (needAsyncExport) {
    const isManage = getV(others, 'isManage', false)
    const waitInvoice = getV(others, 'waitInvoice')
    const isConfirm = getV(others, 'isConfirm')
    const queryDateRange = getV(others, 'queryDateRange')
    let filterStr = decodeURIComponent(exportAllFilterStr({ ...param, params: param }))
    return asyncExport({
      isPost: true,
      filterStr,
      privilegeId,
      config,
      isManage,
      waitInvoice,
      isConfirm,
      showAllFeeType,
      scene: data?.scene,
      queryDateRange
    })
  }
  let flowIds = encodeURIComponent(`[${filterRowFlowIds(data).join(',')}]`)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const showAllFeeTypeValue = showAllFeeType ? '&showAllFeeType=true' : ''
  let privilege
  privilegeId && (privilege = encodeURIComponent(privilegeId))
  let exportUrl = `${Fetch.fixOrigin(location.origin)}/api/flow/v2/export/${flowIds}/$xlsx?corpId=${ekbCorpId}${
    privilege ? `&privilegeId=${privilege}` : ''
  }${showAllFeeTypeValue}`
  if (config) {
    let configStr = getConfigString(config)
    exportUrl += `&config=${configStr}`
  }
  api.emit('@vendor:download', exportUrl)
}

const exportPrefixMap = {
  backlog: '/backlogs',
  carbonCopy: '/carbonCopy',
  flow: ''
}

function exportAllBills(params, prefix = 'flow', dimensionItems) {
  let { data, config, others, privilegeId = '', showAllFeeType } = params
  const { partialPayState } = data
  const waitInvoice = getV(others, 'waitInvoice')
  const isConfirm = getV(others, 'isConfirm')
  const otherQueryString = getNodeValueByPath(others, 'queryString')
  let { status, state, scene } = data
  status = state ? { state, ...status } : { ...status }
  let param = { ...data, status }
  const otherFilter = getNodeValueByPath(others, 'filter')

  let query = parseQuery2Select(param, undefined, prefix)
  if (status['isLink']) {
    query = parseQuery2RequisitionOrLinkRequisitionInfo(param, undefined, prefix)
  }
  const sceneFiltersQuery = typeof scene === 'object' ? filtersFixer(scene, prefix, dimensionItems) : ''

  if (prefix === 'backlog' || prefix === 'carbonCopy') {
    query = query.filterBy(`type!="permit"`)
  } else {
    query = query.filterBy(`formType!="permit"`)
  }
  if (partialPayState) {
    query.filterBy(`!flowId.form.partialPayState.isNull()`)
  }
  if (otherFilter) {
    query = query.filterBy(otherFilter)
  }
  let filterBy = query.filterBy(sceneFiltersQuery).value().filterBy || ''
  if (prefix === 'flow') {
    filterBy = filterBy.replace(/flowId\./gi, '')
  }
  let filterStr = encodeURIComponent(filterBy)
  let configStr = getConfigString(config)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let prefixPath = prefix && exportPrefixMap[prefix]
  let paramArray = [`corpId=${ekbCorpId}`, `filter=${filterStr}`, `config=${configStr}`, `privilegeId=${privilegeId}`]
  if (waitInvoice != void 0) {
    paramArray.push(`waitInvoice=${waitInvoice}`)
  }
  if (isConfirm != void 0) {
    paramArray.push(`isConfirm=${isConfirm}`)
  }
  if (otherQueryString) {
    paramArray.push(otherQueryString)
  }
  const showAllFeeTypeValue = showAllFeeType ? '&showAllFeeType=true' : ''
  const paramStr = paramArray.join('&')
  const exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/flow/v2${prefixPath}/export/$xlsx?${paramStr}${showAllFeeTypeValue}`
  api.emit('@vendor:download', exportAllUrl)
}

function exportMyAllArchivedBills(param, config, dimensionItems) {
  let { data } = param
  data.state = ['archived', 'paid', 'nullify']
  param = { ...param }
  exportAllBills(param, config, dimensionItems)
}

function exportApplySelectBills(flowIds, config) {
  flowIds = encodeURIComponent(`[${flowIds.join(',')}]`)
  let configStr = getConfigString(config)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let exportUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/form/v2/requisition/export/${flowIds}/$xlsx?corpId=${ekbCorpId}&config=${configStr}`
  api.emit('@vendor:download', exportUrl)
}

function exportApplyAllBills(params, config, dimensionItems) {
  const { scene } = params.data
  const sceneFiltersQuery = scene ? filtersFixer(scene, undefined, dimensionItems) : ''
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const query = parseQuery2Select(params)
  const filterBy = (query.filterBy(sceneFiltersQuery).value().filterBy || '').replace(new RegExp(/(flowId.)/g), '')
  let filterStr = encodeURIComponent(filterBy)
  let configStr = getConfigString(config)
  let exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/form/v2/requisition/export/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&config=${configStr}`
  api.emit('@vendor:download', exportAllUrl)
}

function exportAllBudgetBills(data, config, fromNodeId) {
  let { budgetId, nodeId, params } = data
  let exportAllUrl
  let filterStr = encodeURIComponent(params.filterBy)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)
  budgetId = encodeURIComponent(budgetId)
  nodeId = encodeURIComponent(nodeId)
  exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/budget/report/export/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&budgetId=${budgetId}&nodeId=${nodeId}&config=${configStr}&fromNodeId=${fromNodeId}`
  api.emit('@vendor:download', exportAllUrl)
}

function exportSelectedBudgetBills(data, config, fromNodeId) {
  let { budgetId, nodeId, selectedRowKeys } = data
  let ids = encodeURIComponent(`[${selectedRowKeys.join(',')}]`)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)
  budgetId = encodeURIComponent(budgetId)
  nodeId = encodeURIComponent(nodeId)
  let exportUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/budget/report/export/${ids}/$xlsx?corpId=${ekbCorpId}&budgetId=${budgetId}&nodeId=${nodeId}&config=${configStr}&fromNodeId=${fromNodeId}`
  api.emit('@vendor:download', exportUrl)
}

function exportLoanManagerAll(params, config, dimensionItems) {
  let { status, data } = params
  status = { state: ['paying', 'approving', 'sending', 'receiving'], ...status }
  params = { ...params, status }
  const { scene } = data
  const sceneFiltersQuery = scene ? filtersFixer(scene, undefined, dimensionItems) : ''
  const query = parseQuery2Select(params, undefined, 'flow')
  const filterBy = (query.filterBy(sceneFiltersQuery).value().filterBy || '').replace(/flowId\./gi, '')
  let filterStr = encodeURIComponent(filterBy)
  let configStr = getConfigString(config)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/loan/export/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&config=${configStr}`
  api.emit('@vendor:download', exportAllUrl)
}

function exportLoanPackageManagerAll(params, config, privilegeId) {
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)
  let filterStr = encodeURIComponent(params)
  let exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v2/loan/loanInfo/export/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&config=${configStr}&privilegeId=${privilegeId}`
  api.emit('@vendor:download', exportAllUrl)
}

function exportLoanManagerPendingPay(param) {
  let { data, config, privilegeId = '', others, funcType } = param
  data.otherfilter = getV(others, 'filter', '')
  const unNeedState = getV(others, 'unNeedState', false)
  let filterStr = exportAllFilterStr({ params: data, unNeedState, id: data.id, funcType })
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)
  const url = `${Fetch.fixOrigin(
    location.origin
  )}/api/v2/loan/loanInfo/export/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&config=${configStr}&privilegeId=${privilegeId}`
  api.emit('@vendor:download', url)
}

function exprotWalletHistorys(params, config) {
  let { payType } = params
  const filterBy = (payType && `(type.in("${payType}"))`) || ''
  let filterStr = encodeURIComponent(filterBy)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)

  // TODO 企业钱包交易记录导出全部
  let exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/wallets/historys/excel/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&config=${configStr}`
  api.emit('@vendor:download', exportAllUrl)
}

function exprotSelectedWalletHistorys(data, config) {
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)

  // TODO 企业钱包交易记录导出选中
  let url = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/wallets/historys/excel/[${data}]?corpId=${ekbCorpId}&config=${configStr}`
  api.emit('@vendor:download', url)
}

async function justAsyncExport(params) {
  let { data, config, privilegeId = '', showAllFeeType, funcType, others } = params
  config = getConfigString({ ...config }, false)
  data.otherfilter = getV(others, 'filter', '')
  const unNeedState = getV(others, 'unNeedState')
  let filterStr = decodeURIComponent(exportAllFilterStr({ params: data, noFlow: false, funcType, unNeedState }))
  const val = await api.open('@layout:AsyncExportModal')
  const { taskName } = val
  const path =
    funcType === 'carbonCopy'
      ? '/api/flow/v2/carbonCopy/export/$xlsx/async'
      : '/api/flow/v2/backlogs/export/$xlsx/async'
  Fetch.GET(path, {
    filter: filterStr,
    taskName,
    config,
    privilegeId,
    showAllFeeType
  })
}

async function asyncExportAll(param, post = false) {
  let { data, config, privilegeId = '', others, showAllFeeType, funcType } = param
  const ALSLPower = api.getState()['@common'].ALSLPower
  // 当数据比较多的时候，要向后端请求判断当前是同步下载还是异步下载
  // 阿里商旅暂时不支持异步导出全部
  if (ALSLPower) {
    return chooseExportAllFunctionType(param)
  }
  data.otherfilter = getV(others, 'filter', '')
  const unNeedState = getV(others, 'unNeedState', false)
  const isManage = getV(others, 'isManage', false)
  const waitInvoice = getV(others, 'waitInvoice')
  const isConfirm = getV(others, 'isConfirm')
  const queryDateRange = getV(others, 'queryDateRange')
  let filterStr = decodeURIComponent(exportAllFilterStr({ params: data, unNeedState, funcType }))
  if (funcType === 'loan-package-manage') {
    return exportWayForLoanManagerAll({ filter: filterStr, privilegeId, config, param })
  }
  try {
    const way = await exportWay({ isPost: post, filterStr, privilegeId, isManage, waitInvoice, isConfirm })
    //同步导出
    if (way === 'sync') {
      return chooseExportAllFunctionType(param)
    }
    //异步导出
    if (way === 'async') {
      return asyncExport({
        isPost: post,
        filterStr,
        privilegeId,
        config,
        isManage,
        waitInvoice,
        isConfirm,
        showAllFeeType,
        scene: data?.scene,
        queryDateRange
      })
    }
  } catch (e) {
    console.log(JSON.stringify(e))
  }
}

async function exportWayForLoanManagerAll({ filter, privilegeId, config, param }) {
  const result = await Fetch.GET(`/api/v2/loan/loanInfo/export/exportWay`, { filter, privilegeId })
  const way = getV(result, 'value.exportWay', '')
  param.data = filter
  return way === 'async'
    ? asyncExportForLoanManagerAll({ filter, privilegeId, config })
    : chooseExportAllFunctionType(param)
}

async function asyncExportForLoanManagerAll({ filter, privilegeId, config }) {
  const val = await api.open('@layout:AsyncExportModal')
  const { taskName } = val
  const c = getConfigString(config, false)
  Fetch.GET('/api/v2/loan/loanInfo/export/$xlsx/async', {
    filter,
    taskName,
    config: c,
    privilegeId
  })
}

function getParamsIsWait(waitInvoice, isConfirm) {
  const params = Object.create(null)
  if (waitInvoice != void 0) {
    params.waitInvoice = waitInvoice
  }
  if (isConfirm != void 0) {
    params.isConfirm = isConfirm
  }
  return params
}

async function exportWay({ isPost, filterStr, privilegeId, isManage, waitInvoice, isConfirm }) {
  let result = {}
  const params = getParamsIsWait(waitInvoice, isConfirm)
  if (isPost) {
    result = await Fetch.POST(
      `/api/flow/v2/exportType`,
      { privilegeId, isManage, ...params },
      { body: { filterBy: filterStr } }
    )
  } else {
    result = await Fetch.GET(`/api/flow/v2/exportWay`, { filter: filterStr, privilegeId, isManage, ...params })
  }
  return getV(result, 'value.exportWay', '')
}

async function asyncExport({
  isPost,
  filterStr,
  privilegeId,
  config,
  isManage,
  waitInvoice,
  isConfirm,
  showAllFeeType,
  scene,
  queryDateRange
}) {
  const val = await api.open('@layout:AsyncExportModal')
  const { taskName } = val
  const c = getConfigString(config, false)
  const params = getParamsIsWait(waitInvoice, isConfirm)
  const exportIsTimeLimit = api.getState()['@common'].exportIsTimeLimit // 后台配置
  if (exportIsTimeLimit?.exportTimeLimit) {
    Fetch.POST(
      '/api/flow/v2/export/$xlsx/exportAllFlowAsync/v2',
      {
        isManage,
        ...params,
        showAllFeeType,
        formType: scene.formType,
        taskStartTime: queryDateRange?.start,
        taskEndTime: queryDateRange?.end
      },
      {
        body: { query: { filterBy: filterStr }, taskName, config: c, privilegeId }
      }
    )
    return
  }
  if (isPost) {
    Fetch.POST(
      '/api/flow/v2/export/$xlsx/exportAllFlowAsync',
      { isManage, ...params, showAllFeeType },
      {
        body: { query: { filterBy: filterStr }, taskName, config: c, privilegeId }
      }
    )
  } else {
    Fetch.GET('/api/flow/v2/export/$xlsx/async', {
      filter: filterStr,
      taskName,
      config: c,
      privilegeId,
      isManage,
      showAllFeeType,
      ...params
    })
  }
}

export function exportStr(data, filter) {
  const query = parseQuery2SelectNoPage(data)
  if (!!filter) {
    query.filterBy(filter)
  }
  return query.value()
}

function exportAllFilterStr({ params, unNeedState = false, ...other }) {
  let { status, state, otherfilter, otherfilter1, scene } = params
  const { id, funcType, noFlow = true } = other
  const isLoan =
    funcType === 'loan-manger-pending-pay' || funcType === 'loan-package-manage' || funcType === 'carbonCopy'
  const ids = isLoan && id ? `id.in(${formatIds(id).join(',')})` : ''
  if (!unNeedState) {
    state = !!state ? state : ['archived', 'paid', 'paying', 'approving', 'sending', 'receiving', 'rejected']
    status = { state, ...status }
    params = { ...params, status }
  }
  // 把日期格式化成时间戳
  if (params.filters) {
    const keys = Object.keys(params.filters)
    for (const key of keys) {
      if (
        params.filters[key] &&
        params.filters[key] instanceof Array &&
        Number(params.filters[key][0]) > 0 &&
        Number(params.filters[key][1]) > 0
      ) {
        params.filters[key] = {
          start: +params.filters[key][0],
          end: +params.filters[key][1]
        }
      }
    }
  }
  // const type = 'backlog'
  const type = isLoan ? 'backlog' : funcType === 'backlog' ? 'backlog' : 'flow'
  let query = parseQuery2Select(params, undefined, type)
  if (otherfilter) {
    query = query.filterBy(otherfilter)
  }
  if (otherfilter1) {
    query = query.filterBy(otherfilter1)
  }
  if (id) {
    query = query.filterBy(ids)
  }
  if (scene && !isEmptyObject(scene)) {
    const dimensionItems = api.getState('@common.dimensionItems')
    const sceneFiltersQuery = filtersFixer(scene, type, dimensionItems)
    query.filterBy(sceneFiltersQuery)
  }
  let filterBy = query.value().filterBy || ''
  if (!isLoan && noFlow) {
    filterBy = filterBy.replace(/flowId\./gi, '')
  }
  return encodeURIComponent(filterBy)
}

function filterRowFlowIds(data) {
  return Object.keys(data).map(key => {
    const v = data[key]
    return typeof v === 'string' ? v : v.flowId ? v.flowId.id : v.id
  })
}

function formatIds(ids) {
  return ids.map(v => `"${v}"`)
}

function getConfigString(config, needEncode = true) {
  let { includeDetails, includeTrips, fields = [], exportRefCode, exportAll, exportTime, detailsType } = config
  fields = fields.map(field => field.replace(new RegExp(/(flowId.)/g), '').replace(new RegExp(/(form.)/g), ''))
  let str = JSON.stringify({ includeDetails, includeTrips, fields, exportRefCode, exportAll, exportTime, detailsType })
  if (needEncode) {
    str = encodeURIComponent(str)
  }
  return str
}

function exportBudgetdStatementBills(params) {
  const { config, data } = params
  const { selectedRowKeys, budgetId, nodeId, rootNodeId, periodTime } = data
  let ids = encodeURIComponent(`[${selectedRowKeys.join(',')}]`)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  let configStr = getConfigString(config)
  const cBudgetId = encodeURIComponent(budgetId)
  const cNodeId = encodeURIComponent(nodeId)
  let exportUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/budget/report/export/${ids}/$xlsx?corpId=${ekbCorpId}&budgetId=${cBudgetId}&nodeId=${cNodeId}&fromNodeId=${rootNodeId}&config=${configStr}&periodTime=${periodTime}`
  api.emit('@vendor:download', exportUrl)
}

function exportBudgetdStatementAllBills(params) {
  const { config, data } = params
  const { budgetId, nodeId, rootNodeId, expenseList, exporters, paramsData, periodTime } = data
  const configStr = getConfigString(config)
  api.invokeService('@report-budget-chart:find:export:way', exporters).then(res => {
    const value = res.value
    switch (value && value.exportWay) {
      case 'async':
        // 当要异步导出的时候出现弹框
        api.open('@layout:AsyncExportModal').then(v => {
          let params = { ...exporters, taskName: v.taskName, config }
          api.invokeService('@report-budget-chart:async:export:budget', params).then(res => {
            showMessage.success(i18n.get('导出成功,请到个人中心,导出管理查看'))
          })
        })
        break
      case 'sync':
        // 同步导出调用接口
        expenseList.items.length &&
          handleBudgetExportAll.call(this, { budgetId, nodeId, rootNodeId, paramsData, configStr, periodTime })
        break
    }
  })
}

export function handleBudgetExportAll({ budgetId, nodeId, rootNodeId, paramsData, configStr, periodTime }) {
  let exportAllUrl
  let filterStr = encodeURIComponent(paramsData.filterBy)
  let ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
  const cBudgetId = encodeURIComponent(budgetId)
  const cNodeId = encodeURIComponent(nodeId)
  exportAllUrl = `${Fetch.fixOrigin(
    location.origin
  )}/api/v1/budget/report/export/$xlsx?corpId=${ekbCorpId}&filter=${filterStr}&budgetId=${cBudgetId}&nodeId=${cNodeId}&fromNodeId=${rootNodeId}&config=${configStr}&periodTime=${periodTime}`
  api.emit('@vendor:download', exportAllUrl)
}
