/**************************************************
 * Created by nanyuantingfeng on 24/06/2017 16:10.
 **************************************************/
import loadable from '@loadable/component'
import { getRiskFieldNum } from './riskWarning/formatRiskWarningData'
import { parseAsMeta } from './util/parseAsMeta'
import { Resource } from '@ekuaibao/fetch'
import { app as api } from '@ekuaibao/whispered'
import { emitResetFieldsExternals } from './riskWarning/emitResetFieldsExternals'
import { checkAliPayCardAuth, checkAifapiaoCardAuth } from './layers/import-bill/invoice/alipaycardAuth'
import {
  handleFilePreview,
  handleFileDownload,
  getDimensionItemsById,
  setPrintPreviewUrl,
  flowPreview
} from './bills.action'
import { getColumns } from './layers/dataLink-detail-modal/utilsTable.tsx'
import {
  getCommonDimensionConfig,
  getCommonDimension
} from './util/billFetchUtil'
const dispatchHelpers = (name, thenFn = d => d) => async (...theArgs) => {
  const actions = await import('./bills.action')
  const fn = actions[name]
  return api.dispatch(fn(...theArgs)).then(thenFn)
}

const noDispatchHelpers = (name, thenFn = d => d) => async (...theArgs) => {
  const actions = await import('./bills.action')
  const fn = actions[name]
  return fn(...theArgs).then(thenFn)
}

const baseConfig = {
  'set:print:preview:url': dispatchHelpers('setPrintPreviewUrl'),
  'get:budget': dispatchHelpers('getBudgetDetail'),
  'get:apply:event': dispatchHelpers('getApplyEvent'),
  'get:apply:event:fn': noDispatchHelpers('getApplyEvent'),
  'get:apply:event:orderBy': dispatchHelpers('getApplyEventWithOrder'), // 补充申请排序
  'get:apply:data:source': dispatchHelpers('getApplySource'),

  'check:loan:package:exist': dispatchHelpers('checkLoanPackageExist'),
  'search:city': dispatchHelpers('getCityByKeyword'),

  'get:flow-info-new': dispatchHelpers('getFlowInfoByIdNew'),
  'get:flow-info': dispatchHelpers('getFlowInfoById'),
  'get:flow-info-lite': noDispatchHelpers('getFlowInfoByIdLite'),
  'get:flow-action': dispatchHelpers('getFlowByCode'),
  'get:bill-info-byCode': dispatchHelpers('getBillInfoByCode'),

  'get:apply-event-detail-list': dispatchHelpers('getDetailList'),

  'get:ExpenseLink:list': noDispatchHelpers('getExpenseLinkList'),

  'bill:reminde': dispatchHelpers('billRemind'),

  'get:loan:balance': dispatchHelpers('getLoanBalance'),

  'get:calculaterfield': dispatchHelpers('getCalculateField'),

  'get:calculationresult': dispatchHelpers('getAutoCalculationResult'),

  'get:calculateRecordLink': dispatchHelpers('getCalculateRecordLink'),

  'get:getTripsTemplate': dispatchHelpers('getTripsTemplate'),

  'get:getDataLinkEditTemplate': dispatchHelpers('getDataLinkEditTemplate'),

  'get:getDataLinkEditItems': dispatchHelpers('getDataLinkEditItems'),

  'get:getMyPrintRemindCount': dispatchHelpers('getMyPrintRemindCount'),

  'comment:flow': dispatchHelpers('commentFlow'),

  'transfer:flow': dispatchHelpers('transferFlow'),

  'withdraw:approvers': dispatchHelpers('getWithdrawFlowId'),

  'withdraw:save': dispatchHelpers('withdrawSave'),

  'get:Specifications': dispatchHelpers('getSpecificationsByIds'),

  'get:loanpackage:detail:info': dispatchHelpers('getloanpackageDetailInfo'),

  'get:loanpackage:detail:RepayInfo': dispatchHelpers('getRepayInfo'),

  'get:dimension': id => api.dispatch(getDimensionItemsById([id])),

  'update:dataLink:instance': dispatchHelpers('updateDataLinkInstance'),

  'delete:detail:dataLink:instance': dispatchHelpers('deleteDetailDataLinkInstance'),

  'get:VPhoto:data': noDispatchHelpers('getVphotoDetail'),

  'verify:VPhoto:orders': noDispatchHelpers('verifyVPhotoOrders'),

  'get:invoice:corporation': dispatchHelpers('getInvoiceCorporation'),

  'save:invoice:wait': dispatchHelpers('saveInvoiceWait'),

  'record:invoice:riskWarning': dispatchHelpers('recordInvoiceRiskWarning'),

  'delete:invoice:wait': dispatchHelpers('deleteInvoiceInfo'),

  'get:invoice:Alldetails': dispatchHelpers('getInvoiceDetailsById'),

  'get:invoice:state': dispatchHelpers('getInvoiceStateById'),
  'get:datalink:by:id': dispatchHelpers('getDataLinkEntity'),

  'get:flow:risk:warning': dispatchHelpers('getFlowRiskWarning'),

  'get:current:flow:state': dispatchHelpers('getCurrentFlowState'),
  'get:current:backLog': dispatchHelpers('getBackLogByFlowId'),
  'get:ocr:list': dispatchHelpers('getOCRList'),
  'get:invoice:image:by:ids': dispatchHelpers('getInvoiceImgById'),
  'get:invoice:info:by:ids': dispatchHelpers('getInvoiceInfoByIds'),
  'invoice-isDeductible': dispatchHelpers('getInvoiceIsDeductible'),
  'get:datalink:other:info': dispatchHelpers('getLedgerAndPlanByEntityId'),
  'get:datalink:template:byId': dispatchHelpers('getDatalinkTemplateById'),
  'get:datalink:template:byIds': dispatchHelpers('getDatalinkTemplateByIds'),
  'put:transfer:loan:package': dispatchHelpers('transferLoanPackage'),
  'import:getFeeTypeTemplateById': dispatchHelpers('getFeeTypeTemplateById'),

  'import:parseAsMeta': () => parseAsMeta,
  'get:RiskFieldNumFn': () => getRiskFieldNum,

  'get:visibleFeetype:ByFormData': dispatchHelpers('getFeeTypeListByFormData'),
  'get:StaffById': dispatchHelpers('getStaffById', data => data.value),
  'check:Supplement:identity': noDispatchHelpers('checkSupplementIdentity'),
  'get:feetype:invoice:importMode': noDispatchHelpers('getFeetypeInvoiceImportMode'),
  'export:apportion:details': dispatchHelpers('exportApportionExcel'),
  'export:planpay:details': dispatchHelpers('exportPlanPayExcel'),
  'get:DetailSubmitterIdList': dispatchHelpers('getDetailSubmitterIdList'),

  'get:linkRequisitionControlled': id => {
    return new Resource('/api/form/v2/requisition').GET('/$id/supplement', { id })
  },
  'get:getShareListApi': dispatchHelpers('getShareListApi'),
  'get:feeType:recommend': noDispatchHelpers('getFeeTypeRecommed'),
  'get:invoice:inputType': noDispatchHelpers('getInvoiceInputType'),
  'modify:fee:details:taxinfo': dispatchHelpers('modifyFeeDetailTaxInfo'),
  'get:fee:details:getFieldsGroupData': dispatchHelpers('getFieldsGroupData'),
  'modify:fee:details:saveFieldsGroupData': dispatchHelpers('saveFieldsGroupData'),
  'get:related:detail:list': noDispatchHelpers('getRelatedDetailList'),
  'get:metaby:ids': noDispatchHelpers('getMetaByIds'),
  'file:preview': () => handleFilePreview,
  'file:download': () => handleFileDownload,

  'save:feeType:visible:list': dispatchHelpers('saveFeeTypeVisibleList'),
  'get:active:credit:rules': dispatchHelpers('getActiveCreditRules'),
  'get:bill:notes': dispatchHelpers('getBillNotes'),
  'add:bill:note': dispatchHelpers('addBillNote'),
  'search:note:form:history': dispatchHelpers('searchNoteFormHistory'),
  'change:status:of:notes': dispatchHelpers('changeStatusOfShowBillNotesInHistory'),
  'get:preview:wps': noDispatchHelpers('getPreviewWPS'),
  'get:my:credit:point': dispatchHelpers('getMyCreditPoint'),
  'get:apply:event:by:id': dispatchHelpers('getApplyEventById'),
  'get:record:link': dispatchHelpers('getRecordLink'),
  'get:data:link:list': dispatchHelpers('getDataLinkList'),
  'get:data:link:config:rule': dispatchHelpers('getDataLinkConfigRule'),
  'put:feeDetail:moveTo:recordExpends': noDispatchHelpers('feeDetailMoveToRecordExpends'),
  'put:feeDetail:moveTo:quickExpends': noDispatchHelpers('feeDetailMoveToQuickExpends'),
  flowDoAction: noDispatchHelpers('flowDoAction'),
  'import:emitResetFieldsExternals': () => emitResetFieldsExternals,
  'check:aliPayCard:auth': () => checkAliPayCardAuth(),
  'check:aifapiao:auth': () => checkAifapiaoCardAuth(),
  checkApplyClosed: noDispatchHelpers('checkApplyClosed'),
  'get:flows:by:ids': noDispatchHelpers('getFlowByIds'),
  'retry:checker:invoice': dispatchHelpers('getRetryCheckerInvoice'),
  'get:bank:list': noDispatchHelpers('getBankList'),
  'share:loan': noDispatchHelpers('shareLoan'),
  'get:bill:loan:list': noDispatchHelpers('getLoanListByBill'),
  'get:columns': type => getColumns(type),
  'save:written:off:summary': dispatchHelpers('saveWrittenOffSummary'),
  'clear:written:off:summary': dispatchHelpers('clearWrittenOffSummary'),
  'get:getTravelBackInfo': dispatchHelpers('getTravelBackInfo'),
  'activate:flow': dispatchHelpers('activateFlow'),
  'update:dimention:currency': dispatchHelpers('updateDimentionCurrency'),
  'check:return:city': dispatchHelpers('checkReturnCity'),
  'get:flow:config:by:id': noDispatchHelpers('getFlowPlanConfigByConfigId'),
  'get:PullTravelOrderByStaff': dispatchHelpers('GetPullTravelOrderByStaff'),
  'get:datalink:dependence:list': noDispatchHelpers('getDependenceDatalinkList'),
  'get:invoice:compared:data': noDispatchHelpers('getInvoiceComparedData'),
  'get:aifapiao:bind:state': noDispatchHelpers('getAifapiaoCardAuthState'),
  'get:aifapiao:sign': noDispatchHelpers('getAiFaPiaoSign'),
  'import:invoice:from:aifapiao': dispatchHelpers('importInvoiceFromAiFaPiao'),
  'get:aifapiao:public:url': noDispatchHelpers('getAifapiaoPublicUrl'),
  'get:assignment:rule:by:id': dispatchHelpers('getAssignmentRuleById'),
  checkOpenAgain: noDispatchHelpers('checkOpenAgain'),
  'update:invoice:tax': noDispatchHelpers('updateInvocieTax'),
  getAutoAssociateDetail: noDispatchHelpers('getAutoAssociateDetail'),
  postAutoAssociateDetailcr: noDispatchHelpers('postAutoAssociateDetailcr'),
  'get:association:group:data': dispatchHelpers('getAssociationGroupData'),
  'get:loan:isCancel:share': noDispatchHelpers('shareIsCancel'),
  'get:layoutConfig': dispatchHelpers('getLayoutConfigWithType'),
  'set:taxAmount:change:log': noDispatchHelpers('setTaxAmoutChangeLog'),
  'get:invoice:reason': dispatchHelpers('getInvoiceReason'),
  'save:invoice:reason': noDispatchHelpers('saveInvoiceReason'),
  'save:invoice:reason:batch': noDispatchHelpers('saveInvoiceReasonBatch'),
  'get:invoice:reasonAndId': noDispatchHelpers('getInvoiceIdAndReason'),
  checkSurplus: noDispatchHelpers('checkSurplus'),
  getSurplusResult: dispatchHelpers('getSurplusResult'),
  'get:getFeeTypeChange': dispatchHelpers('getFeeTypeChange'),
  'get:orderMicro:fields': noDispatchHelpers('getOrderFields'),
  'get:orderMicro:list': noDispatchHelpers('getOrderList'),
  'get:orderMicro:details': noDispatchHelpers('getOrderDetails'),
  'get:orderMicro:types': noDispatchHelpers('getOrderMicroTypes'),
  'get:default:payee': dispatchHelpers('getDefaultPayee'),
  'get:delegate:config': dispatchHelpers('getDelegateConfig'),
  'set:submitter:data': dispatchHelpers('setSubmitterData'),
  getTravelResult: dispatchHelpers('getTravelResult'),
  calPlanInstance: dispatchHelpers('calPlanInstance'),
  'get:flow:link:list': noDispatchHelpers('getFlowLinkList'),
  'flow:preview': dataSource => flowPreview(dataSource),
  'get:calculate:corpId:whitelist': dispatchHelpers('getCalculateCorpIdWhiteList'),
  'get:link:requisition:coprs': dispatchHelpers('getLinkRequisitionCoprs'),
  'get:check:nulllify:rule': dispatchHelpers('checkNulllifyRule'),
  'get:check:nulllify:flowId': dispatchHelpers('checkNulllifyFlowId'),
  'get:if:download:fileName': dispatchHelpers('getIfDownloadNeedFileName'),
  'get:common:dimension:config': params => getCommonDimensionConfig(params),
  'get:common:dimension': params => getCommonDimension(params)
}

const billPoint = {
  id: '@bills',
  reducer: () => import('./bills.reducer'),
  path: '/bills',
  ref: '/',
  onload: () => import('./bills.view'),
  dependencies: ['@auth-check']
}

export default [
  {
    ...billPoint,
    ...baseConfig
  },
  {
    point: '@@components',
    namespace: '@bills',
    onload: () => [{ key: 'BillDetail', component: () => import('./layers/bill-info-popup/BillDetail') }]
  },
  {
    point: '@@layers',
    prefix: '@bills',
    onload: () => import('./layers')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'home',
        weight: 0,
        label: i18n.get('首页'),
        actived: true,
        __noSub: true,
        href: '/new-homepage'
      }
    ]
  },
  {
    id: '@myBill',
    path: '/bills',
    ref: '/',
    onload: () => import('./bills.view')
  },
  {
    id: '@myBillAction',
    path: '/bills/:action/:id',
    ref: '/',
    onload: () => import('./bills.view')
  },
  {
    id: '@approvePermission',
    path: '/approvePermission',
    ref: '/',
    onload: () => import('./ApprovePermission')
  },
  {
    id: '@myLoan',
    path: '/myLoan',
    ref: '/',
    onload: () => import('./bill-stacker-manager/pages/MyLoanView')
  },
  {
    id: '@myRequisition',
    path: '/myRequisition',
    ref: '/',
    onload: () => import('./bill-stacker-manager/pages/MyRequisitionView')
  },
  {
    id: '@todoPrint',
    path: '/todo/print',
    ref: '/',
    onload: () => import('./elements/PrintListView')
  },
  {
    id: '@datalink',
    path: '/datalink/:id/:label/:platformName',
    ref: '/',
    onload: () => import('./layers/mine-datalink-list/homepage/DataLinkWrap')
  },
  {
    id: '@billEntryDetail',
    path: '/billEntryDetail',
    exact: true,
    dependencies: ['@common', '@layout5'],
    onload: () => import('./bill-entry/index')
  },
  {
    resource: '@bills',
    value: {
      ['elements/EmptyBody']: loadable(() => import('./elements/EmptyBody')),
      ['elements/TripsExtendsField']: loadable(() => import('./layers/add-trips/TripsExtendsField')),
      ['elements/DetailRiskTip']: loadable(() => import('./riskWarning/DetailRiskTip')),
      ['parts/right-part/billInfo/BillInfoReadOnlyContainer']: loadable(() =>
        import('./parts/right-part/billInfo/BillInfoReadOnlyContainer')
      ),
      ['elements/newBillItem']: loadable(() => import('./elements/newBillItem')),
      ['layers/import-bill/importInputInvoice']: loadable(() => import('./layers/import-bill/importInputInvoice')),
      ['layers/import-bill/InvoiceDetails']: loadable(() => import('./layers/import-bill/InvoiceDetails')),
      ['util/getDetailCalculateMoney']: require('./util/getDetailCalculateMoney').default,
      ['vms/Permission.vm']: require('./vms/Permission.vm'),
      ['util/parse']: require('./util/parse'),
      ['util/autoCalculate']: require('./util/autoCalculate'),
      ['legerTableHelper/helper']: require('./layers/dataLink-detail-modal/LegerRelationTable/helper'),
      ['elements/DataLinkDetailModal']: loadable(() => import('./layers/dataLink-detail-modal/DataLinkDetailModal')),
      ['elements/BillInfoView']: loadable(() => import('./bill-stacker-manager/pages/BillInfoView'))
    }
  }
]
