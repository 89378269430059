/**
 * Created by panwei on 2017/11/22.
 */
import React, { PureComponent } from 'react'
import { Dropdown, Menu } from 'antd'
import SearchInput from '../search-input'
import styles from './currency-dropDown.module.less'
import CurrencyChecked from './images/currency-checked.svg'

function MenuItem(props) {
  let { className } = props
  return <div className={className}>{props.children}</div>
}

export default class CurrencyDropdown extends PureComponent {
  constructor(props) {
    super(props)
    let { data = [], checkedData = [], bus } = props
    this.state = { data, visible: false, checkedIds: checkedData.map(item => item.numCode) }
    bus && bus.on('currency:cancel:add:new', this.handleCancelAddCurrency)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData !== this.props.checkedData) {
      let { checkedData = [] } = nextProps
      this.setState({ checkedIds: checkedData.map(item => item.numCode) })
    }
    if (this.props.data !== nextProps.data) {
      this.setState({ data: nextProps.data })
    }
  }

  componentWillUnmount() {
    let { bus } = this.props
    bus && bus.un('currency:cancel:add:new', this.handleCancelAddCurrency)
  }

  handleValueChange = e => {
    let searchText = e.target.value
    if (searchText.trim()) {
      let searchData = []
      let { data } = this.props
      data.forEach(item => {
        if (
          !!~item.name.indexOf(searchText) ||
          !!~item.strCode.indexOf(searchText) ||
          !!~item.strCode.toLowerCase().indexOf(searchText)
        ) {
          searchData.push(item)
        }
      })
      this.setState({ data: searchData, visible: true })
    } else {
      let { data } = this.props
      this.setState({ data })
    }
  }

  handleChecked = item => {
    let { checkedType = 'image', onChange, multiple = false } = this.props
    if (checkedType === 'image') {
      let { checkedIds } = this.state
      const hasChecked = !~checkedIds.indexOf(item.numCode)
      if (hasChecked) {
        checkedIds = []
        checkedIds.push(item.numCode)
        onChange && onChange(item)
        this.setState({ checkedIds, visible: false })
      }
      if (multiple && !hasChecked) {
        onChange && onChange(item)
      }
    }
  }

  handleAddCurrency = (item, index) => {
    let { checkedIds } = this.state
    let { onChange } = this.props
    checkedIds = checkedIds.slice()
    checkedIds.push(item.numCode)
    this.setState({ checkedIds })
    onChange && onChange(item)
  }

  handleCancelAddCurrency = item => {
    let { checkedIds } = this.state
    checkedIds = checkedIds.filter(numCode => numCode !== item.numCode)
    this.setState({ checkedIds })
  }

  onVisibleChange = visible => {
    this.setState({ visible })
  }

  renderMenu = (data = []) => {
    let { checkedType, menuStyle = {} } = this.props
    let { checkedIds } = this.state
    return (
      <Menu style={{ minHeight: '300px', ...menuStyle }}>
        <MenuItem className={styles['currency-dropdown-menu']}>
          <SearchInput placeholder={i18n.get('搜索币种名称或代码')} onChange={this.handleValueChange} />
        </MenuItem>
        {data.length ? (
          data.map((item, index) => {
            return (
              <Menu.Item key={index}>
                <CurrencyMenuItem
                  item={item}
                  index={index}
                  checkedIds={checkedIds}
                  handleAddCurrency={this.handleAddCurrency}
                  handleChecked={this.handleChecked}
                  checkedType={checkedType}
                />
              </Menu.Item>
            )
          })
        ) : (
          <Menu.Item>
            <div className="h-300 center">{i18n.get('没有数据')}</div>
          </Menu.Item>
        )}
      </Menu>
    )
  }

  render() {
    let { children, placement = 'bottomLeft', trigger, dropdownClassName = '' ,disabled=false} = this.props
    let { data, visible } = this.state
    return (
      <Dropdown
        visible={visible}
        trigger={trigger}
        overlay={this.renderMenu(data)}
        placement={placement}
        overlayClassName={`${styles['currency-dropdown']} ${dropdownClassName}`}
        onVisibleChange={this.onVisibleChange}
        disabled={disabled}
      >
        {children}
      </Dropdown>
    )
  }
}

function CurrencyMenuItem(props) {
  let { item, index, handleAddCurrency, handleChecked, checkedType = 'image', checkedIds } = props
  let { name, icon, symbol, strCode, numCode, rate } = item
  let str = checkedType === 'image' ? (rate ? i18n.get('rate', { rate }) : '') : i18n.get('symbol', { symbol })
  return (
    <div className={styles['currency-dropdown-item']} onClick={() => handleChecked(item, index)}>
      <div className="item-right">
        <img className="item-img" src={icon} />
        <div className="item-content">
          <div className="item-currency">{name}</div>
          <div className="item-code">
            {i18n.get('代码') + i18n.get(`：{__k0}（{__k1}） {__k2}`, { __k0: strCode, __k1: numCode, __k2: str })}
          </div>
        </div>
      </div>
      {checkedType === 'image' ? (
        <ImageChecked checked={!!~checkedIds.indexOf(item.numCode)} />
      ) : (
        <CurrencyAddChecked
          checked={!!~checkedIds.indexOf(item.numCode)}
          item={item}
          index={index}
          handleAddCurrency={handleAddCurrency}
        />
      )}
    </div>
  )
}

function ImageChecked(props) {
  let { checked } = props
  return checked ? <img className="w-20 h-20" src={CurrencyChecked} /> : null
}

function CurrencyAddChecked(props) {
  let { checked, item, index, handleAddCurrency } = props
  return checked ? (
    <div className="item-action-disable">{i18n.get('已添加')}</div>
  ) : (
    <div className="item-action" onClick={() => handleAddCurrency(item, index)}>
      +{i18n.get('添加')}
    </div>
  )
}
