import { configure, Fetch } from '@ekuaibao/fetch';
import { app as api } from '@ekuaibao/whispered';
import qs from 'qs';
import { getV } from '@ekuaibao/lib/lib/help';
configure({
    hideLoading: window.hideLoading,
    showLoading: window.showLoading,
    handleStatus431: function () {
        var n = new Date().getTime();
        var hash = getV(location, 'hash', '');
        var isAuthPage = hash.includes('/auth-check');
        if (!isAuthPage) {
            api.history.replace('/auth-check');
        }
    },
    networkErrorMessage: function () { return i18n.get('网络无法访问，请检查网络设置'); },
    checkNetworkMessage: function () { return i18n.get('请检查网络'); },
    getStaffId: function () { return api.getState('@common.userinfo.staff.id'); },
    handleErrorCode500: function () {
        window.alertMessage(i18n.get('系统正在处理中，请稍后重试'));
    }
});
export function go2RedirectUrl(error) {
    return new Promise(function (resolve) {
        if (error.status === 401) {
            if (window.IS_SMG && window.location.href.indexOf('/thirdparty') >= 0) {
                alert('授权已失效，请通过10.27.143.43/smgface/single/login页面重新登录');
                resolve();
            }
            else {
                var urlState = qs.parse(location.search.slice(1));
                if (urlState.overdueTokenRedirect) {
                    location.href = urlState.overdueTokenRedirect;
                }
                else {
                    resolve();
                }
            }
        }
        else if (error.status === 700) {
            var hash = getV(location, 'hash', '');
            var isMCError = hash.includes('/mc-error');
            if (!isMCError) {
                api.history.replace('/mc-error');
            }
        }
        else {
            resolve('');
        }
    });
}
Fetch.go2RedirectUrl = go2RedirectUrl;
export function fetchHandleError(action) {
    Fetch.handleError = function (err) {
        go2RedirectUrl(err).then(function () {
            action && action(err);
        });
    };
}
export default Fetch;
export * from '@ekuaibao/fetch';
