/**
 *  Created by panwei on 2018/3/29 下午9:32.
 */
import { app as api } from '@ekuaibao/whispered'
import { Fetch } from '@ekuaibao/fetch'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { array2Map, getV } from '@ekuaibao/lib/lib/help'
import { get, set, cloneDeep } from 'lodash'
import { getDisableStateByInvoiceID, getMarkStateByInvoiceID } from '../plugins/bills/bills.action'
const attribites = [
  {
    type: 'attachments',
    changedKey: 'fileId',
    attributePath: 'form',
    componentPath: 'form.specificationId.components',
    subValuePath: ''
  },
  {
    type: 'attachments',
    changedKey: 'fileId',
    attributePath: 'form.details',
    componentPath: 'specificationId.components',
    subValuePath: 'feeTypeForm'
  },
  {
    type: 'attachments',
    changedKey: 'fileId',
    attributePath: 'form.trips',
    componentPath: 'specificationId.components',
    subValuePath: 'tripForm'
  },
  {
    type: 'attachments',
    changedKey: 'fileId',
    attributePath: 'logs',
    componentPath: '',
    subValuePath: 'attachments'
  }
]

//获取请求申请列表中的附件
export function fetchAttachmentForApplies(list, prefix = '') {
  if (list && list.length) {
    let promises = list.map(line => {
      const res = line.value ? line : { value: line }
      return fetchAttachment(res, prefix)
    })
    return Promise.all(promises)
      .then(data => {
        return data.map(item => {
          return { ...item.value }
        })
      })
      .then(items => {
        return { items }
      })
  }
  return list
}

function fnExistUrl(detail) {
  if (!Array.isArray(detail)) return false
  let attachmentsList = []
  detail.forEach(v => {
    let list = get(v, 'feeTypeForm.attachments') || []
    attachmentsList.push(...list)
  })
  return !!attachmentsList.find(v => !!get(v, 'fileId.url'))
}

export function fetchAttachment(res, prefix = '') {
  const detail = cloneDeep(get(res, 'value.form.details'))
  let isExistUrl = fnExistUrl(detail) // 不同地方附件返回的数据结构不一致，导致有些附件图片加载失败,如果以后返回的数据结构一致，该兼容可去掉
  let { value } = res
  let map = {},
    fieldList = []
  let resValue = prefix.length ? value[prefix] : value
  let changedKey = attribites[0].changedKey
  attribites.forEach(line => {
    let { fileIds, fieldMap } = parseData({ ...line, resValue })
    fieldList = fieldList.concat(fileIds)
    map = { ...map, ...fieldMap }
  })
  fieldList = fieldList.filter(id => id && id.length)
  if (fieldList.length) {
    // let ids = fieldList
    return Fetch.POST(
      `/api/v1/attachment/attachments/ids`,
      null,
      { body: { ids: fieldList } },
      {
        hiddenLoading: true
      }
    ).then(data => {
      let requestFileMap = {}
      let { items } = data
      items.forEach(d => {
        requestFileMap[d.id] = d
      })
      Object.keys(map).forEach(key => {
        let oo = map[key] || []
        oo.forEach(o => {
          o[changedKey] = requestFileMap[o[changedKey]] || o.fileId
        })
      })
      isExistUrl && set(res, 'value.form.details', detail)
      return res
    })
  }
  isExistUrl && set(res, 'value.form.details', detail)
  return Promise.resolve(res)
}

export async function fetchInvoice(res, prefix = '', isRecordExpends) {
  const path = !!prefix.length ? `value.${prefix}.form.details` : 'value.form.details'
  const detail = isRecordExpends ? res : get(res, path, [])
  let itemIds = []
  const invoiceIds = []
  detail.forEach(item => {
    const invoices = getV(item, 'feeTypeForm.invoiceForm.invoices', [])
    if (!!invoices.length) {
      invoices.forEach(invoice => {
        itemIds = itemIds.concat(invoice.itemIds)
        invoiceIds.push(invoice.invoiceId)
      })
    }
  })
  if (!!itemIds.length || !!invoiceIds.length) {
    const KA_DISABLE_INVOICE = api.getState()['@common'].powers.KA_DISABLE_INVOICE
    const flowId = get(res, 'value.flowId.id')
    let promiseArr = [
      Fetch.POST('/api/v2/invoice/item', null, { body: { ids: itemIds } }),
      Fetch.POST('/api/v2/invoice', null, { body: { ids: invoiceIds } })
    ]

    if (KA_DISABLE_INVOICE && flowId) {
      promiseArr.push( Fetch.GET(`/api/v2/invoiceDisable/checkCanDisable?flowId=${flowId}`))
      promiseArr.push( Fetch.GET(`/api/v2/invoiceMark/checkCanDisable?flowId=${flowId}`))
    }
    const result = await Promise.all(promiseArr)

    const itemMap = array2Map(result[0].items)
    const invoiceMap = array2Map(result[1].items)

    let invoiceDisableObjList = []
    let invoiceMarkObjList = []
    if(KA_DISABLE_INVOICE){
      if(flowId){ //单据详情根据flowId 查询所有发票状态
        invoiceDisableObjList = result[2].value?.invoiceDisableObjList || []
        invoiceMarkObjList = result[3].value?.invoiceDisableObjList || []
        let currentFlowId = `#${get(res, 'value.flowId.form.code') || get(res, 'value.form.code')}`
        let currentFlowState = get(res, 'value.state')

        invoiceDisableObjList.forEach(i=>{
          i.control = result[2].value?.control
          i.id = i.invoiceId
          i.currentFlowId = currentFlowId
          i.currentFlowState = currentFlowState
        })
       invoiceMarkObjList.forEach(i=>{
          const {invoiceId, canDisable, disable} = i
          i.control = result[3].value?.control
          i.id = invoiceId
          i.currentFlowId = currentFlowId
          i.currentFlowState = currentFlowState
          i.canMark = canDisable  // 发票能否被标记
          i.mark = disable        // 发票是否已经标记
        })

      } else {//随手记或其它没有flowid时 根据id 查询
        const disableInfoResult = await api.dispatch(getDisableStateByInvoiceID(Object.keys(invoiceMap)))
        const markInfoResult = await getMarkStateByInvoiceID(Object.keys(invoiceMap))

        disableInfoResult?.items?.forEach(disable => {
          invoiceDisableObjList.push({
            disable: true,
            id: disable.invoiceId
          })
        })

        markInfoResult?.items?.forEach(mark => {
          invoiceMarkObjList.push({
            mark: true,
            id: mark.invoiceId
          })
        })

      }
    }

    const disableInfoList = array2Map(invoiceDisableObjList)
    const markInfoList = array2Map(invoiceMarkObjList)

    detail?.forEach(item => {
      const invoices = getV(item, 'feeTypeForm.invoiceForm.invoices', [])
      invoices?.forEach(invoice => {
        const details = []
        invoice?.itemIds?.forEach(id => {
          details.push(itemMap[id])
        })
        invoice.details = details.filter(detail => !!detail)
        invoice.master = invoiceMap[invoice.invoiceId]
        KA_DISABLE_INVOICE && (invoice.disableInfo = disableInfoList[invoice.invoiceId])
        KA_DISABLE_INVOICE && (invoice.markInfo = markInfoList[invoice.invoiceId])
      })
    })
    return res
  }
  return res
}

function parseData({ attributePath, componentPath, subValuePath, resValue, type, changedKey }) {
  let map = {},
    ids = []
  let attributeValue = getNodeValueByPath(resValue, attributePath, {})
  if (attributeValue instanceof Array) {
    attributeValue.forEach((item, index) => {
      if (componentPath) {
        let components = getNodeValueByPath(item, componentPath, [])
        let itemValue = getNodeValueByPath(item, subValuePath, [])
        let { fileIds, fieldMap } = getAttributes(components, itemValue, `${attributePath}.${index}.`, type, changedKey)
        map = { ...map, ...fieldMap }
        ids = ids.concat(fileIds)
      } else {
        let itemValue = getNodeValueByPath(item, subValuePath, [])
        map[`${attributePath}.${index}`] = itemValue
        const fileIds = itemValue.filter(oo => !oo?.key?.startsWith('DP:')).map(o => o[changedKey])
        ids = ids.concat(fileIds)
      }
    })
  } else if (attributeValue instanceof Object) {
    let components = getNodeValueByPath(resValue, componentPath, [])
    return getAttributes(components, attributeValue, attributePath + '.', type, changedKey)
  }
  return { fileIds: ids, fieldMap: { ...map } }
}

function getAttributes(components, value, key, type, changedKey) {
  let fieldMap = {},
    fileIds = []
  let attachments = components.filter(o => o.type === type)
  attachments.map(item => {
    let oo = value[item.field]
    if (oo) {
      fieldMap[`${key}${item.field}`] = oo
      if (Array.isArray(oo)) {
        const ids = oo.filter(item => !item?.key?.startsWith('DP:')).map(o => o[changedKey])
        fileIds = fileIds.concat(ids)
      }
    }
  })
  return { fileIds, fieldMap }
}
