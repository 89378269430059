var _a;
export default (_a = {},
    _a['en-US'] = {
        ekuaibao: {
            __global_corporation: 'Enterprise',
            __global_staff: 'Employee',
            __global_scope__title: 'Ekuaibao',
            __global_scope__title__long: 'Ekuaibao',
            __global_scope__title__brand: 'Ekuaibao Professional Edition'
        },
        icbc: {
            __global_corporation: 'Enterprise',
            __global_staff: 'Employee',
            __global_scope__title: 'Reimbursement',
            __global_scope__title__long: 'Reimbursement',
            __global_scope__title__brand: 'Reimbursement'
        },
        hose: {
            __global_corporation: 'Enterprise',
            __global_staff: 'Employee',
            __global_scope__title: 'Hose',
            __global_scope__title__long: 'Hose Cost Control ',
            __global_scope__title__brand: 'Hose'
        }
    },
    _a['zh-CN'] = {
        ekuaibao: {
            __global_corporation: '企业',
            __global_staff: '员工',
            __global_scope__title: '易快报',
            __global_scope__title__long: '易快报销',
            __global_scope__title__brand: '易快报专业版'
        },
        icbc: {
            __global_corporation: '企业',
            __global_staff: '员工',
            __global_scope__title: '财务报销',
            __global_scope__title__long: '财务报销',
            __global_scope__title__brand: '财务报销'
        },
        hose: {
            __global_corporation: '企业',
            __global_staff: '员工',
            __global_scope__title: '合思费控',
            __global_scope__title__long: '合思费控报销',
            __global_scope__title__brand: '合思费控'
        }
    },
    _a);
