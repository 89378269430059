import { app as api } from '@ekuaibao/whispered';
export function initialMoney(dataIndex, record) {
    var value = record[dataIndex] ? record[dataIndex] : '';
    return typeof value === 'object' ? (value.foreign ? value.foreign : value.standard) : value;
}
export function initialDate(dataIndex, record) {
    return record["" + dataIndex] ? record["" + dataIndex].ids : [];
}
export function initalNumber(dataIndex, record) {
    return record["" + dataIndex] ? record["" + dataIndex] : '';
}
export function initalPayeeInfo(dataIndex, record) {
    return record["" + dataIndex] ? record["" + dataIndex] : '';
}
export function initalText(dataIndex, record) {
    return record["" + dataIndex] ? record["" + dataIndex] : '';
}
export var initialvalueMap = {
    MONEY: initialMoney,
    DATE: initialDate,
    NUMBER: initalNumber,
    PAYEEINFO: initalPayeeInfo,
    STRING: initalText,
};
export function initMoneyValue(value) {
    var standardCurrency = api.getState()['@common'].standardCurrency;
    var strCode = standardCurrency.strCode, numCode = standardCurrency.numCode, symbol = standardCurrency.symbol, unit = standardCurrency.unit, scale = standardCurrency.scale;
    return {
        standard: value,
        standardStrCode: strCode,
        standardNumCode: numCode,
        standardSymbol: symbol,
        standardUnit: unit,
        standardScale: scale
    };
}
export function initCreditModalTemplateValue() {
    return [
        {
            label: i18n.get('等级'),
            width: '10%',
            dataIndex: 'code',
            field: 'code',
            type: 'string',
            editable: false,
            model: 'credit'
        },
        {
            label: i18n.get('最低分(包含)'),
            width: '15%',
            dataIndex: 'lowest',
            field: 'lowest',
            type: 'number',
            editable: true,
            model: 'credit'
        },
        {
            label: i18n.get('最高分'),
            width: '15%',
            dataIndex: 'highest',
            field: 'highest',
            type: 'number',
            editable: false,
            model: 'credit'
        },
        {
            label: i18n.get('等级描述'),
            width: '40%',
            dataIndex: 'description',
            field: 'description',
            type: 'string',
            editable: true,
            model: 'credit'
        },
        {
            label: i18n.get('操作'),
            width: '20%',
            dataIndex: 'actions',
            editable: false
        }
    ];
}
export function initTemplateValue() {
    return [
        {
            label: i18n.get('收款信息'),
            dataIndex: 'payeeInfo',
            field: 'payeeInfo',
            type: 'payeeInfo',
            active: true,
            editable: true,
            name: 'payeeInfo'
        },
        {
            label: i18n.get('支付金额'),
            dataIndex: 'money',
            field: 'money',
            type: 'money',
            editable: true
        }
    ];
}
export function getPayPlanReadOnlyTableColumns() {
    return [
        {
            label: i18n.get('收款信息'),
            dataIndex: 'payeeInfo',
            field: 'payeeInfo',
            type: 'payeeInfo',
            active: true,
            editable: true,
            name: 'payeeInfo'
        },
        {
            label: i18n.get('支付金额'),
            dataIndex: 'money',
            field: 'money',
            type: 'money',
            editable: true
        },
        {
            label: i18n.get('支付状态'),
            dataIndex: 'paymentStatus',
            field: 'paymentStatus',
            type: 'paymentStatus',
            editable: true
        }
    ];
}
