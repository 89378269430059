import { __assign } from "tslib";
import { isNull } from './Type';
import { get, groupBy, cloneDeep } from 'lodash';
var textFields = [
    { label: i18n.get('包含'), value: 'contains' },
    { label: i18n.get('不包含'), value: 'not contains' },
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' },
    { label: i18n.get('为空'), value: 'null' },
    { label: i18n.get('不为空'), value: 'not null' }
];
var numberFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' },
    { label: i18n.get('大于'), value: '>' },
    { label: i18n.get('小于'), value: '<' },
    { label: i18n.get('大于等于'), value: '>=' },
    { label: i18n.get('小于等于'), value: '<=' }
];
var dateFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' },
    { label: i18n.get('早于'), value: '<' },
    { label: i18n.get('不早于'), value: '>=' },
    { label: i18n.get('晚于'), value: '>' },
    { label: i18n.get('不晚于'), value: '<=' },
    { label: i18n.get('为空'), value: 'null' },
    { label: i18n.get('不为空'), value: 'not null' }
];
var dateRangeFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' },
    { label: i18n.get('早于'), value: '<' },
    { label: i18n.get('不早于'), value: '>=' },
    { label: i18n.get('晚于'), value: '>' },
    { label: i18n.get('不晚于'), value: '<=' },
    { label: i18n.get('属于'), value: 'in' },
    { label: i18n.get('不属于'), value: 'not in' },
    { label: i18n.get('为空'), value: 'null' },
    { label: i18n.get('不为空'), value: 'not null' }
];
export var dateRange = [
    { label: i18n.get('本周'), value: 'THIS_WEEK' },
    { label: i18n.get('上周'), value: 'LAST_WEEK' },
    { label: i18n.get('本月'), value: 'THIS_MONTH' },
    { label: i18n.get('上月'), value: 'LAST_MONTH' },
    { label: i18n.get('本年'), value: 'THIS_YEAR' },
    { label: i18n.get('去年'), value: 'LAST_YEAR' },
    { label: i18n.get('为空'), value: 'null' },
    { label: i18n.get('不为空'), value: 'not null' }
];
var switcherFields = [
    { label: i18n.get('等于'), value: '=' },
    { label: i18n.get('不等于'), value: '!=' }
];
var baseChildren = [
    { label: i18n.get('名称'), value: 'name' },
    { label: i18n.get('编码'), value: 'code' }
];
var moneyChildren = [{ label: i18n.get('本位币'), value: 'standard' }];
var plannedChildren = [
    { label: i18n.get('百分比'), value: 'percentage' },
    { label: i18n.get('余额'), value: 'balance' }
];
var staffChildren = [
    { label: i18n.get('姓名'), value: 'name' },
    { label: i18n.get('工号'), value: 'code' },
    { label: i18n.get('邮箱'), value: 'email' },
    { label: i18n.get('手机号'), value: 'cellphone' }
];
var payeeInfoChildren = [
    { label: i18n.get('开户行'), value: 'bank' },
    { label: i18n.get('开户网点'), value: 'branch' },
    { label: i18n.get('户名'), value: 'name' },
    { label: i18n.get('账号'), value: 'accountNo' }
];
export function getBaseSource(entityName, sourceTypeFieldName) {
    if (!sourceTypeFieldName) {
        return [
            { label: i18n.get('给定值'), value: 'CONSTANT' }
        ];
    }
    return [
        { label: i18n.get('给定值'), value: 'CONSTANT' },
        { label: sourceTypeFieldName, value: 'BILL_FIELD' },
        { label: '明细字段', value: 'BILL_DETAIL_FIELD' }
    ];
}
export function getDateSource(entityName, sourceTypeFieldName) {
    return [
        { label: i18n.get('给定值'), value: 'CONSTANT' },
        { label: sourceTypeFieldName, value: 'BILL_FIELD' },
        { label: '明细字段', value: 'BILL_DETAIL_FIELD' },
        { label: i18n.get('动态范围'), value: 'DYNAMIC' },
        { label: i18n.get('相对今天'), value: 'RELATIVE_TODAY' }
    ];
}
export function initData(data, dataSource) {
    data.forEach(function (item) {
        if (item.children && !isMoneyItem(item.children)) {
            initData(item.children, dataSource);
        }
        else {
            dataSource.push(item);
        }
    });
}
function isMoneyItem(item) {
    var data = cloneDeep(item);
    if (data.length !== 1) {
        return false;
    }
    var valueObj = data.pop();
    if (valueObj.value === 'standard') {
        return true;
    }
    return false;
}
export function getFieldsType(subjectData, key) {
    if (!key || !subjectData.length) {
        return '';
    }
    var arr = key.split('.');
    var keyStr = arr.pop();
    if (keyStr === 'standard') {
        keyStr = arr.pop();
    }
    var dataSource = [];
    initData(cloneDeep(subjectData), dataSource);
    var field = dataSource.find(function (item) { return item.value === keyStr; });
    var type = getType(field);
    if (type === 'money') {
        type = 'number';
    }
    else if (type === 'ref') {
        var entity = getDataEntity(field);
        type = entity.startsWith('basedata.Dimension.') || entity.startsWith('organization.Department') ? 'text' : type;
    }
    else if (type === 'switcher') {
        return 'boolean';
    }
    return type;
}
export var operatorDataMap = {
    text: textFields,
    autoNumber: textFields,
    number: numberFields,
    date: dateFields,
    dateRange: dateRangeFields,
    boolean: switcherFields
};
export function formatEntityList(data, isCascader) {
    if (!data)
        return [];
    var _a = data.fields, fields = _a === void 0 ? [] : _a, _b = data.planned, planned = _b === void 0 ? [] : _b;
    var fieldList = fields.filter(function (oo) { return getDataEntity(oo) !== 'organization.Staff' && getDataEntity(oo) !== 'basedata.city'; });
    if (planned && planned.length) {
        var planList = planned.map(function (line) {
            return formatPlanned(line, isCascader);
        });
        fieldList = fieldList.concat(planList);
    }
    formatFieldList(fieldList);
    return fieldList;
}
function formatFieldList(fieldList) {
    return fieldList.forEach(function (item, index) {
        if (item.children) {
            formatFieldList(item.children);
        }
        else {
            fieldList[index] = formatFieldType(item);
        }
    });
}
function formatPlanned(line, isCascader) {
    var plannedField = __assign(__assign({}, line), { value: line.id, name: line.id, label: line.name, dataType: { type: 'number' } });
    plannedField.children = plannedChildren;
    return plannedField;
}
function formatFieldType(item) {
    var result = __assign(__assign({}, item), { value: item.name, label: item.label });
    var entity = getDataEntity(item);
    if (getType(item) && (entity.startsWith('basedata.Dimension.') || entity.startsWith('organization.Department'))) {
        result.children = baseChildren;
    }
    if (getType(item) === 'money') {
        result.children = moneyChildren;
    }
    return result;
}
export function formatTempFieldValue(data) {
    if (!data || !data.length)
        return [];
    var fields = data
        .filter(function (item) { return item.type !== 'separator'; })
        .map(function (line) {
        var result = __assign(__assign({}, line), { value: line.field || line.name });
        if (getType(line) === 'ref' && !getDataEntity(line).startsWith('datalink.DataLinkEntity')) {
            result.children = getResultChildren(getDataEntity(line));
        }
        if (getType(line) === 'money') {
            result.children = moneyChildren;
        }
        return result;
    });
    var dataLinkList = fields.filter(function (line) { return isDataLnikField(line); });
    var arr = [];
    getNewTemFieldValue(dataLinkList, arr);
    var fieldList = fields.filter(function (line) { return !isDataLnikField(line); }).concat(arr);
    return templateGroup(fieldList);
}
function getNewTemFieldValue(dataLinkList, arr) {
    dataLinkList.forEach(function (item) {
        var childrenGroup = groupBy(item.children, function (oo) {
            if (oo.children && getType(oo) === 'money') {
                return 'number';
            }
            else if (oo.children && getDataEntity(oo).startsWith('basedata.Dimension')) {
                return 'text';
            }
            else {
                return getType(oo);
            }
        });
        Object.keys(childrenGroup).forEach(function (vv) {
            var str = __assign({}, item);
            str['copyType'] = vv;
            str.children = childrenGroup[vv];
            arr.push(str);
        });
    });
}
function templateGroup(fieldList) {
    return groupBy(fieldList, function (line) {
        if (line.children) {
            if (getType(line) === 'money') {
                return 'number';
            }
            else if (line.copyType) {
                return line.copyType;
            }
            else {
                return 'text';
            }
        }
        else {
            return getType(line);
        }
    });
}
export function getResultChildren(entity) {
    switch (entity) {
        case 'organization.Department':
            return baseChildren;
        case 'organization.Staff':
            return staffChildren;
        case 'pay.PayeeInfo':
            return payeeInfoChildren;
        default:
            return baseChildren;
    }
}
export function getEntityList(arr, type) {
    var moneyField = [];
    var dimensionField = [];
    if (type === 'number') {
        moneyField = arr.filter(function (line) { return getType(line) === 'money'; });
    }
    if (type === 'text') {
        dimensionField = arr.filter(function (line) {
            var entity = getDataEntity(line);
            return entity.startsWith('basedata.Dimension') || entity.startsWith('organization.Department');
        });
    }
    var list = arr
        .filter(function (line) {
        var lineType = getType(line);
        if (['text', 'autoNumber'].includes(type)) {
            return ['text', 'autoNumber'].includes(lineType);
        }
        else {
            return getType(line) === type;
        }
    })
        .concat(moneyField)
        .concat(dimensionField);
    list.forEach(function (item) {
        var type = getType(item);
        var entity = getDataEntity(item);
        if (item.children &&
            type !== 'money' &&
            !item.controlType &&
            !entity.startsWith('basedata.Dimension') &&
            !entity.startsWith('organization.Department')) {
            delete item.children;
        }
    });
    return list;
}
export function getDataEntityIds(data) {
    if (data === void 0) { data = []; }
    return data
        .filter(function (item) { return getType(item) === 'ref' && getDataEntity(item).startsWith('datalink.DataLinkEntity'); })
        .map(function (oo) { return (oo.id = getDataEntity(oo).split('.')[2]); })
        .filter(function (oo) { return !!oo; });
}
export function getType(item) {
    return get(item, 'dataType.type') || get(item, 'type');
}
export function getDataEntity(item) {
    return get(item, 'dataType.entity', '') || get(item, 'entity', '');
}
export function isDataLnikField(item) {
    return getType(item) === 'ref' && getDataEntity(item).startsWith('datalink.DataLinkEntity');
}
export function getCascaderOptions(entityInfoList) {
    var entityArr = entityInfoList.filter(function (oo) { return getType(oo) !== 'dateRange'; });
    entityArr.forEach(function (vv) {
        var _a;
        if (isDataLnikField(vv)) {
            vv.children = (_a = vv === null || vv === void 0 ? void 0 : vv.children) === null || _a === void 0 ? void 0 : _a.filter(function (oo) { return getType(oo) !== 'dateRange'; });
        }
    });
    return entityArr;
}
export function isNullOperate(operator) {
    if (operator === isNull.null || operator === isNull.notNull) {
        return true;
    }
    return false;
}
