import { __assign, __awaiter, __generator } from "tslib";
import { app as api } from '@ekuaibao/whispered';
import { checkPriority } from '../../../../../components/utils/fnFormartDatalinkData';
import getDefaultValue from '../../../../../components/utils/getInitialValue';
export function getAssignmentRuleData(_a) {
    var dataLink = _a.dataLink, template = _a.template, assignmentRule = _a.assignmentRule;
    var _b = assignmentRule.fields, fields = _b === void 0 ? [] : _b;
    var valueMap = {};
    fields.forEach(function (item) {
        template.forEach(function (cmp) {
            if (item.targetField === cmp.field && checkPriority(cmp)) {
                var value = dataLink[item.sourceField];
                var key = cmp.field;
                if (!!value) {
                    if (cmp.type === 'number') {
                        valueMap[key] = "" + value * 1;
                    }
                    else if (cmp.type === 'ref') {
                        valueMap[key] = value;
                    }
                    else {
                        valueMap[key] = value;
                    }
                }
            }
        });
    });
    for (var key in valueMap) {
        if (typeof valueMap[key] === 'undefined') {
            delete valueMap[key];
        }
    }
    return valueMap;
}
export function getDefaultFormValue(_a) {
    var cmps = _a.cmps;
    return __awaiter(this, void 0, void 0, function () {
        var baseDataProperties, userInfo, lastChoice, form, _loop_1, i;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    baseDataProperties = api.getState('@common').globalFields.data;
                    userInfo = api.getState('@common').userinfo.data;
                    lastChoice = api.getState('@common').lastChoice.choiceValue;
                    form = {};
                    _loop_1 = function (i) {
                        var el, field, type, cmpField, defaultValue;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    el = cmps[i];
                                    field = el.field;
                                    type = el.type;
                                    if (!el.defaultValue) return [3, 2];
                                    cmpField = baseDataProperties.find(function (f) { return f.name === field; });
                                    return [4, getDefaultValue(el, cmpField, lastChoice, userInfo.staff)];
                                case 1:
                                    defaultValue = _a.sent();
                                    if (defaultValue) {
                                        form[field] = defaultValue;
                                    }
                                    _a.label = 2;
                                case 2: return [2];
                            }
                        });
                    };
                    i = 0;
                    _b.label = 1;
                case 1:
                    if (!(i < cmps.length)) return [3, 4];
                    return [5, _loop_1(i)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    i++;
                    return [3, 1];
                case 4: return [2, form];
            }
        });
    });
}
export function getBillData(_a) {
    var specification = _a.specification, enityList = _a.enityList, ledgerMoney = _a.ledgerMoney, planPayMoney = _a.planPayMoney;
    return __awaiter(this, void 0, void 0, function () {
        var billRuleMap, defBillForm, standard, billData;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    billRuleMap = {};
                    return [4, getDefaultFormValue({ cmps: specification.components })];
                case 1:
                    defBillForm = _b.sent();
                    enityList.forEach(function (element) {
                        var component = element.component, dataLink = element.dataLink;
                        if (dataLink && component) {
                            billRuleMap[component.field] = dataLink.id;
                            if ((component === null || component === void 0 ? void 0 : component.isLinkageAssignment) && (component === null || component === void 0 ? void 0 : component.assignmentRule)) {
                                var result = getAssignmentRuleData({
                                    dataLink: dataLink,
                                    template: specification.components,
                                    assignmentRule: component.assignmentRule
                                });
                                billRuleMap = __assign(__assign({}, billRuleMap), result);
                            }
                        }
                    });
                    if (ledgerMoney) {
                        if (!Object.keys(billRuleMap).find(function (item) { return item === 'payMoney'; })) {
                            billRuleMap.payMoney = planPayMoney;
                        }
                        standard = Number(billRuleMap.payMoney.standard) - Number(ledgerMoney.standard);
                        billRuleMap.payMoney.standard = standard >= 0 ? standard : 0;
                    }
                    billData = {
                        formType: specification.type,
                        state: 'new',
                        currentSpecification: specification,
                        form: __assign(__assign({}, defBillForm), billRuleMap)
                    };
                    return [2, billData];
            }
        });
    });
}
