import { app as api } from '@ekuaibao/whispered'

export default [
  {
    id: '@safeSetting',
    path: '/safeSetting',
    ref: '/',
    onload: () => import('./safeSetting-view'),
    store: () => import('./safeSetting.store')
  },
  {
    point: '@@menus',
    onload: () => {
      return [
        {
          id: 'safeSetting',
          pId: 'baseSetting',
          permissions: ['SYS_ADMIN'],
          weight: 15,
          label: i18n.get('协助'),
          href: '/safeSetting',
        }
      ]
    }
  }
]