export const stateMap = () => ({
  draft: {
    color: '#eda85a',
    icon: 'state-draft',
    text: i18n.get('待提交'),
    textColor: '#3A3F3F'
  },
  approving: {
    color: '#1890ff',
    icon: 'state-approval',
    text: i18n.get('审批中'),
    textColor: '#3A3F3F'
  },
  sending: {
    color: '#1890ff',
    icon: 'state-approval',
    text: i18n.get('待寄送'),
    textColor: '#3A3F3F'
  },
  receiving: {
    color: '#1890ff',
    icon: 'state-approval',
    text: i18n.get('待收单'),
    textColor: '#3A3F3F'
  },
  pending: {
    color: '#1890ff',
    icon: 'state-approval',
    text: i18n.get('提交中'),
    textColor: '#3A3F3F'
  },
  paying: {
    color: '#1890ff',
    icon: 'state-approval',
    text: i18n.get('待支付'),
    textColor: '#3A3F3F'
  },
  paid: {
    color: '#a1dc63',
    icon: 'state-paid',
    text: i18n.get('已完成'),
    textColor: '#bbbdbd'
  },
  archived: {
    color: '#a1dc63',
    icon: 'state-paid',
    text: i18n.get('已完成'),
    textColor: '#bbbdbd'
  },
  rejected: {
    color: '#f5222d',
    icon: 'state-rejected',
    text: i18n.get('被驳回'),
    textColor: '#f5222d'
  },
  nullify: {
    color: '#f5222d',
    icon: 'state-rejected',
    text: i18n.get('已作废'),
    textColor: '#f5222d'
  },
  PROCESS: {
    color: '#a1dc63',
    icon: 'state-paid',
    text: i18n.get('已完成'),
    textColor: '#bbbdbd'
  },
  failure: {
    color: '#f5222d',
    icon: 'state-paid',
    text: i18n.get('支付失败'),
    textColor: '#bbbdbd'
  }
})

export const permissionKeys = {
  SYS_ADMIN: '系统管理', //@i18n-ignore
  REPORT_VIEW: '费用报表管理', //@i18n-ignore
  BUDGET_MANAGE: '预算管理', //@i18n-ignore
  LOAN_MANAGE: '借款管理', //@i18n-ignore
  EXPENSE_MANAGE: '报销单管理', //@i18n-ignore
  REQUISITION_MANAGE: '申请管理', //@i18n-ignore
  CUSTOM_REPORT_VIEW: '高级报表', //@i18n-ignore
  SETTLEMENT_MANAGE: '结算方式管理', //@i18n-ignore
  TRIP_MANAGE: '差旅管理', //@i18n-ignore
  CORP_WALLET_MANAGE: '企业钱包管理', //@i18n-ignore
  INVOICE_MANAGE: '票据管理', //@i18n-ignore
  SUPPLEMENT_INVOICE: '补充发票', //@i18n-ignore
  AUDIT_ADMIN: '审计日志' //@i18n-ignore
}

export const sourceChannelMap = () => ({
  DEBUGGER: i18n.get('测试环境'),
  DING_TALK: i18n.get('钉钉'),
  KD_CLOUD: i18n.get('云之家'),
  WEIXIN: i18n.get('企业微信'),
  APP: i18n.get('企业创建者'),
  HUAWEI: i18n.get('企业创建者'),
  FEISHU: i18n.get('飞书')
})

export const sourceLeaderMap = () => ({
  DEBUGGER: i18n.get('老大'),
  DING_TALK: i18n.get('主管'),
  KD_CLOUD: i18n.get('负责人'),
  WEIXIN: i18n.get('主管'),
  APP: i18n.get('主管'),
  HUAWEI: i18n.get('主管'),
  FEISHU: i18n.get('主管')
})

export const sourceHelpUrl = {
  DEBUGGER: 'https://www.yuque.com/u147672/dingtalk',
  DING_TALK: 'https://www.yuque.com/u147672/dingtalk',
  KD_CLOUD: 'https://www.yuque.com/u147672/cloud-hub',
  WEIXIN: 'https://www.yuque.com/u147672/work-weixin',
  APP: 'https://www.yuque.com/tv710i/re2u54/ggf6a9',
  HUAWEI: 'https://www.ekuaibao.com/help/ekbapp2/index.html',
  THIRDPARTY: 'https://www.ekuaibao.com/help/ekbapp2/index.html',
  FEISHU: 'https://www.ekuaibao.com/help/ekbapp2/index.html'
}

export const PROPERTY_REG = `/(PROPERTY:[\w\-:_+]*/`

export const IMG_REG = /^(.*)\.(jpg|bmp|gif|ico|pcx|jpeg|tif|tiff|png|raw|tga)$/i

export const agreeType = () => ({
  NO_AUTO_AGREE: i18n.get('非自动同意'),
  APPROVER_SAME_AS_SUBMITTER: i18n.get('该节点的审批人与提交人重复，审批人自动同意'),
  APPROVER_REPEATED: i18n.get('该节点的审批人与前面节点的审批人重复，审批人自动同意'),
  CREDIT_INSPECTION_AUTO: i18n.get('提交人信用抽检未中，该节点自动同意'),
  APPROVER_SAME_AS_OWNER: i18n.get('该节点的审批人与创建人重复，审批人自动同意'),
})

export const skipTypeMap = () => ({
  NO_SKIPPED: i18n.get('禁止提交'),
  APPROVER_NOT_FOUND_BY_ROLE: i18n.get('匹配不到合适的审批人'),
  APPROVER_SAME_AS_SUBMITTER_BY_ROLE: i18n.get('审批人与提交人相同'),
  APPROVER_NOT_FOUND: i18n.get('匹配不到审批人'),
  NO_ABILITY: i18n.get('单据无需支付'),
  REQUISITION_NO_ABILITY: i18n.get('自动跳过'),
  NO_AUTO_AGREE: i18n.get('非自动同意'),
  APPROVER_SAME_AS_SUBMITTER: i18n.get('该节点的审批人与提交人重复，审批人自动同意'),
  APPROVER_REPEATED: i18n.get('该节点的审批人与前面节点的审批人重复，审批人自动同意'),
  APPROVER_SAME_AS_OWNER: i18n.get('该节点的审批人与创建人重复，审批人自动同意'),
})

export const skippedTypeMap = () => ({
  NO_SKIPPED: i18n.get('禁止提交'),
  NO_SKIPPED_TRUE: i18n.get('匹配不到审批人，自动跳过'),
  NO_SKIPPED_FALSE: i18n.get('匹配不到审批人，禁止提交'),
  APPROVER_NOT_FOUND_BY_ROLE: i18n.get('匹配不到合适的审批人'),
  APPROVER_NOT_FOUND_BY_ROLE_TRUE: i18n.get('匹配不到合适的审批人，自动跳过'),
  APPROVER_NOT_FOUND_BY_ROLE_FALSE: i18n.get('匹配不到合适的审批人，禁止提交'),
  APPROVER_SAME_AS_SUBMITTER_BY_ROLE: i18n.get('审批人与提交人相同'),
  APPROVER_SAME_AS_SUBMITTER_BY_ROLE_TRUE: i18n.get('审批人与提交人相同，自动跳过'),
  APPROVER_SAME_AS_SUBMITTER_BY_ROLE_FALSE: i18n.get('审批人与提交人相同，禁止提交'),
  APPROVER_NOT_FOUND: i18n.get('匹配不到审批人'),
  APPROVER_NOT_FOUND_TRUE: i18n.get('匹配不到审批人，自动跳过'),
  APPROVER_NOT_FOUND_FALSE: i18n.get('匹配不到审批人，禁止提交'),
  NO_ABILITY: i18n.get('单据无需支付'),
  NO_ABILITY_TRUE: i18n.get('该单据无需支付，自动跳过'),
  NO_ABILITY_FALSE: i18n.get('该单据无需支付，自动跳过'),
  REQUISITION_NO_ABILITY: i18n.get('该单据无需支付，自动跳过')
})

export const skipWhenApproverNonMatched = {
  false: i18n.get('禁止提交'),
  true: i18n.get('自动跳过')
}

export const channelMap = () => ({
  NBBANK: i18n.get('宁波银行'),
  OFFLINE: i18n.get('线下支付'),
  CREDITEASE: i18n.get('在线支付'),
  ERP: i18n.get('ERP支付'),
  WALLET: i18n.get('企业钱包'),
  SOUCHE: i18n.get('搜车支付'),
  SOUCHEV2: i18n.get('搜车支付(测试)'),
  FINGARD: i18n.get('保融支付'),
  CHANPAY: i18n.get('银企联支付')
  // YEEPAY: i18n.get('易宝支付')
})

export const billTypeMap = () => ({
  expense: i18n.get('报销单'),
  loan: window.IS_SMG ? i18n.get('预支单') : i18n.get('借款单'),
  requisition: i18n.get('申请单'),
  permit: i18n.get('授权单'),
  custom: i18n.get('基础单据'),
  payment: i18n.get('付款单')
})
export const billType = () => ({
  expense: i18n.get('报销'),
  loan: window.IS_SMG ? i18n.get('预支') : i18n.get('借款'),
  requisition: i18n.get('申请'),
  permit: i18n.get('授权'),
  custom: i18n.get('基础'),
  payment: i18n.get('付款')
})
export const PAY_TYPE_STATUS = () => ({
  CORPORATION: i18n.get('企业支付'),
  PERSONAL: i18n.get('个人支付'),
  MIXING: i18n.get('混合支付')
})

//tabs用 已在tabs组件中加入i18n.get 此处不加
export const ORDER_TYPE_ENUMS = () => ({
  car: i18n.get('打车'),
  train: i18n.get('火车'),
  plain: i18n.get('飞机')
})

export const PLATFORM_ENUMS = () => ({
  KD_CLOUD: i18n.get('云之家'),
  DING_TALK: i18n.get('钉钉'),
  DEBUGGER: i18n.get('调试'),
  WEIXIN: i18n.get('企业微信'),
  APP: i18n.get('原生应用'),
  HUAWEI: i18n.get('华为WeLink'),
  FEISHU: i18n.get('飞书')
})

export const CELLPHONE_VALUE_ENUMS = {
  KD_CLOUD: '来自云之家', //@i18n-ignore
  DING_TALK: '来自钉钉', //@i18n-ignore
  DEBUGGER: '来自调试', //@i18n-ignore
  WEIXIN: '来自企业微信', //@i18n-ignore
  APP: '来自原生应用', //@i18n-ignore
  THIRDPARTY: '来自私有化平台', //@i18n-ignore
  HUAWEI: '来自华为', //@i18n-ignore
  FEISHU: '来自飞书' //@i18n-ignore
}

export const DETAIL_SHOWTYPE = {
  LIST: 'LIST',
  TABLE: 'TABLE'
}

export const DETAIL_SHOWTYPE_DESC = () => ({
  LIST: i18n.get('明细列表'),
  TABLE: i18n.get('表格展示')
})

export const THIRDPLATFORM_ENTITY_TYPE = () => ({
  DIMENSION: { label: i18n.get('基础档案'), desc: '' },
  BILL: { label: i18n.get('单据'), desc: '' },
  ORDER: { label: i18n.get('订单'), desc: '' }
})

export const DETAIL_SHOWTYPE_CHILDREN = () => ({
  LIST: null,
  TABLE: [
    { type: 'TABLE', label: i18n.get('按费用类型分组'), level: 0 },
    { type: 'TABLE1', label: i18n.get('按一级费用类型分组'), level: 1 },
    { type: 'TABLE2', label: i18n.get('按二级费用类型分组'), level: 3 } //todo
  ]
})

export const THIRDPLATFORM_ENTITY_DISABLESTRATEGY = () => ({
  MANUAL_ADMIN: { label: i18n.get('允许管理员停启用'), desc: i18n.get('由扩展管理员手动维护停启用状态') },
  LIMIT_COUNT: {
    label: i18n.get('限制次数'),
    desc: i18n.get(
      '即引用的次数不得超过所设定值，超过后则自动停用（管理员可单独对某个实例修改引用次数上限，但不得小于已引用次数）'
    )
  }
})

export const THIRDPLATFORM_ENTITY_SCOPE = () => ({
  ALL: { label: i18n.get('全员可见'), desc: '' },
  PART: { label: i18n.get('部分可见'), desc: i18n.get('在导入数据时，需要指定参与人') }
})

export const THIRDPLATFORM_ENTITY_FIELDMARK = () => ({
  name: i18n.get('名称'),
  code: i18n.get('编码')
})

export const IMPORT_METHOD = () => ({
  excel: i18n.get('Excel 导入'),
  api: i18n.get('API 导入'),
  ebot: i18n.get('EBot 导入')
})

export const INVOICE_TYPE = () => ({
  DIGITAL_NORMAL: i18n.get('增值税电子普通发票'),
  PAPER_NORMAL: i18n.get('增值税普通发票'),
  PAPER_SPECIAL: i18n.get('增值税专用发票'),
  PAPER_CAR: i18n.get('机动车销售统一发票'),
  PAPER_ROLL: i18n.get('增值税普通发票（卷式）'),
  PAPER_FEE: i18n.get('通行费发票'),
  BLOCK_CHAIN: i18n.get('区块链电子发票')
})

export const IDENTIFY_INVOICE_TYPE = () => ({
  DIGITAL_NORMAL: i18n.get('增值税电子普票'),
  DIGITAL_SPECIAL: i18n.get('增值税电子专票'),
  PAPER_NORMAL: i18n.get('增值税纸质普票'),
  PAPER_SPECIAL: i18n.get('增值税纸质专票'),
  PAPER_CAR: i18n.get('机动车专用发票'),
  PAPER_ROLL: i18n.get('卷式发票'),
  PAPER_FEE: i18n.get('通行费发票'),
  BLOCK_CHAIN: i18n.get('区块链发票'),
  SECOND_CAR: i18n.get('二手车发票')
})

export const INVOICE_TYPE_BASE_ENUM = () => ({
  GoodsTransportInvoice: i18n.get('货物运输业增值税专用发票'),
  MotorInvoice: i18n.get('机动车销售统一发票'),
  SecondhandCarInvoice: i18n.get('二手车销售统一发票'),
  VATOrdinaryInvoice: i18n.get('增值税普通发票'),
  VATSpecialInvoice: i18n.get('增值税专用发票')
})

export const printLogMap = name => ({
  remind: i18n.get('print_reminded', { __k0: name }),
  reminded: i18n.get('print_reminded', { __k0: name }),
  autoRemind: i18n.get('print_autoRemind'),
  print: i18n.get('print_printed', { __k0: name }),
  printed: i18n.get('print_printed', { __k0: name }),
  receive: i18n.get('print_received', { __k0: name }),
  received: i18n.get('print_received', { __k0: name })
})

export const PRINT_STATE = () => ({
  reminded: { label: i18n.get('已提醒'), value: 'reminded' },
  printed: { label: i18n.get('已打印'), value: 'printed' },
  received: { label: i18n.get('已收单'), value: 'received' },
  noPrint: { label: i18n.get('未打印'), value: 'noPrint' }
})

export const billTypeToMoneyKey = {
  expense: 'expenseMoney',
  loan: 'loanMoney',
  requisition: 'expenseMoney',
  payment: 'payMoney'
}
