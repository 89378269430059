/**
 * Created by zhaoyue on 2017/7/13.
 */
import styles from './Currency.module.less'
import React from 'react'
import { app as api } from '@ekuaibao/whispered'
import Big from 'big.js'
import { Fetch } from '@ekuaibao/fetch'
import { get } from 'lodash'

export default function Currency(props) {
    let {
        bool,
        value, type, style, styleTwo
    } = props
    const standardCurrency = api.getState('@common.standardCurrency')
    const { symbol = '', scale = 2, strCode } = standardCurrency

    const m = getMoneyValue(value, false)
    const mAbs = Math.abs(isNaN(Number(m)) ? 0 : m)
    let dai = Number(m) < 0 ? '-' : ''
    let moneyValue = new Big(mAbs).toFixed(Number(getScale(value, false, scale)))
    moneyValue = dai + thousandBitSeparator(moneyValue)
    const sc = getStrCode(value, false) || strCode

    const f = getMoneyValue(value, true)
    const mAbsf = Math.abs(isNaN(Number(f)) ? 0 : f)
    let daif = Number(f) < 0 ? '-' : ''
    let moneyValuef = new Big(mAbsf).toFixed(Number(getScale(value, true, scale)))
    moneyValuef = daif + thousandBitSeparator(moneyValuef)
    const scf = getStrCode(value, true) || strCode
    if ((moneyValuef && moneyValuef == '0.00') || (scf === sc && scf === 'CNY')) {
        moneyValuef = false
    }


    if (bool) {
        return (
            <div
                className={styles['money-wrap']}
            >
                {moneyValuef && `${moneyValuef} ${scf}`}
                {moneyValuef && <br />}
                {moneyValue} {sc}
            </div>
        )
    }

    if (type == 'table') {
        return (
            <div
                className={styles['money-wrap-table']}
            >

                {moneyValuef && <span className="span1">{moneyValuef} {scf}</span>}
                <span className={moneyValuef ? "span2" : "span1"}> {moneyValue} {sc}</span>
            </div>
        )
    }

    if (!moneyValuef) {
        return (
            <div
                className={styles['money-wrap']}
                style={style}
            >
                <span className="span1"> {moneyValue} {sc}</span>
            </div>
        )
    }

    return (
        <div
            className={styles['money-wrap']}
            style={style}
        >

            {moneyValuef && <span className="span1">{moneyValuef} {scf}</span>}
            <span className={moneyValuef ? "span2" : "span1"} style={styleTwo}> {moneyValue} {sc}</span>
        </div>
    )
}

export function shortening(money, scale = 2) {
    let unit = ''
    let num = Number(money)
    const w = 10000
    const billion = 100000000
    const pow = Math.pow(10, scale)
    if (num >= billion) {
        if (Fetch.defaultLanguage === 'en-US') {
            num = (Math.floor((num * pow) / w) / pow) * 10
            unit = 'k'
        } else {
            num = Math.floor((num * pow) / w) / pow
            unit = i18n.get('万')
        }
    }
    return { money: thousandBitSeparator(Number(num).toFixed(scale) + ''), unit }
}
export function getNewMoney(value, scale = 2) {
    try {
        let bool = !!value?.foreign
        const m = getMoneyValue(value, bool)
        const sc = getStrCode(value, bool) || ''

        const mAbs = Math.abs(isNaN(Number(m)) ? 0 : m)
        let dai = Number(m) < 0 ? '-' : ''
        let moneyValue = new Big(mAbs).toFixed(Number(getScale(value, false, scale)))
        moneyValue = dai + thousandBitSeparator(moneyValue)

        return moneyValue + ' ' + sc
    } catch (e) {
        return '-'
    }
    return '-'
}
export function formateMoneyDataLink(standard, standardStrCode) {
    const money = new Big(standard)
    const config = [
        [1e12, i18n.get('万亿')],
        [1e8, i18n.get('亿')],
        [1e4, i18n.get('万')]
    ]
    let unit = ''
    let offset = 1
    for (const [_offset, _unit] of config) {
        if (money.gte(_offset)) {
            offset = _offset
            unit = _unit
            break
        }
    }
    let newMoney = money.div(offset).toFixed(2)
    let moneyStr = newMoney
    if (Number(newMoney) < 0) {
        newMoney = String(Math.abs(Number(newMoney)))
        moneyStr = `-${newMoney}${unit} ${standardStrCode}`
    } else {
        moneyStr = `${newMoney}${unit} ${standardStrCode}`
    }
    return { moneyStr, unit, money }
}

function thousandBitSeparator(num) {
    if (num && num.toString().indexOf(',') > -1) {
        return num
    }
    const re = /(-?\d+)(\d{3})/g
    while (re.test(num)) {
        num = num.replace(re, '$1,$2')
    }
    return num
}

function getMoneyValue(value, isShowForeign) {
    if (isShowForeign) {
        return typeof value === 'object' ? (value && value.foreign ? value.foreign : 0) : value || 0
    }
    return typeof value === 'object' ? (value && value.standard ? value.standard : 0) : value || 0
}

function getSymbol(value, isShowForeign) {
    if (isShowForeign) {
        return value && value.foreignSymbol
    }
    return value && value.standardSymbol
}

function getStrCode(value, isShowForeign) {
    if (isShowForeign) {
        return value && value.foreignStrCode
    }
    return value && value.standardStrCode
}

function getScale(value, isShowForeign, defaultScale = 2) {
    let key = 'standardScale'
    if (isShowForeign) {
        key = 'foreignScale'
    }
    const scale = get(value, key)
    return !isNaN(Number(scale)) && scale !== null ? scale : defaultScale
}

Currency.defaultProps = {
    className: '', //外层赋予的class
    isNegative: false, //是否负数
    currency: 'CNY', //货币种类
    value: '0.00', //金额
    isShort: false,
    showSymbol: true,
    showStrCode: false,
    symbolSize: 12,
    isShowForeign: false,
    showForeignNum: true,
    onlyForeign: false
}

 // {
 //   "standard":"202.2",
 //   "standardStrCode":"CNY",
 //   "standardNumCode":"156",
 //   "standardSymbol":"¥",
 //   "standardUnit":"元",
 //   "foreignStrCode":"JPY",
 //   "foreignNumCode":"392",
 //   "foreignSymbol":"J¥",
 //   "foreignUnit":"圆",
 //   "sysRate":"6.6",
 //   "foreign":"30.63636363636363636364",
 //   "standardScale":2,
 //   "rate":"6.6",
 //   "foreignScale":0
 // }
