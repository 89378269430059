/**
 *  Created by panwei on 2018/7/30 下午2:55.
 */

import React from 'react'
import { isMoneyObject } from '../../components/utils/fnCurrencyObj'
import { getMoney } from '../../lib/misc'
import { Tooltip, Spin, Icon } from 'antd'
import { app as api } from '@ekuaibao/whispered'
import RIGHT_INVOICE from '../../images/right-invoice.svg'
import { get } from 'lodash'

const antIcon = <Icon type="loading" style={{ fontSize: 22 }} spin />

export function InvoiceDetailTip(props) {
  let { invoiceAllDetails, checkout } = props
  let tips = invoiceAllDetails
    ? i18n.get('以下特殊标识的为绑定该费用的明细')
    : i18n.get('以下发票明细为绑定该费用的明细')
  // return <div className="tips">{!checkout && <div className="payee-tips">{i18n.get(tips)}</div>}</div>
  return <div className="tips" />
}

export function InvoiceDetails(props) {
  let { dataSource, number, checkout, permissions, canSeeLink, showAllFeeType } = props
  if (!dataSource.length) return null
  const re = new RegExp(`^(-?[0-9]\\d*)(\\.\\d*)?$`)
  return (
    <div className="invoice-details">
      <div className="invoice-details-thead">
        <div className="detail-name">{i18n.get('项目明细')}</div>
        <div className="detail-number">{i18n.get('规格型号')}</div>
        <div className="detail-number">{i18n.get('数量')}</div>
        <div className="detail-number">{i18n.get('金额')}</div>
        <div className="detail-number">{i18n.get('税率')}</div>
        <div className="detail-number">{i18n.get('税额')}</div>
      </div>
      <div className="invoice-details-tbody">
        {dataSource.map((item, idx) => {
          if (item.number) {
            item.number = new Big(item.number).toFixed(2)
            number += parseInt(item.number)
          }
          const taxRate = item.taxRate ? (Number(item.taxRate) >= 0 ? item.taxRate + '%' : item.taxRate) : ''
          const isMoney = isMoneyObject(item.tax) || re.test(item.tax)
          const isView = canSeeLink ? canSeeLink : checkout && permissions && !item.active
          const hasArrow = canSeeLink ? false : isView
          const calssnames = checkout
            ? 'detail-tbody-line detail-tbody-hover'
            : `detail-tbody-line ${item.active && item.notAssociated ? 'isActive' : ''}`
          const classname = checkout
            ? item.active
              ? 'detail-mark-grey'
              : 'detail-mark'
            : item.notAssociated
            ? 'detail-mark-grey'
            : 'detail-mark'
          return (
            <div key={idx} className={calssnames} onClick={handleContingencyInvoiceClick.bind(this, isView, item, showAllFeeType)}>
              {/* <div className={classname} /> */}
              <div className="detail-name">
                <Tooltip
                  placement="topLeft"
                  title={
                    item.active && item.notAssociated
                      ? i18n.get(`{__k0}：{__k1}`, { __k0: i18n.get('未关联明细'), __k1: item.name })
                      : item.name
                  }
                >
                  {item.name}
                </Tooltip>
              </div>
              <div className="detail-number">
               {item.model ? <Tooltip
                  placement="topLeft"
                  title={item.model}
                >
                  {item.model}
                </Tooltip> : '--'}
              </div>
              <div className="detail-number">{item.number || '--'}</div>
              <div className="detail-number">
                <div>{i18n.get(`￥{__k0}`, { __k0: getMoney(item.amount) })}</div>
              </div>
              <div className="detail-number">{taxRate}</div>
              <div className="detail-number">
                {isMoney ? (
                  <div>{i18n.get(`￥{__k0}`, { __k0: getMoney(item.tax) })}</div>
                ) : (
                  <div className="detail-number">{item.tax}</div>
                )}
              </div>
              {hasArrow && (
                <div className="invoice-watch">
                  <img src={RIGHT_INVOICE} alt="" />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export function handleContingencyInvoiceClick(isView, item, showAllFeeType) {
  if (!isView) return
  let { id } = item
  api.invokeService('@invoice-manage:dispatch:getInvoiceRelation', id).then(resp => {
    const { type, flowId, batchId, detailId } = resp
    if (type === 'FLOW') {
      api.invokeService('@bills:get:flow-info', { id: flowId }).then(resp => {
        api.open('@bills:BillStackerModal', {
          viewKey: 'BillInfoView',
          showAllFeeType,
          dataSource: resp.value,
          detailStack: { id: detailId }
        })
      })
    } else if (type === 'BATCH') {
      api.invokeService('@invoice-manage:dispatch:getBatchInfoByBatchId', { batchId }).then(resp => {
        // @i18n-ignore
        let showInvoiceBtn = get(resp, 'value.invoiceId.form.E_system_发票主体_来源') === 'UPLOAD'
        api.open('@invoice-manage:InvoiceBatchModal', { batchInfo: resp.value, showInvoiceBtn })
      })
    }
  })
}

export function InvoiceTotal(props) {
  const { invamt, invtaxamt, number } = props
  const isMoney = Number(invamt) || isMoneyObject(invamt)
  const isMoneyInvtaxamt = Number(invtaxamt) || isMoneyObject(invtaxamt)
  return (
    <div className="total-info">
      <div className="detail-name">{i18n.get('合计')}</div>
      <div className="detail-number" />
      <div className="detail-number" />
      <div className="detail-number">
        {isMoney ? <div>{i18n.get(`￥{__k0}`, { __k0: getMoney(invamt) })}</div> : invamt}
      </div>
      <div className="detail-number" />
      <div className="detail-number">
        {isMoneyInvtaxamt ? <div>{i18n.get(`￥{__k0}`, { __k0: getMoney(invtaxamt) })}</div> : invtaxamt}
      </div>
    </div>
  )
}

export function InvoiceRemark(props) {
  const { remark } = props
  return (
    <div className="remark-wrapper">
      <div className="row">
        <div className="title">{i18n.get('备注')}</div>
        <div className="label">{remark}</div>
      </div>
    </div>
  )
}

export function InvoiceAllDetail(props) {
  const { isLoading, handleGetInvoiceDetailsById, invoice } = props
  return (
    <div className="invoice-detail-wrapper">
      {!isLoading ? (
        <div
          className="invoice-detail"
          onClick={() => {
            handleGetInvoiceDetailsById(invoice)
          }}
        >
          {i18n.get('查看完整发票明细')}
        </div>
      ) : (
        <Spin indicator={antIcon} />
      )}
    </div>
  )
}
