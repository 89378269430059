import { __assign } from "tslib";
import { Store } from '@ekuaibao/i18n';
import { getV } from '@ekuaibao/lib/lib/help';
import { Fetch } from '@ekuaibao/fetch';
import { app } from '@ekuaibao/whispered';
import { changeTheme } from './initColors';
export function updateScopeVariable(staffSetting) {
    var _a;
    var _b;
    var corpWordsReplaceItems = staffSetting.corpWordsReplaceItems, corpStyle = staffSetting.corpStyle;
    app.store.dispatch('@layout5/setCorpStyle')(corpStyle);
    changeTheme({
        color: ((_b = corpStyle === null || corpStyle === void 0 ? void 0 : corpStyle.theme) === null || _b === void 0 ? void 0 : _b.color) || '#22b2cc'
    });
    if (corpWordsReplaceItems && corpWordsReplaceItems.length) {
        var store = Store.getInstance();
        var currentLocale = getV(staffSetting, 'language', Fetch.defaultLanguage);
        var currentScope_1 = store.currentScope;
        var corpNatureObj_1 = {};
        corpWordsReplaceItems.forEach(function (item) {
            if (corpStyle.wordCustom && item.brandName === currentScope_1) {
                corpNatureObj_1["__global_scope__title"] = item.replaceKey;
                return;
            }
            if (corpStyle.wordCustom && item.brandName.length) {
                return;
            }
            corpNatureObj_1["__global_" + item.variable] = item.replaceKey;
        });
        var currentScopeVariable = store.scopeVariables[currentLocale];
        var corpScopeVariable = (_a = {},
            _a[currentScope_1] = __assign(__assign({}, currentScopeVariable[currentScope_1]), corpNatureObj_1),
            _a);
        store.addScopeVariable(currentLocale, corpScopeVariable);
    }
}
app.updateI18nStoreScopeVariable = updateScopeVariable;
