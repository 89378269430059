import * as React from 'react'
import { Progress } from 'antd'
import { Thumbnail } from '../../'

import styles from './index.module.less'

export default class ImageItem extends React.Component {
  render() {
    const { imageUrl, progress = 0, status } = this.props
    const p = Number(progress.toFixed(2))
    const isShowprogress = status === 'uploading'
    return (
      <div className={styles['imageitem-wrapper']}>
        <Thumbnail src={imageUrl} />
        {isShowprogress && <div className="mark" />}
        {isShowprogress && <Progress className="progress" type="circle" percent={p} width={60} />}
      </div>
    )
  }
}
