import './feetype-icon.less'
import React, { Component } from 'react'
import Icon from './icon'

//let testData = {
//  "id" : "0004da2sf4340",
//  "corporationId" : "ekuaibaoId",
//  "name" : "长途",
//  "icon" : "https://images.ekuaibao.com/feetype/sf_1500.png",
//  "color" : "#91d7eb",
//  "parentId" : "ROOT",
//  "specificationId" : "0004da2sf4340:f38cf0dfb713becb5bd11ae4c3ecfac6689a7a2d",
//  "active" : true,
//  "value" : "0004da2sf4340",
//  "label" : "长途"
//}

export default class FeetypeIcon extends Component {
  render() {
    if (this.props.feetype) {
      let color = this.props.disable ? 'rgba(29,43,61,0.15)' : this.props.feetype.color
      return (
        <div className={'feetype-icon vertical ' + (this.props.className || '')}>
          <div
            className="icon"
            style={{
              backgroundImage: 'url(' + this.props.feetype.icon + ')',
              backgroundColor: color,
              backgroundSize: '100% 100%',
              borderRadius: '50%'
            }}
          />
          {this.props.type == 'rich' ? <div className="text">{this.props.feetype.name}</div> : null}
        </div>
      )
    } else {
      return (
        <div className={'feetype-icon ' + (this.props.className || '')}>
          <Icon className="icon no-feetype" name="feetype" />
        </div>
      )
    }
  }
}
