import { Themes, fade, grayGradation } from '@ekuaibao/theme-variables';
var themes = new Themes('theme-web');
var curTheme = themes.getTheme();
var curMode = curTheme.mode || 'light';
var curColor = curTheme.color || '#22b2cc';
var curFont = {
    fontSize: 14,
    lineHeight: 22
};
export var initColors = function () {
    var color = curColor;
    var _theme = { mode: curMode, color: curColor, font: curFont };
    themes.initTheme(_theme, {
        '--a-gray-11': fade(grayGradation.black, 0.06),
        '--a-gray-10': fade(grayGradation.black, 0.1),
        '--a-gray-7': fade(grayGradation.black, 0.28),
        '--a-gray-5': fade(grayGradation.black, 0.48),
        '--a-gray-3': fade(grayGradation.black, 0.72),
        '--a-gray-1': fade(grayGradation.black, 0.96),
        '--F-a-gray-1': fade(grayGradation.white, 0.96),
        '--Fa-gray-1': fade(grayGradation.white, 0.96),
        '--F-a-gray-5': fade(grayGradation.white, 0.48),
        '--a-brand-2': fade(color, 0.08),
        '--a-brand-4': fade(color, 0.24),
        '--a-brand-5': fade(color, 0.32),
        '--a-brand-7': fade(color, 0.52),
        '--a-brand-8': fade(color, 0.64),
        '--a-brand-9': fade(color, 0.76),
        '--a-brand-10': fade(color, 0.88),
        '--theme-base': grayGradation.white,
        '--brand-fadeout-70': fade(color, 0.7),
        '--brand-fadeout-10': fade(color, 0.1),
        '--brand-fadeout-15': fade(color, 0.15),
        '--brand-fadeout-25': fade(color, 0.25),
        '--brand-fadeout-45': fade(color, 0.45),
        '--brand-fadeout-20': fade(color, 0.2),
        '--brand-fadeout-24': fade(color, 0.24),
        '--brand-fadeout-50': fade(color, 0.5)
    }, true);
    return _theme;
};
export var changeTheme = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? curMode : _b, _c = _a.color, color = _c === void 0 ? curColor : _c, _d = _a.font, font = _d === void 0 ? curFont : _d;
    var _theme = { mode: mode, color: color, font: font };
    if (color === curColor && mode === curMode) {
        return _theme;
    }
    themes.changeTheme(_theme, {
        '--a-gray-11': fade(grayGradation.black, 0.06),
        '--a-gray-10': fade(grayGradation.black, 0.1),
        '--a-gray-7': fade(grayGradation.black, 0.28),
        '--a-gray-5': fade(grayGradation.black, 0.48),
        '--a-gray-3': fade(grayGradation.black, 0.72),
        '--a-gray-1': fade(grayGradation.black, 0.96),
        '--F-a-gray-1': fade(grayGradation.white, 0.96),
        '--Fa-gray-1': fade(grayGradation.white, 0.96),
        '--F-a-gray-5': fade(grayGradation.white, 0.48),
        '--a-brand-2': fade(color, 0.08),
        '--a-brand-4': fade(color, 0.24),
        '--a-brand-5': fade(color, 0.32),
        '--a-brand-7': fade(color, 0.52),
        '--a-brand-8': fade(color, 0.64),
        '--a-brand-9': fade(color, 0.76),
        '--a-brand-10': fade(color, 0.88),
        '--theme-base': grayGradation.white,
        '--brand-fadeout-70': fade(color, 0.7),
        '--brand-fadeout-10': fade(color, 0.1),
        '--brand-fadeout-15': fade(color, 0.15),
        '--brand-fadeout-25': fade(color, 0.25),
        '--brand-fadeout-45': fade(color, 0.45),
        '--brand-fadeout-20': fade(color, 0.2),
        '--brand-fadeout-24': fade(color, 0.24),
        '--brand-fadeout-50': fade(color, 0.5)
    }, true);
    return _theme;
};
