import * as React from 'react'
import { FilesUploader, fixData, generateFileName } from '@ekuaibao/uploader'
import { Fetch } from '@ekuaibao/fetch'
import MessageCenter from '@ekuaibao/messagecenter'
import ImageItem from './imageItem'
import { message } from 'antd'
import classnames from 'classnames'
import { showModal } from '@ekuaibao/show-util'

import styles from './index.module.less'
import { EnhanceConnect } from '@ekuaibao/store'

function getFileId(keys) {
  return Fetch.POST(
    '/api/v1/attachment/attachments',
    {},
    {
      body: {
        keys: keys
      }
    }
  )
}

function getToken() {
  return Fetch.GET('/api/v1/attachment/attachments/token').then(result => {
    const { value } = result
    return value
  })
}

function buildData(file, tokenData) {
  let { name } = file
  return { key: generateFileName(name), 'x:originalname': name, token: tokenData && tokenData.token }
}

const maxSize = 2
@EnhanceConnect(state => {
  return {
    uploadServiceUrl: state['@common'].uploadServiceUrl
  }
})
export default class ImageUploade extends React.Component {
  bus = new MessageCenter()

  state = {
    tokenData: '',
    imageUrl: '',
    progress: 0,
    status: 'done'
  }

  componentWillMount() {
    this.initializeToken()
    this.bus.on('over', this.handleOver)
  }

  componentWillUnmount() {
    this.bus.un('over', this.handleOver)
  }

  initializeToken() {
    getToken().then(token => {
      this.setState({ tokenData: token })
    })
  }

  fnCleanSpaceStr(name) {
    return name && name.replace(/\s/g, '')
  }

  errorModal = (files = [], contentStr) => {
    let Content = () => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {files.map(o => (
            <span style={{ wordBreak: 'break-all' }}>{o.name}</span>
          ))}
        </div>
      )
    }

    showModal.error({
      title: contentStr,
      content: <Content />,
      onOk: () => {
        this.setState({ status: 'fail' })
      }
    })
  }

  handleOver = data => {
    if (data && data.length) {
      this.errorModal(data, i18n.get('file-max', { maxSize }))
    }
  }

  filterFiles = files => {
    const fileTypeREG = /^(.*)\.(jpg|jpeg|png)$/i
    let oo = []

    files = files.filter(fileData => {
      const { file = {} } = fileData
      const { name = '' } = file
      if (fileTypeREG.test(name.toLowerCase())) {
        return true
      }
      oo.push(file)
      return false
    })
    if (!!oo.length) this.fileTypeError(oo)
    return files
  }

  fileTypeError = files => {
    this.errorModal(files, i18n.get('以下文件类型错误'))
  }

  fnFixData = file => {
    const { uploadServiceUrl } = this.props
    const fileServiceType = uploadServiceUrl && uploadServiceUrl.fileServiceType
    const { tokenData } = this.state
    return fixData(file, fileServiceType, tokenData, this.tokenErrorAction)
  }

  tokenErrorAction = () => {
    this.initializeToken()
    return message.warning(i18n.get('获取上传验证码失败,请稍候重试'))
  }

  handleOnDone = uploaderFileList => {
    let { onFinish } = this.props

    let keys = []
    let fileNames = {}
    uploaderFileList.forEach(file => {
      if (file.status === 'error') {
        this.setState({ status: 'fail' })
        message.error(i18n.get(`「{__k0}」{__k1}!`, { __k0: name, __k1: i18n.get('上传失败') }))
      } else {
        let { response } = file
        let key = response.key
        keys.push({ key })
        fileNames[key] = response['x:originalname']
      }
    })

    getFileId(keys).then(result => {
      const { items } = result
      if (items && items.length) {
        let file = {}
        items.forEach(item => {
          const { id, url, key, thumbUrl } = item
          const f = {
            fileId: {
              id,
              thumbUrl,
              url
            },
            key,
            fileName: fileNames[key]
          }
          file = f
        })
        this.setState({ status: 'success', imageUrl: file.thumbUrl })
        onFinish && onFinish(file)
      }
    })
  }

  handleOnStart = files => {
    if (files && files.length > 0) {
      this.setState({ status: 'uploading', progress: 0 })
    }
  }

  handleOnChange = uploaderFileList => {
    const file = uploaderFileList[0]
    const progress = file && file.progress.percent
    this.setState({ status: 'uploading', progress })
  }

  getUploadUrl = (file, generateFileName) => {
    return Fetch.GET(`/api/v1/attachment/attachments/presign/$${generateFileName}`)
  }

  render() {
    const { status, progress, imageUrl } = this.state
    const { imageSrc, style, uploadServiceUrl } = this.props
    const url = imageSrc || imageUrl
    const cls = classnames(styles.upload_wrapper, styles[status])
    let uploadUrl = uploadServiceUrl && uploadServiceUrl.uploadUrl
    return (
      <div>
        <div className={cls}>
          <FilesUploader
            style={style}
            name="file"
            action={IS_STANDALONE ? this.getUploadUrl : uploadUrl}
            type={IS_STANDALONE}
            bus={this.bus}
            maxSize={maxSize}
            accept="image/png, image/jpeg, image/jpg"
            multiple={false}
            disabled={false}
            data={IS_STANDALONE ? (file, tokenData) => buildData(file, tokenData) : this.fnFixData}
            format={this.filterFiles}
            onDone={this.handleOnDone}
            onStart={this.handleOnStart}
            onChange={this.handleOnChange}
          >
            <ImageItem status={status} progress={progress} imageUrl={url} />
          </FilesUploader>
        </div>
        {status === 'fail' && <span style={{ color: 'red', fontSize: '12px' }}>{i18n.get("上传失败")}</span>}
      </div>
    )
  }
}

ImageUploade.defaultProps = {
  action: 'https://up.qbox.me'
}
