import React, { PureComponent } from 'react'
import { Select, Tooltip } from 'antd'
import { groupBy } from 'lodash'
import EKBIcon from '../../../elements/ekbIcon'

export default class EKBSelect extends PureComponent {
  onChange = value => {
    const { onChange, selectIndex } = this.props
    if (value !== 'add') {
      onChange && onChange(value, selectIndex)
    }
  }

  onSelect = value => {
    const { addHandel } = this.props
    if (value === 'add') {
      addHandel && addHandel()
    }
  }

  renderAddItem = () => {
    const { addTitle } = this.props
    if (addTitle) {
      return (
        <Select.Option value="add" className="add-condition" label="">
          <div className="img-style">
            <EKBIcon name="#EDico-plus-default" />
          </div>
          <span>{addTitle}</span>
        </Select.Option>
      )
    }
    return null
  }

  fnBuildItems(tags) {
    const { lineKey, optionLabelProp, addTitle, groupOption } = this.props
    const arr = (tags || []).reduce((list, line) => {
      const { value, label, id, name, disabled, tips, optionLabel } = line
      let keyValue = value === undefined ? (id ? id : name) : value
      let labelValue = label === undefined ? name : label
      if (lineKey) {
        keyValue = line[lineKey]
      }

      const optionLabelValue = (optionLabelProp && optionLabel) || labelValue

      list.push(
        <Select.Option key={keyValue} name={labelValue} label={optionLabelValue} disabled={disabled}>
          <Tooltip title={tips} overlayStyle={tips ? {} : { display: 'none' }}>
            {labelValue}
          </Tooltip>
        </Select.Option>
      )
      return list
    }, [])

    if (!groupOption) {
      const addItem = this.renderAddItem()
      if (addItem) {
        arr.push(addItem)
      }
    }
    return arr
  }

  buildGroupItems = (tags, groupOption) => {
    const { groupPath, groupAttr } = groupOption
    const data = groupBy(tags, groupPath)
    const list = Object.keys(data).map(key => {
      const children = data[key]
      const groupLabel = groupAttr.groupLabel[key] || key
      const groupKey = groupAttr.groupKey[key] || key
      return (
        <Select.OptGroup label={groupLabel} key={groupKey}>
          {this.fnBuildItems(children)}
        </Select.OptGroup>
      )
    })
    const addItem = this.renderAddItem()
    if (addItem) {
      list.push(addItem)
    }
    return list
  }

  render() {
    const {
      style,
      disabled = false,
      placeholder = '',
      value,
      tags,
      mode,
      optionFilterProp = 'value',
      groupOption,
      ...others
    } = this.props
    return (
      <Select
        style={style || { width: '100%' }}
        disabled={disabled}
        optionFilterProp={optionFilterProp}
        placeholder={placeholder}
        value={value}
        size="large"
        onChange={this.onChange}
        onSelect={this.onSelect}
        mode={mode}
        showSearch={true}
        {...others}
      >
        {!!groupOption ? this.buildGroupItems(tags, groupOption) : this.fnBuildItems(tags)}
      </Select>
    )
  }
}
