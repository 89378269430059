export var numberToStringMap = {
    1: i18n.get('一'),
    2: i18n.get('二'),
    3: i18n.get('三'),
    4: i18n.get('四'),
    5: i18n.get('五'),
    6: i18n.get('六'),
    7: i18n.get('日')
};
export var stringToNumberMap = {
    一: 1,
    二: 2,
    三: 3,
    四: 4,
    五: 5,
    六: 6,
    日: 7
};
