import React from 'react';
import { Checkbox } from 'antd';
export function checkboxLabel(label, classNames, props) {
    var visible = props.field.inputVisible !== false;
    return (React.createElement(Checkbox, { checked: visible, onChange: function (e) {
            props.field.inputVisible = e.target.checked;
            if (!props.field.inputVisible) {
            }
            props.form.setFields({});
        }, className: classNames }, visible ? label + i18n.get('：') : label));
}
export function hideInput(props) {
    return props.field.inputVisible === false;
}
export function isEmpty(value) {
    return Array.isArray(value) ? value.length === 0 : !value;
}
