import { __awaiter, __generator } from "tslib";
require('./env');
import React from 'react';
import { app, renderAsApp } from '@ekuaibao/whispered';
import Patches from './patches';
import { run } from '@ekuaibao/mfe-dock';
import './config';
require('./_plugins');
export function callback() {
    return __awaiter(this, void 0, void 0, function () {
        var ex_1, dom;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4, run()];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    ex_1 = _a.sent();
                    console.error('index.v0.tsx:callback MfeDock.run()', ex_1);
                    return [3, 3];
                case 3: return [4, app.callback(function (children) { return React.createElement(Patches, null, children); })];
                case 4:
                    dom = _a.sent();
                    return [4, renderAsApp(dom)];
                case 5:
                    _a.sent();
                    return [2];
            }
        });
    });
}
export { app };
export default app;
