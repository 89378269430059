import { __assign, __read } from "tslib";
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import './FeetypeRecommend.less';
import { T } from '@ekuaibao/i18n';
var CLICK_RECOMEND = false;
export default function (props) {
    var _a = props.recommends, recommends = _a === void 0 ? [] : _a, selectedId = props.selectedId;
    var defaultValue = recommends.length ? recommends[0] : undefined;
    if (selectedId && recommends.length) {
        defaultValue = recommends.find(function (line) { return line.id === selectedId; });
    }
    var _b = __read(useState(defaultValue), 2), value = _b[0], setSelectValue = _b[1];
    useEffect(function () {
        if (recommends.length) {
            var value_1 = recommends.find(function (line) { return line.id === selectedId; });
            setSelectValue(value_1);
        }
    }, [selectedId]);
    var handlePanelClick = function (e) {
        if (!CLICK_RECOMEND) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (CLICK_RECOMEND) {
            CLICK_RECOMEND = false;
        }
    };
    var handlePanelItemClick = function (value) {
        handleOnChange(value);
        setSelectValue(value);
        CLICK_RECOMEND = true;
    };
    var handleOnChange = function (value) {
        var onChanged = props.onChanged;
        onChanged && onChanged(value);
    };
    return (React.createElement("div", { className: "feeType_recommend_panel", onClick: handlePanelClick },
        React.createElement("div", { className: "title" },
            React.createElement(T, { name: "\u63A8\u8350" })),
        React.createElement("div", { className: "panel_wrapper" }, recommends.map(function (line, index) {
            return (React.createElement(Tooltip, { key: index, title: "" + line.fullname },
                React.createElement("span", { className: classNames('panel_item', {
                        panel_item_normal: value ? value.id !== line.id : false,
                        panel_item_selected: value ? value.id === line.id : false
                    }), onClick: function () { return handlePanelItemClick(line); } }, line.fullname)));
        }))));
}
function getFullPath(feeType, feeTypeMap) {
    var paths = [feeType.name];
    var item = __assign({}, feeType);
    while (item.parentId) {
        var pFeetype = feeTypeMap[item.parentId];
        if (pFeetype) {
            paths.push(pFeetype.name);
            item = pFeetype;
        }
        else {
            item = {};
        }
    }
    return paths.reverse().join('/');
}
