import { app } from '@ekuaibao/whispered';
export function fnFlowShowFields(plan) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var nodes = (_a = plan === null || plan === void 0 ? void 0 : plan.nodes) !== null && _a !== void 0 ? _a : [];
    var curNode = (nodes === null || nodes === void 0 ? void 0 : nodes.length) &&
        nodes.find(function (v) {
            return (v === null || v === void 0 ? void 0 : v.id) === (plan === null || plan === void 0 ? void 0 : plan.taskId);
        });
    var userId = (_d = (_c = (_b = app.getState()['@common'].userinfo) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.staff) === null || _d === void 0 ? void 0 : _d.id;
    if ((_e = curNode === null || curNode === void 0 ? void 0 : curNode.counterSigners) === null || _e === void 0 ? void 0 : _e.length) {
        var isApporver = (_f = curNode === null || curNode === void 0 ? void 0 : curNode.counterSigners) === null || _f === void 0 ? void 0 : _f.find(function (counterSigner) { var _a; return ((_a = counterSigner === null || counterSigner === void 0 ? void 0 : counterSigner.signerId) === null || _a === void 0 ? void 0 : _a.id) === userId; });
        if (!isApporver) {
            return { currentNodeShowFieldMap: {} };
        }
    }
    if (((_g = curNode === null || curNode === void 0 ? void 0 : curNode.approverId) === null || _g === void 0 ? void 0 : _g.id) !== userId) {
        return { currentNodeShowFieldMap: {} };
    }
    var showFileds = (_j = (_h = curNode === null || curNode === void 0 ? void 0 : curNode.config) === null || _h === void 0 ? void 0 : _h.showFileds) !== null && _j !== void 0 ? _j : [];
    var currentNodeShowFieldMap = (showFileds === null || showFileds === void 0 ? void 0 : showFileds.reduce(function (result, field) {
        result[field] = field;
        return result;
    }, {})) || {};
    return { currentNodeShowFieldMap: currentNodeShowFieldMap, isShowFileds: (_k = curNode === null || curNode === void 0 ? void 0 : curNode.config) === null || _k === void 0 ? void 0 : _k.isShowFileds };
}
