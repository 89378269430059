export var payFromChannelMap = {
    manage: 'manage',
    personal: 'personal',
    select: 'select'
};
export var accountIconMap = {
    BANK: '#EDico-bank',
    WEIXIN: '#EDico-wechat2',
    ALIPAY: '#EDico-zfb-path',
    OVERSEABANK: '#EDico-sea1',
    CHECK: '#EDico-zhipiao1',
    ACCEPTANCEBILL: '#EDico-cdhp1',
    WALLET: '#EDico-shoucarbeifen1',
    OTHER: '#EDico-other',
    CORPWALLET: '#EDico-shoucarbeifen1'
};
export var payeeIconMap = {
    BANK: '#EDico-bank',
    ALIPAY: '#EDico-zfb-path',
    OVERSEABANK: '#EDico-sea1',
    CHECK: '#EDico-zhipiao1',
    ACCEPTANCEBILL: '#EDico-cdhp1',
    WALLET: '#EDico-shoucarbeifen1',
    VIRTUALCARD: '#EDico-shoucarbeifen1'
};
