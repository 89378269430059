/**
 *  Created by panwei on 2018/7/30 下午2:54.
 */
import React from 'react'
import Money from '../puppet/Money'

export function InvoiceMasterInfo(props) {
  const { dataSource } = props
  return (
    <div className="peyee-info">
      {dataSource.map((line, index) => {
        return getInvoiceItem(line, index)
      })}
    </div>
  )
}

function getInvoiceItem(line, index) {
  const { type } = line
  switch (type) {
    case 'money':
      return <InvoiceMoneyItem key={index} line={line} />
    default:
      return <InvoiceItem key={index} line={line} />
  }
}

function InvoiceItem(props) {
  const {
    line: { label, value }
  } = props
  return (
    <div className="row">
      <div className="title">{label}</div>
      <div className="label">{value}</div>
    </div>
  )
}

function InvoiceMoneyItem(props) {
  const {
    line: { label, value }
  } = props
  return (
    <div className="row">
      <div className="title">{label}</div>
      <div className="label">
        <Money value={value} />
      </div>
    </div>
  )
}
