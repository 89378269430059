/**
 *  Created by panwei on 2018/7/27 下午7:22.
 */
import React from 'react'
import styles from './InvoiceCard.module.less'
import { Popconfirm, Popover } from 'antd'

import SVG_INVOICE from '../../images/invoice-header-bg.svg'
import INVOICE_DELETE from '../../images/invoice-delete.png'
import SVG_WAR from '../../images/invoice-payerno-warning.svg'
import SVG_ERROR from '../../images/invoice-payerno-error.png'
import SVG_GREEN_SHIELD from '../../images/invoice-green-shield.svg'

export function InvoiceHeader(props) {
  const { title, hasPdf, handleDownloadPDF, Delete, handleConfirm, className, isFullDigital } = props
  return (
    <div className={styles.invoice_card_header}>
      <div className="invoice-type">{title}</div>
      <img className="invoice-header-bg" src={SVG_INVOICE} />
      {hasPdf && (
        <div onClick={() => handleDownloadPDF()} className={className}>
          {isFullDigital ? i18n.get('查看文件') : i18n.get('查看PDF')}
        </div>
      )}

      {Delete && (
        <Popconfirm
          title={i18n.get('确认删除本张发票？')}
          onConfirm={() => handleConfirm()}
          okText={i18n.get('确认')}
          cancelText={i18n.get('取消')}
        >
          <div className="invoice-delete">
            <img src={INVOICE_DELETE} alt="" />
          </div>
        </Popconfirm>
      )}
    </div>
  )
}

export function InvoicePayerInfo(props) {
  const { buyer, taxIdNum, checkPayerName, checkPayerNumber, payerInfoArr, ischeck, status, message, isFrom } = props
  const {
    label,
    value: { payertaxno, payer }
  } = buyer
  const { label: taxIdNumLabel } = taxIdNum
  return (
    <div className="payer-info-details">
      <div className="row">
        <div className="title">{label}</div>
        <div className="label">
          <InvoicePayerInfoName
            ischeck={ischeck}
            status={status}
            message={message}
            payer={payer}
            payertaxno={payertaxno}
            checkPayerName={checkPayerName}
            checkPayerNumber={checkPayerNumber}
            payerInfoArr={payerInfoArr}
            isFrom={isFrom}
          />
        </div>
      </div>
      <div className="row">
        <div className="title">{taxIdNumLabel}</div>
        <div className="label">
          <InvoicePayerInfoNum
            ischeck={ischeck}
            status={status}
            message={message}
            payer={payer}
            payertaxno={payertaxno}
            checkPayerName={checkPayerName}
            checkPayerNumber={checkPayerNumber}
            payerInfoArr={payerInfoArr}
            isFrom={isFrom}
          />
        </div>
      </div>
    </div>
  )
}

function InvoicePayerInfoName(props) {
  const { payer, payertaxno, checkPayerName, checkPayerNumber, payerInfoArr, message, status, isFrom } = props
  if (payer === '个人') {// @i18n-ignore
    return (
      <div className="dis-f ai-c">
        <span>{i18n.get('个人')}</span>
        <img className="ml-5" src={SVG_GREEN_SHIELD} />
      </div>
    )
  }

  const STATUS_MSG = {
    SUCCESS: i18n.get('校验正确，和公司抬头结果一致'),
    NO_VISIBLE: message || i18n.get('校验失败，不属于可用的公司抬头')
  }

  const STATUS_IMG = { SUCCESS: SVG_GREEN_SHIELD, NO_VISIBLE: SVG_WAR, NO_RESULT: SVG_ERROR }

  let ischeckName = checkPayerNumber === 'no',
    payeeTip = '',
    payeeImg
  let cStatus = status
  if (ischeckName) {
    payeeTip = i18n.get('开票信息不符')
    payeeImg = SVG_WAR
  } else {
    payeeTip = checkPayerName === false ? STATUS_MSG['NO_VISIBLE'] : STATUS_MSG[cStatus] || message
    payeeImg = checkPayerName === false ? STATUS_IMG['NO_RESULT'] : STATUS_IMG[cStatus]
  }

  const isNumberError = !checkPayerNumber || checkPayerNumber === 'no'
  let sameNameObj
  if (isNumberError) {
    sameNameObj = payerInfoArr.find(el => el.name === payer)
  }
  // 发票管理页面不受可见性控制
  if (isFrom === 'invoiceManager') {
    if (!checkPayerName) {
      const payerObj = payerInfoArr.find(el => el.payerNo === payertaxno)
      const name = payerObj && payerObj.name
      payeeTip = name ? i18n.get(`{__k0}：{__k1}`, { __k0: i18n.get('对应抬头'), __k1: name }) : i18n.get('开票信息不符')
      cStatus = checkPayerNumber === 'no' ? 'NO_VISIBLE' : 'NO_RESULT'
      payeeImg = STATUS_IMG[cStatus]
    } else {
      cStatus = 'SUCCESS'
      payeeTip = STATUS_MSG[cStatus]
      payeeImg = STATUS_IMG[cStatus]
    }
  }
  return (
    <div>
      <div className="dis-f ai-c">
        <Popover placement="top" content={<RichMessage message={i18n.get(payeeTip)} />}>
          <span className={checkPayerName ? '' : 'color-red'}>
            {checkPayerName === 'no' ? i18n.get('无法获取') : payer}
          </span>
          {cStatus && <img className="ml-5" src={payeeImg} />}
        </Popover>
      </div>
      {checkPayerName && checkPayerName !== 'no' && isNumberError && (
        <div className="warning-text">
          <font className="mr-5">{i18n.get('对应税号')}</font>
          {sameNameObj && sameNameObj.payerNo}
        </div>
      )}
    </div>
  )
}

function InvoicePayerInfoNum(props) {
  let { payertaxno, payer, checkPayerName, checkPayerNumber, payerInfoArr, message, status, isFrom } = props
  payertaxno = payertaxno && payertaxno.toUpperCase()
  let cStatus = status
  if (payer === '个人') return <span>{payertaxno}</span>// @i18n-ignore
  if (checkPayerNumber === 'noCheck') {
    return <span>{payertaxno || i18n.get('无法获取')}</span>
  }

  const STATUS_MSG = {
    SUCCESS: i18n.get('校验正确，和公司纳税人识别号一致'),
    NO_VISIBLE: message || i18n.get('校验失败，不属于可用的纳税人识别号')
  }
  const STATUS_IMG = { SUCCESS: SVG_GREEN_SHIELD, NO_VISIBLE: SVG_WAR, NO_RESULT: SVG_ERROR }

  let ischeckName = checkPayerNumber === 'no',
    payeeTip = '',
    payeeImg
  if (ischeckName) {
    payeeTip = i18n.get('该发票上纳税人识别号信息缺失')
    payeeImg = SVG_WAR
  } else {
    payeeTip = checkPayerName === false ? STATUS_MSG['NO_VISIBLE'] : STATUS_MSG[cStatus] || message
    payeeImg = checkPayerName === false ? STATUS_IMG['NO_RESULT'] : STATUS_IMG[cStatus]
  }

  const isNameError = !checkPayerName || checkPayerName === 'no'
  let sameNumberObj
  if (isNameError) {
    sameNumberObj = payerInfoArr.find(el => el.payerNo === payertaxno)
  }
  // 发票管理页面不受可见性控制
  if (isFrom === 'invoiceManager') {
    if (!checkPayerNumber) {
      const payerObj = payerInfoArr.find(el => el.name === payer)
      const payerNo = payerObj && payerObj.payerNo
      payeeTip = payerNo ? i18n.get(`{__k0}：{__k1}`, { __k0: i18n.get('对应的税号'), __k1: payerNo }) : i18n.get('无法获取购买方纳税人识别号')
      cStatus = 'NO_RESULT'
    } else if (checkPayerNumber === 'no') {
      cStatus = 'NO_VISIBLE'
      payeeTip = STATUS_MSG[cStatus]
    } else {
      cStatus = 'SUCCESS'
      payeeTip = STATUS_MSG[cStatus]
    }
    payeeImg = STATUS_IMG[cStatus]
  }

  const numberCls = checkPayerNumber === 'no' ? 'color-red' : checkPayerNumber ? '' : 'color-red'
  return (
    <div>
      <div className="dis-f ai-c">
        <Popover placement="top" content={<RichMessage message={i18n.get(payeeTip)} />}>
          <span className={numberCls}>{checkPayerNumber === 'no' ? i18n.get('无法获取') : payertaxno}</span>
          {cStatus && <img className="ml-5" src={payeeImg} />}
        </Popover>
      </div>
      {checkPayerNumber && checkPayerNumber !== 'no' && isNameError && (
        <div className="warning-text">
          <font className="mr-5">{i18n.get('对应购买方')}</font>
          {sameNumberObj && sameNumberObj.name}
        </div>
      )}
    </div>
  )
}

function RichMessage({ message }) {
  return <span className={styles['invoice-payer-info-tooltip']} dangerouslySetInnerHTML={{ __html: message }} />
}
