import { __decorate, __extends } from "tslib";
import React from 'react';
import { app } from '@ekuaibao/whispered';
import { Point } from '@ekuaibao/whispered';
import { flatten } from 'lodash';
import { EnhanceLayerManager } from '@ekuaibao/enhance-layer-manager';
var LM = (function (_super) {
    __extends(LM, _super);
    function LM() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        _this.handleCloseLayer = function () {
            var layerManager = _this.props.layerManager;
            layerManager.close();
        };
        _this.handleOpenLayer = function (key, props, isSingle) {
            var layerManager = _this.props.layerManager;
            return isSingle ? layerManager.open(key, props) : layerManager.push(key, props);
        };
        return _this;
    }
    LM.prototype.componentDidMount = function () {
        app.watch('@@:open:layer', this.handleOpenLayer);
        app.watch('@@:close:layer', this.handleCloseLayer);
        app.watch('@layout:support:select-member', this.handleSelectStaff);
    };
    LM.prototype.componentWillUnmount = function () {
        app.un('@@:open:layer', this.handleOpenLayer);
        app.un('@@:close:layer', this.handleCloseLayer);
        app.un('@layout:support:select-member', this.handleSelectStaff);
    };
    LM.prototype.handleSelectStaff = function (args) {
        var checkedList = [
            { type: 'department-member', multiple: !!args.multiple, checkedKeys: args.checkedKeys || args.users || [] }
        ];
        return app
            .open('@layout:SelectStaffsModal', {
            checkedList: checkedList,
            disabledKeys: args.closeable,
            showStaffsAll: args.showStaffsAll,
            whiteList: args.whiteList,
            isVisibilityStaffs: args.isVisibilityStaffs,
            showResignationStaff: args.showResignationStaff,
            isCrosscorp: args.isCrosscorp,
            crosscorpOwners: args.crosscorpOwners,
            isShowLeftCrossCorpNode: args.isShowLeftCrossCorpNode,
            isNewContactList: !!(args === null || args === void 0 ? void 0 : args.isNewContactList),
            isMcTransfer: !!args.isMcTransfer,
            defaultDeparment: args.defaultDeparment
        })
            .then(function (data) {
            var checkedList = data.checkedList;
            var checkedData = checkedList[0].checkedData;
            if (!!args.isMcTransfer) {
                args.callback && args.callback(!!args.multiple ? checkedData : checkedData[0], data.selectedDept);
                return;
            }
            args.callback && args.callback(!!args.multiple ? checkedData : checkedData[0]);
        });
    };
    LM.prototype.render = function () {
        return null;
    };
    LM = __decorate([
        EnhanceLayerManager(function (props) { return props.layers; })
    ], LM);
    return LM;
}(React.Component));
export var GlobalLayerManager = function () {
    return (React.createElement(Point, { point: "@@layers", namespace: null }, function (data) { return React.createElement(LM, { layers: flatten(data) }); }));
};
export default GlobalLayerManager;
