import { __awaiter, __generator, __read, __spread } from "tslib";
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util';
import { app as api } from '@ekuaibao/whispered';
import { array2object } from '@ekuaibao/helpers';
import { set } from 'lodash';
import get from 'lodash/get';
import { Modal } from 'antd';
export default function (resValue, prefix) {
    if (prefix === void 0) { prefix = ''; }
    return __awaiter(this, void 0, void 0, function () {
        var value, ids, _a, formFields, formIds, _b, detailFields, detailIds, _c, tripFields, tripIds, res, _d, items, map;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!resValue) {
                        return [2, resValue];
                    }
                    value = prefix.length ? getNodeValueByPath(resValue, "value." + prefix) : resValue.value;
                    ids = [];
                    _a = formStaff(value), formFields = _a.formFields, formIds = _a.formIds;
                    _b = detailsStaff(value), detailFields = _b.detailFields, detailIds = _b.detailIds;
                    _c = detailsStaff(value, 'form.trips', 'tripForm'), tripFields = _c.detailFields, tripIds = _c.detailIds;
                    if (formIds.length) {
                        ids = ids.concat(formIds);
                    }
                    if (detailIds.length) {
                        ids = ids.concat(detailIds);
                    }
                    if (tripIds.length) {
                        ids = ids.concat(tripIds);
                    }
                    if (!ids.length) return [3, 2];
                    return [4, api.invokeService('@common:get:staff:by:ids', { ids: ids })];
                case 1:
                    res = _e.sent();
                    _d = res.items, items = _d === void 0 ? [] : _d;
                    map = array2object(items);
                    fillFromValue(value, map, formFields);
                    fillDetailValue(value, map, detailFields);
                    fillDetailValue(value, map, tripFields, 'form.trips', 'tripForm');
                    return [2, resValue];
                case 2: return [2, resValue];
            }
        });
    });
}
function formStaff(value) {
    var components = getNodeValueByPath(value, 'form.specificationId.components', []);
    var mutilFields = components.filter(function (c) { return c.multiple; }).filter(function (c) { return isStaffRef(c.field); });
    var ids = [];
    if (mutilFields.length) {
        mutilFields.forEach(function (field) {
            var val = value.form[field.field];
            if (val) {
                ids = ids.concat(val);
            }
        });
    }
    return { formFields: mutilFields, formIds: ids };
}
function detailsStaff(value, path, subPath) {
    if (path === void 0) { path = 'form.details'; }
    if (subPath === void 0) { subPath = 'feeTypeForm'; }
    var details = getNodeValueByPath(value, path, []);
    var fields = [];
    var ids = [];
    if (details.length) {
        details.forEach(function (d) {
            var components = getNodeValueByPath(d, 'specificationId.components', []);
            var mutilFields = components.filter(function (c) { return c.multiple; }).filter(function (c) { return isStaffRef(c.field); });
            fields.push(mutilFields);
        });
        if (fields.length) {
            fields.forEach(function (list, index) {
                var item = details[index];
                list.forEach(function (field) {
                    var val = getNodeValueByPath(item, subPath + "." + field.field);
                    if (val) {
                        ids = ids.concat(val);
                    }
                });
            });
        }
    }
    ids = Array.from(new Set(ids));
    return { detailFields: fields, detailIds: ids };
}
function fillFromValue(value, map, formFields) {
    if (formFields.length) {
        formFields.forEach(function (field) {
            var val = value.form[field.field];
            if (val) {
                value.form[field.field] = val.map(function (id) { return map[id]; });
            }
        });
    }
}
function fillDetailValue(value, map, detailFields, path, subPath) {
    if (path === void 0) { path = 'form.details'; }
    if (subPath === void 0) { subPath = 'feeTypeForm'; }
    if (detailFields.length) {
        var details_1 = getNodeValueByPath(value, path, []);
        detailFields.forEach(function (fields, index) {
            var item = details_1[index];
            fields.forEach(function (field) {
                var val = getNodeValueByPath(item, subPath + "." + field.field);
                if (val) {
                    var items = val.map(function (id) { return map[id]; });
                    set(item, subPath + "." + field.field, items);
                }
            });
        });
    }
}
function isStaffRef(field) {
    var baseDataPropertiesMap = api.getState()['@common']['globalFields'].baseDataPropertiesMap || {};
    var properties = baseDataPropertiesMap[field];
    var entity = getNodeValueByPath(properties, 'dataType.elemType.entity');
    return entity === 'organization.Staff';
}
export function filterMultiStaff(options, fieldMap) {
    return __awaiter(this, void 0, void 0, function () {
        var multiStaffsKeys, staffs_1, flag_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    multiStaffsKeys = Object.keys(options.filters).filter(function (key) {
                        var entity = get(fieldMap[key], 'elemType.entity');
                        return entity === 'organization.Staff' && options.filters[key];
                    });
                    if (!multiStaffsKeys.length) return [3, 2];
                    return [4, api.invokeService('@common:get:staffs:external').then(function (resp) { return __spread(resp[0], resp[1]); })];
                case 1:
                    staffs_1 = _a.sent();
                    flag_1 = false;
                    multiStaffsKeys.forEach(function (key) {
                        var searchValue = staffs_1
                            .filter(function (staff) { return !!~staff.name.indexOf(options.filters[key]) || !!~staff.code.indexOf(options.filters[key]); })
                            .map(function (v) { return v.id; });
                        if (searchValue.length) {
                            options.filters[key] = searchValue;
                        }
                        if (searchValue.length > 200) {
                            flag_1 = true;
                            options.filters[key] = '';
                        }
                    });
                    if (flag_1) {
                        Modal.error({
                            title: i18n.get('匹配到的人员过多'),
                            content: i18n.get('匹配到的人员过多，请尽量输入完整人员姓名，或工号')
                        });
                    }
                    _a.label = 2;
                case 2: return [2];
            }
        });
    });
}
