export var templates = [
    {
        name: 'RANGE',
        showLabel: i18n.get('限制日期范围'),
        disabled: false,
        type: 'dimension-date-select',
        editable: true,
        isChecked: true,
        hiddenLabel: true,
        placeholder: i18n.get('请输入')
    },
    {
        name: 'WEEK',
        showLabel: i18n.get('限制星期'),
        disabled: false,
        type: 'dimension-week-select',
        editable: true,
        isChecked: false,
        hiddenLabel: true,
        tagsList: [
            i18n.get('一'),
            i18n.get('二'),
            i18n.get('三'),
            i18n.get('四'),
            i18n.get('五'),
            i18n.get('六'),
            i18n.get('日')
        ]
    }
];
