export default [
    {
        id: '@risk-manage',
        path: '/risk/display-config',
        ref: '/',
        onload: function () { return import('./pages/risk-display-control'); }
    },
    {
        point: '@@layers',
        prefix: '@risk-manage',
        onload: function () { return ([
            {
                key: 'RiskDisplayControlEdit',
                getComponent: function () {
                    return import('./modal/risk-display-control-edit-modal');
                },
                width: 900,
                maskClosable: false,
            },
        ]); }
    },
];
