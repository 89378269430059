import { __assign } from "tslib";
import { app as api } from '@ekuaibao/whispered';
var isPayNode = function (node) { return node.name === i18n.get('出纳支付节点') || node.name === '出纳支付'; };
var getStaffShowByConfig = function (value) {
    if (!(value === null || value === void 0 ? void 0 : value.name))
        return;
    var config = api.getState('@common.organizationConfig');
    if (!config.applyToGlobal) {
        return value.name;
    }
    var str = getStaffShowValue(value, config.staffDisplayConfig);
    return str ? "" + value.name + str : "" + value.name;
};
var getStaffShowValue = function (value, staffDisplayConfig) {
    var showValue = '';
    if (staffDisplayConfig.length > 1) {
        var str = staffDisplayConfig[1];
        if (str === 'deptCode') {
            var departmentMapData = api.getState('@common.department.mapData') || {};
            var externalDepartmentMapData = api.getState('@common.externalDepartment.mapData') || {};
            var deptData = __assign(__assign({}, departmentMapData), externalDepartmentMapData);
            var deptId = typeof value.defaultDepartment === 'object' ? value.defaultDepartment.id : value.defaultDepartment;
            if (!deptId) {
                deptId =
                    value.externalDefaultDepartment && typeof value.externalDefaultDepartment === 'object'
                        ? value.externalDefaultDepartment.id
                        : value.externalDefaultDepartment;
            }
            var depValue = deptData[deptId];
            if (depValue) {
                showValue = depValue.code
                    ? "" + i18n.get('（') + depValue.name + "-" + depValue.code + i18n.get('）')
                    : "" + i18n.get('（') + depValue.name + i18n.get('）');
            }
        }
        else {
            showValue = value && value[str] ? "" + i18n.get('（') + value[str] + i18n.get('）') : '';
        }
    }
    return showValue;
};
export { isPayNode, getStaffShowValue, getStaffShowByConfig };
