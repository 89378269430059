import './search-input.less'
import { Input, Icon } from 'antd'
import React, { Component } from 'react'
const Search = Input.Search
import classNames from 'classnames'

export default class SearchInput extends Component {
  state = { value: this.props.value || '', focus: false }

  componentWillReceiveProps(nextProps) {
    const lastValue = this.props.value
    const lastSearchClear = this.props.searchclear
    const nextValue = nextProps.value
    const nextSearchClear = nextProps.searchclear
    if (lastValue !== nextValue) {
      this.setState({ value: nextValue || '' })
    }
    if (lastSearchClear !== nextSearchClear) {
      this.handleClear()
    }
  }

  handleInputChange = e => {
    this.setState({ value: e.target.value })
    this.props.onChange && this.props.onChange(e)
  }

  handleFocusBlur = e => {
    this.setState({ focus: e.target === document.activeElement })
  }

  handleSearch = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.value)
    }
  }

  handleClear = () => {
    this.setState({ value: '' })
    this.props.onChange && this.props.onChange({ target: { value: '', category: this.props.category } })
  }

  render() {
    const { style, className, onSearch, isShowClearIcon = true, menuSearch=false, ...restProps } = this.props
    const btnCls = classNames({
      'ant-search-btn': true,
      'ant-search-btn-noempty': !!this.state.value.trim()
    })
    const searchCls = classNames({
      'ant-search-input': true,
      'ant-search-input-focus': this.state.focus
    })
    return (
      <div className={classNames('ant-search-input-wrapper', 'ekb-ele-search-input', className)} style={style}>
        <Search
          {...restProps}
          value={this.state.value}
          onChange={this.handleInputChange}
          onFocus={this.handleFocusBlur}
          onBlur={this.handleFocusBlur}
          onPressEnter={this.handleSearch}
          onSearch={this.handleSearch}
        />
        {isShowClearIcon && <div className={classNames('clear', { hidden: !this.state.value })} onClick={this.handleClear}>
          {!menuSearch ? <Icon type="cross-circle-o" />
          :<Icon type="cross-circle" />}
        </div>}
      </div>
    )
  }
}
