import '../file/iconfont'
import '../file/iconfont7'
import '../file/iconPark'
import { Tooltip } from 'antd'
import React from 'react'

export default function EKBIcon(props) {
  let { name, className = '', style = {}, onClick, tooltipTitle, placement } = props
  let handleOnClick = e => {
    onClick && onClick(e)
  }
  let newName = name
  if (typeof name === 'object') {
    //扩展中心列表里面，第三方平台添加的应用图标需要根据fileId查询出对象
    newName = name.thumbUrl
  }
  let comp = (
    <svg className={`icon ${className}`} style={style} aria-hidden="true" onClick={handleOnClick}>
      <use xlinkHref={newName} />
    </svg>
  )
  if (/^http/.test(newName)) {
    comp = <img src={newName} className={`icon ${className}`} style={style} onClick={handleOnClick} />
  }

  if (tooltipTitle && tooltipTitle.length) {
    return (
      <Tooltip title={tooltipTitle} placement={placement}>
        {comp}
      </Tooltip>
    )
  }

  return comp
}
