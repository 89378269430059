import { __extends } from "tslib";
import React, { PureComponent } from 'react';
import { Popover } from 'antd';
import styles from './PersonnelList.module.less';
import { renderIcon, personName } from './utilsSource';
import { getStaffShowByConfig } from '../../utilFn';
var ENGLISHNUM = 6;
var CHINESENUM = 3;
var INDEX = 8;
var CINDEX = 1;
var PersonnelList = (function (_super) {
    __extends(PersonnelList, _super);
    function PersonnelList(props) {
        var _this = _super.call(this, props) || this;
        _this.personName = function (name) {
            var isChina = /.*[\u4e00-\u9fa5]+.*$/.test(name);
            var index = isChina ? CHINESENUM : ENGLISHNUM;
            if (name && name.length > index) {
                return name.substring(0, index) + '...';
            }
            return name;
        };
        _this.popoverCardContent = function () {
            var dataSource = _this.props.dataSource;
            return dataSource.map(function (item, index) {
                var icon = renderIcon(item);
                var showName = getStaffShowByConfig(item);
                return (React.createElement(Popover, { content: showName },
                    React.createElement("div", { className: "personnel-item", key: index },
                        icon,
                        item && React.createElement("div", { className: "person-name" }, personName(showName, CHINESENUM, ENGLISHNUM)))));
            });
        };
        _this.renderMoreView = function () {
            var _a = _this.props, dataSource = _a.dataSource, popUpTitle = _a.popUpTitle, domStr = _a.domStr;
            var count = dataSource.length;
            var counStr = i18n.get("\u5171{__k0}\u4EBA", { __k0: count });
            var titleStr = popUpTitle ? popUpTitle : counStr;
            var divStr = domStr ? domStr : counStr;
            return (React.createElement(Popover, { content: _this.popoverCardContent(), title: titleStr, placement: "bottomLeft", overlayClassName: styles['popoverCard'], overlayStyle: { maxWidth: 408, maxHeight: 300, overflowY: 'auto' } },
                React.createElement("div", { className: "more" }, divStr)));
        };
        _this.state = {
            isExtend: false
        };
        return _this;
    }
    PersonnelList.prototype.render = function () {
        var _a = this.props, _b = _a.moreLine, moreLine = _b === void 0 ? false : _b, dataSource = _a.dataSource;
        var isExtend = this.state.isExtend;
        var count = dataSource.length;
        var cDataSource = isExtend ? dataSource : dataSource.slice(0, INDEX);
        var oDataSource = count === CINDEX ? dataSource.slice(0, CINDEX) : null;
        return (React.createElement("div", { className: styles['personnel-wrapper'] }, moreLine ? (React.createElement("div", { className: "mutirowstaff-wrapper" },
            React.createElement("div", { className: "mutistaff-content" },
                cDataSource.map(function (line, index) {
                    return React.createElement(StaffItem, { key: index, line: line, i: index });
                }),
                !isExtend && count > INDEX && (React.createElement("div", { className: "more", onClick: this.handleMoreData },
                    i18n.get('共'),
                    count,
                    i18n.get('人')))))) : (React.createElement("div", { className: "personnel-wrapper-oneLine" },
            React.createElement("div", { className: "personnel-items" },
                count > CINDEX && this.renderMoreView(),
                oDataSource &&
                    oDataSource.map(function (line, index) {
                        return React.createElement(StaffItem, { key: index, line: line, i: index });
                    }))))));
    };
    return PersonnelList;
}(PureComponent));
export default PersonnelList;
function StaffItem(props) {
    var line = props.line, i = props.i;
    var oName = line && getStaffShowByConfig(line);
    var cName = personName(oName, CHINESENUM, ENGLISHNUM);
    var icon = renderIcon(line);
    return (React.createElement("div", { className: "staff-item", key: i },
        React.createElement("div", { className: "staff-avatar" }, icon),
        React.createElement("div", { className: "staff-name" },
            React.createElement(Popover, { content: oName, placement: "bottomLeft" },
                React.createElement("div", null, cName)))));
}
