import { showModal } from '@ekuaibao/show-util';
import { app } from '@ekuaibao/whispered';
export default registerHandler;
export function registerHandler() {
    var bus = app.container.get('@@bus');
    bus.on('@@system:error', function (err) {
        showModal.error(err);
    });
    bus.on('@@showModal:error', function (err) {
        showModal.error(err);
    });
    bus.on('@@system:goto', function (path) {
        app.go(path, true);
    });
    bus.on('@@showModal.info', function (obj) {
        showModal.info(obj);
    });
    bus.on('@@system:close:app', function (err) { });
    bus.on('@@system:set:title', function (err) { });
}
