import { __assign, __extends } from "tslib";
import React, { PureComponent } from 'react';
import FeeTypeSelect from '../feeType-tree-select';
import { getAvailableTree } from './fnTreeFilterAvailable';
import { cloneDeep } from 'lodash';
var StandardFeeTypeSelect = (function (_super) {
    __extends(StandardFeeTypeSelect, _super);
    function StandardFeeTypeSelect(props) {
        var _this = _super.call(this, props) || this;
        _this.selectData = undefined;
        _this.handleGetSelectData = function () {
            var dataIndex = _this.props.dataIndex;
            return { result: _this.selectData, dataIndex: dataIndex };
        };
        _this.handleOnChange = function (result) {
            var feeTypeMap = _this.props.feeTypeMap;
            _this.selectData = result.concat().map(function (line) {
                var _a = feeTypeMap[line], id = _a.id, name = _a.name;
                return { label: name, value: id };
            });
        };
        var cFeeTypes = cloneDeep(props.feeTypes);
        getAvailableTree(cFeeTypes, props.value);
        _this.state = { feeTypes: cFeeTypes };
        return _this;
    }
    StandardFeeTypeSelect.prototype.componentWillMount = function () {
        var _a = this.props, bus = _a.bus, dataIndex = _a.dataIndex;
        bus && bus.watch(dataIndex + ":get:select:data", this.handleGetSelectData);
    };
    StandardFeeTypeSelect.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.feeTypes !== nextProps.feeTypes) {
            var cFeeTypes = cloneDeep(nextProps.feeTypes);
            getAvailableTree(nextProps.feeTypes, nextProps.value);
            this.setState({ feeTypes: cFeeTypes });
        }
    };
    StandardFeeTypeSelect.prototype.componentWillUnmount = function () {
        var _a = this.props, bus = _a.bus, dataIndex = _a.dataIndex;
        bus && bus.un(dataIndex + ":get:select:data", this.handleGetSelectData);
    };
    StandardFeeTypeSelect.prototype.render = function () {
        var feeTypes = this.state.feeTypes;
        return (React.createElement(FeeTypeSelect, __assign({}, this.props, { feeTypes: feeTypes, checkedKeys: this.props.value, onChange: this.handleOnChange })));
    };
    return StandardFeeTypeSelect;
}(PureComponent));
export default StandardFeeTypeSelect;
