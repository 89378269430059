import React, { PureComponent } from 'react'
import { TreeSelect } from 'antd'
const TreeNode = TreeSelect.TreeNode
const SHOW_PARENT = TreeSelect.SHOW_PARENT
const SHOW_CHILD = TreeSelect.SHOW_CHILD
export default class EKBTreeSelect extends PureComponent {
  handleOnChange = (value, label, extra) => {
    const { onChange, multiple, value: propsValue, treeCheckStrictly } = this.props
    if (multiple) {
      let newValue = value || []
      if (extra && extra.checked === true) {
        if (treeCheckStrictly) {
          //解决搜索选中的时候清空之前已选择项的问题
          newValue = propsValue.concat({ value: extra.triggerValue })
        } else {
          newValue = propsValue.concat(extra.triggerValue)
        }
      }
      onChange && onChange(newValue)
      return
    }
    onChange && onChange(value)
  }

  renderTreeNode(data, onlyLeafCanBeSelected = false, canSelectInactive = false) {
    if (!data.length) {
      return []
    }
    const { canNotBeApportioned, canNotBeApportionedFields } = this.props
    const loop = arr =>
      arr.length > 0 &&
      arr.map(child => {
        const { id, name, active, children = [], label, code, deleted } = child
        const text = deleted
          ? i18n.get(`{__k0}（已删除）`, { __k0: nameStr })
          : i18n.get(`{__k0}（已停用）`, { __k0: nameStr })
        let nameStr = label || (code ? i18n.get(`{__k0}（{__k1}）`, { __k0: name, __k1: code }) : name)
        nameStr = active ? nameStr : text
        let __active = active
        if (onlyLeafCanBeSelected && children.length) {
          __active = false
        }
        if(canNotBeApportioned && canNotBeApportionedFields?.includes(id)){
          __active = false
        }
        return (
          <TreeNode key={id} name={nameStr} value={id} title={nameStr} disabled={canSelectInactive ? false : !__active}>
            {children && loop(children)}
          </TreeNode>
        )
      })
    return loop(data)
  }
  render() {
    const {
      value,
      treeData,
      placeholder,
      isShowParent,
      refKey,
      className,
      treeCheckStrictly,
      getNode,
      onlyLeafCanBeSelected,
      canSelectInactive,
      ...others
    } = this.props
    let config = { value }
    const showCheckedStrategy = isShowParent ? SHOW_PARENT : SHOW_CHILD
    return (
      <div id={refKey}>
        <TreeSelect
          ref={node => getNode && getNode(node)}
          className={className}
          searchPlaceholder={placeholder}
          placeholder={placeholder}
          showCheckedStrategy={showCheckedStrategy}
          treeCheckStrictly={treeCheckStrictly}
          {...others}
          {...config}
          onChange={this.handleOnChange}
          getPopupContainer={!refKey ? () => document.body : () => document.getElementById(refKey)}
        >
          {this.renderTreeNode(treeData, onlyLeafCanBeSelected, canSelectInactive)}
        </TreeSelect>
      </div>
    )
  }
}
