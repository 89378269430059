/*!
 * Copyright 2019 yangjunbao <yangjunbao@shimo.im>. All rights reserved.
 * @since 2019-07-09 12:02:42
 */
import { __awaiter, __generator } from "tslib";
import { app } from '@ekuaibao/whispered';
import { initSentryWithHistory } from '../initSentryWithHistory';
import { initDatafluxRum } from '../dataflux-rum';
import { addNcpcCode } from '@ekuaibao/lib/lib/addNcpcCode';
import { handleError, checkGroupAPPLogin, initUserInfo } from '../../lib/lib-util';
import { callback } from '../index.v0';
import { newHomeConfig } from '../newHomeConfig';
import { eKuaibaoSDK } from '@ekuaibao/sdk-bridge/sdk/ekuaibao';
import registerAPI from '../registerAPI';
import initializeTitle from '../initializeTitle';
import registerHandler from '../registerHandler';
import initPlatformInfo from '../initPlatformInfo';
import systemLimitLogout from '../systemLimitLogout';
import { fetchHandleError } from '../FetchConfig';
import { initRoute } from '../initRoute';
import { initColors } from '../initColors';
import setNetworkAdapter from '../setNetworkAdapter';
import qs from 'qs';
require('../../styles/app.less');
window.__PLANTFORM__ = 'APP';
initSentryWithHistory({ history: app.history });
initDatafluxRum();
window.GROUP_URL =
    process.env.NODE_ENV === 'production'
        ? 'https://group.ekuaibao.com/web/group.html'
        : 'http://team-movie1.dev.ekuaibao.cn/web/group.html';
checkGroupAPPLogin();
function startup() {
    var params = qs.parse(location.search.slice(1));
    if (params === null || params === void 0 ? void 0 : params.billentry) {
        window.__SUB_PLANNTFORM__ = 'BILLENTRY';
    }
    app.use({
        id: '@app',
        onready: function () {
            addNcpcCode();
        }
    });
    callback();
}
fetchHandleError(handleError);
export function bootstrap() {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    initColors();
                    initRoute();
                    return [4, initPlatformInfo()];
                case 1:
                    _b.sent();
                    if (!((_a = window.PLATFORMINFO) === null || _a === void 0 ? void 0 : _a.clusterURL)) return [3, 3];
                    return [4, initUserInfo(true)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    systemLimitLogout();
                    app.container.set('IDeviceType', 'DESKTOP');
                    app.container.set('ISMessageEntry', false);
                    app.sdk = app.container.get(eKuaibaoSDK);
                    registerHandler();
                    startup();
                    registerAPI();
                    _b.label = 4;
                case 4:
                    _b.trys.push([4, 6, , 8]);
                    return [4, app.sdk.initialize()];
                case 5:
                    _b.sent();
                    setNetworkAdapter();
                    return [3, 8];
                case 6:
                    e_1 = _b.sent();
                    return [4, newHomeConfig()];
                case 7:
                    _b.sent();
                    setNetworkAdapter();
                    return [2];
                case 8: return [4, newHomeConfig()];
                case 9:
                    _b.sent();
                    initializeTitle();
                    return [2];
            }
        });
    });
}
