/**
 *  Created by daiwenjuan on 2018/4/13 下午3:16.
 */
export function thousandBitSeparator(num) {
  if (num && num.toString().indexOf(',') > -1) {
    return num
  }
  return (
    num &&
    num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
      return $1 + ','
    })
  )
}
