import { withdraw } from './bills.action'

/**************************************************
 * Created by nanyuantingfeng on 10/07/2017 18:38.
 **************************************************/

export const ID = '@bills'

export default {
  ID,
  //old keys
  DEPARTMENT_INFO: `${ID}/DEPARTMENT_INFO`,
  REFRESH_GET_NEW_DATA: `${ID}/REFRESH_GET_NEW_DATA`,
  GET_DEPARTMENTS: `${ID}/GET_DEPARTMENTS`,
  FILTER_EXPENSE: `${ID}/FILTER_EXPENSE`,
  SAVE_PAYEE: `${ID}/SAVE_PAYEE`,
  DEL_PAYEE: `${ID}/DEL_PAYEE`,
  SAVE_FLOW: `${ID}/SAVE_FLOW`,
  DEL_FLOW: `${ID}/DEL_FLOW`,
  RETRACT_FLOW: `${ID}/RETRACT_FLOW`,
  CONFIRM_FLOW: `${ID}/CONFIRM_FLOW`,
  CONFIRM_FLOWS: `${ID}/CONFIRM_FLOWS`,
  COMMENT_FLOW: `${ID}/COMMENT_FLOW`,
  TRANSFER_FLOW: `${ID}/TRANSFER_FLOW`,
  GET_ACTIVES_FLOW: `${ID}/GET_ACTIVE_FLOW`,
  GET_ARCHIVES_FLOW: `${ID}/GET_ARCHIVES_FLOW`,
  GET_UPLOAD_TOKEN: `${ID}/GET_UPLOAD_TOKEN`,
  GET_ATTACHMENT_URL: `${ID}/GET_ATTACHMENT_URL`,
  GET_FEETYPE_BY_ID: `${ID}/GET_FEETYPE_BY_ID`,
  GET_LOAN_ACTIVES_FLOW: `${ID}/GET_LOAN_ACTIVES_FLOW`,
  CAL_PLAN_INSTANCE: `${ID}/CAL_PLAN_INSTANCE`,
  GET_WITHDRAW_FLOWID: `${ID}/GET_WITHDRAW_FLOWID`,
  WITHDRAW_SAVE: `${ID}/WITHDRAW_SAVE`,

  GET_LOAN_PACKAGE_LIST: `${ID}/GET_LOAN_PACKAGE_LIST`,
  GET_LOAN_PACKAGE_LIST_PAID: `${ID}/GET_LOAN_PACKAGE_LIST_PAID`,
  GET_LOAN_PACKAGE_DETAIL_BY_ID: `${ID}/GET_LOAN_PACKAGE_DETAIL_BY_ID`,
  GET_LOAN_LOGS: `${ID}/GET_LOAN_LOGS`,
  GET_LOAN_PACKAGE_LIST_BY_STAFFID: `${ID}/GET_LOAN_PACKAGE_LIST_BY_STAFFID`, //根据ownerId获取借款包列表
  GET_FLOW_INFO_BY_ID: `${ID}/GET_FLOW_INFO_BY_ID`,
  GET_BILL_INFO_BY_CODE: `${ID}/GET_BILL_INFO_BY_CODE`,
  GET_LOAN_FLOW_INFO: `${ID}/GET_LOAN_FLOW_INFO`,
  GET_LOAN_BALANCE: `${ID}/GET_LOAN_BALANCE`,

  APPLY_REPAYMENT: `${ID}/APPLY_REPAYMENT`,
  GET_REPAYMENTINFO: `${ID}/GET_REPAYMENTINFO`,
  HIDE_REPAYMENTINFO: `${ID}/HIDE_REPAYMENTINFO`,

  GET_REPAY_APPLY_BY_STATUS: `${ID}/GET_REPAY_APPLY_BY_STATUS`,
  GET_BANK_LIST: `${ID}/GET_BANK_LIST`,
  GET_BUDGET_LIST: `${ID}/GET_BUDGET_LIST`,
  GET_BUDGET_DETAIL: `${ID}/GET_BUDGET_DETAIL`,
  GET_FEETYPE_TEMPLATE_BY_ID: `${ID}/GET_FEETYPE_TEMPLATE_BY_ID`,
  GET_FEETYPE_CALCULATE_FIELDS: `${ID}/GET_FEETYPE_CALCULATE_FIELDS`,

  GET_CITY_BY_KEY_WORD: `${ID}/GET_CITY_BY_KEY_WORD`,
  // SEARCH_MY_BILL_LIST: `${ID}/SEARCH_MY_BILL_LIST`,
  // SEARCH_MY_BILL_LIST_NEW: `${ID}/SEARCH_MY_BILL_LIST_NEW`,
  SEARCH_MY_BILL_PAID_LIST: `${ID}/SEARCH_MY_BILL_PAID_LIST`,
  SEARCH_MY_PRINTREMIND_COUNT: `${ID}/SEARCH_MY_BILL_COUNT`,

  GET_EXPENSE_LINK: `${ID}/GET_EXPENSE_LINK`,
  SEARCH_MY_FINISHED_BILL_LIST: `${ID}/SEARCH_MY_FINISHED_BILL_LIST`,
  GET_APPLY_EVENT: `${ID}/GET_APPLY_EVENT`,
  GET_APPLY_EVENT_LIST: `${ID}/GET_APPLY_EVENT_LIST`,
  GET_REQUISITION_DATA_SOURCE: `${ID}/GET_REQUISITION_DATA_SOURCE`,
  GET_APPLY_BY_EXPENSE: `${ID}/GET_APPLY_BY_EXPENSE`,
  CHANGE_APPLY_EVENT: `${ID}/CHANGE_APPLY_EVENT`,
  GET_AUTO_CALCULATIO_RESULT: `${ID}/GET_AUTO_CALCULATIO_RESULT`,
  GET_CALCULATE_RECORD_LINK: `${ID}/GET_CALCULATE_RECORD_LINK`,

  GET_LOAN_PACKAGE_LIST_BY_ID: `${ID}/GET_LOAN_PACKAGE_LIST_BY_ID`,
  GET_OFFICIAL_CARD: `${ID}/GET_OFFICIAL_CARD`,

  BILL_REMIND: `${ID}/BILL_REMIND`,
  GET_VOUCHER_DATA: `${ID}/GET_VOUCHER_DATA`,

  GET_THIRD_PARTY_ORDER_DATA: `${ID}/GET_THIRD_PARTY_ORDER_DATA`,
  GET_RELEVENT_MEMBERS: `${ID}/GET_RELEVENT_MEMBERS`,
  GET_CARBON_COPY_FLOW_INFO_BY_ID: `${ID}/GET_CARBON_COPY_FLOW_INFO_BY_ID`,
  GET_TRIPS_TEMPLATE: `${ID}/GET_TRIPS_TEMPLATE`,
  GET_DATA_LINK_EDIT: `${ID}/GET_DATA_LINK_EDIT`,
  GET_DATA_LINK_EDIT_ITEMS: `${ID}/GET_DATA_LINK_EDIT_ITMES`,

  GET_INPUT_INVOICE_DATA: `${ID}/GET_INPUT_INVOICE_DATA`,
  GET_INPUT_INVOICE_CAPTCHA: `${ID}/GET_INPUT_INVOICE_CAPTCHA`,
  GET_SPECIFICATIONS: `${ID}/GET_SPECIFICATIONS`,
  GET_DIMENSION_BY_ID: `${ID}/GET_DIMENSION_BY_ID`,

  GET_DATALINK_CONFIGRULE: `${ID}/GET_DATALINK_CONFIGRULE`,
  GET_DATALINK_ENTITY: `${ID}/GET_DATALINK_ENTITY`,
  SHARE_APPLY: `${ID}/SHARE_APPLY`,
  TRANSFER_APPLY: `${ID}/TRANSFER_APPLY`,
  GET_DATALINKFLOW_LIST: `${ID}/GET_DATALINKFLOW_LIST`,
  UPDATE_DATALINK_INSTANCE: `${ID}/UPDATE_DATALINK_INSTANCE`,
  UPDATE_DETAIL_DATALINK_INSTANCE: `${ID}/UPDATE_DETAIL_DATALINK_INSTANCE`,
  SAVE_REPAY_INFO: `${ID}/SAVE_REPAY_INFO`,
  GENERATE_CONSUME_DETAILS: `${ID}/GENERATE_CONSUME_DETAILS`,
  GET_INVOICE_CORPORATION: `${ID}/GET_INVOICE_CORPORATION`,
  SAVE_INVOICE_WAIT: `${ID}/SAVE_INVOICE_WAIT`,
  GET_INVOICE_DETAILALL_BYID: `${ID}/GET_INVOICE_DETAILALL_BYID`,
  GET_APPLY_EVENT_DETAIL_LIST: `${ID}/GET_APPLY_EVENT_DETAIL_LIST`,
  GET_APPLY_BILL_DETAIL: `${ID}/GET_APPLY_BILL_DETAIL`,
  GET_FLOW_INFO_BY_CODE: `${ID}/GET_FLOW_INFO_BY_CODE`,
  GET_FLOW_RISK_WARNING: `${ID}/GET_FLOW_RISK_WARNING`,
  POST_DISABLE_INVOICE:`${ID}/POST_DISABLE_INVOICE`,
  POST_MARK_INVOICE:`${ID}/POST_MARK_INVOICE`,
  GET_DISABLE_STATE_BY_INVOICEID:'${ID}/GET_DISABLE_STATE_BY_INVOICEID',
  POST_FLOW_RISK_WARNING: `${ID}/POST_FLOW_RISK_WARNING`,
  ISSHOW_EBOT: `${ID}/ISSHOW_EBOT`,
  GET_CURRENT_FLOW_STATE: `${ID}/GET_CURRENT_FLOW_STATE`,
  SAVE_OCR_CARD_INFO: `${ID}/SAVE_OCR_CARD_INFO`,
  GET_INVOICE_IMG_BYID: `${ID}/GET_INVOICE_IMG_BYID`,
  GET_OCR_LIST: `${ID}/GET_OCR_LIST`,
  DELETE_INVOICE_WAIT: `${ID}/DELETE_INVOICE_WAIT`,
  GET_INVOICE_INFO_BY_IDS: `${ID}/GET_INVOICE_INFO_BY_IDS`,
  GET_DATALINK: `${ID}/GET_DATALINK`,
  GET_CYR_FZR_LIST: `${ID}/GET_CYR_FZR_LIST`,
  GET_LEDGER_AND_PLAN_BY_ID: `${ID}/GET_LEDGER_AND_PLAN_BY_ID`,
  GET_LEDGER_RELATION_LIST: `${ID}/GET_LEDGER_RELATION_LIST`,
  GET_INVOICE_ISDEDUCTIBLE: `${ID}/GET_INVOICE_ISDEDUCTIBLE`,

  GET_DATALLINK_TEMPLATE_BYID: `${ID}/GET_DATALLINK_TEMPLATE_BYID`,
  GET_RECORDLINK: `${ID}/GET_RECORDLINK`,

  SAVE_MULTIPLE_PAYEED: `${ID}/SAVE_MULTIPLE_PAYEED`,
  GET_LOAN_PACKAGE_BY_FLOWID: `${ID}/GET_LOAN_PACKAGE_BY_FLOWID`,
  PUT_TRANSFER_LOAN_PACKAGE: `${ID}/PUT_TRANSFER_LOAN_PACKAGE`,
  GET_SHOW_BUDGET_TAB: `${ID}/GET_SHOW_BUDGET_TAB`,

  GET_CALCULATE_FIELD: `${ID}/GET_CALCULATE_FIELD`,
  GET_FEETYPE_LIST_BY_FORMDATA: `${ID}/GET_FEETYPE_LIST_BY_FORMDATA`,

  GET_STAFF_BY_ID: `${ID}/GET_STAFF_BY_ID`,

  EXPORT_APPORTION: `${ID}/EXPORT_APPORTION`,
  EXPORT_PLANPAY: `${ID}/EXPORT_PLANPAY`,
  GET_DATALLINK_TEMPLATE_BYIDS: `${ID}/GET_DATALLINK_TEMPLATE_BYIDS`,
  VALIDATE_ERROR: `${ID}/VALIDATE_ERROR`,
  GET_SUBMITTERLIST: `${ID}/GET_SUBMITTERLIST`,
  CHECK_LOAN_PACKAGE_EXIST: `${ID}/CHECK_LOAN_PACKAGE_EXIST`,
  GET_SHARE_LIST_API: `${ID}/GET_SHARE_LIST_API`,
  GET_ASSIGNMENT_RULE_BY_ID: `${ID}/GET_ASSIGNMENT_RULE_BY_ID`,
  NULLIFY_FLOW: `${ID}/NULLIFY_FLOW`,
  GET_NULLIFY_RULE_BYSPECID: `${ID}/GET_NULLIFY_RULE_BYSPECID`,
  GET_NULLIFY_RULE_BY_FLOWID: `${ID}/GET_NULLIFY_RULE_BY_FLOWID`,
  SAVE_FEETYPE_VISIBLE_LIST: `${ID}/SAVE_FEETYPE_VISIBLE_LIST`,
  MODIFY_FEEDETAIL_TAXINFO: `${ID}/MODIFY_FEEDETAIL_TAXINFO`,
  GET_FIELDS_GROUP_DATA: `${ID}/GET_FIELDS_GROUP_DATA`,
  SAVE_FIELDS_GROUP_DATA: `${ID}/SAVE_FIELDS_GROUP_DATA`,

  GET_ACTIVE_CREDIT_RULES: `${ID}/GET_ACTIVE_CREDIT_RULES`,
  GET_BILL_NOTES: `${ID}/GET_BILL_NOTES`,
  POST_ADD_NOTE: `${ID}/POST_ADD_NOTE`,
  SEARCH_NOTE_FORM_HISTORY: `${ID}/SEARCH_NOTE_FORM_HISTORY`,
  CHANGE_STATUS_OF_SHOWBILLNOTESINHISTORY: `${ID}/CHANGE_STATUS_OF_SHOWBILLNOTESINHISTORY`,
  GET_MY_CREDIT_POINT: `${ID}/GET_MY_CREDIT_POINT`,
  GET_ALIPAY_INVOICE_LIST: `${ID}/GET_ALIPAY_INVOICE_LIST`,
  GET_ALIPAY_INVOICE_DETAIL: `${ID}/GET_ALIPAY_INVOICE_DETAIL`,
  GET_RETRY_CHECKER_INVOICE: `${ID}/GET_RETRY_CHECKER_INVOICE`,
  IS_OPEN_TEMPLATE_MODAL: `${ID}/IS_OPEN_TEMPLATE_MODAL`,
  SHARE_LOAN: `${ID}/SHARE_LOAN`,

  SAVE_WRITTENOFF_SUMMARY: `${ID}/SAVE_WRITTENOFF_SUMMARY`,
  CLEAR_WRITTENOFF_SUMMARY: `${ID}/CLEAR_WRITTENOFF_SUMMARY`,
  GET_CURRENT_BACKLOG_BY_ID: `${ID}/GET_CURRENT_BACKLOG_BY_ID`,

  GET_TRAVEL_BACK_INFO: `${ID}/GET_TRAVEL_BACK_INFO`,
  ACTIVATE_FLOW: `${ID}/ACTIVATE_FLOW`,
  UPDATE_DIMENTION_CURRECY: `${ID}/UPDATE_DIMENTION_CURRECY`,
  CHECK_RETURN_CITY: `${ID}/CHECK_RETURN_CITY`,
  GET_PULLTRAVELORDERBYSTAFF: `${ID}/GET_PULLTRAVELORDERBYSTAFF`,
  SAVE_AIFAPIAO_AUTHORIZE: `${ID}/SAVE_AIFAPIAO_AUTHORIZE`,
  HANDLE_INVOICE_IMPORT: `${ID}/HANDLE_INVOICE_IMPORT`,
  // 审批撤回
  FLOW_WITHDRAW: `${ID}/FLOW_WITHDRAW`,
  LOAN_RULE_CONFIG_LIST: `${ID}/LOAN_RULE_CONFIG_LIST`,
  GET_ASSOCIATION_GROUP_DATA: `${ID}/GET_ASSOCIATION_GROUP_DATA`,

  AUTO_GENERATION_FEE_DETAIL_RULES: `${ID}/AUTO_GENERATION_FEE_DETAIL_RULES`,
  AUTO_GENERATION_FEE_DETAIL: `${ID}/AUTO_GENERATION_FEE_DETAIL`,
  GET_SURPLUS_RESULT: `${ID}/GET_SURPLUS_RESULT`,
  GET_TRAVEL_RESULT: `${ID}/GET_TRAVEL_RESULT`,
  SET_PRINT_PREVIEW_URL: `${ID}/SET_PRINT_PREVIEW_URL`,
  GET_LAYOUT_CONFIG: `${ID}/GET_LAYOUT_CONFIG`,
  LOAN_RULE_CONFIG_LIST: `${ID}/LOAN_RULE_CONFIG_LIST`,
  GET_INVOICE_REASON: `${ID}/GET_INVOICE_REASON`,
  GET_OWNER_REMAIN: `${ID}/GET_OWNER_REMAIN`,
  SEARCH_EXTENSION_CENTER_CONFIG: `${ID}/SEARCH_EXTENSION_CENTER_CONFIG`,
  GET_FEE_TYPE_CHANGE: `${ID}/GET_FEE_TYPE_CHANGE`,
  GET_DEFAULT_PAYEE: `${ID}/GET_DEFAULT_PAYEE`,
  GET_DELEGATE_CONFIG: `${ID}/GET_DELEGATE_CONFIG`,
  SET_SUBMITTER_DATA: `${ID}/SET_SUBMITTER_DATA`,
  GET_CAlCULATE_CORP_ID_WHITELIST: `${ID}/GET_CAlCULATE_CORP_ID_WHITELIST`,
  GET_LINK_REQUISITION_COPRS: `${ID}/GET_LINK_REQUISITION_COPRS`,
  GET_CURRENCY_RATES_BYID: `${ID}/AUTO_GENERATION_FEE_DETAIL_RULES`,
  GET_CURRENCY_CONFIG: `${ID}/AUTO_GENERATION_FEE_DETAIL_RULES`,
  GET_ACTIVE_ACCOUNT_LIST: `${ID}/AUTO_GENERATION_FEE_DETAIL_RULES`,
  GET_IF_DOWNLOAD_NEED_FILENAME: `${ID}/GET_IF_DOWNLOAD_NEED_FILENAME`
}
