/**************************************************
 * Created by zhaohuabing on 2018/9/28 下午4:47.
 **************************************************/
import React, { Fragment } from 'react'
import { Popover } from 'antd'
import classnames from 'classnames'
import styles from './ExceedStandardRiskForField.module.less'
import { getV } from '@ekuaibao/lib/lib/help'

const renderItem = (item) => {
  const { messages = [], messagesV2 = [], prices = [] } = item
  if(prices?.length){
    return (
      <div className="risk-content-detail">
        {
          prices.map((v, idx) => (
            <div className="risk-content-detail-item" key={idx}>
              {v.name }
              <div className={classnames('vertical-left-left fs-12 fw-b warning')}>
                <span>{i18n.get('预算余额：')}{v.symbol}{v.usedBudget}</span>
                <span>{i18n.get('超额比例：')}{ v.percent }</span>
              </div>
            </div>
          ))
        }
      </div>
    )
  } else if (messagesV2.length > 0) {
    // messageV2 是携带发票风险的组合风险数据
    return messagesV2.map((message, idx) => (
      <div className="risk-content-detail-v2" key={message.invoiceMsg}>
        <div className="flex-container">
          <div className="risk-item-label">
            {i18n.get('风险信息')}:
          </div>
          <div className="flex-1 risk-item-info">
            {message.invoiceMsg}
          </div>
        </div>
        {!!message.invoiceRiskExplainContent && (
          <div className="flex-container">
            <div className="risk-item-label">{i18n.get('原因')}:</div>
            <div className="flex-1 risk-item-info">
              {message.invoiceRiskExplainContent}
            </div>
          </div>
        )}
        {!!message.relatedFlows?.length && (
          <div className="flex-container">
            <div className="risk-item-label">{i18n.get('相关单据')}:</div>
            <div className="flex-1 risk-item-info">
              {message.relatedFlows
                .map(item => `${item.flowCode}${item.invoiceNum ? `(${item.invoiceNum})` : ''}`)
                .join('，')}
            </div>
          </div>
        )}
      </div>
    ))
  } else {
    return (
      <div className="risk-content-detail">
        {
          messages.map((v, idx) => (
            <div className="risk-content-detail-item" key={idx}>
              { messages.length === 1 ? v : `${(idx + 1)}、` + v }
            </div>
          ))
        }
      </div>
    )
  }
}


export function renderPopoverContent(external) {
  if (!external) return

  return (
    <Fragment>
      <div className={styles['risk-warning-header']}>
        <div className="header-content">{i18n.get('风险详情')}</div>
      </div>
      <div className={styles['risk-warning-content']}>
        {Array.isArray(external) &&
          external.map((item, idx) => {
            const { controlName, controlVersion } = item
            return (
              <Fragment key={idx}>
                <div className="risk-content-title">
                  {controlName && <span className="risk-content-title-name"> {i18n.get(controlName)}</span>}
                  {controlVersion > 0 && (
                    <span className="risk-content-title-version">{`${i18n.get('依据版本')}${controlVersion}`}</span>
                  )}
                </div>
                {renderItem(item)}
              </Fragment>
            )
          })}
      </div>
    </Fragment>
  )
}

export default function RiskWarningForField(props) {
  const {
    children,
    external,
    isForbid = false,
    isEdit,
    noColon = false,
    style,
    trigger = 'hover',
    placement = 'leftBottom',
    label,
    stopPropagation = true,
    popupContainer
  } = props
  const [className, url] = isForbid ? ['error', '#EDico-plaint-circle'] : ['warning', '#EDico-plaint-circle']
  const explanationName = 'explanation ' + className
  const nodeId = 'risk-warning-for-field' + Math.random()
  const getContainer = () => {
    const current = getV(popupContainer, 'current', '')
    return !!current ? current : document.getElementById(nodeId)
  }
  return (
    <span
      className={classnames(styles['risk-warning-for-field-wrapper'], 'text-nowrap-ellipsis')}
      id={nodeId}
      onClick={e => {
        if (stopPropagation) {
          e.stopPropagation()
          e.preventDefault()
          e.nativeEvent.stopImmediatePropagation()
        }
      }}
    >
      <span
        className={classnames({
          'flex-style': true,
          'wrapper-error': external && isForbid,
          'wrapper-warning': external && !isForbid,
          edit: isEdit
        })}
        style={style}
      >
        <span className="flex">{children}</span>
        {external && (
          <Fragment>
            <Popover
              trigger={trigger}
              arrowPointAtCenter
              autoAdjustOverflow
              placement={placement}
              content={renderPopoverContent(external)}
              getPopupContainer={getContainer}
            >
              <div className="risk-icon">
                <span className="risk-hidden">{i18n.get('划过')}</span>
                <svg className={`icon ${className}`} aria-hidden="true">
                  <use xlinkHref={url} />
                </svg>
              </div>
            </Popover>
          </Fragment>
        )}
        <span style={{ padding: '0 4px' }}>{isEdit || noColon ? '' : i18n.get('：')}</span>
      </span>
    </span>
  )
}
