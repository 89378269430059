import { __assign, __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/browser';
import { reactRouterV5Instrumentation } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import { getV } from '@ekuaibao/lib/lib/help';
export function initSentryWithHistory(params) {
    var _this = this;
    console.log('process.env: ', process.env.NODE_ENV, process.env.RELEASE_VERSION);
    var history = params === null || params === void 0 ? void 0 : params.history;
    var hostname = window.location.hostname;
    var envMap = {
        'app.ekuaibao.com': i18n.get('原生'),
        'dd2.ekuaibao.com': i18n.get('钉钉'),
        'ekuaibao2297.eapps.dingtalkcloud.com': i18n.get('钉钉'),
        'wx2.ekuaibao.com': i18n.get('企业微信'),
        'kdcloud2.ekuaibao.com': i18n.get('云之家'),
        'ddenterprise.ekuaibao.com': i18n.get('钉钉自建'),
        'staging.ekuaibao.com': i18n.get('预发布'),
        '460mix.ekuaibao.net': '460mix',
        '460.ekuaibao.net': '460',
    };
    var options = {
        dsn: 'https://3315ed5ce3fa4b3e9fd227e9b7813974@sentry.ekuaibao.com/3',
        release: process.env.RELEASE_VERSION || '',
        environment: envMap[hostname] ? envMap[hostname] : i18n.get('其他'),
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: typeof history === 'object' ? reactRouterV5Instrumentation(history) : undefined,
                beforeNavigate: function (context) {
                    return __assign(__assign({}, context), { name: "" + location.origin + location.pathname + location.hash });
                }
            }),
            new OfflineIntegration()
        ],
        sampleRate: 1.0,
        tracesSampleRate: 1,
        beforeSend: function (event, hint) { return __awaiter(_this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = getV(event, 'request.url', '');
                if (url.includes('debugger')) {
                    return [2, null];
                }
                if (event.tags === undefined) {
                    event.tags = {};
                }
                event.tags.hashpath = location.hash;
                return [2, event];
            });
        }); },
    };
    if (process.env.NODE_ENV !== 'development') {
        Sentry.init(options);
        window.Sentry = Sentry;
    }
}
