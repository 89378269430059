import React, { Component } from 'react'
import InvoiceCard from './InvoiceCard/InvoiceCard'

export default class InvoiceFormCard extends Component {
  render() {
    const {
      invoice,
      bus,
      checkout,
      permissions,
      Delete,
      submitterId,
      isFrom,
      hiddenHeader,
      className = '',
      canSeeLink,
      showAllFeeType
    } = this.props
    return (
      <InvoiceCard
        className={className}
        invoice={invoice}
        isFrom={isFrom}
        bus={bus}
        checkout={checkout}
        Delete={Delete}
        permissions={permissions}
        submitterId={submitterId}
        hiddenHeader={hiddenHeader}
        canSeeLink={canSeeLink}
        showAllFeeType={showAllFeeType}
      />
    )
  }
}
