/**
 * Created by LiuXinai on 19/06/2017.
 */

// @i18n-ignore-all
export default [
  {
    id: '@reportsadv',
    path: '/reports/adv/:id',
    ref: '/',
    onload: () => import('./bi-view')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'reportsadv',
        pId: 'reports-new',
        powers: ['SmartReport'],
        permissions: ['SMART_REPORT'],
        weight: 1,
        label: '智能报表',
        actived: true,
        href: '/reportsadv',
        icon: 'myreport'
      }
    ]
  }
]
