/**************************************************
 * Created by nanyuantingfeng on 14/07/2017 15:45.
 **************************************************/
import styles from './FakeInput.module.less'
import React from 'react'
import classNames from 'classnames'
export default function FakeInput(props) {
  const { className = '', children, onClick, disabled = false, ellipsis = false, placeholder = '', onRemove, allowClear } = props
  return (
    <div
      className={classNames(`${className}`,
        { [styles.fake_input_wrapper_disabled]: disabled },
        { [styles.fake_input_wrapper]: !disabled },
        { [styles.ellipsis]: ellipsis })}
      onClick={disabled ? void 0 : onClick}
      disabled={disabled}
      placeholder={placeholder}
    >
      {children}
      {allowClear && children && <span className={styles.fake_input_wrapper_removed} onClick={onRemove}></span>}
    </div>
  )
}

FakeInput.defaultProps = {
  onClick: () => { }
}

export function TextArea(props) {
  return <FakeInput {...props} className={styles.textArea} />
}
