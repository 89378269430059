import { __assign, __awaiter, __generator } from "tslib";
import { app as api } from '@ekuaibao/whispered';
import { getAllAutoCalResultForBillDiff } from '../../plugins/bills/util/autoCalculate';
export function fnHideFields(hide, hideVisibility) {
    var _a, _b, _c;
    if (hide) {
        var _d = hideVisibility || { departments: [], roles: [], staffs: [] }, departments = _d.departments, roles = _d.roles, staffs = _d.staffs;
        if ((departments === null || departments === void 0 ? void 0 : departments.length) || (roles === null || roles === void 0 ? void 0 : roles.length) || (staffs === null || staffs === void 0 ? void 0 : staffs.length)) {
            var userInfo = api.getState('@common.userinfo').staff || {};
            var departmentIds = ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.departments) === null || _a === void 0 ? void 0 : _a.map(function (line) { return line.id; })) || [];
            var rolesIds = ((_c = (_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.roles) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.map(function (line) { return line.roleDefId; })) || [];
            return staffs.indexOf(userInfo.id) >= 0 || isIncludes(roles, rolesIds) || isIncludes(departments, departmentIds);
        }
        return false;
    }
    else {
        return !hide;
    }
}
export function fnHideFieldsNote(hideVisibility) {
    var _a, _b, _c;
    var _d = hideVisibility || { departments: [], roles: [], staffs: [] }, departments = _d.departments, roles = _d.roles, staffs = _d.staffs;
    if ((departments === null || departments === void 0 ? void 0 : departments.length) || (roles === null || roles === void 0 ? void 0 : roles.length) || (staffs === null || staffs === void 0 ? void 0 : staffs.length)) {
        var userInfo = api.getState('@common.userinfo').staff || {};
        var departmentIds = ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.departments) === null || _a === void 0 ? void 0 : _a.map(function (line) { return line.id; })) || [];
        var rolesIds = ((_c = (_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.roles) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.map(function (line) { return line.roleDefId; })) || [];
        return staffs.indexOf(userInfo.id) >= 0 || isIncludes(roles, rolesIds) || isIncludes(departments, departmentIds);
    }
    return false;
}
function isIncludes(departments, ids) {
    var flag = false;
    for (var i = 0; i < ids.length; i++) {
        if (departments.indexOf(ids[i]) >= 0) {
            flag = true;
            break;
        }
    }
    return flag;
}
export function fnFlowHideFields(plan) {
    var _a, _b;
    var nodes = (_a = plan === null || plan === void 0 ? void 0 : plan.nodes) !== null && _a !== void 0 ? _a : [];
    var curNode = (nodes === null || nodes === void 0 ? void 0 : nodes.length) && nodes.find(function (v) { return (v === null || v === void 0 ? void 0 : v.id) === (plan === null || plan === void 0 ? void 0 : plan.taskId); });
    return (_b = curNode === null || curNode === void 0 ? void 0 : curNode.hiddenFileds) !== null && _b !== void 0 ? _b : [];
}
export function getApportionHideDetailCount(billData, selectedData) {
    var _this = this;
    var fncalResult = function () { return __awaiter(_this, void 0, void 0, function () {
        var data, apportionItem_1, result, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    if (!selectedData || !selectedData.length)
                        return [2, false];
                    return [4, getAllAutoCalResultForBillDiff(__assign(__assign({}, billData), { details: selectedData }))];
                case 1:
                    data = _c.sent();
                    apportionItem_1 = (_a = data === null || data === void 0 ? void 0 : data.items) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !!item.detailId && item.onField === 'apportions' && item.attribute; });
                    result = selectedData.map(function (detail) {
                        var _a, _b;
                        var apportionComponet = ((_b = (_a = detail === null || detail === void 0 ? void 0 : detail.specificationId) === null || _a === void 0 ? void 0 : _a.components) === null || _b === void 0 ? void 0 : _b.find(function (comp) { return comp.field === 'apportions'; })) || {};
                        if (!apportionComponet.hide)
                            return;
                        var hideAttr = apportionItem_1.find(function (item) { var _a; return item.detailId === ((_a = detail === null || detail === void 0 ? void 0 : detail.feeTypeForm) === null || _a === void 0 ? void 0 : _a.detailId) && item.attribute === 'hide'; });
                        if (hideAttr && hideAttr.result === 'false') {
                            return;
                        }
                        if (apportionComponet.hideVisibility && fnHideFieldsNote(apportionComponet.hideVisibility)) {
                            return;
                        }
                        if (apportionComponet.open) {
                            var openItem = apportionItem_1.find(function (item) { var _a; return item.detailId === ((_a = detail === null || detail === void 0 ? void 0 : detail.feeTypeForm) === null || _a === void 0 ? void 0 : _a.detailId) && item.attribute === 'open'; });
                            if (!openItem || openItem.result === 'true') {
                                return;
                            }
                        }
                        return detail;
                    });
                    return [2, (_b = result.filter(function (i) { return i; })) === null || _b === void 0 ? void 0 : _b.length];
                case 2:
                    error_1 = _c.sent();
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    return fncalResult();
}
