/**
 *  Created by panwei on 2018/9/25 下午4:40.
 */
import React, { PureComponent } from 'react'
import { Select, Spin, Checkbox } from 'antd'
const { OptGroup, Option } = Select
import { debounce, uniqBy } from 'lodash'
import { app as api } from '@ekuaibao/whispered'
import { Fetch } from '@ekuaibao/fetch'
import { isDisable } from '../../components/utils/fnDisableComponent'
import styles from './CityComponent.module.less'

export default class CityComponent extends PureComponent {
  constructor(props) {
    super(props)
    const {
      field: { originalData }
    } = props
    this.lastFetchId = 0
    this.fetchCity = debounce(this.fetchCity, 800)
    this.state = {
      data: originalData || [],
      fetching: false,
      emptyText: '',
      value: {},
      checked: false
    }
  }

  fetchCity = value => {
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ fetching: true })
    this.searchValue = value
    api
      .invokeService('@expense-standard:get:standard:city', { name: value, isSelectDistrict: this.state.checked })
      .then(result => {
        if (fetchId !== this.lastFetchId) {
          return
        } // for fetch callback order
        const { value } = result
        const mvalue = this.fnMergeDataSource(value)
        const { city, cityGrade } = mvalue
        const emptyText = !city.length && !cityGrade.length ? i18n.get('没有查询到城市') : ''
        this.setState({ data: mvalue, fetching: false, emptyText })
      })
      .catch(e => {
        this.setState({ fetching: false })
      })
  }
  fnMergeDataSource = value => {
    const { getSelectData } = this.props
    if (!getSelectData) return value
    const { city, cityGrade } = getSelectData()
    if (!city && !cityGrade) return value
    const mCity = city.concat(value.city)
    const mCityGrade = cityGrade.concat(value.cityGrade)
    const c = uniqBy(mCity, 'id')
    const cG = uniqBy(mCityGrade, 'id')
    return { city: c, cityGrade: cG }
  }

  fnParseValue2Show = value => {
    const { field } = this.props
    const { multiple } = field
    let result
    if (multiple) {
      try {
        result =
          value &&
          value.map(line => {
            if (line.type) {
              return {
                key: JSON.stringify({ type: line.type, id: line.key }),
                label: line.label
              }
            }
            return line
          })
      } catch (err) {}
    } else {
      try {
        result = value && value[0]
      } catch (err) {}
    }

    return result
  }
  isJSON = str => {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }
  fnParseValue2Save(value) {
    const { field } = this.props
    const { multiple } = field
    let result
    if (multiple) {
      try {
        if (!value.length) {
          result = undefined
        } else {
          result = value.map(v => {
            const keyObj = this.isJSON(v.key) ? JSON.parse(v.key) : v.key
            const label =
              typeof v.label === 'object' ? this.fnFormatCityLabel(v.label.key) : this.fnFormatCityLabel(v.label)
            let key = v.key
            let type = v.type
            if (typeof keyObj === 'object') {
              key = keyObj.id
              type = keyObj.type
            }
            return { key, type, label }
          })
        }
      } catch (err) {}
    } else {
      try {
        const keyObj = this.isJSON(value.key) ? JSON.parse(value.key) : value.key
        const label =
          typeof value.label === 'object'
            ? this.fnFormatCityLabel(value.label.key)
            : this.fnFormatCityLabel(value.label)
        let key = value.key
        let type = value.type
        if (typeof keyObj === 'object') {
          key = keyObj.id
          type = keyObj.type
        }
        result = [{ key, type, label }]
      } catch (err) {}
    }
    return result
  }

  handleChange = value => {
    const { data } = this.state
    const { onChange, getExpenseStandardItemsLength } = this.props
    const result = this.fnParseValue2Save(value)
    onChange && onChange(result, data)
    getExpenseStandardItemsLength && setTimeout(getExpenseStandardItemsLength, 0)
  }

  fnFormatCityLabel(label) {
    return label.replace(/^中国,\s*/, '').replace(/,\s*/g, '/')
  }
  onChange = e => {
    this.setState({
      checked: e.target.checked
    })
    this.searchValue && this.fetchCity(this.searchValue)
  }
  renderSwitch = (label, index) => {
    if (index === 0) {
      return (
        <div className={styles['box-switch']}>
          {label}{' '}
          <Checkbox checked={this.state.checked} onChange={this.onChange} className="right">
            {i18n.get('选择区县')}
          </Checkbox>
        </div>
      )
    } else {
      return label
    }
  }
  renderOptions(d, index) {
    // @ts-ignore
    const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage
    if (lang === 'en-US') {
      return (
        <OptGroup label={this.renderSwitch(d.label, index)} key={d.label}>
          {d.children.map(item => {
            return <Option key={item.id}>{d.isGroup ? item.nameSpell : item.enName}</Option>
          })}
        </OptGroup>
      )
    }

    return (
      <OptGroup label={this.renderSwitch(d.label, index)} key={d.label}>
        {d.children.map(item => {
          return (
            <Option key={d.isGroup ? JSON.stringify({ type: 'cityGrade', id: item.id }) : item.id}>
              {d.isGroup ? (
                <span key={item.name}>{item.name}</span>
              ) : (
                <span key={item.fullName}>
                  <span>{item.name}</span>
                  <span className="ml-5 color-gray">{item.fullName}</span>
                </span>
              )}
            </Option>
          )
        })}
      </OptGroup>
    )
  }

  fnParseDataToShow = data => {
    const result = []
    // @ts-ignore
    const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage
    const { cityGrade, city } = data
    if (cityGrade && cityGrade.length > 0) {
      result.push({ isGroup: true, label: lang === 'en-US' ? 'CITYGROUP' : i18n.get('城市组'), children: cityGrade })
    }
    if (city && city.length > 0) {
      result.push({ label: lang === 'en-US' ? 'CITY' : i18n.get('城市'), children: city })
    }
    return result
  }

  render() {
    // @ts-ignore
    const { field, value } = this.props
    const { multiple, placeholder, Component, disabledPopupContainer, dropdownClassName } = field
    // @ts-ignore
    const { fetching, data, emptyText } = this.state
    const value2show = this.fnParseValue2Show(value) || []
    const randomNumber = Math.ceil(Math.random() * 10000)
    const cityClassId = `ekb-city-${randomNumber}`
    const disable = isDisable(this.props)
    const mData = this.fnParseDataToShow(data)
    return (
      <div id={cityClassId} style={{ position: 'relative' }}>
        <Select
          defaultValue={value}
          dropdownClassName={dropdownClassName}
          className={styles['ekb-city']}
          mode={multiple ? 'multiple' : 'default'}
          showSearch={!multiple}
          labelInValue
          allowClear
          filterOption={false}
          placeholder={placeholder}
          notFoundContent={fetching ? <Spin size="small" /> : emptyText}
          onSearch={this.fetchCity}
          onChange={this.handleChange}
          style={{ width: '100%' }}
          disabled={disable}
          getPopupContainer={disabledPopupContainer ? () => document.body : () => document.getElementById(cityClassId)}
        >
          {mData.map((d, index) => this.renderOptions(d, index))}
        </Select>
        {Component && value2show.length > 0 && <Component totalCount={value2show.length} />}
      </div>
    )
  }
}
