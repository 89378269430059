import { cloneDeep } from 'lodash'
import {
  THIRDPLATFORM_ENTITY_DISABLESTRATEGY,
  THIRDPLATFORM_ENTITY_FIELDMARK,
  THIRDPLATFORM_ENTITY_SCOPE,
  THIRDPLATFORM_ENTITY_TYPE
} from '@ekuaibao/lib/lib/enums'
import { getFieldType } from './getFieldType'

export function entityDisableStrateData(value) {
  if (!value) {
    return {}
  }
  const { type, count } = value
  const result = cloneDeep(THIRDPLATFORM_ENTITY_DISABLESTRATEGY()[type])
  if (type === 'LIMIT_COUNT') {
    result.label = i18n.get(result.label) + i18n.get('limitCount', { count })
  }
  return result
}

export function entityTypeDate(value) {
  if (!value) return {}
  return i18n.get(THIRDPLATFORM_ENTITY_TYPE()[value])
}

export function entityScope(value) {
  let a = 'ALL'
  if (value) {
    a = 'PART'
  }

  return THIRDPLATFORM_ENTITY_SCOPE()[a]
}

export function entityFieldItemData(item, entityList, dimension, enumerate) {
  const { name, label, title, ...others } = cloneDeep(item)
  const desc = entityFieldDesc(others, entityList, enumerate)
  let subtitle = i18n.get(getSubtitle(name))
  if (dimension) {
    const primary = dimension.primaryDimension
    const children = dimension.childrenDimension
    if (item.name === primary) {
      subtitle = i18n.get('主维度')
    } else if (item.name === children) {
      subtitle = i18n.get('子维度')
    }
  }
  return {
    title: label,
    subtitle,
    desc
  }
}

function getSubtitle(name) {
  if (!name) {
    return ''
  }
  const n = name.split('_').pop()
  return THIRDPLATFORM_ENTITY_FIELDMARK()[n]
}

function entityFieldDesc(args, entityList, enumerate) {
  const { type, withTime, scale, unit, rule, entity, elemType = {} } = args
  const cType = getFieldType(args)
  switch (cType) {
    case 'text':
      return i18n.get('文本')
    case 'switcher':
      return i18n.get('开关')
    case 'money':
      return i18n.get('金额')
    case 'autoNumber':
      return i18n.get('自动编号，编号规则：') + getCodeStr(rule)
    case 'date':
      return i18n.get('日期') + getDataStatus(withTime)
    case 'dateRange':
      return i18n.get('日期范围') + getDataStatus(withTime)
    case 'number':
      return i18n.get('数字') + getNumberStr({ scale, unit })
    case 'ref:pay.PayeeInfo':
      return i18n.get('收款信息')
    case 'ref:datalink.DataLinkEntity':
      return i18n.get('业务对象') + ',' + getDataLinkEntityStr(entity, entityList)
    case 'list:datalink.DataLinkEntity':
      return i18n.get('业务对象(多选)') + ',' + getDataLinkEntityStr(elemType.entity, entityList)
    case 'ref:basedata.Dimension':
      return i18n.get('自定义档案') + ',' + getDimensionStr(entity)
    case 'list:ref:basedata.Dimension':
      const dimensionStr = elemType?.entity ? ',' + getDimensionStr(elemType.entity) : ''
      return i18n.get('自定义档案(多选)') + dimensionStr
    case 'ref:basedata.Enum':
      return `${i18n.get('枚举')},${getEnumStr(entity, enumerate)}`
    case 'ref:basedata.city':
      return i18n.get('城市')
    case 'ref:organization.Staff':
      return i18n.get('人员')
    case 'list:organization.Staff':
      return i18n.get('人员(多选)')
    case 'autoComputed':
      return (
        i18n.get('自动计算') +
        ',' +
        (type === 'money' ? i18n.get('金额') : i18n.get('数字') + getNumberStr({ scale, unit }))
      )
    case 'attachment':
      return i18n.get('附件')
    case 'ref:organization.Department':
      return i18n.get('部门')
    default:
      return ''
  }
}

function getDataStatus(withTime) {
  if (withTime) {
    return i18n.get(', 含时间')
  }
  return i18n.get(', 不含时间')
}

function getNumberStr({ scale, unit }) {
  let str = i18n.get(', 整数')
  if (scale > 0) {
    str = i18n.get(', 小数')
  }

  if (!!unit) {
    str = str + i18n.get(', 单位 {unit}', { unit })
  }
  return str
}

function getCodeStr(rule) {
  if (rule) {
    return i18n.get(rule)
  }
  return '' // @i18n-ignore
}

function getDataLinkEntityStr(entity, entityList) {
  //结构:datalink.DataLinkEntity.1Ps9eccuOU0000
  const id = entity.split('.', 3)[2]
  if (!entityList || !id) {
    return ''
  }
  const entityLists = entityList.find(o => o.id === id)
  // @i18n-ignore
  return entityLists && entityLists.platformId.name + '/' + entityLists.name
}

function getDimensionStr(entity) {
  // 结构:basedata.Dimension.法人实体,:后拼接的是自定义档案的名字
  return entity.split('.', 3)[2]
}

function getEnumStr(entity, enumerates) {
  const enumId = entity.split('.', 3)[2]
  const em = enumerates?.find(item => item.id === enumId)
  return em?.name
}
