import { __assign, __decorate, __extends, __metadata } from "tslib";
import { injectKeel, KeelVM } from '@ekuaibao/keel';
import { Component } from 'react';
import { inject } from '@ekuaibao/react-ioc';
import { LayoutViewVm } from '@ekuaibao/collection-definition';
var KeelBasic = (function (_super) {
    __extends(KeelBasic, _super);
    function KeelBasic() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.syncKeel = function () {
            if (_this.vm) {
                _this.vm.outerKeel = _this.keel;
            }
        };
        _this.handleChangeBreadcrumbLastTitle = function (titleName) {
            if (_this.vm) {
                _this.vm.outerKeel = { keel: _this.keel, breadcrumbLastTitle: titleName };
            }
        };
        _this.initKeel = function () {
            if (_this.vm) {
                _this.vm.outerKeel = null;
            }
        };
        return _this;
    }
    KeelBasic.prototype.componentDidMount = function () {
        this.keel.open(this.props.viewKey, __assign(__assign({}, this.props), { keel: this.keel }));
        if (this.vm) {
            this.vm.outerKeel = this.keel;
        }
    };
    var _a, _b;
    __decorate([
        inject('LayoutViewVm'),
        __metadata("design:type", typeof (_a = typeof LayoutViewVm !== "undefined" && LayoutViewVm) === "function" ? _a : Object)
    ], KeelBasic.prototype, "vm", void 0);
    __decorate([
        injectKeel(),
        __metadata("design:type", typeof (_b = typeof KeelVM !== "undefined" && KeelVM) === "function" ? _b : Object)
    ], KeelBasic.prototype, "keel", void 0);
    return KeelBasic;
}(Component));
export default KeelBasic;
