/**************************************************
 * Created by nanyuantingfeng on 11/08/2017 17:41.
 **************************************************/
import moment from 'moment'
import { ENUM_DATE_TYPE } from '../consts'

export default (field = {}) => {
  const { defaultValue, withTime, optionalDefaultValue = false, dateTimeType } = field
  if (optionalDefaultValue) return
  if (!defaultValue) {
    return formatDateTime(withTime, dateTimeType) === 'YYYY-MM-DD HH:mm' ?
      Date.parse(new Date()) : formatWithTime(withTime, undefined, dateTimeType)
  }
  let { type, value } = defaultValue

  if (type === 'predefine' && value === 'repayment.date') {
    return formatWithTime(withTime, moment().add(1, 'months'), dateTimeType)
  }

  if (type === 'predefine' && !!~['submit.date', 'lastSubmit.date', 'firstSubmit.date'].indexOf(value)) {
    return formatWithTime(withTime, undefined, dateTimeType)
  }
}

export function formatWithTime(withTime, date = moment(), dateTimeType) {
  if (moment.isMoment(date)) {
    const dateTime = formatDateTime(withTime, dateTimeType)
    return moment(date.format(dateTime)).valueOf()
  }
  return date
}

export function timeConvert(withTime, dateTimeType, value) {
  if (!value) {
    return i18n.get('无')
  }
  const dateTime = formatDateTime(withTime, dateTimeType).replace(/-/g, '/')
  return moment(Number(value)).format(dateTime)
}

export function formatDateTime(withTime, dateTimeType) {
  if (dateTimeType) {
    switch (dateTimeType) {
      case ENUM_DATE_TYPE.YEAR_MONTH:
        return 'YYYY-MM'
      case ENUM_DATE_TYPE.YEAR_MONTH_DAY:
        return 'YYYY-MM-DD'
      case ENUM_DATE_TYPE.YEAR_MONTH_DAY_TIME:
        return 'YYYY-MM-DD HH:mm'
    }
  }
  return withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
}

export function getShowTime(withTime, dateTimeType) {
  const format = { format: 'HH:mm' }
  if (dateTimeType) {
    return dateTimeType === ENUM_DATE_TYPE.YEAR_MONTH_DAY_TIME && format
  }
  return withTime && format
}
