import React, { PureComponent } from 'react'
import { fnCompareProps } from '@ekuaibao/lib/lib/lib-util'

export class BaseTagsComponent extends PureComponent {
  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)
    this.state = { tags: this.fixData(props.tags) }
  }

  fixData(list) {
    list = list || []
    return list.map(line => {
      line.key = line.id || line.key
      line.name = line.name || line.label
      return line
    })
  }

  componentWillReceiveProps(nextProps) {
    const fn = fnCompareProps(this.props, nextProps)
    fn('tags', () => {
      const tags = this.fixData(nextProps.tags)
      this.setState({ tags })
    })
  }

  handleClose(key, tag) {
    this.props.onClose && this.props.onClose(key, tag)
    const tags = [...this.state.tags].filter(tag => tag.key !== key)
    this.setState({ tags }, () => {
      this.props.onChange && this.props.onChange(tags)
    })
  }

  handleClick = e => {
    e.preventDefault()
    this.props.onClick && this.props.onClick(e)
  }
}
