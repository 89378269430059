import { __extends } from "tslib";
import styles from './WarningPopup.module.less';
import React, { PureComponent } from 'react';
import { EKBPopover } from '../../ekb-components';
import EKBIcon from '../ekbIcon';
var WarningPopup = (function (_super) {
    __extends(WarningPopup, _super);
    function WarningPopup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WarningPopup.prototype.render = function () {
        var _a = this.props, content = _a.content, style = _a.style;
        return (React.createElement("div", { className: styles['set-component-styles'], style: style },
            React.createElement(EKBPopover, { trigger: "click", children: React.createElement(EKBIcon, { name: "#EDico-plaint-circle", className: "set-icon cur-p ml-8" }), overlayClassName: styles['set-popup-styles'], content: content })));
    };
    return WarningPopup;
}(PureComponent));
export default WarningPopup;
