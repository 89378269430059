/**
 * Created by zhaoyue on 2017/8/28 下午3:31
 */
import React from 'react'
import styles from './ThirdCardWrap.module.less'

const ThirdCardWrap = props => {
  let { header, body, hiddenHeader, className = '' } = props
  return (
    <div className={`${styles.thirdCard_wrap} ${className}`}>
      {hiddenHeader ? (
        <div className={styles.thirdCard_body_isHideHead}>{body}</div>
      ) : (
        <>
          <div className={styles.thirdCard_head}>{header}</div>
          <div className={styles.thirdCard_body}>{body}</div>
        </>
      )}
    </div>
  )
}

export default ThirdCardWrap
