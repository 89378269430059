/**************************************************
 * Created by nanyuantingfeng on 21/09/2017 13:50.
 **************************************************/

import {
  isPayeeInfos,
  isBaseData,
  isDepartments,
  isSpecification,
  isStaffs,
  isMoney,
  isPaymentAccount,
  isDataLink,
  isDataLinkCount
} from '@ekuaibao/lib/lib/propertySetIs'

function fixFilter(key, value, property) {
  const vv = split(value)
  if (!property) {
    return { [key]: vv }
  }

  if (isSpecification(property) || isFeeDetails(property)) {
    return { [`${key}.name`]: vv }
  }

  if (isBaseData(property)) {
    return {
      [`${key}.code`]: vv,
      [`${key}.name`]: vv
    }
  }

  if (isDepartments(property)) {
    return {
      [`${key}.name`]: vv,
      [`${key}.nameSpell`]: vv
    }
  }

  if (isStaffs(property)) {
    return {
      [`${key}.name`]: vv
    }
  }

  if (isMoney(property) && property.name !== 'actualPrice') {
    return { [`${key}.standard`]: value }
  }

  if (isPayeeInfos(property)) {
    return {
      [`${key}.name`]: vv,
      [`${key}.nameSpell`]: vv,
      [`${key}.bank`]: vv,
      [`${key}.branch`]: vv
    }
  }

  if (isPaymentAccount(property)) {
    // PRP-16025
    return { [`${key}.detail.name`]: vv }
  }

  if (isDataLink(property)) {
    let {
      dataType: { entity, type }
    } = property
    if (type === 'ref') {
      return {
        [`${key}.code`]: vv,
        [`${key}.name`]: vv
      }
    }
    const reg = /[^a-zA-Z0-9\\_\u4e00-\u9fa5]/g
    let entityId = entity.replace('datalink.DataLinkEntity.', '')
    entityId = entityId.replace(reg, '') //只保留字母，数字中文字符和_
    return {
      [`${key}.form.E_${entityId}_name`]: vv,
      [`${key}.form.E_${entityId}_code`]: vv
    }
  }

  if (isDataLinkCount(property)) {
    return {
      totalCount: vv,
      useCount: vv
    }
  }

  return { [key]: vv }
}

function isFeeDetails(property) {
  // 比较特殊，引用类型而且是费用明细模板
  const { dataType } = property
  if (!dataType) return false
  let { type, entity } = dataType
  entity = entity ? entity : property.entity

  return type === 'ref' && entity === 'flow.FeeType'
}

function split(value) {
  if (typeof value !== 'string') return value
  return value.split(/[\s\t;,]/).filter(v => !!v)
}

function fixValues(values, mapping = {}, type = 'filter') {
  let r = {}
  if (!values) return r
  Object.keys(values).forEach(key => {
    const value = values[key]
    const property = mapping[key]
    let obj = type === 'filter' ? { [key]: fixFilter(key, value, property) } : { ...fixFilter(key, value, property) }
    r = { ...r, ...obj }
  })
  return r
}

export default function(options, mapping) {
  let { filters, sorters } = options

  if (!filters && !sorters) {
    return options
  }

  filters = fixValues(filters, mapping)
  sorters = fixValues(sorters, mapping, 'sorters')

  return { ...options, filters, sorters }
}
