import { __assign, __decorate, __extends, __read } from "tslib";
import React, { Component } from 'react';
import CurrencyDropdown from '../currency/currency-dropdown';
import { EnhanceConnect } from '@ekuaibao/store';
import { isArray } from '@ekuaibao/helpers';
var CurrencySetting = (function (_super) {
    __extends(CurrencySetting, _super);
    function CurrencySetting() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            selectCurrency: undefined
        };
        _this.fnGetData = function () {
            var _a = _this.props, fields = _a.fields, _b = _a.allStandardCurrency, allStandardCurrency = _b === void 0 ? [] : _b, _c = _a.allCurrencyRates, allCurrencyRates = _c === void 0 ? [] : _c;
            if (!fields.length) {
                return [];
            }
            var _d = __read(fields, 1), currency = _d[0];
            return currency === 'STANDARD' ? allStandardCurrency : allCurrencyRates;
        };
        _this.handleSelectData = function () {
            var dataIndex = _this.props.dataIndex;
            return { result: [_this.state.selectCurrency], dataIndex: dataIndex };
        };
        _this.handleCurrencyChange = function (currency) {
            _this.setState({ selectCurrency: currency });
            var onValueChange = _this.props.onValueChange;
            var data = isArray(currency) ? currency : [currency];
            onValueChange && onValueChange(data);
        };
        _this.renderChildren = function () {
            var selectCurrency = _this.state.selectCurrency;
            var styles = { cursor: 'pointer', padding: '4px 10px' };
            return selectCurrency ? (React.createElement("div", { style: styles, ref: function (ref) { return (_this.ref = ref); } }, selectCurrency.name + "(" + selectCurrency.strCode + ")")) : (React.createElement("div", { style: __assign(__assign({}, styles), { color: 'rgb(202, 202, 202)' }), ref: function (ref) { return (_this.ref = ref); } }, "\u8BF7\u9009\u62E9\u5E01\u79CD"));
        };
        return _this;
    }
    CurrencySetting.prototype.componentDidMount = function () {
        var _a = this.props, dataIndex = _a.dataIndex, bus = _a.bus, value = _a.value, _b = _a.fields, fields = _b === void 0 ? [] : _b;
        bus.watch(dataIndex + ":get:select:data", this.handleSelectData);
        if (value) {
            var data = this.fnGetData();
            var selectCurrency = data.find(function (currency) { return currency.numCode === value; });
            this.setState({ selectCurrency: selectCurrency });
        }
        this.ref && this.ref.click();
    };
    CurrencySetting.prototype.componentWillUnmount = function () {
        var _a = this.props, dataIndex = _a.dataIndex, bus = _a.bus;
        bus.un(dataIndex + ":get:select:data", this.handleSelectData);
    };
    CurrencySetting.prototype.render = function () {
        var _a = this.props, dataIndex = _a.dataIndex, _b = _a.fields, fields = _b === void 0 ? [] : _b, children = _a.children;
        if (!fields.length) {
            return null;
        }
        var selectCurrency = this.state.selectCurrency;
        return (React.createElement(CurrencyDropdown, { menuStyle: { width: '100%' }, dropdownClassName: dataIndex + "_standard", placement: "bottomCenter", data: this.fnGetData(), checkedType: 'image', trigger: ['click'], checkedData: selectCurrency ? [selectCurrency] : [], onChange: this.handleCurrencyChange }, this.renderChildren()));
    };
    CurrencySetting = __decorate([
        EnhanceConnect(function (state) { return ({
            allCurrencyRates: state['@common'].allCurrencyRates,
            allStandardCurrency: state['@common'].allStandardCurrency
        }); })
    ], CurrencySetting);
    return CurrencySetting;
}(Component));
export default CurrencySetting;
