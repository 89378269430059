/**************************************************
 * Created by nanyuantingfeng on 26/06/2017 10:51.
 **************************************************/
import styles from './layout.module.less'
import React, { Children, cloneElement, isValidElement, PureComponent } from 'react'

export default class Box extends PureComponent {
  static defaultProps = {
    layout: 'center'
  }

  render() {
    let { children, layout, style, className, ...others } = this.props
    className = className || ''
    let childrenNew = Children.map(children, c => (!isValidElement(c) ? c : cloneElement(c, { ...others })))

    return (
      <div className={styles[`box-content-${layout}`] + ' ' + className} style={style}>
        {childrenNew}
      </div>
    )
  }
}

export { Box }

export function Header(props) {
  let { children, ...others } = props
  return (
    <Box layout="header" {...others}>
      {children}
    </Box>
  )
}

export function Footer(props) {
  let { children, ...others } = props
  return (
    <Box layout="footer" {...others}>
      {children}
    </Box>
  )
}

export function Left(props) {
  let { children, ...others } = props
  return (
    <Box layout="left" {...others}>
      {children}
    </Box>
  )
}

export function Right(props) {
  let { children, ...others } = props
  return (
    <Box layout="right" {...others}>
      {children}
    </Box>
  )
}
