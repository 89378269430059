import './index.less'
import React, { PureComponent } from 'react'
import Title from './Title'
import EKBBreadcrumb from './Breadcrumb'
import MessageCenter from '@ekuaibao/messagecenter'

export default class NavigationBar extends PureComponent {
  constructor(props) {
    super(props)
    this.bus = props.bus || new MessageCenter()
  }

  render() {
    let {
      items = [],
      titleVisible = true,
      changeColor = false,
      breadcrumbVisible = true,
      statusText,
      isPopover = true,
      otherView,
      explanation,
      style = {},
      tip = '',
      title = '',
      ...otherProps
    } = this.props
    let length = 0,
      titleName = title
    if (items && items.length) {
      titleName = items[items.length - 1].title
      length = items.length
    }
    return (
      <div className="navigation-bar" style={style}>
        {breadcrumbVisible && (length > 1 || !titleVisible) && <EKBBreadcrumb {...this.props} bus={this.bus} />}
        {titleVisible && (
          <Title
            title={titleName}
            statusText={statusText}
            changeColor={changeColor}
            useAlone={length === 1 && true}
            isPopover={isPopover}
            otherView={otherView}
            bus={this.bus}
            nodeLength={items.length}
            {...otherProps}
          />
        )}
        {explanation && <div className="explanation">{explanation}</div>}
        {tip && <span className="navigation-bar-tip">{tip}</span>}
      </div>
    )
  }
}
