import { sha1, uuid } from '@ekuaibao/helpers';
import { sourceCustomerUrl } from '@ekuaibao/lib/lib/enums';
import { permissionName } from '../elements/configure/config';
export default getCustomerServiceUrl;
export function getCustomerServiceUrl(user, sourceId) {
    var permissions = user.permissions || [];
    var staff = user.staff || {};
    var admin = !!~permissions.indexOf('SYS_ADMIN') ? i18n.get('管理员') : i18n.get('用户');
    var name = staff.name;
    var corp = staff.corporation && staff.corporation.name;
    var c_name = name + i18n.get('（app/') + admin + i18n.get('）');
    var web_token = staff.id;
    var key = 'bf62151d7cce4b1f881c8bf898bab775';
    var nonce = uuid(32);
    var timestamp = +new Date();
    var signature = "nonce=" + nonce + "&timestamp=" + timestamp + "&web_token=" + web_token + "&" + key;
    signature = sha1(signature);
    signature = signature.toUpperCase();
    var customerUrl = sourceCustomerUrl[window.__PLANTFORM__];
    var corpId = staff.corporation && staff.corporation.id;
    var cellphone = staff.cellphone;
    var permissionNames = permissions.map(function (item) { return permissionName(item); }).filter(function (item) { return item; }).join(",");
    return (customerUrl +
        '&web_plugin_id=' +
        sourceId +
        '&nonce=' +
        nonce +
        '&timestamp=' +
        timestamp +
        '&signature=' +
        signature +
        '&web_token=' +
        web_token +
        '&c_name=' +
        encodeURIComponent(c_name) +
        '&c_org=' +
        encodeURIComponent(corp)
        +
            '&partnerid=' + web_token +
        '&uname=' + encodeURIComponent(name) +
        '&customer_fields=' + encodeURIComponent(JSON.stringify({ customField4: permissionNames, customField6: corp, customField7: cellphone, customField8: corpId })));
}
export function getAskBotCustomerServiceUrl(user) {
    var staff = user.staff || {};
    var userName = staff.name;
    var userId = staff.id;
    var companyName = staff.corporation && staff.corporation.name;
    var source = window.__PLANTFORM__;
    var timestamp = +new Date();
    var appId = 5001;
    var id = 199;
    var apiKey = '9c5b9583655d42afab6a0037c09fa994';
    var secretKey = '4e5463784e546b774d4449334e7a51314d54677a';
    return ('https://ekuaibao-mall.guoranbot.com/?appId=' +
        appId +
        '&userId=' +
        userId +
        '&userName=' +
        encodeURIComponent(userName) +
        '&companyName=' +
        encodeURIComponent(companyName) +
        '&source=' +
        source +
        '&timestamp=' +
        timestamp +
        '&apiKey=' +
        apiKey +
        '&id=' +
        id +
        '&secretKey=' +
        secretKey);
}
