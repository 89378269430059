/**************************************************
 * Created by nanyuantingfeng on 14/09/2017 14:24.
 **************************************************/
// import { showMessage } from '@ekuaibao/show-util'
import { Resource, Fetch } from '@ekuaibao/fetch'
import { getV } from '@ekuaibao/lib/lib/help'
import { QuerySelect } from 'ekbc-query-builder'

const receipt = new Resource('/api/pay/v1/receipt')
import { app as api } from '@ekuaibao/whispered'
import { showMessage } from '@ekuaibao/show-util'

// 下载回单文件
export function downloadReceipt(ids) {
  Fetch.GET(`/api/pay/v1/receipt/getFileKeysByIds/[${ids}]`).then(res => {
    if (res.value) {
      const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
      if (ids.length > 20) {
        return api
          .open('@audit:PrintModal', {
            okText: i18n.get('download'),
            modalTitle: i18n.get('下载任务生成'),
            handleSubmit: data => {
              receipt.GET(`/asyncByIds/$type/[ids]?taskName=${data.taskName}`, { ids, type: 'download' })
            }
          })
          .then(_ => {
            showMessage.success(i18n.get('创建成功'))
          }) // 大于20条，异步下载
      }
      console.log('ceshi-->>>', ids)
      const downloadUrl = `${Fetch.fixOrigin(
        location.origin
      )}/api/pay/v1/receipt/downloadByIds/[${ids}]?corpId=${ekbCorpId}`
      console.log('downloadUrl:', downloadUrl)
      api.emit('@vendor:download', downloadUrl)
    } else {
      showMessage.info('请去回单管理中心重新获取回单文件')
      return
    }
  })
}

// 打印回单文件
export function printReceipt(ids) {
  Fetch.GET(`/api/pay/v1/receipt/getFileKeysByIds/[${ids}]`).then(res => {
    if (res.value) {
      if (ids.length > 20) {
        return api
          .open('@audit:PrintModal', {
            handleSubmit: data => {
              receipt.GET(`/asyncByIds/$type/[ids]?taskName=${data.taskName}`, { ids, type: 'print' })
            }
          })
          .then(_ => {
            showMessage.success(i18n.get('创建成功'))
          }) // 大于20条，异步打印
      }
      const ekbCorpId = encodeURIComponent(Fetch.ekbCorpId)
      const printUrl = `${Fetch.fixOrigin(
        location.origin
      )}/api/pay/v1/receipt/printByIds/[${ids}]?corpId=${ekbCorpId}&accessToken=${Fetch.accessToken}`
      api.emit('@vendor:open:link', printUrl)
    } else {
      showMessage.info('请去回单管理中心重新获取回单文件')
      return
    }
  })
}

// 预览九恒星支付返回的回单文件
export function previewReceipt(id, showModel) {
  Fetch.GET(`/api/pay/v1/receipt/findById/${id}`).then(res => {
    const previewUrl = getV(res, 'value.form.E_system_电子回单_预览地址.E_system_电子回单_webPreUrl')
    if (previewUrl) {
      if (showModel) {
          return api.open('@audit:PreviewByIframe', {
            url: previewUrl
          })
        }
      api.emit('@vendor:open:link', previewUrl)
    }
  })
}

// 获取回单文件弹窗列表内容
export function getReceiptFileModalList (params, ids) {
  const query = new QuerySelect().limit(params.limit.start, params.limit.count)
  let str = ids.map(item => {
    return `\"${item}"`
  })
  if (ids.length) {
    query.filterBy(`id.in(${str})`)
  }
  return receipt.POST('/search', query.value())
}
