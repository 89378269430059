/**
 *  Created by gym on 2018/5/14 下午11:20.
 */

import * as React from 'react'
import * as styles from './form-item.module.less'
import { Form, Tooltip, Icon } from 'antd'
import { isObject } from '@ekuaibao/helpers'

const FormItems = Form.Item

export default class FormItem extends React.Component {
  constructor(props) {
    super(props)
  }

  fnGetLabel = () => {
    let { label, title } = this.props
    return (
      <span>
        {label}
        <Tooltip title={title}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </span>
    )
  }

  render() {
    let {
      form,
      keyId,
      rules,
      wrapperCol,
      marginBottom,
      label,
      title,
      children,
      initialValue,
      className,
      labelCol
    } = this.props
    let { getFieldDecorator } = form
    label = title ? this.fnGetLabel() : label
    wrapperCol = wrapperCol ? wrapperCol : { span: 24 }
    const _labelCol = isObject(labelCol) ? labelCol : { span: labelCol ? labelCol : 24 }
    return (
      <FormItems
        className={styles['formItem-wrapper'] + ' ' + className}
        style={{ marginBottom: marginBottom }}
        label={label}
        labelCol={_labelCol}
        wrapperCol={wrapperCol}
      >
        {getFieldDecorator(`${keyId}`, {
          rules: rules,
          initialValue
        })(children)}
      </FormItems>
    )
  }
}
