import { __assign, __rest } from "tslib";
import React from 'react';
import StandardCitySelectView from './StandardCitySelect';
import StandardFeeTypeSelectView from './StandardFeeTypeSelect';
import StandardDimensionSelectView from './StandardDimensionSelect';
import InputSelect from '../input-selector';
import StandardDateSelect from './StandardDateSelect';
import CurrencySetting from './CurrencySetting';
export var SelectItemMap = {
    STAFF: StandardInputSelect,
    DEPARTMENT: StandardInputSelect,
    FEETYPE: StandardFeeTypeSelect,
    CITY: StandardCitySelect,
    DIMENSION: StandardDimensionSelect,
    DATE: StandardDateInputSelect,
    DIMENSION_SELECT: StandardInputSelect,
    CURRENCY: CurrencySetting
};
function StandardDateInputSelect(props) {
    return React.createElement(StandardDateSelect, __assign({}, props));
}
function StandardInputSelect(props) {
    var placeholder = props.placeholder, onSelect = props.onSelect, dataIndex = props.dataIndex, record = props.record, inputValue = props.inputValue, others = __rest(props, ["placeholder", "onSelect", "dataIndex", "record", "inputValue"]);
    var value = inputValue ? inputValue : record[dataIndex];
    return React.createElement(InputSelect, __assign({ placeholder: placeholder, onSelect: function () { return onSelect && onSelect(); } }, others, { value: value }));
}
function StandardCitySelect(props) {
    var record = props.record, dataIndex = props.dataIndex, bus = props.bus, others = __rest(props, ["record", "dataIndex", "bus"]);
    var originalData = record[dataIndex] ? record[dataIndex].originalData : {};
    return (React.createElement(StandardCitySelectView, __assign({ bus: bus, dataIndex: dataIndex, cls: dataIndex + "_standard", field: {
            disabledPopupContainer: true,
            multiple: true,
            placeholder: i18n.get('请输入城市'),
            originalData: originalData,
            dropdownClassName: dataIndex + "_standard"
        } }, others)));
}
function StandardFeeTypeSelect(props) {
    var dataIndex = props.dataIndex, bus = props.bus, visibilityFeeTypes = props.visibilityFeeTypes, others = __rest(props, ["dataIndex", "bus", "visibilityFeeTypes"]);
    return (React.createElement(StandardFeeTypeSelectView, __assign({ dataIndex: dataIndex, bus: bus, cls: dataIndex + "_standard", size: "large", multiple: true, treeCheckable: true, feeTypes: visibilityFeeTypes, disabledPopupContainer: true, dropdownWidth: "450px" }, others)));
}
function StandardDimensionSelect(props) {
    var dataIndex = props.dataIndex, bus = props.bus, others = __rest(props, ["dataIndex", "bus"]);
    return (React.createElement(StandardDimensionSelectView, __assign({ dataIndex: dataIndex, bus: bus, dropdownClassName: dataIndex + "_standard", multiple: true }, others, { dropdownStyle: { maxHeight: 320, overflowY: 'auto' } })));
}
