if (IS_HSFK) {
  module.exports = {
    ICON_AUTH_MANAGE_ICON_EKB: require('./auth-manage-icon-ekb.hsfk.svg'),
    ICON_ICON_EKB_CARD: require('./icon-ekb-card.hsfk.svg'),
    ICON_LOGO_1: require('./logo-1.hsfk.png'),
    ICON_LOGO_128: require('./logo-128.hsfk.png'),
    ICON_LOGO_NAME: require('./logo-name.hsfk.svg'),
    ICON_LOGO_NEW: require('./logo-new.hsfk.svg'),
    ICON_LOGO_SVG: require('./logo-svg.hsfk.svg'),
    ICON_LOGO: require('./logo.hsfk.svg'),
    ICON_SHAREEXPINFO_LOGO: require('./shareexpinfo-logo.hsfk.svg')
  }
} else if (IS_CMBC) {
  module.exports = {
    ICON_AUTH_MANAGE_ICON_EKB: require('./icon-cmbc.png'),
    ICON_ICON_EKB_CARD: require('./icon-cmbc.png'),
    ICON_LOGO_1: require('./icon-cmbc.png'),
    ICON_LOGO_128: require('./icon-cmbc.png'),
    ICON_LOGO_NAME: require('./icon-cmbc.png'),
    ICON_LOGO_NEW: require('./icon-cmbc.png'),
    ICON_LOGO_SVG: require('./icon-cmbc.png'),
    ICON_LOGO: require('./icon-cmbc.png'),
    ICON_SHAREEXPINFO_LOGO: require('./icon-cmbc.png')
  }
} else if (IS_ICBC){
  module.exports = {
    ICON_AUTH_MANAGE_ICON_EKB: require('./logo_icbc.png'),
    ICON_ICON_EKB_CARD: require('./logo_icbc.png'),
    ICON_LOGO_1: require('./logo_icbc_small.png'),
    ICON_LOGO_128: require('./logo_icbc.png'),
    ICON_LOGO_NAME: require('./logo_icbc.png'),
    ICON_LOGO_NEW: require('./logo_icbc.png'),
    ICON_LOGO_SVG: require('./logo_icbc.png'),
    ICON_LOGO: require('./logo_icbc.png'),
    ICON_SHAREEXPINFO_LOGO: require('./logo_icbc.png'),
  }
} else {
  module.exports = {
    ICON_AUTH_MANAGE_ICON_EKB: require('./auth-manage-icon-ekb.svg'),
    ICON_ICON_EKB_CARD: require('./icon-ekb-card.svg'),
    ICON_LOGO_1: require('./logo1.svg'),
    ICON_LOGO_128: require('./logo-128.png'),
    ICON_LOGO_NAME: require('./logo-name.svg'),
    ICON_LOGO_NEW: require('./logo-new.svg'),
    ICON_LOGO_SVG: require('./logo-svg.svg'),
    ICON_LOGO: require('./logo.svg'),
    ICON_SHAREEXPINFO_LOGO: require('./shareexpinfo-logo.svg')
  }
}