import { get } from 'lodash';
import { app } from '@ekuaibao/whispered';
import standardConfig from './standardConfig.json';
export function UniversalComponent(props) {
    var _a;
    var uniqueKey = props.uniqueKey, children = props.children;
    var json = getStandardConfig();
    if (!json) {
        return children;
    }
    var show = (_a = get(json, uniqueKey)) !== null && _a !== void 0 ? _a : true;
    return show ? children : null;
}
export function showFunctionByKey(key) {
    var _a;
    var json = getStandardConfig();
    if (!json) {
        return true;
    }
    return (_a = get(json, key)) !== null && _a !== void 0 ? _a : true;
}
export function getLimitByKey(key) {
    var _a;
    var json = getStandardConfig();
    if (!json) {
        return 0;
    }
    return (_a = get(json, key)) !== null && _a !== void 0 ? _a : 0;
}
function isOpenStandard() {
    var _a;
    return (_a = app.getState()['@common'].powers) === null || _a === void 0 ? void 0 : _a.Universal;
}
function getStandardConfig() {
    if (isOpenStandard()) {
        return app.getState()['@common'].standardLimtConfig || standardConfig;
    }
    return undefined;
}
