import { __assign, __extends, __rest } from "tslib";
import React, { PureComponent } from 'react';
import { EKBTreeSelect } from '../../ekb-components';
import { app as api } from '@ekuaibao/whispered';
import { treeDataToMap } from '@ekuaibao/lib/lib/fnTreeDataToMap';
import { getAvailableTree } from './fnTreeFilterAvailable';
import { isArray } from '@ekuaibao/helpers';
import { cloneDeep } from 'lodash';
var StandardDimensionSelect = (function (_super) {
    __extends(StandardDimensionSelect, _super);
    function StandardDimensionSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { dimensionList: [], value: _this.props.value || [] };
        _this.handleGetSelectData = function () {
            var dataIndex = _this.props.dataIndex;
            return { result: _this.selectData, dataIndex: dataIndex };
        };
        _this.handleOnChange = function (result) {
            var _a = _this.props, apportionStore = _a.apportionStore, dataIndex = _a.dataIndex, _b = _a.record, record = _b === void 0 ? {} : _b;
            if (apportionStore) {
                var key = record.key;
                apportionStore.updateOneApportionValue(key, dataIndex, _this.map[result]);
            }
            _this.setState({ value: result });
            if (isArray(result)) {
                _this.selectData = result.concat().map(function (line) {
                    var _a = _this.map[line], id = _a.id, name = _a.name;
                    return { label: name, value: id };
                });
            }
        };
        return _this;
    }
    StandardDimensionSelect.prototype.componentWillMount = function () {
        var _this = this;
        var _a = this.props, bus = _a.bus, dataIndex = _a.dataIndex, value = _a.value, entity = _a.entity;
        bus && bus.watch(dataIndex + ":get:select:data", this.handleGetSelectData);
        var name = entity ? entity : dataIndex.replace(/&/g, '.');
        api.invokeService('@common:get:staff:dimension', { name: name, withVisibility: false }).then(function (data) {
            var dimensionList = cloneDeep(data.items || []);
            getAvailableTree(dimensionList, value);
            _this.map = treeDataToMap(dimensionList);
            _this.setState({ dimensionList: dimensionList });
        });
    };
    StandardDimensionSelect.prototype.componentWillUnmount = function () {
        var _a = this.props, bus = _a.bus, dataIndex = _a.dataIndex;
        bus && bus.un(dataIndex + ":get:select:data", this.handleGetSelectData);
    };
    StandardDimensionSelect.prototype.render = function () {
        var _a = this.props, dataIndex = _a.dataIndex, _b = _a.treeCheckable, treeCheckable = _b === void 0 ? true : _b, others = __rest(_a, ["dataIndex", "treeCheckable"]);
        var _c = this.state, _d = _c.dimensionList, dimensionList = _d === void 0 ? [] : _d, value = _c.value;
        var title = dataIndex.substring(dataIndex.lastIndexOf('&') + 1);
        return (React.createElement(EKBTreeSelect, __assign({}, others, { value: value, notFoundContent: i18n.get('没有匹配结果'), placeholder: i18n.get('请选择{__k0}', { __k0: title }), treeData: dimensionList, treeCheckable: treeCheckable, isShowParent: true, treeNodeFilterProp: "name", treeNodeLabelProp: "name", size: 'large', onChange: this.handleOnChange })));
    };
    return StandardDimensionSelect;
}(PureComponent));
export default StandardDimensionSelect;
