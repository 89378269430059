import { groupBy, concat } from 'lodash'

/***************************************************
 * Created by nanyuantingfeng on 2020/4/21 00:45. *
 ***************************************************/
import { calcDetailsAmount, getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { MoneyMath } from '@ekuaibao/money-math'

const title = formType => {
  if (formType === 'expense') {
    return i18n.get('报销金额')
  }
  if (formType === 'requisition') {
    return i18n.get('申请金额')
  }
  if (formType === 'loan') {
    return window.IS_SMG ? i18n.get('预支金额') : i18n.get('借款金额')
  }
  if (formType === 'settlement') {
    return i18n.get('结算金额')
  }
  if (formType === 'receipt') {
    return i18n.get('收款金额')
  }
}

function total(list = []) {
  return list.reduce((a, b) => new MoneyMath(a).add(b).fixedValue, 0)
}

export function getDetailCalculateMoney(details = [], formType) {
  const detailTotalMoney = calcDetailsAmount(details)
  const detailsTotal = {
    label: title(formType),
    money: detailTotalMoney
  }
  const companyPayMoney = total(details.map(v => v.feeTypeForm.companyRealPay).filter(o => o))
  if (formType !== 'loan') {
    const settlementMoneyObject = groupBy(details, item => {
      return item.feeTypeForm.settlement && item.feeTypeForm.settlement.opportunity
    })
    const settlementGroup = groupBy(
      details.filter(item => !!item.feeTypeForm.settlement),
      item => {
        return item.feeTypeForm.settlement && item.feeTypeForm.settlement.name
      }
    )
    const expenseDetails = concat(
      settlementMoneyObject['SINGLEPAYMENT'] || [],
      settlementMoneyObject.undefined || [],
      settlementMoneyObject[''] || [],
      settlementMoneyObject.null || []
    )
    const expenseMoney = calcDetailsAmount(expenseDetails)

    let settlements = []
    Object.keys(settlementGroup)
      .filter(name => !!name)
      .forEach(name => {
        const items = settlementGroup[name]
        const opportunity = getNodeValueByPath(items[0], 'feeTypeForm.settlement.opportunity')
        const money = calcDetailsAmount(items)
        settlements.push({
          value: money,
          items,
          label: name,
          opportunity,
          showNegative: formType === 'expense'
        })
      })
    if (formType === 'expense') {
      settlements = settlements.filter(item => item.opportunity !== 'SINGLEPAYMENT')
    }
    return {
      detailsTotal,
      companyPayMoney,
      payDetail: {
        lable: '',
        value: expenseMoney,
        items: expenseDetails
      },
      settlements
    }
  }
  return {
    detailsTotal,
    companyPayMoney,
    payDetail: {
      lable: '',
      items: details,
      value: calcDetailsAmount(details)
    }
  }
}

export default getDetailCalculateMoney
