import SVG_DEFAULT from '../../../images/avatar.svg';
import EkbIcon from '../../ekbIcon';
import React from 'react';
export function renderIcon(item) {
    var avatar = item && item.avatar ? item.avatar : SVG_DEFAULT;
    if (!item) {
        return React.createElement(React.Fragment, null);
    }
    if (!item.type) {
        return React.createElement("img", { className: "head-portrait", src: "" + avatar, alt: "" });
    }
    var dom = React.createElement(React.Fragment, null);
    switch (item.type) {
        case 'staff':
            dom = React.createElement("img", { className: "head-portrait", src: "" + avatar, alt: "" });
            break;
        case 'role':
            dom = React.createElement(EkbIcon, { name: "#EDico-role-fill", className: "personnel_list_icon role_bg" });
            break;
        case 'department':
            dom = React.createElement(EkbIcon, { name: "#EDico-folder-fill", className: "personnel_list_icon department_bg" });
            break;
    }
    return dom;
}
export function personName(name, CHINESENUM, ENGLISHNUM) {
    var isChina = /.*[\u4e00-\u9fa5]+.*$/.test(name);
    var index = isChina ? CHINESENUM : ENGLISHNUM;
    if (name && name.length > index) {
        return name.substring(0, index) + '...';
    }
    return name;
}
