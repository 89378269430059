import { __awaiter, __generator } from "tslib";
import { initialize } from './i18n-initialize';
import { Fetch } from '@ekuaibao/fetch';
export function newHomeConfig() {
    return __awaiter(this, void 0, void 0, function () {
        var defaultPreviewURL, value, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    window.isNewHome = true;
                    defaultPreviewURL = 'https://doc.ekuaibao.com';
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4, Fetch.GET(location.origin + "/api/previewUrl")];
                case 2:
                    value = (_a.sent()).value;
                    window.PREVIEW_DOMAIN = value || defaultPreviewURL;
                    return [3, 4];
                case 3:
                    error_1 = _a.sent();
                    window.PREVIEW_DOMAIN = defaultPreviewURL;
                    return [3, 4];
                case 4:
                    if (IS_HSFK)
                        return [2, Promise.resolve()];
                    return [4, initialize()];
                case 5:
                    _a.sent();
                    return [2];
            }
        });
    });
}
