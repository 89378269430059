/**
 *  Created by gym on 2018/7/10 下午6:52.
 */
import { fnFormatAttachment } from '@ekuaibao/lib/lib/lib-util'
import { get } from 'lodash'

export function invoiceItemOptions(value) {
  if (!value || value.length === 0) return

  const invoiceItems = [
    {
      label: i18n.get('已有发票'),
      value: i18n.get('已有发票'),
      type: 'exist'
    },
    {
      label: i18n.get('待开发票'),
      value: i18n.get('待开发票'),
      type: 'wait'
    },
    {
      label: i18n.get('统一开票'),
      value: i18n.get('统一开票'),
      type: 'unify'
    },
    {
      label: i18n.get('无发票'),
      value: i18n.get('无发票'),
      type: 'noExist'
    }
  ]

  const InvoiceType = [
    ...invoiceItems,
    {
      label: i18n.get('无需填写'),
      value: i18n.get('无需填写'),
      type: 'noWrite'
    }
  ]

  let item = InvoiceType.filter(v => v.type === value.type)
  return item[0] ? item[0].value : i18n.get('无需填写')
}

export function _formatInvoiceDetail(oldData, newData) {
  let oldDetailsList = (oldData.invoiceDetail && oldData.invoiceDetail.details) || oldData.details
  let newDetailsList = newData.details
  newDetailsList.map(v => {
    if (oldDetailsList.findIndex(oo => oo.id === v.id) < 0) {
      v.notAssociated = true
    }
  })
  let formatInvoice = { details: newDetailsList, master: newData.master }
  return formatInvoice
}

export function parseAsShowValue(fileList, invoiceDetailList) {
  let files = fnFormatAttachment(fileList)
  files.map(item => (item.type = 'invoicePhoto'))
  return files.concat(invoiceDetailList)
}

export function isHaftInvoice(arr) {
  if (!arr || !arr.length) return false
  let flag = false
  for (const item of arr) {
    const { details, master = {} } = item
    const source = get(item, i18n.get('master.form.E_system_发票主体_来源'))
    const originalDetails = get(item, 'originalData.details', [])
    if (master.entityId === 'system_发票主体') {
      // @i18n-ignore
      if (originalDetails.length > 0 && originalDetails.length !== details.length) {
        flag = true
        break
      }
    }
  }
  return flag
}

export function fnGetInvoiceManage(permissions) {
  if (permissions.find(item => item === 'INVOICE_MANAGE')) {
    return true
  }
}

export const keyMap = {
  onImportInvoiceClick: 'upload',
  onImportInputInvoiceClick: 'query',
  onImportInvoiceAttachmentClick: 'photo',
  onImportOCRClick: 'ocr',
  onImportDidiPersonalClick: 'didiPersonal'
}
