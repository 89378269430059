import { __decorate, __extends } from "tslib";
import React from 'react';
import GlobalLayerManager from './GlobalLayerManager';
import OpenLinkInIframe from '@ekuaibao/sdk-bridge/patch/OpenLinkInIframe';
import PreviewImages from '@ekuaibao/sdk-bridge/patch/PreviewImages';
import { LocaleProviderPatch } from './LocaleProviderPatch';
import { SystemOpenAction4ThirdResourceConnector } from '@ekuaibao/3rd_resources';
import { provider, toValue } from '@ekuaibao/react-ioc';
import { app } from '@ekuaibao/whispered';
var collection = app.collection;
var Patches = (function (_super) {
    __extends(Patches, _super);
    function Patches() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Patches.prototype.render = function () {
        return (React.createElement(LocaleProviderPatch, null,
            React.createElement(React.Fragment, null,
                React.createElement(OpenLinkInIframe, null),
                React.createElement(PreviewImages, null),
                React.createElement(GlobalLayerManager, null),
                React.createElement(SystemOpenAction4ThirdResourceConnector, null, this.props.children))));
    };
    Patches = __decorate([
        provider(['collection', toValue(collection)])
    ], Patches);
    return Patches;
}(React.Component));
export default Patches;
