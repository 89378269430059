import loadable from '@loadable/component';
import { ENUM_TYPES } from './consts';
import { includes } from 'lodash';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var Attachment = loadableWithDescriptor(function () { return import('./dynamic/Attachment'); }, {
    type: 'attachments'
});
var DateCell = loadableWithDescriptor(function () { return import('./dynamic/Date'); }, {
    type: 'date'
});
var DateMonth = loadableWithDescriptor(function () { return import('./dynamic/Month'); }, {
    type: 'date-month'
});
var Details = loadableWithDescriptor(function () { return import('./dynamic/Details'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return type === 'requisitionDetails' || type === 'details';
    }
});
var Money = loadableWithDescriptor(function () { return import('./dynamic/Money'); }, {
    type: 'money'
});
var Number = loadableWithDescriptor(function () { return import('./dynamic/Number'); }, {
    type: 'number'
});
var Ref = loadableWithDescriptor(function () { return import('./dynamic/Ref'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return (type.startsWith('ref') &&
            type !== 'ref:organization.Staff' &&
            type !== 'ref:organization.Department' &&
            type !== 'ref:basedata.Enum.currency' &&
            !includes(ENUM_TYPES, type));
    }
});
var RefDepartment = loadableWithDescriptor(function () { return import('./dynamic/RefDepartment'); }, {
    type: 'ref:organization.Department'
});
var RefStaff = loadableWithDescriptor(function () { return import('./dynamic/RefStaff'); }, {
    type: 'ref:organization.Staff'
});
var City = loadableWithDescriptor(function () { return import('./dynamic/CityPicker'); }, {
    type: 'city'
});
var Text = loadableWithDescriptor(function () { return import('./dynamic/Text'); }, {
    type: 'text'
});
var TextArea = loadableWithDescriptor(function () { return import('./dynamic/TextArea'); }, {
    type: 'textarea'
});
var Phone = loadableWithDescriptor(function () { return import('./dynamic/Phone'); }, {
    type: 'phone'
});
var PayeeInfo = loadableWithDescriptor(function () { return import('./dynamic/PayeeInfo'); }, {
    type: 'payeeInfo'
});
var MutilPayeeInfo = loadableWithDescriptor(function () { return import('./dynamic/MutilPayeeInfo'); }, {
    type: 'list:ref:pay.PayeeInfo'
});
var Separator = loadableWithDescriptor(function () { return import('./dynamic/Separator'); }, {
    type: 'separator'
});
var Annotation = loadableWithDescriptor(function () { return import('./dynamic/Annotation'); }, {
    type: 'annotation'
});
var Select = loadableWithDescriptor(function () { return import('./dynamic/Select'); }, {
    type: 'select'
});
var Cascader = loadableWithDescriptor(function () { return import('./dynamic/Cascader'); }, {
    type: 'cascader'
});
var SelectSearch = loadableWithDescriptor(function () { return import('./dynamic/SelectSearch'); }, {
    type: 'select_search'
});
var DateRange = loadableWithDescriptor(function () { return import('./dynamic/DateRange'); }, {
    type: 'dateRange'
});
var SelectPrintTemplate = loadableWithDescriptor(function () { return import('./internal/SelectPrintTemplate'); }, {
    type: 'select:printTemplate'
});
var Description = loadableWithDescriptor(function () { return import('./dynamic/Description'); }, {
    type: 'description'
});
var LinkRequisitionInfo = loadableWithDescriptor(function () { return import('./dynamic/LinkRequisitionInfo'); }, {
    type: 'linkRequisitionInfo'
});
var ExpenseLink = loadableWithDescriptor(function () { return import('./dynamic/ExpenseLink'); }, {
    type: 'expenseLink'
});
var ExpenseLinkList = loadableWithDescriptor(function () { return import('./dynamic/ExpenseLinkList'); }, {
    type: 'expenseLinks'
});
var RefEnum = loadableWithDescriptor(function () { return import('./dynamic/RefEnum'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return includes(ENUM_TYPES, type);
    }
});
var Switcher = loadableWithDescriptor(function () { return import('./dynamic/Switcher'); }, {
    type: 'switcher'
});
var Apportion = loadableWithDescriptor(function () { return import('./dynamic/Apportions'); }, {
    type: 'apportions'
});
var DataLink = loadableWithDescriptor(function () { return import('./dynamic/DataLink'); }, {
    type: 'dataLink'
});
var InvoiceSelect = loadableWithDescriptor(function () { return import('./dynamic/InvoiceSelect'); }, {
    type: 'invoice'
});
var Trips = loadableWithDescriptor(function () { return import('./dynamic/Trips'); }, { type: 'trips' });
var VPhoto = loadableWithDescriptor(function () { return import('./dynamic/VPhoto'); }, {
    name: 'vphoto_order'
});
var Time = loadableWithDescriptor(function () { return import('./dynamic/Time'); }, {
    type: 'time'
});
var MutilStaff = loadableWithDescriptor(function () { return import('./dynamic/MutilStaff'); }, {
    type: 'list:ref:organization.Staff'
});
var EnterWay = loadableWithDescriptor(function () { return import('./dynamic/EnterWay'); }, {
    type: 'enter-way'
});
var DataLinkEdit = loadableWithDescriptor(function () { return import('./dynamic/dataLinkEdit/DataLinkEdit'); }, {
    test: function (field) {
        var type = field.type, referenceData = field.referenceData;
        return type === 'dataLinkEdits' && referenceData.type !== 'TRIP';
    }
});
var DataLinkList = loadableWithDescriptor(function () { return import('./dynamic/DataLinkList'); }, {
    type: 'dataLinks'
});
var RelatedDetails = loadableWithDescriptor(function () { return import('./dynamic/RelatedDetails'); }, {
    type: 'linkDetailEntities'
});
var TripDataLink = loadableWithDescriptor(function () { return import('./dynamic/dataLinkEdit/TripDataLink'); }, {
    test: function (field) {
        var type = field.type, referenceData = field.referenceData;
        return type === 'dataLinkEdits' && referenceData.type === 'TRIP';
    }
});
var sourceType = loadableWithDescriptor(function () { return import('./dynamic/SourceType'); }, {
    name: 'sourceType'
});
var payment = loadableWithDescriptor(function () { return import('./dynamic/Payment'); }, {
    name: 'payment'
});
var supplierTilte = loadableWithDescriptor(function () { return import('./dynamic/SupplierTitle'); }, {
    type: 'supplierTitle'
});
var travelList = loadableWithDescriptor(function () { return import('./dynamic/TravelList/TravelList'); }, {
    type: 'travel'
});
var BankSelect = loadableWithDescriptor(function () { return import('./dynamic/BankSelect'); }, {
    type: 'bankSelect'
});
var StaffSelectTags = loadableWithDescriptor(function () { return import('./dynamic/StaffSelectTags'); }, {
    type: 'staff-select-tags'
});
var LoanRuleTemplate = loadableWithDescriptor(function () { return import('./dynamic/LoanRuleTemplate'); }, {
    type: 'loan-rule-template'
});
var LoanRuleType = loadableWithDescriptor(function () { return import('./dynamic/LoanRuleType'); }, {
    type: 'loan-rule-type'
});
var LoanRuleAmount = loadableWithDescriptor(function () { return import('./dynamic/LoanRuleAmount'); }, {
    type: 'loan-rule-amount'
});
var MutilDimensionList = loadableWithDescriptor(function () { return import('./dynamic/MutilDimensionList'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return type.startsWith('list:ref:basedata.Dimension') && type.endsWith(':select');
    }
});
var MutilDimensionListSearch = loadableWithDescriptor(function () { return import('./dynamic/MutilDimensionListSearch'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return type.startsWith('list:ref:basedata.Dimension') && type.endsWith(':select_search');
    }
});
var RadioGroupSupplier = loadableWithDescriptor(function () { return import('./dynamic/RadioGroupSupplier'); }, {
    type: 'radio-group-supplier'
});
var SplitCalculation = loadableWithDescriptor(function () { return import('./dynamic/SplitCalculation'); }, {
    type: 'splitCalculation'
});
var Unknown = loadableWithDescriptor(function () { return import('./internal/Unknown'); }, { type: 'unknown' });
var InterConnection = loadableWithDescriptor(function () { return import('./dynamic/interConnection/index'); }, {
    test: function (field) {
        var type = field.type;
        return type === 'engineConnect';
    }
});
var SupplierSelect = loadableWithDescriptor(function () { return import('./unify_invoice/Supplier'); }, {
    type: 'select:supplier'
});
var CheckboxGroup = loadableWithDescriptor(function () { return import('./unify_invoice/CheckboxGroup'); }, {
    type: 'checkbox:group'
});
var AutoSplitCheckbox = loadableWithDescriptor(function () { return import('./unify_invoice/AutoSplitCheckbox'); }, {
    type: 'autoSplitCheckbox'
});
var RadioGroup = loadableWithDescriptor(function () { return import('./unify_invoice/RadioGroup'); }, {
    type: 'radio:group'
});
var SplitRule = loadableWithDescriptor(function () { return import('./unify_invoice/SplitRule'); }, {
    type: 'split:rule'
});
var CorpPayerInfo = loadableWithDescriptor(function () { return import('./unify_invoice/CorpPayerInfo'); }, {
    type: 'corp:payer:info'
});
var Subsidy = loadableWithDescriptor(function () { return import('./dynamic/Subsidy'); }, {
    type: 'subsidy'
});
var Association = loadableWithDescriptor(function () { return import('./dynamic/Association'); }, {
    name: 'isOpenAssociation'
});
var BlockUI = loadableWithDescriptor(function () { return import('./dynamic/BlockUI'); }, {
    type: 'engineBlockUI'
});
var SelectCodeRule = loadableWithDescriptor(function () { return import('./internal/SelectCodeRule'); }, {
    type: 'select:code:rule'
});
var Currency = loadableWithDescriptor(function () { return import('./dynamic/Currency'); }, {
    type: "ref:basedata.Enum.currency"
});
var CheckingBillForm = loadableWithDescriptor(function () { return import('./dynamic/CheckingBillForm'); }, {
    name: 'checkingBillForm'
});
var DataLinkStaff = loadableWithDescriptor(function () { return import('./dynamic/DataLinkStaff'); }, {
    type: 'list:ref:dataLink.Staff'
});
var GroupTitle = loadableWithDescriptor(function () { return import('./dynamic/GroupTitle'); }, {
    type: 'group'
});
var OrderConfigRules = loadableWithDescriptor(function () { return import('./dynamic/OrderConfigRules'); }, {
    type: 'order-config-rules'
});
var OrderConfigSelect = loadableWithDescriptor(function () { return import('./dynamic/OrderConfigSelect'); }, {
    type: 'order-config-select'
});
var FlowLinks = loadableWithDescriptor(function () { return import('./dynamic/FlowLinks'); }, {
    type: 'flowLinks'
});
var CheckCitySelect = loadableWithDescriptor(function () { return import('./dynamic/CheckCitySelect'); }, {
    type: 'check-city-select'
});
export var editable = [
    Currency,
    Attachment,
    DateCell,
    Details,
    Money,
    Number,
    Ref,
    RefDepartment,
    RefStaff,
    City,
    Text,
    TextArea,
    Phone,
    PayeeInfo,
    MutilPayeeInfo,
    Separator,
    Annotation,
    Select,
    Cascader,
    DateRange,
    SelectPrintTemplate,
    Description,
    LinkRequisitionInfo,
    ExpenseLink,
    ExpenseLinkList,
    RefEnum,
    Switcher,
    Apportion,
    Association,
    SelectSearch,
    DataLink,
    InvoiceSelect,
    Trips,
    VPhoto,
    Time,
    MutilStaff,
    EnterWay,
    DataLinkEdit,
    DataLinkList,
    RelatedDetails,
    TripDataLink,
    BankSelect,
    StaffSelectTags,
    LoanRuleTemplate,
    LoanRuleType,
    LoanRuleAmount,
    MutilDimensionList,
    MutilDimensionListSearch,
    RadioGroupSupplier,
    SplitCalculation,
    InterConnection,
    Unknown,
    payment,
    sourceType,
    supplierTilte,
    SupplierSelect,
    CheckboxGroup,
    RadioGroup,
    AutoSplitCheckbox,
    SplitRule,
    CorpPayerInfo,
    DateMonth,
    Subsidy,
    SelectCodeRule,
    CheckingBillForm,
    BlockUI,
    DataLinkStaff,
    travelList,
    GroupTitle,
    OrderConfigSelect,
    OrderConfigRules,
    FlowLinks,
    CheckCitySelect,
];
