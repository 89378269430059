export var ENUM_TYPES = [
    'ref:basedata.Enum.CabinType',
    'ref:basedata.Enum.TrainSeatType',
    'ref:basedata.Enum.CruiseCabinType'
];
export var ENUM_DATE_TYPE = {
    YEAR_MONTH: 'YEAR_MONTH',
    YEAR_MONTH_DAY: 'YEAR_MONTH_DAY',
    YEAR_MONTH_DAY_TIME: 'YEAR_MONTH_DAY_TIME'
};
export var DATE_TYPE = [
    {
        k: ENUM_DATE_TYPE.YEAR_MONTH,
        v: '年-月'
    },
    {
        k: ENUM_DATE_TYPE.YEAR_MONTH_DAY,
        v: '年-月-日'
    },
    {
        k: ENUM_DATE_TYPE.YEAR_MONTH_DAY_TIME,
        v: '年-月-日-时-分'
    }
];
export var DATE_TYPE_RANGE = [
    {
        k: ENUM_DATE_TYPE.YEAR_MONTH_DAY,
        v: '年-月-日'
    },
    {
        k: ENUM_DATE_TYPE.YEAR_MONTH_DAY_TIME,
        v: '年-月-日-时-分'
    }
];
