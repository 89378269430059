/**************************************************
 * Created by nanyuantingfeng on 21/09/2017 15:08.
 **************************************************/
import React from 'react'
import { stateMap, INVOICE_TYPE_BASE_ENUM, PRINT_STATE, RECEIPT_STATE } from '@ekuaibao/lib/lib/enums'
import { NullCell } from 'ekbc-datagrid/lib/puppet/Cells'
import { Popover, Tooltip } from 'antd'
// import Money from '../puppet/Money'
import Money from '../puppet/Currency'
import moment from 'moment'
import { get } from 'lodash'
import { app as api } from '@ekuaibao/whispered'
import { filterChannels } from '../../components/utils/fnFilterPaymentChannel'
import {
  isBaseData,
  isCity,
  isStaffs,
  isMoney,
  isPayeeInfos,
  isSwitch,
  isDataLink,
  isInvoiceType,
  isDepartments
} from '@ekuaibao/lib/lib/propertySetIs'
import styles from './column.module.less'
import { getStaffShowByConfig } from '../utilFn'
import { isZhongYing } from '@ekuaibao/lib/lib/checkZY'
import { Fetch } from '@ekuaibao/fetch'
export const SwitcherData = [
  { label: i18n.get('是'), value: true },
  { label: i18n.get('否'), value: false }
]

const getState = {
  draft: i18n.get('待提交'), //草稿
  sending: i18n.get('待寄送'), //待寄送
  receiving: i18n.get('待收单') //待收单
}

export function fixEnums(enumSource, filterDataSource) {
  return {
    filterType: 'list',
    filterDataSource: filterDataSource,
    render(text, record) {
      let state = enumSource[text]
      return (
        <span className={styles.urgentWrapper}>
          {record.isUrgent && window.isNewHome && <i className={styles.urgent} />}
          {state ? state : getState[text]}
        </span>
      )
    }
  }
}

export function fixPaymentAccountId() {
  return {
    render(line) {
      return line && line.detail ? (
        <Tooltip placement="top" title={line.detail.name}>
          <span className="text-nowrap-ellipsis ws-nowrap">{line.detail.name}</span>
        </Tooltip>
      ) : (
        <NullCell />
      )
    }
  }
}

export function fixPaymentChannel(filterDataSource, dynamicChannelMap) {
  return {
    filterType: 'list',
    filterDataSource: filterDataSource,
    render(paymentChannel) {
      if (!paymentChannel || !dynamicChannelMap[paymentChannel]?.name) {
        return <NullCell />
      }
      return <span>{dynamicChannelMap[paymentChannel]?.name}</span>
    }
  }
}

export function fixInvoiceEnums(enumSource, filterDataSource) {
  return {
    filterType: 'list',
    filterDataSource: filterDataSource,
    render(line) {
      return line ? <span>{i18n.get(enumSource[line.id])}</span> : <NullCell />
    }
  }
}

export function fixBaseData(property) {
  return {
    render(text, record) {
      if (!text) {
        return <NullCell />
      }
      if (typeof text === 'string') {
        return <span>{text}</span>
      }
      const { name, code } = text
      return (
        <span>
          {name}({code})
        </span>
      )
    }
  }
}

function fixReceiptState() {
  return {
    filterType: 'list',
    filterDataSource: [...Object.values(RECEIPT_STATE())],
    render(value, record) {
      if (RECEIPT_STATE()[value]) {
        return i18n.get(RECEIPT_STATE()[value].label)
      } else {
        return '-'
      }
    }
  }
}

function fixPrintState() {
  return {
    filterType: 'list',
    filterDataSource: [...Object.values(PRINT_STATE())],
    render(value, record) {
      if (PRINT_STATE()[value]) {
        return i18n.get(PRINT_STATE()[value].label)
      } else {
        return '-'
      }
    }
  }
}

export function fixCity() {
  return {
    render(text, record) {
      if (!text) {
        return <NullCell />
      }

      const arr = JSON.parse(text)
      const label = arr.map(line => line.label).join(',')
      return <span>{label}</span>
    }
  }
}

function renderPayDetail(doc = {}) {
  return (
    <div className="ekb-account-info-popover">
      <div className="header">
        {i18n.get(doc.type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户'))} |{' '}
        {doc.accountName || doc.name}
      </div>
      <div className="body">
        <div className="line1">
          <img className="stand-20-icon" src={doc.icon} />
          {doc.bank || doc.unionBank}
        </div>
        <div className="line2 text-nowrap-ellipsis">{doc.cardNo || doc.accountNo}</div>
        <div className="line3">{doc.branch}</div>
      </div>
    </div>
  )
}

export function fixPayeeInfo() {
  return {
    render(payeeInfo, record) {
      if (!payeeInfo) {
        const multiplePayeesMode =
          get(record, 'flowId.form.multiplePayeesMode') || get(record, 'form.multiplePayeesMode')
        if (multiplePayeesMode) {
          return i18n.get('多收款人')
        }
        return <NullCell />
      }

      return (
        <Popover content={renderPayDetail.call(this, payeeInfo)}>
          <div className="account">
            <div>{payeeInfo.accountName || payeeInfo.name}</div>
            <div className="color-gray">
              {i18n.get(payeeInfo.type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户'))}
            </div>
          </div>
        </Popover>
      )
    }
  }
}

export function fixMoney({ valueSize = 12, canFilter = true }) {
  return {
    filterType: canFilter ? 'money' : undefined,
    sorter: canFilter,
    render(money, record) {
      if (!money && money !== 0) {
        return <NullCell />
      }
      if (window.IS_SMG || isZhongYing(Fetch.ekbCorpId)) {
        const isSmgMoney = Number(money.standard) >= 99999999999.0 //SMG（11位9）
        const isZYMoney = Number(money.standard) >= 9999999999.0 //中影企业（10位9）
        if (isSmgMoney || isZYMoney) {
          return '-'
        } else {
          // return <Money currencySize={12} valueSize={valueSize} color="#333333" value={money} />
          return <Money value={money} style={{ textAlign: 'right' }} styleTwo={{ fontSize: '12px' }} />
        }
      }
      return <Money value={money} style={{ textAlign: 'right' }} styleTwo={{ fontSize: '12px' }} />
      // return <Money currencySize={12} valueSize={valueSize} color="#333333" value={money} onlyForeign={(money && money.foreignStrCode)} isShowForeign={(money && money.foreignStrCode)}  showSymbol={false} showStrCode  />
    }
  }
}

export function fixPayDate() {
  return {
    render(value, record) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
    }
  }
}

export function fixRepaymentDate() {
  return {
    render(value, record) {
      let newValue = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
      return newValue === 'Invalid date' ? i18n.get('无还款日期') : newValue
    }
  }
}

export function fixSwitch(filterDataSource) {
  return {
    filterType: 'list',
    filterDataSource: filterDataSource,
    lookup: {
      dataSource: filterDataSource,
      displayExpr: 'label',
      valueExpr: 'value'
    },
    render(value) {
      let title = ''
      if (value === undefined || value === null) {
        title = '-'
      } else {
        title = i18n.get(value ? i18n.get('是') : i18n.get('否'))
      }
      return <div>{title}</div>
    }
  }
}

export function fixDataLink() {
  return {
    render(value) {
      let title = ''
      if (value === undefined || value === null) {
        title = '-'
      } else {
        const { form } = value
        let refCount = 0
        Object.keys(form).forEach(key => {
          if (key.endsWith('_name')) {
            title = form[key] + title
            refCount++
          } else if (key.endsWith('_code')) {
            title = `${title}(${form[key]})`
            refCount++
          }
          if (refCount === 2) return title
        })
      }
      return <div>{title}</div>
    }
  }
}

export function fixInvoiceType() {
  const filterDataSource = Object.keys(INVOICE_TYPE_BASE_ENUM()).map(key => {
    return { label: INVOICE_TYPE_BASE_ENUM()[key], value: key }
  })
  return fixInvoiceEnums(INVOICE_TYPE_BASE_ENUM(), filterDataSource)
}

export function fixLastPrintTime() {
  return {
    render(value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'
    }
  }
}

export function fixSubmitter() {
  return {
    render(value, record) {
      const { flowId, form } = record
      let ownerId = ''
      let submitterId = ''
      let owner = ''
      let submitter = ''
      if (flowId) {
        ownerId = get(flowId, 'ownerId.id', '')
        submitterId = get(flowId, 'form.submitterId.id', '')
        owner = getStaffShowByConfig(get(flowId, 'ownerId'))
        submitter = getStaffShowByConfig(get(flowId, 'form.submitterId'))
      } else {
        ownerId = get(record, 'ownerId.id', '')
        submitterId = get(form, 'submitterId.id', '')
        owner = getStaffShowByConfig(get(record, 'ownerId'))
        submitter = getStaffShowByConfig(get(form, 'submitterId'))
      }

      let str =
        ownerId === submitterId
          ? getStaffShowByConfig(value)
          : i18n.get('submitted-by', {
              name: submitter,
              ownerName: owner
            })
      return <div>{str}</div>
    }
  }
}

export function fixStaffShow() {
  return {
    render(value) {
      return value ? getStaffShowByConfig(value) : '-'
    }
  }
}
const FLOW_REVIEW_STATUS = () => ({
  ALL: i18n.get('全部收到'),
  NONE: i18n.get('暂未收到'),
  PART: i18n.get('部分收到')
})

const FLOW_REVIEW_STATUS_COLOR = {
  ALL: { color: '#18B694' },
  NONE: { color: '#F4526B' },
  PART: { color: '#FAA442' }
}

const FLOW_REVIEW_FILTER_DATA = () => {
  return [
    { label: i18n.get('暂未收到'), value: 'NONE' },
    { label: i18n.get('部分收到'), value: 'PART' },
    { label: i18n.get('全部收到'), value: 'ALL' }
  ]
}

export function fixReviewStatus() {
  return {
    filterType: 'list',
    filterDataSource: FLOW_REVIEW_FILTER_DATA(),
    render(value, line) {
      return <div style={FLOW_REVIEW_STATUS_COLOR[value]}>{FLOW_REVIEW_STATUS()[value] || '-'}</div>
    }
  }
}
const findDeptPath = api.invokeServiceAsLazyValue('@contacts:import:findDeptPath')

export function fixDepartment() {
  const deptMap = api.getState('@common.department').mapData
  return {
    render: (item, row) => {
      if (!item) {
        return <NullCell />
      }
      let depts = deptMap[item.id]
      let pathName = findDeptPath.value(depts, deptMap)
      let name = item.name
      if (typeof item === 'string') {
        name = item
      }
      return (
        <Tooltip placement="topLeft" title={pathName}>
          <div className="td-text-wrap">{name}</div>
        </Tooltip>
      )
    }
  }
}

export function fixExpenseLinks() {
  return {
    render(value) {
      if (!value) {
        return '-'
      }
      if (!Array.isArray(value)) {
        value = [value]
      }
      return (
        <div>
          {value.map(v => (
            <div>{(v && v.name) || '-'}</div>
          ))}
        </div>
      )
    }
  }
}

export function fixPartialPayState() {
  return {
    filterType: 'list',
    filterDataSource: [
      {
        value: 'PartialPaying',
        label: '部分支付中'
      },
      {
        value: 'PartialPaied',
        label: '部分支付成功'
      }
    ]
  }
}

const billState = (stateMap, Express, scenesType) => {
  const oo = {}
  //TODO 让佟P泽该需求,去掉俩 if
  Object.keys(stateMap).forEach(key => {
    // 我的单据表格模式：
    if (scenesType === 'MyBill') {
      const myBillBlackList = ['PROCESS', 'nullify', 'failure', 'paid', 'archived']
      if (myBillBlackList.includes(key)) return null
    }

    if (key === 'paid') {
      oo[key] = i18n.get('已完成(待确认)')
    } else if (key === 'archived') {
      oo[key] = i18n.get('已完成(已确认)')
    } else if ((key === 'draft' || key === 'pending') && scenesType !== 'MyBill') {
      // 去掉列选中的待提交和提交中
      return null
    } else if (!Express && (key === 'sending' || key === 'receiving')) {
      return null
    } else {
      oo[key] = stateMap[key].text
    }
  })
  return oo
}

export function parseMeta2ColumSpecials(name, specialColumn = {}) {
  if (specialColumn[name]) {
    return specialColumn[name]
  }
}

const EXPENSE_STATUS = {
  PROCESSING: i18n.get('报销中'),
  WAITING: i18n.get('待报销'),
  PROCESSED: i18n.get('已报销')
}

const CAR_TYPE = {
  TAXI: i18n.get('出租'),
  SPECIAL: i18n.get('专车'),
  FAST: i18n.get('快车'),
  BEHALF: i18n.get('代驾')
}

const EXPENSE_STATUS_STATUS_FILTERS_DATASOURCE = Object.keys(EXPENSE_STATUS).map(key => ({
  label: EXPENSE_STATUS[key],
  value: key
}))

const CAR_TYPE_STATUS_FILTERS_DATASOURCE = Object.keys(CAR_TYPE).map(key => ({
  label: CAR_TYPE[key],
  value: key
}))

export function parseMeta2ColumnOthers(name, property, dynamicChannelMap = {}, Express, scenesType) {
  if (name === 'expenseStatus') {
    return fixEnums(EXPENSE_STATUS, EXPENSE_STATUS_STATUS_FILTERS_DATASOURCE)
  }

  if (name === 'useCarType') {
    return fixEnums(CAR_TYPE, CAR_TYPE_STATUS_FILTERS_DATASOURCE)
  }

  if (name === 'state') {
    const stateMapRes = stateMap()
    if (!api.getState()['@common'].powers.Express) {
      // 如果没有寄送单据管理charge，去除该选项
      delete stateMapRes.receivingExcep
    }
    let BILL_STATUS = billState(stateMapRes, Express, scenesType)
    return fixEnums(
      BILL_STATUS,
      Object.keys(BILL_STATUS).map(key => ({ label: BILL_STATUS[key], value: key }))
    )
  }

  if (name === 'paymentChannel') {
    const channelList = Object.values(dynamicChannelMap) || []
    const arr = filterChannels(channelList)
    return fixPaymentChannel(arr, dynamicChannelMap)
  }

  if (name === 'paymentAccountId') {
    return fixPaymentAccountId()
  }

  if (name === 'payDate' || name === 'timeToEnterPendingPayment' || name === 'preNodeApprovedTime') {
    return fixPayDate()
  }

  if (name === 'repaymentDate') {
    return fixRepaymentDate()
  }

  if (name === 'receiptState') {
    return fixReceiptState()
  }

  if (name === 'printState') {
    return fixPrintState()
  }

  if (isBaseData(property)) {
    return fixBaseData(property)
  }

  if (isCity(property)) {
    return fixCity()
  }

  if (isPayeeInfos(property)) {
    return fixPayeeInfo()
  }
  if (isBaseData(property)) {
    return fixBaseData()
  }

  if (isMoney(property)) {
    return fixMoney({ valueSize: 12 })
  }

  if (isSwitch(property)) {
    return fixSwitch(SwitcherData)
  }

  if (isDataLink(property)) {
    return fixDataLink()
  }

  if (isInvoiceType(property)) {
    return fixInvoiceType()
  }

  if (isDepartments(property)) {
    return fixDepartment()
  }

  if (name === 'lastPrintTime') {
    return fixLastPrintTime()
  }

  if (name === 'submitterId') {
    return fixSubmitter()
  }
  if (name === 'reviewStatus') {
    return fixReviewStatus()
  }
  if (name === 'expenseLinks') {
    return fixExpenseLinks()
  }
  if (name === 'partialPayState') {
    return fixPartialPayState()
  }
  if (isStaffs(property)) {
    return fixStaffShow()
  }
}
