/**************************************************
 * Created by nanyuantingfeng on 10/07/2017 15:27.
 **************************************************/
import { isObject, isString, isArray } from '@ekuaibao/helpers'
import { cloneDeep, get, isUndefined } from 'lodash'
import { getV } from '@ekuaibao/lib/lib/help'

export function fnParseDetails(details = [], baseDataProperties, multiplePayeesMode) {
  let arr = []
  details.forEach(fee => {
    let isTripType = Boolean(fee.tripTypeId)
    let { feeTypeId, feeTypeForm, specificationId, tripTypeId, tripForm } = fee
    let feeTypeFormCopy = isTripType ? cloneDeep(tripForm) : cloneDeep(feeTypeForm)
    if (feeTypeFormCopy.invoiceForm) {
      feeTypeFormCopy.invoiceForm.invoiceCorporation && delete feeTypeFormCopy.invoiceForm.invoiceCorporation
    }
    if (feeTypeFormCopy.orders) {
      feeTypeFormCopy.orders = feeTypeFormCopy.orders.map(line => line.id || line)
      feeTypeFormCopy.ordersData && delete feeTypeFormCopy.ordersData
      feeTypeFormCopy.thirdPartyOrders && delete feeTypeFormCopy.thirdPartyOrders
    }
    const feeDetailPayeeId = specificationId?.components?.find(cp => cp?.field === 'feeDetailPayeeId')
    // 没开启多收款人，把从随手记带过来的收款信息去掉
    if (!multiplePayeesMode && !feeDetailPayeeId) {
      delete feeTypeFormCopy.feeDetailPayeeId
    }

    getAttachmentsBySpecification(feeTypeFormCopy, specificationId)
    fnFormatApportion(feeTypeFormCopy)
    let refField = baseDataProperties.filter(v => v.dataType.type === 'ref').map(v => v.name)
    let dateField = baseDataProperties.filter(v => v.dataType.type === 'date').map(v => v.name)
    for (let key in feeTypeFormCopy) {
      if (key === 'linkDetailEntities') {
        const linkDetailEntities = feeTypeFormCopy[key]
        let list = []
        if (linkDetailEntities && linkDetailEntities.length && linkDetailEntities[0].linkDetailEntityId) {
          linkDetailEntities.map(v => {
            v.linkDetailEntityId = isObject(v.linkDetailEntityId) ? v.linkDetailEntityId.id : v.linkDetailEntityId
            return { ...v }
          })
          list = linkDetailEntities
        } else {
          linkDetailEntities &&
            linkDetailEntities.length &&
            linkDetailEntities.forEach(line => {
              const dataList = get(line, 'dataList', []) || []
              dataList.forEach(item => {
                const amount = get(item, 'modifyValue')
                list.push({
                  amount,
                  linkDetailEntityId: item.id
                })
              })
            })
        }
        feeTypeFormCopy.linkDetailEntities = list
      }

      if (key === 'invoiceForm') {
        let { invoices, fileList, attachments, invoiceCorporationId } = feeTypeFormCopy[key]
        if (invoices && invoices.length > 0) {
          let invoiceData = []
          const list = ['taxAmount', 'taxRate', 'approveAmount', 'comment']
          invoices.forEach(item => {
            let ids = []
            item.details && !!item.details.length && item.details.forEach(v => ids.push(v.id))
            const invoiceMap = { itemIds: item.itemIds || ids, invoiceId: item.invoiceId || item.master.id }
            list.forEach(oo => {
              if (item.hasOwnProperty(oo)) {
                invoiceMap[oo] = item[oo]
              }
            })
            invoiceData.push(invoiceMap)
            feeTypeFormCopy[key].invoices = invoiceData
          })
        }

        if (attachments) {
          let attachmentsList = fileList || attachments
          let attachmentData = attachmentsList.map(line => {
            return {
              key: line.key,
              fileName: line.fileName,
              fileId: line.id || get(line, 'fileId.id', '') || line.fileId
            }
          })
          feeTypeFormCopy[key].attachments = attachmentData
        }
        if (invoiceCorporationId) {
          feeTypeFormCopy[key].invoiceCorporationId = invoiceCorporationId
        }
      }
      if (!!~refField.indexOf(key) && feeTypeFormCopy[key] instanceof Object) {
        feeTypeFormCopy[key] = feeTypeFormCopy[key].id
      }

      if (!!~dateField.indexOf(key) && isString(feeTypeFormCopy[key])) {
        feeTypeFormCopy[key] = parseFloat(feeTypeFormCopy[key])
      }

      const listValue = feeTypeFormCopy[key]
      if (isArray(listValue) && !isDataLinkEdits(listValue) && key !== 'travelPlanning') {
        feeTypeFormCopy[key] = parseListValue(listValue, key)
      }
      if (isDataLinkEdits(feeTypeFormCopy[key])) {
        feeTypeFormCopy[key] = resetDataLinEdits(feeTypeFormCopy[key])
      }
    }

    isTripType
      ? arr.push({
          tripTypeId: isString(tripTypeId) ? tripTypeId : get(tripTypeId, 'id', ''),
          specificationId: isString(specificationId) ? specificationId : get(specificationId, 'id', ''),
          tripForm: feeTypeFormCopy
        })
      : arr.push({
          feeTypeId: isString(feeTypeId) ? feeTypeId : get(feeTypeId, 'id', ''),
          specificationId: isString(specificationId) ? specificationId : get(specificationId, 'id', ''),
          feeTypeForm: feeTypeFormCopy
        })
  })
  return arr
}

function fnFormatAttachment(attachments = []) {
  if (attachments == void 0) return []
  return attachments
    .filter(vv => !!vv)
    .map(v => {
      let { key, fileName, id, fileId } = v
      if (fileId && typeof fileId === 'object') {
        let { id } = v.fileId || v.id
        return { key, fileName, fileId: id }
      }
      if (id && typeof id === 'object') {
        id = id.id
      }

      return { key, fileName, fileId: id || fileId }
    })
}

function fnFormatApportion(feeTypeFormCopy = {}) {
  let { apportions } = feeTypeFormCopy
  if (!apportions) return
  let apportionFormCopy = apportions.map(line => {
    let obj = {}
    let { apportionForm, specificationId } = line
    for (let key in apportionForm) {
      if (apportionForm[key] instanceof Object && key !== 'apportionMoney' && !key.includes('otherApportionMoney')) {
        obj[key] = apportionForm[key].id
      } else {
        obj[key] = apportionForm[key]
      }
    }

    return { apportionForm: { ...obj }, specificationId: specificationId?.id }
  })
  feeTypeFormCopy['apportions'] = apportionFormCopy
}

function getFlowPlanConfigId(specification) {
  let configs = specification.configs || []
  let flowConfig = configs.find(v => v.ability === 'flow') || {}
  return flowConfig.flowPlanConfigId
}

export function getPayConfig(specification) {
  let configs = specification?.configs || []
  return configs.find(v => v.ability === 'pay') || {}
}

export function fnParseFormValue(form = {}, specification, baseDataProperties, dataSource) {
  let formCopy = cloneDeep(form)
  const { multiplePayeesMode } = formCopy
  //开启多收款人需要改造的数据
  if (multiplePayeesMode) {
    // 多收款人模式下删掉 payeeId 字段
    delete formCopy.payeeId
  }
  if (dataSource?.form?.systemGeneration !== undefined) {
    formCopy.systemGeneration = dataSource?.form?.systemGeneration
  }
  if (dataSource?.form?.systemGenerationSettle !== undefined) {
    formCopy.systemGenerationSettle = dataSource?.form?.systemGenerationSettle
  }
  if (dataSource?.form?.subsidyGeneration !== undefined) {
    formCopy.subsidyGeneration = dataSource?.form?.subsidyGeneration
  }
  let refField = baseDataProperties.filter(v => v.dataType.type === 'ref').map(v => v.name)
  let enumField = baseDataProperties.filter(v => v.dataType.type === 'Enum').map(v => v.name)
  let textField = baseDataProperties.filter(v => v.dataType.type === 'text').map(v => v.name)
  let dateField = baseDataProperties.filter(v => v.dataType.type === 'date').map(v => v.name)
  let components = specification.components || []
  let datalinkedits = components.filter(v => v.type === 'dataLinkEdits').map(v => v.field)
  Object.keys(formCopy).forEach(key => {
    if (!!~refField.indexOf(key) && formCopy[key] instanceof Object) {
      formCopy[key] = formCopy[key].id
    }

    if (!!~enumField.indexOf(key) && formCopy[key] instanceof Object) {
      formCopy[key] = formCopy[key].id
    }

    if (!!~textField.indexOf(key) && formCopy[key] && !formCopy[key].trim().length) {
      formCopy[key] = ''
    }

    if (!!~datalinkedits.indexOf(key) && formCopy[key] && formCopy[key].length) {
      formCopy[key] = resetDataLinEdits(formCopy[key])
    }
    if (!!~dateField.indexOf(key) && isString(formCopy[key])) {
      formCopy[key] = parseFloat(formCopy[key])
    }

    const listValue = formCopy[key]
    if (isArray(listValue) && !~datalinkedits.indexOf(key) && key !== 'travelPlanning') {
      formCopy[key] = parseListValue(listValue, key)
    }
  })

  formCopy.specificationId = specification.id
  handleMoney(formCopy, specification)

  getAttachmentsBySpecification(formCopy, specification)
  if (formCopy.details || formCopy.trips) {
    let details = formCopy.details || formCopy.trips
    formCopy[formCopy.details ? 'details' : 'trips'] = fnParseDetails(details, baseDataProperties, multiplePayeesMode)
  }

  return formCopy
}

function isDataLinkEdits(value) {
  const type = typeof value
  return (
    value !== null &&
    value !== undefined &&
    type === 'object' &&
    typeof value.length === 'number' &&
    value.length > 0 &&
    value[0]?.dataLinkForm
  )
}
function resetDataLinEdits(arr) {
  let ns = cloneDeep(arr || [])
  ns.forEach(item => {
    let form = item.dataLinkForm
    for (let it in form) {
      const value = form[it]
      if (value && typeof value === 'object' && value.id) {
        form[it] = value.id
      }
      if (isArray(value)) {
        if (value.length > 0 && value[0] && value[0].fileName) {
          form[it] = fnFormatAttachment(value)
        } else {
          let ids = []
          value.forEach(line => {
            if (line && typeof line === 'object' && line.id) {
              ids.push(line.id)
            } else if (line && typeof line === 'string') {
              ids.push(line)
            }
          })
          form[it] = ids
        }
      }
      if (form[it] && Array.isArray(form[it]) && form[it].length > 0 && form[it][0].id) {
        form[it] = form[it].map(i => i.id)
      }
    }
  })
  return ns
}

export function handleMoney(formCopy, specification) {
  let components = (specification && specification.components) || []
  components.map(line => {
    if (line.type === 'money') {
      let key = line.field
      let standardNumCode = get(formCopy[key], 'standardNumCode.numCode')
      if (standardNumCode) formCopy[key] = { ...formCopy[key], standardNumCode }
    }
  })
}

function getAttachmentsBySpecification(form, specification) {
  const components = getV(specification, 'components', [])
  components.forEach(line => {
    if (line.type === 'attachments') {
      form[line.field] = fnFormatAttachment(form[line.field])
    }
  })
}

function parseListValue(value, key) {
  const ignoreKeys = ['details', 'trips', 'attachments', 'apportions', 'payPlan', 'linkDetailEntities']
  if (ignoreKeys.indexOf(key) > -1) {
    return value
  }
  if (isArray(value)) {
    if (value.find(oo => !!get(oo, 'fileId'))) {
      return value
    } else {
      return value.map(line => (isObject(line) ? line.id : line))
    }
  }
  return value
}

/**********************************
 * @param specification 模板
 * @param globalFields 全局字段列表
 */

import { parseAsMeta } from './parseAsMeta'

export { parseAsMeta }

/**
 * 系统预置的字段的自动计算公式是在调用一次计算接口后才知道的，调完接口后设置对应字段的公式
 * @param result 计算接口返回的结果值数组
 * @param components 当前消费类型模版的组件
 * @returns {Array}
 */
export function presetFormulaValue(result, components, isDetail = false, autoCalFields = {}) {
  let r = []

  if (!components || !result) return components

  const resultMap = {}
  if (isDetail) {
    result = result.filter(elment => elment.dataFrom === 'details')
  } else {
    result = result.filter(elment => elment.dataFrom !== 'details')
  }

  result.forEach(element => {
    if (element.onField) {
      resultMap[element.onField] = element.description
    }
  })

  components.slice().forEach(element => {
    if (!!~Object.keys(resultMap).indexOf(element.field) && element.defaultValue.type !== 'formula') {
      element.defaultValue.formulaValue = resultMap[element.field]
    }

    let { onFields = [] } = autoCalFields
    if (onFields.indexOf(element.field) >= 0) {
      element.editable = false
    }

    r.push(element)
  })

  return r
}

/**********************************
 * @param bill 单据值
 * @param globalFields 全局字段列表
 * 需要一个 { form : { template} }节点, 用specification值join(at globalFields)可获得
 */
export function parseAsFormTemplate(bill, globalFields) {
  let { form } = bill
  let { specificationId } = form
  return parseAsMeta(specificationId, globalFields)
}

export function parseAsReadOnlyFormTemplate(template) {
  return template.filter(line => {
    return !(line.type === 'annotation' && line.onlyEditVisible)
  })
}

/**********************************
 * @param bill 单据值
 * @param bool 是否以 fields 列表为基准
 *
 * 全局字段列表的值都应在请求的时候处理 form 节点中的字段,
 * 如果可json 就将值直接覆盖!(这点很重要, 无论是详情还是
 * 编辑状态,都应展示label 而不是 id)
 * example:
 *  { submitterId : 'lGo5jDy1lQ0c00:05633811631056697'}
 *  ===>
 *  { submitterId : {label : "AAA", id : 'lGo5jDy1lQ0c00:05633811631056697'}}
 *

 */
export function parseAsFormValue(bill = {}, pathMap, bool = false) {
  if (!bill.form) {
    return {}
  }

  let { form } = bill
  let { specificationId, ...value } = form

  if (bool) {
    let oo = {}
    let { components } = specificationId
    let keys = components.map(line => line.field).filter(a => !(a === undefined || a === null))

    keys.forEach(key => {
      oo[key] = value[key]
    })
    value = oo
  }

  let { details } = form

  if (details && details.length) {
    details.forEach((v, idx) => {
      v.idx = idx
    })
  }
  if (value.loanDepartment && pathMap && pathMap.hasOwnProperty(value.loanDepartment.id)) {
    value.loanDepartment.name = pathMap[value.loanDepartment.id]
  }
  if (value.expenseDepartment && pathMap && pathMap.hasOwnProperty(value.expenseDepartment.id)) {
    value.expenseDepartment.name = pathMap[value.expenseDepartment.id]
  }
  if (value.multiplePayeesMode) {
    value.components = specificationId.components
  }
  return value
}

export function parseSpecificationAsSelectDataSource(array = []) {
  return array.map(line => {
    let { id } = line
    return { ...line, label: line.name, value: String(id) }
  })
}

//将ref类型的表单值转换成字符串
export function parseFormValueAsParam(form, specificationId, dataSource, baseDataProperties) {
  let formCopy = fnParseFormValue(form, specificationId, baseDataProperties, dataSource)
  let value = { name: 'freeflow.edit', form: formCopy }
  if (dataSource?.id) value.id = dataSource.id
  return value
}

export function formatValue(template, formValue, isFeeType = false, baseDataProperties) {
  const submitObj = {}
  const specificationMap = []
  template?.forEach(item => {
    const key = item?.field
    const hide = item?.hide
    const type = item?.type
    const showInDetails = item?.showInDetails
    const enLabel = item?.enLabel
    const cnLabel = item?.cnLabel
    const label = item?.label
    const obj = {
      field: key,
      label,
      type,
      cnLabel,
      enLabel,
      isHide: hide
    }
    if (!isFeeType && key === 'details') {
      const details = formValue?.[key]
      const originValue = []
      const value = []
      details?.forEach(detail => {
        const { feeTypeForm, specificationId } = detail
        const template = parseAsMeta(specificationId, baseDataProperties)?.filter(field => field?.field)
        const { submitObj, specificationMap } = formatValue(template, feeTypeForm, true, baseDataProperties)
        originValue.push(submitObj)
        value.push(specificationMap)
      })
      submitObj[key] = originValue
      obj.originValue = originValue
      obj.value = value
    } else {
      const data = formValue?.[key]
      if (isObject(data)) {
        if (type === 'money') {
          submitObj[key] = data
          obj.originValue = data
          obj.value = data?.standard
        } else if (type === 'dateRange') {
          submitObj[key] = data
          obj.originValue = data
          obj.value = data
        } else if (type === 'dataLink') {
          const res = data?.data?.dataLink
          submitObj[key] = data?.id
          obj.originValue = data?.id
          obj.entityId = res?.entityId
          obj.code = res?.[`E_${res?.entityId}_code`]
          obj.name = res?.[`E_${res?.entityId}_name`]
          obj.value = res?.[`E_${res?.entityId}_name`]
        } else {
          // 值取 id
          submitObj[key] = data.id
          obj.originValue = data.id
          obj.value = data.name
          if (data?.dimensionId) {
            obj.dimensionId = data?.dimensionId
          }
        }
      } else {
        // 直接取值
        if (!isUndefined(data)) {
          submitObj[key] = data
        }
        obj.originValue = data
        obj.value = data
      }
    }
    specificationMap.push(obj)
  })
  return { submitObj, specificationMap }
}
export function parseSubmitParamAsFormValue(form, specification, dataSource, baseDataProperties) {
  let formCopy = fnParseFormValue(form, specification, baseDataProperties, dataSource)
  let specificationMapTemp = []
  if (dataSource?.openFrom === 'permit-form') {
    const template = parseAsMeta(dataSource?.originSpecification, baseDataProperties)?.filter(field => field?.field)
    const { specificationMap } = formatValue(template, form || {}, false, baseDataProperties)
    specificationMapTemp = specificationMap
  }
  const specificationForm = { ...formCopy }
  delete specificationForm['specificationId']
  let value = {
    form: formCopy,
    formType: dataSource.formType,
    flowPlanConfigId: getFlowPlanConfigId(specification)
  }
  if (dataSource?.openFrom === 'permit-form') {
    value = { ...value, value: form, specificationMap: specificationMapTemp, specificationForm }
  }
  if (dataSource.id) value.id = dataSource.id
  return value
}

export function needUpdateResult(field, autoCalFields = {}) {
  let { fields = [] } = autoCalFields
  return !!~fields.indexOf(field) || (!!~fields.indexOf('feeTypeId') && field === 'details')
}
export function needUpdateCustomizeResult(field, customizeQueryRule = []) {
  const exist = customizeQueryRule.findIndex(r => {
    return r.filed === field
  })
  return !!~exist
}

export function parseFlowRiskV2(riskData) {
  const { singleInvoiceRiskWarning = [], value, invoiceRiskExplainContent } = riskData
  const result = parseFlowRisk(value)
  // 存在发票数据时，需要将对应数据转换到 messageV2 字段提供给对应组件
  if (result?.form?.details && singleInvoiceRiskWarning.length > 0) {
    const riskNameAndInvoiceRiskDataMap = {}
    const riskWarningReasonMap = {}
    singleInvoiceRiskWarning.forEach(item => {
      const { invoiceId, invoiceMsg, riskWarning, pathValueId, riskWarningReason } = item
      riskWarningReason?.forEach(reasonItem => {
        const { invoiceNormDesc } = reasonItem
        riskWarningReasonMap[`${pathValueId}-${invoiceNormDesc}`] = reasonItem
      })
      riskWarning?.forEach?.(name => {
        const list = riskNameAndInvoiceRiskDataMap[name] ?? []
        const relatedFlows = item.relatedFlows?.[name] ?? []
        riskNameAndInvoiceRiskDataMap[name] = list.concat({
          invoiceMsg,
          invoiceId,
          pathValueId,
          invoiceRiskExplainContent:
            riskWarningReasonMap[`${pathValueId}-${name}`]?.invoiceRiskExplainContent ||
            (name === '票据连号' ? invoiceRiskExplainContent ?? '' : ''),
          relatedFlows,
          riskWarningReason
        })
      })
    })

    Object.keys(result.form.details).forEach(key => {
      const detail = result.form.details[key]
      const isInvoiceRiskEmpty = !detail.invoiceForm || detail.invoiceForm.length === 0
      if (isInvoiceRiskEmpty) {
        return
      }
      for (const risk of detail.invoiceForm) {
        const { controlName, pathValueId } = risk
        const name = controlName.slice(0, -1)
        // 目前仅有以下数据存在 messageV2
        // switch (name) {
        //   case '发票疑似重复': {
        //     risk.messagesV2 = (riskNameAndInvoiceRiskDataMap[name] ?? []).filter(item => item.pathValueId === key)
        //     break
        //   }
        //   case '票据连号': {
        //     risk.messagesV2 = (riskNameAndInvoiceRiskDataMap[name] ?? []).filter(item => item.pathValueId === key)
        //     break
        //   }
        // }
        if (riskWarningReasonMap[`${pathValueId}-${name}`]) {
          risk.messagesV2 = (riskNameAndInvoiceRiskDataMap[name] ?? []).filter(item => item.pathValueId === key)
        }
      }
    })
  }

  return result
}

export function parseFlowRisk(value) {
  // todo 预算超标现在仅展示在表头部位，后续后台数据改造，能够将预算超标对应上消费明细的id，需要进一步作处理，将预算超标信息穿透到对应的明细表体
  if (!value || !value.riskWarning) return {}
  let step = 0
  const { riskWarning } = value
  let flowRiskWarnings = {}
  let detailObj = {}
  let apportionsObj = {}
  let tripObj = {}
  flowRiskWarnings.form = {}
  riskWarning.forEach(item => {
    let detailItemInnerObj = {}
    let tripInnerObj = {}
    switch (item.path) {
      case 'FORM':
        let formName = item.controlField ? item.controlField : 'noName' + step
        step++
        flowRiskWarnings.form[formName]
          ? flowRiskWarnings.form[formName].push(item)
          : (flowRiskWarnings.form[formName] = [item])
        break
      case 'FEE_DETAIL':
        let detailName = item.controlField ? item.controlField : 'noName' + step
        step++
        if (detailObj[item.pathValueId]) {
          detailObj[item.pathValueId][detailName]
            ? detailObj[item.pathValueId][detailName].push(item)
            : (detailObj[item.pathValueId][detailName] = [item])
        } else {
          detailItemInnerObj[detailName] = [item]
          detailObj[item.pathValueId] = detailItemInnerObj
        }
        break
      case 'APPORTION':
        let pathValueId = item.pathValueId.split('$ID_')[0]
        let apportionId = item.pathValueId.substring(pathValueId.length + 1)
        if (apportionsObj[pathValueId]) {
          if (apportionsObj[pathValueId][apportionId]) {
            apportionsObj[pathValueId][apportionId][item.controlField]
              ? apportionsObj[pathValueId][apportionId][item.controlField].push(item)
              : (apportionsObj[pathValueId][apportionId][item.controlField] = [item])
          } else {
            apportionsObj[pathValueId][apportionId] = {}
            apportionsObj[pathValueId][apportionId][item.controlField] = [item]
          }
        } else {
          apportionsObj[pathValueId] = {}
          apportionsObj[pathValueId][apportionId] = {}
          apportionsObj[pathValueId][apportionId][item.controlField] = [item]
        }
        break
      case 'TRIP_DETAIL':
        if (tripObj[item.pathValueId]) {
          tripObj[item.pathValueId][item.controlField]
            ? tripObj[item.pathValueId][item.controlField].push(item)
            : (tripObj[item.pathValueId][item.controlField] = [item])
        } else {
          tripInnerObj[item.controlField] = [item]
          tripObj[item.pathValueId] = tripInnerObj
        }
        break
    }
  })

  for (const key in detailObj) {
    if (apportionsObj.hasOwnProperty(key)) {
      detailObj[key].apportions = apportionsObj[key]
      delete apportionsObj[key]
    }
  }

  if (Object.keys(apportionsObj).length) {
    for (let key in apportionsObj) {
      detailObj[key] = { apportions: apportionsObj[key] }
    }
  }

  if (Object.keys(detailObj).length) {
    flowRiskWarnings.form.details = detailObj
  }

  if (Object.keys(tripObj).length) {
    flowRiskWarnings.form.trips = tripObj
  }

  return flowRiskWarnings
}

// flowId 判断当前单据是否存在
export function fnGetDetailsParams(
  amountList,
  baseDataProperties,
  billFormType,
  ownerId,
  flowId,
  details,
  multiplePayeesMode
) {
  const arr = fnParseDetails(amountList, baseDataProperties, multiplePayeesMode)
  const params = []
  arr.forEach(item => {
    const { feeTypeId, specificationId, feeTypeForm } = item
    const detaildId = get(item, 'feeTypeForm.detailId')
    const data = { ...feeTypeForm, feeTypeId, specificationId, stage: billFormType, state: 'draft' }
    const id = flowId && details && details.length ? (detaildId ? detaildId : '') : ''
    params.push({ id, ownerId, data })
  })
  return params
}

/**
 * @description 对 切换/更新模板 自动将E商卡费用移到随手记
 * @param {*} amountList
 * @param {*} baseDataProperties
 * @param {*} billFormType
 * @param {*} ownerId
 * @param {*} flowId
 * @param {*} multiplePayeesMode
 * @returns
 */
export function fnGetDetailsParamsForChangeTemplate(
  amountList,
  baseDataProperties,
  billFormType,
  ownerId,
  flowId,
  multiplePayeesMode
) {
  const arr = fnParseDetails(amountList, baseDataProperties, multiplePayeesMode)
  const params = []
  arr.forEach(item => {
    const { feeTypeId, specificationId, feeTypeForm } = item
    const detaildId = get(item, 'feeTypeForm.detailId')
    const data = { ...feeTypeForm, feeTypeId, specificationId, stage: billFormType, state: 'draft' }
    const id = flowId ? (detaildId ? detaildId : '') : ''
    params.push({ id, ownerId, data })
  })
  return params
}

export function canModifyApproveMoney(specification) {
  const config = specification.configs.find(oo => oo.ability === 'expense' && oo.isTicketReview)
  return config ? config : false
}

export function parseApproveModifyLimitField(currentNode, template) {
  const isLimitFieldRequired = get(currentNode, 'config.isLimitFieldRequired')
  const limitFieldRequireds = get(currentNode, 'config.limitFieldRequireds', [])
  if (isLimitFieldRequired && !!limitFieldRequireds?.length) {
    const limitRequiredFieldMap = limitFieldRequireds.reduce((res, field) => {
      res[field] = field
      return res
    }, {})
    template = template?.map(field => {
      if (limitRequiredFieldMap[field?.name]) {
        field.optional = false
      }
      return field
    })
  }
  return template
}

export function convertArray(ids) {
  if (Array.isArray(ids)) return ids
  if (isString(ids)) return ids?.split(',')
  return []
}
