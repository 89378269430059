/**
 *  Created by daiwenjuan on 2018/4/25 下午5:52.
 */
import { get } from 'lodash'

export function isDisable(props) {
  let { currentNode, field, isModify } = props
  let { name } = field
  let disabled = isModify && !isAllowModifyFiled(currentNode, name)
  if (field.editable === undefined) return false
  return !field.editable ? !field.editable : disabled
}

export function isAllowModifyFiled(currentNode, name) {
  let isLimitFields = get(currentNode, 'config.isLimitFields')
  let modifyFileds = get(currentNode, 'config.modifyFileds', [])
  if (!isLimitFields) return true
  return modifyFileds.indexOf(name) >= 0
}

export function getModifyFileds(props) {
  const { currentNode } = props
  const modifyFileds = get(currentNode, 'config.modifyFileds', [])
  return modifyFileds.length > 0
}

export function isModifyPayPlan(props, name) {
  const { currentNode } = props
  const modifyFileds = get(currentNode, 'config.modifyFileds', [])
  return modifyFileds.length ? !(modifyFileds.indexOf(name) >= 0) : modifyFileds.length
}

export function detailIsDisable(props) {
  let { currentNode, field, isModify } = props
  let { name } = field
  // //TODO fielddMap.editable 是否要判断
  if (isModify) {
    return !isAllowModifyFiled(currentNode, name)
  }
  return false
}
