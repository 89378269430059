import loadable from '@loadable/component';
import { ENUM_TYPES } from './consts';
import { includes } from 'lodash';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var Attachment = loadableWithDescriptor(function () { return import('./dynamic/Attachment.readonly'); }, {
    type: 'attachments'
});
var Details = loadableWithDescriptor(function () { return import('./dynamic/Details.readonly'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return type === 'requisitionDetails' || type === 'details';
    }
});
var Money = loadableWithDescriptor(function () { return import('./dynamic/Money.readonly'); }, {
    type: 'money'
});
var PayeeInfo = loadableWithDescriptor(function () { return import('./dynamic/PayeeInfo.readonly'); }, {
    type: 'payeeInfo'
});
var Ref = loadableWithDescriptor(function () { return import('./dynamic/Ref.readonly'); }, {
    test: function (_a) {
        var type = _a.type;
        return type.startsWith('ref') && type !== 'ref:organization.Staff' && !includes(ENUM_TYPES, type) && type !== 'ref:basedata.Enum.currency';
    }
});
var RefStaff = loadableWithDescriptor(function () { return import('./dynamic/RefStaff.readonly'); }, {
    type: 'ref:organization.Staff'
});
var Date = loadableWithDescriptor(function () { return import('./dynamic/Date.readonly'); }, {
    type: 'date'
});
var Number = loadableWithDescriptor(function () { return import('./dynamic/Number.readonly'); }, {
    type: 'number'
});
var DateRange = loadableWithDescriptor(function () { return import('./dynamic/DateRange.readonly'); }, {
    type: 'dateRange'
});
var Switcher = loadableWithDescriptor(function () { return import('./dynamic/Switcher.readonly'); }, {
    type: 'switcher'
});
var ApportionReadonly = loadableWithDescriptor(function () { return import('./dynamic/Apportion.readonly'); }, {
    type: 'apportions'
});
var DataLink = loadableWithDescriptor(function () { return import('./dynamic/DataLink.readonly'); }, {
    type: 'dataLink'
});
var InvoiceSelect = loadableWithDescriptor(function () { return import('./dynamic/InvoiceSelect.readonly'); }, {
    type: 'invoice'
});
var Label = loadableWithDescriptor(function () { return import('./dynamic/Label.readonly'); }, {
    test: function (_a) {
        var type = _a.type;
        return !!~['specification', 'text', 'textarea', 'list'].indexOf(type);
    }
});
var Separator = loadableWithDescriptor(function () { return import('./dynamic/Separator.readonly'); }, {
    type: 'separator'
});
var Annotation = loadableWithDescriptor(function () { return import('./dynamic/Annotation'); }, {
    type: 'annotation'
});
var City = loadableWithDescriptor(function () { return import('./dynamic/City.readonly'); }, {
    type: 'city'
});
var RefEnum = loadableWithDescriptor(function () { return import('./dynamic/RefEnum.readonly'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return includes(ENUM_TYPES, type);
    }
});
var LinkRequisitionInfoReadonly = loadableWithDescriptor(function () { return import('./dynamic/LinkRequisitionInfo.readonly'); }, {
    type: 'linkRequisitionInfo'
});
var ExpenseLinkReadonly = loadableWithDescriptor(function () { return import('./dynamic/ExpenseLink.readonly'); }, {
    test: function (_a) {
        var type = _a.type;
        return type === 'expenseLink' || type === 'expenseLinks';
    }
});
var SelectSearch = loadableWithDescriptor(function () { return import('./dynamic/SelectSearch.readonly'); }, {
    type: 'select_search'
});
var Trips = loadableWithDescriptor(function () { return import('./dynamic/Trips.readonly'); }, { type: 'trips' });
var VPhoto = loadableWithDescriptor(function () { return import('./dynamic/VPhoto.readonly'); }, {
    name: 'vphoto_order'
});
var MutilStaff = loadableWithDescriptor(function () { return import('./dynamic/MutilStaff.readonly'); }, {
    type: 'list:ref:organization.Staff'
});
var DataLinkEdit = loadableWithDescriptor(function () { return import('./dynamic/dataLinkEdit/DataLinkEdit.readonly'); }, {
    test: function (field) {
        var type = field.type, referenceData = field.referenceData;
        return type === 'dataLinkEdits' && referenceData.type !== 'TRIP';
    }
});
var DataLinkList = loadableWithDescriptor(function () { return import('./dynamic/DataLinkList.readonly'); }, {
    type: 'dataLinks'
});
var RelatedDetails = loadableWithDescriptor(function () { return import('./dynamic/RelatedDetails.readonly'); }, {
    type: 'linkDetailEntities'
});
var TripDataLink = loadableWithDescriptor(function () { return import('./dynamic/dataLinkEdit/TripDataLink.readonly'); }, {
    test: function (field) {
        var type = field.type, referenceData = field.referenceData;
        return type === 'dataLinkEdits' && referenceData.type === 'TRIP';
    }
});
var MutilDimensionList = loadableWithDescriptor(function () { return import('./dynamic/MutilDimensionList.readonly'); }, {
    test: function (_a) {
        var _b = _a.type, type = _b === void 0 ? '' : _b;
        return type.startsWith('list:ref:basedata.Dimension');
    }
});
var SplitCalculation = loadableWithDescriptor(function () { return import('./dynamic/SplitCalculation.readonly'); }, {
    type: 'splitCalculation'
});
var Unknown = loadableWithDescriptor(function () { return import('./internal/Unknown'); }, { type: 'unknown' });
var InterConnection = loadableWithDescriptor(function () { return import('./dynamic/interConnection/index.readonly'); }, {
    test: function (field) {
        var type = field.type;
        return type === 'engineConnect';
    }
});
var MutilPayeeInfo = loadableWithDescriptor(function () { return import('./dynamic/MutilPayeeInfo.readonly'); }, {
    type: 'list:ref:pay.PayeeInfo'
});
var RadioGroup = loadableWithDescriptor(function () { return import('./dynamic/RadioGroupSupplier.readonly'); }, {
    type: 'radio-group-supplier'
});
var SourceType = loadableWithDescriptor(function () { return import('./dynamic/SourceType.readonly'); }, {
    name: 'sourceType'
});
var paymentReadonly = loadableWithDescriptor(function () { return import('./dynamic/payment.readonly'); }, {
    name: 'payment'
});
var supplierTilte = loadableWithDescriptor(function () { return import('./dynamic/SupplierTitle'); }, {
    type: 'supplierTitle'
});
var Subsidy = loadableWithDescriptor(function () { return import('./dynamic/Subsidy'); }, {
    type: 'subsidy'
});
var CheckingBillForm = loadableWithDescriptor(function () { return import('./dynamic/CheckingBillForm'); }, {
    name: 'checkingBillForm'
});
var Association = loadableWithDescriptor(function () { return import('./dynamic/Association.readonly'); }, {
    name: 'isOpenAssociation'
});
var BlockUI = loadableWithDescriptor(function () { return import('./dynamic/BlockUI.readonly'); }, {
    type: 'engineBlockUI'
});
var Currency = loadableWithDescriptor(function () { return import('./dynamic/Currency.readonly'); }, {
    type: 'ref:basedata.Enum.currency'
});
var DataLinkStaff = loadableWithDescriptor(function () { return import('./dynamic/DataLinkStaff'); }, {
    type: 'list:ref:dataLink.Staff'
});
var TravelList = loadableWithDescriptor(function () { return import('./dynamic/TravelList/TravelList.readonly'); }, {
    type: 'travel'
});
var GroupTitle = loadableWithDescriptor(function () { return import('./dynamic/GroupTitle'); }, {
    type: 'group'
});
var FlowLinks = loadableWithDescriptor(function () { return import('./dynamic/FlowLinks/FlowLinks.readonly'); }, {
    type: 'flowLinks'
});
export var readonly = [
    Attachment,
    Date,
    Ref,
    RefStaff,
    Details,
    Money,
    PayeeInfo,
    Label,
    Separator,
    Annotation,
    Number,
    DateRange,
    City,
    LinkRequisitionInfoReadonly,
    ExpenseLinkReadonly,
    RefEnum,
    Switcher,
    ApportionReadonly,
    SelectSearch,
    DataLink,
    InvoiceSelect,
    Trips,
    VPhoto,
    MutilStaff,
    DataLinkEdit,
    DataLinkList,
    RelatedDetails,
    TripDataLink,
    MutilDimensionList,
    SplitCalculation,
    InterConnection,
    MutilPayeeInfo,
    RadioGroup,
    SourceType,
    paymentReadonly,
    supplierTilte,
    Unknown,
    Subsidy,
    Currency,
    CheckingBillForm,
    Association,
    BlockUI,
    DataLinkStaff,
    TravelList,
    FlowLinks,
    GroupTitle
];
