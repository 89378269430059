import React from 'react';
import { Fetch } from '@ekuaibao/fetch';
export var ApportionContext = React.createContext({
    form: undefined
});
var SpecialAllowCancelDependenceUtils = (function () {
    function SpecialAllowCancelDependenceUtils() {
    }
    SpecialAllowCancelDependenceUtils.isRefSpecial = function () {
        var whiteList = [
            'miX3HxrY5i0e90',
            'DTj4_Yucjk_Eyw',
            'ID_3yW65E300bg',
            'GxV3GfnNWF0eWg',
            'gPY3Dl9NBr0oM0',
            'Rlx3kgsCrz00uM',
            '59s8ib_XFc3w00',
            'wkz3LxRHqy02yv',
        ];
        if (Fetch === null || Fetch === void 0 ? void 0 : Fetch.ekbCorpId) {
            return whiteList.includes(Fetch === null || Fetch === void 0 ? void 0 : Fetch.ekbCorpId);
        }
        else if (Fetch === null || Fetch === void 0 ? void 0 : Fetch.corpId) {
            return whiteList.includes(Fetch === null || Fetch === void 0 ? void 0 : Fetch.corpId);
        }
        return false;
    };
    return SpecialAllowCancelDependenceUtils;
}());
export { SpecialAllowCancelDependenceUtils };
