import { __decorate, __extends } from "tslib";
import React from 'react';
import './EditDimensionCell.less';
import { formatDisplayValue } from '../helper/formatTableData';
import { funcMap, getColumnType } from '../helper/tableHelper';
import { observer } from 'mobx-react';
import classnames from 'classnames';
var EditDimensionCell = (function (_super) {
    __extends(EditDimensionCell, _super);
    function EditDimensionCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var _a = _this.props, type = _a.type, entity = _a.entity, payPlanStore = _a.payPlanStore, title = _a.title, dataIndex = _a.dataIndex, flowId = _a.flowId, editable = _a.editable, isModify = _a.isModify, value = _a.value, billSpecification = _a.billSpecification;
            var itemType = getColumnType(entity || type);
            var func = funcMap[itemType];
            var templateid = billSpecification.id;
            var record = _this.props.record;
            return func({ flowId: flowId, isModify: isModify, templateid: templateid, data: value }).then(function (result) {
                if (result && result.id) {
                    var key = record.key;
                    payPlanStore.updateOneObjValue(key, dataIndex, result);
                }
            });
        };
        return _this;
    }
    EditDimensionCell.prototype.render = function () {
        var _a = this.props, value = _a.value, type = _a.type, dataIndex = _a.dataIndex, model = _a.model, editable = _a.editable, isModify = _a.isModify;
        var displayValue = formatDisplayValue(value, type, dataIndex, model);
        var isCredit = model === 'credit' ? (!isModify ? isModify : editable) : isModify;
        var click = isCredit ? this.handleClick : void 0;
        return (React.createElement("div", { className: classnames('edit_dimension_cell_wrapper', { 'hover': isCredit, 'cur-p': isCredit }), onClick: click }, displayValue));
    };
    EditDimensionCell = __decorate([
        observer
    ], EditDimensionCell);
    return EditDimensionCell;
}(React.Component));
export default EditDimensionCell;
