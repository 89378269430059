import { __awaiter, __generator } from "tslib";
import { Fetch } from '@ekuaibao/fetch';
export default initPlatformInfo;
export function initPlatformInfo() {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var platformInfo;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4, Fetch.GET('/api/platformInfo')];
                case 1:
                    platformInfo = _b.sent();
                    window.PLATFORMINFO = platformInfo;
                    window.isZhongDian = (_a = platformInfo === null || platformInfo === void 0 ? void 0 : platformInfo.name) === null || _a === void 0 ? void 0 : _a.includes('zhongdian');
                    return [2];
            }
        });
    });
}
