import { __assign } from "tslib";
import { datafluxRum } from '@cloudcare/browser-rum';
var InitConfig = {
    applicationId: 'appid_web_eECpzrHci5rDPY29C2GM',
    datakitOrigin: location.origin + "/dk/",
    version: process.env.RELEASE_VERSION || '',
    trackInteractions: true,
    traceType: 'ddtrace',
    allowedTracingOrigins: [location.origin]
};
var PlatformList = ['app.ekuaibao.com', 'wx2.ekuaibao.com', 'ekuaibao2297.eapps.dingtalkcloud.com'];
export function initDatafluxRum() {
    var hostname = window.location.hostname;
    var matchTestHost = hostname.match('(.*).ekuaibao.net');
    if (matchTestHost) {
        datafluxRum.init(__assign(__assign({}, InitConfig), { env: matchTestHost[1] }));
    }
    else if (PlatformList.includes(hostname)) {
        datafluxRum.init(__assign(__assign({}, InitConfig), { env: window.__PLANTFORM__ }));
    }
}
