import React from 'react'
import { Input } from 'antd'

class InputSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      key: props.value ? props.value.key : '',
      value: props.value ? props.value.value : ''
    }
    this.handleInputClick = this.handleInputClick.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      key: nextProps.value ? nextProps.value.key : '',
      value: nextProps.value ? nextProps.value.value : ''
    })
  }

  handleInputClick() {
    if (this.props.onSelect) {
      this.props.onSelect(value => {
        this.setState({ ...value }, () => {
          this.props.onChange && this.props.onChange(value)
        })
      })
    }
  }

  render() {
    let { pass = { isPass: false }, placeholder, disabled, noticeDom } = this.props
    return (
      <div className={this.props.className}>
        <Input
          style={{ width: '100%', cursor: 'pointer' }}
          size="large"
          disabled={pass.isPass || disabled}
          value={this.state.value}
          onClick={this.handleInputClick}
          onFocus={e => {
            e.target.blur()
          }}
          placeholder={placeholder}
        />
        {!!noticeDom && noticeDom}
      </div>
    )
  }
}

export default InputSelector
