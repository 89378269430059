import { __awaiter, __generator } from "tslib";
import React from 'react';
import styles from './DataLinkUrlText.module.less';
import { billTypeMap } from '@ekuaibao/lib/lib/enums';
import { app as api } from '@ekuaibao/whispered';
var DataLinkCode = function (sourceId, code) {
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, flow, title, params;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, api.invokeService('@bills:get:flow-info', { id: sourceId, checkPermissions: false })];
                case 1:
                    resp = _a.sent();
                    flow = resp.value;
                    title = "" + i18n.get(billTypeMap()[flow.formType]) + i18n.get('详情');
                    params = {
                        fromDataLink: true,
                        title: title,
                        invokeService: '@bills:get:flow-info',
                        params: { id: flow.id },
                        backlog: { id: -1, flowId: flow },
                        reload: function () {
                        }
                    };
                    api.open('@bills:BillInfoPopup', params);
                    return [2];
            }
        });
    }); };
    return (React.createElement("span", { className: styles['dataLinkUrlText-wrap'], onClick: handleClick }, code));
};
export default DataLinkCode;
