import React, { PureComponent } from 'react'
import { Checkbox } from 'antd'

export default class EKBCheckBox extends PureComponent {
  onChange = e => {
    let { onChange } = this.props
    onChange && onChange(e.target.checked)
  }

  render() {
    const { checked, defaultChecked, disabled } = this.props
    return (
      <div className="input-wrapper">
        <Checkbox disabled={disabled} defaultChecked={defaultChecked} checked={checked} onChange={this.onChange}>
          {this.props.children}
        </Checkbox>
      </div>
    )
  }
}
