/**************************************************
 * Created by nanyuantingfeng on 12/07/2017 16:13.
 **************************************************/
import styles from './FormItem4Readonly.module.less'
import React from 'react'
import { Col, Row } from 'antd'
import EKBIcon from '../../elements/ekbIcon'
import classNames from 'classnames'
import FormItemLabelWrap from './FormItemLabelWrap/FormItemLabelWrap'

//不受布局约束的 field
const Unconstrained = {
  payeeInfo: 'payeeInfo',
  attachments: 'attachments',
  dataLink: 'dataLink',
  expenseLink: 'expenseLink'
}
const includeType = ['expenseLinks', 'expenseLink']
export default function FormItem4Readonly(props) {
  let {
    isFeeType,
    label,
    style,
    labelCol,
    wrapperCol,
    children,
    external,
    isForbid,
    multiplePayeesMode = false,
    field = {},
    flowId,
    payPlanMode,
    noColon,
    labelNoWrap,
    isDetail,
    detailId,
    layout,
    canEditNote,
    offsetWidth,
    noPayInfo,
    isAlign = false
  } = props
  const isVertical = layout === 'vertical'
  const LabelComponent = (
    <FormItemLabelWrap
      external={external}
      isForbid={isForbid}
      noColon={noColon}
      field={field}
      canEditNote={canEditNote}
      flowId={flowId}
      isDetail={isDetail}
      detailId={detailId}
      layout={layout}
      isAlign={isAlign}
    >
      <span className={classNames({ 'flex-center': !isVertical })}>{label}</span>
    </FormItemLabelWrap>
  )
  const formItemWrapClassName = classNames(styles.form_item__content, 'form_item__content_forFix', { [styles.vertical_form_item__content]: !wrapperCol })

  if (labelNoWrap) {
    return (
      <div className={styles.wrapperRow}>
        <div className={classNames(styles.form_item__label, 'form_item__label_forFix')}>{LabelComponent}</div>
        <div className={formItemWrapClassName}>
          {multiplePayeesMode && !isFeeType && field.type === 'payeeInfo'
            ? <MultiplePayeesModeReadonly payPlanMode={payPlanMode} noPayInfo={noPayInfo} />
            : children
          }
        </div>
      </div>
    )
  }

  const tableHeaderMutilPayee = multiplePayeesMode && !isFeeType && field.type === 'payeeInfo'
  const verticalLabelSpan = isVertical ? null : { span: 4 }
  const verticalWrapSpan = isVertical ? null : { span: 20 }
  const labelLayout = tableHeaderMutilPayee || !Unconstrained[field.type] ? { ...labelCol } : verticalLabelSpan
  const wrapLayout = tableHeaderMutilPayee || !Unconstrained[field.type] ? { ...wrapperCol } : verticalWrapSpan
  const targetClassName = wrapLayout ? '' : styles.vertical_form_item__content
  const realNeedWrapLayout = label ? { ...wrapLayout } : {}
  const isFullScreen = !tableHeaderMutilPayee && offsetWidth >= 768
  let inline = false
  if (labelCol && labelCol.span && wrapperCol && wrapperCol.span && wrapperCol.span + labelCol.span <= 24) {
    inline = true
  }
  const { type } = field
  const blockUIstyle = type === 'engineBlockUI' ? styles.form_item__content_blockUI : ''
  const expenseLinks = includeType.includes(field.type) || includeType.includes(field.field)
  const colWrapClassName = classNames(
    styles.form_item__content,
    'form_item__content_forFix',
    targetClassName,
    blockUIstyle
  )
  return (
    <Row className={classNames(!expenseLinks && styles.form_item_4_readonly_wrapper, {[styles.form_item_4_readonly_wrapper_vertical]:isVertical})} style={style}>
      {!!label && (
        <Col
          {...labelLayout}
          className={classNames(styles.form_item__label, 'form_item__label_forFix', expenseLinks && styles['float_none'], {
            [styles['form_item__label_unset']]: field.type !== 'payeeInfo' || tableHeaderMutilPayee || isFullScreen
          })}
        >
          {LabelComponent}
        </Col>
      )}
      <Col {...realNeedWrapLayout} className={colWrapClassName}>
        {tableHeaderMutilPayee
          ? <MultiplePayeesModeReadonly payPlanMode={payPlanMode} noPayInfo={noPayInfo} />
          : children
        }
      </Col>
    </Row>
  )
}

function MultiplePayeesModeReadonly(props) {
  const { payPlanMode, noPayInfo } = props
  if (noPayInfo) {
    return <div>{i18n.get('无')}</div>
  }
  return (
    <div>
      {i18n.get('多收款人模式')}
      <EKBIcon
        name="#EDico-help"
        tooltipTitle={payPlanMode ? i18n.get('请在支付计划中查看收款信息。') : i18n.get('请在消费明细中查看收款信息。')}
        placement="bottom"
        className={styles.help_icon}
      />
    </div>
  )
}