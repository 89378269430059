import { __values } from "tslib";
import './CustomDimensionFilter.less';
import { app as api } from '@ekuaibao/whispered';
export function isValidCustomDimensions(input, isApportionRule) {
    var e_1, _a;
    var _b, _c, _d, _e, _f;
    var map = api.getState()['@custom-dimension'].customDimensionMap || {};
    try {
        for (var input_1 = __values(input), input_1_1 = input_1.next(); !input_1_1.done; input_1_1 = input_1.next()) {
            var item = input_1_1.value;
            var conditions = !item.left ||
                !item.operator ||
                !item.right ||
                !item.right.length ||
                (item.right === null && !map[item.right[0]]);
            if (isApportionRule) {
                conditions =
                    !item.left ||
                        !item.operator ||
                        !item.right ||
                        !((_b = item.right) === null || _b === void 0 ? void 0 : _b.type) ||
                        !((_c = item.right) === null || _c === void 0 ? void 0 : _c.value) ||
                        !((_d = item.right) === null || _d === void 0 ? void 0 : _d.value.length) ||
                        (((_e = item.right) === null || _e === void 0 ? void 0 : _e.value) === null && !map[(_f = item.right) === null || _f === void 0 ? void 0 : _f.value[0]]);
            }
            if (conditions) {
                return false;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (input_1_1 && !input_1_1.done && (_a = input_1.return)) _a.call(input_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return true;
}
export default isValidCustomDimensions;
