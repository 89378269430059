/**
 *  Created by daiwenjuan on 2018/5/14 下午12:16.
 */
import React, { PureComponent } from 'react'

import styles from './index.module.less'
import { Switch } from 'antd'
import classnames from 'classnames'

export default class DataInterConCard extends PureComponent {
  renderAction() {
    let { actionName, onClick } = this.props
    return (
      <div className="edit-btn" onClick={() => onClick && onClick()}>
        {actionName}
      </div>
    )
  }

  renderSwitch() {
    let { isOpen, onChange } = this.props
    return (
      <Switch
        checkedChildren={i18n.get("开")}
        unCheckedChildren={i18n.get("关")}
        defaultChecked={isOpen}
        onChange={e => onChange && onChange(e)}
      />
    )
  }

  renderEditable() {
    return (
      <div className="right">
        {this.renderAction()}
        {this.renderSwitch()}
      </div>
    )
  }

  renderDisable() {
    return this.renderSwitch()
  }

  renderShowAction() {
    return this.renderAction()
  }

  renderRight() {
    let { isShowSwitch = true, isOpen } = this.props
    if (isShowSwitch) {
      if (isOpen) {
        return this.renderEditable()
      }
      return this.renderDisable()
    }
    return this.renderShowAction()
  }

  render() {
    let { name, id, isShowImag = false, isOpen, className = '', iconUrl } = this.props
    const thumbUrl = iconUrl || ''
    let classNames = classnames(styles.cardWrapper, { [styles.disable]: !isOpen && !isShowImag }, className)
    let nameClass = classnames('name', { disableName: !isOpen && !isShowImag })
    return (
      <div className={classNames}>
        <div className="card-content">
          <div className="left">
            {isShowImag && (
              <div className="image">
                <img src={thumbUrl} />
              </div>
            )}
            <div>
              <div className={nameClass}>{name}</div>
              <div className="id">{`ID:${id}`}</div>
            </div>
          </div>
          <div className="right">{this.renderRight()}</div>
        </div>
      </div>
    )
  }
}
