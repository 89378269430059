import { app } from '@ekuaibao/whispered';
app.use(require('../res-codemod').default);
app.use(require('../res_lib_sync').default);
app.use(require('../plugins/bills').default);
app.use(require('../plugins/reports-adv').default);
app.use(require('../plugins/safeSetting').default);
app.use(require('../plugins/customizeCodeRule').default);
app.use(require('../plugins/orderConfirm').default);
app.use(require('../plugins/risk-manage').default);
app.use(require('../plugins/commonLib').default);
