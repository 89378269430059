/**************************************************
 * Created by panwei on 2017/5/22 下午10:30.
 **************************************************/
import React from 'react'
import { TreeSelect } from 'antd'
import styles from './feeType-tree-select.module.less'
const TreeNode = TreeSelect.TreeNode
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import FeetypeRoot from '../images/feetype_root.png'
import FeeTypeIcon from './feetype-icon'
import { cloneDeep, forEach } from 'lodash'
import { fnCompareProps } from '@ekuaibao/lib/lib/lib-util'
import { isHongShanTestingEnterprise } from '@ekuaibao/lib/lib/help'
import { Fetch } from '@ekuaibao/fetch'
import FeetypeRecommend from './FeetypeRecommend'
import { app as api } from '@ekuaibao/whispered'
export default class FeeTypeTreeSelect extends React.PureComponent {
  constructor(props) {
    super(props)
    let fullPath = this.getFullFeeTypePath(props.feeTypes, props.value)
    this.state = {
      checkedKeys: props.checkedKeys || props.value || [],
      fullPath
    }
    this.code = ''
  }

  componentDidMount() {
    const { isRoot, checkedKeys } = this.props
    if (isRoot && !checkedKeys) {
      this.setState({ checkedKeys: ['0'] })
    }
  }

  componentWillReceiveProps(nextProps) {
    let fn = fnCompareProps(this.props, nextProps)
    fn('feeTypes', _ => {
      this.forceUpdate()
    })

    fn('checkedKeys', _ => {
      let fullPath = this.getFullFeeTypePath(nextProps.feeTypes, nextProps.checkedKeys)
      this.setState({ checkedKeys: nextProps.checkedKeys, fullPath })
    })

    fn('value', _ => {
      let fullPath = this.getFullFeeTypePath(nextProps.feeTypes, nextProps.value)
      this.setState({ checkedKeys: nextProps.value, fullPath })
    })
  }

  handleFeeTypeChange = (value, label, extra) => {
    let { disabledCheckedFather, needReturn } = this.props
    if (!value && (disabledCheckedFather || needReturn)) return
    let checkedKeys = value
    let fullPath = this.getFullFeeTypePath(this.props.feeTypes, checkedKeys)
    this.setState({ checkedKeys, fullPath })
    this.props.onChange && this.props.onChange(checkedKeys, label)
  }

  handleRecommendChanged = feeType => {
    const { id, name: label } = feeType
    this.handleFeeTypeChange(id, [label])
  }

  getFullFeeTypePath(list, value) {
    let fullPath = ''
    if (typeof value === 'string') {
      let selectItem = this.getFeeTypeById(list, value)
      if (selectItem) {
        let fullItems = this.getFullPath(list, selectItem).reverse()
        fullItems.push(selectItem.name)
        fullPath = fullItems.join(i18n.get('／'))
        this.code = selectItem.code
      }
    }
    return fullPath
  }

  getFeeTypeById(list = [], id) {
    let selectItem = undefined
    let fn = list => {
      forEach(list, line => {
        if (line.id === id) {
          selectItem = line
        }

        if (line.children && line.children.length > 0) {
          fn(line.children, id)
        }
      })
    }

    fn(list)
    return selectItem
  }

  getFullPath(list, selectedItem, result = []) {
    list.forEach(el => {
      let node = undefined
      if (el.id === selectedItem.parentId) {
        node = el
        result.push(el.name)
      }

      if (node) {
        this.getFullPath(cloneDeep(this.props.feeTypes), node, result)
      } else {
        this.getFullPath(el.children || [], selectedItem, result)
      }
    })
    return result
  }

  getCode = () => {
    const { showCode = false } = this.props
    if (!showCode || !this.code.length) {
      return ''
    }
    return `(${this.code})`
  }

  renderTreeNodeTitle(item, isChildren) {
    return (
      <div
        className="template-feeType-item horizontal"
        value={item}
        style={{
          cursor: isChildren ? (item.active ? 'pointer' : 'not-allowed') : 'not-allowed'
        }}
      >
        <FeeTypeIcon className="w-20 h-20" feetype={item} />
        <span className="feeType-desc vertical ml-10">
          <div className="type-name">
            {item.active ? item.name : item.name + i18n.get('(已停用)')}{' '}
            {item.description && <span>{`(${item.description})`}</span>}
          </div>
        </span>
      </div>
    )
  }

  handleAdd = e => {
    const { id } = this.props
    e.preventDefault()
    // e.stopPropagation()
    this.props.bus.emit('openAddFeetypeModal', { id: id || '' })
  }

  renderTreeNodeAdd() {
    const { label } = this.props
    return (
      <span className="add-feetype" onClick={this.handleAdd}>
        + {i18n.get(label)}
      </span>
    )
  }

  renderTreeNodeRoot() {
    return (
      <div className="default-feetype">
        <FeeTypeIcon
          className="w-20 h-20 feetype-icon"
          feetype={{
            color: '#676f70',
            icon: FeetypeRoot
          }}
        />
        <span className="feeType-desc vertical ml-10">{i18n.get('设置为费用类型大类（无上级）')}</span>
      </div>
    )
  }

  renderTreeNode() {
    let { disabledCheckedFather, isHaveAdd, isRoot, recommends = [], temFeeType, disabledSelectFeeType = [] } = this.props
    const data = this.props.feeTypes
    const { checkedKeys = [] } = this.state
    let isAddRecordmend = false
    const loop = (data, level = 0) => {
      let treeNodeList = []
      if (recommends.length && !isAddRecordmend) {
        const selectedId = Array.isArray(checkedKeys) ? checkedKeys[0] : checkedKeys
        isAddRecordmend = true
        treeNodeList.push(
          <TreeNode
            key={'recommennd'}
            className="panel_tree_node"
            title={
              <FeetypeRecommend
                key={'recommennd'}
                selectedId={selectedId}
                recommends={recommends}
                onChanged={this.handleRecommendChanged}
              />
            }
          />
        )
      }
      if (isHaveAdd && level === 0) {
        treeNodeList.push(
          <TreeNode
            className="tree-node-add tree-node-add-header"
            key="0"
            title={this.renderTreeNodeAdd()}
            disableCheckbox={true}
            selectable={true}
          />
        )
      } else if (isRoot && level === 0) {
        treeNodeList.push(
          <TreeNode
            className="tree-node-add"
            key="0"
            title={this.renderTreeNodeRoot()}
            disableCheckbox={true}
            selectable={true}
            value="0"
            name={i18n.get('设置为费用类型大类（无上级）')}
          />
        )
      }
      data =
        data &&
        data.map(item => {
          item.children = item.children || []
          if (item.children.length > 0) {
            return (
              <TreeNode
                className={`template-field-feeType-node ${
                  !this.props.disabledCheckedFather ? (item.active ? '' : 'can-not-select') : 'can-not-select'
                }`}
                key={item.id}
                value={item.id}
                disableCheckbox={disabledCheckedFather && item.children.length > 0}
                selectable={!disabledCheckedFather}
                title={this.renderTreeNodeTitle(item, !this.props.disabledCheckedFather)}
                name={item.name}
                disabled={disabledSelectFeeType.includes(item.id)}
              >
                {loop(item.children, 1)}
              </TreeNode>
            )
          } else {
            return (
              <TreeNode
                className={`template-field-feeType-node ${item.active ? '' : 'can-not-select'}`}
                key={item.id}
                value={item.id}
                name={item.name}
                title={this.renderTreeNodeTitle(item, true)}
                disabled={!item.active || (temFeeType && item.id === temFeeType.id) || disabledSelectFeeType.includes(item.id)}
              />
            )
          }
        })
      return treeNodeList.concat(data)
    }
    return loop(data)
  }

  getExpandKeys(list, parentId, expanedKeys = []) {
    let isContainsChildren = false
    list.forEach(el => {
      let tempParentId = undefined
      if (el.id === parentId) {
        tempParentId = el.parentId
        expanedKeys.push(el.id)
        if (!isContainsChildren && el.children.length) {
          expanedKeys.push(el.children[0].id)
          isContainsChildren = true
        }
      }
      if (tempParentId) {
        this.getExpandKeys(this.searchList, tempParentId, expanedKeys)
      } else {
        this.getExpandKeys(el.children || [], parentId, expanedKeys)
      }
    })
  }

  fnSearchTree(tree = [], text, expanedKeys = []) {
    tree.forEach(line => {
      if (!!~line.name.indexOf(text) || !!~line.code.indexOf(text)) {
        this.getExpandKeys(this.searchList, line.parentId, expanedKeys)
      }
      if (line.children && line.children.length > 0) {
        this.fnSearchTree(line.children, text, expanedKeys)
      }
    })
    return expanedKeys
  }
  handleTreeData = (dataSource, searchText) => {
    if (!searchText) {
      return dataSource
    }
    let data = []
    const loop = (item1, item2) => {
      let flag = false
      let subflag = false

      if (!!~item1.name.indexOf(searchText)) {
        flag = true
      }

      if (item1.children) {
        item1.children.forEach(subitem1 => {
          const children = subitem1.children
          const hasChild = children && children.length
          let subitem2 = { ...subitem1, children: [], hasChild }
          if (loop(subitem1, subitem2)) {
            subflag = true
            if (!!~subitem1.name.indexOf(searchText)) {
              item2.children.push({ ...subitem1, hasChild })
            } else {
              item2.children.push(subitem2)
            }
          }
        })
      }
      return flag || subflag
    }

    dataSource.forEach(item1 => {
      const hasChild = item1.children && item1.children.length
      const item2 = { ...item1, children: [], hasChild }
      if (!!~item1.name.indexOf(searchText)) {
        data.push(item1)
      } else if (loop(item1, item2)) {
        data.push(item2)
      }
    })

    return data
  }

  onSearch = value => {
    this.searchList = this.props.feeTypes
    if (value.length > 0) {
      let expanedKeys = this.fnSearchTree(this.props.feeTypes, value, expanedKeys)
      this.setState({
        searchText: value,
        expanedKeys
      })
    } else {
      this.setState({
        searchText: value,
        expanedKeys: []
      })
    }
  }

  getTreeMap = tree => {
    const map = {}
    const list = []
    if (tree.length) {
      let fn = children =>
        children.forEach(line => {
          list.push(line)
          line.children && line.children.length && fn(line.children)
        })
      fn(tree)
      list.forEach(o => (map[o.id] = o))
    }
    return map
  }

  renderSearchTreeNode = () => {
    let { disabledCheckedFather, checkedKeys, checkedValue, disabledSelectFeeType = [] } = this.props
    const data = this.handleTreeData(this.props.feeTypes, this.state.searchText)
    const dataMap = this.getTreeMap(data)
    if (checkedKeys && !dataMap[checkedKeys]) {
      data.unshift(checkedValue)
    }
    const loop = data => {
      let treeNodeList = []
      data = data.map(item => {
        item.children = item.children || []
        if (item.children.length > 0) {
          return (
            <TreeNode
              className={`template-field-feeType-node ${
                !this.props.disabledCheckedFather ? (item.active ? '' : 'can-not-select') : 'can-not-select'
              }`}
              key={item.id}
              value={item.id}
              disableCheckbox={disabledCheckedFather && item.children.length > 0}
              selectable={!disabledCheckedFather}
              title={this.renderTreeNodeTitle(item, !this.props.disabledCheckedFather)}
              name={item.name}
              disabled={disabledSelectFeeType.includes(item.id)}
            >
              {loop(item.children)}
            </TreeNode>
          )
        } else {
          return (
            <TreeNode
              className={`template-field-feeType-node ${item.active ? '' : 'can-not-select'}`}
              key={item.id}
              value={item.id}
              name={item.name}
              title={this.renderTreeNodeTitle(item, true)}
              disabled={!item.active || disabledSelectFeeType.includes(item.id)}
            />
          )
        }
      })
      return treeNodeList.concat(data)
    }

    return loop(data)
  }
  render() {
    let {
      className,
      size,
      multiple,
      showFullPath,
      treeCheckable,
      disabledCheckedFather,
      isRenderTitle,
      errorMsg,
      treeCheckStrictly = false,
      disabledPopupContainer,
      feeTypes,
      style,
      disabled = false,
      cls,
      placeholder,
      id = '',
      dropdownHeight,
      dropdownWidth,
      allowClear = true,
      treeDefaultExpandAll = false,
      filterOnSearch,
      wrapperStyle={}
    } = this.props

    let { checkedKeys, fullPath, searchText } = this.state
    if (!fullPath && feeTypes.length) {
      fullPath = this.getFullFeeTypePath(feeTypes, checkedKeys)
    }
    let errStyle = errorMsg ? 'field-feeType-input' : ''
    let config = treeCheckStrictly ? {} : { value: checkedKeys }
    if (filterOnSearch) {
      config = { ...config, onSearch: this.onSearch, filterTreeNode: false }
    }
    return (
      <div ref="treeSelect" style={{...wrapperStyle}} className={styles.feeType_select_wrap} id={id}>
        <TreeSelect
          dropdownClassName={`feetype_dropdown ${cls}`}
          style={style}
          className={`${className} ${errStyle}` || `${errStyle}`}
          size={size || 'default'} //large,small,default
          placeholder={placeholder || i18n.get('请选择费用类型')}
          notFoundContent={i18n.get('没有匹配结果')}
          multiple={multiple}
          showSearch
          showCheckedStrategy={SHOW_PARENT}
          treeNodeFilterProp="name"
          treeCheckable={treeCheckable}
          dropdownStyle={{
            maxHeight: dropdownHeight ? dropdownHeight : 280,
            overflow: 'auto',
            width: dropdownWidth ? dropdownWidth : '100%'
          }}
          treeCheckStrictly={treeCheckStrictly}
          treeNodeLabelProp={disabledCheckedFather || isRenderTitle ? 'title' : 'name'}
          {...config}
          dropdownMatchSelectWidth={true}
          defaultValue="1"
          disabled={disabled}
          onChange={this.handleFeeTypeChange}
          getPopupContainer={disabledPopupContainer ? () => document.body : triggerNode => triggerNode}
          allowClear={allowClear}
          treeDefaultExpandAll={isHongShanTestingEnterprise(Fetch.ekbCorpId) || treeDefaultExpandAll}
        >
          {searchText ? this.renderSearchTreeNode() : this.renderTreeNode()}
        </TreeSelect>
        {showFullPath && <div className="field-feeType-fullPath">{`${fullPath}${this.getCode()}`}</div>}
        {!!errorMsg && <div className="field-feeType-errorMsg">{errorMsg}</div>}
      </div>
    )
  }
}
