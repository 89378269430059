import React from 'react'
import moment from 'moment'
import CardListView from '../../../elements/InvoiceCard/InvoiceItem'
import { parseAsShowValue } from '../../../elements/invoice-form/utils/config'
import { app as api } from '@ekuaibao/whispered'
import { IMG_REG } from '@ekuaibao/lib/lib/enums'
import { formatDateTime } from '../../../components/utils/fnPredefine4Date'

function buildFeeDate2String(feeDate, isPeriod = false, components = []) {
  if (!feeDate) {
    return ''
  }
  const dateRangeComponent = components.find(
    component => component.type === 'dateRange' && component.field === 'feeDatePeriod'
  )
  const feeDateComponent = components.find(component => component.type === 'date' && component.field === 'feeDate')
  const dataComponent = dateRangeComponent || feeDateComponent
  if (!dataComponent) {
    return ''
  }
  const { dateTimeType, withTime } = dataComponent
  const dateFormat = formatDateTime(withTime, dateTimeType)
  if (isPeriod && typeof feeDate === 'object') {
    let { start, end } = feeDate
    start = moment(start).format(dateFormat)
    end = moment(end).format(dateFormat)
    return `${start} ~ ${end}`
  }
  return moment(parseInt(feeDate)).format(dateFormat)
}

export function fnGetDetailsByType(feeTypeForm, components) {
  let { feeDate, feeDatePeriod, fromCity, toCity, city } = feeTypeForm
  let feeDateString = ''
  let cityStr = ''
  if (feeDatePeriod) {
    feeDateString = buildFeeDate2String(feeDatePeriod, true, components)
  } else if (feeDate && !feeDatePeriod) {
    feeDateString = buildFeeDate2String(feeDate, false, components)
  } else {
    feeDateString = i18n.get('无消费日期')
  }

  try {
    if (city) {
      cityStr = `${JSON.parse(city)[0].label}`
    }
    if (fromCity && toCity) {
      cityStr = `${JSON.parse(fromCity)[0].label}-${JSON.parse(toCity)[0].label}`
    }
  } catch (error) {}

  return { cityStr, feeDateString }
}

export function renderTitle(invoiceForm) {
  if (!invoiceForm) return ''
  return invoiceForm.attachments || invoiceForm.fileList ? '' : invoiceForm.invoices ? '' : i18n.get('开票方')
}

export function renderStyle(invoiceForm) {
  if (!invoiceForm) return { display: 'none' }
  return Object.keys(invoiceForm).length > 1 &&
    (invoiceForm.type === 'unify' ||
      (invoiceForm.type === 'exist' &&
        ((invoiceForm.attachments && invoiceForm.attachments.length) ||
          (invoiceForm.invoices && invoiceForm.invoices.length))) ||
      ((invoiceForm.type === 'noWrite' || invoiceForm.type === 'exist') &&
        invoiceForm.invoices &&
        invoiceForm.invoices.length))
    ? { maxWidth: 512 }
    : { display: 'none' }
}

function handleFilePreview(line) {
  if (!IMG_REG.test(line.fileName)) {
    api.emit('@vendor:preview', line.url, line.fileName)
  } else {
    api.emit('@vendor:preview:images', [line], line)
  }
}

export function renderContent(
  invoiceForm,
  submitterId,
  invoiceImageList,
  isInvoiceManagePermissions,
  onChange,
  billState = ''
) {
  if (!invoiceForm) return ''
  if (invoiceForm.attachments || invoiceForm.invoices) {
    const invoiceDetailList = invoiceForm.invoices || []
    const fileList = invoiceForm.attachments
    const showValue = parseAsShowValue(fileList, invoiceDetailList)
    return (
      <div style={renderStyle(invoiceForm)}>
        <CardListView
          dataSource={showValue || []}
          isEdit={false}
          isHover={true}
          billState={billState}
          submitterId={submitterId}
          isDownload={false}
          invoiceNum={showValue.length} //只有明细列表和明细表格发票张数为1时，展示发票所有的明细
          invoiceImgList={invoiceImageList}
          onFilePreview={handleFilePreview}
          handleImgPreview={handleFilePreview}
          onChange={onChange} //可抵扣税额开关
          isInvoiceManagePermissions={isInvoiceManagePermissions}
          isEditAuthenticity={false} //验证发票
        />
      </div>
    )
  } else if (invoiceForm.invoiceCorporation) {
    return invoiceForm.invoiceCorporation.name
  }
}

export const invoiceFormMap = {
  exist: i18n.get('已有发票'),
  noExist: i18n.get('无发票'),
  wait: i18n.get('待开发票'),
  unify: i18n.get('统一开票'),
  noWrite: i18n.get('无需发票'),
  existConfirm: i18n.get('已有发票(待确认)')
}

const getActionValue = (actionObj, fId, sId) => {
  const { isActive, isChange, isLast, feeTypeId, specificationId } = actionObj
  if (fId !== feeTypeId) {
    return ''
  } else {
    if (sId === specificationId) {
      if (!isActive) {
        return i18n.get('已停用')
      }
      if (!isLast) {
        return i18n.get('非末级')
      }
      if (isChange) {
        return i18n.get('版本变更')
      }
    } else {
      if (!isActive) {
        return i18n.get('已停用')
      }
      if (!isLast) {
        return i18n.get('非末级')
      }
    }
  }
}

export const formatFeeTypeList = (dataSourceList = [], feeChangeInfo) => {
  if (!dataSourceList.length) return dataSourceList
  return dataSourceList.map((item, index) => {
    const { feeTypeId = {}, specificationId = {} } = item
    const actionObj = feeChangeInfo[`${feeTypeId.id}${index}`]
    if (actionObj) {
      feeTypeId.actionValue = getActionValue(actionObj, feeTypeId.id, specificationId.id)
    }
    item.feeTypeId = feeTypeId
    return item
  })
}

export const sortType = {
  INPUT: 'INPUT',
  ASC: 'ASC',
  DESC: 'DESC'
}

export const sortTypeList = [
  { type: sortType.INPUT, label: i18n.get('默认排序') },
  { type: sortType.ASC, label: i18n.get('费用金额正序') },
  { type: sortType.DESC, label: i18n.get('费用金额倒序') }
]

export default {
  fnGetDetailsByType,
  renderTitle,
  renderContent,
  invoiceFormMap,
  formatFeeTypeList,
  sortTypeList,
  sortType
}
