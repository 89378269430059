var questionnaireConfig = {
    submit: {
        sid: '2193827109022720',
        channelId: '2193828085640192',
        width: '400px'
    },
    approve: {
        sid: '2193839010884608',
        channelId: '2193839946738688',
        width: '400px'
    }
};
if (process.env.NODE_ENV === 'development' || window.origin.includes('ekb-qa.k8s03.ekuaibao')) {
    questionnaireConfig = {
        submit: {
            sid: '2233210038118400',
            channelId: '2233210849912832',
            width: '400px'
        },
        approve: {
            sid: '2233223087712256',
            channelId: '2233224470259712',
            width: '400px'
        }
    };
}
export { questionnaireConfig };
