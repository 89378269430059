import { __assign, __awaiter, __generator } from "tslib";
import { showMessage } from '@ekuaibao/show-util';
import { parseFormValueAsParam } from './parse';
import { standardMoneyByForeign, standardValueMoney } from '../../../lib/misc';
import { Resource, Fetch } from '@ekuaibao/fetch';
import { isObject, isString } from '@ekuaibao/helpers';
import { xor, get, cloneDeep } from 'lodash';
import { getAssignmentRuleById } from '../bills.action';
import { app as api } from '@ekuaibao/whispered';
var rule = new Resource('/api/rpc/v1/rule');
var blackCheckCorpList = ['AJU3H9IgqT6rxM'];
export function getParam(source, billData, formValue, currentSpecification, baseDataProperties, submitterId, isCalculateWrite) {
    var value = parseFormValueAsParam(billData, currentSpecification, undefined, baseDataProperties);
    var form = value.form;
    form.source = source;
    var canPay = currentSpecification.configs.find(function (v) { return v.ability === 'pay'; });
    var canWrittenOff = currentSpecification.configs.find(function (v) { return v.ability === 'writtenOff'; });
    var canExpense = currentSpecification.configs.find(function (v) { return v.ability === 'expense'; });
    var canRequisition = currentSpecification.configs.find(function (v) { return v.ability === 'requisition' && v.applyContentRule.applyContentRule === 'auto'; });
    if ((currentSpecification === null || currentSpecification === void 0 ? void 0 : currentSpecification.type) === 'permit' && (currentSpecification === null || currentSpecification === void 0 ? void 0 : currentSpecification.budgetPermitAllowEdit)) {
        formValue.submitterId = { id: submitterId };
        form.submitterId = submitterId;
    }
    var canReceipt = currentSpecification.configs.find(function (v) { return v.ability === 'receipt'; });
    if (canPay) {
        form.payMoney = standardValueMoney(0);
    }
    if (canWrittenOff) {
        form.writtenOffMoney = standardValueMoney(0);
    }
    if (canExpense) {
        form.expenseMoney = standardValueMoney(0);
    }
    if (canRequisition) {
        form.requisitionMoney = standardValueMoney(0);
    }
    if (canReceipt) {
        form.receiptMoney = standardValueMoney(0);
    }
    if (formValue.details) {
        delete formValue.details;
    }
    if (formValue.trips) {
        delete formValue.trips;
    }
    var payeeId = get(formValue, 'payeeId.id') || '';
    if (!payeeId) {
        formValue.payeeId = {};
    }
    var expenseLinkId = get(formValue, 'expenseLink');
    if (typeof expenseLinkId === 'object') {
        formValue.expenseLink = formValue.expenseLink.id;
    }
    var params = {
        submitterId: submitterId || billData.submitterId.id,
        formData: formValue,
        billData: form,
        isCalculateWrite: isCalculateWrite || false
    };
    return params;
}
function checkValueChange(prevValue, nextValue, type, checkDefaultValue, component, entity, currencyType) {
    var defaultValue = get(component, 'defaultValue.type');
    if (component && component.editable === true && defaultValue === 'formula') {
        return !checkDefaultValue || Boolean(prevValue);
    }
    if (type === 'dateRange') {
        var next = nextValue ? JSON.parse(nextValue) : {};
        var prev = isObject(prevValue) ? prevValue : {};
        return next.start === prev.start && next.end === prev.end;
    }
    else if (type === 'boolean') {
        return String(prevValue) === String(nextValue);
    }
    else if (type === 'number' || type === 'money' || type === 'date') {
        var prev = isObject(prevValue) && type === 'money' ? parseFloat(prevValue.standard) : parseFloat(prevValue);
        var next = isObject(nextValue) && type === 'money' ? parseFloat(nextValue.standard) : parseFloat(nextValue);
        if (type === 'money' && currencyType === 'FOREIGN') {
            prev =
                isObject(prevValue) && type === 'money'
                    ? parseFloat(prevValue.foreign || prevValue.standard)
                    : parseFloat(prevValue);
            next = isObject(nextValue) ? parseFloat(nextValue.foreign) : parseFloat(nextValue);
        }
        return (isNaN(prev) && isNaN(next)) || prev === next;
    }
    else if (entity === 'basedata.city') {
        var next = nextValue ? JSON.parse(nextValue) : [];
        var prev = prevValue ? JSON.parse(prevValue) : [];
        var nextIds = next.map(function (v) { return v.key; });
        var prevIds = prev.map(function (v) { return v.key; });
        return !xor(nextIds, prevIds).length;
    }
    else if (type === 'list' && entity === 'organization.Staff') {
        var next = nextValue ? JSON.parse(nextValue) : [];
        var prev = prevValue ? prevValue : [];
        var nextIds = next.map(function (v) { return v.id; });
        var prevIds = prev.map(function (v) { return v.id; });
        return !xor(nextIds, prevIds).length;
    }
    else if ((type === 'ref' && entity === 'organization.Staff') || entity === 'organization.Department') {
        var next = nextValue ? JSON.parse(nextValue) : {};
        var prev = isObject(prevValue) ? prevValue : {};
        return prev.id === next.id;
    }
    else if (type === 'ref' && isString(entity) && entity.startsWith('basedata.Dimension')) {
        var next = nextValue ? JSON.parse(nextValue) : {};
        var prev = isObject(prevValue) ? prevValue : {};
        return prev.id === next.id;
    }
    else if (entity && entity.includes('datalink.DataLinkEntity')) {
        var next = nextValue ? JSON.parse(nextValue) : {};
        var prev = isObject(prevValue) ? prevValue : {};
        return prev.id === next.id;
    }
    else {
        return prevValue === nextValue;
    }
}
export function checkAttrChange(prevValue, nextValue, component, field) {
    var type = get(field, 'dataType.elemType.type') || get(field, 'dataType.type');
    var typeArr = ['attachment', 'text', 'ref', 'money', 'complex', 'travel'];
    if (typeArr.indexOf(type) > -1 && prevValue !== undefined) {
        return String(!prevValue) === String(nextValue);
    }
    return prevValue === nextValue;
}
function formatAutoCalculateResult(results, baseDataPropertiesMap, bus, checkDefaultValue, template, updateAutoCalResultAttribute, checkSubmit) {
    return __awaiter(this, void 0, void 0, function () {
        var formValue, resultValue, attrValue, isCheckDetailCalAttr, checkDetails;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formValue = {};
                    if (!bus.getValue) return [3, 2];
                    return [4, bus.getValue()];
                case 1:
                    formValue = _a.sent();
                    _a.label = 2;
                case 2:
                    resultValue = {};
                    attrValue = {};
                    isCheckDetailCalAttr = false;
                    checkDetails = [];
                    results &&
                        results.forEach(function (element) {
                            var _a;
                            var onField = element.onField, result = element.result, dataFrom = element.dataFrom, loc = element.loc, resultType = element.resultType, attribute = element.attribute, currencyType = element.currencyType, numCode = element.numCode;
                            var r = result === '' || result === 'null' ? undefined : result;
                            var _b = get(baseDataPropertiesMap[onField], 'dataType') || {}, type = _b.type, entity = _b.entity;
                            if (!entity && type === 'list') {
                                entity = get(baseDataPropertiesMap[onField], 'dataType.elemType.entity');
                            }
                            var component = template.find(function (v) {
                                return onField === v.name;
                            });
                            if (resultType === 'VALUE') {
                                if (updateAutoCalResultAttribute)
                                    return;
                                if (dataFrom === 'details') {
                                    var _c = formValue.details, details = _c === void 0 ? [] : _c;
                                    var currentDetail = details[loc];
                                    var specificationId = currentDetail.specificationId, feeTypeForm = currentDetail.feeTypeForm;
                                    var feeTypeComponent = specificationId.components.find(function (v) {
                                        return onField === v.field;
                                    });
                                    var defaultValue = get(feeTypeComponent, 'defaultValue.type');
                                    var orginalValue = typeof feeTypeForm[onField] === 'object' ? (_a = feeTypeForm[onField]) === null || _a === void 0 ? void 0 : _a.id : feeTypeForm[onField];
                                    feeTypeForm[onField] =
                                        feeTypeComponent.editable && defaultValue === 'formula'
                                            ? feeTypeForm[onField]
                                            : formatValue(type, entity, r, feeTypeComponent, feeTypeForm[onField]);
                                    if (onField === 'amount' && !(feeTypeComponent.editable && defaultValue === 'formula')) {
                                        checkDetails = details;
                                    }
                                    if (feeTypeComponent.type === 'dataLink' &&
                                        feeTypeComponent.isLinkageAssignment &&
                                        !feeTypeComponent.editable &&
                                        orginalValue !== feeTypeForm[onField]) {
                                        feeTypeForm[onField] = orginalValue;
                                        currentDetail.errorMsg = {};
                                        currentDetail.errorMsg['needUpdate'] = i18n.get("{__k0}\u7684\u503C\u53D1\u751F\u53D8\u5316,\u8BF7\u91CD\u65B0\u4FDD\u5B58\u8BE5\u6761\u660E\u7EC6", {
                                            __k0: feeTypeComponent.label
                                        });
                                        currentDetail.needUpdate = true;
                                    }
                                    resultValue.details = details;
                                }
                                else if (component &&
                                    !checkValueChange(formValue[onField], r, type, checkDefaultValue, component, entity, currencyType)) {
                                    resultValue[onField] = formatValue(type, entity, r, component, formValue[onField], { currencyType: currencyType, numCode: numCode });
                                }
                            }
                            else if (resultType === 'SPECIFICATION_ATTRIBUTE') {
                                if (dataFrom === 'details') {
                                    var _d = formValue.details, details = _d === void 0 ? [] : _d;
                                    var currentDetail = details[loc];
                                    var detailComponents = get(currentDetail, 'specificationId.components', []);
                                    var feeTypeForm = currentDetail.feeTypeForm;
                                    var cmpIndex = detailComponents.findIndex(function (el) { return el.field === onField; });
                                    var cmp = detailComponents[cmpIndex];
                                    var type_1 = get(baseDataPropertiesMap[onField], 'dataType.elemType.type') ||
                                        get(baseDataPropertiesMap[onField], 'dataType.type');
                                    if (attribute === 'optional') {
                                        if (!checkAttrChange(cmp[attribute], r, cmp, baseDataPropertiesMap[onField])) {
                                            var attrObj = formatAttrValue(attribute, r, detailComponents, baseDataPropertiesMap[onField]);
                                            cmp = __assign(__assign({}, cmp), attrObj);
                                        }
                                        var isOptional = false;
                                        if (type_1 === 'attachment') {
                                            isOptional =
                                                cmp[attribute] === false &&
                                                    (!feeTypeForm[onField] || !(Array.isArray(feeTypeForm[onField]) && feeTypeForm[onField].length));
                                        }
                                        else {
                                            isOptional = cmp[attribute] === false && !feeTypeForm[onField];
                                        }
                                        if (isOptional) {
                                            isCheckDetailCalAttr = true;
                                            currentDetail.errorMsg = {
                                                completed: i18n.get('消费明细填写不完整；'),
                                                isCheckCalAttr: true
                                            };
                                        }
                                    }
                                    attrValue.detailInfo = { details: details, isCheckDetailCalAttr: isCheckDetailCalAttr };
                                }
                                else {
                                    if (attribute === 'optional') {
                                        if (component && !checkAttrChange(component[attribute], r, component, baseDataPropertiesMap[onField])) {
                                            var res = formatAttrValue(attribute, r, component, baseDataPropertiesMap[onField]);
                                            attrValue[onField] = __assign(__assign({}, attrValue[onField]), res);
                                        }
                                    }
                                    else if (attribute === 'hide') {
                                        var res = formatSetValue('attributeHide', r, component, baseDataPropertiesMap[onField]);
                                        attrValue[onField] = __assign(__assign({}, attrValue[onField]), res);
                                    }
                                    else if (attribute === 'open') {
                                        attrValue[onField] = __assign(__assign({}, attrValue[onField]), { open: r === 'true' });
                                    }
                                }
                            }
                        });
                    if (!checkSubmit && (checkDetails === null || checkDetails === void 0 ? void 0 : checkDetails.length)) {
                        bus.emit('details:change', checkDetails);
                    }
                    return [2, { resultValue: resultValue, attrValue: attrValue }];
            }
        });
    });
}
function formatSetValue(attribute, result, component, field) {
    var _a;
    var type = get(field, 'dataType.elemType.type') || get(field, 'dataType.type');
    var res = {};
    var typeArr = ['text', 'ref', 'boolean', 'number', 'money', 'date', 'dateRange', 'complex', 'attachment'];
    if (typeArr.indexOf(type) > -1 && result !== undefined) {
        return __assign(__assign({}, res), (_a = {}, _a[attribute] = result === 'true' ? true : false, _a));
    }
    return res;
}
export function formatAttrValue(attribute, result, component, field) {
    var _a, _b;
    var type = get(field, 'dataType.elemType.type') || get(field, 'dataType.type');
    var res = {};
    var typeArr = ['attachment', 'text', 'ref', 'money', 'date', 'dateRange', 'complex', 'travel'];
    if (typeArr.indexOf(type) > -1 && result && attribute) {
        if (attribute === 'open') {
            return __assign(__assign({}, res), (_a = {}, _a[attribute] = result === 'true', _a));
        }
        else {
            return __assign(__assign({}, res), (_b = {}, _b[attribute] = result !== 'true', _b));
        }
    }
    return res;
}
export function formatValue(type, entity, value, component, formValue, resultCurrencyInfo) {
    if (type === 'money') {
        if (isObject(formValue)) {
            var foreignScale = formValue.foreignScale, scale = formValue.scale, rate = formValue.rate, foreignNumCode = formValue.foreignNumCode;
            if (isString(value) && (resultCurrencyInfo === null || resultCurrencyInfo === void 0 ? void 0 : resultCurrencyInfo.currencyType) === 'FOREIGN') {
                return standardMoneyByForeign(value, resultCurrencyInfo === null || resultCurrencyInfo === void 0 ? void 0 : resultCurrencyInfo.numCode, (resultCurrencyInfo === null || resultCurrencyInfo === void 0 ? void 0 : resultCurrencyInfo.numCode) === foreignNumCode ? rate : undefined);
            }
            else {
                if (isString(value) && formValue.hasOwnProperty('foreign')) {
                    formValue.foreign = new Big(value).div(rate || 1).toFixed(foreignScale !== undefined ? foreignScale : (scale !== undefined ? scale : 2));
                }
                formValue.standard = value;
            }
            return __assign({}, formValue);
        }
        if (isString(value)) {
            var dimentionCurrency = api.getState()['@bills'].dimentionCurrencyInfo;
            if ((resultCurrencyInfo === null || resultCurrencyInfo === void 0 ? void 0 : resultCurrencyInfo.currencyType) === 'FOREIGN') {
                return standardMoneyByForeign(value, resultCurrencyInfo === null || resultCurrencyInfo === void 0 ? void 0 : resultCurrencyInfo.numCode);
            }
            return standardValueMoney(value, dimentionCurrency === null || dimentionCurrency === void 0 ? void 0 : dimentionCurrency.currency);
        }
        return value;
    }
    else if (type === 'date' && value) {
        return parseFloat(value);
    }
    else if (type === 'dateRange' && isString(value)) {
        return JSON.parse(value);
    }
    else if ((type === 'switcher' || type === 'boolean') && value) {
        return value === 'true';
    }
    else if (entity === 'basedata.city') {
        if (!value || value === '[]') {
            return;
        }
        var vv = value.replace(/中国\/\s*/g, '');
        return !component.multiple && component.editable ? JSON.stringify([JSON.parse(vv)[0]]) : vv;
    }
    else if (isString(entity) &&
        isString(value) &&
        (entity === 'organization.Staff' || entity === 'organization.Department' || entity.startsWith('basedata.Dimension'))) {
        return JSON.parse(value);
    }
    else if (entity && entity.includes('datalink.DataLinkEntity') && isString(value)) {
        var dataLinkObj = JSON.parse(value);
        return dataLinkObj && dataLinkObj.id;
    }
    return value;
}
var lastFetchId = 0;
export function getAllAutoCalResultForBillDiff(billData) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var baseDataProperties, billDataCloned, specificationId, submitterId, params, results;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    baseDataProperties = api.getState()['@common'].globalFields.data;
                    billDataCloned = cloneDeep(billData);
                    specificationId = billDataCloned.specificationId, submitterId = billDataCloned.submitterId;
                    params = getParam('master_', billDataCloned, billDataCloned, specificationId, baseDataProperties, submitterId === null || submitterId === void 0 ? void 0 : submitterId.id, undefined);
                    params.formData.details = (_a = params === null || params === void 0 ? void 0 : params.billData.details) !== null && _a !== void 0 ? _a : [];
                    if (typeof params.formData.specificationId !== 'string' && !!((_b = params.formData.specificationId) === null || _b === void 0 ? void 0 : _b.id)) {
                        params.formData.specificationId = params.formData.specificationId.id;
                    }
                    return [4, rule
                            .POST('/calculate', params)];
                case 1:
                    results = _c.sent();
                    return [2, results];
            }
        });
    });
}
export function updateAutoCalResult(source, billData, formValue, billSpecification, baseDataProperties, baseDataPropertiesMap, bus, submitterId, changeValues, isTextChange, checkDefaultValue, template, updateAutoCalResultAttribute) {
    return __awaiter(this, void 0, void 0, function () {
        var fetchId, params, presetTemplateId, results, _a, resultValue, attrValue;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    lastFetchId += 1;
                    fetchId = lastFetchId;
                    bus.emit('savebtn:state:change', { disabled: true });
                    params = getParam(source, billData, formValue, billSpecification, baseDataProperties, submitterId, checkDefaultValue);
                    if (!(source === 'detail_' && bus.has('send:presetTemplateId'))) return [3, 2];
                    return [4, bus.invoke('send:presetTemplateId')];
                case 1:
                    presetTemplateId = _b.sent();
                    if (presetTemplateId && (params === null || params === void 0 ? void 0 : params.billData)) {
                        params.billData.specificationId = presetTemplateId;
                    }
                    _b.label = 2;
                case 2: return [4, rule
                        .POST('/calculate', params)
                        .then(function (response) {
                        var items = response.items;
                        var hasError = items.filter(function (v) { return v.errorMsg; });
                        if (hasError.length > 0) {
                            throw { msg: hasError[0].errorMsg };
                            return;
                        }
                        if (fetchId !== lastFetchId && !checkDefaultValue) {
                            bus.emit('savebtn:state:change', { disabled: false });
                            return;
                        }
                        bus.emit('switch:message:change', { messageData: response.items });
                        return response.items;
                    })
                        .catch(function (err) {
                        if (err.msg) {
                            showMessage.error(err.msg);
                            bus.emit('savebtn:state:change', { disabled: false });
                        }
                        return;
                    })];
                case 3:
                    results = _b.sent();
                    return [4, formatAutoCalculateResult(results, baseDataPropertiesMap, bus, checkDefaultValue, template, updateAutoCalResultAttribute)];
                case 4:
                    _a = _b.sent(), resultValue = _a.resultValue, attrValue = _a.attrValue;
                    if (attrValue.details) {
                        delete attrValue.details;
                    }
                    if (Object.keys(attrValue).length) {
                        bus.emit('update:calculate:template', attrValue);
                        bus.emit('update:calculate:detail:template', attrValue);
                    }
                    if (Object.keys(resultValue).length) {
                        Object.keys(resultValue).forEach(function (key) {
                            if (key === 'loanMoney') {
                                bus.emit('loanMoney:changed', resultValue.loanMoney);
                            }
                            if (key === 'amount' && bus.has('amount:changed')) {
                                bus.invoke('amount:changed', resultValue.amount);
                            }
                        });
                        bus.setFieldsValue(__assign({}, resultValue));
                    }
                    bus.emit('savebtn:state:change', { disabled: false });
                    return [2];
            }
        });
    });
}
export function checkSubmitValue(formValue, baseDataPropertiesMap, bus, template) {
    return __awaiter(this, void 0, void 0, function () {
        var params, result, checkSubmit, _a, resultValue, attrValue;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!formValue || !formValue.details || !formValue.details.length) {
                        return [2];
                    }
                    params = {
                        submitterId: formValue.submitterId,
                        formData: formValue,
                        billData: __assign(__assign({}, formValue), { source: 'master_' }),
                        isCalculateWrite: false
                    };
                    return [4, rule
                            .POST('/calculate', params)
                            .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                            var items, hasError;
                            return __generator(this, function (_a) {
                                items = response.items;
                                hasError = items.filter(function (v) { return v.errorMsg; });
                                if (hasError.length > 0) {
                                    throw { msg: hasError[0].errorMsg };
                                }
                                return [2, items];
                            });
                        }); })
                            .catch(function (err) {
                            return;
                        })];
                case 1:
                    result = _b.sent();
                    if (!result) {
                        return [2];
                    }
                    checkSubmit = blackCheckCorpList.includes(Fetch.ekbCorpId);
                    return [4, formatAutoCalculateResult(result, baseDataPropertiesMap, bus, false, template, false, checkSubmit)];
                case 2:
                    _a = _b.sent(), resultValue = _a.resultValue, attrValue = _a.attrValue;
                    bus.setFieldsValue(__assign({}, resultValue));
                    return [2, { resultValue: resultValue, attrValue: attrValue }];
            }
        });
    });
}
export function setDataLinkFormValue(id, template, field) {
    var assignmentRule = field.assignmentRule;
    return api.invokeService('@bills:get:datalink:template:byId', { entityId: id, type: 'CARD' }).then(function (res) {
        var data = res.value.data;
        return api.dispatch(getAssignmentRuleById([data.dataLink.entityId])).then(function (result) {
            var ruleList = result.items;
            var assignmentRuleId = isString(assignmentRule) ? assignmentRule : assignmentRule === null || assignmentRule === void 0 ? void 0 : assignmentRule.id;
            var ruleObj = ruleList.find(function (v) { return v.id === assignmentRuleId; }) || ruleList[0];
            return fnUpdateData(data, ruleObj, template);
        });
    });
}
function fnUpdateData(data, assignmentRule, template) {
    var _a = assignmentRule.fields, fields = _a === void 0 ? [] : _a;
    var valueMap = {};
    fields.forEach(function (item) {
        template.forEach(function (v) {
            if (item.targetField === v.field) {
                var value = data.dataLink[item.sourceField];
                if (value !== undefined) {
                    if (v.type === 'number') {
                        valueMap[v.field] = "" + value * 1;
                    }
                    else if (v.type === 'dataLink') {
                        valueMap[v.field] = __assign(__assign({}, value), { id: get(value, 'data.dataLink.id') });
                    }
                    else {
                        valueMap[v.field] = value;
                    }
                }
            }
        });
    });
    for (var key in valueMap) {
        if (typeof valueMap[key] === 'undefined') {
            delete valueMap[key];
        }
    }
    return valueMap;
}
