import moment from 'moment'
// @i18n-ignore-all

/**
 *  Created by panwei on 2018/7/25 下午3:52.
 */

export function formatInvoiceData({ data, date, type, dateFormat = 'YYYY.MM.DD' }) {
  type = type ? type : data.data ? 'oldData' : ''
  switch (type) {
    case 'oldData':
      return { invoiceInfo: formatOldData({ arg: data, date }), originalData: data, isOld: true }
    case 'train':
      return { invoiceInfo: formatInvoiceTrain({ data }), originalData: data, isOld: false }
    default:
      return { invoiceInfo: formatData({ data, dateFormat }), originalData: data, isOld: false }
  }
}

function formatData({ data, dateFormat }) {
  const { master, details } = data
  const { entityId, form, id, status } = master
  let value = form[i18n.get(`E_{__k0}_发票日期`, { __k0: entityId })]
  let value1 = value && (moment(value) ? moment(value).format(dateFormat) : moment().format(dateFormat))
  let basicFields =  {
    id: id,
    buyer: {
      label: i18n.get('购买方名称'),
      value: {
        payer: form[i18n.get(`E_{__k0}_购买方名称`, { __k0: entityId })],
        payertaxno: form[i18n.get(`E_{__k0}_购买方纳税人识别号`, { __k0: entityId })],
        invdate: form[`E_${entityId}_invdate`]
      }
    },
    taxIdNum: {
      label: i18n.get('购买方纳税人识别号'),
      value: {
        payer: form[i18n.get(`E_{__k0}_购买方名称`, { __k0: entityId })],
        payertaxno: form[i18n.get(`E_{__k0}_购买方纳税人识别号`, { __k0: entityId })],
        invdate: form[`E_${entityId}_invdate`]
      }
    },
    invamt: form[`E_${entityId}_价税合计`],
    invtaxamt: form[i18n.get(`E_{__k0}_税额`, { __k0: entityId })],
    type: form[i18n.get(`E_{__k0}_发票类别`, { __k0: entityId })],
    fpdm: form[i18n.get(`E_{__k0}_发票代码`, { __k0: entityId })],
    fphm: form[i18n.get(`E_{__k0}_发票号码`, { __k0: entityId })],
    masterItems: [
      {
        label: i18n.get('购买方地址电话'),
        value: form[`E_${entityId}_购买方地址电话`]
      },
      {
        label: i18n.get('购买方开户行及账号'),
        value: form[`E_${entityId}_购买方开户行及账号`]
      },
      {
        type: 'seller',
        label: i18n.get('销售方名称'),
        value: form[i18n.get(`E_{__k0}_销售方名称`, { __k0: entityId })]
      },
      {
        label: i18n.get('发票代码'),
        value: form[i18n.get(`E_{__k0}_发票代码`, { __k0: entityId })]
      },
      {
        label: i18n.get('发票号码'),
        value: form[i18n.get(`E_{__k0}_发票号码`, { __k0: entityId })]
      },
      {
        type: 'date',
        label: i18n.get('开票日期'),
        value: value1
      },
      {
        type: 'money',
        label: i18n.get('价税合计'),
        value: form[i18n.get(`E_{__k0}_价税合计`, { __k0: entityId })]
      },
      {
        type: 'money',
        label: i18n.get('税额'),
        value: form[i18n.get(`E_{__k0}_税额`, { __k0: entityId })]
      }
    ],
    source: form[i18n.get(`E_{__k0}_来源`, { __k0: entityId })],
    detailItems: formatInvoiceDetails(details),
    remark: form[i18n.get(`E_{__k0}_备注`, { __k0: entityId })],
    status: status
  }
  
  const invoiceType = form[i18n.get(`E_{__k0}_发票类别`, { __k0: entityId })]
  const vatInvoice = ['DIGITAL_NORMAL','DIGITAL_SPECIAL','PAPER_NORMAL','PAPER_SPECIAL']
  if (vatInvoice.includes(invoiceType)) {
    let basicFieldsItems = basicFields.masterItems
    basicFieldsItems.splice(3,0,{
      label: i18n.get('销售方纳税人识别号'),
      value: form[i18n.get(`E_{__k0}_销售方纳税人识别号`, { __k0: entityId })]
    },
    {
      label: i18n.get('销售方地址电话'),
      value: form[i18n.get(`E_{__k0}_销售方地址电话`, { __k0: entityId })]
    },
    {
      label: i18n.get('销售方开户行及账号'),
      value: form[i18n.get(`E_{__k0}_销售方开户行及账号`, { __k0: entityId })]
    },
    {
      label: i18n.get('校验码'),
      value: form[i18n.get(`E_{__k0}_校验码`, { __k0: entityId })]
    })
  }
  return basicFields
}

function formatInvoiceDetails(detials = []) {
  return detials.map(line => {
    const { entityId, form, ...others } = line
    return {
      name: form[`E_${entityId}_name`],
      amount: form[i18n.get(`E_{__k0}_金额`, { __k0: entityId })],
      taxRate: form[i18n.get(`E_{__k0}_税率`, { __k0: entityId })],
      tax: form[i18n.get(`E_{__k0}_税额`, { __k0: entityId })],
      number: form[i18n.get(`E_{__k0}_数量`, { __k0: entityId })],
      model: form[i18n.get(`E_{__k0}_规格型号`, { __k0: entityId })],
      ...others
    }
  })
}

/**
 * 火车发票
 * @param {data}
 */
function formatInvoiceTrain({ data }) {
  const { master } = data
  const { entityId, form, id, status } = master
  return {
    from: {
      id: id,
      code: form[i18n.get(`E_{__k0}_号码`, { __k0: entityId })],
      number: form[i18n.get(`E_{__k0}_车次`, { __k0: entityId })],
      money: form[i18n.get(`E_{__k0}_金额`, { __k0: entityId })],
      seat: form[i18n.get(`E_{__k0}_座位类型`, { __k0: entityId })],
      kind: form[i18n.get(`E_{__k0}_消费类型`, { __k0: entityId })],
      timer: form[i18n.get(`E_{__k0}_乘车日期`, { __k0: entityId })],
      getOn: form[i18n.get(`E_{__k0}_上车车站`, { __k0: entityId })],
      name: form[i18n.get(`E_{__k0}_乘车人姓名`, { __k0: entityId })],
      getOff: form[i18n.get(`E_{__k0}_下车车站`, { __k0: entityId })],
      status: status
    }
  }
}

/**
 * 出租车发票
 * @param {data}
 */
function formatInvoiceTaxi({ data }) {
  const { master } = data
  const { entityId, form, id, status } = master
  return {
    from: {
      id: id,
      money: form[i18n.get(`E_{__k0}_金额`, { __k0: entityId })],
      bill_id: form[i18n.get(`E_{__k0}_发票号码`, { __k0: entityId })],
      mileage: form[i18n.get(`E_{__k0}_里程`, { __k0: entityId })],
      code: form[i18n.get(`E_{__k0}_发票代码`, { __k0: entityId })],
      date: form[i18n.get(`E_{__k0}_乘车日期`, { __k0: entityId })],
      kind: form[i18n.get(`E_{__k0}_消费类型`, { __k0: entityId })],
      place: form[i18n.get(`E_{__k0}_发票所在地`, { __k0: entityId })],
      geton: form[i18n.get(`E_{__k0}_上车时间`, { __k0: entityId })],
      getoff: form[i18n.get(`E_{__k0}_下车时间`, { __k0: entityId })],
      status: status
    }
  }
}

/**
 * 过路费发票
 * @param {data}
 */
function formatInvoiceRoadToll({ data }) {
  const { master } = data
  const { entityId, form, id, status } = master
  return {
    from: {
      id: id,
      date: form[i18n.get(`E_{__k0}_日期`, { __k0: entityId })],
      timer: form[i18n.get(`E_{__k0}_时间`, { __k0: entityId })],
      exit: form[i18n.get(`E_{__k0}_出口`, { __k0: entityId })],
      money: form[i18n.get(`E_{__k0}_金额`, { __k0: entityId })],
      kind: form[i18n.get(`E_{__k0}_消费类型`, { __k0: entityId })],
      code: form[i18n.get(`E_{__k0}_发票代码`, { __k0: entityId })],
      entrance: form[i18n.get(`E_{__k0}_入口`, { __k0: entityId })],
      number: form[i18n.get(`E_{__k0}_发票号码`, { __k0: entityId })],
      status: status
    }
  }
}

/**
 * 客运汽车发票
 * @param {data}
 */
function formatINvoicePassengerCar({ data }) {
  const { master } = data
  const { entityId, form, id, status } = master
  return {
    from: {
      id: id,
      name: form[i18n.get(`E_{__k0}_姓名`, { __k0: entityId })],
      date: form[i18n.get(`E_{__k0}_日期`, { __k0: entityId })],
      timer: form[i18n.get(`E_{__k0}_时间`, { __k0: entityId })],
      money: form[i18n.get(`E_{__k0}_金额`, { __k0: entityId })],
      code: form[i18n.get(`E_{__k0}_发票代码`, { __k0: entityId })],
      kind: form[i18n.get(`E_{__k0}_消费类型`, { __k0: entityId })],
      number: form[i18n.get(`E_{__k0}_发票代码`, { __k0: entityId })],
      getOn: form[i18n.get(`E_{__k0}_出发车站`, { __k0: entityId })],
      getOff: form[i18n.get(`E_{__k0}_达到车站`, { __k0: entityId })],
      status: status
    }
  }
}

/**
 * 航空运输电子客票行程单发票
 * @param {data}
 */
function formatInvoiceAircraft({ data }) {
  const { master, details } = data
  const { entityId, form, id, status } = master
  return {
    from: {
      id: id,
      tax: form[i18n.get(`E_{__k0}_税费`, { __k0: entityId })],
      fare: form[i18n.get(`E_{__k0}_票价`, { __k0: entityId })],
      money: form[i18n.get(`E_{__k0}_金额`, { __k0: entityId })],
      code: form[i18n.get(`E_{__k0}_身份证号`, { __k0: entityId })],
      date: form[i18n.get(`E_{__k0}_填开日期`, { __k0: entityId })],
      id: form[i18n.get(`E_{__k0}_电子客票号码`, { __k0: entityId })],
      name: form[i18n.get(`E_{__k0}_乘机人姓名`, { __k0: entityId })],
      insurance: form[i18n.get(`E_{__k0}_保险费`, { __k0: entityId })],
      issue_by: form[i18n.get(`E_{__k0}_填开单位`, { __k0: entityId })],
      check_code: form[i18n.get(`E_{__k0}_验证码`, { __k0: entityId })],
      agent_code: form[i18n.get(`E_{__k0}_销售单位代号`, { __k0: entityId })],
      fuel_surcharge: form[i18n.get(`E_{__k0}_燃油附加费`, { __k0: entityId })],
      caac_development_fund: form[i18n.get(`E_{__k0}_民航发展基金`, { __k0: entityId })],
      status: status
    },
    details: this.AircraftDetail(details)
  }
}

/**
 * 航空运输电子客票行程单
 * 详情信息
 */
function AircraftDetail(data) {
  return {
    from: {
      to: form[i18n.get(`E_{__k0}_到达站`, { __k0: entityId })],
      from: form[i18n.get(`E_{__k0}_出发站`, { __k0: entityId })],
      date: form[i18n.get(`E_{__k0}_乘机日期`, { __k0: entityId })],
      timer: form[i18n.get(`E_{__k0}_乘机时间`, { __k0: entityId })],
      seat: form[i18n.get(`E_{__k0}_座位等级`, { __k0: entityId })],
      flight_number: form[i18n.get(`E_{__k0}_航班号`, { __k0: entityId })]
    }
  }
}

function formatOldData({ arg, date }) {
  const { hasPdf, data } = arg
  const { payer, payertaxno, invdate } = data
  return {
    buyer: {
      label: i18n.get('购买方'),
      value: {
        payer,
        payertaxno,
        invdate
      }
    },
    taxIdNum: {
      label: i18n.get('纳税人识别号'),
      value: {
        payer,
        payertaxno,
        invdate
      }
    },
    id: data?.id,
    invamt: data.invsumamt,
    invtaxamt: data.invtaxamt,
    type: data.invtype,
    fpdm: data.invcode,
    fphm: data.invno,
    masterItems: [
      {
        label: i18n.get('销售方名称'),
        value: data.payee
      },
      {
        label: i18n.get('发票代码'),
        value: data.invcode
      },
      {
        label: i18n.get('发票号码'),
        value: data.invno
      },
      {
        type: 'date',
        label: i18n.get('开票日期'),
        value: data.invdate
      },
      {
        type: 'money',
        label: i18n.get('价税合计'),
        value: data.invsumamt
      },
      {
        type: 'money',
        label: i18n.get('税额'),
        value: data.invtaxamt
      }
    ],
    source: hasPdf ? 'UPLOAD' : '',
    detailItems: typeof data.itemdata === 'object' ? data.itemdata : JSON.parse(data.itemdata),
    remark: data.remark
  }
}
