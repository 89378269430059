/**
 *  Created by daiwenjuan on 2017/12/6 下午3:07.
 */
import { standardValueMoney } from '../../lib/misc'
import Big from 'big.js'
import { app as api } from '@ekuaibao/whispered'
const MaxAccuracy = 8
export function initValue(value, currency) {
  if (typeof value === 'object' && isNaN(value)) {
    if (isFixValue(value)) return standardValueMoney(value.standard)
    return value
  }
  let result = standardValueMoney(value)
  if (currency) {
    result = updateValueCurrency(result, currency)
  }
  return result
}

export function updateValueCurrency(value, currency) {
  // currency as foreignCurrency
  let result = { ...value }
  const { strCode, numCode, symbol, unit, scale, rate } = currency

  if (value.standardStrCode === strCode) {
    delete result.foreign
    delete result.foreignNumCode
    delete result.foreignScale
    delete result.foreignStrCode
    delete result.foreignSymbol
    delete result.foreignUnit
    delete result.rate
    delete result.sysRate
  } else if (hasForeign(result) || result.foreignStrCode !== strCode) {
    result.foreignStrCode = strCode
    result.foreignNumCode = numCode
    result.foreignSymbol = symbol
    result.foreignUnit = unit
    result.foreignScale = scale
    result.rate = rate
    result.sysRate = rate
    !value?.foreign && (result.foreign = '')
    if (calculateAble([result.standard, rate, scale])) {
      result.foreign = new Big(result.standard).div(rate).toFixed(Number(scale))
    }
  }

  return result
}

export function updateValueMoney({
  value,
  money,
  prefix,
  isSelectedAutoRate = false,
  isSelectedAutoBudgetRate = false,
  currency
}) {
  let result = { ...value }
  const { rate, standardScale, foreignScale, foreign, budgetRate, budgetScale } = result
  if (prefix === 'foreign') {
    result.foreign = money
    if (!isSelectedAutoRate && calculateAble([money, rate, standardScale])) {
      result.standard = new Big(money).times(rate).toFixed(Number(standardScale))
      if (hasBudget(result)) {
        if (calculateAble([result.standard, budgetRate, budgetScale])) {
          result.budget = new Big(result.standard).div(budgetRate).toFixed(Number(budgetScale))
        }
      }
    } else if (money === '') {
      result.standard = ''
      if (hasBudget(result)) {
        result.budget = ''
      }
    }
    if (isSelectedAutoRate && calculateAble([result.standard, result.foreign])) {
      result.rate = new Big(result.standard).div(result.foreign).toFixed(MaxAccuracy || 8)
    }
    if (isSelectedAutoBudgetRate && calculateAble([result.standard, result.budget])) {
      result.budgetRate = new Big(result.standard).div(result.budget).toFixed(MaxAccuracy || 8)
    }
    return result
  }
  if (prefix === 'budget') {
    result.budget = money
    if (!isSelectedAutoBudgetRate && calculateAble([money, budgetRate, standardScale])) {
      result.standard = new Big(money).times(budgetRate).toFixed(Number(standardScale))
      if (hasForeign(result)) {
        result.foreign = foreign
        if (!isSelectedAutoRate && calculateAble([result.standard, rate, foreignScale]) && Number(rate) !== 0) {
          result.foreign = new Big(result.standard).div(rate).toFixed(Number(foreignScale))
        }
      }
    } else if (money === '') {
      result.standard = ''
      if (hasForeign(result)) {
        result.foreign = ''
      }
    }
    if (isSelectedAutoBudgetRate && calculateAble([result.standard, result.budget])) {
      result.budgetRate = new Big(result.standard).div(result.budget).toFixed(MaxAccuracy || 8)
    }
    if (isSelectedAutoRate && calculateAble([result.standard, result.foreign])) {
      result.rate = new Big(result.standard).div(result.foreign).toFixed(MaxAccuracy || 8)
    }
    return result
  }
  result.standard = money
  if (hasBudget(result)) {
    if (!isSelectedAutoBudgetRate) {
      if (calculateAble([result.standard, budgetRate, budgetScale])) {
        result.budget = new Big(result.standard).div(budgetRate).toFixed(Number(budgetScale))
      } else if (money === '') {
        result.budget = ''
      }
    } else if (isSelectedAutoBudgetRate && calculateAble([result.standard, result.budget])) {
      result.budgetRate = new Big(result.standard).div(result.budget).toFixed(MaxAccuracy || 8)
    }
  }
  if (hasForeign(result)) {
    result.foreign = foreign
    if (!isSelectedAutoRate) {
      if (calculateAble([money, rate, foreignScale])) {
        result.foreign = new Big(money).div(rate).toFixed(Number(foreignScale))
      } else if (money === '') {
        result.foreign = ''
      }
    } else if (isSelectedAutoRate && calculateAble([result.standard, result.foreign])) {
      result.rate = new Big(result.standard).div(result.foreign).toFixed(MaxAccuracy || 8)
    }
  }
  if (isFixValue(result)) {
    result = standardValueMoney(result.standard, currency)
  }
  return result
}

function isFixValue(value) {
  const keys = Object.keys(value)

  if (!keys.includes('foreign')) {
    if (keys.length === 1) {
      return true
    }
    keys
      .filter(key => !!key)
      .forEach(key => {
        if (value[key] === 'undefined') {
          return true
        }
      })
  }
  return false
}

export function updateValueRate(value, rate, unchangeCurrency, rateType) {
  let result = { ...value }
  const { standard, standardScale, foreign, foreignScale, budgetRate, budgetScale } = result
  if (unchangeCurrency === 'standard') {
    if (rateType === 'foreign') {
      result.rate = rate
      if (calculateAble([standard, result.rate, foreignScale]) && Number(rate) !== 0) {
        result.foreign = new Big(standard).div(result.rate).toFixed(Number(foreignScale))
      }
    } else if (rateType === 'budget') {
      result.budgetRate = rate
      if (calculateAble([result.standard, result.budgetRate, budgetScale]) && Number(result.budgetRate) !== 0) {
        result.budget = new Big(result.standard).div(result.budgetRate).toFixed(Number(budgetScale))
      }
    }
  }
  if (unchangeCurrency === 'foreign') {
    if (rateType === 'foreign') {
      result.rate = rate
      if (calculateAble([foreign, result.rate, standardScale]) && Number(rate) !== 0) {
        result.standard = new Big(foreign).times(result.rate).toFixed(Number(standardScale))
        if (calculateAble([result.standard, result.budgetRate, budgetScale]) && Number(result.budgetRate) !== 0) {
          result.budget = new Big(result.standard).div(result.budgetRate).toFixed(Number(budgetScale))
        }
      }
    } else if (rateType === 'budget') {
      result.budgetRate = rate
      if (calculateAble([result.standard, result.budgetRate, budgetScale]) && Number(result.budgetRate) !== 0) {
        result.budget = new Big(result.standard).div(result.budgetRate).toFixed(Number(budgetScale))
      }
    }
  }
  if (unchangeCurrency === 'budget') {
    if (rateType === 'foreign') {
      result.rate = rate
      if (calculateAble([result.standard, result.rate, foreignScale]) && Number(result.rate) !== 0) {
        result.foreign = new Big(result.standard).div(result.rate).toFixed(Number(foreignScale))
      }
    } else if (rateType === 'budget') {
      result.budgetRate = rate
      if (calculateAble([result.budget, result.budgetRate, standardScale])) {
        result.standard = new Big(result.budget).times(result.budgetRate).toFixed(Number(standardScale))
        if (calculateAble([result.standard, result.rate, foreignScale]) && Number(result.rate) !== 0) {
          result.foreign = new Big(result.standard).div(result.rate).toFixed(Number(foreignScale))
        }
      }
    }
  }
  return result
}

export function formartMoney(value) {
  const result = { ...value }
  const { standard, foreign, standardScale, foreignScale } = result
  if (calculateAble([standard, standardScale])) {
    result.standard = new Big(standard).toFixed(Number(standardScale))
  }
  if (hasForeign(result) && calculateAble([foreign, foreignScale])) {
    result.foreign = new Big(foreign).toFixed(Number(foreignScale))
  }
  return result
}

function calculateAble(iterms = []) {
  let result = true
  iterms.forEach(e => {
    if (isNaN(e) || e === '' || e === null || e === undefined) result = false
  })
  return result
}

export function format0MoneyFrom(amount) {
  if (!amount) return standardValueMoney(amount)
  const result = { ...amount }
  result.standard = '0.00'
  if (hasForeign(result)) {
    result.foreign = '0.00'
  }
  return result
}

export function isMoneyObject(obj) {
  return obj && typeof obj === 'object'
}

export function hasForeign(value) {
  return !!value && !!value.foreignStrCode
}

export function hasBudget(value) {
  return !!value && !!value.budgetStrCode
}

export const BudgetStrCode = 'CNY'

export function updateCurrencyValueByDimention(value, currency, rates = [], isChangeDimension, others = {}) {
  if (!value) {
    return value
  }
  const { strCode, numCode, symbol, unit, scale } = currency
  const result = { ...value }
  result.standardNumCode = numCode
  result.standardScale = scale
  result.standardStrCode = strCode
  result.standardSymbol = symbol
  result.standardUnit = unit
  const useOriginForeign = others?.useOriginForeign
  const useSystemRate = others?.useSystemRate
  if (hasForeign(value)) {
    const rate = rates.find(rate => rate.strCode === value.foreignStrCode)
    if (rate) {
      let computeRate = value?.rate || rate.rate
      if (useSystemRate) {
        computeRate = rate.rate
        result.rate = rate.rate
      }
      if (value.standard !== undefined) {
        const originForeign = value?.foreign
        let nowForeign = new Big(value.standard).div(computeRate).toFixed(rate.scale)
        if (useOriginForeign && originForeign !== undefined && nowForeign !== originForeign) {
          nowForeign = originForeign
        }
        result.foreign = nowForeign
      }
      result.foreignNumCode = rate.numCode
      result.foreignScale = rate.scale
      result.foreignStrCode = rate.strCode
      result.foreignSymbol = rate.symbol
      result.foreignUnit = rate.unit
    } else {
      delete result.foreign
      delete result.foreignNumCode
      delete result.foreignScale
      delete result.foreignStrCode
      delete result.foreignSymbol
      delete result.foreignUnit
    }
  }
  if (result.standardStrCode !== BudgetStrCode) {
    const budgetCurrency = rates.find(rate => rate.strCode === BudgetStrCode)
    if (budgetCurrency && budgetCurrency.budgetRate && Number(budgetCurrency.budgetRate)) {
      // result.budgetRate = isChangeDimension ? budgetCurrency.budgetRate : value?.budgetRate || budgetCurrency.budgetRate
      result.budgetRate = budgetCurrency.budgetRate
      result.budgetNumCode = budgetCurrency.numCode
      result.budgetScale = budgetCurrency.scale
      result.budgetStrCode = budgetCurrency.strCode
      result.budgetSymbol = budgetCurrency.symbol
      result.budgetUnit = budgetCurrency.unit
      if (value.standard !== undefined && result.budgetRate) {
        result.budget = new Big(value.standard).div(result.budgetRate).toFixed(budgetCurrency.scale)
      } else {
        delete result.budget
      }
    } else {
      delete result.budget
      delete result.budgetNumCode
      delete result.budgetScale
      delete result.budgetStrCode
      delete result.budgetSymbol
      delete result.budgetUnit
      delete result.budgetRate
    }
  } else if (hasBudget(value) && result.standardStrCode === BudgetStrCode) {
    delete result.budget
    delete result.budgetNumCode
    delete result.budgetScale
    delete result.budgetStrCode
    delete result.budgetSymbol
    delete result.budgetUnit
    delete result.budgetRate
  }
  return result
}

export function updateDetailsMoneyValue(details = [], currency, rates = [], others = {}) {
  return details.map(d => {
    const { specificationId, feeTypeForm } = d
    const moneyComponents = specificationId.components.filter(cp => cp.type === 'money')
    moneyComponents.forEach(cp => {
      const value = feeTypeForm[cp.field]
      if (!!value) {
        feeTypeForm[cp.field] = updateCurrencyValueByDimention(value, currency, rates, undefined, others)
      }
    })
    if (feeTypeForm?.apportions?.length) {
      feeTypeForm.apportions.forEach(apportion => {
        apportion.apportionForm.apportionMoney = updateCurrencyValueByDimention(
          apportion.apportionForm.apportionMoney,
          currency,
          rates,
          others
        )
      })
      feeTypeForm.apportions = feeTypeForm.apportions.slice()
    }
    return d
  })
}

// 货币参考示例
// {
//   "numCode": "156",
//   "strCode": "CNY",
//   "scale": 2,
//   "name": "人民币",
//   "symbol": "￥",
//   "unit": "元",
//   "icon": "https://images.ekuaibao.com/currency/cny.png",
//   "rate": "1",
//   "startTime": 1512544620000,
//   "endTime": 4638916800000,
//   "id": "U5M6uhTVjQ6c00",
//   "version": 1,
//   "active": true,
//   "createTime": 1512544669795,
//   "updateTime": 1512544669795,
//   "corporationId": "VMU6hlsYeg0000"
// }
