import { __assign } from "tslib";
import React from 'react';
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util';
import { hasForeign } from '../../../components/utils/fnCurrencyObj';
import Big from 'big.js';
import { standardValueMoney } from '../../../lib/misc';
import { app as api } from '@ekuaibao/whispered';
import { Popover } from 'antd';
import { renderPayDetail } from '../../../lib/lib-util';
import { uuid } from '@ekuaibao/helpers';
import { getV } from "@ekuaibao/lib/lib/help";
export function formatColumn(template, addition) {
    var components = template.components;
    var editable = addition.editable, baseDataPropertiesMap = addition.baseDataPropertiesMap;
    return components.map(function (c) {
        var entity = getNodeValueByPath(baseDataPropertiesMap[c.field], 'dataType.entity');
        return __assign({ title: c.label, dataIndex: c.dataIndex, editable: c.editable ? editable : c.editable, type: c.type, entity: entity, width: c.width }, c);
    });
}
export function formatData(values) {
    if (values === void 0) { values = []; }
    return values.map(function (value) {
        var money = value.dataLinkForm.E_system_支付计划_支付金额;
        money = __assign(__assign({}, money), { standard: Number(money.standard) });
        var payeeInfo = value.dataLinkForm.payeeId
            ? value.dataLinkForm.payeeId
            : value.dataLinkForm.E_system_支付计划_收款信息;
        var legalEntity = getV(value, 'dataLinkForm.legalEntity');
        var E_system_支付计划_legalEntity = getV(value, 'dataLinkForm.E_system_支付计划_legalEntity');
        if (legalEntity && typeof E_system_支付计划_legalEntity === 'string') {
            E_system_支付计划_legalEntity = legalEntity;
        }
        return {
            key: value.dataLinkId,
            money: money,
            payeeInfo: payeeInfo,
            paymentStatus: value.dataLinkForm.E_system_支付计划_支付状态,
            receiptIds: value.receiptId,
            payPlanState: value.state,
            receiptActions: value.receiptId,
            apportions: value.apportions,
            E_system_支付计划_legalEntity: E_system_支付计划_legalEntity,
        };
    });
}
export function formatCreditData(values) {
    if (values === void 0) { values = []; }
    return values.map(function (value) {
        return {
            code: value.code,
            lowest: value.lowest,
            highest: value.highest,
            description: value.description,
            key: uuid(14)
        };
    });
}
export function formatDisplayValue(value, type, dataIndex, model) {
    if (model !== 'credit' && type === 'number' && value) {
        var money = value;
        return "" + Number(money).toFixed(2);
    }
    if (model !== 'credit' && type === 'legalEntity') {
        return value ? value.name : '-';
    }
    if (model === 'credit' && type === 'number' && !value) {
        return 0;
    }
    if (model === 'credit' && type === 'string' && !value) {
        return (React.createElement("span", { className: "disable-btn-class" }, i18n.get('请填写等级描述')));
    }
    if (model !== 'credit' && type !== 'money' && !value) {
        return i18n.get('请选择');
    }
    if (model !== 'credit' && dataIndex === 'payeeInfo') {
        if (typeof value === 'object') {
            return getPayPopover(value);
        }
        else {
            var payeeList = api.getState('@common.payees') || [];
            var payee = payeeList.length && payeeList.find(function (payee) { return payee.id === value; });
            return getPayPopover(payee);
        }
    }
    return value;
}
function getPayPopover(payee) {
    if (!payee)
        return null;
    return (React.createElement(Popover, { content: renderPayDetail.call(this, payee) },
        React.createElement("div", { className: "account" },
            React.createElement("div", null, payee.accountName || payee.name))));
}
export function editCellIsReadOnly(dataIndex, rule) {
    if (dataIndex !== 'apportionMoney' && dataIndex !== 'apportionPercent') {
        return false;
    }
    if (rule === 'ALL') {
        return false;
    }
    if (dataIndex === 'apportionMoney') {
        return rule === 'PERCENTAGE';
    }
    if (dataIndex === 'apportionPercent') {
        return rule === 'MONEY';
    }
    return false;
}
export function getMoneyValue(value) {
    if (!value) {
        return value;
    }
    if (hasForeign(value)) {
        return value.foreign;
    }
    return value.standard;
}
export function getMoneySymbol(value) {
    if (!value) {
        return value;
    }
    if (hasForeign(value)) {
        return value.foreignSymbol;
    }
    return value.standardSymbol;
}
export function formatMoney(value, feeAmount, currency) {
    if (!value) {
        value = standardValueMoney('0.00');
    }
    if (value && !value.standard) {
        value = standardValueMoney(value, currency);
    }
    if (!hasForeign(feeAmount)) {
        return {
            standard: value.standard,
            standardNumCode: value.standardNumCode,
            standardScale: value.standardScale,
            standardStrCode: value.standardStrCode,
            standardSymbol: value.standardSymbol,
            standardUnit: value.standardUnit
        };
    }
    var rate = feeAmount.rate, foreignScale = feeAmount.foreignScale, foreignNumCode = feeAmount.foreignNumCode, foreignStrCode = feeAmount.foreignStrCode, foreignSymbol = feeAmount.foreignSymbol, foreignUnit = feeAmount.foreignUnit, sysRate = feeAmount.sysRate;
    value.foreign = new Big(value.standard).div(rate).toFixed(foreignScale);
    value.foreignScale = foreignScale;
    value.foreignNumCode = foreignNumCode;
    value.foreignStrCode = foreignStrCode;
    value.foreignSymbol = foreignSymbol;
    value.foreignUnit = foreignUnit;
    value.rate = rate;
    value.sysRate = sysRate;
    return value;
}
