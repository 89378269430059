/**************************************************
 * Created by kaili on 2017/8/18 上午12:26.
 **************************************************/
import { app as api } from '@ekuaibao/whispered'
import { getMoney } from '../../lib/misc'
import { get, forIn } from 'lodash'
import { isObject } from '@ekuaibao/helpers'
import { checkIsRemuneration } from '../../lib/lib-util'
export function required(field, value) {
  let { optional, label, inputVisible, dataType = {} } = field
  if (inputVisible === false) {
    return undefined
  }
  if (value && typeof value === 'string') {
    value = value.trim()
  }
  if (!value && typeof value === 'boolean') {
    return undefined
  }

  if (
    dataType.type === 'ref' &&
    value &&
    isObject(value) &&
    !Array.isArray(value) &&
    !value.id &&
    !value.multiplePayeesMode
  ) {
    value = ''
  }

  if (!optional && (!value || value.length === 0)) {
    return i18n.get('not-empty', { label: i18n.get(label) })
  }

  return undefined
}
export function dataLinkEditValidator(field, rule, value, callback) {
  const { importMode, fieldBus, optional } = field
  if (importMode === 'SINGLE' && value) {
    let val = value.length > 0 && value[0].dataLinkForm ? Object.values(value[0].dataLinkForm).find(i => !!i) : 0
    if (fieldBus.getValueWithValidate && (!optional || (val && optional))) {
      fieldBus.setValidateLevel(0)
      return fieldBus
        .getValueWithValidate(rule.level)
        .then(_ => {
          callback()
        })
        .catch(_ => {
          callback(' ')
        })
    }
    callback(requiredDataLinkEdit(field, value))
  } else {
    callback(required(field, value))
  }
}
export function requiredDataLinkEdit(field, value) {
  let { optional, label, inputVisible, dataType = {} } = field
  if (inputVisible === false) {
    return undefined
  }
  if (value && typeof value === 'string') {
    value = value.trim()
  }

  if (dataType.type === 'ref' && value && isObject(value) && !value.id) {
    value = ''
  }

  if (!optional) {
    if (!value || value.length === 0 || (value && value[0] && !value[0].dataLinkTemplateId) || value === '')
      return i18n.get('not-empty', { label: i18n.get(label) })
  }

  return undefined
}

export function tripDataLinkEditValidator(field, value) {
  let errorMsg = undefined
  if (!value || value.length === 0) {
    if (!field.optional) {
      errorMsg = i18n.get('not-empty', { label: i18n.get(field.label) })
    }
  } else {
    field.child && field.child.handleSaveTrip()
    const { dataLinkForm } = value[0]
    forIn(dataLinkForm, function(value, key) {
      const label = key.split('_')[2]
      if (value === '' || value == undefined) {
        if (!field.optional) {
          errorMsg = i18n.get('not-empty', { label: i18n.get(label) })
        }
      }
    })
  }
  return errorMsg
}

export function fnCheckCompleted({ value = [], isTrip = false, billSpecification, mltiplePayeeObj = {} }) {
  const billType = billSpecification?.type
  let label
  if (isTrip) {
    label = i18n.get('行程记录')
  } else {
    switch (billType) {
      case 'requisition':
        label = i18n.get('申请明细')
        break
      case 'expense':
        label = i18n.get('费用明细')
        break
      case 'reconciliation':
        label = i18n.get('对账明细')
        break
      case 'settlement':
        label = i18n.get('结算明细')
        break
      default:
        label = i18n.get('报销明细')
    }
  }
  const obj = get(billSpecification, 'components', []).find(item => item.type === 'details')
  const isHideBillDetail = get(obj, 'hide', false)
  const isRequired = get(obj, 'optional', false)
  if (isHideBillDetail) return
  const isRemuneration = checkIsRemuneration(billSpecification)
  if (isRemuneration) return
  if (!value.length && !isRequired) {
    return i18n.get(`{__k0}不能为空`, { __k0: label })
  }
  if (!isTrip) {
    let error = undefined
    let approtionError= undefined
    let totalAmount = 0
    const { multiplePayeesMode, payPlanMode } = mltiplePayeeObj
    const payeeInfo = billSpecification?.components.find(item => item.field === 'payeeId')
    value.forEach(v => {
      v.errorMsg = v.errorMsg || {}
      totalAmount = new Big(totalAmount).add(getMoney(v.feeTypeForm.amount))
      let { feeTypeForm, specificationId = {} } = v
      let { components } = specificationId
      let cc = components
      if (
        multiplePayeesMode && !payPlanMode &&
        cc.findIndex(item => item.type === 'payeeInfo') <= -1 &&
        !feeTypeForm.feeDetailPayeeId
      ) {
        if (payeeInfo) {
          components.push(payeeInfo)
        }
      } else if (!multiplePayeesMode || (multiplePayeesMode && payPlanMode)) {
        // 切回单收款人模式时去掉明细里的收款信息的校验
        cc = components.filter(item => item.field !== 'payeeId' && item.field !== 'feeDetailPayeeId')
      }
      const pay = billSpecification.configs.find(v => v.ability === 'pay')
      const requiredComponents = cc.filter(v => {
        const noConfigs = !Array.isArray(v.configs) || (Array.isArray(v.configs) && !v.configs.length)
        if (pay) {
          return v.optional === false && noConfigs && v.field !== 'feeDetailPayeeId'
        } else {
          return v.optional === false && noConfigs
        }
      })
      const result = requiredComponents.filter(
        c =>
          feeTypeForm[c.field] === undefined ||
          feeTypeForm[c.field] === '' ||
          (Array.isArray(feeTypeForm[c.field]) && !feeTypeForm[c.field].length) ||
          (c.type === 'payeeInfo' && isHavePayeeInfo(feeTypeForm, c)) ||
          taxInvalid(feeTypeForm[c.field], c)
      )
      if (result.length) {
        error = i18n.get('消费明细填写不完整；')
        v.errorMsg['completed'] = error
      } else if (feeTypeForm.apportions?.length) {
        let hasDisactiveDimension = false
        let hasDisactiveDataLink = false
        const hasDisactive = feeTypeForm.apportions.filter(line => {
          return (
            Object.keys(line.apportionForm).filter(key => {
              if (line.apportionForm[key] && line.apportionForm[key].active === false) {
                if (line.apportionForm[key].entityId) {
                  hasDisactiveDataLink = true
                } else {
                  hasDisactiveDimension = true
                }
                return true
              }
              return false
            }).length > 0
          )
        }).length > 0

        let errorText = '分摊中存在已停用的'
        if (hasDisactiveDimension) {
          errorText += '自定义档案'
        }
        if (hasDisactiveDataLink) {
          errorText += hasDisactiveDimension
            ? "、业务对象；"
            : "业务对象；"
        }
        error = hasDisactive ? errorText : ''
        v.errorMsg['completed'] = error
        //校验联查未赋值
        if (!error && api.getState()['@common'].powers.customizeQuery) {
          const dataLinkComponentFields = []
          feeTypeForm.apportions[0]?.specificationId?.components?.forEach(comp => {
            if (comp.defaultValue.type === 'customizeQuery') {
              dataLinkComponentFields.push(comp.field)
            }
          })
          if (dataLinkComponentFields.length) {
            const emptyItem = feeTypeForm.apportions.find(el => {
              let hasEmptyField = false
              dataLinkComponentFields.forEach(fieldKey => {
                if (!hasEmptyField && !el.apportionForm[fieldKey]) {
                  hasEmptyField = true
                  return true
                }
              })
              return hasEmptyField
            })
            if (emptyItem) {
              approtionError = i18n.get('分摊明细不完整')
              v.errorMsg['completed'] = approtionError
            }else{
              v.errorMsg['completed'] = ''
            }
          }
        }
        //检查分摊模板其它配置
        const hasOtherConfigErr = fnCheckApportionOtherConfig(feeTypeForm.apportions)
        if (hasOtherConfigErr) {
          v.errorMsg['completed'] = hasOtherConfigErr
        } 
      } else if (v.errorMsg['isCheckCalAttr'] && v.errorMsg['completed']) {
      } else {
        v.errorMsg['completed'] = ''
      }
    })

    if (billSpecification) {
      const { type, configs } = billSpecification
      if (
        type !== 'requisition' && type !== 'receipt' &&
        !configs.find(v => v.ability === 'chargeAgainst') &&
        new Big(totalAmount).lte(0)
      ) {
        //冲销允许金额为负
        return i18n.get('消费记录总额不能小于0')
      }
    }

    return error || approtionError
  }
}
export function fnCheckApportionOtherConfig(apportions){
  //检查分摊其它配置
  if(apportions && apportions.length){
    const apportionConfig = get(apportions[0],'specificationId.configs') 
    const otherConfig = apportionConfig.find(item=>item.ability === 'apportionOtherConfig')
    const {apportionDetailsAtLeastTwo,apportionSingleItemNotEqualOne} = otherConfig || {}
    if (apportionDetailsAtLeastTwo && apportions.length < 2) {
      return i18n.get('分摊明细数量至少2条')
    } else if (apportionSingleItemNotEqualOne) {
      const hasOne = apportions.some((apportion) => {
        const { apportionPercent } = apportion.apportionForm
        return apportionPercent == 100
      })
      if (hasOne) {
        return i18n.get('单条分摊比例不可等于100%')
      }
    }
  }
}
// 兼容账户的老数据 (不是做了数据升级了吗,咋还有老数据)
function isHavePayeeInfo(feeTypeForm, c) {
  return feeTypeForm[c.field].hasOwnProperty('asPayee')
    ? feeTypeForm[c.field].sort !== 'OTHER' && feeTypeForm[c.field].sort !== 'WEIXIN' && !feeTypeForm[c.field].accountNo
    : !feeTypeForm[c.field].cardNo
}

function taxInvalid(value, component) {
  return get(component, 'defaultValue.type') === 'invoiceSum' && (value === undefined || value === null)
}

export function validatorMoney(value, max, min, callback, field) {
  //const { optional } = field
  //if (optional) {
  //  return callback()
  //}
  const { editable } = field
  let {
    standard,
    standardStrCode,
    standardScale,
    rate,
    foreign,
    foreignStrCode,
    foreignScale,
    budget,
    budgetStrCode,
    budgetScale,
    budgetRate
  } = value

  if (standardStrCode) {
    const { result, commond } = _regExpMoneyFormat(standard, standardStrCode, standardScale)
    if (!result) {
      return callback(commond)
    }
  }

  const maxLimit = 1000000000000000
  if (foreignStrCode) {
    const { result, commond } = _regExpMoneyFormat(foreign, foreignStrCode, foreignScale)
    if (!result) {
      return callback(commond)
    }
    if (foreign * 1 > maxLimit) {
      return callback(i18n.get('amount-cannot-be-greater', { standardStrCode: foreignStrCode, max: maxLimit }))
    }
    if (foreign * 1 < -1000000000 * 1) {
      return callback(i18n.get('amount-cannot-be-less', { standardStrCode: foreignStrCode, min: -1000000000 }))
    }
    if (editable && foreign * 1 > max * 1) {
      return callback(i18n.get('amount-cannot-be-greater', { standardStrCode: foreignStrCode, max }))
    }
    if (editable && foreign * 1 < min * 1) {
      return callback(i18n.get('amount-cannot-be-less', { standardStrCode: foreignStrCode, min }))
    }
    if (rate <= 0) {
      return callback(i18n.get('输入的汇率必须大于0'))
    }
    let re = /^([1-9]\d*(\.\d+)?$)|^0(\.\d+)$/
    if (!re.test(rate)) {
      return callback(i18n.get('输入的汇率的格式不正确'))
    }
  }

  if (budgetStrCode) {
    const { result, commond } = _regExpMoneyFormat(budget, budgetStrCode, budgetScale)
    if (!result) {
      return callback(commond)
    }
    if (budget * 1 > maxLimit) {
      return callback(i18n.get('amount-cannot-be-greater', { standardStrCode: budgetStrCode, max: maxLimit }))
    }
    if (budget * 1 < -1000000000 * 1) {
      return callback(i18n.get('amount-cannot-be-less', { standardStrCode: budgetStrCode, min: -1000000000 }))
    }
    if (budget * 1 > max * 1) {
      return callback(i18n.get('amount-cannot-be-greater', { standardStrCode: budgetStrCode, max }))
    }
    if (budget * 1 < min * 1) {
      return callback(i18n.get('amount-cannot-be-less', { standardStrCode: budgetStrCode, min }))
    }
  }
  if (!editable && standard * 1 > maxLimit) {
    return callback(i18n.get('amount-cannot-be-greater', { standardStrCode, max: maxLimit }))
  }
  if (!editable && standard * 1 < -1000000000 * 1) {
    return callback(i18n.get('amount-cannot-be-less', { standardStrCode, min: -1000000000 }))
  }
  if (editable && standard * 1 > max * 1) {
    return callback(i18n.get('amount-cannot-be-greater', { standardStrCode, max }))
  }
  if (editable && standard * 1 < min * 1) {
    return callback(i18n.get('amount-cannot-be-less', { standardStrCode, min }))
  }
}

export function checkPhone(field, value) {
  if (!value || /^((\+?86)|(\(\+86\)))?\d{2,15}$/.test(value)) {
    return undefined
  } else {
    return i18n.get('手机格式不正确')
  }
}

function _regExpMoneyFormat(money, moneyStrCode, moneyScale) {
  if (money === undefined || money === '') {
    return { result: false, commond: i18n.get('请输入金额') }
  }
  let moneyRe = new RegExp(`^(-?)(([1-9]\\d*)|0)(\\.\\d{0,${moneyScale}})?$`)
  let numberFormatFlag = moneyRe.test(money)
  if (!numberFormatFlag) {
    return { result: false, commond: _commondStr(moneyStrCode, moneyScale) }
  }
  return { result: true }
}

function _commondStr(strCode, scale) {
  if (scale === 0) {
    return i18n.get('strCode', { strCode })
  }
  return i18n.get('strCode-scale', { strCode, scale })
}

export function checkedEmail(value) {
  let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  if (!value) {
    return i18n.get('邮箱不能为空')
  } else if (!reg.test(value)) {
    return i18n.get('请输入正确格式的邮箱地址')
  }
}

export function checkedAddress(value) {
  let reg = /^[0-9_.-]+$/
  if (!value) {
    return i18n.get('地址不能为空')
  } else if (reg.test(value)) {
    return i18n.get('请输入正确格式的地址')
  }
}
