import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { Input, InputNumber } from 'antd'

export default class EKBInput extends PureComponent {
  onChange = e => {
    let { onChange } = this.props
    onChange && onChange(e.target.value)
  }
  onNumChange = value => {
    let { onChange } = this.props
    onChange && onChange(value)
  }

  onClick = () => {}

  render() {
    const {
      value,
      placeholder,
      disabled,
      addonBefore,
      addonAfter,
      className,
      size = 'large',
      type,
      onClick,
      editable = true
    } = this.props
    if (type !== 'number') {
      return (
        <div className={classnames('input-wrapper', className)}>
          <Input
            value={value}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            size={size}
            disabled={disabled}
            placeholder={placeholder}
            onChange={this.onChange}
            onClick={onClick}
            onFocus={e => {
              !editable ? e.target.blur() : () => {}
            }}
          />
        </div>
      )
    }
    return (
      <div className={classnames('input-wrapper', className)}>
        <InputNumber
          value={value}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          size={size}
          disabled={disabled}
          placeholder={placeholder}
          onChange={this.onNumChange}
        />
      </div>
    )
  }
}
