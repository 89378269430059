import { __assign, __decorate, __extends, __read } from "tslib";
import React, { PureComponent } from 'react';
import { EnhanceField } from '@ekuaibao/template';
import styles from './DateSelectItem.module.less';
import { Checkbox, Input } from 'antd';
import { wrapper } from '../../../../components/layout/FormWrapper';
import { memoize, cloneDeep, get } from 'lodash';
var DateSelectItem = (function (_super) {
    __extends(DateSelectItem, _super);
    function DateSelectItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleOnChecked = function (e) {
            var _a = _this.props, onChange = _a.onChange, name = _a.field.name, value = _a.value, bus = _a.bus;
            bus.setValidateLevel(1);
            var isChecked = e.target.checked;
            onChange && onChange(__assign(__assign({}, value), { isChecked: isChecked, type: name }));
        };
        _this.handleChangeRange = memoize(function (field) { return function (e) {
            var _a = _this.props, onChange = _a.onChange, value = _a.value;
            var cValue = cloneDeep(value.values);
            _this.setState({ field: e.currentTarget.value });
            var _b = __read(field.split('.'), 2), a = _b[0], b = _b[1];
            cValue[a][b] = e.currentTarget.value;
            value.values = cValue;
            onChange && onChange(__assign({}, value));
        }; });
        return _this;
    }
    DateSelectItem.prototype.renderDateSelect = function (parameters) {
        var value = parameters.value, monthFn = parameters.monthFn, dayFn = parameters.dayFn;
        var placeholder = this.props.field.placeholder;
        return (React.createElement("div", { className: "dateSelect-content-item" },
            React.createElement(Input, { value: value && value.month, onChange: monthFn, placeholder: placeholder }),
            React.createElement("div", { className: "month-day" }, i18n.get('月')),
            React.createElement(Input, { value: value && value.day, onChange: dayFn, placeholder: placeholder }),
            React.createElement("div", { className: "month-day" }, i18n.get('日'))));
    };
    DateSelectItem.prototype.render = function () {
        var _a = this.props, showLabel = _a.field.showLabel, _b = _a.value, isChecked = _b.isChecked, disabled = _b.disabled, values = _b.values;
        return (React.createElement("div", { className: styles['dateSelect-wrapper'] },
            React.createElement("div", { className: "dateSelect-title" },
                React.createElement(Checkbox, { onChange: this.handleOnChecked, defaultChecked: isChecked, disabled: disabled },
                    React.createElement("span", null, showLabel)),
                React.createElement("div", { className: "dateSelect-content" }, isChecked && (React.createElement("div", { className: "dateSelect-content-wrap" },
                    this.renderDateSelect({
                        value: values && values.start,
                        monthFn: this.handleChangeRange('start.month'),
                        dayFn: this.handleChangeRange('start.day')
                    }),
                    React.createElement("div", null, "~"),
                    this.renderDateSelect({
                        value: values && values.end,
                        monthFn: this.handleChangeRange('end.month'),
                        dayFn: this.handleChangeRange('end.day')
                    })))))));
    };
    DateSelectItem = __decorate([
        EnhanceField({
            descriptor: {
                type: 'dimension-date-select'
            },
            validator: function (field) { return function (rule, value, callback) {
                if (rule.level === 1) {
                    return callback();
                }
                var isChecked = (value && value).isChecked;
                var showLabel = field.showLabel;
                if (isChecked) {
                    var sMonth = get(value, 'values.start.month'), sDay = get(value, 'values.start.day'), eMonth = get(value, 'values.end.month'), eDay = get(value, 'values.end.day');
                    if (!/^[0-9]*$/.test(sMonth) || !/^[0-9]*$/.test(sDay) || !/^[0-9]*$/.test(eMonth) || !/^[0-9]*$/.test(eDay)) {
                        return callback(i18n.get('请输入数字'));
                    }
                    else if (Number(sMonth) > 12 || Number(eMonth) > 12) {
                        return callback(i18n.get('月份不得超过12'));
                    }
                    else if (Number(sDay) > 31 || Number(eDay) > 31) {
                        return callback(i18n.get('日期不得超过31'));
                    }
                    else if ((Number(sMonth) === 2 && Number(sDay) > 29) || (Number(eMonth) === 2 && Number(eDay) > 29)) {
                        return callback(i18n.get('2月份日期不得超过29天'));
                    }
                    else if (!sMonth || !sDay || !eMonth || !eDay) {
                        return callback(i18n.get("\u8BF7\u6DFB\u52A0{__k0}", { __k0: showLabel }));
                    }
                }
                return callback();
            }; },
            initialValue: function (props) {
                var _a = props.field, disabled = _a.disabled, name = _a.name, isChecked = _a.isChecked;
                return {
                    type: name,
                    disabled: disabled,
                    isChecked: isChecked,
                    values: { start: { month: '', day: '' }, end: { month: '', day: '' } }
                };
            },
            wrapper: wrapper()
        })
    ], DateSelectItem);
    return DateSelectItem;
}(PureComponent));
export default DateSelectItem;
