/**************************************************
 * Created by zhaohuabing on 2018/11/1 下午2:06.
 **************************************************/
import { cloneDeep } from 'lodash'
import { globalFieldsMap } from '@ekuaibao/lib/lib/lib-util'
import { app as api } from '@ekuaibao/whispered'

const ExtraRiskWarningWhitelist = {
  expenseMoney: i18n.get('报销金额'),
  payMoney: i18n.get('支付金额'),
  writtenOffMoney: i18n.get('核销金额'),
  requisitionMoney: i18n.get('申请金额'),
  receiptMoney: i18n.get('收款金额')
}

export function getValidRiskWarningData(template, riskWarning) {
  if (!riskWarning) return void 0
  const templateMap = globalFieldsMap(template)
  const riskWarningCopy = cloneDeep(riskWarning)
  const values = Object.keys(riskWarningCopy)
  values.forEach(v => {
    if (!templateMap[v] && !ExtraRiskWarningWhitelist[v]) {
      delete riskWarningCopy[v]
    }
  })
  return riskWarningCopy
}

export function getExtraRiskWarningList(template, riskWarning, multiplePayeesMode) {
  if (!riskWarning) return void 0
  const templateMap = globalFieldsMap(template)
  const arr = []
  const values = Object.keys(riskWarning)
  values.forEach(v => {
    if (!templateMap[v] && ExtraRiskWarningWhitelist[v]) {
      arr.push({ label: ExtraRiskWarningWhitelist[v], value: riskWarning[v] })
    }
    if (v.startsWith('noName')) {
      //针对多收款人做的特殊处理  后台没有给出 code 判断,所以只能这么写了..
      // const isContains = riskWarning[v].find(item => {
      //   return item.messages.find(msg => msg.indexOf('有可核销借款但未核销') > -1)
      // })
      // if (!multiplePayeesMode && !isContains) {
      //   arr.push({ label: '', value: riskWarning[v] })
      // }
      arr.push({ label: '', value: riskWarning[v] })
    }
  })
  return arr.length ? arr : void 0
}

export function disableTemplateField(disableFields = [], template) {
  if (!disableFields?.length) return template
  return template.map(item => {
    let editable = disableFields.includes(item.field) ? false : item.editable
    return {
      ...item,
      editable
    }
  })
}

function operateByType(list, type, data) {
  type === 'standard' && list.push(...data)
  type === 'field' && list.push(data)
}

export function createList(list, type, external) {
  if (!external) return []
  const values = Array.isArray(external) ? external : Object.values(external)
  values.forEach(v => {
    if (Array.isArray(v)) {
      operateByType(list, type, v)
    } else {
      createList(list, type, v)
    }
  })
  return list
}

export function fnIsRiskError(external) {
  if (!external) return false
  let standardList = []
  standardList = Array.isArray(external) ? external : createList(standardList, 'standard', external)
  return Boolean(standardList.find(v => v.isOutOfLimitReject))
}

export function getRiskFieldNum(external) {
  if (!external) return 0
  let fieldList = []
  fieldList = Array.isArray(external) ? external : createList(fieldList, 'field', external)
  return fieldList.length
}

export function getRiskInTemplate(template, riskWarning) {
  if (!riskWarning) return []
  const riskCopy = cloneDeep(riskWarning)
  const templateMap = globalFieldsMap(template)
  let fieldList = []
  for (let key in riskCopy) {
    if (!templateMap[key]) {
      delete riskCopy[key]
    }
  }
  fieldList = Array.isArray(riskCopy) ? riskCopy : createList(fieldList, 'field', riskCopy)
  return fieldList
}

export function setFieldsExternal(props) {
  if (!props) return
  let { riskWarning, bus, nextAndPr } = props
  if (nextAndPr) {
    riskWarning = riskWarning || {}
  }
  riskWarning && bus?.setFieldsExternalsData?.({ ...riskWarning })
}

import { emitResetFieldsExternals } from './emitResetFieldsExternals'

export { emitResetFieldsExternals }

export function resetFieldsExternalsForBillInfoEditable(data, props, state, fn) {
  if (!data) return
  const { dataSource } = props
  const isHiddenRiskWarning = !!~['modify'].indexOf(dataSource.state)
  let flag = false
  const { formRiskWarningData, template } = state
  const formRiskWarnings = cloneDeep(formRiskWarningData)
  if (data.externalFlag) {
    data = data.externalToFather
    flag = true
  }
  data = Array.isArray(data) ? data : [data]
  data.forEach(v => {
    const { path, pathValueId, controlField } = v
    switch (path) {
      case 'FORM':
        formRiskWarnings[controlField] && delete formRiskWarnings[controlField]
        break
      case 'FEE_DETAIL':
        delete formRiskWarnings.details[pathValueId][controlField]
        break
      case 'APPORTION':
        // const [id, idx] = pathValueId.split('$')
        let id = pathValueId.split('$ID_')[0]
        let idx = pathValueId.substring(id.length + 1)
        if (flag) {
          //如果修改分摊中的一个，并强制保存，在草稿状态删除该明细下所有分摊中的超标提醒，等提交时再次判断是否超标
          for (let key in formRiskWarnings.details[id].apportions) {
            delete formRiskWarnings.details[id].apportions[key][controlField]
          }
        } else {
          delete formRiskWarnings.details[id].apportions[idx][controlField]
        }
        break
      case 'TRIP_DETAIL':
        delete formRiskWarnings.trips[pathValueId][controlField]
        break
    }
  })
  const extraRiskWarningList = getExtraRiskWarningList(template, formRiskWarnings)
  const riskInTemplateList = getRiskInTemplate(template, formRiskWarnings)
  const riskFieldNum = isHiddenRiskWarning ? 0 : getRiskFieldNum(formRiskWarnings)
  let params = {
    formRiskWarningData: formRiskWarnings,
    extraRiskWarningList,
    riskFieldNum,
    riskInTemplateList
  }
  fn(params, formRiskWarnings, isHiddenRiskWarning)
}

export function delDetailsExternalsForBillInfoEditable(delDetailIds, state, fn) {
  if (!delDetailIds.length) return
  const { formRiskWarningData, template } = state
  const formRiskWarnings = cloneDeep(formRiskWarningData)
  delDetailIds.forEach(v => {
    if (!!formRiskWarnings?.details) {
      delete formRiskWarnings?.details[v]
    }
  })
  const extraRiskWarningList = getExtraRiskWarningList(template, formRiskWarnings)
  const riskInTemplateList = getRiskInTemplate(template, formRiskWarnings)
  const riskFieldNum = getRiskFieldNum(formRiskWarnings)
  let params = {
    formRiskWarningData: formRiskWarnings,
    extraRiskWarningList,
    riskFieldNum,
    riskInTemplateList
  }
  fn(params, formRiskWarnings)
}
