/************************************************
 * Created By nanyuantingfeng On 5/30/16 02:44.
 ************************************************/

import { app as api } from '@ekuaibao/whispered'
import { Fetch } from '@ekuaibao/fetch'
import moment from 'moment'
import { showMessage, showModal } from '@ekuaibao/show-util'
import { getV } from '@ekuaibao/lib/lib/help'
import { cloneDeep, debounce } from 'lodash'
import qs from 'qs'
import { session } from '@ekuaibao/session-info'

export function getPropertiesNameList() {
  let properties = api.getState('@common.globalFields.data')
  return properties
    .filter(line => {
      let {
        dataType: { type, entity }
      } = line
      return type === 'ref' && entity && entity.startsWith('basedata.Dimension')
    })
    .map(line => line.name)
}

export function checkPayerInfo(payerInfo, isOld) {
  const payerInfoArr = api.getState('@common.payerInfo')

  let showError = true
  let checkPayerName = ''
  let checkPayerNumber = ''

  if (payerInfo.payer === '个人') {
    // @i18n-ignore
    return { showError: false }
  }

  const filterNameObj = payerInfoArr.find(el => {
    let invoicePayerName = payerInfo.payer.replace(/（/g, '(').replace(/）/g, ')')
    let companyPayerName = el.name.replace(/（/g, '(').replace(/）/g, ')')
    let historicalCompanyPayerName = el.historicalName.replace(/（/g, '(').replace(/）/g, ')')
    return invoicePayerName === companyPayerName || invoicePayerName === historicalCompanyPayerName
  })
  if (filterNameObj) {
    checkPayerName = true
    if (!payerInfo.payertaxno) {
      checkPayerNumber = 'no'
    } else {
      if (filterNameObj.payerNo === (payerInfo.payertaxno && payerInfo.payertaxno.toUpperCase())) {
        checkPayerNumber = true
        showError = false
      } else {
        checkPayerNumber = false
      }
    }
  } else {
    checkPayerName = false
    if (!payerInfo.payertaxno) {
      checkPayerNumber = 'no'
    } else {
      checkPayerNumber = !!payerInfoArr.find(
        el => el.payerNo === (payerInfo.payertaxno && payerInfo.payertaxno.toUpperCase())
      )
    }
  }

  if (isOld && payerInfo.invdate < 20170701) {
    return { showError: !checkPayerName, checkPayerName, checkPayerNumber: 'noCheck' }
  } else if (!isOld && payerInfo.invdate < 1498838400000) {
    return { showError: !checkPayerName, checkPayerName, checkPayerNumber: 'noCheck' }
  }
  return { showError, checkPayerName, checkPayerNumber }
}

export function formatLang() {
  const lang = Fetch.staffSetting ? Fetch.staffSetting.language : Fetch.defaultLanguage
  return lang.split('-')[0] === 'zh' ? 'name' : lang.split('-')[0] + 'Name'
}

export function goto(path) {
  if (location.hash.substring(1) !== path) {
    console.log('=====start-lib-util-goto-log=====')
    console.log(path)
    console.log('=====end--log=====')
    const { provisionalToken, billentry } = qs.parse(location.search.slice(1))
    if (billentry && provisionalToken?.length) {
      // 补偿一下，如果是单据入口进入的，就取provisionalToken上面的token，provisionalToken和token是一样的
      // 设置完之后就把provisionalToken设置为空
      const params = Fetch.makeUrlParams({
        accessToken: provisionalToken,
        provisionalToken: null
      })
      location.replace('?' + params + location.hash)
      return
    }
    const params = Fetch.makeUrlParams({
      accessToken: null
    })
    location.replace('?' + params + '#' + path)
  }
}

function clusterLogout() {
  const isLogOutUrl = sessionStorage.getItem('isLogOutUrl')
  const params = qs.parse(location.search.slice(1)) || {}
  if (!isLogOutUrl || params.accessToken) {
    const corpId = getCorpId()
    const existGP = corpId?.includes('~GP')
    let logoutUrl = window.PLATFORMINFO?.logoutUrl
    logoutUrl = logoutUrl && existGP ? logoutUrl : ''
    const paramsStr = corpId ? `fromCluster=true&corpId=${corpId}` : ''
    sessionStorage.setItem('isLogOutUrl', 'true')
    location.href = `${logoutUrl}/web/app.html?${paramsStr}#/login`
  }
}

let isLoaded = false
export const handleError = debounce(async error => {
  const code = getV(error, 'code', '')
  const modifyPasswordCode = ['DEFAULT_PASSWORD_MODIFY', 'FORCE_PASSWORD_MODIFY', 'ACCOUNT_LOCK', 'EXPIRE_FORCE_PASSWORD_MODIFY']
  const corpId = Fetch.ekbCorpId || ''
  const message = getV(error, 'errorMessage')
  const title = code === 'ACCOUNT_LOCK' ? i18n.get('账号锁定') : i18n.get('密码过期提醒')

  if (code === 'EXPIRE_FORCE_PASSWORD_MODIFY' && !isLoaded) {
    isLoaded = true
    showModal.warning({
      title: title,
      content: '您的密码已过期,请前往修改密码',
      okText: '确定',
      onOk() {
        isLoaded = false
        session.clear();
        api.go('/forgotPassword5/title')
        return
      }
    })
    return
  }
  if (modifyPasswordCode.includes(code) && !isLoaded) {
    isLoaded = true
    if (['/modifyPassword', '/login'].includes(location.hash.substring(1))) { return }
    showModal.info({
      title: title,
      content: message,
      onOk() {
        isLoaded = false
        if (code === 'ACCOUNT_LOCK') {
          goto('/login')
        } else {
          goto('/modifyPassword')
        }
      }
    })
  } else if (error.status === 401) {
    // 未登录
    if (window.PLATFORMINFO?.clusterURL && window.__PLANTFORM__ !== 'MC') {
      clusterLogout()
    } else if (window.IS_SMG) {
      const isLogOutUrl = sessionStorage.getItem('isLogOutUrl')
      if (window.PLATFORMINFO?.logoutUrl && !isLogOutUrl) {
        sessionStorage.setItem('isLogOutUrl', 'true')
        location.href = window.PLATFORMINFO?.logoutUrl
      }
    } else if (window.inGroupApp && window.APP_URL) {
      // 在集团版中时，跳转至原生APP的登录页
      location.href = `${window.APP_URL}?formGroupApp=true#/login`
    } else {
      // 未登录
      console.log('=====start-lib-util-log=====')
      console.log(error)
      console.log('=====end--log=====')
      goto('/login')
    }
  }
}, 1000)

//打印弹框
export function showPrintModal(flowId, fn) {
  showModal.confirm({
    className: 'print-modal-wrapper',
    iconType: 'info-circle',
    title: i18n.get('请确认打印是否已完成？'),
    content: i18n.get('打印预览已在新窗口中打开。如果没有响应，请确认浏览器是否拦截了新窗口'),
    okText: i18n.get('打印完成'),
    cancelText: i18n.get('打印未完成'),
    onOk() {
      Fetch.PUT(`/api/v1/print/confirm/$[${flowId}]`).then(() => fn && fn())
    }
  })
}

export function renderPayDetail(doc = {}) {
  return (
    <div className="ekb-account-info-popover">
      <div className="header">
        {doc.type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户')} | {doc.accountName || doc.name}
      </div>
      <div className="body">
        <div className="line1">
          <img className="stand-20-icon" src={doc.icon} />
          {doc.bank || doc.unionBank}
        </div>
        <div className="line2 text-nowrap-ellipsis">{doc.accountNo || doc.cardNo}</div>
        <div className="line3">{doc.branch}</div>
      </div>
    </div>
  )
}

export function repaymentDateTip(repaymentDate, state) {
  if (!repaymentDate && repaymentDate !== 0) {
    return false
  }

  if (!moment(repaymentDate).isValid()) {
    return (
      <div className="fs-12 color-gray-9c" key="1">
        {i18n.get('无还款期限')}
      </div>
    )
  }

  if (state && state === 'PAID') {
    return (
      <div className="fs-12 color-gray-9c" key="1">
        {moment(repaymentDate).format(i18n.get('YYYY.MM.DD到期'))}
      </div>
    )
  }

  let rDate = moment(repaymentDate).format('YYYY-MM-DD') + ' 00:00:00'
  let nDate = moment().format('YYYY-MM-DD') + ' 00:00:00'
  let expireDate = moment(rDate).diff(moment(nDate), 'days', false)
  if (expireDate > 0 && expireDate < 7) {
    return (
      <div className="fs-12 color-orange" key="1">
        {i18n.get('expired-after', { expireDate })}
      </div>
    )
  } else if (expireDate < 0) {
    return (
      <div className="fs-12 color-red" key="1">
        {i18n.get('timeout', { expireDate: Math.abs(expireDate) })}
      </div>
    )
  } else if (expireDate == 0) {
    return (
      <div className="fs-12 color-red" key="1">
        {i18n.get('今天到期')}
      </div>
    )
  } else {
    return (
      <div className="fs-12 color-gray-9c" key="1">
        {i18n.get('expires-date', { date: moment(repaymentDate).format('YYYY.MM.DD') })}
      </div>
    )
  }
}

export function repaymentDateTipfromBill(repaymentDate, state) {
  if (!repaymentDate && repaymentDate !== 0) {
    return false
  }

  if (!moment(repaymentDate).isValid()) {
    return <div className="fs-12 color-gray-9c">{i18n.get('无还款期限')}</div>
  }

  if (state && state === 'PAID') {
    return <div className="fs-12 color-gray-9c">{moment(repaymentDate).format(i18n.get('YYYY.MM.DD'))}</div>
  }

  let rDate = moment(repaymentDate).format('YYYY-MM-DD') + ' 00:00:00'
  let nDate = moment().format('YYYY-MM-DD') + ' 00:00:00'
  let expireDate = moment(rDate).diff(moment(nDate), 'days', false)
  if (expireDate > 0 && expireDate < 7) {
    return (
      <div className="fs-14">
        <span className="date-font">{moment(repaymentDate).format(i18n.get('YYYY年MM月DD日'))}</span>
        <span className="color-orange">{i18n.get('expired-after', { expireDate })}</span>
      </div>
    )
  } else if (expireDate < 0) {
    return (
      <div className="fs-14">
        <span className="date-font">{moment(repaymentDate).format(i18n.get('YYYY年MM月DD日'))}</span>
        <span className="color-red">{i18n.get('timeout', { expireDate: Math.abs(expireDate) })}</span>
      </div>
    )
  } else if (expireDate == 0) {
    return (
      <div className="fs-14">
        <span className="date-font">{moment(repaymentDate).format(i18n.get('YYYY年MM月DD日'))}</span>
        <span className="color-red">{i18n.get('今天到期')}</span>
      </div>
    )
  } else {
    return (
      <div className="fs-14 color-gray-9c">
        <span className="date-font">
          {i18n.get('expires-date', { date: moment(repaymentDate).format(i18n.get('YYYY年MM月DD日')) })}
        </span>
      </div>
    )
  }
}

//打印提醒弹框
export function showPrintRemindModal(fn) {
  showModal.info({
    className: 'print-modal-wrapper',
    title: i18n.get('发送打印提醒'),
    okText: i18n.get('确定'),
    content: (
      <div>
        {i18n.get(
          '这些单据的打印状态将会变为「已提醒」，单据打印成功后状态将会变为「已打印」。你可以通过收到打印操作，将打印状态变为「已收单」。'
        )}
        <br />
        {i18n.get('你可以在列表展示里找到「打印状态」字段，并以此进行筛选。')}
      </div>
    ),
    onOk() {
      fn && fn()
    }
  })
}

//校验身份证和手机号的正则
export const checkRegex = {
  phoneAndCardNo: /^(?:1\d{10}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/,
  aliPayAccountNo: /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,20}\.[a-zA-Z\d]{1,20})$/
}

//从url中获取参数
export function filterFromUrl(name = '', defaultValue = null) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r = decodeURIComponent(window.location.search)
    .substr(1)
    .match(reg)
  if (r != null) return unescape(r[2])
  return defaultValue
}

// 保证按照顺序format
const keys = ['staffs', 'departments', 'roles']

export function formatArr(visibility) {
  let arr = []

  if (!visibility) {
    return arr
  }

  keys.forEach(k => {
    if (visibility[k] && visibility[k].length) {
      arr = arr.concat(visibility[k])
    }
  })
  return arr
}

export function checkIsRemuneration(specification) {
  if (!specification?.id) return false
  const remunerationConfig = api.getState()['@remuneration'].remunerationConfig
  const originalId = specification.id.split(':')[0]
  return remunerationConfig?.specificationId == originalId
}

export function fixRemunerationSpecification(specification) {
  const components = specification?.components ?? []
  let detailsConfig = components.find(c => c.field === 'details')
  if (detailsConfig?.hide) {
    detailsConfig.hide = false
  }
}

export function getDeptItemsByIds(list = [], ids = []) {
  let items = []
  let fn = item => {
    if (ids.indexOf(item.id) > -1) {
      items.push(item)
    }

    item.children = item.children || []
    if (item.children.length) {
      item.children.forEach(c => {
        fn(c)
      })
    }
  }

  list.forEach(item => {
    fn(item)
  })
  return items
}

export function getItemByIds(data = [], ids = []) {
  if (data.length === 0 || ids.length === 0) return []
  return data.filter(line => {
    return ids.indexOf(line.id) > -1
  })
}

export const getCheckedKeys = (checkedList, key) => {
  const value = checkedList.find(o => o.type === key) || {}
  if (!value.checkedKeys) return []
  return value.checkedKeys.filter(v => v)
}

export const getWeek = value => {
  moment.updateLocale('zh-cn', {
    weekdays: [
      i18n.get('周日'),
      i18n.get('周一'),
      i18n.get('周二'),
      i18n.get('周三'),
      i18n.get('周四'),
      i18n.get('周五'),
      i18n.get('周六')
    ]
  })
  return moment(value).format('dddd')
}

// 申请单回退校验行程是否可编辑
export const getTripEditable = (trip, tripList) => {
  // const curTrip = tripList.find(i => i.travelInfoId === trip.dataLinkId)
  // return curTrip?.isWithdrawn ?? true
  return !tripList.find(i => i === trip.dataLinkId)
}

export const PAYEE_INFO = {
  ability: 'feeDetail',
  active: true,
  canAsDimension: false,
  cnLabel: '收款信息',
  cnPlaceholder: null,
  dataType: { type: 'ref', entity: 'pay.PayeeInfo' },
  defaultValue: { value: 'entity.default', type: 'predefine' },
  dependence: null,
  editable: true,
  enLabel: '收款信息',
  enPlaceholder: null,
  field: 'feeDetailPayeeId',
  // field: 'payeeId',
  hide: false,
  label: '收款信息',
  name: 'feeDetailPayeeId',
  // name: 'payeeId',
  optional: false,
  placeholder: '请选择收款信息',
  showInDetails: false,
  type: 'payeeInfo'
}

// 城市围栏校验行程规划是否可清除|过滤人员id
export const clearableTripDataLink = (curTravelers, preTravelers, submitter) => {
  let clearable = true
  let travelerId = ''
  const submitterId = submitter.id
  if (curTravelers.length > 1) {
    clearable = false
  }
  if (curTravelers.length === 1) {
    travelerId = curTravelers[0].id
    if (preTravelers.length === 1 && curTravelers[0].id === preTravelers[0].id) {
      clearable = false
    }
    if (preTravelers.length === 0 && curTravelers[0].id === submitterId) {
      clearable = false
    }
  }
  if (curTravelers.length === 0) {
    travelerId = submitterId
    if (preTravelers.length === 1 && preTravelers[0].id === submitterId) {
      clearable = false
    }
  }
  return { clearable, travelerId }
}

/**
 * 检查是否登录过集团版APP
 * 登录过：直接跳转到集团APP
 * 没登录过：不做操作
 * formGroupApp有值时，不跳转至集团APP，维持原生原逻辑
 */
export const checkGroupAPPLogin = () => {
  // 上次是否登陆到集团版易快报
  const wereRedirectToGroupApp = localStorage.getItem('wereRedirectToGroupApp')
  // 是否从集团APP中返回
  const params = qs.parse(location.search.slice(1))
  const { formGroupApp } = params
  if (wereRedirectToGroupApp && formGroupApp) {
    localStorage.setItem('wereRedirectToGroupApp', '')
    session.set('user', {})
    Fetch.DELETE('/api/account/v2/session')
    api.go('/login', true)
  } else if (wereRedirectToGroupApp) {
    // 上次登录集团APP的用户，跳转至集团APP
    if (window.GROUP_URL) {
      location.href = window.GROUP_URL
    }
  }
}

/**
 * 获取logo路径
 */
export const getLogoPath = () => {
  //logo.png指向打包后的根目录，方便运维处理私有化项目
  return IS_ICBC ? 'logo_icbc.png' : 'logo.png'
}

/**
 * 集团版APP登录
 */
export const initGroupUser = () => {
  const params = qs.parse(location.search.slice(1)) || {}
  const user = session.get('user') || {}
  const token = params.accessToken || user.accessToken
  const corpId = params.corpId || user.corpId
  session.set('user', {
    corpId,
    accessToken: token
  })
  Fetch.accessToken = token
  Fetch.ekbCorpId = corpId
  window.isNewHome = true
}

export const getDays = (start, end) => {
  if (!start || !end) {
    return 0
  }
  const startString = moment(start).format('YYYY-MM-DD')
  const endString = moment(end).format('YYYY-MM-DD')
  return moment(endString).diff(moment(startString), 'days', false)
}

// 整理支付计划数据
export const formatPayPlanData = payPlans => {
  return (
    payPlans &&
    payPlans.map(item => {
      return {
        dataLinkId: item.key,
        dataLinkForm: {
          E_system_支付计划_支付金额: item.money,
          E_system_支付计划_收款信息: typeof item.payeeInfo === 'object' ? item.payeeInfo.id : item.payeeInfo,
          E_system_支付计划_legalEntity: item.E_system_支付计划_legalEntity?.id
        },
        dataLinkTemplateId: null
      }
    })
  )
}
/**
 * 企业ID是否含有GP
 */
export const isExistGP = () => {
  const corpId = getCorpId()
  const existGP = corpId.includes('~GP')
  return existGP
}

export const getCorpId = () => {
  const params = qs.parse(window.location.search.slice(1))
  const corpId = params?.corpId || Fetch.ekbCorpId || ''
  return corpId
}

/**
 * 初始化accessToken、corpId
 */
export const initUserInfo = async withSession => {
  const params = qs.parse(location.search.slice(1)) || {}
  const user = session.get('user') || {}
  const token = params.accessToken || user.accessToken
  const corpId = params.corpId || user.corpId
  session.set('user', {
    corpId,
    accessToken: token
  })
  Fetch.accessToken = token
  Fetch.ekbCorpId = corpId

  if (params.accessToken && withSession) {
    await getSession(params.accessToken)
  }
}

export const getSession = async accessToken => {
  const params = {}
  params.accessToken = accessToken
  const result = await Fetch.GET('/api/account/v2/session', params)
  Fetch.accessToken = result.value && result.value.id
}
