import React from 'react';
import styles from './PayPlanStatusCell.module.less';
var payPlanStatusMap = {
    '待支付': 'orange',
    '支付失败': 'red',
    '支付中': 'blue',
    '支付成功': 'green'
};
export var PayPlanStatusCell = function (props) {
    var status = props.status;
    if (!status) {
        return null;
    }
    return (React.createElement("div", { className: styles['payPlanStatusCell-wrapper'] }, status && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'payPlanStatusCell-status-block ' + payPlanStatusMap[status] }),
        React.createElement("span", null, i18n.get(status))))));
};
export default PayPlanStatusCell;
