import { __assign, __awaiter, __generator } from "tslib";
import get from 'lodash/get';
import { isObject } from '@ekuaibao/helpers';
import { invoiceOptions } from '../../lib/InvoiceUtil';
import { checkDefaultDeptValue, constantValue, handleGetDataById, lastSelectValue } from './fnInitalValue';
import fnPredefine4Date from './fnPredefine4Date';
import fnPredefine4DateRange from './fnPredefine4DateRange';
import fnPredefine4Number from './fnPredefine4Number';
import { app } from '@ekuaibao/whispered';
import { Fetch } from '@ekuaibao/fetch';
function getDepartmentValue(component, field, lastChoice, submitterId) {
    var constVal = constantValue(component);
    if (constVal) {
        return checkDefaultDeptValue({ id: constVal }, component);
    }
    var lastVal = lastSelectValue(__assign(__assign({}, component), field), lastChoice);
    if (lastVal) {
        return checkDefaultDeptValue({ id: lastVal }, component);
    }
    var _a = component.defaultValue, defaultValue = _a === void 0 ? {} : _a;
    var type = get(component, 'defaultValue.type', '');
    if (type === 'predefine' && !['submit.requisition', 'lastselect'].includes(defaultValue.value)) {
        var defaultDepartment = submitterId.defaultDepartment;
        if (defaultDepartment) {
            var id = defaultDepartment.id, name = defaultDepartment.name, code = defaultDepartment.code;
            return checkDefaultDeptValue({ id: id, name: name, code: code }, component);
        }
        return checkDefaultDeptValue(defaultDepartment, component);
    }
}
function getRefDimensionValue(component, field, lastChoice) {
    return new Promise(function (resolve) {
        var constValue = constantValue(component);
        if (constValue) {
            return handleGetDataById(field, constValue).then(function (data) {
                return resolve(data);
            });
        }
        var lastVal = lastSelectValue(__assign(__assign({}, component), field), lastChoice);
        if (lastVal) {
            return handleGetDataById(field, lastVal).then(function (data) {
                resolve(data);
            });
        }
        resolve();
    });
}
function getRefValue(component, field, lastChoice, submitterId) {
    if (component && component.dependence && component.dependence.length) {
        return Promise.resolve();
    }
    var entity = get(field, 'dataType.entity') || get(field, 'dataType.elemType.entity');
    return new Promise(function (resolve) {
        if (entity === 'organization.Department') {
            resolve(getDepartmentValue(component, field, lastChoice, submitterId));
        }
        else if (entity.startsWith('basedata.Dimension.') ||
            entity === 'organization.Staff' ||
            entity.startsWith('basedata.Enum.') ||
            entity === 'basedata.Settlement') {
            getRefDimensionValue(component, field, lastChoice).then(function (data) {
                resolve(data);
            });
        }
        else {
            resolve();
        }
    });
}
function getInvoiceDefaultValue(component) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return __awaiter(this, void 0, void 0, function () {
        var arr, type, ids, res, invoiceCorporation;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    if (!!component.editable) return [3, 1];
                    return [2, { type: 'noWrite' }];
                case 1:
                    arr = invoiceOptions(component.invoiceType) || [];
                    type = arr.length && arr[0].type;
                    if (!type) return [3, 4];
                    if (!(type === 'unify' && (Fetch === null || Fetch === void 0 ? void 0 : Fetch.ekbCorpId) === 'AxF3GaQGJscELw')) return [3, 3];
                    ids = ((_b = (_a = component === null || component === void 0 ? void 0 : component.invoiceType) === null || _a === void 0 ? void 0 : _a.unify) === null || _b === void 0 ? void 0 : _b.limit) ? (_d = (_c = component === null || component === void 0 ? void 0 : component.invoiceType) === null || _c === void 0 ? void 0 : _c.unify) === null || _d === void 0 ? void 0 : _d.invoiceCorporation : undefined;
                    if (!((ids && ids.length) || !((_f = (_e = component === null || component === void 0 ? void 0 : component.invoiceType) === null || _e === void 0 ? void 0 : _e.unify) === null || _f === void 0 ? void 0 : _f.limit))) return [3, 3];
                    return [4, app.invokeService('@bills:get:invoice:corporation', ids)];
                case 2:
                    res = _j.sent();
                    if (((_g = res === null || res === void 0 ? void 0 : res.items) === null || _g === void 0 ? void 0 : _g.length) && ((_h = res === null || res === void 0 ? void 0 : res.items) === null || _h === void 0 ? void 0 : _h.length) === 1) {
                        invoiceCorporation = res.items[0];
                        return [2, { type: type, invoiceCorporationId: invoiceCorporation.id, invoiceCorporation: invoiceCorporation }];
                    }
                    _j.label = 3;
                case 3: return [2, type === 'unify' ? { type: type, invoiceCorporationId: 'defaults' } : { type: type }];
                case 4: return [2, undefined];
            }
        });
    });
}
function getSwitcherInitialValue(component) {
    var defaultValue = component.defaultValue;
    if (defaultValue && defaultValue.type === 'constant') {
        return defaultValue.value;
    }
    return undefined;
}
export default function getDefaultValue(component, field, lastChoice, submitterId) {
    var _this = this;
    var type = get(field, 'dataType.type');
    return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
        var invoiceDefaultValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (type === 'number') {
                        return [2, resolve(fnPredefine4Number(__assign(__assign({}, component), field)))];
                    }
                    if (type === 'date') {
                        return [2, resolve(fnPredefine4Date(component))];
                    }
                    if (type === 'dateRange') {
                        return [2, resolve(fnPredefine4DateRange(component))];
                    }
                    if (type === 'ref' || (type === 'list' && get(field, 'dataType.elemType.type') === 'ref')) {
                        return [2, getRefValue(component, field, lastChoice, submitterId).then(function (data) {
                                var entity = get(field, 'dataType.elemType.entity', '');
                                if (type === 'list' && entity.startsWith('basedata.Dimension')) {
                                    resolve(isObject(data) ? [data.id] : data);
                                }
                                else {
                                    resolve(data);
                                }
                            })];
                    }
                    if (!(type === 'invoice')) return [3, 2];
                    return [4, getInvoiceDefaultValue(component)];
                case 1:
                    invoiceDefaultValue = _a.sent();
                    return [2, resolve(invoiceDefaultValue)];
                case 2:
                    if (type === 'boolean') {
                        return [2, resolve(getSwitcherInitialValue(component))];
                    }
                    resolve();
                    return [2];
            }
        });
    }); });
}
