import loadable from '@loadable/component';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var Text = loadableWithDescriptor(function () { return import('./dynamic/Text'); }, {
    type: 'text'
});
var Select = loadableWithDescriptor(function () { return import('./dynamic/Select'); }, {
    type: 'select'
});
var CheckBoxGroup = loadableWithDescriptor(function () { return import('./internal/CheckBoxGroup'); }, {
    type: 'checkbox-group'
});
var AccountPeriod = loadableWithDescriptor(function () { return import('./supplier/AccountPeriod'); }, {
    type: 'accountPeriod'
});
var RuleList = loadableWithDescriptor(function () { return import('./supplier/RuleList'); }, {
    type: 'ruleList'
});
var AccountPaymentSetting = loadableWithDescriptor(function () { return import('./supplier/AccountPaymentSetting'); }, {
    type: 'accountPaymentSetting'
});
var BalanceAdjustSetting = loadableWithDescriptor(function () { return import('./supplier/BalanceAdjustSetting'); }, {
    type: 'balanceAdjustSetting'
});
export var supplierConfigEditable = [
    Text,
    Select,
    CheckBoxGroup,
    AccountPeriod,
    RuleList,
    AccountPaymentSetting,
    BalanceAdjustSetting
];
