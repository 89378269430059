import { __assign, __awaiter, __extends, __generator } from "tslib";
import React from 'react';
import { LocaleProvider } from './LocaleProvider';
export function withLoader(loadFn) {
    var Loader = (function (_super) {
        __extends(Loader, _super);
        function Loader() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.unmounted = false;
            _this.state = {
                loaded: false
            };
            return _this;
        }
        Loader.prototype.componentDidMount = function () {
            return __awaiter(this, void 0, void 0, function () {
                var load, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!!window.DevExpress) return [3, 3];
                            return [4, import('@ekuaibao/datagrid/esm/load')];
                        case 1:
                            load = (_b.sent()).load;
                            return [4, load()];
                        case 2:
                            _b.sent();
                            _b.label = 3;
                        case 3:
                            _a = this;
                            return [4, loadFn().then(function (d) { return d.default; })];
                        case 4:
                            _a.DataGridWrapper = _b.sent();
                            if (!this.unmounted) {
                                this.setState({
                                    loaded: true
                                });
                            }
                            return [2];
                    }
                });
            });
        };
        Loader.prototype.componentWillUnmount = function () {
            this.unmounted = true;
        };
        Loader.prototype.render = function () {
            if (!this.state.loaded) {
                return null;
            }
            return (React.createElement(LocaleProvider, null,
                React.createElement(this.DataGridWrapper, __assign({}, this.props))));
        };
        Loader.displayName = 'DevExtreme Loader';
        return Loader;
    }(React.PureComponent));
    return Loader;
}
export default withLoader;
