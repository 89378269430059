import { message } from 'antd'

//延迟1秒关闭loading，为了保证网络请求的连续性
const DELAY_TIME = 1000

export class Loading {
  constructor(name) {
    if (Loading.instance !== null) return Loading.instance
    this.name = name
    this.counter = 0
    this.callCount = 0
    Loading.instance = this
  }

  showLoading(msg) {
    if (this.counter == 0 && !this.hide) {
      this.callCount++
      this.hide = message.loading((i18n && i18n.get(msg)) || (i18n && i18n.get('数据加载中,请稍候...')), 20)
    }
    this.counter++
  }

  hideLoading() {
    const that = this
    const callCount = this.callCount
    setTimeout(function() {
      // 如果短期内再次发起showLoading,直接返回
      if (that.counter <= 0 || callCount !== that.callCount) return
      that.counter--
      if (that.counter == 0) {
        if (that.hide) {
          that.hide()
          that.hide = null
        }
      }
    }, DELAY_TIME)
  }
}

Loading.instance = null

export default new Loading()
