import loadable from '@loadable/component';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var InterconInput = loadableWithDescriptor(function () { return import('./interconnectal/InterconInput'); }, {
    type: 'interconnectal-text'
});
var ImportMethod = loadableWithDescriptor(function () { return import('./interconnectal/ImportMethod'); }, {
    type: 'interconnectal-import-way'
});
var InterconSelect = loadableWithDescriptor(function () { return import('./interconnectal/InterconSelect'); }, {
    type: 'interconnectal-select'
});
var InterconSelectReadonly = loadableWithDescriptor(function () { return import('./interconnectal/InterconSelect.readonly'); }, {
    type: 'interconnectal-select'
});
var InterconRadioGroupReadOnly = loadableWithDescriptor(function () { return import('./interconnectal/InterconRadioGroup.readonly'); }, {
    type: 'interconnectal-select'
});
var InterconSelectRelationReadOnly = loadableWithDescriptor(function () { return import('./interconnectal/InterconSelectRelation.readonly'); }, {
    type: 'interconnectal-select-relation'
});
var EntityFieldReadonly = loadableWithDescriptor(function () { return import('./interconnectal/EntityField.readonly'); }, {
    type: 'entity-field'
});
var InterconTagSelect = loadableWithDescriptor(function () { return import('./interconnectal/InterconTagSelect'); }, {
    type: 'interconnectal-tag-select'
});
var InterconLabel = loadableWithDescriptor(function () { return import('./interconnectal/InterconLable'); }, {
    type: 'interconnectal-label'
});
var RecordLogCheckBox = loadableWithDescriptor(function () { return import('./interconnectal/RecordLogCheckBox'); }, {
    type: 'reacord-log-checkbox'
});
var CheckBox = loadableWithDescriptor(function () { return import('./internal/CheckBox'); }, {
    type: 'checkbox'
});
var Unknown = loadableWithDescriptor(function () { return import('./internal/Unknown'); }, { type: 'unknown' });
var interconnectalDataClear = loadableWithDescriptor(function () { return import('./interconnectal/interconnectalDataClear'); }, {
    type: 'interconnectal-dataclear'
});
var interInitDataCurrency = loadableWithDescriptor(function () { return import('./interconnectal/interInitDataCurrency'); }, {
    type: 'interconnectal-data-currency'
});
var interInitExcelDataSet = loadableWithDescriptor(function () { return import('./interconnectal/interconnectal-excel-dataset'); }, {
    type: 'interconnectal-excel-dataset'
});
export var entityDetail = [
    InterconInput,
    InterconRadioGroupReadOnly,
    InterconSelectReadonly,
    InterconSelect,
    InterconSelectRelationReadOnly,
    EntityFieldReadonly,
    ImportMethod,
    InterconLabel,
    RecordLogCheckBox,
    InterconTagSelect,
    CheckBox,
    Unknown,
    interconnectalDataClear,
    interInitDataCurrency,
    interInitExcelDataSet
];
