var AuthorityEnum = {
    TENANT_LIST: 'TENANT_LIST',
    TENANT_SETTING: 'TENANT_SETTING',
    TENANT_RIGHTS: 'TENANT_RIGHTS',
    INVOICE_FIELD_MAPPING: 'INVOICE_FIELD_MAPPING',
    INVOICE: 'INVOICE',
    FORM: 'FORM',
    ACCOUNT_PEE: 'ACCOUNT_PEE',
    RULE: 'RULE',
    INVOICE_SEPARATED: 'INVOICE_SEPARATED',
    CITY_GRADE: 'CITY_GRADE',
    RECORD: 'RECORD',
    DIMENSION: 'DIMENSION',
    DEPARTMENT: 'DEPARTMENT',
    ROLE: 'ROLE',
    DATALINK: 'DATALINK',
    ACCOUNT_PAY: 'ACCOUNT_PAY',
    BUDGET: 'BUDGET',
    FEETYPE: 'FEETYPE',
    PLAN: 'PLAN',
};
export default AuthorityEnum;
