import { __assign, __extends } from "tslib";
import React, { PureComponent } from 'react';
import CityComponent from '../city/CityComponent';
import { get } from 'lodash';
var StandardCitySelect = (function (_super) {
    __extends(StandardCitySelect, _super);
    function StandardCitySelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleGetSelectData = function () {
            var dataIndex = _this.props.dataIndex;
            var orignalData = _this.fnParseDataById(_this.selectData);
            return { result: _this.selectData, dataIndex: dataIndex, orignalData: orignalData };
        };
        _this.handleOnChange = function (result, orignalData) {
            _this.selectData = result ? result : [];
            _this.orignalData = orignalData;
        };
        _this.getSelectOrignalData = function () {
            var orignalData = get(_this.props.field, 'originalData', {});
            var _a = _this.fnParseDataById(_this.selectData), city = _a.city, cityGrade = _a.cityGrade;
            var mCity = orignalData.city ? city.concat(orignalData.city) : city;
            var mCityGrade = orignalData.cityGrade ? cityGrade.concat(orignalData.cityGrade) : cityGrade;
            return { city: mCity, cityGrade: mCityGrade };
        };
        return _this;
    }
    StandardCitySelect.prototype.componentWillMount = function () {
        var _a = this.props, bus = _a.bus, dataIndex = _a.dataIndex;
        bus && bus.watch(dataIndex + ":get:select:data", this.handleGetSelectData);
    };
    StandardCitySelect.prototype.componentWillUnmount = function () {
        var _a = this.props, bus = _a.bus, dataIndex = _a.dataIndex;
        bus && bus.un(dataIndex + ":get:select:data", this.handleGetSelectData);
    };
    StandardCitySelect.prototype.fnParseDataById = function (value) {
        if (!this.orignalData || !value) {
            return { city: [], cityGrade: [] };
        }
        var city;
        var cityGrade;
        var ids = value.slice().map(function (line) { return line.key; });
        city = this.orignalData.city
            .slice()
            .map(function (item) {
            if (ids.indexOf(item.id) >= 0) {
                return item;
            }
        })
            .filter(function (line) { return line; });
        cityGrade = this.orignalData.cityGrade
            .slice()
            .map(function (item) {
            if (ids.indexOf(item.id) >= 0) {
                return item;
            }
        })
            .filter(function (line) { return line; });
        return { city: city, cityGrade: cityGrade };
    };
    StandardCitySelect.prototype.render = function () {
        return React.createElement(CityComponent, __assign({}, this.props, { onChange: this.handleOnChange, getSelectData: this.getSelectOrignalData }));
    };
    return StandardCitySelect;
}(PureComponent));
export default StandardCitySelect;
