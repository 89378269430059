/**
 * created by zhaohaubing on 2018/05/16
 * */
import './tags.less'
import { Button, Tag } from 'antd'
import DEFAULT from './default.png'
import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { BaseTagsComponent } from './BaseTagsComponent'

export default class RoleSelectedTags extends BaseTagsComponent {
  render() {
    const { tags } = this.state
    const { disabled = false, multiple = true, error = false, noneImg = false, errorText = '不能为空' } = this.props
    const claseName =
      'ekb-components-tags ' +
      (multiple ? 'ekb-components-tags-4-plan-multiple' : 'ekb-components-tags-4-plan-single') +
      (disabled ? ' disabled' : '') +
      (error ? ' error' : '')
    return (
      <>
        <div className={claseName}>
          {tags.map(tag => {
            let URL = getNodeValueByPath(tag, 'avatar')
            URL = URL || DEFAULT
            return (
              <Tag key={tag.key} closable onClose={() => this.handleClose(tag.key, tag)}>
                {!noneImg && <img src={URL} alt="" />}
                <span className="name" title={tag.name}>
                  {tag.name}
                </span>
              </Tag>
            )
          })}
          <Button ghost={disabled} disabled={disabled} className="btn" size="small" onClick={this.handleClick}>
            {i18n.get('编辑')}
          </Button>
          {disabled && <div className="mask" />}
        </div>
        {error && <div className='error-text'>{i18n.get(errorText)}</div>}
      </>
    )
  }
}
