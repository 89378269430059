import { __decorate, __extends, __metadata, __read } from "tslib";
import React from 'react';
import styles from '../ExceedStandardRiskForField.module.less';
import { app as api } from '@ekuaibao/whispered';
import { Popover, Tooltip } from 'antd';
import classnames from 'classnames';
import NoteView from './NoteView';
import { EnhanceConnect } from '@ekuaibao/store';
import EKBIcon from '../../../elements/ekbIcon';
import { observer } from 'mobx-react';
import { inject } from '@ekuaibao/react-ioc';
import { isIE } from '../../../lib/misc';
var FormItemLabelWrap = (function (_super) {
    __extends(FormItemLabelWrap, _super);
    function FormItemLabelWrap() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { noteArr: [] };
        _this.fnRefreshNoteArr = function (billNotes) {
            var _a = _this.props, isDetail = _a.isDetail, detailId = _a.detailId, field = _a.field, flowId = _a.flowId;
            var noteArr = billNotes.filter(function (note) {
                var subDataType = note.subDataType, subDataId = note.subDataId, onField = note.onField;
                if (isDetail) {
                    if (subDataType === 'FLOW') {
                        return false;
                    }
                    else {
                        return subDataId === detailId && field.field === onField;
                    }
                }
                else {
                    if (subDataType === 'FLOW') {
                        return subDataId === flowId && field.field === onField;
                    }
                    else {
                        return false;
                    }
                }
            });
            _this.setState({ noteArr: noteArr });
        };
        _this.addNote = function () {
            var _a = _this.props, flowId = _a.flowId, detailId = _a.detailId, isDetail = _a.isDetail, field = _a.field;
            api.open('@bills:NoteEditModal', { page: 'add', flowId: flowId, detailId: detailId, isDetail: isDetail, field: field });
        };
        return _this;
    }
    FormItemLabelWrap.prototype.componentWillReceiveProps = function (np) {
        var _a = this.props, billNotes = _a.billNotes, showBillNotesInHistory = _a.showBillNotesInHistory, billNotesInHistory = _a.billNotesInHistory;
        if (showBillNotesInHistory) {
            if (np.billNotesInHistory !== billNotesInHistory) {
                this.fnRefreshNoteArr(np.billNotesInHistory);
            }
        }
        else {
            if (np.billNotes !== billNotes) {
                this.fnRefreshNoteArr(np.billNotes);
            }
        }
    };
    FormItemLabelWrap.prototype.componentDidMount = function () {
        var _a = this.props, billNotes = _a.billNotes, showBillNotesInHistory = _a.showBillNotesInHistory, billNotesInHistory = _a.billNotesInHistory;
        var arr = showBillNotesInHistory ? billNotesInHistory : billNotes;
        this.fnRefreshNoteArr(arr);
    };
    FormItemLabelWrap.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, external = _b.external, _c = _b.isForbid, isForbid = _c === void 0 ? false : _c, isEdit = _b.isEdit, _d = _b.noColon, noColon = _d === void 0 ? false : _d, style = _b.style, _e = _b.placement, placement = _e === void 0 ? 'rightTop' : _e, _f = _b.stopPropagation, stopPropagation = _f === void 0 ? true : _f, flowId = _b.flowId, field = _b.field, isDetail = _b.isDetail, detailId = _b.detailId, detailReadable = _b.detailReadable, authorRemovable = _b.authorRemovable, canEditNote = _b.canEditNote, layout = _b.layout, showBillNotesInHistory = _b.showBillNotesInHistory, _g = _b.isAlign, isAlign = _g === void 0 ? false : _g;
        var noteArr = this.state.noteArr;
        var _h = __read(isForbid ? ['error', '#EDico-plaint-circle'] : ['warning', '#EDico-plaint-circle'], 2), className = _h[0], url = _h[1];
        var nodeId = 'risk-warning-for-field' + Math.random();
        var hasNote = !!noteArr.length;
        var noteIcon = external
            ? (React.createElement("div", { className: classnames("risk-icon risk-icon-svg", { 'mr-16': isIE() }) },
                React.createElement("span", { className: "risk-hidden" }, i18n.get('划过')),
                React.createElement("svg", { className: "icon " + className, "aria-hidden": "true" },
                    React.createElement("use", { xlinkHref: url }))))
            : hasNote
                ? React.createElement("div", { className: 'risk-view-note-icon' },
                    React.createElement(EKBIcon, { name: '#EDico-comment1' }))
                : null;
        var ContentComponent = (React.createElement(NoteView, { external: external, noteArr: noteArr, flowId: flowId, field: field, canEditNote: canEditNote, isDetail: isDetail, detailId: detailId, detailReadable: detailReadable, authorRemovable: authorRemovable, showBillNotesInHistory: showBillNotesInHistory }));
        var showAddBtn = canEditNote && !showBillNotesInHistory && !noteIcon && !((_a = this.permission) === null || _a === void 0 ? void 0 : _a.isMCDisabled());
        return (React.createElement("span", { className: styles['risk-warning-for-field-wrapper'], id: nodeId, onClick: function (e) {
                if (stopPropagation) {
                    e.stopPropagation();
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                }
            } },
            React.createElement("span", { className: classnames({
                    'is-align': isAlign,
                    'risk-warning-horizontal': layout !== 'vertical' && !noteIcon,
                    'risk-warning-label-wrapper': true,
                    'dis-f': layout !== 'vertical',
                    'wrapper-error': external && isForbid,
                    'wrapper-warning': external && !isForbid,
                    edit: isEdit
                }), style: style },
                noteIcon
                    ? (React.createElement(Popover, { overlayClassName: styles['note-view-popover-wrap'], trigger: 'hover', arrowPointAtCenter: true, autoAdjustOverflow: true, placement: placement, content: ContentComponent, getPopupContainer: function (triggerNode) { return triggerNode.parentNode; } }, noteIcon))
                    : (showAddBtn
                        ? (React.createElement(Tooltip, { title: i18n.get('添加批注') },
                            React.createElement("div", { className: 'addNoteBtn risk-view-note-icon', onClick: this.addNote },
                                React.createElement(EKBIcon, { name: '#EDico-comment1' }))))
                        : null),
                React.createElement("span", { className: classnames({ "risk-warning-label": layout !== 'vertical' }) }, children),
                React.createElement("span", { style: { padding: '0 4px' } }, isEdit || noColon ? '' : i18n.get('：')))));
    };
    __decorate([
        inject('permission'),
        __metadata("design:type", Object)
    ], FormItemLabelWrap.prototype, "permission", void 0);
    FormItemLabelWrap = __decorate([
        EnhanceConnect(function (state) { return ({
            billNotes: state['@bills'].billNotes,
            activeCreditRule: state['@bills'].activeCreditRule,
            detailReadable: state['@bills'].detailReadable,
            authorRemovable: state['@bills'].authorRemovable,
            billNotesInHistory: state['@bills'].billNotesInHistory,
            showBillNotesInHistory: state['@bills'].showBillNotesInHistory
        }); }),
        observer
    ], FormItemLabelWrap);
    return FormItemLabelWrap;
}(React.Component));
export default FormItemLabelWrap;
