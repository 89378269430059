/**
 *  Created by daiwenjuan on 2018/5/16 下午2:22.
 */
import { Button, Steps } from 'antd'
import React, { PureComponent } from 'react'

import styles from './index.module.less'
// TODO antd 升级后删除该文件
import stylesTemp from './antdLowVersionTemp.module.less'

const Step = Steps.Step

export default class EKBSteps extends PureComponent {
  state = {
    current: 0
  }
  status = 'process'

  next() {
    let { next } = this.props
    let { current } = this.state
    next &&
      next(current).then(status => {
        this.status = status
        this.forceUpdate()
        if (status === 'error') {
          return
        }
        const current = this.state.current + 1
        this.setState({ current })
      })
  }

  prev() {
    let { prev } = this.props
    const current = this.state.current - 1
    prev && prev(this.state.current)
    this.setState({ current })
  }

  done() {
    let { done } = this.props
    done && done()
  }

  render() {
    const { current } = this.state
    const {
      steps,
      nextAction,
      preAction,
      doneAction,
      bus,
      dataSource,
      disablePrev,
      entityList,
      allEntityList,
      className,
      stepHeaderClass,
      stepContentClass,
      stepActionClass,
      ...other
    } = this.props
    const Component = steps[current].Component
    const content = steps[current].content
    const value = dataSource && dataSource.length > 0 && dataSource[current]
    return (
      <div className={`${styles['steps-wrapper']} ${className}`}>
        <div className="wrapper">
          <div className={`${stylesTemp['steps-header']} ${stepHeaderClass}`}>
            <Steps current={current} status={this.status}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          <div className={`steps-content ${stepContentClass}`}>
            {/* excuse me, add entityList here? */}
            {!!content ? (
              content
            ) : (
              <Component {...other} bus={bus} value={value} entityList={entityList} allEntityList={allEntityList} />
            )}
          </div>
          <div className={`steps-action ${stepActionClass}`}>
            {current < steps.length - 1 && (
              <Button className={'action-btn'} type="primary" onClick={() => this.next()}>
                {nextAction}
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button className={'action-btn'} type="primary" onClick={() => this.done()}>
                {doneAction}
              </Button>
            )}
            {!disablePrev && current > 0 && (
              <Button className={'pre-btn'} style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                {preAction}
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}
