export default {
  ICON_SF_100_GRAY: require('./../images/feetype/sf_100_gray.png'),
  ICON_SF_100: require('./../images/feetype/sf_100.png'),
  ICON_SF_1000_GRAY: require('./../images/feetype/sf_1000_gray.png'),
  ICON_SF_1000: require('./../images/feetype/sf_1000.png'),
  ICON_SF_1100_GRAY: require('./../images/feetype/sf_1100_gray.png'),
  ICON_SF_1100: require('./../images/feetype/sf_1100.png'),
  ICON_SF_1200_GRAY: require('./../images/feetype/sf_1200_gray.png'),
  ICON_SF_1200: require('./../images/feetype/sf_1200.png'),
  ICON_SF_1300_GRAY: require('./../images/feetype/sf_1300_gray.png'),
  ICON_SF_1300: require('./../images/feetype/sf_1300.png'),
  ICON_SF_1400_GRAY: require('./../images/feetype/sf_1400_gray.png'),
  ICON_SF_1400: require('./../images/feetype/sf_1400.png'),
  ICON_SF_1500_GRAY: require('./../images/feetype/sf_1500_gray.png'),
  ICON_SF_1500: require('./../images/feetype/sf_1500.png'),
  ICON_SF_1600_GRAY: require('./../images/feetype/sf_1600_gray.png'),
  ICON_SF_1600: require('./../images/feetype/sf_1600.png'),
  ICON_SF_1700_GRAY: require('./../images/feetype/sf_1700_gray.png'),
  ICON_SF_1700: require('./../images/feetype/sf_1700.png'),
  ICON_SF_1800_GRAY: require('./../images/feetype/sf_1800_gray.png'),
  ICON_SF_1800: require('./../images/feetype/sf_1800.png'),
  ICON_SF_1900_GRAY: require('./../images/feetype/sf_1900_gray.png'),
  ICON_SF_1900: require('./../images/feetype/sf_1900.png'),
  ICON_SF_200_GRAY: require('./../images/feetype/sf_200_gray.png'),
  ICON_SF_200: require('./../images/feetype/sf_200.png'),
  ICON_SF_2000_GRAY: require('./../images/feetype/sf_2000_gray.png'),
  ICON_SF_2000: require('./../images/feetype/sf_2000.png'),
  ICON_SF_2100_GRAY: require('./../images/feetype/sf_2100_gray.png'),
  ICON_SF_2100: require('./../images/feetype/sf_2100.png'),
  ICON_SF_2200_GRAY: require('./../images/feetype/sf_2200_gray.png'),
  ICON_SF_2200: require('./../images/feetype/sf_2200.png'),
  ICON_SF_2300_GRAY: require('./../images/feetype/sf_2300_gray.png'),
  ICON_SF_2300: require('./../images/feetype/sf_2300.png'),
  ICON_SF_2400_GRAY: require('./../images/feetype/sf_2400_gray.png'),
  ICON_SF_2400: require('./../images/feetype/sf_2400.png'),
  ICON_SF_2500_GRAY: require('./../images/feetype/sf_2500_gray.png'),
  ICON_SF_2500: require('./../images/feetype/sf_2500.png'),
  ICON_SF_2600_GRAY: require('./../images/feetype/sf_2600_gray.png'),
  ICON_SF_2600: require('./../images/feetype/sf_2600.png'),
  ICON_SF_2700_GRAY: require('./../images/feetype/sf_2700_gray.png'),
  ICON_SF_2700: require('./../images/feetype/sf_2700.png'),
  ICON_SF_2800_GRAY: require('./../images/feetype/sf_2800_gray.png'),
  ICON_SF_2800: require('./../images/feetype/sf_2800.png'),
  ICON_SF_2900_GRAY: require('./../images/feetype/sf_2900_gray.png'),
  ICON_SF_2900: require('./../images/feetype/sf_2900.png'),
  ICON_SF_300_GRAY: require('./../images/feetype/sf_300_gray.png'),
  ICON_SF_300: require('./../images/feetype/sf_300.png'),
  ICON_SF_3000_GRAY: require('./../images/feetype/sf_3000_gray.png'),
  ICON_SF_3000: require('./../images/feetype/sf_3000.png'),
  ICON_SF_3100_GRAY: require('./../images/feetype/sf_3100_gray.png'),
  ICON_SF_3100: require('./../images/feetype/sf_3100.png'),
  ICON_SF_3200_GRAY: require('./../images/feetype/sf_3200_gray.png'),
  ICON_SF_3200: require('./../images/feetype/sf_3200.png'),
  ICON_SF_400_GRAY: require('./../images/feetype/sf_400_gray.png'),
  ICON_SF_400: require('./../images/feetype/sf_400.png'),
  ICON_SF_500_GRAY: require('./../images/feetype/sf_500_gray.png'),
  ICON_SF_500: require('./../images/feetype/sf_500.png'),
  ICON_SF_600_GRAY: require('./../images/feetype/sf_600_gray.png'),
  ICON_SF_600: require('./../images/feetype/sf_600.png'),
  ICON_SF_700_GRAY: require('./../images/feetype/sf_700_gray.png'),
  ICON_SF_700: require('./../images/feetype/sf_700.png'),
  ICON_SF_800_GRAY: require('./../images/feetype/sf_800_gray.png'),
  ICON_SF_800: require('./../images/feetype/sf_800.png'),
  ICON_SF_900_GRAY: require('./../images/feetype/sf_900_gray.png'),
  ICON_SF_900: require('./../images/feetype/sf_900.png'),
  ICON_SF_3300_GRAY: require('./../images/feetype/sf_3300_gray.png'),
  ICON_SF_3300: require('./../images/feetype/sf_3300.png'),
  ICON_SF_3400_GRAY: require('./../images/feetype/sf_3400_gray.png'),
  ICON_SF_3400: require('./../images/feetype/sf_3400.png')
}
