/**
 *  Created by daiwenjuan on 2018/5/16 下午5:10.
 */
import React, { PureComponent } from 'react'
import { EnhanceField } from '@ekuaibao/template'
import { EKBInput } from '../../ekb-components/index'
import { wrapper } from '../layout/FormWrapper'
import { required } from '../validator/validator'
import styles from './InterconInput.module.less'
import { debounce } from 'lodash'

@EnhanceField({
  descriptor: {
    type: 'interconnectal-text'
  },
  validator: field => (level, value, callback) => {
    const { label, maxLength } = field
    if (value && value.length > maxLength) {
      return callback(i18n.get('cannot-exceed-words', { label, maxLength }))
    }
    return callback(required(field, value))
  },
  wrapper: wrapper()
})
export default class InterconInput extends PureComponent {
  onChange = value => {
    let { onChange } = this.props
    onChange && onChange(value)
    if (value.length <= 14) {
      this.emitChange(value)
    }
  }

  emitChange = debounce(value => {
    const { bus } = this.props
    bus?.has('sumFieldName:value:change') && bus.emit('sumFieldName:value:change', value)
  }, 500)

  componentDidMount(){
    if(this.props?.value){
      this.emitChange(this.props?.value)
    }
  }

  render() {
    // todo zhb 业务对象名称
    const { value, field, disabledEdit } = this.props
    let { placeholder, editable, describe } = field
    let params = { value, placeholder, disabled: !editable || disabledEdit }
    return (
      <>
        <EKBInput {...params} onChange={this.onChange} />
        {describe ? <span className={styles.intercon_input_describe}>{describe}</span> : null}
      </>
    )
  }
}
