import { __assign, __values } from "tslib";
export var FieldType;
(function (FieldType) {
    FieldType["Text"] = "text";
    FieldType["AutoNumber"] = "autoNumber";
    FieldType["Date"] = "date";
    FieldType["DateRange"] = "dateRange";
    FieldType["Number"] = "number";
    FieldType["Switch"] = "switcher";
    FieldType["Money"] = "money";
    FieldType["RefPayee"] = "ref:pay.PayeeInfo";
    FieldType["RefEntity"] = "ref:datalink.DataLinkEntity";
    FieldType["RefEntityList"] = "list:datalink.DataLinkEntity";
    FieldType["RefDimension"] = "ref:basedata.Dimension";
    FieldType["RefDimensions"] = "list:ref:basedata.Dimension";
    FieldType["RefEnum"] = "ref:basedata.Enum";
    FieldType["RefDepartment"] = "ref:organization.Department";
    FieldType["AutoComputed"] = "autoComputed";
    FieldType["RefCity"] = "ref:basedata.city";
    FieldType["RefStaff"] = "ref:organization.Staff";
    FieldType["ListStaff"] = "list:organization.Staff";
    FieldType["Default"] = "";
    FieldType["Ref"] = "ref";
    FieldType["List"] = "list";
    FieldType["Attachment"] = "attachment";
})(FieldType || (FieldType = {}));
export var selectFields = [
    {
        value: FieldType.Text,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.Text; }
    },
    {
        value: FieldType.AutoNumber,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.AutoNumber; }
    },
    {
        value: FieldType.Date,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.Date; }
    },
    {
        value: FieldType.DateRange,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.DateRange; }
    },
    {
        value: FieldType.Number,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.Money; }
    },
    {
        value: FieldType.Money,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.Money; }
    },
    {
        value: FieldType.Switch,
        formatPriority: 0,
        formatField: function (input) { return input.type === FieldType.Switch; }
    },
    {
        value: FieldType.RefPayee,
        formatPriority: 1,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity === 'pay.PayeeInfo' && __assign(__assign({}, input), { type: FieldType.RefPayee });
        }
    },
    {
        value: FieldType.RefEntity,
        formatPriority: 1,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity.startsWith('datalink.DataLinkEntity.') && __assign(__assign({}, input), { type: FieldType.RefEntity, entity: input.entity.substr('datalink.DataLinkEntity.'.length) });
        }
    },
    {
        value: FieldType.RefEntityList,
        formatPriority: 0,
        formatField: function (input) {
            return input.type === FieldType.List &&
                input.elemType &&
                input.elemType.type === FieldType.Ref &&
                input.elemType.entity.startsWith('datalink.DataLinkEntity.') && __assign(__assign({}, input), { entity: input.elemType.entity.substr('datalink.DataLinkEntity.'.length), type: FieldType.RefEntityList });
        }
    },
    {
        value: FieldType.RefDimension,
        formatPriority: 1,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity.startsWith('basedata.Dimension.') && __assign(__assign({}, input), { type: FieldType.RefDimension, entity: input.entity.substr('basedata.Dimension.'.length) });
        }
    },
    {
        value: FieldType.RefDimensions,
        formatPriority: 1,
        formatField: function (input) {
            return input.type === FieldType.List &&
                input.elemType &&
                input.elemType.type === FieldType.Ref &&
                input.elemType.entity.startsWith('basedata.Dimension.') && __assign(__assign({}, input), { type: FieldType.RefDimensions });
        }
    },
    {
        value: FieldType.RefEnum,
        formatPriority: 1,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity.startsWith('basedata.Enum.') && __assign(__assign({}, input), { type: FieldType.RefEnum, entity: input.entity.substr('basedata.RefEnum.'.length) });
        }
    },
    {
        value: FieldType.RefDepartment,
        formatPriority: 1,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity.startsWith('organization.Department') && __assign(__assign({}, input), { type: FieldType.RefDepartment });
        }
    },
    {
        value: FieldType.RefCity,
        formatPriority: 0,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity === 'basedata.city' && __assign(__assign({}, input), { type: FieldType.RefCity });
        }
    },
    {
        value: FieldType.RefStaff,
        formatPriority: 0,
        formatField: function (input) {
            return input.type === FieldType.Ref &&
                input.entity === 'organization.Staff' && __assign(__assign({}, input), { type: FieldType.RefStaff });
        }
    },
    {
        value: FieldType.ListStaff,
        formatPriority: 0,
        formatField: function (input) {
            return input.type === FieldType.List &&
                input.elemType &&
                input.elemType.type === FieldType.Ref &&
                input.elemType.entity === 'organization.Staff' && __assign(__assign({}, input), { type: FieldType.ListStaff });
        }
    },
    {
        value: FieldType.AutoComputed,
        formatPriority: 2,
        formatField: function (input) {
            return (input.type === FieldType.Money || input.type === FieldType.Number) &&
                !!input.formula && __assign(__assign({}, input), { type: FieldType.AutoComputed, _returnType: input.type });
        }
    },
    {
        value: FieldType.Attachment,
        formatPriority: 0,
        formatField: function (input) {
            return input.type === FieldType.List &&
                input.elemType &&
                input.elemType.type === FieldType.Attachment && __assign(__assign({}, input), { type: FieldType.Attachment });
        }
    }
];
var formatFields = selectFields.slice().sort(function (a, b) { return b.formatPriority - a.formatPriority; });
export function getFieldType(line) {
    var e_1, _a;
    try {
        for (var formatFields_1 = __values(formatFields), formatFields_1_1 = formatFields_1.next(); !formatFields_1_1.done; formatFields_1_1 = formatFields_1.next()) {
            var item = formatFields_1_1.value;
            var output = item.formatField(line);
            if (output) {
                return output === true ? line.type : item.value;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (formatFields_1_1 && !formatFields_1_1.done && (_a = formatFields_1.return)) _a.call(formatFields_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return line.type;
}
