/**************************************************
 * Created by nanyuantingfeng on 29/03/2018 11:49.
 **************************************************/

import RefDataVisibility from './RefDataVisibility'
import RefStaffMemberDepartmentTags from './RefStaffMemberDepartmentTags'

const elements = [RefStaffMemberDepartmentTags, RefDataVisibility]

export default elements
