/**************************************************
 * Created by kaili on 2017/7/20 下午5:05.
 **************************************************/

import { parsePropertySet2Select, getAttachments, buildAttachments } from 'ekbc-query-builder'

import { app as api } from '@ekuaibao/whispered'
import { getV } from '@ekuaibao/lib/lib/help'

const selectOwner = () => `ownerId(name,id,defaultDepartment)`

const joinFlowPlanNodes = prefix => [
  prefix + `plan.nodes.approverId,approverId,/v1/organization/staffs`,
  prefix + `plan.nodes.counterSigners.signerId,signerId,/v1/organization/staffs?select=id,name,avatar,code,email,cellphone,note`,
  prefix + `plan.nodes.counterSignersCandidate.signerId,signerId,/v1/organization/staffs?select=id,name,avatar,code,email,cellphone,note`,
  prefix + 'logs.modifyFlowLog.operatorId,operatorId,/v1/organization/staffs?select=id,name,avatar,code,email,cellphone,note',
  prefix + 'logs.attributes.resubmitOperatorIds,resubmitOperatorIds,/v1/organization/staffs'
]
const selectFlowPlan = () => `plan(...)`

const selectExpenseLick = () => {
  return `expenseLink(specificationId(...),...)`
}

const selectExpenseLicks = () => {
  return `expenseLinks(specificationId(...),...)`
}
const selectFlowLinks = () => {
  return `flowLinks(id,form(...),...)`
}

const joinLinkDetails = (prefix = '') => [
  prefix +
    'form.details.feeTypeForm.linkDetailEntities.linkDetailEntityId,linkDetailEntityId,/form/v3/requisition/info/details/byId?join=feeTypeId,feeTypeId,/v1/form/feeTypes'
]

const selectLinkRequisitionInfo = () => {
  return `linkRequisitionInfo(specificationId(...),ownerId(...),...)`
}

//region now just expense
const joinWrittenOff = (prefix = '') =>
  prefix + 'id,writtenOff,/v1/loan/writtenOff?join=records.loanInfoId,loanInfoId,/v1/loan/loanInfo'

const joinNewWrittenOff = (prefix = '') =>
  prefix + 'writtenOff.records.loanInfoId,loanInfoId,/v1/loan/loanInfo'

const joinManualRepayment = (prefix = '') =>
prefix + 'id,loanManualRepayment,/v1/loanManual/repayment'

const selectWrittenOff = () => `writtenOff(
                                           records(
                                                   loanInfoId(remain,id)
                                                  ,...)
                                           ,...)`

const selectRule = () => `flowRulePerformLogs(...)`
//endregion

export const selectForm = (isFeeTypeForm = false) => {
  let properties = api.getState('@common.globalFields.data')
  let extensionFields = api.getState('@common.globalFields.extension')
  let list = isFeeTypeForm ? extensionFields : properties
  return parsePropertySet2Select(list)
}

export const asyncSelectForm = async (isFeeTypeForm = false) => {
  const globalFields = await api.dataLoader('@common.globalFields').load()
  const properties = getV(globalFields, 'data', [])
  const extensionFields = getV(globalFields, 'data', [])
  const list = isFeeTypeForm ? extensionFields : properties
  return parsePropertySet2Select(list)
}

const selectApportions = () => `apportions(
                                    specificationId(...),
                                    apportionForm(
                                         ${selectForm(true)}
                                         ,...)
                                ,...)`

const selectFeeDetailPayeeId = () => `feeDetailPayeeId(staffId(...),...)`

const joinLogs1 = prefix => [
  prefix + 'logs.attributes.oldApproverId,oldApproverId,/v1/organization/staffs',
  prefix + 'logs.attributes.newApproverId,newApproverId,/v1/organization/staffs',
  prefix + 'logs.attributes.participants,participants,/v1/organization/staffs',
  prefix + 'logs.attributes.receiverIds,receiverIds,/v1/organization/staffs',
  prefix + 'logs.attributes.paymentAccountId,accountCompany,/pay/v1/accounts',
  prefix + 'logs.attributes.carbonCopyIds,carbonCopy,/v1/organization/staffs',
  prefix + 'logs.attributes.autographImageId,autographImageId,/v1/basedata/autographs/imageIds',
  prefix + 'logs.byDelegateId,byDelegateId,/v1/organization/staffs',
  prefix + 'logs.modifyFlowLog.byDelegateId,byDelegateId,/v1/organization/staffs'
]

const   selectLogs = () => {
  return `logs(operatorId(...),
               nextOperatorId(...),
               nextOperatorIds(...)
               ,...)`
}

export const paramsToMap = params => {
  let paramsMap = {}
  params.forEach((url, idx) => {
    paramsMap[`join$${idx}`] = url
  })
  return paramsMap
}

const joinFeeType = (prefix = '') => prefix + 'form.details.feeTypeId,feeTypeId,/v1/form/feeTypes'
const joinTripType = (prefix = '') =>
  prefix +
  'form.trips.tripTypeId,tripTypeId,/trip/v2/type?join=specificationId,specificationId,/form/v1/specificationVersions'
const joinOrders = (prefix = '') => prefix + 'form.details.feeTypeForm.orders,ordersData,/v1/order/orders'
const joinCorporationInvoice = (prefix = '') =>
  prefix +
  'form.details.feeTypeForm.invoiceForm.invoiceCorporationId,invoiceCorporation,/v2/invoice/unify/corporation/list'
const joinAttaInvoice = (prefix = '') =>
  prefix + 'form.details.feeTypeForm.invoiceForm.attachments.fileId,fileId,/v1/attachment/attachments'
const joinThirdPartyOrders = (prefix = '') =>
  prefix + 'form.details.feeTypeForm.orders,thirdPartyOrders,/v2/order/orders'

const joinCountDown = (prefix = '') => prefix + 'id,countDownDuration,/flow/v2/backlogs/duration'
const joinAttachments = (prefix = '') => {
  let properties = api.getState('@common.globalFields.data')
  let attachments = getAttachments(properties)
  return buildAttachments(attachments, prefix, {})
}

const joinExpenseLogs = prefix => [
  `${prefix}form.expenseLink.changeLogs.operatorId,operatorId,/v1/organization/staffs`,
  `${prefix}form.expenseLink.changeLogs.fromStaff,fromStaff,/v1/organization/staffs`,
  `${prefix}form.expenseLink.changeLogs.toStaffs,toStaffs,/v1/organization/staffs`,
  `${prefix}form.expenseLink.ownerId,ownerId,/v1/organization/staffs`,
  `${prefix}form.expenseLink.changeLogs.operatorId,operatorId,/v1/organization/staffs`,
  `${prefix}form.expenseLinks.changeLogs.fromStaff,fromStaff,/v1/organization/staffs`,
  `${prefix}form.expenseLinks.changeLogs.toStaffs,toStaffs,/v1/organization/staffs`,
  `${prefix}form.expenseLinks.ownerId,ownerId,/v1/organization/staffs`,
  `${prefix}form.linkRequisitionInfo.changeLogs.operatorId,operatorId,/v1/organization/staffs`,
  `${prefix}form.linkRequisitionInfo.changeLogs.fromStaff,fromStaff,/v1/organization/staffs`,
  `${prefix}form.linkRequisitionInfo.changeLogs.toStaffs,toStaffs,/v1/organization/staffs`
]

const joinFormMoney = (prefix = '') => [
  prefix + moneyJoin('pay'),
  prefix + moneyJoin('expense'),
  prefix + moneyJoin('loan'),
  prefix + moneyJoin('requisition')
]
const moneyJoin = (type = '') => `form.${type}Money.standardNumCode,standardNumCode,/v1/basedata/enumItems`

export const selectFeeTypeForm = () => {
  return `feeTypeForm(  ${selectFeeDetailPayeeId()},
                        ${selectApportions()},
                        ${selectFlowLinks()}, 
                        ${selectForm(true)}
                        ,...)`
}

const selectTripForm = () => {
  return `tripForm(${selectForm(true)},...)`
}

export const selectDetails = () => {
  return `details(
                  specificationId(...),
                   ${selectFeeTypeForm()},
                  ...)`
}

const selectTrips = () => {
  return `trips(specificationId(...),
                tripTypeId(specificationId(...),...),
                ${selectTripForm()},
                ...)`
}

const selectSubmitterDefaultDept = () => {
  return `submitterId(defaultDepartment(...),...)`
}

const selectNoFlowEntity = () => {
  return flowEntity(selectForm())
}


const selectNoFlowEntityLite = () => {
  return flowEntityLite(selectForm())
}

const asyncSelectNoFlowEntity = async () => {
  const selectForm = await asyncSelectForm()
  return flowEntity(selectForm)
}

const flowEntity = selectForm => {
  return `form(${selectForm},
               ${selectDetails()},
               ${selectSubmitterDefaultDept()},
               ${selectExpenseLick()},
               ${selectExpenseLicks()},
               ${selectLinkRequisitionInfo()},
               ${selectFlowLinks()}, 
               ${selectTrips()},
               ...),
          ${selectLogs()},
          ${selectOwner()},
          ${selectFlowPlan()},
          ${selectRule()},
          ${selectriskWarning()}
          ,...`
}
const flowEntityLite = selectForm => {
  return `form(${selectForm},...),...`
}
/**
 * 同步获取select参数的方法：如果需要的全局地段有时效性问题，请使用下面的异步方法
 * @param prefix
 * @returns {Promise<string|Promise<string>>}
 */
export const selectEntity = (prefix = '') => {
  if (prefix) {
    return `${prefix}(${selectNoFlowEntity()}),...`
  }

  return selectNoFlowEntity()
}
export const selectEntityLite = (prefix = '') => {
  if (prefix) {
    return `${prefix}(${selectNoFlowEntityLite()}),...`
  }

  return selectNoFlowEntityLite()
}
export const selectEntityBudgetDetails = () => {
  return `form(${selectForm()},
               ${selectDetails()},
               ${selectSubmitterDefaultDept()},
               ${selectExpenseLick()},
               ${selectExpenseLicks()},
               ${selectFlowLinks()}, 
               ${selectLinkRequisitionInfo()},
               ${selectTrips()},
               ...),
          ${selectLogs()},
          ${selectOwner()},
          ${selectFlowPlan()},
          ${selectRule()}
          ,...`
}
/**
 * 异步获取select参数的方法：避免拼接需要的全局字段没有返回问题
 * @param prefix
 * @returns {Promise<string|Promise<string>>}
 */
export const asyncSelectEntity = async (prefix = '') => {
  if (prefix) {
    const selectNoFlowEntity = await asyncSelectNoFlowEntity()
    return `${prefix}(${selectNoFlowEntity}),...`
  }

  return asyncSelectNoFlowEntity()
}

const selectriskWarning = () => `riskWarning(...)`
const budgetFlow = () => `budgetFlow(...)`
const joinDataLink = (prefix = '') => [
  prefix +
    'components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform?join=icon.fileId,fileId,/v1/attachment/attachments',
  prefix + 'components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping'
]

export const joinParamsOnlyDetail = (prefix = '') => {
  let params = [
    joinFeeType(prefix),
    joinOrders(prefix),
    joinThirdPartyOrders(prefix),
    joinDataLink(prefix + 'form.specificationId.'),
    joinDataLink(prefix + 'form.details.specificationId.'),
    joinDataLink(prefix + 'form.trips.specificationId.'),
    joinDataLink(prefix + 'form.trips.tripTypeId.specificationId.')
  ]
  return { ...paramsToMap(params) }
}

export const joinParamsLite = (prefix = '') => {
  let params = [
    // joinFeeType(prefix),
    // joinOrders(prefix),
    // joinThirdPartyOrders(prefix),
    joinDataLink(prefix + 'form.specificationId.'),
    // joinDataLink(prefix + 'form.details.specificationId.'),
    joinDataLink(prefix + 'form.trips.specificationId.'),
    joinDataLink(prefix + 'form.trips.tripTypeId.specificationId.')
  ]
  return { ...paramsToMap(params) }
}

//不支持select查询方式
export const joinParams = (prefix = '') => {
  let KA_REPAYMENT_MANAGEMENT = api.getState()['@common'].powers.KA_REPAYMENT_MANAGEMENT
  let params = [
    ...joinFlowPlanNodes(prefix),
    ...joinLogs1(prefix),
    ...joinExpenseLogs(prefix),
    joinWrittenOff(prefix),
    joinNewWrittenOff(prefix),
    joinFeeType(prefix),
    joinOrders(prefix),
    joinCorporationInvoice(prefix),
    joinAttaInvoice(prefix),
    joinThirdPartyOrders(prefix),
    joinFormMoney(prefix),
    joinDataLink(prefix + 'form.specificationId.'),
    joinDataLink(prefix + 'form.details.specificationId.'),
    joinDataLink(prefix + 'form.trips.specificationId.'),
    joinDataLink(prefix + 'form.trips.tripTypeId.specificationId.'),
    joinLinkDetails(prefix),
  ]
  if (KA_REPAYMENT_MANAGEMENT) {
    //报销单还款
    params.push(joinManualRepayment(prefix))
  }
  return { ...paramsToMap(params) }
}

export const joinMoney = (prefix = '') => {
  return { ...paramsToMap(joinFormMoney(prefix)) }
}
