import { __assign, __decorate, __metadata } from "tslib";
import { observable, computed, action, runInAction, toJS } from 'mobx';
import { formatData, formatMoney } from '../helper/formatTableData';
import { uuid } from '@ekuaibao/helpers';
import { MoneyInterface } from '@ekuaibao/lib/lib/entityUtil/EntityTypes';
import { MoneyMath } from '@ekuaibao/money-math';
import { hasForeign } from '../../../components/utils/fnCurrencyObj';
import { get } from 'lodash';
var TableStore = (function () {
    function TableStore() {
        this.objs = [];
        this.writtenOffObjs = [];
        this.columns = [];
        this.displayColumns = [];
        this.selectObjs = [];
        this.targetTotalMoney = '0';
        this.isNe = false;
        this.myValue = {};
        this.supportPlanByApportion = false;
        this.paymentPlanByApportion = false;
        this.showApportionCheckbox = false;
    }
    Object.defineProperty(TableStore.prototype, "totalMoney", {
        get: function () {
            return this.objs.reduce(function (sum, line) { return new MoneyMath(sum).add(line.money && line.money.standard || 0).value; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableStore.prototype, "selectKeys", {
        get: function () {
            return this.selectObjs.map(function (line) { return line.key; });
        },
        enumerable: true,
        configurable: true
    });
    TableStore.prototype.splitPayPlanWithApportion = function (values) {
        if (this.supportPlanByApportion && this.paymentPlanByApportion) {
            var payPlans_1 = [];
            values === null || values === void 0 ? void 0 : values.forEach(function (el) {
                var _a, _b, _c, _d;
                var paymentStatus = (_a = el.dataLinkForm) === null || _a === void 0 ? void 0 : _a.E_system_支付计划_支付状态;
                var payeeInfo = el.payeeInfo
                    ? el.payeeInfo
                    : ((_b = el.dataLinkForm) === null || _b === void 0 ? void 0 : _b.payeeId) ? el.dataLinkForm.payeeId
                        : (_c = el.dataLinkForm) === null || _c === void 0 ? void 0 : _c.E_system_支付计划_收款信息;
                if (!(payeeInfo && payeeInfo.id))
                    return;
                if ((_d = el.apportions) === null || _d === void 0 ? void 0 : _d.length) {
                    el.apportions.forEach(function (item) {
                        var _a, _b;
                        payPlans_1.push({
                            key: (_a = item.apportionForm) === null || _a === void 0 ? void 0 : _a.apportionId,
                            money: (_b = item.apportionForm) === null || _b === void 0 ? void 0 : _b.apportionMoney,
                            E_system_支付计划_legalEntity: get(item, 'apportionForm.法人实体'),
                            paymentStatus: paymentStatus,
                            payeeInfo: payeeInfo
                        });
                    });
                }
                else {
                    if (el.key && el.money) {
                        payPlans_1.push(el);
                    }
                    else {
                        var money = el.dataLinkForm.E_system_支付计划_支付金额;
                        money = __assign(__assign({}, money), { standard: Number(money.standard) });
                        payPlans_1.push({
                            key: el.dataLinkId,
                            money: money,
                            payeeInfo: payeeInfo,
                            E_system_支付计划_legalEntity: get(el, 'dataLinkForm.E_system_支付计划_legalEntity'),
                            paymentStatus: paymentStatus,
                        });
                    }
                }
            });
            this.objs = payPlans_1;
        }
        else {
            this.objs = this.writtenOffObjs.filter(function (o) { return o.payeeInfo && o.payeeInfo.id; });
        }
    };
    TableStore.prototype.initObjs = function (values) {
        var _a;
        this.writtenOffObjs = formatData(values);
        this.splitPayPlanWithApportion(values);
        this.targetTotalMoney = typeof this.totalMoney === 'object' ? this.totalMoney.standard : this.totalMoney;
        (_a = this.bus) === null || _a === void 0 ? void 0 : _a.emit('init:pay:plan:columns');
    };
    TableStore.prototype.changeObjsMoney = function (currency) {
        this.objs.map(function (item) {
            var _a;
            var value = (_a = item === null || item === void 0 ? void 0 : item.money) === null || _a === void 0 ? void 0 : _a.standard;
            item.money = formatMoney(value, value, currency);
        });
    };
    TableStore.prototype.updateObjs = function (objs, dimentionCurrencyInfo) {
        var _this = this;
        var currency = get(dimentionCurrencyInfo, 'currency');
        runInAction(function () {
            objs = objs.map(function (line) {
                var value = new MoneyMath('0').value;
                value = formatMoney(value, value, currency);
                if (Number(value.standard) < 0) {
                    value.standard = '0.00';
                }
                if (hasForeign(value) && Number(value.standard) < 0) {
                    value.foreign = '0.00';
                }
                return { payeeInfo: __assign({}, line), key: uuid(14), money: value };
            });
            _this.objs = _this.objs.concat(objs);
        });
    };
    TableStore.prototype.updateObjsWithAmount = function (objs, dimentionCurrencyInfo) {
        var _this = this;
        var currency = get(dimentionCurrencyInfo, 'currency');
        runInAction(function () {
            objs = objs.map(function (item) {
                var line = item.any;
                var money = item.money;
                var value = new MoneyMath(money).value;
                value = formatMoney(value, value, currency);
                if (Number(value.standard) < 0) {
                    value.standard = '0.00';
                }
                if (hasForeign(value) && Number(value.standard) < 0) {
                    value.foreign = '0.00';
                }
                return { payeeInfo: __assign({}, line), key: uuid(14), money: value };
            });
            _this.objs = _this.objs.concat(objs);
        });
    };
    TableStore.prototype.deleteObjs = function (objs) {
        var ids = objs.map(function (line) { return line.key; });
        this.objs = this.objs.filter(function (line) { return !~ids.indexOf(line.key); });
        this.selectObjs = this.selectObjs.filter(function (line) { return !~ids.indexOf(line.key); });
    };
    TableStore.prototype.updateColumns = function (columns) {
        this.columns = columns;
    };
    TableStore.prototype.updateDisplayColumns = function (columns) {
        this.displayColumns = columns;
    };
    TableStore.prototype.updateSelectObjs = function (objs) {
        this.selectObjs = objs;
    };
    TableStore.prototype.clearObjs = function () {
        this.objs = [];
        this.selectObjs = [];
    };
    TableStore.prototype.clearSelected = function () {
        this.selectObjs = [];
    };
    TableStore.prototype.addOneObj = function (dimentionCurrencyInfo) {
        var money = new MoneyMath(this.totalMoney).minus(this.totalMoney).value;
        var currency = get(dimentionCurrencyInfo, 'currency');
        money = formatMoney(money, this.totalMoney, currency);
        if (Number(money.standard) < 0) {
            money.standard = '0.00';
        }
        if (hasForeign(money) && Number(money.standard) < 0) {
            money.foreign = '0.00';
        }
        var value = {
            money: money,
            payeeInfo: undefined,
            key: uuid(14)
        };
        this.objs.push(value);
    };
    TableStore.prototype.updateOneObjValue = function (id, fieldName, value, dimentionCurrencyInfo) {
        var _this = this;
        var currency = get(dimentionCurrencyInfo, 'currency');
        runInAction(function () {
            if (fieldName === 'money') {
                value = new MoneyMath(value).value;
                value = formatMoney(value, _this.totalMoney, currency);
                if (Number(value.standard) < 0) {
                    value.standard = '0.00';
                }
                if (hasForeign(value) && Number(value.standard) < 0) {
                    value.foreign = '0.00';
                }
            }
            var list = toJS(_this.objs);
            var index = list.findIndex((function (obj) { return obj.key === id; }));
            list[index][fieldName] = value;
            _this.objs = list;
        });
    };
    TableStore.prototype.updateObjsValue = function (fieldName, value, dimentionCurrencyInfo) {
        var _this = this;
        this.selectObjs.forEach(function (line) {
            _this.updateOneObjValue(line.key, fieldName, value, dimentionCurrencyInfo);
        });
    };
    var _a;
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "objs", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "writtenOffObjs", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "columns", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "displayColumns", void 0);
    __decorate([
        observable,
        __metadata("design:type", Array)
    ], TableStore.prototype, "selectObjs", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], TableStore.prototype, "targetTotalMoney", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], TableStore.prototype, "isNe", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], TableStore.prototype, "myValue", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], TableStore.prototype, "supportPlanByApportion", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], TableStore.prototype, "paymentPlanByApportion", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], TableStore.prototype, "showApportionCheckbox", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], TableStore.prototype, "bus", void 0);
    __decorate([
        computed,
        __metadata("design:type", typeof (_a = typeof MoneyInterface !== "undefined" && MoneyInterface) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], TableStore.prototype, "totalMoney", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], TableStore.prototype, "selectKeys", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "splitPayPlanWithApportion", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "initObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateObjsWithAmount", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "deleteObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateColumns", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateDisplayColumns", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateSelectObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "clearObjs", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "clearSelected", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "addOneObj", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String, Object, Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateOneObjValue", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Object, Object]),
        __metadata("design:returntype", void 0)
    ], TableStore.prototype, "updateObjsValue", null);
    return TableStore;
}());
export default TableStore;
