import { get } from 'lodash';
var InvoiceTaxInfo = (function () {
    function InvoiceTaxInfo() {
        var _this = this;
        this.fnCanEditType = function (type) {
            return _this.canEditList.includes(type);
        };
        this.fnCanState = function (billState) {
            return !_this.canState.includes(billState);
        };
        this.getInvoiceTaxAmount = function (item) {
            var form = item.master.form, taxAmount = item.taxAmount, details = item.details;
            var active = form['E_是否抵扣'];
            if (taxAmount) {
                return typeof taxAmount === 'object' ? Number(taxAmount.standard) : Number(taxAmount);
            }
            if (!active) {
                return 0;
            }
            var total = details.reduce(function (cur, next) {
                var num = get(next, 'form.E_system_发票明细_税额.standard') || 0;
                var standardScale = get(next, 'form.E_system_发票明细_税额.standardScale') || 2;
                return new Big(cur).plus(new Big(num)).toFixed(Number(standardScale));
            }, 0);
            return Number(total);
        };
        this.getTicketTaxAmount = function (item) {
            var form = item.master.form, taxAmount = item.taxAmount;
            if (taxAmount) {
                return typeof taxAmount === 'object' ? Number(taxAmount.standard) : Number(taxAmount);
            }
            return typeof form['E_税额'] === 'object' && form['E_税额'] !== null ? Number(form['E_税额'].standard) : 0;
        };
        this.getTaxAmount = function (item) {
            var entityId = item.master.entityId;
            if (entityId === 'system_发票主体') {
                return _this.getInvoiceTaxAmount(item);
            }
            else {
                return _this.getTicketTaxAmount(item);
            }
        };
        this.getTaxRate = function (item) {
            var _a = item.master, entityId = _a.entityId, form = _a.form, taxRate = item.taxRate;
            if (taxRate !== undefined) {
                return Number(taxRate);
            }
            else {
                if (entityId !== 'system_发票主体') {
                    return !!form['E_税率'] ? Number(form['E_税率']) : 0;
                }
                return 0;
            }
        };
        this.getAllMoney = function (details) {
            return details.reduce(function (cur, next) {
                var form = next.form;
                var money = typeof form['E_system_发票明细_金额'] === 'object' && form['E_system_发票明细_金额'] !== null ? form['E_system_发票明细_金额'].standard : form['E_system_发票明细_金额'] || 0;
                var tax = typeof form['E_system_发票明细_税额'] === 'object' && form['E_system_发票明细_税额'] !== null ? form['E_system_发票明细_税额'].standard : form['E_system_发票明细_税额'] || 0;
                var standardScale = typeof form['E_system_发票明细_金额'] === 'object' && form['E_system_发票明细_金额'] !== null ? form['E_system_发票明细_金额'].standardScale : 2;
                var num = new Big(Number(money)).plus(new Big(Number(tax)));
                return new Big(Number(cur)).plus(new Big(Number(num))).toFixed(Number(standardScale));
            }, 0);
        };
    }
    Object.defineProperty(InvoiceTaxInfo.prototype, "canEditList", {
        get: function () {
            return ['system_发票主体', 'system_火车票', 'system_客运汽车发票', 'system_航空运输电子客票行程单', 'system_其他', 'system_过路费发票', 'system_机打发票'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvoiceTaxInfo.prototype, "canState", {
        get: function () {
            return ['paid', 'archived'];
        },
        enumerable: true,
        configurable: true
    });
    return InvoiceTaxInfo;
}());
export default InvoiceTaxInfo;
