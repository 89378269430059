import { __assign, __read, __spread } from "tslib";
import { get } from 'lodash';
import React from 'react';
import { app as api } from '@ekuaibao/whispered';
import { getNumberColor } from '@ekuaibao/lib/lib/entityUtil/entityTableUtil';
import { parseQuery2Select } from '@ekuaibao/lib/lib/parseQuery2Select';
import { fixBaseData, fixMoney, fixPayeeInfo, fixSwitch, SwitcherData } from '../data-grid/columnOthers';
import fetchFixer from '../data-grid/fetchFixer';
import PersonnelList from '../puppet/personnelList/PersonnelList';
import { getValue } from '../dataLink-card/utils/dataLinkUtils';
import { Tooltip, Icon, Popover } from 'antd';
import { fnShouldShowWarning } from '../../components/utils/fnFormartDatalinkData';
import DataLinkUrlText from './DataLinkUrlText';
import DataLinkCode from './DataLinkCode';
import { getStaffShowByConfig } from '../utilFn';
export function parseColumns(param) {
    var bus = param.bus, action = param.action, fields = param.fields, otherColumns = param.otherColumns, path = param.path, useCountCanLink = param.useCountCanLink, platformType = param.platformType, entityInfoMap = param.entityInfoMap, entityInfo = param.entityInfo, linkType = param.linkType;
    var newFields = [];
    var fieldMap = {};
    if (fields && path) {
        fieldMap = parseFieldMap(fields, path);
    }
    fields.forEach(function (f) {
        if (!(f.type === 'list' && platformType === 'PRIVATE_CAR')) {
            newFields.push(f);
        }
    });
    var columns = parseToColumn({
        fields: fields,
        fixedCount: 0,
        otherColumns: otherColumns || undefined,
        action: action ? action : undefined,
        bus: bus,
        entityInfoMap: entityInfoMap,
        useCountCanLink: useCountCanLink,
        platformType: platformType,
        entityInfo: entityInfo,
        linkType: linkType
    });
    return { columns: columns, fieldMap: fieldMap };
}
export function parseFieldMap(fields, path) {
    var fieldMap = {};
    fields.forEach(function (f) {
        if (f.name === 'entityId') {
            fieldMap[f.name] = __assign(__assign({}, f), { dataType: { type: f.type } });
        }
        else {
            fieldMap["" + path[f.name]] = __assign(__assign({}, f), { dataType: { type: f.type } });
        }
    });
    return fieldMap;
}
function parseToColumn(_a) {
    var fields = _a.fields, fixedCount = _a.fixedCount, otherColumns = _a.otherColumns, action = _a.action, bus = _a.bus, useCountCanLink = _a.useCountCanLink, platformType = _a.platformType, entityInfoMap = _a.entityInfoMap, entityInfo = _a.entityInfo, linkType = _a.linkType;
    var columns = createColumns({
        fields: fields,
        fixedCount: fixedCount,
        bus: bus,
        useCountCanLink: useCountCanLink,
        platformType: platformType,
        entityInfoMap: entityInfoMap,
        entityInfo: entityInfo,
        linkType: linkType
    });
    if (otherColumns) {
        columns.push(otherColumns(bus, columns.length));
    }
    if (action) {
        columns.push(action(bus, columns.length));
    }
    return columns;
}
export function createColumns(_a) {
    var fields = _a.fields, fixedCount = _a.fixedCount, bus = _a.bus, useCountCanLink = _a.useCountCanLink, platformType = _a.platformType, entityInfoMap = _a.entityInfoMap, entityInfo = _a.entityInfo, linkType = _a.linkType;
    return fields.map(function (line, index, arr) {
        return parseColumn({
            property: line,
            index: index,
            fixedCount: fixedCount,
            bus: bus,
            length: arr.length,
            isSingleSelect: false,
            useCountCanLink: useCountCanLink,
            platformType: platformType,
            entityInfoMap: entityInfoMap,
            entityInfo: entityInfo,
            linkType: linkType
        });
    });
}
export function parseData(dataSource) {
    return dataSource.map(function (line) {
        return __assign(__assign({}, line), { key: line.id, id: line.id, platformId: line.platformId, active: line.active, dataLinkCount: { useCount: line.useCount, totalCount: line.totalCount } });
    });
}
var hanldeOnClick = function (e, value, entityInfo) {
    e && e.stopPropagation && e.stopPropagation();
    e && e.preventDefault && e.preventDefault();
    if (value) {
        var id = get(value, 'data.dataLink.id');
        api.open('@bills:DataLinkDetailModal', {
            entityInfo: { dataLink: { id: id } },
            showClose: true,
            viewKey: 'DataLinkDetailModal'
        });
    }
};
function parseColumn(_a) {
    var property = _a.property, index = _a.index, fixedCount = _a.fixedCount, bus = _a.bus, isSingleSelect = _a.isSingleSelect, useCountCanLink = _a.useCountCanLink, length = _a.length, platformType = _a.platformType, entityInfoMap = _a.entityInfoMap, entityInfo = _a.entityInfo, linkType = _a.linkType;
    var label = property.label, type = property.type, fixed = property.fixed, entity = property.entity;
    var dataIndex = parseDataIndex(property);
    var others = parseMeta2ColumnOthers({
        type: type,
        property: property,
        bus: bus,
        isSingleSelect: isSingleSelect,
        useCountCanLink: useCountCanLink,
        platformType: platformType,
        entityInfoMap: entityInfoMap,
        entityInfo: entityInfo,
        linkType: linkType
    });
    var mfixed = length <= 4 ? false : index < fixedCount;
    var width = length <= 4 ? {} : { width: 200 };
    var EXCLUDE_I18N = ['code'];
    var showLabel = EXCLUDE_I18N.includes(label) ? label : i18n.get(label);
    return __assign(__assign(__assign({ title: showLabel || label, dataIndex: dataIndex, dataType: type, filterType: (entity === null || entity === void 0 ? void 0 : entity.startsWith('basedata.Enum')) ? false : type, key: dataIndex, sorter: true, label: showLabel || label, value: dataIndex, property: property }, width), { className: 'fs-14', fixed: !!fixed ? fixed : mfixed }), others);
}
function parseDataIndex(property) {
    var _a = property.source, source = _a === void 0 ? 'form' : _a, name = property.name;
    if (source === 'planned') {
        var array = name.split('_');
        var suffix = array[array.length - 1].toUpperCase();
        return source + "." + name + "." + suffix;
    }
    return source + "." + name;
}
export function parseMeta2ColumnOthers(_a) {
    var type = _a.type, property = _a.property, bus = _a.bus, isSingleSelect = _a.isSingleSelect, useCountCanLink = _a.useCountCanLink, platformType = _a.platformType, entityInfoMap = _a.entityInfoMap, entityInfo = _a.entityInfo, linkType = _a.linkType;
    if (type === 'text' && property.name.endsWith('原始单据')) {
        return fixBill();
    }
    if (type === 'ref' && (property === null || property === void 0 ? void 0 : property.entity) === 'basedata.Enum.currency') {
        return fixCurrency(property.name);
    }
    if (type === 'text') {
        return fixText(property);
    }
    if (type === 'money') {
        return fixMoney({ valueSize: 14, canFilter: property.source !== 'planned' });
    }
    if (type === 'active' || property.name === 'active') {
        return fixActive(property, isSingleSelect);
    }
    if (type === 'switcher') {
        return fixSwitch(SwitcherData);
    }
    if (type === 'dataLinkCount' || property.name === 'useCount') {
        return fixDataLinkCount(property, bus, isSingleSelect, useCountCanLink);
    }
    if (type === 'location') {
        return fixLocation();
    }
    if (type === 'duration') {
        return fixDuration();
    }
    if (type === 'staffFZ' || type === 'staffCYR') {
        return fixStaff(type, bus, property, platformType);
    }
    if (type === 'list' && get(property, 'elemType.entity', '').startsWith('datalink.DataLinkEntity')) {
        return fixDataLinks(entityInfo);
    }
    if (type === 'list' && property.elemType && property.elemType.entity === 'pay.PayeeInfo') {
        return fixMutilPayeeInfo(bus, platformType);
    }
    if (type === 'list') {
        return fixList(property);
    }
    if (type === 'number') {
        return fixNumber(property);
    }
    if (type === 'dataLinkType') {
        return fixDataLinkType(entityInfoMap);
    }
    if (type === 'settlementOpportunity') {
        return fixSettlementOpportunity();
    }
    if (type === 'settlementPeriod') {
        return fixSettlementPeriod();
    }
    if (type === 'ref') {
        if (property.entity.startsWith('pay.PayeeInfo')) {
            return fixPayeeInfo();
        }
        else if (property.entity.startsWith('organization.Staff')) {
            return fixStaffName(type, bus, property, platformType);
        }
        else if (property.entity.startsWith('datalink.DataLinkEntity')) {
            return fixDataLink(entityInfo);
        }
        else if (property.entity.startsWith('basedata.Dimension')) {
            return fixBaseData();
        }
        else if (property.entity.startsWith('basedata.city')) {
            return fixCity();
        }
    }
    if (property.label === '单号' && linkType === 'CHANPAY') {
        return fixDocumentNo(property.label);
    }
    return {};
}
var opportunityFn = api.invokeServiceAsLazyValue('@supplier-file:get:supplier:enums');
function fixSettlementOpportunity() {
    var opportunity = opportunityFn().opportunity;
    return {
        render: function (val) {
            var settleType = opportunity.find(function (el) { return el.name === val; });
            return settleType ? settleType.label : '-';
        },
        filterType: 'list',
        lookup: {
            dataSource: opportunity,
            displayExpr: 'label',
            valueExpr: 'name'
        }
    };
}
var periodFn = api.invokeServiceAsLazyValue('@supplier-file:get:supplier:enums');
function fixSettlementPeriod() {
    var period = periodFn().period;
    return {
        render: function (val) {
            var settleTime = period.find(function (el) { return el.name === val; });
            return settleTime ? settleTime.label : '-';
        },
        filterType: 'list',
        lookup: {
            dataSource: period,
            displayExpr: 'label',
            valueExpr: 'name'
        }
    };
}
function fixMutilPayeeInfo(bus, platformType) {
    return {
        width: 350,
        render: function (payeeInfo, record) {
            if (!(Array.isArray(payeeInfo) && payeeInfo.length)) {
                return React.createElement(NullCell, null);
            }
            var item = payeeInfo[0];
            var name = item.name, bank = item.bank, branch = item.branch, cardNo = item.cardNo, icon = item.icon, type = item.type;
            var bankName = bank || branch;
            var accountType = type === 'PERSONAL' ? i18n.get('个人账户') : i18n.get('对公账户');
            var cardNoLable = " **** " + cardNo.substring(cardNo.length - 4, cardNo.length);
            return (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                React.createElement("div", { className: "bank-card", style: {
                        background: '#fff',
                        border: '1px solid rgba(29,43,61,0.09)',
                        borderRadius: '4px',
                        padding: '8px'
                    } },
                    React.createElement("div", { className: "bank-title", style: { display: 'flex', justifyContent: 'space-between', marginBottom: '8px' } },
                        React.createElement("div", { className: "text-nowrap-ellipsis", style: { fontWeight: 600, color: 'rgba(29,43,61,1)', width: '160px' } }, name),
                        React.createElement("div", { style: { fontSize: '12px', color: 'rgba(29,43,61,0.4)' } }, accountType)),
                    React.createElement("div", { className: "bank-body", style: { display: 'flex' } },
                        icon && React.createElement("img", { className: "bank-icon-img", src: icon, alt: "" }),
                        React.createElement("div", { className: "bank-name text-nowrap-ellipsis", style: { width: '150px' } }, bankName),
                        React.createElement("div", { className: "bank-cardNo" }, cardNoLable))),
                React.createElement("span", { className: "bank-more", style: { color: 'rgba(0,107,224,1)', cursor: 'pointer', padding: '8px 16px' }, onClick: function () {
                        return bus.emit('edit:table:supplier', platformType === 'SUPPLIER' ? record : get(record, 'dataLink'));
                    } }, i18n.get('更多'))));
        }
    };
}
function fixDataLinkType(entityInfoMap) {
    return {
        filterType: undefined,
        render: function (text) {
            if (!text) {
                return React.createElement("span", null, '-');
            }
            var value = entityInfoMap[text];
            return React.createElement("span", null, value ? value : '-');
        }
    };
}
function getTitleByTem(value) {
    if (!value) {
        return '-';
    }
    var _a = value.data, data = _a === void 0 ? {} : _a, _b = value.template, template = _b === void 0 ? {} : _b;
    var titleTem = get(template, 'content.expansion.title.fields[0]');
    var codeTem = get(template, 'content.expansion.description.fields[0]');
    var title = titleTem ? getValue([titleTem], data) : '-';
    var code = codeTem ? getValue([codeTem], data) : '-';
    return title === '-' || code === '-' || !title || !code ? { title: title || '-', code: '-' } : { title: title, code: code };
}
export function fixDataLink(entityInfo) {
    return {
        render: function (value) {
            var data = getTitleByTem(value);
            var title = data.title, code = data.code;
            return title === '-' || code === '-' ? (React.createElement("span", null, title)) : (React.createElement(Popover, { placement: "topLeft", content: React.createElement("div", null, code) },
                React.createElement("span", { style: { color: 'var(--brand-base)', cursor: 'pointer' }, onClick: function (e) { return hanldeOnClick(e, value, entityInfo); } }, title)));
        }
    };
}
export function fixDataLinks(entityInfo) {
    return {
        render: function (value) {
            if (value && Array.isArray(value)) {
                var maps = value.map(function (item, index) {
                    var data = getTitleByTem(item);
                    var title = data.title, code = data.code;
                    return title === '-' || code === '-' ? (React.createElement("span", null, title)) : (React.createElement(Popover, { key: index, placement: "topLeft", content: React.createElement("div", null, code) },
                        React.createElement("span", { style: { color: 'var(--brand-base)', cursor: 'pointer' }, onClick: function (e) { return hanldeOnClick(e, item, entityInfo); } }, title)));
                });
                return maps;
            }
            else {
                return React.createElement("div", null, "-");
            }
        }
    };
}
export function fixCity() {
    return {
        render: function (text) {
            if (!text) {
                return React.createElement("span", null, '-');
            }
            var arr = JSON.parse(text);
            var label = arr.map(function (line) { return line.label; }).join(',');
            return React.createElement("span", null, label);
        }
    };
}
export function fixList(property) {
    var _a = property.elemType, type = _a.type, entity = _a.entity;
    if (type === 'ref' && entity === 'organization.Staff') {
        return {
            filterType: 'ref',
            render: function (value) {
                var staffs = value && value.length > 0
                    ? value
                        .filter(function (i) { return i; })
                        .map(function (item) { return getStaffShowByConfig(item); })
                        .join(',')
                    : '-';
                return React.createElement("span", null, staffs);
            }
        };
    }
    else if (type === 'ref' && entity.startsWith('basedata.Dimension.')) {
        return {
            filterType: 'ref',
            render: function (value) {
                var dimension = value && value.length > 0
                    ? value
                        .filter(function (i) { return i; })
                        .map(function (item) { return item.name + "(" + item.code + ")"; })
                        .join(',')
                    : '-';
                return React.createElement("span", null, dimension);
            }
        };
    }
    else if (type === 'location') {
        return {
            render: function (value) {
                if (value && value.length) {
                    return (React.createElement("span", null, value
                        .filter(function (i) { return i; })
                        .map(function (v) { return v.name || v.address; })
                        .join('/')));
                }
                return React.createElement("span", null, "-");
            }
        };
    }
    else {
        return {
            render: function (value) {
                return React.createElement("span", null, i18n.get('无法解析'));
            }
        };
    }
}
export function parseOptions(_a) {
    var options = _a.options, isSingleSelect = _a.isSingleSelect, _b = _a.entityInfo, entityInfo = _b === void 0 ? {} : _b, fieldMap = _a.fieldMap, dataLinkList = _a.dataLinkList, isRemuneration = _a.isRemuneration, flowId = _a.flowId, isSelectOwner = _a.isSelectOwner, _c = _a.type, type = _c === void 0 ? 'DATALINK' : _c, expenseCodeList = _a.expenseCodeList;
    var param = Object.assign({}, options);
    param = fetchFixer(param, __assign(__assign({}, fieldMap), { 'form.dataLinkCount': { name: 'form.dataLinkCount' } }));
    var _d = param.filters, filters = _d === void 0 ? {} : _d, orderManagement = param.orderManagement, formdataQuery = param.formdataQuery;
    var searchText = param.searchText;
    if (isSingleSelect && param.filters && !param.filters.active) {
        filters = __assign(__assign({}, filters), { active: true });
    }
    param.filters = filters;
    var _e = entityInfo, fields = _e.fields, entityInfoType = _e.type;
    var columns = [];
    if (searchText && searchText.length) {
        if (isRemuneration) {
            columns = fields === null || fields === void 0 ? void 0 : fields.filter(function (f) { var _a, _b; return ((_a = f === null || f === void 0 ? void 0 : f.name) === null || _a === void 0 ? void 0 : _a.indexOf('_name')) > -1 || ((_b = f === null || f === void 0 ? void 0 : f.name) === null || _b === void 0 ? void 0 : _b.indexOf('_工号证件号')) > -1; });
        }
        else {
            columns = fields === null || fields === void 0 ? void 0 : fields.slice().filter(function (f) { var _a, _b; return ((_a = f === null || f === void 0 ? void 0 : f.name) === null || _a === void 0 ? void 0 : _a.indexOf('_name')) > -1 || ((_b = f === null || f === void 0 ? void 0 : f.name) === null || _b === void 0 ? void 0 : _b.indexOf('_code')) > -1; });
        }
        columns = columns === null || columns === void 0 ? void 0 : columns.map(function (f) { return "form." + (f === null || f === void 0 ? void 0 : f.name); });
    }
    var query = parseQuery2Select(param, undefined, columns, dataLinkList, flowId, fieldMap);
    if (type === 'PRIVATE_CAR' || type === 'TRAVEL_MANAGEMENT') {
        query.orderBy('createTime', 'DESC');
    }
    if (orderManagement) {
        formdataQuery && constructorOrderManageQuery(query, formdataQuery);
    }
    if (entityInfoType === 'ORDER' && type === 'TRAVEL_MANAGEMENT') {
        var entityId_1 = entityInfo.id;
        var filterCode = expenseCodeList && expenseCodeList.length
            ?
                expenseCodeList.map(function (item) { return "form.E_" + entityId_1 + "_\u7533\u8BF7\u5355\u7F16\u53F7.contains(\"" + item + "\")"; }).join(' || ')
            : '';
        if (filterCode) {
            query.filterBy(filterCode);
        }
    }
    if (isSelectOwner) {
        query = query.select("ownerId(...),...");
    }
    query = query.value();
    return query;
}
var constructorOrderManageQuery = function (query, formdataQuery) {
    console.log('=====formdataQuery', formdataQuery);
    formdataQuery &&
        Object.keys(formdataQuery).forEach(function (e) {
            if (e && e != undefined) {
                query.filterBy(e + ".contains(\"" + formdataQuery[e] + "\")");
            }
        });
};
var filterDataSource = [
    { label: i18n.get('已启用'), value: 'true' },
    { label: i18n.get('已停用'), value: 'false' }
];
var activeLookup = {
    dataSource: filterDataSource,
    displayExpr: 'label',
    valueExpr: 'value'
};
function fixActive(property, isSingleSelect) {
    return {
        filterType: isSingleSelect ? undefined : 'list',
        filterDataSource: filterDataSource,
        lookup: activeLookup,
        render: function (active, record) {
            var backgroundColor = active ? '#A4D76E' : '#CBCBCB';
            var styles = { width: 6, height: 6, backgroundColor: backgroundColor, marginRight: 8, borderRadius: 4 };
            var text = active === undefined ? '-' : i18n.get('已停用');
            return active ? (React.createElement("div", { className: "option-line" },
                React.createElement("span", { style: styles }),
                i18n.get('启用中'))) : (React.createElement("div", { className: "option-line" },
                active === undefined ? null : React.createElement("span", { style: styles }),
                text));
        }
    };
}
function fixLocation() {
    return {
        filterType: undefined,
        render: function (location) {
            if (!location) {
                return '-';
            }
            var _a = location, name = _a.name, address = _a.address;
            return React.createElement("span", null, name || address);
        }
    };
}
function fixBill() {
    return {
        filterType: undefined,
        render: function (bill) {
            var value = typeof bill === 'object' ? get(bill, 'form.title', '-') : bill ? bill : '-';
            return React.createElement("span", null, value);
        }
    };
}
export var formatLinkText = function (value, propertyName) {
    var linkObj;
    var n = String(propertyName).split('_')[2];
    var shouldNotOpenLink = ['name', 'code'].includes(n);
    var needOpenLink = !shouldNotOpenLink && String(value).includes('"link"');
    if (needOpenLink) {
        try {
            linkObj = JSON.parse(value);
        }
        catch (e) {
        }
    }
    var fnOnClick = linkObj && linkObj.link ? function () { return api.emit('@vendor:open:link', linkObj.link); } : null;
    var textValue = value ? (linkObj && linkObj.link ? (linkObj.name ? linkObj.name : linkObj.link) : value) : '-';
    var domValue = fnOnClick ? DataLinkUrlText(textValue, fnOnClick) : textValue;
    return { textValue: textValue, domValue: domValue };
};
export var renderRedirectCode = function (propertyName, data, value) {
    if (!IS_STANDALONE) {
        return null;
    }
    var dataLink = data.dataLink, sourceId = data.sourceId;
    if (sourceId && propertyName === "E_" + dataLink.entityId + "_code") {
        return DataLinkCode(sourceId, value);
    }
    return null;
};
var renderEveryStatus = function (filterDataSourcePayStatus) {
    return {
        filterType: 'list',
        filterDataSource: filterDataSourcePayStatus,
        lookup: {
            dataSource: filterDataSourcePayStatus,
            displayExpr: 'label',
            valueExpr: 'value'
        }
    };
};
var StatusColor;
(function (StatusColor) {
    StatusColor["#A4D76E"] = "#A4D76E";
    StatusColor["#CBCBCB"] = "#CBCBCB";
    StatusColor["rgba(34, 178, 204, 0.36)"] = "rgba(34, 178, 204, 0.36)";
    StatusColor["#22B2CC"] = "#22B2CC";
    StatusColor["#F4664A"] = "#F4664A";
    StatusColor["#FAAD14"] = "#FAAD14";
})(StatusColor || (StatusColor = {}));
var renderStatusStyle = function (value) {
    var backgroundColor;
    switch (value) {
        case '已付款':
        case '全部发起':
        case '全部支付':
            backgroundColor = StatusColor['#22B2CC'];
            break;
        case '部分付款':
        case '部分发起':
        case '部分支付':
            backgroundColor = StatusColor['rgba(34, 178, 204, 0.36)'];
            break;
        case '未付款':
        case '未发起':
        case '未支付':
            backgroundColor = StatusColor['#CBCBCB'];
            break;
        case '超额付款':
        case '超额发起':
        case '超额支付':
            backgroundColor = StatusColor['#F4664A'];
            break;
        default:
            break;
    }
    return { width: 6, height: 6, backgroundColor: backgroundColor, marginRight: 8, borderRadius: 4 };
};
function renderDataLinkTableTextStyleNew(lineNumber) {
    if (lineNumber === void 0) { lineNumber = '5'; }
    var style = {
        'overflow': 'hidden',
        'textOverflow': 'ellipsis',
        'display': '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': lineNumber
    };
    return style;
}
function fixText(_a) {
    var name = _a.name, label = _a.label;
    var nameArr = (name === null || name === void 0 ? void 0 : name.split('_')) || [];
    switch (nameArr[nameArr.length - 1]) {
        case '付款计划发起状态':
            return __assign({ render: function (value, record) {
                    var styles = renderStatusStyle(value);
                    return (React.createElement("div", { className: "option-line" },
                        React.createElement("span", { style: styles }),
                        value ? i18n.get(value) : '-'));
                } }, renderEveryStatus([
                { label: i18n.get('未发起'), value: i18n.get('未发起') },
                { label: i18n.get('部分发起'), value: i18n.get('部分发起') },
                { label: i18n.get('全部发起'), value: i18n.get('全部发起') },
                { label: i18n.get('超额发起'), value: i18n.get('超额发起') }
            ]));
        case '付款计划支付状态':
            return __assign({ render: function (value, record) {
                    var styles = renderStatusStyle(value);
                    return (React.createElement("div", { className: "option-line" },
                        React.createElement("span", { style: styles }),
                        value ? i18n.get(value) : '-'));
                } }, renderEveryStatus([
                { label: i18n.get('未支付'), value: i18n.get('未支付') },
                { label: i18n.get('部分支付'), value: i18n.get('部分支付') },
                { label: i18n.get('全部支付'), value: i18n.get('全部支付') },
                { label: i18n.get('超额支付'), value: i18n.get('超额支付') }
            ]));
        case '付款状态':
            return __assign({ render: function (value, record) {
                    var styles = renderStatusStyle(value);
                    return (React.createElement("div", { className: "option-line" },
                        React.createElement("span", { style: styles }),
                        value ? i18n.get(value) : '-'));
                } }, renderEveryStatus([
                { label: i18n.get('未付款'), value: i18n.get('未付款') },
                { label: i18n.get('已付款'), value: i18n.get('已付款') },
                { label: i18n.get('部分付款'), value: i18n.get('部分付款') },
                { label: i18n.get('超额付款'), value: i18n.get('超额付款') }
            ]));
        default:
            return {
                filterType: 'text',
                render: function (value, line) {
                    var data = value ? value : '-';
                    if (window.IS_SMG && data && data.indexOf('NCC:') === 0) {
                        var url_1 = getSMGNCCTextURL(value);
                        return React.createElement("a", { onClick: function () { return api.emit('@vendor:open:link', url_1); } }, "\u67E5\u770B\u5408\u540C");
                    }
                    var dom = renderRedirectCode(name, line, value);
                    if (dom) {
                        return dom;
                    }
                    var _a = formatLinkText(value, name), textValue = _a.textValue, domValue = _a.domValue;
                    var textValueStyle = renderDataLinkTableTextStyleNew('5');
                    if (value) {
                        return (React.createElement(Tooltip, { title: textValue },
                            React.createElement("span", { className: "data-link-table-text-style-new", style: textValueStyle }, domValue)));
                    }
                    else {
                        return React.createElement("span", { className: "data-link-table-text-style-new", style: textValueStyle }, textValue);
                    }
                }
            };
    }
}
function fixCurrency(propertyName) {
    var _a;
    var list = (_a = api.getState()['@common'].getCurrencyAll) === null || _a === void 0 ? void 0 : _a.slice();
    return {
        filterType: 'list',
        filterDataSource: [],
        lookup: {
            dataSource: [],
            displayExpr: 'label',
            valueExpr: 'value'
        },
        render: function (value) {
            var name = list === null || list === void 0 ? void 0 : list.find(function (i) { return value == (i === null || i === void 0 ? void 0 : i.numCode) || (value === null || value === void 0 ? void 0 : value.id) === (i === null || i === void 0 ? void 0 : i.numCode) || (value === null || value === void 0 ? void 0 : value.numCode) === (i === null || i === void 0 ? void 0 : i.numCode); });
            if (name) {
                return (name === null || name === void 0 ? void 0 : name.name) + " (" + (name === null || name === void 0 ? void 0 : name.strCode) + ")";
            }
            else {
                return '-';
            }
        }
    };
}
export function fixCurrencyList(value) {
    var _a;
    var list = (_a = api.getState()['@common'].getCurrencyAll) === null || _a === void 0 ? void 0 : _a.slice();
    var name = list === null || list === void 0 ? void 0 : list.find(function (i) { return value == (i === null || i === void 0 ? void 0 : i.numCode) || (value === null || value === void 0 ? void 0 : value.id) === (i === null || i === void 0 ? void 0 : i.numCode) || (value === null || value === void 0 ? void 0 : value.numCode) === (i === null || i === void 0 ? void 0 : i.numCode); });
    if (name) {
        return (name === null || name === void 0 ? void 0 : name.name) + " (" + (name === null || name === void 0 ? void 0 : name.strCode) + ")";
    }
    else {
        return '-';
    }
}
function fixDuration() {
    return {
        filterType: undefined,
        render: function (duration) {
            var m = Math.ceil((duration % 3600000) / 60000);
            var h = Math.floor(duration / 3600000);
            if (h > 0) {
                return React.createElement("div", null, i18n.get("{__k0}\u65F6{__k1}\u5206", { __k0: h, __k1: m }));
            }
            return React.createElement("div", null, i18n.get("{__k0}\u5206", { __k0: m }));
        }
    };
}
function fixDataLinkCount(property, bus, isSingleSelect, useCountCanLink) {
    if (useCountCanLink === void 0) { useCountCanLink = true; }
    return {
        filterType: undefined,
        render: function (obj, line) {
            if (typeof line.dataLink.totalCount !== 'number') {
                return React.createElement(NullCell, null);
            }
            return isSingleSelect || !useCountCanLink ? (React.createElement("div", { className: "option-line" },
                React.createElement("span", { className: "ml-5" }, line.dataLink.useCount + " / " + line.dataLink.totalCount))) : (React.createElement("div", { className: "option-line" },
                React.createElement("a", { className: "ant-dropdown-link mr-5", onClick: function (e) { return bus.emit('table:row:useCount:click', line); } }, line.dataLink.useCount),
                React.createElement("span", null, '/'),
                React.createElement("span", { className: "ml-5" }, line.dataLink.totalCount)));
        }
    };
}
export function generateList(obj) {
    var arr = [];
    var staffList = [];
    var roleList = [];
    var departmentList = [];
    staffList = obj ? obj.staff : [];
    roleList = obj ? obj.role : [];
    departmentList = obj ? obj.department : [];
    staffList.forEach(function (item) { return (item.type = 'staff'); });
    roleList.forEach(function (item) { return (item.type = 'role'); });
    departmentList.forEach(function (item) { return (item.type = 'department'); });
    arr = __spread(staffList, roleList, departmentList);
    return arr;
}
function fixStaffName(type, bus, property, platformType) {
    return {
        filterType: 'ref',
        render: function (obj, line) {
            return obj ? getStaffShowByConfig(obj) : '-';
        }
    };
}
function fixStaff(type, bus, property, platformType) {
    var _a = property.isHiddenEditBtn, isHiddenEditBtn = _a === void 0 ? false : _a, isManage = property.isManage;
    return {
        filterType: undefined,
        render: function (obj, line) {
            var dataLink = line.dataLink;
            var ownerId = dataLink.ownerId;
            var staffList = [];
            var flag = false;
            var str = '';
            if (type === 'staffCYR') {
                staffList = generateList(obj);
                var CYRStr = !staffList.length ? '-' : obj;
                str = obj
                    ? obj.fullVisible && platformType != 'supplierFile' && platformType != 'SUPPLIER'
                        ? i18n.get('全部人员')
                        : CYRStr
                    : CYRStr;
                flag = obj ? obj.fullVisible || !staffList.length : !staffList.length;
            }
            else {
                staffList = obj ? [obj] : [];
                var active = obj && obj.active;
                flag = !active;
                str = active ? '' : '-';
            }
            var isShowEdit = obj && !obj.fullVisible;
            var fieldVisibility = ownerId !== undefined;
            if (!fieldVisibility || isHiddenEditBtn) {
                isShowEdit = false;
            }
            if (platformType === 'supplierFile') {
                isShowEdit = true;
            }
            if (type === 'staffFZ' && !isShowEdit && !(staffList === null || staffList === void 0 ? void 0 : staffList.length) && !isHiddenEditBtn) {
                isShowEdit = true;
            }
            var strAll = i18n.get('全部参与人');
            var domStr = i18n.get('查看所有');
            var _a = api.getState()['@common'].userinfo.staff, staff = _a === void 0 ? {} : _a;
            isShowEdit = (ownerId === null || ownerId === void 0 ? void 0 : ownerId.id) === (staff === null || staff === void 0 ? void 0 : staff.id);
            if (type === 'staffCYR' && (obj === null || obj === void 0 ? void 0 : obj.fullVisible)) {
                isShowEdit = false;
            }
            if (isManage && !(obj === null || obj === void 0 ? void 0 : obj.fullVisible)) {
                isShowEdit = true;
            }
            return (React.createElement("div", { className: "option-line" },
                isShowEdit && (React.createElement("div", { style: {
                        color: 'var(--brand-base)',
                        fontSize: 14,
                        flexShrink: 0,
                        marginRight: 8,
                        cursor: 'pointer'
                    }, onClick: function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        bus.emit('table:row:edit:staff', { type: type, obj: obj, line: line, property: property });
                    } }, i18n.get('编辑'))),
                React.createElement("div", null, flag ? (React.createElement("div", null, str)) : (React.createElement(PersonnelList, { dataSource: staffList, moreLine: false, popUpTitle: strAll, domStr: domStr })))));
        }
    };
}
function fixNumber(property) {
    var _a = property.unit, unit = _a === void 0 ? '' : _a, source = property.source, _b = property.name, name = _b === void 0 ? '' : _b;
    var canFilter = source !== 'planned';
    return {
        filterType: canFilter ? 'number' : undefined,
        sorter: canFilter,
        render: function (value, line) {
            if (!value && value !== 0) {
                return React.createElement(NullCell, null);
            }
            var planned = line.planned;
            var plannedValue = get(planned, property.name);
            var cls;
            if (plannedValue) {
                cls = fnShouldShowWarning(value, plannedValue) ? 'data_link_number_red_color' : 'datalink_item_value_color_nor';
            }
            else {
                cls = getNumberColor(name, value);
            }
            return React.createElement("span", { className: cls }, "" + value + unit);
        }
    };
}
function NullCell() {
    return React.createElement("span", null, "-");
}
function fixDocumentNo(label) {
    return {
        title: (React.createElement("span", null,
            label,
            React.createElement(Tooltip, { title: i18n.get('当前仅支持招商银行、建设银行的流水信息匹配单号。如有特殊需要，请联系易快报工作人员') },
                React.createElement(Icon, { style: { fontSize: '14px', color: '#e39a47' }, type: "question-circle-o" }))))
    };
}
export function getActionText(disableStrategy, line) {
    var title = '';
    var text = '';
    switch (disableStrategy) {
        case 'MANUAL_ADMIN':
        case 'MANUAL':
            text = line.dataLink.active === undefined ? '-' : line.dataLink.active ? i18n.get('停用') : i18n.get('启用');
            title = i18n.get('状态');
            break;
        case 'LIMIT_COUNT':
            text = line.dataLink.totalCount === undefined ? '-' : i18n.get('修改次数上限');
            title = i18n.get('引用次数');
            break;
        case 'WRITTEN_OFF':
            text = i18n.get('核销');
            break;
    }
    return { title: title, text: text };
}
export function getSMGNCCTextURL(data) {
    var _a, _b;
    var code = (_b = (_a = api.getState('@common').userinfo) === null || _a === void 0 ? void 0 : _a.staff) === null || _b === void 0 ? void 0 : _b.code;
    var url = data.split('NCC:')[1];
    return url.replace('%empcode%', code);
}
