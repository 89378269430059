/**
 *  Created by panwei on 2018/7/26 下午9:15.
 */
import { getMoney, standardValueMoney, getAcountKey } from './misc'
import { setInvoiceType } from './third-data'
import Big from 'big.js'
import { cloneDeep, get } from 'lodash'
import { isArray } from '@ekuaibao/helpers'
import { total } from '@ekuaibao/lib/lib/lib-util'
import { MoneyMath } from '@ekuaibao/money-math'
import { app as api } from '@ekuaibao/whispered'
import { InvoiceMasterIF } from '@ekuaibao/ekuaibao_types'

export function getInvoiceDetailAmount(detail) {
  const { entityId } = detail
  // @i18n-ignore
  return detail.form[`E_${entityId}_金额`].standard
}

export function invoiceDataConvertDetail({ feeType, invoiceData, specification, isBatch, attachmentList }) {
  let { components } = specification
  let feeTypeForm = getFeeTypeFormFormInvoice(components, invoiceData)
  const formData = {
    feeTypeId: feeType,
    feeTypeForm,
    specificationId: specification,
    attachmentList
  }
  return isBatch
    ? api
        .invoke('generate:DetailByAutoCalculate', { detailFormValue: formData, components })
        .then(newForm => {
          // 如果发现 specificationId 的 open 发生了变化是因为 generate:DetailByAutoCalculate 修改的
          formData.feeTypeForm = newForm
          return formData
        })
        .catch(_ => {
          return formData
        })
    : Promise.resolve(formData)
}

export function getFeeTypeFormFormInvoice(components, invoiceData) {
  let invoiceList = invoiceData
  if (!isArray(invoiceData)) {
    invoiceList = [invoiceData]
  }
  const invoiceInfoList = invoiceList.map(formatInvoiceData)
  const invoiceInfo = invoiceInfoList[0]
  const moneyList = invoiceList.map(getInvoiceAmount)
  const amount = total(moneyList.map(line => line.amount))
  const taxAmount = total(moneyList.map(line => line.taxAmount))
  const noTaxAmount = total(moneyList.map(line => line.noTaxAmount))
  const firstTaxRate = moneyList.length ? moneyList[0].taxRate : undefined
  const flag = moneyList.every(line => line.taxRate === firstTaxRate)
  const taxRate = flag ? (isNaN(Number(firstTaxRate)) ? '0' : firstTaxRate) : undefined
  const haveTaxAmount = components.find(
    line => line.field === 'taxAmount' && (line.defaultValue.type === 'none' || line.defaultValue.type === 'invoiceSum')
  )
  const haveNoTaxAmount = components.find(
    line =>
      line.field === 'noTaxAmount' && (line.defaultValue.type === 'none' || line.defaultValue.type === 'invoiceSum')
  )
  const haveTaxRate = components.find(line => line.field === 'taxRate')
  const haveTaxTotal = components.find(line => line.type === 'money' && line.defaultValue.value === 'taxTotal')
  let feeTypeField = {}
  feeTypeField.amount = amount
  haveNoTaxAmount ? (feeTypeField.noTaxAmount = noTaxAmount) : ''
  haveTaxAmount ? (feeTypeField.taxAmount = taxAmount) : ''
  haveTaxRate ? (feeTypeField.taxRate = taxRate) : ''
  haveTaxTotal ? (feeTypeField[haveTaxTotal.field] = new MoneyMath(taxAmount).add(noTaxAmount).value) : ''
  let c = components.find(v => v.field === 'feeDate')
  if (c && invoiceInfo.date) {
    feeTypeField['feeDate'] = invoiceInfo.date
  }

  if (components.find(v => v.field === 'invoice')) {
    feeTypeField['invoice'] = '1'
  }

  if (components.find(v => v.field === 'invoiceType')) {
    feeTypeField.invoiceType = setInvoiceType(invoiceInfo.type)
  }
  let invoiceType = components.find(v => v.field === 'invoiceForm')
  if (invoiceType) {
    feeTypeField.invoiceForm = {
      invoices: [...invoiceList],
      type: 'exist'
    }
  }

  return { ...feeTypeField }
}

export function formatInvoiceForTax(components, feeTypeField) {
  // 汇总已关联票据的 金额自动计算 计算税额、不计税金额、可抵扣税额、价税合计
  const taxs = components.filter(line => get(line, 'defaultValue.type', '') === 'invoiceSum')
  taxs.forEach(({ defaultValue, field }) => {
    let value = getAcountKey(get(defaultValue, 'value'))

    feeTypeField[field] = feeTypeField[value]
  })
}

export function totalInvoceMoney(data) {
  let total = '0.00'
  data.forEach(line => {
    total = Number(total) + Number(totalAmountAddTaxAmount(line))
  })
  return Number(total).toFixed(2)
}

export function totalAmountAddTaxAmount(detail) {
  const { entityId, form } = detail
  // @i18n-ignore
  return new Big(getMoney(form[`E_${entityId}_金额`])).add(getMoney(form[`E_${entityId}_税额`])).toFixed(2)
}
export function getTaxAmount(detail) {
  const { entityId, form } = detail
  const tags = form[`E_${entityId}_金额`] == void 0 ? form[`E_${entityId}_发票金额`] : form[`E_${entityId}_金额`]
  // @i18n-ignore
  return new Big(getMoney(tags)).toFixed(2)
}

function formatInvoiceData(invoiceData) {
  const {
    master: { id, form, entityId },
    details = []
  } = invoiceData
  return {
    id,
    date: form[`E_${entityId}_发票日期`], // @i18n-ignore
    type: form[`E_${entityId}_发票类别`], // @i18n-ignore
    amount: form[`E_${entityId}_发票金额`], // @i18n-ignore
    taxAmount: form[`E_${entityId}_税额`], // @i18n-ignore
    details: formatInvoiceDetail(details)
  }
}

function formatInvoiceDetail(details = []) {
  return !!details.length
    ? details.map(detail => {
        const { entityId, form, id } = detail
        return {
          taxRate: form[`E_${entityId}_税率`], // @i18n-ignore
          amount: form[`E_${entityId}_单价`], // @i18n-ignore
          taxAmount: form[`E_${entityId}_税额`], // @i18n-ignore
          id: id
        }
      })
    : []
}

export function getInvoiceAmount(invoiceData) {
  let entityId = i18n.get('E_system_发票明细_')
  const invoiceEntity = cloneDeep(invoiceData)
  let entityForm = {}
  let moneyPath
  if (invoiceEntity.details && invoiceEntity.details.length) {
    entityForm = invoiceEntity.details[0].form
    entityId = i18n.get('E_system_发票明细_')
    moneyPath = entityId
  } else if (invoiceEntity.master) {
    entityForm = invoiceEntity.master.form
    entityId = 'E_'
    moneyPath = `E_${invoiceEntity.master.entityId}_`
  }
  // @i18n-ignore
  const noTaxAmountEntity = cloneDeep(entityForm[`${entityId}不计税金额`])
  let amount = entityForm[`${moneyPath}金额`] // @i18n-ignore
  if (!amount || typeof amount !== 'object' || !amount.hasOwnProperty('standard')) {
    amount = standardValueMoney(0)
  }
  let taxAmount = invoiceEntity.taxAmount || entityForm[`${entityId}税额`] // @i18n-ignore
  if (!taxAmount || typeof taxAmount !== 'object' || !taxAmount.hasOwnProperty('standard')) {
    taxAmount = standardValueMoney(0)
  }
  let noTaxAmount = noTaxAmountEntity
  if (!noTaxAmount || typeof noTaxAmount !== 'object' || !noTaxAmount.hasOwnProperty('standard')) {
    noTaxAmount = standardValueMoney(0)
  }
  let taxRate = entityForm[`${entityId}税率`] // @i18n-ignore
  if (invoiceData.details && invoiceData.details.length) {
    let taxAmountNum = 0,
      noTaxAmountNum = 0
    let taxRateMap = {}
    invoiceData.details.forEach(v => {
      // @i18n-ignore
      if (v.form[`${entityId}税额`]) {
        taxAmountNum += v.form[`${entityId}税额`].standard * 1 // @i18n-ignore
      }
      noTaxAmountNum += v.form[`${entityId}金额`].standard * 1 // @i18n-ignore
      // @i18n-ignore
      taxRateMap[v.form[`${entityId}税率`]] = v.form[`${entityId}税率`]
    })
    amount.standard = (taxAmountNum + noTaxAmountNum).toFixed(2)
    // taxAmount.standard = taxAmountNum.toFixed(2)
    noTaxAmount.standard = noTaxAmountNum.toFixed(2)
    const keys = Object.keys(taxRateMap)
    taxRate = keys.length > 1 ? undefined : keys.length === 1 ? taxRateMap[keys[0]] + '' : undefined
    taxRate = taxRate ? taxRate.replace('%', '') : undefined
  }

  return { amount, taxAmount, noTaxAmount, taxRate }
}

const amountKeys = ['amount', 'taxAmount', 'noTaxAmount', 'taxRate']
export function getInvoicesAmount(invoices) {
  let invoiceAmount = {}

  if (invoices.length) {
    invoices.forEach(invoice => {
      const amount = getInvoiceAmount(invoice)
      amountKeys.forEach(key => {
        if ('taxRate' === key) {
          return (invoiceAmount[key] = amount[key])
        }
        invoiceAmount[key] = new MoneyMath(amount[key]).add(invoiceAmount[key]).value
      })
    })
  }
  return invoiceAmount
}

export function invoiceOptions(invoiceType, currentPlanNode) {
  if (!invoiceType) return
  const { noExist, noExistConfig } = invoiceType
  let invoiceItems = [
    {
      label: i18n.get('已有发票'),
      value: i18n.get('已有发票'),
      type: 'exist'
    },
    {
      label: i18n.get('待开发票'),
      value: i18n.get('待开发票'),
      type: 'wait'
    },
    {
      label: i18n.get('统一开票'),
      value: i18n.get('统一开票'),
      type: 'unify'
    }
  ]
  if (noExist) {
    const noExistItem = {
      label: i18n.get('无发票'),
      value: i18n.get('无发票'),
      type: 'noExist'
    }
    if (!noExistConfig || !noExistConfig?.visibility || !currentPlanNode) {
      //未配置适用范围 或无需审批流控制得场景， 所有人可见 无发票
      invoiceItems.push(noExistItem)
    } else {
      //配置了适用范围 但是没有选择审批流 所有人不可见 无发票
      if (noExistConfig?.freeflowScope) {
        let currentFlowCanUseNoExist = noExistConfig?.freeflowScope?.some(i => {
          return i.freeflowId === currentPlanNode?.flowPlanConfigId &&
            (!i.nodeIds || !i.nodeIds?.length || i.nodeIds?.includes(currentPlanNode?.nodeId))
        })
        if (currentFlowCanUseNoExist) {
          invoiceItems.push(noExistItem)
        }
      }
    }
  }
  const selectTypeInvoice = [...invoiceItems]

  let data = selectTypeInvoice.filter(v => (v.type === 'unify' ? invoiceType[v.type].choose : invoiceType[v.type]))
  return data
}
