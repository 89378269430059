import { Fetch } from '@ekuaibao/fetch';
export default function (field) {
    if (field === void 0) { field = {}; }
    var languageMap = {
        'en-US': 'en',
        'zh-CN': 'cn'
    };
    var currentLanguagePrefix = languageMap[Fetch.defaultLanguage];
    return field[currentLanguagePrefix + "Label"] || field.label;
}
