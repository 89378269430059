import { app, app as api } from '@ekuaibao/whispered';
import { getCustomerServiceUrl } from './getCustomerServiceUrl';
export default registerAPI;
export function registerAPI() {
    app.on('@vendor:preview:images', function (urls, current) {
        var urls2 = urls.map(function (url) { return (typeof url === 'string' ? url : url.url); });
        var currentUrl = typeof current === 'string' ? current : current.url;
        app.sdk.previewImages(urls2, currentUrl);
    });
    app.on('@vendor:preview:pdf', function (href) {
        app.sdk.preview(href);
    });
    app.on('@vendor:iframepreview:pdf', function (_a) {
        var modalTitle = _a.modalTitle, items = _a.items, currentIdx = _a.currentIdx;
        app.open('@bills:IframePreviewerModal', {
            modalTitle: modalTitle || '附件预览',
            items: items,
            currentIdx: currentIdx
        });
    });
    app.on('@vendor:preview', function (href, fileName) {
        var watermark = api.getState()['@common'].waterMark;
        watermark && watermark !== '' ? app.sdk.preview(href, fileName, { watermark: watermark }) :
            app.sdk.preview(href, fileName);
    });
    app.on('@vendor:open:link', function (href, needEncode) {
        if (needEncode === void 0) { needEncode = false; }
        app.sdk.openLink(href, { needEncode: needEncode });
    });
    app.on('@vendor:download', function (href, fileName, showDownloadName) {
        app.sdk.download(href, fileName, showDownloadName);
    });
    app.on('@vendor:goto:ocsa', function (user) {
        var url = getCustomerServiceUrl(user, '7321');
        app.sdk.openLink(url);
    });
    app.watch('@vendor:open:layer', function (key, props, isSingle) {
        return app.invoke('@layout:support:open:layer', key, props, isSingle);
    });
    app.watch('@vendor:close:layer', function () {
        return app.invoke('@layout:support:close:layer');
    });
    app.watch('@vendor:dd:message:code', function () {
        return new Promise(function (resolve) { return resolve('debug'); });
    });
    app.watch('@vendor:is:support:authorization', function (args) {
        return true;
    });
    app.on('@vendor:support:select-user', function (args) {
        args.callback && args.callback(false);
    });
    app.on('@vendor:select-user', function (args) {
        app.emit('@layout:support:select-member', args);
    });
    app.on('@vendor:select-multiple-user', function (args) {
        args.multiple = true;
        app.emit('@layout:support:select-member', args);
    });
    app.on('@vendor:select-multiple-user:web', function (args) {
        args.multiple = true;
        app.emit('@layout:support:select-member', args);
    });
    app.watch('@vendor:dingtalk:uploadAttachment', function (params) {
        return app.sdk.invoke('uploadAttachment', params);
    });
    app.watch('@vender:dingtalk:dp:preview', function (params) {
        return app.sdk.invoke('preview', params);
    });
}
