/**
 * Created by LinK on 2017/8/24.
 */
import get from 'lodash/get'
export default field => {
  let { defaultValue } = field
  let scale = get(field, 'dataType.scale', 0)
  if (defaultValue && defaultValue.type === 'constant') {
    return new Big(defaultValue.value).toFixed(scale)
  }
  return undefined
}
