import { getNodeValueByPath } from '@ekuaibao/lib/lib/lib-util'
import { moneyStr2StandardMoneyValue } from '../../lib/misc'
import moment from 'moment'
import { app as api } from '@ekuaibao/whispered'
import { invoiceOptions } from '../../lib/InvoiceUtil'

export function parseValue(data) {
  const form = getNodeValueByPath(data, 'form')
  if (!form) return ''
  let name = ''
  let code = ''
  Object.keys(form).forEach(item => {
    if (item.endsWith('_name')) {
      name = form[item]
    }
    if (item.endsWith('_code')) {
      code = form[item]
    }
  })
  return (name = code ? name + '(' + code + ')' : name)
}
// 通用格式化时间
export function setFeeDate(expenseSpecification, value) {
  let components = expenseSpecification.components
  for (let i = 0; i < components.length; i++) {
    let item = components[i]
    let field = item.field
    if ((field && field === 'feeDatePeriod') || field === 'feeDate') {
      value = value && value.toString()
      let time = moment(value, 'YYYY-MM-DD HH:mm:ss').format('x') * 1
      if (item.type === 'dateRange') {
        return setValueRange(time)
      } else {
        return setValue(time)
      }
    }
  }
}
function setValueRange(time) {
  let start = time || Date.now()
  let end = time || Date.now()
  return {
    feeDatePeriod: {
      start: start,
      end: end
    }
  }
}

function setValue(time) {
  return time || Date.now()
}
export function setInvoiceType(type) {
  switch (type) {
    case 'DIGITAL_NORMAL':
    case 'PAPER_ROLL':
    case 'PAPER_FEE':
    case 'PAPER_NORMAL':
      return 'VATOrdinaryInvoice'
    case 'DIGITAL_SPECIAL':
      return 'VATSpecialInvoic'
    case 'PAPER_SPECIAL':
      return 'VATSpecialInvoice'
    case 'PAPER_CAR':
      return 'MotorInvoice'
    default:
      return ''
  }
}
function invoiceFromDefault(v, feeTypeForm, others) {
  if (!v.editable) {
    feeTypeForm[v.field] = { type: 'noWrite' }
  } else {
    let { invoiceForm } = others || {}
    if (invoiceForm === 'unify' && v.invoiceType.unify.choose) {
      const unifyList = api.getState()['@bills'].corporationList || []
      let didiUnfiy = unifyList.find(line => line.active && line.channel === 'DIDI')
      let invoiceCorpIds = get(v, 'invoiceType.unify.invoiceCorporation')
      if (didiUnfiy && ((invoiceCorpIds && invoiceCorpIds.length === 0) || invoiceCorpIds.indexOf(didiUnfiy.id) >= 0)) {
        feeTypeForm[v.field] = { type: 'unify', invoiceCorporationId: didiUnfiy }
      } else {
        feeTypeForm[v.field] = { type: 'unify' }
      }
    } else {
      let arr = invoiceOptions(v.invoiceType) || []
      let type = arr.length > 0 && arr[0].type
      if (type) {
        feeTypeForm[v.field] = { type }
      }
    }
  }
}
// 初始化feeTypeForm
export function formatFeeTypeForm(components, feeDate, feeTypeForm = {}, data = {}, others) {
  if (typeof feeDate === 'object') {
    feeTypeForm['feeDatePeriod'] = feeDate.feeDatePeriod
  } else {
    feeTypeForm['feeDate'] = feeDate
  }

  components.forEach(v => {
    // 处理映射字段默认值
    if (v.field === 'invoice') {
      feeTypeForm[v.field] = '1'
    }
    if (v.type === 'switcher') {
      feeTypeForm[v.field] = v.defaultValue && v.defaultValue.value
    }
    if (v.field === 'invoiceForm') {
      invoiceFromDefault(v, feeTypeForm, others)
    }
    if (v.type === 'money' && feeTypeForm[v.field]) {
      feeTypeForm[v.field] = moneyStr2StandardMoneyValue(feeTypeForm[v.field])
    }

    if (v.field === 'invoiceType') {
      feeTypeForm.invoiceType = setInvoiceType(data.invtype)
    }

    if (v.field === 'noTaxAmount') {
      const noTaxAmount = Number(data.invamt) || data.invamt || data.noTaxAmount
      feeTypeForm.noTaxAmount = moneyStr2StandardMoneyValue(noTaxAmount)
    }

    if (v.field === 'taxAmount') {
      const taxAmount = Number(data.invtaxamt) || data.invtaxamt
      feeTypeForm.taxAmount = moneyStr2StandardMoneyValue(taxAmount)
    }
    if (v.field === 'taxRate') {
      //税率
      if (data && data.itemdata) {
        const itemdata = JSON.parse(data.itemdata)
        if (itemdata && itemdata.length === 1) {
          feeTypeForm.taxRate = itemdata[0].taxRate
        }
      }
    }

    // //档案的固定值处理
    // if (v.defaultValue && v.defaultValue.type === 'constant') {
    //   const {
    //     defaultValue: { value }
    //   } = v
    //   if (value && value.id) {
    //     feeTypeForm[v.field] = value.id
    //   }
    // }
  })
  return feeTypeForm
}

export function checkPriority(field) {
  let { editable, priority } = field
  //旧数据没有priority的特殊处理
  if (!priority && editable) {
    return true
  }
  if (!priority && !editable) {
    return false
  }
  const link = priority.find(v => v.type === 'LINKAGE_ASSIGNMENT').value
  const manual = priority.find(v => v.type === 'MANUALLY_FILL_IN').value
  const cost = priority.find(v => v.type === 'COST_STANDARD').value
  //说明数据互联的优先级高于自动计算
  if (link > cost && !editable) {
    return true
  }

  //说明数据互联的优先级高于手动填写，底于自动计算
  if (link > manual && editable) {
    return true
  }

  return false
}

//判断是否需要展示超额提示
export function fnShouldShowWarning(percent, planned) {
  const { RIGID_PERCENTAGE, WEAKLY_PERCENTAGE, controlType } = planned
  if (controlType === 'ALL' || controlType === 'WEAKLY') {
    return percent > WEAKLY_PERCENTAGE
  } else if (controlType === 'RIGID') {
    return percent > RIGID_PERCENTAGE
  }
  return false
}
