import loadable from '@loadable/component';
function loadableWithDescriptor(fn, descriptor) {
    var oo = loadable(fn);
    oo.descriptor = descriptor;
    return oo;
}
var CheckBox = loadableWithDescriptor(function () { return import('./internal/CheckBox'); }, {
    type: 'checkbox'
});
var BillExceedCheckbox = loadableWithDescriptor(function () { return import('./internal/BillExceedCheckbox'); }, {
    type: 'bill-exceed-checkbox'
});
var LinkRequisitionControlled = loadableWithDescriptor(function () { return import('./internal/LinkRequisitionControlled'); }, {
    type: 'linkRequisitionControlled'
});
var CheckBoxGroup = loadableWithDescriptor(function () { return import('./internal/CheckBoxGroup'); }, {
    type: 'checkbox-group'
});
var CheckBoxScopeTags = loadableWithDescriptor(function () { return import('./internal/CheckBoxScopeTags'); }, {
    type: 'checkbox:scope:tags'
});
var CheckBoxTagsTree = loadableWithDescriptor(function () { return import('./internal/CheckBoxFeetypeTags'); }, {
    type: 'checkbox:feetype:tags'
});
var CheckBoxDefaultValue = loadableWithDescriptor(function () { return import('./internal/CheckBoxDefaultValue'); }, {
    type: 'checkbox:default:value'
});
var RadioGroup = loadableWithDescriptor(function () { return import('./internal/RadioGroup'); }, {
    type: 'radio-group'
});
var WhiteSpace = loadableWithDescriptor(function () { return import('./internal/WhiteSpace'); }, {
    type: 'whitespace'
});
var RadioGroupMore = loadableWithDescriptor(function () { return import('./internal/RadioGroupMore'); }, {
    type: 'radio-group-more'
});
var TagList = loadableWithDescriptor(function () { return import('./internal/TagList'); }, {
    type: 'tag-list'
});
var NumberRange = loadableWithDescriptor(function () { return import('./internal/NumberRange'); }, {
    type: 'numberRange'
});
var DepRuleCheckBox = loadableWithDescriptor(function () { return import('./internal/DepRuleCheckBox'); }, {
    type: 'depRule-checkbox'
});
var filterRuleCheckBox = loadableWithDescriptor(function () { return import('./internal/FilterRuleCheckBox'); }, {
    type: 'filter-rule-checkbox'
});
var descTitle = loadableWithDescriptor(function () { return import('./internal/DescTitle'); }, {
    type: 'descTitle'
});
var CheckBoxSelectTags = loadableWithDescriptor(function () { return import('./internal/CheckBoxSelectTags'); }, {
    type: 'checkbox:select:tags'
});
var ApplyContentRuleRadioGroup = loadableWithDescriptor(function () { return import('./internal/ApplyContentRuleRadioGroup'); }, {
    type: 'applyContentRule'
});
var SelectFlow = loadableWithDescriptor(function () { return import('./internal/SelectFlow'); }, {
    type: 'select:flow'
});
var DescText = loadableWithDescriptor(function () { return import('./internal/DescText'); }, {
    type: 'descText'
});
var ComplexSelect = loadableWithDescriptor(function () { return import('./internal/DataLinkComplexSelect'); }, {
    type: 'complex-select'
});
var SubmitterSelect = loadableWithDescriptor(function () { return import('./internal/SubmitterComplexSelect'); }, {
    type: 'submitter-select'
});
var ComplexFilter = loadableWithDescriptor(function () { return import('./internal/DataLinkComplexFilter'); }, {
    type: 'complex-filter'
});
var DetailComplexSelect = loadableWithDescriptor(function () { return import('./internal/DetailComplexSelect'); }, {
    type: 'detail-complex-select'
});
var SubsidyComplexSelect = loadableWithDescriptor(function () { return import('./internal/SubsidyComplexSelect'); }, {
    type: 'subsidy-complex-select'
});
var BillTypeCheckboxGroup = loadableWithDescriptor(function () { return import('./internal/BillTypeCheckboxGroup'); }, {
    type: 'bill-checkbox-group'
});
var BillTypeRadioGroup = loadableWithDescriptor(function () { return import('./internal/BillTypeRadioGroup'); }, {
    type: 'bill-radio-group'
});
var BlockUI = loadableWithDescriptor(function () { return import('./internal/BlockUIConfig'); }, {
    type: 'blockUI'
});
var Icon = loadableWithDescriptor(function () { return import('./internal/Icon'); }, {
    type: 'icon'
});
var Color = loadableWithDescriptor(function () { return import('./internal/Color'); }, {
    type: 'color'
});
var RequisitionCanLoan = loadableWithDescriptor(function () { return import('./internal/RequisitionCanLoan'); }, {
    type: 'requisitionCanLoan'
});
var AutoShareRule = loadableWithDescriptor(function () { return import('./internal/autoShareRule'); }, {
    type: 'autoShareRule'
});
var tripPlatform = loadableWithDescriptor(function () { return import('./internal/tripPlatformJump'); }, {
    type: 'tripPlatform'
});
var InvoiceImportCheckboxGroup = loadableWithDescriptor(function () { return import('./internal/InvoiceImportCheckboxGroup'); }, {
    type: 'import-invoice-checkbox-group'
});
var Describe = loadableWithDescriptor(function () { return import('./internal/Describe'); }, {
    type: 'describe'
});
var Divider = loadableWithDescriptor(function () { return import('./internal/Divider'); }, {
    type: 'divider'
});
var Priority = loadableWithDescriptor(function () { return import('./internal/Priority'); }, {
    name: 'priority'
});
var ForbidEdit = loadableWithDescriptor(function () { return import('./internal/ForbidEdit'); }, {
    type: 'forbidEdit'
});
var ApportionRuleRadio = loadableWithDescriptor(function () { return import('./internal/ApportionRuleRadio'); }, {
    type: 'radio-select'
});
var ExpenseComplexSelect = loadableWithDescriptor(function () { return import('./internal/ExpenseComplexSelect'); }, {
    type: 'expense-complex-select'
});
var MultPayeesCheckBoxSelectTags = loadableWithDescriptor(function () { return import('./internal/MultPayeesCheckBoxSelectTags'); }, {
    type: 'multipayees:checkbox:select:tags'
});
var DescribeWithPopup = loadableWithDescriptor(function () { return import('./internal/DescribeWithPopup'); }, {
    type: 'describeWithPopup'
});
var DetailVisibleCheckBox = loadableWithDescriptor(function () { return import('./internal/DetailVisibleCheckBox'); }, {
    type: 'detailVisibleCheckBox'
});
var DestrictedTravelTypeSelect = loadableWithDescriptor(function () { return import('./internal/DestrictedTravelTypeSelect'); }, {
    type: 'destrictedTravelType'
});
var BeneficiaryAccountRule = loadableWithDescriptor(function () { return import('./internal/BeneficiaryAccountRule'); }, {
    type: 'beneficiaryAccountRule'
});
var ReceiptSummarySelect = loadableWithDescriptor(function () { return import('./internal/ReceiptSummarySelect'); }, {
    type: 'receiptSummarySelect'
});
var AllowedRepaymentSelect = loadableWithDescriptor(function () { return import('./internal/AllowedRepaymentSelect'); }, {
    type: 'allowedRepaymentSelect'
});
var WrittenOffRateType = loadableWithDescriptor(function () { return import('./internal/WrittenOffRateType'); }, {
    type: 'writtenOffRateType'
});
var RepaymentDateLimitField = loadableWithDescriptor(function () { return import('./internal/repaymentdate/RepaymentDateLimitField'); }, {
    type: 'repaymentdatelimit'
});
var RepaymentDateModifyField = loadableWithDescriptor(function () { return import('./internal/repaymentdate/RepaymentDateModifyField'); }, {
    type: 'repaymentdatemodify'
});
var TextFiled = loadableWithDescriptor(function () { return import('./internal/TextField'); }, {
    type: 'text-field'
});
var RequiredFieldConfig = loadableWithDescriptor(function () { return import('./internal/RequiredFieldConfig'); }, {
    type: 'required-field-config'
});
var HideFieldConfig = loadableWithDescriptor(function () { return import('./internal/HideFieldConfig'); }, {
    type: 'hide-field-config'
});
var SelectStaffCheckBox = loadableWithDescriptor(function () { return import('./internal/SelectStaffCheckBox'); }, {
    type: 'select-staff-checkbox'
});
var SelectDateTime = loadableWithDescriptor(function () { return import('./internal/SelectDateTime'); }, {
    type: 'select-date-time'
});
var AuditScopeSelect = loadableWithDescriptor(function () { return import('./internal/AuditScopeSelect'); }, {
    type: 'audit_scope_select'
});
var HideFieldsSelect = loadableWithDescriptor(function () { return import('./internal/HideFieldsSelect'); }, {
    type: 'hide-fields-checkbox'
});
var HasForbiddenCityGroup = loadableWithDescriptor(function () { return import('./internal/HasForbiddenCityGroup'); }, {
    type: 'hasForbiddenCityGroup'
});
var CityGroupId = loadableWithDescriptor(function () { return import('./internal/CityGroupId'); }, {
    type: 'cityGroupId'
});
var FeetypeDynamicItem = loadableWithDescriptor(function () { return import('./internal/FeetypeDynamicItem'); }, {
    type: 'feetype-dynamic-item'
});
var SpecificationDynamicItem = loadableWithDescriptor(function () { return import('./internal/SpecificationDynamicItem'); }, {
    type: 'specification-dynamic-item'
});
var ApplyGenerateFee = loadableWithDescriptor(function () { return import('./internal/ApplyGenerateFee'); }, {
    type: 'checkbox:generate:fee'
});
var StaffRangeRuleCheckBox = loadableWithDescriptor(function () { return import('./internal/StaffRangeRuleCheckBox'); }, {
    type: 'staff-Range-Rule-checkbox'
});
var onlyDetailDateCheckBox = loadableWithDescriptor(function () { return import('./internal/OnlyDetailDateCheckbox'); }, {
    type: 'only-detail-date-checkbox'
});
var Unknown = loadableWithDescriptor(function () { return import('./internal/Unknown'); }, { type: 'unknown' });
var CheckBoxSelectShares = loadableWithDescriptor(function () { return import('./internal/CheckBoxSelectShares'); }, {
    type: 'checkbox:select:shares'
});
var StaffRangeFormItem = loadableWithDescriptor(function () { return import('./internal/StaffRangeFormItem'); }, {
    type: 'staff-range-form-item'
});
var DisplayRuleConfig = loadableWithDescriptor(function () { return import('./internal/DisplayRuleConfig'); }, {
    type: 'display-rule-config'
});
var FlowLinksStateSettle = loadableWithDescriptor(function () { return import('./internal/FlowLinksStateSettle'); }, {
    type: 'flow-links-state'
});
var CheckBoxDefaultCurrency = loadableWithDescriptor(function () { return import('./internal/CheckBoxDefaultCurrency'); }, {
    type: 'checkbox:default:currency'
});
var limitOrderCondition = loadableWithDescriptor(function () { return import('./internal/limitOrderCondition'); }, {
    type: 'limitOrderCondition'
});
export var internal = [
    TextFiled,
    CheckBox,
    BillExceedCheckbox,
    CheckBoxGroup,
    CheckBoxScopeTags,
    CheckBoxTagsTree,
    CheckBoxDefaultValue,
    RadioGroup,
    WhiteSpace,
    RadioGroupMore,
    TagList,
    NumberRange,
    DepRuleCheckBox,
    filterRuleCheckBox,
    descTitle,
    CheckBoxSelectTags,
    ApplyContentRuleRadioGroup,
    SelectFlow,
    DescText,
    ComplexSelect,
    SubmitterSelect,
    ComplexFilter,
    DetailComplexSelect,
    SubsidyComplexSelect,
    BillTypeCheckboxGroup,
    BillTypeRadioGroup,
    BlockUI,
    Icon,
    Color,
    InvoiceImportCheckboxGroup,
    Describe,
    Divider,
    RequisitionCanLoan,
    InvoiceImportCheckboxGroup,
    Priority,
    ForbidEdit,
    ApportionRuleRadio,
    ExpenseComplexSelect,
    MultPayeesCheckBoxSelectTags,
    LinkRequisitionControlled,
    DescribeWithPopup,
    DetailVisibleCheckBox,
    DestrictedTravelTypeSelect,
    BeneficiaryAccountRule,
    ReceiptSummarySelect,
    WrittenOffRateType,
    RepaymentDateLimitField,
    RepaymentDateModifyField,
    RequiredFieldConfig,
    HideFieldConfig,
    SelectStaffCheckBox,
    SelectDateTime,
    HideFieldsSelect,
    HasForbiddenCityGroup,
    CityGroupId,
    Unknown,
    tripPlatform,
    FeetypeDynamicItem,
    SpecificationDynamicItem,
    ApplyGenerateFee,
    AuditScopeSelect,
    AutoShareRule,
    StaffRangeRuleCheckBox,
    CheckBoxSelectShares,
    onlyDetailDateCheckBox,
    StaffRangeFormItem,
    DisplayRuleConfig,
    FlowLinksStateSettle,
    CheckBoxDefaultCurrency,
    AllowedRepaymentSelect,
    limitOrderCondition
];
