/**************************************************
 * Created by nanyuantingfeng on 08/06/2017 19:01.
 **************************************************/
import React, { PureComponent } from 'react'
import { Button, Icon, message, Modal, Tooltip } from 'antd'
import { showModal } from '@ekuaibao/show-util'
import { Fetch } from '@ekuaibao/fetch'
import { FilesUploader, fixData, generateFileName, getFileSuffix } from '@ekuaibao/uploader'
import { showMessage } from '@ekuaibao/show-util'
import MessageCenter from '@ekuaibao/messagecenter'
import styles from './upload-button.module.less'
import { EnhanceConnect } from '@ekuaibao/store'
import { getV } from '@ekuaibao/lib/lib/help'
import TokenData from '../../lib/token-data'
import { app as api } from '@ekuaibao/whispered'
import { uuid } from '@ekuaibao/helpers'
import { showLoading, hideLoading } from '@ekuaibao/lib/lib/lib-util'

function getFileId(keys) {
  return Fetch.POST(
    '/api/v1/attachment/attachments',
    {},
    {
      body: {
        keys: keys
      }
    }
  )
}

function getCorporationset() {
  return Fetch.GET('/api/v2/corporationset/watermark', {})
}

function buildData(file, tokenData, isOCR, uploadServiceUrl) {
  let { name } = file
  if (isOCR && IS_STANDALONE) {
    // 私有化的OCR不用上传图片的名称，如果有特殊字符切割服务有问题
    name = uuid(12) + getFileSuffix(name)
  }
  return {
    key: generateFileName(name),
    'x:originalname': name,
    token: tokenData && tokenData.token,
    uploadType: uploadServiceUrl?.fileServiceType
  }
}

const maxSize = 64
const defaultInvalidSuffixes = ['exe', 'com', 'sys', 'dll', 'bat', 'py', 'sh']
@EnhanceConnect(state => {
  return {
    uploadServiceUrl: state['@common'].uploadServiceUrl,
    dpPower: state['@common'].powers.DP
  }
})
export default class UploadButton extends PureComponent {
  token = null
  constructor(props, ...args) {
    super(props, ...args)
    this.bus = new MessageCenter()
    this.tokenData = TokenData.getInstance()
    this.state = { status: 'done', attachmentSetting: {} }
  }

  componentDidMount() {
    this.bus.on('over', this.handleOver)
    this.getConfig()
  }

  componentWillMount() {
    this.props.bus && this.props.bus.on('gpyImageList:change', this.extHandleOnchage.bind(this))
  }

  componentWillUnmount() {
    const fileServiceType = getV(this.props, 'uploadServiceUrl.fileServiceType')
    if (fileServiceType !== 'alioss') {
      this.tokenData.cleanToken()
    }
    this.props.bus && this.props.bus.un('gpyImageList:change', this.extHandleOnchage.bind(this))
    this.setState = (state, callback) => {
      return
    }
  }

  getConfig = async () => {
    const { suffixesPath, suffixesFiled } = this.props
    const attachmentSetting = (await getCorporationset())?.value?.attachmentSetting
    let invalidSuffixesConfig = {}

    if (attachmentSetting?.onFields.includes(suffixesFiled) || attachmentSetting?.onPath?.includes(suffixesPath)) {
      attachmentSetting?.fileType?.forEach(item => {
        item.name?.forEach(suffix => {
          invalidSuffixesConfig[suffix] = item.size
        })
      })
    }

    this.setState({
      attachmentSetting: {
        onPath: attachmentSetting?.onPath || [],
        onFields: attachmentSetting?.onFields || [],
        invalidSuffixesConfig: invalidSuffixesConfig || {}
      }
    })
  }

  uploadPrepare = async () => {
    try {
      const fileServiceType = getV(this.props, 'uploadServiceUrl.fileServiceType')
      this.token = await this.tokenData.data(fileServiceType)
    } catch (e) {
      Modal.warning({
        title: i18n.get('获取上传验证码失败,请稍候重试'),
        content: e.msg || e.message
      })
      return Promise.reject(e)
    }
  }

  extHandleOnchage(gpyImageList) {
    const handleSelect = this.refs.filesUpLoader.handleSelect
    if (handleSelect) {
      handleSelect({ target: { files: gpyImageList } })
      this.handleOnChange(gpyImageList)
    }
  }

  fnFixData = file => {
    const { uploadServiceUrl, fileName } = this.props
    const fileServiceType = uploadServiceUrl && uploadServiceUrl.fileServiceType
    if (fileName) {
      const type = file.type
      const newFile = new File([file], fileName, { type })
      return fixData(newFile, fileServiceType, this.token, this.tokenErrorAction)
    }
    return fixData(file, fileServiceType, this.token, this.tokenErrorAction)
  }

  tokenErrorAction = () => {
    message.warning(i18n.get('获取上传验证码失败,请稍候重试'))
    return this.uploadPrepare()
  }

  fnGetFileType = file => {
    // 部分类型的文件 file 实例无 type类型 现以文件名后缀为 type
    const fileNameArr = file?.name?.split('.')
    return fileNameArr?.pop()?.toLocaleLowerCase()
  }

  handleOver = data => {
    const { invalidSuffixesConfig } = this.state.attachmentSetting
    let { fileMaxSize } = this.props
    if (data && data.length) {
      let Content = () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {data.map(o => {
              const type = this.fnGetFileType(o)
              const limitSize = invalidSuffixesConfig?.[type]
              return (
                <div>
                  <span>{o.name}</span>
                  {limitSize && <span>，{`上限为${limitSize}MB`}</span>}
                </div>
              )
            })}
          </div>
        )
      }

      showModal.error({
        title: i18n.get('file-max', { maxSize: fileMaxSize || maxSize - 4 }), // 比最大的小4M
        content: <Content />,
        onOk: () => {}
      })
    }
  }

  handleOnStart = file => {
    const { onStart } = this.props
    if (file && file.length > 0) {
      this.setState({ status: 'start' })
      onStart && onStart()
    }
  }

  handleOnDone = uploaderFileList => {
    let { fileList = [], onFinish, showSuccessTip = true } = this.props
    let cloneList = fileList.slice(0)
    let keys = []
    let fileNames = {}
    let hashMap = {}
    uploaderFileList.forEach(file => {
      if (file.status === 'error') {
        this.setState({ status: 'done' })
        const name = file.name || ''
        showMessage.error(i18n.get(`「{__k0}」{__k1}!`, { __k0: name, __k1: i18n.get('上传失败') }))
      } else {
        let { response } = file
        let key = response.key

        keys.push({ key })
        fileNames[key] = response['x:originalname']
        hashMap[key] = response.hash
      }
    })
    if (!keys.length) {
      return onFinish && onFinish(cloneList)
    }
    getFileId(keys).then(({ items }) => {
      if (items && items.length) {
        items.forEach(item => {
          let { id, url, key, thumbUrl } = item
          let f = {
            key,
            id,
            url,
            fileName: fileNames[key],
            thumbUrl,
            hash: hashMap[key]
          }
          cloneList.push(f)
        })
        this.setState({ status: 'done' })
        onFinish && onFinish(cloneList)
        if (showSuccessTip) {
          showMessage.success(i18n.get('上传成功'))
        }
        let filesUpLoader = this.refs['filesUpLoader']
        let input = filesUpLoader && filesUpLoader.refs['input']
        if (input) input.value = ''
      }
    })
  }

  handleOnChange = uploaderFileList => {
    let { onChange } = this.props
    onChange && onChange(uploaderFileList)
  }

  handleDingTalkUpload = async () => {
    const spaceId = await api.invokeService('@common:get:space:id')
    await api.invokeService('@common:ding:pan:space:authorize')
    const result = await api.invoke('@vendor:dingtalk:uploadAttachment', {
      types: ['file', 'space', 'photo'],
      space: { corpId: Fetch.corpId, spaceId },
      image: { multiple: true, compression: true, quality: 50, resize: 50, spaceId, max: 9 },
      file: { spaceId, max: 9 }
    })
    if (result) {
      const data = result.data.map(extNode => {
        const key = `DP:${JSON.stringify(extNode)}`
        return {
          key,
          name: extNode.fileName,
          fileName: extNode.fileName,
          fileId: { id: extNode.fileId, key }
        }
      })
      const { onFinish, fileList = [] } = this.props
      onFinish && onFinish(fileList.concat(data))
    }
  }

  handleInvalidFIle = files => {
    const { invalidSuffixes = defaultInvalidSuffixes } = this.props
    message.error(i18n.get(`不支持{__k0}格式的文件`, { __k0: this.fnFormatTypeName(invalidSuffixes).join('、') }))
  }

  getUploadUrl = (file, generateFileName) => {
    return Fetch.GET(`/api/v1/attachment/attachments/presign/$${generateFileName}`)
  }

  fnFormatTypeName = strArr => {
    return strArr.map(str => '.' + str)
  }
  renderText = (validAccept, validAcceptText, minLimitSize, invalidSuffixes) => {
    const { field } = this.props
    if (field?.useSelfConfig && field?.tooltipmsg) {
      // 退汇回单中上传组件进行自定义配置
      // {
      //   name: 'attachments',
      //   label: i18n.get('退汇凭证'),
      //   type: 'attachments',
      //   dataType: { type: 'list', elemType: { type: 'attachment'}},
      //   optional: false,
      //   useSelfConfig: true, // 是否使用自己template中的配置
      //   btnText: i18n.get('上传退汇凭证'),
      //   tooltipmsg: i18n.get('仅支持上传「jpg,jpeg,bnp,pdf」格式文件'),
      // },
      return field?.tooltipmsg
    } else {
      return validAccept
        ? i18n.get(`只允许上传${validAcceptText}，${minLimitSize}M以内的文件`)
        : i18n.get(`不支持{__k0}格式的文件`, { __k0: this.fnFormatTypeName(invalidSuffixes).join('、') })
    }
  }

  render() {
    let { status } = this.state
    const { invalidSuffixesConfig = {} } = this.state.attachmentSetting
    let {
      disabled,
      children,
      isLoading,
      accept,
      multiple,
      uploadServiceUrl,
      inputStyle,
      onFormatFile,
      filesNum,
      fileMaxSize,
      filesExceedEvent = () => {},
      orgVisible,
      dpPower,
      canSelectDP = false,
      isOCR,
      invalidSuffixes = defaultInvalidSuffixes,
      onError,
      field
    } = this.props

    console.log('invalidSuffixesConfig', invalidSuffixesConfig)
    if (dpPower && canSelectDP) {
      return (
        <div onClick={this.handleDingTalkUpload}>
          {children ? (
            children
          ) : (
            <Button disabled={disabled || status !== 'done'} className={orgVisible ? styles['gpy-border-wrapper'] : ''}>
              <Icon type="upload" /> {i18n.get('添加附件')}
            </Button>
          )}
        </div>
      )
    }

    let multiples = multiple ? true : multiple
    let uploadUrl = uploadServiceUrl && uploadServiceUrl.uploadUrl
    const config = invalidSuffixesConfig && Object.keys(invalidSuffixesConfig)
    const sizes = invalidSuffixesConfig && Object.values(invalidSuffixesConfig)
    const minLimitSize = Math.min(...sizes) || 60
    const validAccept = config?.map(item => '.' + item)?.join(',') || accept
    const validAcceptText = validAccept?.replace('.', '')
    const text = this.renderText(validAccept, validAcceptText, minLimitSize, invalidSuffixes)
    return (
      <FilesUploader
        name="file"
        bus={this.bus}
        action={IS_STANDALONE ? this.getUploadUrl : uploadUrl}
        type={IS_STANDALONE}
        maxSize={fileMaxSize || maxSize}
        ref="filesUpLoader"
        multiple={multiples}
        accept={validAccept}
        disabledUpload={isLoading}
        disabled={disabled || status !== 'done'}
        data={IS_STANDALONE ? (file, tokenData) => buildData(file, tokenData, isOCR, uploadServiceUrl) : this.fnFixData}
        onDone={this.handleOnDone}
        onStart={this.handleOnStart}
        onChange={this.handleOnChange}
        filesNum={filesNum}
        filesExceedEvent={filesExceedEvent}
        inputStyle={inputStyle}
        format={onFormatFile}
        uploadPrepare={this.uploadPrepare}
        invalidSuffixes={invalidSuffixes}
        onInvalidFile={this.handleInvalidFIle}
        onError={onError}
        invalidSuffixesConfig={invalidSuffixesConfig}
      >
        {children ? (
          children
        ) : (
          <>
            <Button disabled={disabled || status !== 'done'} className={orgVisible ? styles['gpy-border-wrapper'] : ''}>
              <Icon type="upload" /> {field?.useSelfConfig && field?.btnText ? field?.btnText : i18n.get('添加附件')}
            </Button>
            {/* <Tooltip placement="bottom" title={i18n.get('不支持.exe、.com、.sys、.dll、.bat、.py和.sh格式的文件')}> */}
            <Tooltip placement="bottom" title={text}>
              <Icon className={styles['uploader-tooltip']} type="question-circle-o" />
            </Tooltip>
          </>
        )}
      </FilesUploader>
    )
  }
}

UploadButton.defaultProps = {
  action: 'https://up.qbox.me'
}
